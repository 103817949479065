import { useCallback } from 'react';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  CodenameEventType,
  CodenameTargetType,
} from '../../../../../_shared/models/TrackUserEventData.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { WorkflowStepColor } from '../../../../../data/constants/WorkflowStepColor.ts';
import {
  finishWorkflowStepDragging,
  startWorkflowStepDragging,
  workflowStepCodenameChanged,
  workflowStepCreatorInitialized,
  workflowStepEditorInitiated,
} from '../../actions/workflowActions.ts';
import { DraggableStepBar as DraggableStepBarComponent } from '../../components/stepBarItems/DraggableStepBar.tsx';
import { scheduledStepConstantValues } from '../../constants/publishedStepValues.ts';
import { getOrderedAllSteps } from '../../selectors/getOrderedWorkflowSteps.ts';

interface Props {
  readonly canAddNewStep: boolean;
  readonly color: WorkflowStepColor;
  readonly id: Uuid;
  readonly isDraggable: boolean;
  readonly name: string;
  readonly codename: string;
}

const getRelatedCodenames = (state: IStore, stepId: Uuid): ReadonlySet<string> => {
  const { archivedWorkflowStep, publishedWorkflowStep, regularWorkflowSteps } =
    state.workflowsApp.editorUi;

  const workflowStepCodenames = Array.from(state.data.workflows.byId.values())
    .flatMap((workflow) => workflow.steps)
    .filter((step) => step.id !== stepId)
    .concat(regularWorkflowSteps)
    .flatMap((step) => step.codename)
    .concat([
      publishedWorkflowStep.codename,
      archivedWorkflowStep.codename,
      scheduledStepConstantValues.codename,
    ]);

  return new Set(workflowStepCodenames);
};

export const DraggableStepBar = (props: Props) => {
  const errorMessage = useSelector((state) =>
    state.workflowsApp.editorUi.workflowErrors.get(props.id),
  );
  const isReadonly = useSelector((state) => {
    const { editedWorkflowStepId } = state.workflowsApp.editorUi;
    return !!editedWorkflowStepId && editedWorkflowStepId !== props.id;
  });
  const isRegularStep = useSelector((state) =>
    state.workflowsApp.editorUi.regularWorkflowSteps.some((step) => step.id === props.id),
  );
  const allRenderedSteps = useSelector((state) => getOrderedAllSteps(state.workflowsApp.editorUi));
  const relatedCodenames = useSelector((state) => getRelatedCodenames(state, props.id));
  const editedWorkflowId = useSelector((state) => state.workflowsApp.editorUi.editedWorkflowId);

  const dispatch = useDispatch();

  const onStatusEditingInitiated = () => dispatch(workflowStepEditorInitiated(props.id));
  const onAddNextStep = (stepId: Uuid) => dispatch(workflowStepCreatorInitialized(stepId));
  const onCodenameSave = (stepCodename: string, onSaved: () => void) => {
    dispatch(workflowStepCodenameChanged(props.id, stepCodename));
    onSaved();
  };
  const onCodenameCopy = () =>
    editedWorkflowId &&
    dispatch(
      trackUserEventWithData(TrackedEvent.Codename, {
        type: CodenameEventType.Copy,
        target: CodenameTargetType.WorkflowStep,
        workflowId: editedWorkflowId,
      }),
    );
  const onCodenameEdit = () =>
    editedWorkflowId &&
    dispatch(
      trackUserEventWithData(TrackedEvent.Codename, {
        type: CodenameEventType.Edit,
        target: CodenameTargetType.WorkflowStep,
        workflowId: editedWorkflowId,
      }),
    );
  const onStepPickedUp = useCallback(
    () => dispatch(startWorkflowStepDragging(props.id)),
    [props.id],
  );
  const onStepDropped = useCallback(() => dispatch(finishWorkflowStepDragging()), []);

  return (
    <DraggableStepBarComponent
      allRenderedSteps={allRenderedSteps}
      canAddNewStep={props.canAddNewStep}
      codename={props.codename}
      color={props.color}
      errorMessage={errorMessage}
      id={props.id}
      isDraggable={props.isDraggable}
      isReadonly={isReadonly}
      isRegularStep={isRegularStep}
      name={props.name}
      onAddNextStep={onAddNextStep}
      onCodenameSave={onCodenameSave}
      onCodenameCopy={onCodenameCopy}
      onCodenameEdit={onCodenameEdit}
      onStatusEditingInitiated={onStatusEditingInitiated}
      onStepDropped={onStepDropped}
      onStepPickedUp={onStepPickedUp}
      relatedCodenames={relatedCodenames}
    />
  );
};
