import { Icons } from '@kontent-ai/component-library/Icons';
import { colorIconDisabled } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { WidgetBaseEmptyState } from '../../../../missionControl/widgets/shared/components/WidgetBaseEmptyState.tsx';
import {
  NoItemAssignedToYouMessage,
  NoItemAssignedToYouTitle,
} from '../../../../missionControl/widgets/shared/constants/uiConstants.ts';

export const WidgetEmptyState: React.FC = () => {
  return (
    <WidgetBaseEmptyState
      icon={Icons.Doc}
      iconColor={colorIconDisabled}
      title={NoItemAssignedToYouTitle}
      message={NoItemAssignedToYouMessage}
    />
  );
};
