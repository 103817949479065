import React, { useCallback } from 'react';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { DraftJsEditorPlugin } from '../../draftJs/DraftJsEditorPlugin.type.ts';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { DisplayLinksPlugin, GetLinkEntityComponent } from '../DisplayLinksPlugin.tsx';
import { isPhoneLink } from '../api/LinkEntity.ts';
import { LinkType } from '../api/LinkType.ts';
import { PhoneLink } from './components/PhoneLink.tsx';

export type DisplayPhoneLinksPlugin = DraftJsEditorPlugin<
  None,
  None,
  None,
  None,
  [DisplayLinksPlugin]
>;

type PhoneLinkEntityProps = EntityDecoratorProps & {
  readonly children: ReadonlyArray<React.ReactNode>;
};

const PhoneLinkEntity: React.FC<PhoneLinkEntityProps> = ({
  children,
  contentState,
  decoratedText,
  entityKey,
}) => {
  const entity = contentState.getEntity(entityKey);
  if (!isPhoneLink(entity)) {
    return children;
  }

  const { phoneNumber } = entity.getData();

  return (
    <PhoneLink disabled key={entityKey} phoneNumber={phoneNumber} text={decoratedText}>
      {children}
    </PhoneLink>
  );
};

PhoneLinkEntity.displayName = 'PhoneLinkEntity';

export const DisplayPhoneLinksPlugin: PluginComponent<DisplayPhoneLinksPlugin> = (props) => {
  const apply: Apply<DisplayPhoneLinksPlugin> = useCallback((state) => {
    const getLinkEntityComponent: Decorator<GetLinkEntityComponent<None>> =
      (baseGetLinkEntityComponent) => (linkType) => {
        switch (linkType) {
          case LinkType.Phone:
            return {
              component: PhoneLinkEntity,
              props: {},
            };

          default:
            return baseGetLinkEntityComponent(linkType);
        }
      };

    state.getLinkEntityComponent.decorate(getLinkEntityComponent);

    return {};
  }, []);

  return useEditorWithPlugin(props, { apply });
};
