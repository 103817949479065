import { Box } from '@kontent-ai/component-library/Box';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { WorkflowStatus } from '@kontent-ai/component-library/WorkflowStatus';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { RedactedItemName } from '../../../applications/contentInventory/content/constants/uiConstants.ts';
import { IContentItemVariantReference } from '../../../applications/itemEditor/models/contentItem/ContentItemVariantReference.ts';
import { renderWorkflowStepColor } from '../../../data/models/workflow/utils/renderWorkflowStepColor.ts';

interface IDisabledUsageInItemVariantProps {
  readonly message: string;
  readonly variantReference: IContentItemVariantReference;
}

/* KCL-2043 - when usage is disabled, no additional published version link should be displayed even if available */
export const DisabledUsageInItemVariant: React.FC<IDisabledUsageInItemVariantProps> = (props) => (
  <Tooltip tooltipText={props.message} placement="left">
    <div className="content-item-usage">
      <div className="content-item-usage__primary-action content-item-usage__primary-action--disabled">
        <div className="content-item-usage__title content-item-usage__title--redacted">
          {RedactedItemName}
        </div>
        <Box marginLeft={Spacing.S}>
          <WorkflowStatus
            primary={{
              name: props.variantReference.assignment.workflowStatus.name,
              background: renderWorkflowStepColor(
                props.variantReference.assignment.workflowStatus.color,
              ),
            }}
          />
        </Box>
      </div>
    </div>
  </Tooltip>
);

DisabledUsageInItemVariant.displayName = 'DisabledUsageInItemVariant';
