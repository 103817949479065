import { TabView } from '@kontent-ai/component-library/TabView';
import {
  getDataUiComponentAttribute,
  getDataUiObjectNameAttribute,
} from '@kontent-ai/component-library/utils';
import classNames from 'classnames';
import Immutable from 'immutable';
import React, { PropsWithChildren } from 'react';
import { TabBadgeType } from '../../../../../_shared/components/TabMenu/TabBadgeType.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

import { Badge } from '@kontent-ai/component-library/Badge';
import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import { IContentGroupWithComments } from '../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { getTooltipText } from '../utils/tooltipUilts.ts';
import { ContentGroupTabComments } from './ContentGroupTabComments.tsx';

export const ContentItemTabViewCssClass = 'content-group-tabs';

export interface IContentItemTabViewOwnProps extends PropsWithChildren {
  readonly hideComments?: boolean;
}

export interface IContentItemTabViewStateProps {
  readonly badgeType: TabBadgeType | undefined;
  readonly canEditContent: boolean;
  readonly contentTypeGroups: ReadonlyArray<IContentGroupWithComments>;
  readonly incompleteElementsPerGroup: Immutable.Map<Uuid, number>;
  readonly selectedContentGroupId: Uuid | null;
}

export interface IContentItemTabViewDispatchProps {
  readonly onSelect: (id: Uuid) => void;
}

export type ContentItemTabViewProps = IContentItemTabViewOwnProps &
  IContentItemTabViewDispatchProps &
  IContentItemTabViewStateProps & { readonly children: any };

export const ContentItemTabView: React.FC<ContentItemTabViewProps> = ({
  badgeType,
  contentTypeGroups,
  hideComments,
  incompleteElementsPerGroup,
  onSelect,
  selectedContentGroupId,
  children,
}) => {
  const contentTypeGroupsList = contentTypeGroups.map(
    ({ id, name, commentCount, onCommentsClick }) => {
      const incompleteElements = incompleteElementsPerGroup.get(id) ?? 0;
      const showComments = !hideComments && !!commentCount;

      return {
        id,
        label: name,
        ariaDescription: incompleteElements
          ? `${badgeType}: ${pluralizeWithCount('incomplete element', incompleteElements)}`
          : undefined,
        leadingElement: incompleteElements ? (
          <Box
            display="flex"
            width={Spacing.L}
            height={Spacing.L}
            className={classNames({
              'tabbed-navigation__tab--with-warning': badgeType === TabBadgeType.Warning,
              'tabbed-navigation__tab--with-error': badgeType === TabBadgeType.Error,
            })}
            {...getDataUiComponentAttribute(Badge)}
          />
        ) : null,
        trailingElement: showComments ? (
          <ContentGroupTabComments commentCount={commentCount} onCommentsClick={onCommentsClick} />
        ) : null,
        tooltipText:
          incompleteElements || showComments
            ? getTooltipText(incompleteElements, commentCount || 0)
            : undefined,
        ...getDataUiObjectNameAttribute(name),
        ...getDataUiElementAttribute(DataUiElement.ContentGroup),
      };
    },
  );

  return (
    <TabView
      items={contentTypeGroupsList}
      onSelectionChange={onSelect}
      selectedKey={selectedContentGroupId}
    >
      {children}
    </TabView>
  );
};

ContentItemTabView.displayName = 'ContentItemTabView';
