import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Callback, RegisterCallback } from '../../../../../_shared/types/RegisterCallback.type.ts';
import { waitUntilFocusAndScrollAreNotDeferred } from '../../../../../_shared/utils/autoScrollUtils.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { OnUpdate } from '../../draftJs/DraftJsPlugin.type.ts';

export const useSelfPositioningComponentCallback = (): {
  readonly registerUpdateSelfPositioningComponent: RegisterCallback<Callback>;
  readonly updateSelfPositioningComponent: Callback;
  readonly onUpdateDecorator: Decorator<OnUpdate>;
} => {
  const updateCallback = useRef<Callback | null>(null);
  const registerUpdateToolbarPosition: RegisterCallback<Callback> = useCallback((callback) => {
    updateCallback.current = callback;
    return () => {
      updateCallback.current = null;
    };
  }, []);
  const deferredUpdateSelfPositioningComponent = useMemo(
    () => waitUntilFocusAndScrollAreNotDeferred(() => updateCallback.current?.()),
    [],
  );

  useEffect(
    () => deferredUpdateSelfPositioningComponent.cancel,
    [deferredUpdateSelfPositioningComponent],
  );

  const onUpdate: Decorator<OnUpdate> = useCallback(
    (baseOnUpdate) => (params) => {
      deferredUpdateSelfPositioningComponent();
      baseOnUpdate(params);
    },
    [deferredUpdateSelfPositioningComponent],
  );

  return {
    registerUpdateSelfPositioningComponent: registerUpdateToolbarPosition,
    updateSelfPositioningComponent: deferredUpdateSelfPositioningComponent,
    onUpdateDecorator: onUpdate,
  };
};
