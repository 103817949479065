import { Box } from '@kontent-ai/component-library/Box';
import { TabView } from '@kontent-ai/component-library/TabView';
import { Spacing } from '@kontent-ai/component-library/tokens';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';
import { ContentItemCollection } from '../containers/elements/ContentItemCollection.tsx';
import { ItemNameElement } from '../containers/elements/ItemNameElement.tsx';
import { ContentGroupTabsId } from '../utils/contentGroupTabsId.ts';
import { ContentItemPaneElementsGroup } from './ContentItemPaneElementsGroup.tsx';

type Props = {
  readonly areAnyContentGroupsVisible: boolean;
  readonly canEditGroupContent: boolean;
  readonly canEditName: boolean;
  readonly contentGroupTabsId: ContentGroupTabsId;
  readonly contentType: ICompiledContentType;
  readonly isCollectionInMainPaneEnabled: boolean;
};

export const ContentItemHeader = ({
  areAnyContentGroupsVisible,
  canEditGroupContent,
  canEditName,
  isCollectionInMainPaneEnabled,
}: Props) => {
  return (
    <>
      <ContentItemPaneElementsGroup
        isDisabled={!canEditName}
        hasTopRoundedCorners
        hasBottomRoundedCorners={areAnyContentGroupsVisible || isCollectionInMainPaneEnabled}
      >
        <ItemNameElement />
        {isCollectionInMainPaneEnabled && <ContentItemCollection />}
      </ContentItemPaneElementsGroup>

      {areAnyContentGroupsVisible && (
        <ContentItemPaneElementsGroup areHeaderContentGroups isDisabled={!canEditGroupContent}>
          <Box paddingTop={Spacing.L}>
            <TabView.TabGroup {...getDataUiCollectionAttribute(DataUiCollection.ContentGroups)} />
          </Box>
        </ContentItemPaneElementsGroup>
      )}
    </>
  );
};
