import { Box } from '@kontent-ai/component-library/Box';
import { WorkflowStatus } from '@kontent-ai/component-library/WorkflowStatus';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import {
  BorderRadius,
  Spacing,
  Typography,
  offsetFocus,
  px,
  shadowFocusStyles,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import { IContentItemVariantReference } from '../../../applications/itemEditor/models/contentItem/ContentItemVariantReference.ts';
import { renderWorkflowStepColor } from '../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import { UsageItemReference } from '../../utils/usage/usageUtils.ts';
import { AutoScrollId } from '../AutoScroll/AutoScrollId.ts';
import { AutoScrollLink } from '../AutoScroll/AutoScrollLink.tsx';

interface IEnabledUsageInItemVariantProps {
  readonly buildLink: (variantReference: IContentItemVariantReference) => string;
  readonly onClick?: () => void;
  readonly onNavigateToItem?: () => void;
  readonly scrollId?: AutoScrollId;
  readonly toScrollId: AutoScrollId;
  readonly typeName: string | null;
  readonly usage: UsageItemReference;
}

type StyledAdditionalNoteWrapperProps = Readonly<{
  $isFocusVisible: boolean;
}>;

const StyledAdditionalNoteWrapper = styled.span<StyledAdditionalNoteWrapperProps>`
  ${Typography.LabelSmall};

  &::before {
    ${({ $isFocusVisible }) => $isFocusVisible && shadowFocusStyles};
    inset: ${px(-1 * offsetFocus)};
    content: '';
    position: absolute;
    border-radius: ${px(BorderRadius.S)};
    pointer-events: none;
  }
  position: relative;
`;

type ExtraPublishedVersionNoteProps = IEnabledUsageInItemVariantProps &
  ReturnType<typeof useOurFocusRing>;

const ExtraPublishedVersionNote: React.FC<ExtraPublishedVersionNoteProps> = ({
  scrollId,
  toScrollId,
  usage,
  buildLink,
  onNavigateToItem,
  isFocusVisible,
  focusProps,
}) => {
  if (!usage.secondary) {
    return null;
  }

  const publishedVersion = usage.secondary;
  return (
    <div className="content-item-usage__additional-note">
      {'\u21b3 Also used in '}
      <StyledAdditionalNoteWrapper $isFocusVisible={isFocusVisible}>
        <AutoScrollLink
          aria-label={`Navigates to published version of item ${usage.primary.name}`}
          scrollId={scrollId}
          to={buildLink(publishedVersion)}
          toScrollId={toScrollId}
          onNavigated={onNavigateToItem}
          {...focusProps}
        >
          published version
        </AutoScrollLink>
      </StyledAdditionalNoteWrapper>{' '}
      of this item
    </div>
  );
};

export const EnabledUsageInItemVariant: React.FC<IEnabledUsageInItemVariantProps> = (props) => {
  const { buildLink, onClick, onNavigateToItem, scrollId, toScrollId, typeName, usage } = props;
  const { isFocusVisible, focusProps } = useOurFocusRing();

  return (
    <div className="content-item-usage" onClick={onClick}>
      <AutoScrollLink
        aria-label={`Navigates to ${usage.primary.name}`}
        className="content-item-usage__primary-action content-item-usage__primary-action--is-clickable"
        scrollId={scrollId}
        to={buildLink(usage.primary)}
        toScrollId={toScrollId}
        onNavigated={onNavigateToItem}
      >
        <div
          className="content-item-usage__title"
          data-hj-suppress=""
          title={typeName ? `${usage.primary.name} (${typeName})` : usage.primary.name}
        >
          {usage.primary.name}
        </div>
        <Box marginLeft={Spacing.S}>
          <WorkflowStatus
            primary={{
              name: usage.primary.assignment.workflowStatus.name,
              background: renderWorkflowStepColor(usage.primary.assignment.workflowStatus.color),
            }}
          />
        </Box>
      </AutoScrollLink>
      <ExtraPublishedVersionNote
        buildLink={buildLink}
        toScrollId={toScrollId}
        scrollId={scrollId}
        usage={usage}
        typeName={typeName}
        onNavigateToItem={onNavigateToItem}
        isFocusVisible={isFocusVisible}
        focusProps={focusProps}
      />
    </div>
  );
};

EnabledUsageInItemVariant.displayName = 'EnabledUsageInItemVariant';
