import { assert } from '@kontent-ai/utils';
import { ReactNode, createContext, useContext } from 'react';
import { createVariantTranslationTaskManager } from '../utils/translations/variantTranslationTaskManager.ts';

type VariantTranslationManager = ReturnType<typeof createVariantTranslationTaskManager>;

const TranslationTaskManagerContext = createContext<VariantTranslationManager | null>(null);
TranslationTaskManagerContext.displayName = 'TranslationTaskManager';

const translationTaskManager = createVariantTranslationTaskManager();

type Props = {
  readonly children: ReactNode;
};

export const TranslationTaskManagerProvider = ({ children }: Props) => {
  return (
    <TranslationTaskManagerContext.Provider value={translationTaskManager}>
      {children}
    </TranslationTaskManagerContext.Provider>
  );
};

export const useTranslationTaskManager = (): VariantTranslationManager => {
  const variantTranslationManager = useContext(TranslationTaskManagerContext);

  assert(
    variantTranslationManager,
    () => `"${TranslationTaskManagerContext.displayName}" is missing in the current tree.`,
  );

  return variantTranslationManager;
};
