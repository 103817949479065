import { Card } from '@kontent-ai/component-library/Card';
import { PaperLevel } from '@kontent-ai/component-library/Paper';
import { Collection } from '@kontent-ai/utils';
import { DialogState } from '../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { PopoverFrame } from '../../../../component-library/components/Dialogs/Popover/components/PopoverFrame.tsx';
import { usePopoverDialog } from '../../../../component-library/components/Dialogs/Popover/usePopoverDialog.tsx';
import { isCodenameUnique } from '../../../applications/contentModels/shared/utils/typeCodenameUtils.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { BarItemCodenameAction } from '../BarItems/Actions/BarItemCodenameAction.tsx';
import { Codename } from './Codename.tsx';
import { OnSaveCodename } from './OnSaveCodename.type.ts';

type Props = {
  readonly codename: string | null;
  readonly isBeingSaved?: boolean;
  readonly maxLength?: number;
  readonly mode?: 'save' | 'confirm';
  readonly relatedCodenames?: ReadonlySet<string>;
  readonly onCodenameSave?: OnSaveCodename;
  readonly onCodenameCopy?: () => void;
  readonly onCodenameEdit?: () => void;
};

export const CodenameBarItemButton = (props: Props) => {
  const { isOpen, openDialog, closeDialog, targetProps, popoverDialogProps } = usePopoverDialog({
    dialogState: DialogState.Default,
    headline: 'Foo',
    placement: 'bottom-end',
    __disabledFocusLock: true,
  });

  const isCodenameUniqueCallback = (codename: string): boolean => {
    return props.relatedCodenames && props.codename
      ? isCodenameUnique(codename, Collection.remove(props.relatedCodenames, props.codename))
      : isCodenameUnique(codename);
  };

  return props.codename ? (
    <>
      <BarItemCodenameAction
        onToggle={isOpen ? closeDialog : openDialog}
        isActive={isOpen}
        {...targetProps}
      />
      <PopoverFrame {...popoverDialogProps}>
        <Card
          level={PaperLevel.Popout}
          cardLabel="Codename"
          component="section"
          {...getDataUiElementAttribute(DataUiElement.CodenameForm)}
        >
          <Card.Body>
            <Codename
              codename={props.codename}
              isBeingSaved={props.isBeingSaved}
              isEditable={!!props.onCodenameSave}
              isCodenameUnique={isCodenameUniqueCallback}
              maxLength={props.maxLength}
              onSave={props.onCodenameSave}
              onCopyCompleted={closeDialog}
              onEdited={props.onCodenameEdit}
              onCopied={props.onCodenameCopy}
              mode={props.mode || 'confirm'}
            />
          </Card.Body>
        </Card>
      </PopoverFrame>
    </>
  ) : null;
};
