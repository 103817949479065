import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { TooltipPropsExtension } from '@kontent-ai/component-library/component-utils';
import React from 'react';
import { ConnectDragSource } from 'react-dnd';
import { DragAction } from '../../DragDrop/DragAction.tsx';

type Props = TooltipPropsExtension & {
  readonly connectDragSource?: ConnectDragSource;
  readonly disabled?: boolean;
};

export const BarItemDragAction: React.FC<Props> = ({
  connectDragSource,
  disabled,
  tooltipMaxGridUnitsWidth,
  tooltipPlacement = 'top',
  tooltipShortcuts,
  tooltipText,
}) => (
  <Tooltip
    maxGridUnitsWidth={tooltipMaxGridUnitsWidth}
    placement={tooltipPlacement}
    shortcuts={tooltipShortcuts}
    tooltipText={tooltipText}
  >
    <DragAction connectDragSource={connectDragSource} disabled={disabled} />
  </Tooltip>
);
