import { ReactNode, useEffect } from 'react';
import { unmountEditor } from '../../../../../_shared/actions/thunks/unmountEditor.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';

type Props = {
  readonly children: ReactNode;
};

export const UnmountEditorOnLeave = ({ children }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(unmountEditor()), []);

  return children;
};
