import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../@types/Action.type.ts';
import { ContentListing_Init_Started } from '../../../applications/contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { HighlightedTask_Ensured } from '../../../applications/contentInventory/shared/constants/contentInventoryActionTypes.ts';
import { ContentItemEditing_HighlightOpenTasks_Finished } from '../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { YourTasks_GoToTask_Selected } from '../../../applications/missionControl/widgets/tasksAssignedToYou/constants/yourTasksActionTypes.ts';
import { IItemElementPath } from '../../../applications/smartLink/actions/thunks/editItemVariant.ts';
import { SmartLink_HandleSuccessfulRedirectToContentItem } from '../../../applications/smartLink/constants/smartLinkActionTypes.ts';
import { AutoScrollId, CreateAutoScrollId } from '../../components/AutoScroll/AutoScrollId.ts';
import {
  Shared_ReturnScrollId_Apply,
  Shared_ScrollId_Forget,
  Shared_ScrollId_Remember,
} from '../../constants/sharedActionTypes.ts';

const initialState: ReadonlyMap<string, AutoScrollId> = new Map<string, AutoScrollId>();

export function scrollIdByPath(
  state = initialState,
  action: Action,
): ReadonlyMap<string, AutoScrollId> {
  switch (action.type) {
    // Reset remembered scrolling upon displaying content inventory to avoid initial scrolling within the first entry to content item
    case ContentListing_Init_Started:
      return initialState;

    case SmartLink_HandleSuccessfulRedirectToContentItem: {
      const hopsToEditedItem = action.payload.hopsToEditedItem;
      const newState = hopsToEditedItem.reduce(
        (reducedState: Map<string, AutoScrollId>, item: IItemElementPath) => {
          if (item.elementCodename) {
            return reducedState.set(
              item.path,
              item.deliveryContentComponentId
                ? CreateAutoScrollId.forComponentElement(
                    item.elementCodename,
                    item.deliveryContentComponentId,
                    item.currentItemId,
                  )
                : CreateAutoScrollId.forElement(item.elementCodename, item.currentItemId),
            );
          }
          return reducedState;
        },
        state,
      );
      return newState;
    }

    case Shared_ScrollId_Remember: {
      const { target } = action.payload;
      return Collection.add(state, [target.path, target.scrollId]);
    }

    case Shared_ScrollId_Forget: {
      const { path } = action.payload;
      return Collection.remove(state, path);
    }

    case Shared_ReturnScrollId_Apply: {
      const { returnTarget } = action.payload;
      return Collection.add(state, [returnTarget.path, returnTarget.scrollId]);
    }

    case HighlightedTask_Ensured:
    case YourTasks_GoToTask_Selected: {
      const { pathToItem, taskId } = action.payload;
      return Collection.add(state, [pathToItem, CreateAutoScrollId.forTask(taskId)]);
    }

    case ContentItemEditing_HighlightOpenTasks_Finished: {
      const { currentPath, taskIds } = action.payload;
      const firstTaskId = taskIds[0];
      return firstTaskId
        ? Collection.add(state, [currentPath, CreateAutoScrollId.forTask(firstTaskId)])
        : state;
    }

    default:
      return state;
  }
}
