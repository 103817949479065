import { Box } from '@kontent-ai/component-library/Box';
import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import { getA11yLabelProps } from '@kontent-ai/component-library/component-utils';
import { colorAlertBackgroundInverse } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export type ContentTypeOption = {
  readonly id: Uuid;
  readonly label: string;
  readonly isArchived: boolean;
};

interface IContentTypesLimitationsProps {
  readonly allContentTypes: readonly ContentTypeOption[];
  readonly isDisabled: boolean;
  readonly label?: string;
  readonly onSelectedContentTypesChanged: (options: ReadonlySet<Uuid>) => void;
  readonly selectedContentTypesIds: ReadonlySet<Uuid>;
  readonly tooltipText?: string;
}

const anyContentTypeLabel: string = 'Any content type';

export const ContentTypesLimitations: React.FC<IContentTypesLimitationsProps> = ({
  allContentTypes,
  isDisabled,
  label,
  onSelectedContentTypesChanged,
  selectedContentTypesIds,
  tooltipText,
}) => {
  const labelingProps = getA11yLabelProps(label, 'Select a content type');

  return (
    <Box>
      <MultiSelect
        inputState={isDisabled ? InputState.Disabled : InputState.Default}
        items={allContentTypes}
        onSelectionChange={onSelectedContentTypesChanged}
        placeholder={anyContentTypeLabel}
        placeholderType="tag"
        renderMenuOption={(optionProps) => (optionProps.item.value?.isArchived ? '' : undefined)}
        renderSelectedOption={(_id, selectedItem, defaultTagProps) =>
          selectedItem.isArchived ? (
            <Tag background={colorAlertBackgroundInverse} {...defaultTagProps} />
          ) : (
            <DefaultTag {...defaultTagProps} />
          )
        }
        selectedItemIds={selectedContentTypesIds}
        tooltipText={tooltipText}
        {...labelingProps}
        {...getDataUiCollectionAttribute(DataUiCollection.ContentTypes)}
      />
    </Box>
  );
};

ContentTypesLimitations.displayName = 'ContentTypesLimitations';
