import { ContentItemId } from '../../../_shared/models/ContentItemId.ts';
import { ReviewByGuidelinesParams } from '../../../paperModels/aiReview/repositories/serverModels/AiServerModels.reviewByGuidelines.ts';
import { AiActionName } from './AiActionName.type.ts';
import { CategorizeItemParams } from './actions/AiServerModels.categorizeItem.ts';
import { ChangeToneParams } from './actions/AiServerModels.changeTone.ts';
import { CreateTitleParams } from './actions/AiServerModels.createTitle.ts';
import { DescribeImageParams } from './actions/AiServerModels.describeImage.ts';
import { GenerateContentByInstructionParams } from './actions/AiServerModels.generateContentByInstruction.ts';
import { ImproveContentByBriefParams } from './actions/AiServerModels.improveContentByBrief.ts';
import { MakeShorterParams } from './actions/AiServerModels.makeShorter.ts';
import { MatchWritingStyleOfItemParams } from './actions/AiServerModels.matchWritingStyleOfItem.ts';
import { SummarizeParams } from './actions/AiServerModels.summarize.ts';
import { TranslateContentParams } from './actions/AiServerModels.translateContent.ts';

export type ElementOperationTrackingData = {
  readonly type: 'element-operation-tracking-data-v1';
  readonly aiSessionId: Uuid;
  readonly itemId: Uuid;
  readonly variantId: Uuid;
  readonly elementId: Uuid;
};

export const emptyOperationTrackingData = {
  type: 'empty-operation-tracking-data-v1',
} as const;

export type EmptyOperationTrackingData = typeof emptyOperationTrackingData;

export type AssetOperationTrackingData = {
  readonly type: 'asset-operation-tracking-data-v1';
  readonly aiSessionId: Uuid;
  readonly assetId: Uuid;
};

export type OperationTrackingData =
  | ElementOperationTrackingData
  | EmptyOperationTrackingData
  | AssetOperationTrackingData;

export type MultipleInputsOperationParamsModelBase<TTrackingData extends OperationTrackingData> = {
  readonly type: 'multiple-inputs-request-v1';
  readonly trackingData: TTrackingData;
};

export type AiActionNameToParameters = {
  [AiActionName.CategorizeItem]: CategorizeItemParams;
  [AiActionName.ChangeTone]: ChangeToneParams;
  [AiActionName.CreateTitle]: CreateTitleParams;
  [AiActionName.DescribeImage]: DescribeImageParams;
  [AiActionName.GenerateContentByInstruction]: GenerateContentByInstructionParams;
  [AiActionName.ImproveContentByBrief]: ImproveContentByBriefParams;
  [AiActionName.MakeShorter]: MakeShorterParams;
  [AiActionName.MatchWritingStyleOfItem]: MatchWritingStyleOfItemParams;
  [AiActionName.ReviewByGuidelines]: ReviewByGuidelinesParams;
  [AiActionName.Summarize]: SummarizeParams;
  [AiActionName.TranslateContent]: TranslateContentParams;
};

export type AnyAiActionParameters = AiActionNameToParameters[AiActionName];

export const createElementOperationTrackingData = (
  itemId: ContentItemId,
  elementId: Uuid,
  sessionId: Uuid,
): ElementOperationTrackingData => ({
  type: 'element-operation-tracking-data-v1',
  aiSessionId: sessionId,
  itemId: itemId.itemId,
  variantId: itemId.variantId,
  elementId,
});

export const createAssetOperationTrackingData = (
  assetId: Uuid,
  sessionId: Uuid,
): AssetOperationTrackingData => ({
  type: 'asset-operation-tracking-data-v1',
  aiSessionId: sessionId,
  assetId,
});
