import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { createTaxonomyGroupServerModel } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyGroupServerModel } from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import { SaveTaxonomyGroupErrorMessage } from '../../constants/uiConstants.ts';
import {
  taxonomyGroupSavingFailed,
  taxonomyGroupSavingFinished,
  taxonomyGroupSavingStarted,
} from '../taxonomyActions.ts';

interface IUpdateTaxonomyGroupActionDependencies {
  readonly taxonomyRepository: {
    readonly updateTaxonomyGroup: (
      taxonomyGroup: ITaxonomyGroupServerModel,
    ) => Promise<ITaxonomyGroupServerModel>;
  };
  readonly loadTaxonomyGroups: () => ThunkPromise;
}

export const createUpdateEditedTaxonomyGroupAction =
  ({ taxonomyRepository, loadTaxonomyGroups }: IUpdateTaxonomyGroupActionDependencies) =>
  (): ThunkPromise =>
  async (dispatch, getState) => {
    const { editedTaxonomyGroup } = getState().taxonomyApp;

    dispatch(taxonomyGroupSavingStarted());
    const taxonomyGroupServerModel = createTaxonomyGroupServerModel(editedTaxonomyGroup);

    try {
      const taxonomyGroup = await taxonomyRepository.updateTaxonomyGroup(taxonomyGroupServerModel);
      await dispatch(loadTaxonomyGroups());
      dispatch(taxonomyGroupSavingFinished(taxonomyGroup));
    } catch (error) {
      dispatch(taxonomyGroupSavingFailed(SaveTaxonomyGroupErrorMessage));
      throw error;
    }
  };
