import { isElement } from '@kontent-ai/DOM';
import { useObserveElementPresence } from '@kontent-ai/hooks';
import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useEffect, useState } from 'react';
import { createPortal, flushSync } from 'react-dom';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import {
  CustomAppConfigurationEditingRoute,
  CustomAppConfigurationEditingRouteParams,
  SubscriptionEnvironmentCustomAppConfigurationEditingRoute,
  SubscriptionEnvironmentCustomAppConfigurationEditingRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { HandleUnsavedFormOnNavigation } from '../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { createFormValidationResolver } from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import {
  CustomApp,
  createCustomAppCreateServerModel,
  createCustomAppDomainModel,
  emptyCustomApp,
} from '../../../../data/models/customApps/CustomApp.ts';
import { RoleOption } from '../../../../data/models/roles/RoleOption.ts';
import { initCustomAppListing } from '../../../customApps/actions/thunkCustomAppActions.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import { customAppNameChanged } from '../actions/customAppActions.ts';
import { CustomAppCreatorToolbarActions } from '../components/toolbar/CustomAppCreatorToolbarActions.tsx';
import { CustomAppToolbarActionsPlaceholderElementId } from '../components/toolbar/CustomAppToolbarActionsPlaceholder.tsx';
import { CustomAppFormShape } from '../models/CustomAppFormShape.type.ts';
import { createCustomAppFromFormValues } from '../utils/createCustomAppFromFormValues.ts';
import { getSortedRoleOptionsListFromServerModelWithAllRolesFirst } from '../utils/roleUtils.ts';
import { customAppEditorFormValidationConfig } from '../validation/customAppValidation.ts';
import { CustomAppEditorForm } from './../components/CustomAppEditorForm.tsx';

type CustomAppCreatorProps = {
  readonly subscriptionId?: Uuid;
};

const initFormValues: CustomAppFormShape = {
  allowedRoleIds: [],
  config: '',
  name: '',
  sourceUrl: '',
};

const { customAppRepository, roleRepository } = repositoryCollection;

export const CustomAppCreator = ({ subscriptionId }: CustomAppCreatorProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const projectId = useSelector((state) => state.sharedApp.currentProjectId);

  const [customAppActionIsInProgress, setCustomAppActionIsInProgress] = useState<boolean>(false);
  const [displayNotificationBar, setDisplayNotificationBar] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [roles, setRoles] = useState<ReadonlyArray<RoleOption> | null>(null);
  const [currentName, setCurrentName] = useState<string>(emptyCustomApp.name);

  useEffect(() => {
    const { cancel } = makeCancellablePromise(async () => await roleRepository.getRoles())
      .then((rolesServerModel) => {
        setRoles(getSortedRoleOptionsListFromServerModelWithAllRolesFirst(rolesServerModel));
      })
      .catch(swallowCancelledPromiseError);

    return cancel;
  }, []);

  const formProps = useForm<CustomAppFormShape>({
    defaultValues: initFormValues,
    mode: 'onTouched',
    resolver: createFormValidationResolver(customAppEditorFormValidationConfig, {}),
    shouldFocusError: false,
  });

  const { formState, handleSubmit, reset, setValue, getValues, watch, trigger } = formProps;
  useEffect(() => {
    const subscription = watch(async () => {
      // Prevents form fields from being validated before their inputs are touched
      const isNameValid = !!formState.touchedFields.name && (await trigger('name'));
      const isAllowedRoleIdsValid =
        !!formState.touchedFields.allowedRoleIds && (await trigger('allowedRoleIds'));
      const isConfigValid = !!formState.touchedFields.config && (await trigger('config'));
      const isSourceUrlValid = !!formState.touchedFields.sourceUrl && (await trigger('sourceUrl'));

      const isActualFormValid =
        isNameValid && isAllowedRoleIdsValid && isConfigValid && isSourceUrlValid;
      if (displayNotificationBar && isActualFormValid) {
        setDisplayNotificationBar(false);
      }

      setIsValid(isActualFormValid);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    displayNotificationBar,
    formState.touchedFields.name,
    formState.touchedFields.allowedRoleIds,
    formState.touchedFields.config,
    formState.touchedFields.sourceUrl,
    trigger,
    watch,
  ]);

  useEffect(() => {
    const nameSubscription = watch((formValues: CustomAppFormShape, { name }) => {
      if (name === 'name') {
        dispatch(customAppNameChanged(formValues.name));
        setCurrentName(formValues.name);
      }
    });

    return () => {
      nameSubscription.unsubscribe();
    };
  }, [watch]);

  const createCustomApp = async (formValues: CustomAppFormShape): Promise<CustomApp> => {
    setCustomAppActionIsInProgress(true);

    const customApp = createCustomAppFromFormValues(formValues, emptyCustomApp);
    const serverModel = createCustomAppCreateServerModel(customApp);
    const createdCustomApp = await customAppRepository.insert(serverModel);

    await dispatch(initCustomAppListing(projectId));
    setCustomAppActionIsInProgress(false);

    return createCustomAppDomainModel(createdCustomApp);
  };

  const submitForm = handleSubmit(
    async (formValues) => {
      const createdCustomApp = await createCustomApp(formValues);
      flushSync(() => {
        reset(formValues, { keepValues: true, keepIsValid: true });
      });

      if (subscriptionId == null) {
        history.push(
          buildPath<CustomAppConfigurationEditingRouteParams>(CustomAppConfigurationEditingRoute, {
            projectId,
            customAppId: createdCustomApp.id,
          }),
        );
      } else {
        history.push(
          buildPath<SubscriptionEnvironmentCustomAppConfigurationEditingRouteParams>(
            SubscriptionEnvironmentCustomAppConfigurationEditingRoute,
            {
              projectId,
              subscriptionId,
              customAppId: createdCustomApp.id,
            },
          ),
        );
      }
    },
    () => {
      const values = getValues();
      if (values.sourceUrl === '') {
        setValue('sourceUrl', '', { shouldTouch: true });
      }
      setDisplayNotificationBar(true);
    },
  );

  const handleUnsavedChanges = async (onSuccess: () => void, onFail: () => void) => {
    try {
      const values = getValues();
      await createCustomApp(values);

      if (isValid) {
        onSuccess();
      } else {
        onFail();
      }
    } catch {
      onFail();
    }
  };

  const { current: customAppToolbarContainerElement } = useObserveElementPresence(
    CustomAppToolbarActionsPlaceholderElementId,
  );

  if (!roles) {
    return <Loader />;
  }

  return (
    <>
      <HtmlSettingsPageTitle
        customName={currentName}
        settingsAppName={EnvironmentSettingsAppNames.CustomApps}
      />
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={formState.isDirty}
        isBeingSaved={customAppActionIsInProgress}
        onSaveChanges={handleUnsavedChanges}
      />
      <CustomAppEditorForm
        formProps={formProps}
        hasError={displayNotificationBar}
        onCloseNotificationBar={() => setDisplayNotificationBar(false)}
        onSubmit={submitForm}
        roles={roles}
      />
      {isElement(customAppToolbarContainerElement) &&
        createPortal(
          <CustomAppCreatorToolbarActions
            customAppActionIsInProgress={customAppActionIsInProgress}
            onSubmit={submitForm}
          />,
          customAppToolbarContainerElement,
        )}
    </>
  );
};
