import { assert } from '@kontent-ai/utils';

type DecorableBase<TFunc extends (...args: any[]) => any> = (
  ...args: Parameters<TFunc>
) => ReturnType<TFunc>;

export type Decorator<TFunc extends (...args: any[]) => any> = (base: TFunc) => TFunc;

type DecoratorCall<TFunc extends (...args: any[]) => any> = (decorator: Decorator<TFunc>) => void;

type Decorate<TFunc extends (...args: any[]) => any> = {
  readonly decorate: DecoratorCall<(...args: Parameters<TFunc>) => ReturnType<TFunc>>;
};

const sealProperty = Symbol('seal');

export type Sealable = {
  readonly [sealProperty]: () => void;
};

const isSealable = (x: unknown): x is Sealable => typeof (x as any)?.[sealProperty] === 'function';

export type DecorableFunction<TFunc extends (...args: any[]) => any> = DecorableBase<TFunc> &
  Decorate<TFunc> &
  Sealable;

export const decorable = <TFunc extends (...args: any[]) => any>(
  initialImplementation: TFunc,
): DecorableFunction<TFunc> => {
  let implementation: TFunc = initialImplementation;
  let sealed = false;

  const result = (...args: Parameters<TFunc>) => {
    assert(
      sealed,
      () =>
        'Decorable function cannot be called before it is sealed, as there may still be some decorations pending. Call the function later in the life cycle.',
    );
    const baseCalled = false;
    const functionResult = implementation(...args);
    assert(
      !baseCalled,
      () =>
        'Decorable function cannot be called before it is sealed, as there may still be some decorations pending. Call the function later in the life cycle.',
    );
    return functionResult;
  };

  const decorate: DecoratorCall<TFunc> = (decorator: Decorator<TFunc>) => {
    assert(
      !sealed,
      () =>
        'Decorable function is already sealed and cannot be further decorated. Call the decorate earlier in the life cycle.',
    );
    implementation = decorator(implementation);
  };

  const sealable: DecorableFunction<TFunc> & Sealable = Object.assign(result, {
    decorate,
    [sealProperty]: () => {
      sealed = true;
    },
  });

  return sealable;
};

export type Decorable<TState extends ReadonlyRecord<string, unknown>> = {
  readonly [TKey in keyof TState]: TState[TKey] extends (...args: any[]) => any
    ? DecorableFunction<TState[TKey]>
    : TState[TKey];
} & Sealable;

export type DeepSealed<T> = T extends DecorableFunction<infer TFunc>
  ? TFunc
  : T extends Sealable
    ? {
        readonly [P in keyof T as P extends symbol ? never : P]: DeepSealed<T[P]>;
      }
    : T;

const sealInternal = <T>(value: T): DeepSealed<T> => {
  if (isSealable(value)) {
    value[sealProperty]();
  }
  return value as DeepSealed<T>;
};

export const sealable = <T extends ReadonlyRecord<string, unknown>>(obj: T): T & Sealable =>
  Object.assign({}, obj, {
    [sealProperty]: () => {
      for (const key of Object.keys(obj)) {
        if (Object.hasOwn(obj, key)) {
          sealInternal(obj[key]);
        }
      }
    },
  });

export const seal = <T extends Sealable>(value: T): DeepSealed<T> => sealInternal(value);
