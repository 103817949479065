import styled from 'styled-components';
import { Spacing, gridUnit } from '../../../tokens/quarks/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';

export const StyledSubheaderDividerWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const StyledSubheaderDivider = styled.div`
  min-width: ${px(5 * gridUnit)};
  padding-left: ${px(Spacing.S)};
  flex-grow: 1;
`;
