import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import React, { ReactNode, Ref, forwardRef } from 'react';
import { getDataAttributeProps } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { HotkeysHandlers, HotkeysKeyMap } from './hotkeys.type.ts';

export enum HotkeysMode {
  Dual = 'Dual',
  InPlace = 'InPlace',
  WindowOnly = 'WindowOnly',
}

interface IHotkeysHandlerProps {
  readonly children: ReactNode;
  readonly className?: string;
  readonly handlers: HotkeysHandlers;
  readonly mode?: HotkeysMode;
  readonly excludedAreas?: readonly React.RefObject<HTMLElement>[];
}

const hotkeysKeyMap: ReadonlyRecord<keyof HotkeysKeyMap, ShortcutsConfig | ''> = {
  onBackspace: ShortcutsConfig.Backspace,
  onControlC: ShortcutsConfig.ControlC,
  onControlEnter: ShortcutsConfig.ControlEnter,
  onControlS: ShortcutsConfig.ControlS,
  onDown: ShortcutsConfig.Down,
  onEnter: ShortcutsConfig.Enter,
  onSpace: ShortcutsConfig.Space,
  onEscape: ShortcutsConfig.Escape,
  onLeft: ShortcutsConfig.Left,
  onRight: ShortcutsConfig.Right,
  onTab: ShortcutsConfig.Tab,
  onUp: ShortcutsConfig.Up,
};

const useHotkeysHandlers = (
  handlers: HotkeysHandlers,
  {
    excludedElementRefs = [],
    ref: forwardedRef,
  }: {
    mode: 'InPlace' | 'WindowOnly';
    ref?: React.Ref<HTMLElement>;
    excludedElementRefs?: readonly React.RefObject<HTMLElement>[];
    scopeName?: string;
  },
) => {
  const callbacksByNewHotkeys = Object.entries(handlers).map(
    ([onKey, handler]) => [hotkeysKeyMap[onKey], handler] as const,
  );
  const newHandlers = Object.fromEntries(callbacksByNewHotkeys);

  return useHotkeys(newHandlers, {
    ref: forwardedRef,
    excludedElementRefs,
  });
};

const SimpleHotkeysHandler = forwardRef<
  HTMLElement,
  ReplaceProperties<
    IHotkeysHandlerProps,
    {
      readonly mode: HotkeysMode.InPlace | HotkeysMode.WindowOnly;
    }
  >
>(({ excludedAreas, handlers, mode, ...rest }, forwardedRef) => {
  const { ref } = useHotkeysHandlers(handlers, {
    excludedElementRefs: excludedAreas,
    mode,
    ref: forwardedRef,
  });

  return <div ref={ref as Ref<HTMLDivElement>} {...rest} />;
});

const DualHotkeysHandler = forwardRef<HTMLElement, Omit<IHotkeysHandlerProps, 'mode'>>(
  ({ handlers, excludedAreas, ...rest }, forwardedRef) => {
    useHotkeysHandlers(handlers, {
      mode: HotkeysMode.WindowOnly,
    });
    const { ref } = useHotkeysHandlers(handlers, {
      mode: HotkeysMode.InPlace,
      ref: forwardedRef,
      excludedElementRefs: excludedAreas,
    });

    return <div ref={ref as Ref<HTMLDivElement>} {...rest} />;
  },
);

/**
 * @deprecated Use the `useHotkeys` hook instead
 */
export const HotkeysHandler = forwardRef<HTMLElement, IHotkeysHandlerProps>(
  (
    { children, className, excludedAreas, handlers, mode = HotkeysMode.InPlace, ...restProps },
    forwardedRef,
  ) => {
    const dataAttributes = getDataAttributeProps(restProps);

    if (mode !== 'Dual') {
      return (
        <SimpleHotkeysHandler
          className={className}
          excludedAreas={excludedAreas}
          handlers={handlers}
          mode={mode}
          ref={forwardedRef}
          {...dataAttributes}
        >
          {children}
        </SimpleHotkeysHandler>
      );
    }

    return (
      <DualHotkeysHandler
        className={className}
        excludedAreas={excludedAreas}
        handlers={handlers}
        ref={forwardedRef}
        {...dataAttributes}
      >
        {children}
      </DualHotkeysHandler>
    );
  },
);

HotkeysHandler.displayName = 'HotkeysHandler';
