import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { uncategorizedItemFilterTaxonomyTermId } from '../../../applications/contentInventory/content/features/ListingFilter/constants/listingFilterConstants.ts';
import { isContentItemStatusEmpty } from '../../../applications/contentInventory/content/models/filter/IContentItemStatus.ts';
import { IListingFilter } from '../../../applications/contentInventory/content/models/filter/IListingFilter.ts';
import { isPublishingStateEmpty } from '../../../applications/contentInventory/content/models/filter/IPublishingState.ts';
import { getAssignmentFromServerModel } from '../../../applications/itemEditor/models/contentItem/Assignment.ts';
import { PublishingState } from '../../../data/constants/PublishingState.ts';
import { SearchMethod } from '../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import {
  IContentItemServerModel,
  IContentItemWithVariantServerModel,
} from '../../../repositories/serverModels/INewContentItemServerModel.ts';
import { isTaxonomyItemElementServerModel } from '../../../repositories/serverModels/elementServerModelTypeGuards.ts';
import { IUserIdentifier } from '../../models/UserIdentifier.ts';
import { VariantCompletionStatus, getContentVariantState } from '../contentItemVariantUtils.ts';
import { doesItemSatisfyFilterPhrase } from './nameFilterUtils.ts';

type ItemWithVariantFilterChecker = (
  item: IContentItemWithVariantServerModel,
  filter: IListingFilter,
) => boolean;
type ItemFilterChecker = (item: IContentItemServerModel, filter: IListingFilter) => boolean;

const publishingStateFilterSatisfies = (
  publishingState: string,
  filter: IListingFilter,
): boolean => {
  if (isPublishingStateEmpty(filter.selectedPublishingStateNodes)) {
    return true;
  }

  switch (publishingState) {
    case PublishingState.None:
      return filter.selectedPublishingStateNodes.none;
    case PublishingState.Published:
      return filter.selectedPublishingStateNodes.published;
    case PublishingState.Unpublished:
      return filter.selectedPublishingStateNodes.unpublished;
    default:
      return false;
  }
};

const nameFilterSatisfies: ItemFilterChecker = (
  item: IContentItemServerModel,
  filter: IListingFilter,
): boolean => doesItemSatisfyFilterPhrase(filter.searchPhrase, item, [(i) => i.name]);

const collectionFilterSatisfies: ItemFilterChecker = (
  item: IContentItemServerModel,
  filter: IListingFilter,
): boolean =>
  !filter.selectedCollectionsNodes.size || filter.selectedCollectionsNodes.has(item.collectionId);

const contentTypeFilterSatisfies: ItemWithVariantFilterChecker = (
  item: IContentItemWithVariantServerModel,
  filter: IListingFilter,
): boolean =>
  !filter.selectedContentTypesNodes.size ||
  filter.selectedContentTypesNodes.has(item.item.type._id);

const contributorsFilterSatisfies: ItemWithVariantFilterChecker = (
  item: IContentItemWithVariantServerModel,
  filter: IListingFilter,
): boolean =>
  !filter.selectedContributorsNodes.size ||
  Collection.getValues(filter.selectedContributorsNodes).some(
    (contributor) =>
      !!item.variant &&
      item.variant.assignment.assignees.some(
        (user: IUserIdentifier) => user.userId === contributor,
      ),
  );

const workflowFilterSatisfies: ItemWithVariantFilterChecker = (
  item: IContentItemWithVariantServerModel,
  filter: IListingFilter,
): boolean =>
  filter.selectedWorkflowNodes.size === 0 ||
  !!filter.selectedWorkflowNodes
    .get(item.variant.assignment.workflowStatus.workflowId)
    ?.has(item.variant.assignment.workflowStatus._id);

const sitemapLocationsSatisfiesFilter = (
  sitemapLocations: Immutable.Set<Uuid>,
  filter: IListingFilter,
): boolean =>
  !filter.selectedSitemapNodes.size ||
  sitemapLocations.some((id: Uuid) => filter.selectedSitemapNodes.has(id));

const sitemapFilterSatisfies: ItemFilterChecker = (
  item: IContentItemServerModel,
  filter: IListingFilter,
): boolean => sitemapLocationsSatisfiesFilter(Immutable.Set(item.sitemapLocation), filter);

const taxonomyFilterSatisfies: ItemWithVariantFilterChecker = (
  item: IContentItemWithVariantServerModel,
  filter: IListingFilter,
): boolean => {
  const taxonomyElements = item.variant.contentElements.filter(isTaxonomyItemElementServerModel);

  return Collection.getEntries(filter.selectedTaxonomyNodes).every(
    ([groupId, termIdsWithUncategorized]) => {
      const relevantTaxonomyElements = taxonomyElements.filter((el) => el.groupId === groupId);

      const containsRelevantTaxonomyElement = relevantTaxonomyElements.length > 0;
      if (!containsRelevantTaxonomyElement) {
        return false;
      }

      const shouldIncludeUncategorized = termIdsWithUncategorized.has(
        uncategorizedItemFilterTaxonomyTermId,
      );
      const isUncategorized = relevantTaxonomyElements.every((el) => !el.value.length);

      const realTermIds = [...termIdsWithUncategorized].filter(
        (tId) => tId !== uncategorizedItemFilterTaxonomyTermId,
      );
      const matchesSelectedFilterRealTermIds = relevantTaxonomyElements.some((el) =>
        el.value.some((tId) => realTermIds.includes(tId)),
      );

      return (shouldIncludeUncategorized && isUncategorized) || matchesSelectedFilterRealTermIds;
    },
  );
};

const statusFilterSatisfies: ItemWithVariantFilterChecker = (
  item: IContentItemWithVariantServerModel,
  filter: IListingFilter,
): boolean => {
  const variant = {
    assignment: getAssignmentFromServerModel(item.variant.assignment),
    isArchived: item.variant.archived,
    isComplete: item.variant.isComplete,
  };
  const variantStatus = getContentVariantState(variant);
  const contentItemStatus = filter.selectedContentItemStatus;

  if (isContentItemStatusEmpty(contentItemStatus)) {
    return true;
  }

  switch (variantStatus) {
    case VariantCompletionStatus.notTranslated:
      return contentItemStatus.notTranslated;
    case VariantCompletionStatus.ready:
      return contentItemStatus.ready;
    case VariantCompletionStatus.allDone:
      return contentItemStatus.allDone;
    case VariantCompletionStatus.unfinished:
      return contentItemStatus.unfinished;
  }
};

const allItemWithVariantCheckers: ReadonlyArray<ItemWithVariantFilterChecker> = [
  (item, filter) => publishingStateFilterSatisfies(item.variant.publishingState, filter),
  contentTypeFilterSatisfies,
  contributorsFilterSatisfies,
  workflowFilterSatisfies,
  taxonomyFilterSatisfies,
  statusFilterSatisfies,
];

const itemWithVariantCheckers: ReadonlyRecord<
  SearchMethod,
  ReadonlyArray<ItemWithVariantFilterChecker>
> = {
  [SearchMethod.Standard]: allItemWithVariantCheckers,
  [SearchMethod.StandardAsBackUp]: allItemWithVariantCheckers,
  [SearchMethod.FullText]: allItemWithVariantCheckers,
  [SearchMethod.Ai]: [contentTypeFilterSatisfies, contributorsFilterSatisfies],
};

const allItemCheckers: ReadonlyArray<ItemFilterChecker> = [
  collectionFilterSatisfies,
  nameFilterSatisfies,
  sitemapFilterSatisfies,
];

const itemCheckers: ReadonlyRecord<SearchMethod, ReadonlyArray<ItemFilterChecker>> = {
  [SearchMethod.Standard]: allItemCheckers,
  [SearchMethod.StandardAsBackUp]: allItemCheckers,
  [SearchMethod.FullText]: [collectionFilterSatisfies, sitemapFilterSatisfies],
  [SearchMethod.Ai]: [collectionFilterSatisfies],
};

export const doesItemWithVariantSatisfyFilter = (
  item: IContentItemWithVariantServerModel,
  filter: IListingFilter,
  usedSearchMethod: SearchMethod,
): boolean =>
  itemWithVariantCheckers[usedSearchMethod].every((checker) => checker(item, filter)) &&
  itemCheckers[usedSearchMethod].every((checker) => checker(item.item, filter));

export const doesItemSatisfyFilter = (
  item: IContentItemServerModel,
  filter: IListingFilter,
  usedSearchMethod: SearchMethod,
): boolean => itemCheckers[usedSearchMethod].every((checker) => checker(item, filter));
