import {
  CustomAppConfigurationCreatingRoute,
  CustomAppConfigurationEditingRoute,
  CustomAppConfigurationEditingRouteParams,
  EnsuredSubscriptionRouteParams,
  EnvironmentRouteParams,
  EnvironmentSettingsRoute,
  EnvironmentsRoute,
  InviteModalRouteParams,
  InviteUserRoute,
  PreviewConfigurationRouteParams,
  PreviewURLsRoute,
  RoleCreatorRoute,
  RoleEditorRoute,
  RoleEditorRouteParams,
  RolesRoute,
  SpacesRoute,
  SpacesRouteParams,
  SubscriptionEnvironmentCustomAppConfigurationCreatingRoute,
  SubscriptionEnvironmentCustomAppConfigurationEditingRoute,
  SubscriptionEnvironmentCustomAppConfigurationEditingRouteParams,
  SubscriptionEnvironmentEnvironmentsRoute,
  SubscriptionEnvironmentInviteModalRouteParams,
  SubscriptionEnvironmentInviteUserRoute,
  SubscriptionEnvironmentPreviewConfigurationRouteParams,
  SubscriptionEnvironmentPreviewURLsRoute,
  SubscriptionEnvironmentRoleCreatorRoute,
  SubscriptionEnvironmentRoleEditorRoute,
  SubscriptionEnvironmentRoleEditorRouteParams,
  SubscriptionEnvironmentRolesRoute,
  SubscriptionEnvironmentSettingsRoute,
  SubscriptionEnvironmentSettingsRouteParams,
  SubscriptionEnvironmentSpacesRoute,
  SubscriptionEnvironmentSpacesRouteParams,
  SubscriptionEnvironmentUserDetailRoute,
  SubscriptionEnvironmentUserDetailRouteParams,
  SubscriptionEnvironmentUsersRoute,
  SubscriptionEnvironmentWorkflowCreatingRoute,
  SubscriptionEnvironmentWorkflowEditingRoute,
  SubscriptionEnvironmentWorkflowEditingRouteParams,
  SubscriptionEnvironmentWorkflowsRoute,
  SubscriptionUserDetailRoute,
  SubscriptionUserDetailRouteParams,
  SubscriptionUsersRoute,
  UserDetailRoute,
  UserDetailRouteParams,
  UsersRoute,
  WorkflowCreatingRoute,
  WorkflowEditingRoute,
  WorkflowEditingRouteParams,
  WorkflowsRoute,
  WorkflowsRouteParams,
} from '../../constants/routePaths.ts';
import { buildPath } from './routeTransitionUtils.ts';

type ProjectSubscriptionRouteParams = {
  readonly projectId: Uuid;
  readonly subscriptionId?: Uuid | null;
};

export const createUserListingLink = ({
  projectId,
  subscriptionId,
}: ProjectSubscriptionRouteParams): string =>
  subscriptionId
    ? buildPath<SubscriptionEnvironmentSettingsRouteParams>(SubscriptionEnvironmentUsersRoute, {
        projectId,
        subscriptionId,
      })
    : buildPath<EnvironmentRouteParams>(UsersRoute, { projectId });

export const createInviteLink = ({
  rawEmail,
  projectId,
  subscriptionId,
}: ProjectSubscriptionRouteParams & { readonly rawEmail?: string }): string => {
  const urlEncodedEmail = rawEmail && encodeURIComponent(rawEmail);

  return subscriptionId
    ? buildPath<SubscriptionEnvironmentInviteModalRouteParams>(
        SubscriptionEnvironmentInviteUserRoute,
        { urlEncodedEmail, projectId, subscriptionId },
      )
    : buildPath<InviteModalRouteParams>(InviteUserRoute, { urlEncodedEmail, projectId });
};

export const createUserDetailLink = ({
  projectId,
  subscriptionId,
  userId,
}: ProjectSubscriptionRouteParams & { readonly userId: Uuid }): string =>
  subscriptionId
    ? buildPath<SubscriptionEnvironmentUserDetailRouteParams>(
        SubscriptionEnvironmentUserDetailRoute,
        { projectId, subscriptionId, userId },
      )
    : buildPath<UserDetailRouteParams>(UserDetailRoute, { projectId, userId });

export const createSubscriptionUserListingLink = ({
  subscriptionId,
}: { readonly subscriptionId: Uuid }): string =>
  buildPath<EnsuredSubscriptionRouteParams>(SubscriptionUsersRoute, { subscriptionId });

export const createSubscriptionUserDetailLink = ({
  subscriptionId,
  userId,
}: { readonly subscriptionId: Uuid; readonly userId: Uuid }): string =>
  buildPath<SubscriptionUserDetailRouteParams>(SubscriptionUserDetailRoute, {
    subscriptionId,
    userId,
  });

export const createNewRoleLink = ({
  projectId,
  subscriptionId,
}: ProjectSubscriptionRouteParams): string =>
  subscriptionId
    ? buildPath<SubscriptionEnvironmentSettingsRouteParams>(
        SubscriptionEnvironmentRoleCreatorRoute,
        {
          projectId,
          subscriptionId,
        },
      )
    : buildPath<EnvironmentRouteParams>(RoleCreatorRoute, { projectId });

export const createRoleEditingBackLink = ({
  projectId,
  subscriptionId,
}: ProjectSubscriptionRouteParams): string =>
  subscriptionId
    ? buildPath<SubscriptionEnvironmentSettingsRouteParams>(SubscriptionEnvironmentRolesRoute, {
        projectId,
        subscriptionId,
      })
    : buildPath<EnvironmentRouteParams>(RolesRoute, { projectId });

export const createRoleEditingLink = ({
  projectId,
  subscriptionId,
  roleId,
}: ProjectSubscriptionRouteParams & { roleId: Uuid }): string =>
  subscriptionId
    ? buildPath<SubscriptionEnvironmentRoleEditorRouteParams>(
        SubscriptionEnvironmentRoleEditorRoute,
        {
          projectId,
          roleId,
          subscriptionId,
        },
      )
    : buildPath<RoleEditorRouteParams>(RoleEditorRoute, {
        projectId,
        roleId,
      });

export const createGoToEnvironmentLink = ({
  projectId,
  subscriptionId,
}: ProjectSubscriptionRouteParams): string =>
  subscriptionId
    ? buildPath<SubscriptionEnvironmentSettingsRouteParams>(
        SubscriptionEnvironmentEnvironmentsRoute,
        {
          projectId,
          subscriptionId,
        },
      )
    : buildPath<EnvironmentRouteParams>(EnvironmentsRoute, {
        projectId,
      });

export const createNewWorkflowLink = ({
  projectId,
  subscriptionId,
}: ProjectSubscriptionRouteParams): string =>
  subscriptionId
    ? buildPath<SubscriptionEnvironmentSettingsRouteParams>(
        SubscriptionEnvironmentWorkflowCreatingRoute,
        {
          projectId,
          subscriptionId,
        },
      )
    : buildPath<WorkflowsRouteParams>(WorkflowCreatingRoute, { projectId });

export const createWorkflowEditingLink = ({
  projectId,
  subscriptionId,
  workflowId,
}: ProjectSubscriptionRouteParams & { workflowId: Uuid }): string =>
  subscriptionId
    ? buildPath<SubscriptionEnvironmentWorkflowEditingRouteParams>(
        SubscriptionEnvironmentWorkflowEditingRoute,
        {
          projectId,
          workflowId,
          subscriptionId,
        },
      )
    : buildPath<WorkflowEditingRouteParams>(WorkflowEditingRoute, {
        projectId,
        workflowId,
      });

export const createWorkflowListingLink = ({
  projectId,
  subscriptionId,
}: ProjectSubscriptionRouteParams): string => {
  return subscriptionId
    ? buildPath<SubscriptionEnvironmentSettingsRouteParams>(SubscriptionEnvironmentWorkflowsRoute, {
        projectId,
        subscriptionId,
      })
    : buildPath<WorkflowsRouteParams>(WorkflowsRoute, {
        projectId,
      });
};

export const createSpaceListingLink = ({
  projectId,
  subscriptionId,
}: ProjectSubscriptionRouteParams): string => {
  return subscriptionId
    ? buildPath<SubscriptionEnvironmentSpacesRouteParams>(SubscriptionEnvironmentSpacesRoute, {
        projectId,
        subscriptionId,
      })
    : buildPath<SpacesRouteParams>(SpacesRoute, {
        projectId,
      });
};

export const createPreviewConfigurationLink = ({
  projectId,
  subscriptionId,
}: ProjectSubscriptionRouteParams): string => {
  return subscriptionId
    ? buildPath<SubscriptionEnvironmentPreviewConfigurationRouteParams>(
        SubscriptionEnvironmentPreviewURLsRoute,
        {
          projectId,
          subscriptionId,
        },
      )
    : buildPath<PreviewConfigurationRouteParams>(PreviewURLsRoute, {
        projectId,
      });
};

export const createEnvironmentSettingsLink = ({
  projectId,
  subscriptionId,
}: ProjectSubscriptionRouteParams): string => {
  return subscriptionId
    ? buildPath<SubscriptionEnvironmentSettingsRouteParams>(SubscriptionEnvironmentSettingsRoute, {
        projectId,
        subscriptionId,
      })
    : buildPath<EnvironmentRouteParams>(EnvironmentSettingsRoute, {
        projectId,
      });
};

export const createNewCustomAppLink = ({
  projectId,
  subscriptionId,
}: ProjectSubscriptionRouteParams): string =>
  subscriptionId
    ? buildPath<SubscriptionEnvironmentSettingsRouteParams>(
        SubscriptionEnvironmentCustomAppConfigurationCreatingRoute,
        {
          projectId,
          subscriptionId,
        },
      )
    : buildPath<WorkflowsRouteParams>(CustomAppConfigurationCreatingRoute, { projectId });

export const createCustomAppEditingLink = ({
  projectId,
  subscriptionId,
  customAppId,
}: ProjectSubscriptionRouteParams & { customAppId: Uuid }): string =>
  subscriptionId
    ? buildPath<SubscriptionEnvironmentCustomAppConfigurationEditingRouteParams>(
        SubscriptionEnvironmentCustomAppConfigurationEditingRoute,
        {
          projectId,
          customAppId,
          subscriptionId,
        },
      )
    : buildPath<CustomAppConfigurationEditingRouteParams>(CustomAppConfigurationEditingRoute, {
        projectId,
        customAppId,
      });
