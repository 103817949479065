import React from 'react';
import { colorTextLowEmphasis } from '../../tokens/decision/colors.ts';
import { BaseColor } from '../../tokens/quarks/colors.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { Label } from '../Label/Label.tsx';
import { LabelSize } from '../Label/labelSize.ts';
import { Divider } from './Divider.tsx';
import { DividerDirection } from './components/StyledDivider.tsx';
import {
  StyledSubheaderDivider,
  StyledSubheaderDividerWrapper,
} from './components/StyledSubheaderDivider.tsx';

export interface ISubheaderDividerProps {
  readonly text: string;
}

export const SubheaderDivider: React.FC<ISubheaderDividerProps> = (props) => (
  <StyledSubheaderDividerWrapper aria-hidden>
    <Label size={LabelSize.M} color={colorTextLowEmphasis}>
      {props.text}
    </Label>
    <StyledSubheaderDivider>
      <Divider
        direction={DividerDirection.Horizontal}
        offsetAfter={0}
        offsetBefore={Spacing.S}
        color={BaseColor.Gray40}
      />
    </StyledSubheaderDivider>
  </StyledSubheaderDividerWrapper>
);

SubheaderDivider.displayName = 'SubheaderDivider';
