import { Direction } from '@kontent-ai/types';
import { Collection, delay, identity } from '@kontent-ai/utils';
import { DraftHandleValue, EditorState } from 'draft-js';
import { ClipboardEvent, useCallback, useContext } from 'react';
import { ContentNestingContext } from '../../../itemEditor/features/ContentItemEditing/context/ContentNestingContext.tsx';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import { Apply, PluginState, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { DecorableFunction, Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import { ShouldDeferGrammarlyReplace } from '../../editorCore/utils/editorComponentUtils.ts';
import { isInTable } from '../../utils/blocks/blockTypeUtils.ts';
import {
  doesSelectionContainText,
  getMetadataAtSelection,
} from '../../utils/editorSelectionUtils.ts';
import { EditorFeatureLimitations } from '../apiLimitations/api/EditorFeatureLimitations.ts';
import { TopLevelBlockCategoryFeature } from '../apiLimitations/api/editorLimitationUtils.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { KeyboardShortcutsPlugin } from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { TextApiPlugin } from '../textApi/TextApiPlugin.tsx';
import { WrapperPlugin } from '../visuals/WrapperPlugin.tsx';
import { EditorClipboardApi } from './api/EditorClipboardApi.type.ts';
import { editorClipboardApi } from './api/editorClipboardApi.ts';
import { PasteContentToRichTextParams } from './thunks/pasteContentToRichText.ts';

export type OnPasteContent = (
  editorState: EditorState,
  params: PasteContentToRichTextParams,
) => Promise<EditorState>;
export type SetRichTextClipboard = (e: ClipboardEvent, editorState: EditorState) => void;

type ClipboardPluginProps = {
  readonly onPasteContent?: OnPasteContent;
  readonly setRichTextClipboard: SetRichTextClipboard;
};

export type PostProcessPastedContent = (editorState: EditorState) => EditorState;

type ClipboardPluginState = {
  readonly postProcessPastedContent: DecorableFunction<PostProcessPastedContent>;
};

export type ClipboardPlugin = DraftJsEditorPlugin<
  ClipboardPluginState,
  ClipboardPluginProps,
  EditorClipboardApi,
  None,
  [TextApiPlugin, WrapperPlugin, KeyboardShortcutsPlugin<unknown>]
>;

const EditorWithClipboardHandling: DecoratedEditor<ClipboardPlugin> = ({
  baseRender,
  state,
  onPasteContent,
  setRichTextClipboard,
}) => {
  const {
    canUpdateContent,
    executeChange,
    executeExternalAction,
    getApi,
    getEditorState,
    getIsShiftPressed,
    postProcessPastedContent,
  } = state;

  const { nestedLevel } = useContext(ContentNestingContext);

  const copySelectionToClipboard = useCallback(
    (event: ClipboardEvent<Element>) => {
      // Do not handle for inputs placed inside the editor as we need copy to work in link editing dialogs. See KCL-4040 for more details
      if ((event.target as HTMLElement).tagName.toLowerCase() === 'input') {
        return;
      }
      setRichTextClipboard(event, getEditorState());
    },
    [setRichTextClipboard, getEditorState],
  );

  const cutSelectionToClipboard = useCallback(
    (event: ClipboardEvent<Element>) => {
      // Do not handle for inputs placed inside the editor as we need cut to work in link editing dialogs. See KCL-4040 for more details
      if ((event.target as HTMLElement).tagName.toLowerCase() === 'input') {
        return;
      }
      event.stopPropagation(); // to prevent editor from remounting all its content upon cut - KCL-3413
      event.preventDefault(); // to prevent editor from cutting content when the whole block is selected

      executeChange((editorState) => {
        const selection = editorState.getSelection();

        if (canUpdateContent() && selection.getHasFocus() && !selection.isCollapsed()) {
          setRichTextClipboard(event, editorState);

          const newEditorState = getApi().handleDeleteAtSelection(
            editorState,
            selection,
            Direction.Backward,
          ).editorState;
          return newEditorState;
        }
        return editorState;
      });
    },
    [canUpdateContent, executeChange, getApi, setRichTextClipboard],
  );

  const handlePastedPlainText = useCallback(
    (text: string | undefined): DraftHandleValue => {
      executeChange((editorState) => {
        const selection = editorState.getSelection();
        if (selection.getHasFocus() && canUpdateContent()) {
          // Do not allow pasting if target doesn't allow text at all to keep the behavior consistent with pasteContentToRichText thunk action
          const targetLimitations = getTargetLimitations(editorState, getApi().getLimitations());
          if (!targetLimitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Text)) {
            return editorState;
          }

          const newEditorState = getApi().insertNewChars(editorState, text || '', true);
          if (newEditorState === editorState) {
            return editorState;
          }
          return postProcessPastedContent(newEditorState);
        }
        return editorState;
      });
      return 'handled';
    },
    [canUpdateContent, executeChange, getApi, postProcessPastedContent],
  );

  const handlePastedText = useCallback(
    (text: string | undefined, html: string | undefined): DraftHandleValue => {
      const isPlainText = !html && !text?.includes('\n');
      if (!onPasteContent || isPlainText) {
        // In case of pasting plain text, we don't want to go via async external update as the paste may be a replacement from grammar checker
        // such as Grammarly and doing it asynchronously may cause race condition upon DOM selection update
        return handlePastedPlainText(text);
      }

      const isShiftPressed = getIsShiftPressed();

      executeExternalAction(async (editorState) => {
        const selection = editorState.getSelection();
        if (selection.getHasFocus() && canUpdateContent()) {
          const usePlainText = isShiftPressed || !html;
          const content = usePlainText ? text : html;
          const targetLimitations = getTargetLimitations(editorState, getApi().getLimitations());

          const pasteContentParams: PasteContentToRichTextParams = {
            isPlainText: usePlainText,
            limitations: targetLimitations,
            nestedLevel: nestedLevel || 0,
            pasteContent: getApi().pasteContent,
            pastedHtmlString: content || '',
            pastedPlainText: text || '',
            selection,
          };

          return postProcessPastedContent(await onPasteContent(editorState, pasteContentParams));
        }

        return editorState;
      });

      return 'handled';
    },
    [
      canUpdateContent,
      executeExternalAction,
      getApi,
      getIsShiftPressed,
      handlePastedPlainText,
      nestedLevel,
      onPasteContent,
      postProcessPastedContent,
    ],
  );

  const deferredHandlePastedText = useCallback(
    (text: string | undefined, html: string | undefined): DraftHandleValue => {
      delay(0).then(() => handlePastedText(text, html));
      return 'handled';
    },
    [handlePastedText],
  );

  const stateWithClipboardEvents: PluginState<ClipboardPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      handlePastedText: ShouldDeferGrammarlyReplace ? deferredHandlePastedText : handlePastedText,
    },
    wrapperProps: {
      ...state.wrapperProps,
      onCopyCapture: copySelectionToClipboard,
      onCutCapture: cutSelectionToClipboard,
    },
  };

  return baseRender(stateWithClipboardEvents);
};

EditorWithClipboardHandling.displayName = 'EditorWithClipboardHandling';

export const ClipboardPlugin: PluginComponent<ClipboardPlugin> = (props) => {
  const { onPasteContent, setRichTextClipboard } = props;

  const render: Decorator<Render<ClipboardPlugin>> = useCallback(
    (baseRender) => (state) => (
      <EditorWithClipboardHandling
        baseRender={baseRender}
        onPasteContent={onPasteContent}
        setRichTextClipboard={setRichTextClipboard}
        state={state}
      />
    ),
    [onPasteContent, setRichTextClipboard],
  );

  const apply: Apply<ClipboardPlugin> = useCallback(
    (state) => {
      state.render.decorate(render);

      return {
        postProcessPastedContent: decorable(identity<EditorState>),
      };
    },
    [render],
  );

  const { getApiMethods } = useEditorApi<ClipboardPlugin>(editorClipboardApi);

  return useEditorWithPlugin(props, { apply, getApiMethods });
};

const transformToTableContentLimitations = (
  limitations: EditorFeatureLimitations,
): EditorFeatureLimitations => ({
  ...limitations,
  allowedBlocks: limitations.allowedTableBlocks,
  allowedTextBlocks: limitations.allowedTableTextBlocks,
  allowedTextFormatting: limitations.allowedTableTextFormatting,
});

const getTargetLimitations = (
  editorState: EditorState,
  limitations: EditorFeatureLimitations,
): EditorFeatureLimitations => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();

  const targetBlockKey = selection.getStartKey();
  const targetBlock = content.getBlockForKey(targetBlockKey);
  const pastingToTable = isInTable(targetBlock);

  const useLimitations = pastingToTable
    ? // When we are pasting to table, we use limitation for table contents because top level blocks may be more restrictive,
      // and we want to keep whatever is allowed in a table cell
      transformToTableContentLimitations(limitations)
    : limitations;

  // Text may be disallowed at the target, but in case there already is some (invalid) text we still want to allow pasting it
  // because we need grammar check to be able to apply an approved fix. It won't make the situation worse regarding invalid content.
  const metadataAtSelection = getMetadataAtSelection(content, selection);
  const selectionContainsText = doesSelectionContainText(selection, metadataAtSelection);

  if (
    selectionContainsText &&
    !useLimitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Text)
  ) {
    return {
      ...useLimitations,
      allowedBlocks: Collection.add(
        useLimitations.allowedBlocks,
        TopLevelBlockCategoryFeature.Text,
      ),
    };
  }

  return useLimitations;
};
