import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Spacing } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import React, { useState } from 'react';
import { NoUsage } from '../../../../../../_shared/components/Usages/NoUsage.tsx';
import {
  DataUiCollection,
  ItemDetailSection,
  getDataUiCollectionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { UsageItemReference } from '../../../../../../_shared/utils/usage/usageUtils.ts';
import { SingleContentItemUsage } from '../../containers/details/SingleContentItemUsage.tsx';
import { SectionTitle } from './SectionTitle.tsx';

export interface IContentItemUsageStateProps {
  readonly contentItemId: Uuid;
  readonly usages: Immutable.OrderedMap<Uuid, UsageItemReference>;
}

const DefaultUsagesDisplayed = 3;

const Usages: React.FC<IContentItemUsageStateProps> = (props) => {
  const { contentItemId, usages } = props;

  const [showAll, setShowAll] = useState(false);

  const areAllUsagesDisplayed = showAll || usages.size <= DefaultUsagesDisplayed;
  const usagesToDisplay = areAllUsagesDisplayed ? usages : usages.take(DefaultUsagesDisplayed);

  return (
    <div {...getDataUiCollectionAttribute(DataUiCollection.ContentItemUsages)}>
      {usagesToDisplay
        .valueSeq()
        .toArray()
        .map((usage) => (
          <SingleContentItemUsage
            key={usage.primary.id.itemId}
            contentItemId={contentItemId}
            usage={usage}
          />
        ))}
      {!areAllUsagesDisplayed && (
        <Box marginLeft={Spacing.XXL} marginTop={Spacing.S}>
          <Button
            aria-label={`Show all ${usages.size} usages`}
            buttonStyle="secondary"
            size="small"
            onClick={() => setShowAll(true)}
          >
            Show all ({usages.size})
          </Button>
        </Box>
      )}
    </div>
  );
};

Usages.displayName = 'Usages';

export const UsagesSection: React.FC<IContentItemUsageStateProps> = (props) => {
  return (
    <div
      className="sidebar__section sidebar__section--contains-hover"
      {...getDataUiObjectNameAttribute(ItemDetailSection.Usage)}
    >
      <Box paddingX={Spacing.XXL}>
        <SectionTitle>Used in</SectionTitle>
      </Box>
      {props.usages.size ? (
        <Usages {...props} />
      ) : (
        <NoUsage>This content item is not used in any other content item.</NoUsage>
      )}
    </div>
  );
};

UsagesSection.displayName = 'ContentItemUsage';
