import { Button, QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useAttachRef } from '@kontent-ai/hooks';
import classNames from 'classnames';
import { HandleClickOutside } from '../../../../../../_shared/components/HandleClickOutside.tsx';
import {
  DataUiCommentsAction,
  DataUiElement,
  getDataUiCommentActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
} from '../../../../../../_shared/utils/forwardedRefProps.tsx';
import { CommentThreadCssClass } from '../../constants/uiConstants.ts';

type ResolveUndoDialogProps = IForwardedRefProps<HTMLDivElement> & {
  readonly isSuggestionThread: boolean;
  readonly onResolveUndo: () => void;
  readonly onResolveUndoCancel: () => void;
};

const _ResolveUndoDialog: React.FC<ResolveUndoDialogProps> = (props) => {
  const { refObject, refToForward } = useAttachRef(props.forwardedRef);

  return (
    <HandleClickOutside observedRefs={refObject} onClickOutside={props.onResolveUndoCancel}>
      <div
        className={classNames(CommentThreadCssClass, 'comment-thread__undo-dialog')}
        ref={refToForward}
        {...getDataUiElementAttribute(DataUiElement.CommentThread)}
      >
        <Inline align="center" spacingX={Spacing.L}>
          {props.isSuggestionThread ? 'Suggestion' : 'Comment'} resolved
          <Button
            buttonStyle="tertiary"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              props.onResolveUndo();
            }}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.ResolveUndo)}
          >
            Undo
          </Button>
        </Inline>
        <QuinaryButton
          tooltipText="Close"
          tooltipPlacement="top-start"
          onClick={props.onResolveUndoCancel}
          {...getDataUiCommentActionAttribute(DataUiCommentsAction.ResolveUndoCancel)}
        >
          <QuinaryButton.Icon icon={Icons.Times} />
        </QuinaryButton>
      </div>
    </HandleClickOutside>
  );
};

_ResolveUndoDialog.displayName = 'ResolveUndoDialog';

export const ResolveUndoDialog = forwardRef(_ResolveUndoDialog);
