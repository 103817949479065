import { useAttachRef } from '@kontent-ai/hooks';
import { mergeProps } from '@react-aria/utils';
import React, { PropsWithChildren, useId, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { Box } from '../../../layout/Box/Box.tsx';
import { Column } from '../../../layout/Row/Column.tsx';
import { Row } from '../../../layout/Row/Row.tsx';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { colorIconDefault, colorIconDefaultInverse } from '../../../tokens/decision/colors.ts';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { QuinaryButton } from '../../Button/QuinaryButton/QuinaryButton.tsx';
import { QuinaryButtonStyle } from '../../Button/QuinaryButton/QuinaryButtonStyle.ts';
import { Icons } from '../../Icons/components/icons.ts';
import { NotificationBarType } from '../notificationBarType.ts';
import { StyledNotificationBarContent } from './StyledNotificationBarContent.tsx';
import { StyledNotificationBarWrapper } from './StyledNotificationBarWrapper.tsx';

const notificationBarActionsAlignmentValues = ['left', 'right'] as const;
export type NotificationBarActionsAlignment =
  (typeof notificationBarActionsAlignmentValues)[number];

export type NotificationBarActions = {
  readonly alignment: NotificationBarActionsAlignment;
  readonly renderActions: () => React.ReactNode;
};

export type NotificationBarProps = PropsWithChildren<{
  readonly actions?: NotificationBarActions;
  readonly hideIcon?: boolean;
  readonly isFloating?: boolean;
  readonly onDismiss?: () => void;
}>;

type BaseNotificationBarProps = NotificationBarProps & {
  readonly notificationBarType: NotificationBarType;
};

const getIconColor = (notificationBarType: NotificationBarType) => {
  switch (notificationBarType) {
    case 'Alert':
    case 'Success':
      return colorIconDefaultInverse;
    case 'Info':
    case 'Warning':
    case 'FriendlyWarning':
      return colorIconDefault;
    case 'InfoEmphasis':
    case 'Blank':
      return undefined;
  }
};

const getIcon = (notificationBarType: NotificationBarType) => {
  switch (notificationBarType) {
    case 'Success':
      return Icons.CbCheck;
    case 'Warning':
    case 'FriendlyWarning':
      return Icons.ExclamationTriangle;
    case 'Alert':
      return Icons.ExclamationTriangleInverted;
    case 'Info':
      return Icons.ICircle;
    case 'InfoEmphasis':
    case 'Blank':
      return null;
  }
};

const getQuinaryButtonStyle = (notificationBarType: NotificationBarType): QuinaryButtonStyle => {
  switch (notificationBarType) {
    case 'Alert':
    case 'InfoEmphasis':
    case 'Success':
      return 'inverse';
    case 'Warning':
    case 'Info':
    case 'FriendlyWarning':
    case 'Blank':
      return 'default';
  }
};

const widthWrapBreakpoint = 600;

export const BaseNotificationBar = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<BaseNotificationBarProps>
>(
  (
    { children, isFloating, hideIcon, onDismiss, actions, notificationBarType, ...otherProps },
    forwardedRef,
  ) => {
    const [shouldWrap, setShouldWrap] = useState<boolean>(false);
    const [areActionsAriaHidden, setAreActionsAriaHidden] = useState(true);

    const { refToForward, refObject } = useAttachRef(forwardedRef);

    useResizeObserver({
      ref: refObject,
      onResize: ({ width }) => {
        if (width) {
          setShouldWrap(width <= widthWrapBreakpoint);
        }
      },
    });

    const { isFocusVisible, focusProps } = useOurFocusRing();

    const messageId = useId();

    // Prevents screen reader from reading buttons when notification is shown, since just name of the button without context is announced
    const actionProps = {
      'aria-hidden': areActionsAriaHidden,
    };

    const notificationProps = {
      onFocus: () => setAreActionsAriaHidden(false),
      onBlur: () => setAreActionsAriaHidden(true),
    };

    const Icon = getIcon(notificationBarType);

    return (
      <StyledNotificationBarWrapper
        $type={notificationBarType}
        $isFocusVisible={isFocusVisible}
        floating={isFloating}
        ref={refToForward}
        aria-labelledby={messageId}
        tabIndex={0}
        {...mergeProps(notificationProps, otherProps, focusProps)}
      >
        <Box paddingY={Spacing.S} paddingRight={Spacing.M} paddingLeft={Spacing.L}>
          <Row alignY="center" noWrap>
            {Icon && !hideIcon && (
              <Column width="content">
                <Box paddingRight={Spacing.S}>
                  <Icon
                    size={IconSize.S}
                    color={getIconColor(notificationBarType)}
                    aria-hidden="true"
                  />
                </Box>
              </Column>
            )}

            <Column>
              <Box display="flex" alignItems="center" paddingRight={Spacing.M}>
                <StyledNotificationBarContent id={messageId}>
                  {children}
                </StyledNotificationBarContent>
                {actions && !shouldWrap && actions.alignment === 'left' && (
                  <Box paddingLeft={Spacing.M} {...actionProps}>
                    {actions.renderActions()}
                  </Box>
                )}
              </Box>
            </Column>
            <SrOnly>{notificationBarType} Notification</SrOnly>

            {actions && actions.alignment === 'right' && (
              <Column width="content">
                <Box paddingRight={Spacing.M} {...actionProps}>
                  {actions.renderActions()}
                </Box>
              </Column>
            )}

            {onDismiss && (
              <Column
                width="content"
                css={`
                  align-self: ${shouldWrap ? 'flex-start' : 'center'};
                `}
              >
                <QuinaryButton
                  buttonStyle={getQuinaryButtonStyle(notificationBarType)}
                  tooltipPlacement="top"
                  onClick={onDismiss}
                  tooltipText="Dismiss"
                  {...actionProps}
                >
                  <QuinaryButton.Icon
                    icon={Icons.ModalClose}
                    screenReaderText="Dismiss notification"
                  />
                </QuinaryButton>
              </Column>
            )}
          </Row>
          {actions && actions.alignment === 'left' && shouldWrap && (
            <Row noWrap alignX="center">
              <Column width="content">
                <Box paddingTop={Spacing.M} {...actionProps}>
                  {actions.renderActions()}
                </Box>
              </Column>
            </Row>
          )}
        </Box>
      </StyledNotificationBarWrapper>
    );
  },
);

BaseNotificationBar.displayName = 'BaseNotificationBar';
