import { areShallowEqual } from '@kontent-ai/utils';
import {
  Dispatch,
  GetState,
  ThunkFunction,
} from '../../../../../../../../@types/Dispatcher.type.ts';
import { Data_ListingContentItems_FullTextSearchTurnedOff } from '../../../../../../../../data/constants/dataActionTypes.ts';
import {
  initialContentListingOrderBy,
  notSpecifiedContentListingOrderBy,
} from '../../../../../reducers/listingUi/reducers/orderBy.ts';
import { UpdateListingOrderBy } from '../updateListingOrderBy.ts';

const fullTextSearchTurnedOff = () =>
  ({
    type: Data_ListingContentItems_FullTextSearchTurnedOff,
  }) as const;

export type HandleFullTextSearchTurnedOffActionsType = ReturnType<typeof fullTextSearchTurnedOff>;

interface IDeps {
  readonly updateListingOrderBy: UpdateListingOrderBy;
}

export const createHandleFullTextSearchTurnedOffAction =
  (deps: IDeps) =>
  (isInDialog: boolean): ThunkFunction =>
  (dispatch: Dispatch, getState: GetState) => {
    const {
      contentApp: {
        listingUi: { fullTextSearchContext, orderBy },
      },
    } = getState();

    dispatch(fullTextSearchTurnedOff());

    //  We can not compare object references because orderBy can be loaded from local storage too
    if (!areShallowEqual(orderBy, notSpecifiedContentListingOrderBy)) {
      return;
    }

    const updatedOrderBy = fullTextSearchContext?.previousOrderBy
      ? fullTextSearchContext.previousOrderBy
      : initialContentListingOrderBy;

    dispatch(deps.updateListingOrderBy(updatedOrderBy, !isInDialog));
  };
