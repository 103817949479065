import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { emptySelectionItem } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { memoize } from '@kontent-ai/memoization';
import { Collection, notNullNorUndefined } from '@kontent-ai/utils';
import React from 'react';
import { MultipleChoiceMode } from '../../../../../../../_shared/models/MultipleChoiceModeEnum.ts';
import { IMultipleChoiceOptionData } from '../../../../../../../_shared/models/MultipleChoiceOption.type.ts';
import { getOrderedOptions } from '../../../../../../../_shared/selectors/multipleChoiceOptions.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IMultipleChoiceTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import { IMultipleChoiceItemElement } from '../../../../../models/contentItemElements/MultipleChoiceItemElement.ts';

const noneOption: IMultipleChoiceOptionData = {
  ...emptySelectionItem,
  codename: '',
};

const addNoneOption = memoize.weak(
  (options: ReadonlyArray<IMultipleChoiceOptionData>): ReadonlyArray<IMultipleChoiceOptionData> => [
    noneOption,
    ...options,
  ],
);

export interface ICompactMultipleChoiceInputStateProps {
  readonly disabled: boolean;
  readonly elementData: IMultipleChoiceItemElement;
  readonly onUpdateSelectedOptions: (optionIds: readonly Uuid[]) => void;
  readonly typeElement: IMultipleChoiceTypeElement;
}

type CompactMultipleChoiceInputProps = ICompactMultipleChoiceInputStateProps;

export const CompactMultipleChoiceInput: React.FC<CompactMultipleChoiceInputProps> = ({
  disabled,
  elementData,
  onUpdateSelectedOptions,
  typeElement,
}) => {
  const isMultipleChoiceSingleMode = typeElement.mode === MultipleChoiceMode.Single;
  const orderedOptions = getOrderedOptions(typeElement.options, typeElement.optionsOrder);

  return isMultipleChoiceSingleMode ? (
    <SingleSelect
      aria-label="Select an option"
      inputState={disabled ? InputState.ReadOnly : InputState.Default}
      items={addNoneOption(orderedOptions)}
      onSelectionChange={(optionId) =>
        onUpdateSelectedOptions([optionId].filter(notNullNorUndefined))
      }
      placeholder="Select an option"
      selectedItemId={Collection.getFirst(elementData.options) ?? null}
    />
  ) : (
    <MultiSelect
      aria-label="Select an option"
      inputState={disabled ? InputState.ReadOnly : InputState.Default}
      items={orderedOptions}
      onSelectionChange={(optionIds) => onUpdateSelectedOptions([...optionIds])}
      placeholder={disabled ? 'There is no option selected' : 'Select options'}
      selectedItemIds={elementData.options}
      {...getDataUiCollectionAttribute(DataUiCollection.MultipleChoiceOptions)}
    />
  );
};

CompactMultipleChoiceInput.displayName = 'CompactMultipleChoiceInput';
