import { Inline } from '@kontent-ai/component-library/Inline';
import { Input, InputState } from '@kontent-ai/component-library/Input';
import { OptionalTooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useState } from 'react';
import {
  BarItemActionButtons,
  DestructiveBarItemAction,
} from '../../../../../_shared/components/BarItems/BarItemActionButtons.tsx';
import { BarItemExpandedSimpleHeader } from '../../../../../_shared/components/BarItems/BarItemExpandedSimpleHeader.tsx';
import { BarItemTitleTag } from '../../../../../_shared/components/BarItems/BarItemTitleTag.tsx';
import { HotkeysHandler } from '../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { ShortcutSymbols } from '../../../../../_shared/constants/shortcutSymbols.ts';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { DefaultCollectionId } from '../../../../../_shared/constants/variantIdValues.ts';
import {
  DataUiAction,
  DataUiElement,
  DataUiInput,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import { TagColor } from '../../../../../data/constants/tagColor.ts';
import { emptyNameErrorMessage } from '../../constants/UIConstants.tsx';
import { CollectionExpandedFormSpaces } from '../../containers/CollectionExpandedFormSpaces.tsx';
import { CollectionWorkflowLimitations } from '../../containers/CollectionWorkflowLimitations.tsx';

type Props = {
  readonly collectionId: Uuid | undefined;
  readonly destructiveAction?: DestructiveBarItemAction;
  readonly name: string;
  readonly onCancel: () => void;
  readonly onNameChange: (name: string) => void;
  readonly onSubmit: (collectionName: string) => void;
  readonly showSpaces: boolean;
};

const createErrorMessage = (name: string) => {
  if (isEmptyOrWhitespace(name)) {
    return emptyNameErrorMessage;
  }

  return undefined;
};

export const CollectionExpandedForm: React.FC<Props> = ({
  collectionId,
  destructiveAction,
  name,
  onCancel,
  onNameChange,
  onSubmit,
  showSpaces,
}) => {
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false);
  const errorMessage = createErrorMessage(name);
  const isErrorDisplayed = shouldShowErrorMessage && !!errorMessage;
  const onSubmitHandler = errorMessage
    ? () => setShouldShowErrorMessage(true)
    : () => onSubmit(name);

  const isDefaultCollection = collectionId === DefaultCollectionId;
  const label = isDefaultCollection ? 'Default collection name' : 'Collection name';

  return (
    <HotkeysHandler
      className="bar-item__wrapper"
      handlers={{
        onEscape: onCancel,
        onEnter: onSubmitHandler,
      }}
      {...getDataUiElementAttribute(DataUiElement.BarItemForm)}
    >
      <div className="bar-item__pane bar-item__pane--is-expanded">
        <BarItemExpandedSimpleHeader
          dataUiObjectName={name}
          isDraggable
          renderTitle={() => (
            <Inline align="center" spacing={Spacing.L} noWrap>
              <OptionalTooltip placement="bottom-start" text={name}>
                {name}
              </OptionalTooltip>
              {isDefaultCollection && (
                <BarItemTitleTag color={TagColor.Gray}>Default collection</BarItemTitleTag>
              )}
            </Inline>
          )}
        />
        <div className="bar-item__expansion">
          <Input
            autoFocus
            caption={isErrorDisplayed ? errorMessage : undefined}
            inputState={isErrorDisplayed ? InputState.Alert : InputState.Default}
            label={label}
            maxLength={ValidationConstants.CollectionNameMaxLength}
            onChange={(e) => onNameChange(e.target.value)}
            value={name}
            {...getDataUiInputAttribute(DataUiInput.Text)}
          />
          {collectionId && <CollectionWorkflowLimitations collectionId={collectionId} />}
          {collectionId && showSpaces && (
            <CollectionExpandedFormSpaces collectionId={collectionId} />
          )}
        </div>
        <BarItemActionButtons
          secondaryAction={{
            handler: onCancel,
            text: 'Cancel',
            dataUIActionName: DataUiAction.Cancel,
            shortcut: ShortcutSymbols.Escape,
          }}
          primaryAction={{
            text: 'Confirm',
            handler: onSubmitHandler,
            dataUIActionName: DataUiAction.Confirm,
            shortcut: ShortcutSymbols.Enter,
            isDisabled: isErrorDisplayed,
            tooltipText:
              shouldShowErrorMessage && !!errorMessage ? emptyNameErrorMessage : undefined,
          }}
          destructiveAction={destructiveAction}
        />
      </div>
    </HotkeysHandler>
  );
};

CollectionExpandedForm.displayName = 'CollectionExpandedForm';
