import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { getDataUiObjectNameAttribute } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SectionTitle } from '../details/SectionTitle.tsx';
import { SectionType } from './sectionTypes/SectionType.tsx';

interface ISectionProps {
  readonly ClickableSectionComponent: React.ComponentType;
  readonly NonClickableSectionComponent: React.ComponentType;
  readonly ReadonlySectionComponent: React.ComponentType;
  readonly title: string;
  readonly type: SectionType;
  readonly uiObjectNameAttribute: string;
}

export const Section: React.FC<ISectionProps> = ({
  ClickableSectionComponent,
  NonClickableSectionComponent,
  ReadonlySectionComponent,
  title,
  type,
  uiObjectNameAttribute,
}) => {
  const getSectionComponent = () => {
    switch (type) {
      case SectionType.Clickable:
        return <ClickableSectionComponent />;
      case SectionType.NonClickable:
        return <NonClickableSectionComponent />;
      case SectionType.Readonly:
        return <ReadonlySectionComponent />;
    }
  };

  return (
    <div
      className="sidebar__section sidebar__section--contains-hover"
      {...getDataUiObjectNameAttribute(uiObjectNameAttribute)}
    >
      <Box paddingX={Spacing.XXL}>
        <SectionTitle>{title}</SectionTitle>
      </Box>
      {getSectionComponent()}
    </div>
  );
};

Section.displayName = 'Section';
