import { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { EditorStatisticsApi } from './api/EditorStatisticsApi.type.ts';
import { IEditorStatistics } from './api/editorStatistics.ts';
import { createEditorStatisticsApi } from './api/editorStatisticsApi.ts';

type ApiStatisticsPluginProps = { readonly statistics: IEditorStatistics | null };

export type ApiStatisticsPlugin = DraftJsEditorPlugin<
  None,
  ApiStatisticsPluginProps,
  EditorStatisticsApi
>;

export const ApiStatisticsPlugin: PluginComponent<ApiStatisticsPlugin> = (props) => {
  const { statistics } = props;
  const api = useMemo(() => createEditorStatisticsApi(statistics), [statistics]);
  const getApiMethods = useCallback(() => api, [api]);

  return useEditorWithPlugin<ApiStatisticsPlugin>(props, { getApiMethods });
};
