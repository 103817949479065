import { Box } from '@kontent-ai/component-library/Box';
import { IconButton } from '@kontent-ai/component-library/Button';
import { IconName } from '@kontent-ai/component-library/Icons';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { BaseColor, BorderRadius } from '@kontent-ai/component-library/tokens';
import React, { CSSProperties } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface SidebarButtonProps {
  readonly activated?: boolean;
  readonly className: string;
  readonly dataUiAction?: DataUiAction;
  readonly iconName: IconName;
  readonly onClick: React.MouseEventHandler;
  readonly style?: CSSProperties;
  readonly tooltipText: string;
}

export const ContentItemSidebarButton: React.FC<SidebarButtonProps> = ({
  activated,
  className,
  dataUiAction,
  iconName,
  onClick,
  style,
  tooltipText,
}) => (
  <Box
    backgroundColor={BaseColor.White}
    borderRadius={BorderRadius.Pill}
    className={className}
    style={style}
  >
    {/* Custom tooltip is used, so that it appears even in activated state */}
    <Tooltip placement="left" tooltipText={tooltipText}>
      <IconButton
        activated={activated}
        buttonStyle="tertiary"
        iconName={iconName}
        onClick={onClick}
        aria-label={tooltipText}
        size="large"
        tooltipPlacement="left"
        tooltipText=""
        {...getDataUiActionAttribute(dataUiAction)}
      />
    </Tooltip>
  </Box>
);
