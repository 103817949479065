import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { ErrorTag } from '@kontent-ai/component-library/Selects';
import { DefaultTag } from '@kontent-ai/component-library/Tag';
import { Collection } from '@kontent-ai/utils';
import React, { useCallback, useMemo } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICollection } from '../../../../data/models/collections/Collection.ts';

interface IProps {
  readonly collections: ReadonlyArray<ICollection>;
  readonly selectedCollectionIds: ReadonlyArray<Uuid>;
  readonly onSelectedIdsChanged: (collectionIds: ReadonlyArray<Uuid>) => void;
}

export const CollectionMultiselect: React.FC<IProps> = ({
  collections,
  selectedCollectionIds,
  onSelectedIdsChanged,
}) => {
  const onChange = useCallback(
    (collectionIds: ReadonlySet<Uuid>) => onSelectedIdsChanged(Array.from(collectionIds)),
    [onSelectedIdsChanged],
  );

  const deletedCollectionIds = useMemo(() => {
    const collectionIds = new Set(collections.map((collection) => collection.id));
    return new Set(
      selectedCollectionIds.filter((collectionId) => !collectionIds.has(collectionId)),
    );
  }, [collections, selectedCollectionIds]);

  const items = useMemo(() => {
    return [
      ...collections.map((collection) => ({
        id: collection.id,
        label: collection.name,
      })),
      ...Collection.getValues(deletedCollectionIds).map((id) => ({
        id,
        label: 'Deleted collection',
        tooltipText: 'This collection was deleted',
      })),
    ];
  }, [collections, deletedCollectionIds]);

  return (
    <MultiSelect
      aria-label="Select collections"
      placeholder="Select collections"
      items={items}
      selectedItemIds={selectedCollectionIds}
      onSelectionChange={onChange}
      disabledItemIds={deletedCollectionIds}
      {...getDataUiCollectionAttribute(DataUiCollection.ContentCollections)}
      renderSelectedOption={(_id, selectedOption, defaultTagProps) =>
        deletedCollectionIds.has(selectedOption.id) ? (
          <ErrorTag {...defaultTagProps} customTooltipText="This collection was deleted" />
        ) : (
          <DefaultTag {...defaultTagProps} />
        )
      }
    />
  );
};
