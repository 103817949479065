import { useAttachRef } from '@kontent-ai/hooks';
import { useHover } from '@react-aria/interactions';
import { useTab } from '@react-aria/tabs';
import { mergeProps } from '@react-aria/utils';
import { AriaTabProps } from '@react-types/tabs';
import React from 'react';
import styled, { css } from 'styled-components';

import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { Box } from '../../../layout/Box/Box.tsx';
import { borderWidthDefault } from '../../../tokens/decision/border.ts';
import {
  colorAccent,
  colorBackgroundDragged,
  colorBackgroundHover,
  colorBackgroundLowEmphasis,
  colorBorderLowEmphasis,
  colorTextDefault,
} from '../../../tokens/decision/colors.ts';
import { Size } from '../../../tokens/decision/spacing.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { overlayColors } from '../../../utils/color.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { RouterLink, RouterLinkProps } from '../../Anchor/Anchor.tsx';
import { OptionalTooltip } from '../../Tooltip/OptionalTooltip.tsx';
import { ConditionalWrapper } from '../../_utils/ConditionalWrapper.tsx';
import { TabItem, useTabViewState } from '../utils.ts';

export const tabHeight = Size.L;
const tabColorBackground = colorBackgroundLowEmphasis;
const tabColorBackgroundHover = overlayColors(colorBackgroundHover, tabColorBackground);
const tabColorBackgroundActive = BaseColor.White;

const TabComponent = styled.div<{
  readonly $centerContent: boolean;
  readonly $isActive: boolean;
  readonly $isAnyDragging: boolean;
  readonly $isFirst: boolean;
  readonly $isFocusVisible?: boolean;
  readonly $isHovered?: boolean;
  readonly $isLast: boolean;
}>`
  position: relative;

  display: flex;
  align-items: center;
  ${({ $centerContent }) =>
    $centerContent &&
    css`
    justify-content: center;
  `};
  
  height: ${px(tabHeight)};
  padding: ${px(Spacing.L)};
  border: ${px(borderWidthDefault)} solid ${BaseColor.Transparent};
  border-bottom: none;
  transition: border-color ${transition250};

  ${({ $isActive }) =>
    $isActive &&
    css`
    border-color: ${colorBorderLowEmphasis};
  `};
  
  background-color: ${tabColorBackground};
  transition: background-color ${transition250};

  color: ${colorTextDefault};
  user-select: none;
  ${Typography.LabelLarge};

  ${({ $isFirst }) =>
    $isFirst &&
    css`
    border-top-left-radius: ${px(BorderRadius.S)};
  `};

  ${({ $isLast }) =>
    $isLast &&
    css`
    border-top-right-radius: ${px(BorderRadius.S)};
  `};

  ${({ $isActive }) =>
    $isActive
      ? css`
      background-color: ${tabColorBackgroundActive};
    `
      : css`
      cursor: pointer;
    `};

  ${({ $isActive, $isAnyDragging, $isHovered }) =>
    !$isActive &&
    !$isAnyDragging &&
    $isHovered &&
    css`
    background-color: ${tabColorBackgroundHover};
  `};

  &:before {
    position: absolute;
    z-index: 1;
    inset: 0;

    content: '';

    border-radius: inherit;
    opacity: 0;
    ${shadowFocusStyles};
    transition: opacity ${transition250};
    pointer-events: none;
  }

  ${({ $isFocusVisible }) =>
    $isFocusVisible &&
    css`
    &:before {
      opacity: 1;
    }
  `}

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;

    border-bottom: ${px(borderWidthDefault)} solid ${BaseColor.Transparent};
    transition: border-color ${transition250};
    pointer-events: none;

    ${({ $isActive }) =>
      $isActive &&
      css`
    border-color: ${tabColorBackgroundActive};
  `};
  }
`;

type CommonTabProps = {
  readonly children?: never;
  readonly ariaDescription?: string;
  readonly isActive?: boolean;
  readonly isAnyDragging?: boolean;
  readonly isFirst?: boolean;
  readonly isLast?: boolean;
  readonly label: string;
  readonly leadingElement?: React.ReactNode;
  readonly trailingElement?: React.ReactNode;
  readonly centerContent?: boolean;
};

type RouterLinkOrDivElement =
  | RouterLinkProps
  | ({ readonly to?: never } & React.HTMLAttributes<HTMLDivElement>);

type TabProps = CommonTabProps & AriaTabProps & RouterLinkOrDivElement;

export const Tab = React.forwardRef<HTMLAnchorElement | HTMLDivElement, TabProps>(
  (
    {
      children,
      centerContent = false,
      isActive = false,
      isAnyDragging = false,
      isDisabled,
      isFirst = false,
      isLast = false,
      label,
      leadingElement,
      to,
      trailingElement,
      ariaDescription,
      ...otherProps
    },
    ref,
  ) => {
    const { isFocusVisible, focusProps } = useOurFocusRing();
    const { isHovered, hoverProps } = useHover({});

    const commonProps = {
      $centerContent: centerContent,
      $isActive: isActive,
      $isFirst: isFirst,
      $isFocusVisible: isFocusVisible,
      $isHovered: isHovered,
      $isLast: isLast,
      'aria-description': ariaDescription,
      ...mergeProps(hoverProps, focusProps, otherProps),
      ...getDataUiComponentAttribute(Tab),
    };

    const tabContent = (
      <ConditionalWrapper
        condition={!!leadingElement || !!trailingElement}
        wrapper={(wrapperChildren) => (
          <Box display="flex" alignItems="center" columnGap={Spacing.S} minWidth={0}>
            {wrapperChildren}
          </Box>
        )}
      >
        {leadingElement && <Box aria-hidden>{leadingElement}</Box>}
        <OptionalTooltip
          placement="bottom"
          text={label}
          visible={isHovered || isFocusVisible}
          customRenderText={(refForTruncatedElement) => (
            <Box
              ref={refForTruncatedElement}
              component="span"
              overflow="hidden"
              paddingY={Spacing.XS}
              css={`
                border-block: 2px solid transparent;
                transition: border-color ${transition250};
                
                ${
                  isActive &&
                  css`
                  border-bottom-color: ${colorAccent};
                `
                }

                text-overflow: ellipsis;
                white-space: nowrap;
            `}
            >
              {label}
            </Box>
          )}
        />
        {trailingElement && <Box aria-hidden>{trailingElement}</Box>}
      </ConditionalWrapper>
    );

    if (to) {
      return (
        <TabComponent
          css={`
          &,
          &:focus,
          &:hover {
            color: ${colorTextDefault};
          }
        `}
          as={RouterLink}
          to={to}
          $isAnyDragging={false}
          {...commonProps}
          ref={ref as React.Ref<HTMLAnchorElement>}
        >
          {tabContent}
        </TabComponent>
      );
    }

    return (
      <TabComponent
        $isAnyDragging={isAnyDragging}
        {...commonProps}
        ref={ref as React.Ref<HTMLDivElement>}
      >
        {tabContent}
      </TabComponent>
    );
  },
);

Tab.displayName = 'Tab';

type TabWithStateProps = {
  readonly children?: never;
  readonly item: TabItem;
};

export const TabWithState = React.forwardRef<HTMLElement, TabWithStateProps>(
  ({ item: { id, ...otherItemProps }, ...otherProps }, ref) => {
    const { refToForward, refObject } = useAttachRef(ref);
    const { fullWidthTabs, tabListState } = useTabViewState();
    const { tabProps } = useTab({ key: id }, tabListState, refObject);
    const { children: tabPropsChildren, 'aria-controls': _, ...restTabProps } = tabProps;

    const isLast = tabListState.collection.getLastKey() === id;
    const isFirst = tabListState.collection.getFirstKey() === id;
    const isActive = tabListState.selectedKey === id;

    return (
      <Tab
        centerContent={fullWidthTabs}
        isActive={isActive}
        isFirst={isFirst}
        isLast={isLast}
        key={id}
        ref={refToForward}
        {...mergeProps(restTabProps, otherProps, otherItemProps)}
      />
    );
  },
);

TabWithState.displayName = 'TabWithState';

/**
 * @deprecated
 * The developer experience is very bad. Contact CL team if you need this
 */
export const DraggableTab = React.forwardRef<
  HTMLElement,
  CommonTabProps &
    AriaTabProps & {
      readonly isDragging: boolean;
    }
>(({ isDragging, ...rest }, ref) => {
  const { refToForward, refObject } = useAttachRef(ref);
  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    if (isDragging) {
      setWidth((prevWidth) => refObject.current?.getBoundingClientRect().width ?? prevWidth);
    }
  }, [refObject, isDragging]);

  if (isDragging) {
    return (
      <Box
        display="flex"
        width={width}
        height={tabHeight}
        backgroundColor={colorBackgroundDragged}
        css={`
          ${
            rest.isFirst &&
            css`
            border-top-left-radius: ${px(BorderRadius.S)};
          `
          }
          ${
            rest.isLast &&
            css`
            border-top-right-radius: ${px(BorderRadius.S)};
          `
          }
        `}
      />
    );
  }

  return <Tab ref={refToForward} {...rest} />;
});

DraggableTab.displayName = 'DraggableTab';
