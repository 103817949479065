import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { TextSkeleton } from '@kontent-ai/component-library/Skeleton';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { WorkflowStatus } from '@kontent-ai/component-library/WorkflowStatus';
import { ConditionalWrapper } from '@kontent-ai/component-library/component-utils';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import { SrOnly } from '@kontent-ai/component-library/styles';
import {
  BorderRadius,
  Spacing,
  Typography,
  colorBackgroundHover,
  colorTextDefault,
  px,
  shadowFocusStyles,
  transition250,
} from '@kontent-ai/component-library/tokens';
import { usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import React from 'react';
import styled, { css } from 'styled-components';
import { IHorizontalStackedBarData } from '../../../types/IHorizontalStackedBarData.type.ts';
import { getDaysAndHoursFromHours } from '../../utils/getDaysAndHoursFromHours.ts';

type WrapperProps = Readonly<{
  isInteractive: boolean;
}>;
const Wrapper = styled.div<WrapperProps>`
  position: relative;
  max-width: 100%;
  padding: ${px(Spacing.XS)};
  padding-right: ${px(Spacing.S)};
  
  ${({ isInteractive }) =>
    isInteractive &&
    css`
    cursor: pointer;
  `};
`;

type StyledButtonProps = Readonly<{
  $isFocusVisible: boolean;
}>;

const StyledButton = styled.button<StyledButtonProps>`
  &::before {
    ${({ $isFocusVisible }) => $isFocusVisible && shadowFocusStyles};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${px(BorderRadius.Pill)};
  }
  
  position: absolute;
  top: 0;
  left: 0;
  
  width: 100%;
  height: 100%;
  border-radius: ${px(BorderRadius.Pill)};
  border-width: 0;
  border-style: solid;
  margin-bottom: 0;

  cursor: pointer;
  background: none;
  outline: none;
  touch-action: manipulation;
  transition: background-color ${transition250};
  transition-property: background-color, color, border-color;

  user-select: none;
  white-space: nowrap;

  &:hover,
  &:focus,
  & {
    outline: none;
    text-decoration: none;
  }

  &:active {
    outline: none;
  }
  

  &:hover,
  ${Wrapper}:hover & {
    background-color: ${colorBackgroundHover};
  }

  ${({ $isFocusVisible }) =>
    $isFocusVisible &&
    css`
    background-color: ${colorBackgroundHover};
  `};
`;

const getTooltipText = (isInteractive: boolean, noData: boolean, buttonText: string): string => {
  if (noData) {
    return 'Not enough activity in this workflow step for the set time frame.';
  }
  if (isInteractive) {
    return buttonText;
  }

  return '';
};

type Props = Readonly<{
  isLoading?: boolean;
  item: IHorizontalStackedBarData;
  onItemClick?: (itemId: string, item: IHorizontalStackedBarData) => void;
  roundedPercentage: number;
}>;

export const LegendItem: React.FC<Props> = ({
  isLoading,
  item,
  onItemClick,
  roundedPercentage,
  ...otherProps
}) => {
  const { isFocusVisible, focusProps } = useOurFocusRing();

  const { pressProps } = usePress({
    onPress: () => onItemClick?.(item.id, item),
  });

  const isInteractive = !!onItemClick;

  if (isLoading) {
    return <TextSkeleton width={100} />;
  }

  const buttonText = `Show the average time spent in the “${item.label}” workflow step.`;

  const noData = item.value === 0 && !item?.isValueRoundedToZero;
  const tooltipText = getTooltipText(isInteractive, noData, buttonText);

  return (
    <Tooltip placement="top" tooltipText={tooltipText}>
      <div>
        <ConditionalWrapper
          condition={isInteractive}
          wrapper={(children) => (
            <Wrapper isInteractive {...mergeProps(pressProps, otherProps)}>
              <StyledButton
                $isFocusVisible={isFocusVisible}
                {...mergeProps(pressProps, focusProps)}
              >
                <SrOnly>{buttonText}</SrOnly>
              </StyledButton>
              {children}
            </Wrapper>
          )}
        >
          <Row spacing={Spacing.S} alignY="center" noWrap>
            <Column width="fit-content">
              <WorkflowStatus
                primary={{
                  background: item.color,
                  name: item.label,
                }}
              />
            </Column>
            <Column width="content">
              <Box typography={Typography.TitleMedium} color={colorTextDefault} whiteSpace="nowrap">
                {getDaysAndHoursFromHours(item.value)} ({roundedPercentage}%)
              </Box>
            </Column>
          </Row>
        </ConditionalWrapper>
      </div>
    </Tooltip>
  );
};

LegendItem.displayName = 'LegendItem';
