import { Collection, areArraysShallowEqual } from '@kontent-ai/utils';
import React, { useCallback } from 'react';
import { TreeList } from '../../../../../../../_shared/components/TreeList/TreeList.tsx';
import { LoadingStatus } from '../../../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  getSelectedTermIdsIncludingDeleted,
  shouldTaxonomyBeCompact,
} from '../../../../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { ITaxonomyGroup } from '../../../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { getDeletedTaxonomyTermObject } from '../../../../../../contentModels/taxonomy/utils/deletedTaxonomyUtils.ts';
import { hasDefaultValue } from '../../../utils/defaultValueUtils.ts';
import { ContentItemElementStatus } from '../ContentItemElementStatus.tsx';
import { DefaultValueStatus } from '../subComponents/limitInfoMessages/DefaultValueStatus.tsx';
import {
  ItemElementLimitType,
  ItemLimitStatusMessage,
} from '../subComponents/limitInfoMessages/ItemLimitStatusMessage.tsx';
import { CompactTaxonomySelector } from './CompactTaxonomySelector.tsx';
import {
  IGetTaxonomySelectorNodeClassNameInput,
  TaxonomySelectorNode,
} from './TaxonomySelectorNode.tsx';

export enum TaxonomySelectorView {
  Auto = 'auto',
  Compact = 'compact',
  Tree = 'tree',
}

interface ITaxonomyProps {
  readonly autoFocus?: boolean;
  readonly disabled: boolean;
  readonly getNodeClassName?: (input: IGetTaxonomySelectorNodeClassNameInput) => string;
  readonly selectedTermIds: ReadonlySet<Uuid>;
  readonly tooltipText?: string;
  readonly view?: TaxonomySelectorView;
}

export interface ITaxonomySelectorOwnProps extends ITaxonomyProps {
  readonly defaultValue: readonly Uuid[];
  readonly isNumberOfTermsLimitMet?: boolean;
  readonly isInRevisions?: boolean;
  readonly maxItems: number | null;
  readonly minItems: number | null;
  readonly onTermSelectionChanged?: (termIds: ReadonlySet<Uuid>) => void;
}

export interface ITaxonomySelectorDataProps {
  readonly taxonomyGroup: ITaxonomyGroup | null;
  readonly taxonomyGroupsLoadingStatus: LoadingStatus;
}

type TaxonomySelectorProps = ITaxonomySelectorDataProps & ITaxonomySelectorOwnProps;

export const TaxonomySelector: React.FC<TaxonomySelectorProps> = ({
  disabled,
  defaultValue,
  isNumberOfTermsLimitMet,
  isInRevisions,
  maxItems,
  minItems,
  onTermSelectionChanged,
  selectedTermIds,
  ...otherProps
}) => {
  const onTermToggled = useCallback(
    (termId: Uuid): void => {
      if (onTermSelectionChanged) {
        onTermSelectionChanged(Collection.togglePresence(selectedTermIds, termId));
      }
    },
    [onTermSelectionChanged, selectedTermIds],
  );

  const onSelectionChanged = useCallback(
    (newTermIds: ReadonlySet<Uuid>): void => {
      if (onTermSelectionChanged) {
        onTermSelectionChanged(newTermIds);
      }
    },
    [onTermSelectionChanged],
  );

  const isNumberOfItemsLimited = !!minItems || !!maxItems;
  const isDefaultValueSet = hasDefaultValue(defaultValue);
  const isCurrentValueDefault = isDefaultValueSet
    ? areArraysShallowEqual<readonly string[]>([...selectedTermIds], defaultValue)
    : false;

  return (
    <div className="taxonomy-element">
      <Taxonomy
        disabled={disabled}
        onSelectionChanged={onSelectionChanged}
        onTermToggled={onTermToggled}
        selectedTermIds={selectedTermIds}
        {...otherProps}
      />
      <DefaultValueStatus
        isStatusRendered={isDefaultValueSet && !disabled}
        isValueDefault={isCurrentValueDefault}
      />
      {isNumberOfItemsLimited && !isInRevisions && (
        <ContentItemElementStatus>
          <div className="content-item-element__status-pane">
            <span>Element parameters: </span>
            <span className="content-item-element__status-segment">
              <ItemLimitStatusMessage
                min={minItems}
                max={maxItems}
                type={ItemElementLimitType.TaxonomyItemCount}
                isLimitValueMet={!!isNumberOfTermsLimitMet}
              />
            </span>
          </div>
        </ContentItemElementStatus>
      )}
    </div>
  );
};

TaxonomySelector.displayName = 'TaxonomySelector';

type TaxonomyOwnProps = {
  readonly onSelectionChanged: (newTermIds: ReadonlySet<Uuid>) => void;
  readonly onTermToggled: (termId: Uuid) => void;
};

const Taxonomy: React.FC<ITaxonomyProps & ITaxonomySelectorDataProps & TaxonomyOwnProps> = ({
  autoFocus,
  disabled,
  getNodeClassName,
  onSelectionChanged,
  onTermToggled,
  selectedTermIds,
  taxonomyGroup,
  taxonomyGroupsLoadingStatus,
  tooltipText,
  view = TaxonomySelectorView.Auto,
}) => {
  if (taxonomyGroupsLoadingStatus !== LoadingStatus.Loaded) {
    return (
      <div className="taxonomy-element__message taxonomy-element__message--is-loading">
        Loading taxonomy group
      </div>
    );
  }

  const isTaxonomyEmpty = !taxonomyGroup?.childIds.length;
  if (isTaxonomyEmpty) {
    return (
      <div className="taxonomy-element__message">
        {isTaxonomyEmpty
          ? 'This taxonomy group is empty.'
          : 'This taxonomy group has been deleted.'}
      </div>
    );
  }

  const compactView = (
    <CompactTaxonomySelector
      disabled={disabled}
      onSelectionChange={disabled ? undefined : onSelectionChanged}
      selectedTermIds={selectedTermIds}
      taxonomyGroup={taxonomyGroup}
      tooltipText={tooltipText}
    />
  );

  const treeView = (
    <TreeList>
      {getSelectedTermIdsIncludingDeleted(taxonomyGroup, selectedTermIds).map(
        (termId: Uuid, index) => (
          <TaxonomySelectorNode
            autoFocus={autoFocus && index === 0}
            disabled={disabled}
            getNodeClassName={getNodeClassName}
            isDeleted={!taxonomyGroup.childIds.includes(termId)}
            key={termId}
            onClick={disabled ? undefined : onTermToggled}
            selectedTermIds={selectedTermIds}
            taxonomyGroup={taxonomyGroup}
            term={taxonomyGroup.terms.get(termId) ?? getDeletedTaxonomyTermObject(termId)}
            tooltipText={tooltipText}
          />
        ),
      )}
    </TreeList>
  );

  switch (view) {
    case TaxonomySelectorView.Compact:
      return compactView;
    case TaxonomySelectorView.Tree:
      return treeView;
    case TaxonomySelectorView.Auto: {
      return shouldTaxonomyBeCompact(taxonomyGroup) ? compactView : treeView;
    }
  }
};
