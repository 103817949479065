import { forwardRef } from 'react';
import {
  CommentThreadItemType,
  ICommentThreadItemContentModel,
} from '../../../../models/comments/CommentThreadItem.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import {
  NewCommentThreadItem,
  NewCommentThreadItemHandle,
} from './threadItem/NewCommentThreadItem.tsx';
import { NewCommentThreadItemReplyOptions } from './threadItem/NewCommentThreadItemReplyOptions.tsx';

type Props = {
  readonly canSuggest: boolean;
  readonly commentThread: ICommentThread;
  readonly isResolved: boolean;
  readonly onBlurNewItemText: (isCommentPending: boolean) => void;
  readonly onCancelNewItem: () => void;
  readonly onStartNewItem: (type: CommentThreadItemType) => void;
  readonly onSubmitNewItem: (
    type: CommentThreadItemType,
    content: ICommentThreadItemContentModel,
  ) => Promise<void>;
  readonly type: CommentThreadItemType | undefined;
};

const isNewCommentThreadItem = (
  commentThread: ICommentThread,
  type?: CommentThreadItemType,
): type is CommentThreadItemType =>
  !!type && (commentThread.isReplying || commentThread.isSubmitting);

export const Reply = forwardRef<NewCommentThreadItemHandle, Props>((props, ref) => {
  if (isNewCommentThreadItem(props.commentThread, props.type)) {
    return (
      <div className="comment-editor__reply">
        <NewCommentThreadItem
          ref={ref}
          elementSegment={props.commentThread.elementSegment}
          inputValue=""
          isEditing={props.commentThread.isReplying}
          isSubmitting={props.commentThread.isSubmitting}
          onBlur={props.onBlurNewItemText}
          onCancel={props.onCancelNewItem}
          onSubmit={props.onSubmitNewItem}
          type={props.type}
        />
      </div>
    );
  }

  return (
    <NewCommentThreadItemReplyOptions
      canSuggest={props.canSuggest && !props.isResolved}
      onNewSuggestion={() => props.onStartNewItem(CommentThreadItemType.Suggestion)}
      onNewComment={() => props.onStartNewItem(CommentThreadItemType.Comment)}
    />
  );
});

Reply.displayName = 'Reply';
