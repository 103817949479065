import React from 'react';
import styled from 'styled-components';
import { TagColor } from '../../../data/constants/tagColor.ts';
import { Tag } from '../../uiComponents/Tag/Tag.tsx';

const StyledBarItemTitleTag = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type Props = {
  readonly color: TagColor;
  readonly children: string;
};

export const BarItemTitleTag: React.FC<Props> = ({ color, children }: Props) => (
  <StyledBarItemTitleTag>
    <Tag color={color}>{children}</Tag>
  </StyledBarItemTitleTag>
);
