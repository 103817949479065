import React from 'react';
import { urlStartsWithHttps } from '../../../../../../_shared/utils/urlUtils.ts';
import { AccessDeniedToContentItemAction } from '../../../../../errorHandling/constants/AccessDeniedToContentItemAction.ts';
import { AccessDeniedToContentItem } from '../../../../../errorHandling/containers/AccessDeniedToContentItem.tsx';
import { WebSpotlightPreviewEmptyState } from '../../../../../webSpotlight/components/preview/WebSpotlightPreviewEmptyState.tsx';
import { WebSpotlightPreviewError } from '../../../../../webSpotlight/components/preview/WebSpotlightPreviewError.tsx';
import { PreviewError, PreviewUrlInfo } from '../../../../utils/previewUtils.ts';
import { PreviewContentItemInfo } from '../../../../utils/selectDeepestItemWithPreview.ts';
import { WebSpotlightPreviewSandboxInItemEditing } from './WebSpotlightPreviewSandboxInItemEditing.tsx';

const sharedPreviewUrlExcludedErrors: ReadonlyArray<PreviewError> = [
  PreviewError.MissingUrlSlugValue,
  PreviewError.MissingUrlSlugElement,
];

type IWebSpotlightPreviewContentProps = Readonly<{
  previewContentItemInfo: PreviewContentItemInfo | null;
  previewUrlInfo: PreviewUrlInfo;
  sharedPreviewUrl: string | null;
}>;

export const WebSpotlightPreviewInItemEditingContent: React.FC<
  IWebSpotlightPreviewContentProps
> = ({ previewContentItemInfo, previewUrlInfo, sharedPreviewUrl }) => {
  if (previewUrlInfo.error) {
    if (previewUrlInfo.error === PreviewError.AccessDenied) {
      return <AccessDeniedToContentItem requestedAction={AccessDeniedToContentItemAction.View} />;
    }

    if (!sharedPreviewUrl || !sharedPreviewUrlExcludedErrors.includes(previewUrlInfo.error)) {
      return (
        <WebSpotlightPreviewEmptyState>
          {previewUrlInfo.error !== PreviewError.NoPreview && previewContentItemInfo && (
            <WebSpotlightPreviewError
              error={previewUrlInfo.error}
              previewContentItemInfo={previewContentItemInfo}
            />
          )}
        </WebSpotlightPreviewEmptyState>
      );
    }
  }

  if ((!sharedPreviewUrl && !previewUrlInfo.url) || !previewContentItemInfo) {
    return <WebSpotlightPreviewEmptyState />;
  }

  const selectedPreviewUrlStartsWithHttps = sharedPreviewUrl
    ? urlStartsWithHttps(sharedPreviewUrl)
    : previewUrlInfo.url && urlStartsWithHttps(previewUrlInfo.url);

  if (urlStartsWithHttps(document.location.href) && !selectedPreviewUrlStartsWithHttps) {
    return (
      <WebSpotlightPreviewEmptyState hideDocumentationLink={!!sharedPreviewUrl}>
        <WebSpotlightPreviewError
          error={PreviewError.NotHttps}
          previewContentItemInfo={previewContentItemInfo}
        />
      </WebSpotlightPreviewEmptyState>
    );
  }

  return <WebSpotlightPreviewSandboxInItemEditing isResizable={false} />;
};
