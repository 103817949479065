import { Button } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { ControlShortcutTemplate, ShortcutSymbols } from '../../constants/shortcutSymbols.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { EnterHotkeyDisabler } from '../Hotkeys/EnterHotkeyDisabler.tsx';
import { SaveChangesButton } from '../SaveChangesButton.tsx';

type Props = {
  readonly isConfirmDisabled: boolean;
  readonly isSaving?: boolean;
  readonly mode: 'save' | 'confirm';
  readonly onConfirm: () => void;
  readonly onDiscard: () => void;
};

export const CodenameActionButtonPane = (props: Props) => {
  const { isConfirmDisabled, isSaving, onConfirm, onDiscard, mode } = props;

  const saveMode = mode === 'save';

  return (
    <EnterHotkeyDisabler>
      <div className="codename-editor__action-button-pane">
        <Inline spacing={Spacing.S}>
          {!isSaving && (
            <Button
              tooltipText="Discard"
              tooltipShortcuts={ShortcutSymbols.Escape}
              tooltipPlacement="bottom-end"
              buttonStyle="secondary"
              onClick={onDiscard}
              {...getDataUiActionAttribute(DataUiAction.Discard)}
            >
              Discard
            </Button>
          )}
          <SaveChangesButton
            buttonStyle="default"
            dataUiAction={saveMode ? DataUiAction.Save : DataUiAction.Confirm}
            disabled={isConfirmDisabled}
            isBeingSaved={isSaving || false}
            onSave={onConfirm}
            shortcuts={saveMode ? ControlShortcutTemplate('S') : ShortcutSymbols.Enter}
            text={saveMode ? 'Save' : 'Confirm'}
          />
        </Inline>
      </div>
    </EnterHotkeyDisabler>
  );
};
