import { notNull } from '@kontent-ai/utils';
import { ContentBlock, ContentState, genKey } from 'draft-js';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  createContent,
  setBlockKey,
  setBlockText,
} from '../../../utils/blocks/editorBlockUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';

export const getContentForActionInput = (
  elementType: ElementType,
  content: ContentState,
): ContentState =>
  elementType === ElementType.RichText ? content : splitContentIntoParagraphs(content);

const paragraphSeparatorRegex = /\n\n+/g;

const splitContentIntoParagraphs = (content: ContentState): ContentState =>
  createContent(getBlocks(content).flatMap(splitBlockIntoParagraphs));

const splitBlockIntoParagraphs = (block: ContentBlock): ReadonlyArray<ContentBlock> => {
  const text = block.getText();
  const paragraphBoundaries = Array.from(text.matchAll(paragraphSeparatorRegex));

  if (!paragraphBoundaries.length) {
    return [block];
  }

  const characterList = block.getCharacterList();

  const extractBlock = (startIndex: number, endIndex?: number) =>
    setBlockText(setBlockKey(block, genKey()), {
      text: text.substring(startIndex, endIndex),
      characterList: characterList.slice(startIndex, endIndex).toList(),
    });

  let lastIndex = 0;
  const newBlocks = [
    ...paragraphBoundaries.map((match) => {
      const matchEnd = match.index + match[0].length;
      const isEntityAtSeparator = characterList
        .slice(match.index, matchEnd)
        .some((char) => !!char?.getEntity());
      if (isEntityAtSeparator) return null;

      const newBlock = extractBlock(lastIndex, match.index);
      lastIndex = matchEnd;
      return newBlock;
    }),
    extractBlock(lastIndex),
  ].filter(notNull);

  return newBlocks;
};
