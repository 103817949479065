import { Node } from '@react-types/shared';
import React, { useMemo, ReactNode } from 'react';
import {
  VariableSizeList as VirtualizedList,
  VariableSizeListProps as VirtualizedListProps,
} from 'react-window';
import { RefForwardingComponent } from '../../@types/RefForwardingComponent.type.ts';
import { Paper, PaperLevel } from '../../containers/Paper/Paper.tsx';
import { Box } from '../../layout/Box/Box.tsx';
import { colorTextDefault } from '../../tokens/decision/colors.ts';
import { BaseColor } from '../../tokens/quarks/colors.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Divider } from '../Dividers/Divider.tsx';
import { DividerDirection } from '../Dividers/components/StyledDivider.tsx';
import { VerticalMenuProps } from './VerticalMenu.tsx';
import { VerticalMenuSizingProps } from './VerticalMenuFrame.tsx';
import {
  verticalMenuSectionSize,
  verticalMenuSectionWithDividerSize,
} from './components/Section.tsx';
import { VirtualizedListInnerWrapper } from './components/VirtualizedListInnerWrapper.tsx';
import { VirtualizedRow } from './components/VirtualizedRow.tsx';
import {
  verticalMenuMaxWidth,
  verticalMenuMinWidth,
  verticalMenuScrollablePartPadding,
} from './decisionTokens.ts';
import { BaseMenuItem, VerticalMenuItem, VerticalMenuSection } from './types.ts';
import { memoizeLastFlattenAndNormalizedItems } from './utils/flattenItems.ts';
import { isItemNode, isSectionNode } from './utils/utils.tsx';

type GetListItemHeight = <TItem extends VerticalMenuItem<TItem>>(
  item: Node<VerticalMenuSection<TItem> | TItem> | undefined,
  index: number,
  menuItemHeight: number | ((item: Node<TItem>) => number),
) => number;

const getListItemHeight: GetListItemHeight = (item, index, menuItemHeight) => {
  if (!item) {
    return 0;
  }

  if (isSectionNode(item)) {
    return index === 0 ? verticalMenuSectionSize : verticalMenuSectionWithDividerSize;
  }

  if (isItemNode(item)) {
    return typeof menuItemHeight === 'function' ? menuItemHeight(item) : menuItemHeight;
  }

  return 0;
};

const getListHeight = <TItem extends VerticalMenuItem<TItem>>(
  items: ReadonlyArray<Node<VerticalMenuSection<TItem> | TItem>>,
  getItemHeight: GetListItemHeight,
  menuItemHeight: number | ((item: Node<TItem>) => number),
  maxHeight: number,
): number => {
  let result = 2 * verticalMenuScrollablePartPadding;

  for (let i = 0; i < items.length; i++) {
    result += getItemHeight(items[i], i, menuItemHeight);

    if (result >= maxHeight) {
      return maxHeight;
    }
  }

  return result;
};

export type VirtualizedVerticalMenuFrameProps<TItem extends VerticalMenuItem<TItem>> = Pick<
  VerticalMenuSizingProps,
  'minWidth' | 'maxWidth' | 'width'
> &
  Pick<VerticalMenuProps<TItem>, 'renderItem' | 'pinnedItemId' | 'state'> &
  Pick<VirtualizedListProps, 'estimatedItemSize' | 'onScroll' | 'overscanCount'> & {
    readonly menuItemHeight: number | ((item: Node<TItem>) => number);
    readonly virtualizedListHeight?: number;
    readonly virtualizedListMaxHeight?: number;
    readonly virtualizedListRef?: React.Ref<VirtualizedList<HTMLDivElement>>;
  };

interface VirtualizedVerticalMenuFrameForwardingRef
  extends RefForwardingComponent<VirtualizedVerticalMenuFrameProps<BaseMenuItem>, HTMLDivElement> {
  <TItem extends VerticalMenuItem<TItem>>(
    props: VirtualizedVerticalMenuFrameProps<TItem>,
  ): ReactNode;
}

export const VirtualizedVerticalMenuFrame: VirtualizedVerticalMenuFrameForwardingRef =
  React.forwardRef(
    (
      {
        estimatedItemSize,
        menuItemHeight,
        renderItem,
        virtualizedListHeight,
        virtualizedListMaxHeight = 300,
        minWidth = verticalMenuMinWidth,
        maxWidth = verticalMenuMaxWidth,
        overscanCount,
        onScroll,
        width,
        pinnedItemId,
        state,
        virtualizedListRef,
        ...otherProps
      },
      forwardedRef,
    ) => {
      const items = [...state.collection];
      const flattenedItems = memoizeLastFlattenAndNormalizedItems(items);

      const getItemKey = (index: number) => flattenedItems[index]?.key ?? index;

      const virtualizedListItemCount = pinnedItemId
        ? flattenedItems.length - 1
        : flattenedItems.length;
      const listHeight = useMemo(
        () =>
          virtualizedListHeight ??
          getListHeight(
            flattenedItems,
            getListItemHeight,
            menuItemHeight,
            virtualizedListMaxHeight,
          ),
        [flattenedItems, virtualizedListHeight, virtualizedListMaxHeight, menuItemHeight],
      );

      const pinnedItem = pinnedItemId ? state.collection.getItem(pinnedItemId) : null;

      return (
        <Paper
          display="flex"
          flexDirection="column"
          color={colorTextDefault}
          level={PaperLevel.Elevated}
          overflow="hidden"
          minWidth={minWidth}
          maxWidth={maxWidth}
          width={width}
          {...otherProps}
          {...getDataUiComponentAttribute(VirtualizedVerticalMenuFrame)}
          ref={forwardedRef}
        >
          <VirtualizedList
            height={listHeight}
            innerElementType={VirtualizedListInnerWrapper}
            itemCount={virtualizedListItemCount}
            itemKey={getItemKey}
            itemSize={(index: number) =>
              getListItemHeight(flattenedItems[index], index, menuItemHeight)
            }
            estimatedItemSize={estimatedItemSize}
            onScroll={onScroll}
            overscanCount={overscanCount}
            width="100%"
            ref={virtualizedListRef}
          >
            {({ index, style }) => (
              <VirtualizedRow
                {...{
                  index,
                  item: flattenedItems[index],
                  pinnedItemId,
                  renderItem,
                  state,
                  style,
                }}
              />
            )}
          </VirtualizedList>
          {pinnedItem && isItemNode(pinnedItem) && (
            <Box paddingBottom={Spacing.S}>
              <Divider
                offsetAfter={Spacing.S}
                offsetBefore={Spacing.None}
                color={BaseColor.Gray20}
                direction={DividerDirection.Horizontal}
              />
              {renderItem({
                item: pinnedItem,
                level: 0,
              })}
            </Box>
          )}
        </Paper>
      );
    },
  );

VirtualizedVerticalMenuFrame.displayName = 'NonAccessibleVirtualizedVerticalMenu';
