import { useEffect } from 'react';
import { useWebSpotlightInItemEditing } from '../context/WebSpotlightInItemEditingContext.tsx';

export const useWebSpotlightPreviewInItemEditingInitialization = () => {
  const { setIsPreviewIFrameInitialized } = useWebSpotlightInItemEditing();

  useEffect(() => {
    setIsPreviewIFrameInitialized(true);
    return () => {
      setIsPreviewIFrameInitialized(false);
    };
  }, [setIsPreviewIFrameInitialized]);
};
