import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import { BaseColor } from '@kontent-ai/component-library/tokens';
import React, { ComponentProps } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { roleBuilderAnyLanguageOptionId } from '../../constants/roleBuilder.ts';

export type LanguageOption = ISelectItem<LanguageOption> & {
  readonly inactive?: boolean;
};

const getLanguageTagColor = (language: LanguageOption): BaseColor | undefined => {
  if (language.id === roleBuilderAnyLanguageOptionId) {
    return BaseColor.Gray30;
  }

  if (language.inactive) {
    return BaseColor.Red60;
  }

  return undefined;
};

const renderSelectedOption = (
  _id: Uuid,
  selectedItem: LanguageOption,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) => {
  const background = getLanguageTagColor(selectedItem);
  if (background) {
    return <Tag {...defaultTagProps} background={background} />;
  }
  return <DefaultTag {...defaultTagProps} />;
};

type Props = {
  readonly disabled: boolean;
  readonly languages: ReadonlyArray<LanguageOption>;
  readonly onChange: (selectedIds: ReadonlySet<Uuid>) => void;
  readonly selectedLanguageIds: ReadonlyArray<Uuid>;
  readonly tooltipText: string | undefined;
};

export const LanguagesSelector: React.FC<Props> = ({
  disabled,
  languages,
  onChange,
  selectedLanguageIds,
  tooltipText,
}) => {
  const disabledLanguageIds = languages
    .filter((item) => !!item.disabledTooltipText)
    .map((item) => item.id);

  return (
    <MultiSelect
      aria-label="Select a language"
      disabledItemIds={disabledLanguageIds}
      inputState={disabled ? InputState.Disabled : InputState.Default}
      items={languages}
      onSelectionChange={onChange}
      placeholder="Select a language"
      renderSelectedOption={renderSelectedOption}
      selectedItemIds={selectedLanguageIds}
      tooltipText={tooltipText}
      {...getDataUiCollectionAttribute(DataUiCollection.LanguageList)}
    />
  );
};

LanguagesSelector.displayName = 'LanguagesSelector';
