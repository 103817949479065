import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { useMemo } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { shallowEqual } from '../../../../../../../_shared/utils/shallowEqual.ts';
import { IUser } from '../../../../../../../data/reducers/user/IUser.type.ts';
import { getAvailableSpacesForUser } from '../../../../../../itemEditor/features/ContentItemEditing/selectors/getAvailableSpacesForUser.ts';
import { ListingFilterCategorySelector } from '../../components/ListingFilterCategorySelector.tsx';

type Props = {
  readonly onSpaceSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
  readonly selectedLanguageId: Uuid;
  readonly selectedSpaces: ReadonlySet<Uuid>;
  readonly user: IUser;
};

export const SpacesFilterSelector = (props: Props) => {
  const availableSpaces = useSelector(getAvailableSpacesForUser, shallowEqual);
  const label = 'Select a space';

  const selectedOptionIds = useMemo(
    () => availableSpaces.filter((space) => props.selectedSpaces.has(space.id)).map((c) => c.id),
    [props.selectedSpaces, availableSpaces],
  );

  const options: ReadonlyArray<IBaseSelectItem> = availableSpaces.map((space) => ({
    id: space.id,
    label: space.name,
  }));

  return availableSpaces.length ? (
    <ListingFilterCategorySelector
      options={options}
      onChange={props.onSpaceSelectionChanged}
      collection={DataUiCollection.Spaces}
      placeholder={label}
      ariaLabel={label}
      selectedOptionIds={selectedOptionIds}
      title="Space"
    />
  ) : null;
};
