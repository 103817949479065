import { Icon, Icons } from '@kontent-ai/component-library/Icons';
import { InputState } from '@kontent-ai/component-library/Input';
import { ISelectItem, Option } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { SetUtils } from '../../../../_shared/utils/setUtils/setUtils.ts';
import { SearchScope } from '../models/filter/IListingFilter.ts';

interface ISearchScopeOption extends ISelectItem<ISearchScopeOption> {
  readonly icon?: Icon;
  readonly id: string;
  readonly label: string;
  readonly value: ReadonlySet<SearchScope>;
}

const scopeOptions: ReadonlyArray<ISearchScopeOption> = [
  {
    id: 'names-and-content',
    label: 'Search in names and content',
    value: new Set<SearchScope>([SearchScope.Name, SearchScope.Content]),
  },
  {
    id: 'names',
    label: 'Search in names only',
    value: new Set<SearchScope>([SearchScope.Name]),
  },
];

const scopeOptionsWithAiSearch: ReadonlyArray<ISearchScopeOption> = [
  ...scopeOptions,
  {
    id: 'ai',
    label: 'Semantic search',
    value: new Set<SearchScope>([SearchScope.Ai]),
    icon: Icons.Sparkles,
  },
];

const tooltipText =
  'Searching by content is currently unavailable. For more details, check our status page on https://status.kontent.ai.';

type Props = {
  readonly allowAiSearch: boolean;
  readonly isFulltextDisabled: boolean;
  readonly onChange: (scope: ReadonlySet<SearchScope>) => void;
  readonly scope: ReadonlySet<SearchScope>;
};

export const ContentItemsSearchScope: React.FC<Props> = ({
  allowAiSearch,
  onChange,
  scope,
  isFulltextDisabled,
}) => {
  const options = allowAiSearch ? scopeOptionsWithAiSearch : scopeOptions;
  const foundSelectedOption = options.find((option) => SetUtils.isEqual(option.value, scope));

  if (!foundSelectedOption) {
    logError(`Option was not found. Search scope: ${JSON.stringify(Array.from(scope))}`);
  }

  const selectedOption = foundSelectedOption ?? scopeOptions[0];

  return (
    <Tooltip tooltipText={isFulltextDisabled ? tooltipText : undefined} placement="top-end">
      <div className="content-item-search-area__search-scope-selector-wrapper">
        <SingleSelect
          aria-label="Select how to search for content"
          items={options}
          placeholder="Select a search type"
          verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.SearchScope)}
          onSelectionChange={(_, item) => item && onChange(item.value)}
          selectedItemId={selectedOption?.id}
          inputState={isFulltextDisabled ? InputState.Disabled : InputState.Default}
          renderMenuOption={(optionProps) => (
            <Option
              {...optionProps}
              leadingElement={optionProps.item.value?.icon && <optionProps.item.value.icon />}
            />
          )}
          renderPrefix={(_, item: ISearchScopeOption | null) => item?.icon && <item.icon />}
        />
      </div>
    </Tooltip>
  );
};
