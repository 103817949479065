import { repositoryCollection } from '../../../_shared/repositories/repositories.ts';
import { loadListingItems } from '../../../applications/itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import { createAiSearchLoadListingContentItemsForInventoryAction } from './aiSearchLoadListingContentItemsForInventory.ts';

const { aiSearchRepository } = repositoryCollection;

export const aiSearchLoadListingContentItemsForInventory =
  createAiSearchLoadListingContentItemsForInventoryAction({
    aiSearchRepository,
    loadListingItems,
  });
