import { AppMenuItem } from '@kontent-ai/component-library/NavigationBar';
import { Route, Switch } from 'react-router';
import {
  ProjectSettingsRoute,
  ProjectsRoute,
  SubscriptionEnvironmentSettingsRoute,
  SubscriptionRoute,
  UserProfileRoute,
} from '../../constants/routePaths.ts';
import { MainLayoutGrid } from '../MainLayoutGrid.tsx';
import { MainMenuNavigationBar } from './MainMenuNavigationBar.tsx';

type Props = {
  readonly menuStructure: ReadonlyArray<AppMenuItem>;
};

const projectOrEnvironmentUnrelatedRoutes = [SubscriptionRoute, UserProfileRoute, ProjectsRoute];

const projectOrEnvironmentRelatedRoutes = [
  SubscriptionEnvironmentSettingsRoute,
  ProjectSettingsRoute,
];

export const MainMenu = ({ menuStructure }: Props) => (
  <MainLayoutGrid.NavMenu>
    <Switch>
      <Route path={projectOrEnvironmentRelatedRoutes}>
        <MainMenuNavigationBar appMenuItems={menuStructure} />
      </Route>
      <Route path={projectOrEnvironmentUnrelatedRoutes}>
        <MainMenuNavigationBar appMenuItems={[]} />
      </Route>
      <Route>
        <MainMenuNavigationBar appMenuItems={menuStructure} />
      </Route>
    </Switch>
  </MainLayoutGrid.NavMenu>
);
