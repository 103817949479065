import { isReviewByGuidelinesMessage } from '../../../paperModels/aiReview/repositories/serverModels/AiServerModels.reviewByGuidelines.ts';
import { AiActionName } from '../../../repositories/serverModels/ai/AiActionName.type.ts';
import { isCategorizeItemMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.categorizeItem.ts';
import { isChangeToneMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.changeTone.ts';
import { isCreateTitleMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.createTitle.ts';
import { isDescribeImageMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.describeImage.ts';
import { isGenerateContentByInstructionMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.generateContentByInstruction.ts';
import { isImproveContentByBriefMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.improveContentByBrief.ts';
import { isMakeShorterMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.makeShorter.ts';
import { isMatchWritingStyleOfItemMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.matchWritingStyleOfItem.ts';
import { isSummarizeMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.summarize.ts';
import { isTranslateContentMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.translateContent.ts';

export const aiActionNameToMessagePayloadTypeGuard = {
  [AiActionName.CategorizeItem]: isCategorizeItemMessage,
  [AiActionName.ChangeTone]: isChangeToneMessage,
  [AiActionName.CreateTitle]: isCreateTitleMessage,
  [AiActionName.DescribeImage]: isDescribeImageMessage,
  [AiActionName.GenerateContentByInstruction]: isGenerateContentByInstructionMessage,
  [AiActionName.ImproveContentByBrief]: isImproveContentByBriefMessage,
  [AiActionName.MakeShorter]: isMakeShorterMessage,
  [AiActionName.MatchWritingStyleOfItem]: isMatchWritingStyleOfItemMessage,
  [AiActionName.ReviewByGuidelines]: isReviewByGuidelinesMessage,
  [AiActionName.Summarize]: isSummarizeMessage,
  [AiActionName.TranslateContent]: isTranslateContentMessage,
} as const satisfies ReadonlyRecord<AiActionName, unknown>;
