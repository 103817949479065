import {
  BaseColor,
  colorPrimary,
  colorPrimaryDisabled,
  colorWarningBackgroundInverse,
} from '@kontent-ai/component-library/tokens';
import { TagColor } from '../../../data/constants/tagColor.ts';

export const calculateTagBackgroundColor = (color: TagColor): BaseColor => {
  switch (color) {
    case TagColor.Pink:
      return BaseColor.Red30;
    case TagColor.LightBlue:
      return BaseColor.OceanBlue30;
    case TagColor.Brown:
      return BaseColor.Yellow60;
    case TagColor.SkyBlue:
      return BaseColor.SkyBlue30;
    case TagColor.DarkPurple:
      return BaseColor.Purple60;
    case TagColor.PersianGreen:
      return BaseColor.PersianGreen60;
    case TagColor.Gray:
    case TagColor.CoolGray:
      return BaseColor.Gray60;
    case TagColor.DarkBlue:
      return BaseColor.OceanBlue60;
    case TagColor.DarkGreen:
      return BaseColor.NeonGreen60;
    case TagColor.LightGreen:
      return BaseColor.NeonGreen30;
    case TagColor.Orange:
      return BaseColor.Orange60;
    case TagColor.Rose:
      return BaseColor.Rose60;
    case TagColor.LightPurple:
      return BaseColor.Purple30;
    case TagColor.Red:
      return BaseColor.Red60;
    case TagColor.MintGreen:
      return BaseColor.PersianGreen30;
    case TagColor.Yellow:
      return BaseColor.Yellow30;
    case TagColor.None:
      return BaseColor.Transparent;
    case TagColor.LightGray:
      return BaseColor.Gray40;
    case TagColor.Product:
      return colorPrimary;
    case TagColor.ProductDisabled:
      return colorPrimaryDisabled;
    case TagColor.Warning:
      return colorWarningBackgroundInverse;
  }
};
