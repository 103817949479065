import { combineReducers } from '@reduxjs/toolkit';
import { itemsSelection } from './itemsSelection/itemsSelection.ts';
import { bulkActionCheckResult } from './reducers/bulkActionCheckResult.ts';
import { contentItemListingScrollTableState } from './reducers/contentItemListingScrollTableState.ts';
import { filter } from './reducers/filter.ts';
import { filterStatus } from './reducers/filterStatus.ts';
import { fullTextSearchContext } from './reducers/fullTextSearchContext.ts';
import { hiddenColumns } from './reducers/hiddenColumns.ts';
import { highlightedContentItems } from './reducers/highlightedContentItems.ts';
import { isOrderByDisplayed } from './reducers/isOrderByDisplayed.ts';
import { orderBy } from './reducers/orderBy.ts';
import { publishWarnings } from './reducers/publishWarnings.ts';
import { savedFiltersUi } from './reducers/savedFiltersUi/savedFiltersUi.ts';
import { statusInfo } from './statusInfo/statusInfo.ts';

export const listingUi = combineReducers({
  bulkActionCheckResult,
  contentItemListingScrollTableState,
  filter,
  filterStatus,
  fullTextSearchContext,
  hiddenColumns,
  highlightedContentItems,
  isOrderByDisplayed,
  itemsSelection,
  orderBy,
  publishWarnings,
  savedFiltersUi,
  statusInfo,
});
