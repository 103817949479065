import { InvariantException } from '@kontent-ai/errors';
import { useAttachRef } from '@kontent-ai/hooks';
import { FocusScope } from '@react-aria/focus';
import { AriaMenuOptions, useMenu } from '@react-aria/menu';
import { mergeProps } from '@react-aria/utils';
import { Key } from '@react-types/shared';
import React, { ComponentProps, ReactNode } from 'react';
import { RefForwardingComponent } from '../../@types/RefForwardingComponent.type.ts';
import { Box } from '../../layout/Box/Box.tsx';
import { BaseColor } from '../../tokens/quarks/colors.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { Divider } from '../Dividers/Divider.tsx';
import { DividerDirection } from '../Dividers/components/StyledDivider.tsx';
import { VerticalMenuFrame, VerticalMenuSizingProps } from './VerticalMenuFrame.tsx';
import { Item } from './components/Item.tsx';
import { Section } from './components/Section.tsx';
import { BaseMenuItem, IItemProps, VerticalMenuItem, VerticalMenuSection } from './types.ts';
import { VerticalMenuState } from './useNonAccessibleVerticalMenu.ts';
import { getDataUiAttributeWithSectionName, isItemNode, isSectionNode } from './utils/utils.tsx';

export interface VerticalMenuProps<TItem extends VerticalMenuItem<TItem>>
  extends VerticalMenuSizingProps,
    Partial<Pick<ComponentProps<typeof FocusScope>, 'contain' | 'restoreFocus'>>,
    Pick<AriaMenuOptions<TItem>, 'autoFocus' | 'onAction'> {
  readonly items: ReadonlyArray<VerticalMenuSection<TItem> | TItem> | undefined;

  readonly pinnedItemId?: Key;
  readonly renderItem: (itemProps: IItemProps<TItem>) => React.ReactNode;
  readonly state: VerticalMenuState<TItem>;
}

type NonAccessibleVerticalMenuProps<TItem extends VerticalMenuItem<TItem>> = Omit<
  VerticalMenuProps<TItem>,
  'contain' | 'restoreFocus' | 'items'
>;

interface NonAccessibleVerticalMenuForwardingRef
  extends RefForwardingComponent<NonAccessibleVerticalMenuProps<BaseMenuItem>, HTMLDivElement> {
  <TItem extends VerticalMenuItem<TItem>>(props: NonAccessibleVerticalMenuProps<TItem>): ReactNode;
}

/**
 * @deprecated
 * */
export const NonAccessibleVerticalMenu: NonAccessibleVerticalMenuForwardingRef = React.forwardRef(
  ({ autoFocus, state, renderItem, pinnedItemId, ...otherProps }, forwardedRef) => {
    const commonItemProps = {
      expandedKeys: state.expandedKeys,
      pinnedItemId,
      renderItem,
      state,
    };

    const pinnedItem = pinnedItemId ? state.collection.getItem(pinnedItemId) : undefined;

    return (
      <VerticalMenuFrame
        ref={forwardedRef}
        renderPinnedItem={() => {
          return (
            pinnedItem &&
            isItemNode(pinnedItem) && (
              <Box paddingBottom={Spacing.S}>
                <Divider
                  offsetAfter={Spacing.S}
                  offsetBefore={Spacing.None}
                  color={BaseColor.Gray20}
                  direction={DividerDirection.Horizontal}
                />
                {renderItem({
                  item: pinnedItem,
                  level: 0,
                })}
              </Box>
            )
          );
        }}
        {...otherProps}
      >
        {[...state.collection].map((sectionItem, i) => {
          if (isSectionNode(sectionItem)) {
            const items = sectionItem.hasChildNodes ? Array.from(sectionItem.childNodes) : [];

            return [
              <Section
                key={sectionItem.key}
                isFirst={i === 0}
                item={sectionItem}
                {...commonItemProps}
              />,
              ...items.map(
                (item) =>
                  isItemNode(item) && (
                    <Item
                      key={item.key}
                      {...getDataUiAttributeWithSectionName(item, state)}
                      {...commonItemProps}
                      item={item}
                    />
                  ),
              ),
            ];
          }
          if (isItemNode(sectionItem)) {
            return <Item key={sectionItem.key} item={sectionItem} {...commonItemProps} />;
          }

          throw InvariantException(`Unknown Node type '${sectionItem.type}'.`);
        })}
      </VerticalMenuFrame>
    );
  },
);

interface VerticalMenuForwardingRef
  extends RefForwardingComponent<VerticalMenuProps<BaseMenuItem>, HTMLDivElement> {
  <TItem extends VerticalMenuItem<TItem>>(props: VerticalMenuProps<TItem>): ReactNode;
}

export const VerticalMenu: VerticalMenuForwardingRef = React.forwardRef(
  (
    { autoFocus, contain = true, restoreFocus = true, state, items, ...otherProps },
    forwardedRef,
  ) => {
    const { refObject, refToForward } = useAttachRef(forwardedRef);
    const { menuProps } = useMenu(
      {
        autoFocus,
        items,
        shouldFocusWrap: false,
        ...otherProps,
      },
      state,
      refObject,
    );

    const { children, ...verticalMenuProps } = mergeProps(
      otherProps,
      {
        ref: refToForward,
        state,
      },
      menuProps,
    );

    return (
      <FocusScope contain={contain} restoreFocus={restoreFocus}>
        <NonAccessibleVerticalMenu {...verticalMenuProps} />
      </FocusScope>
    );
  },
);

VerticalMenu.displayName = 'VerticalMenu';
