import React from 'react';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { OpenInNewTabButton as OpenInNewTabButtonComponent } from '../../components/contentItemPreview/OpenInNewTabButton.tsx';
import { useWebSpotlightInItemEditing } from '../../context/WebSpotlightInItemEditingContext.tsx';

export const OpenInNewTabButton: React.FC = () => {
  const dispatch = useDispatch();
  const { previewUrl, spaceId } = useWebSpotlightInItemEditing();

  if (!previewUrl) {
    return null;
  }

  const trackAction = () => {
    dispatch(
      trackUserEventWithData(TrackedEvent.SpacePreviewOpened, {
        'space-id': spaceId ?? '',
      }),
    );
  };

  return <OpenInNewTabButtonComponent previewUrl={previewUrl} onClick={trackAction} />;
};
