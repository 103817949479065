import { mergeProps } from '@react-aria/utils';
import React, { ButtonHTMLAttributes } from 'react';
import { BaseAvatarComponent, IBaseAvatarProps } from '../components/BaseAvatarComponent.tsx';
import { StyledButtonAvatar } from '../components/StyledAvatar.tsx';

export interface IButtonAvatarProps
  extends IBaseAvatarProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  readonly activated?: boolean;
}

export const ButtonAvatar = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<IButtonAvatarProps>
>(
  (
    {
      activated,
      backgroundGradient,
      boxShadow,
      image,
      initials,
      label,
      size,
      tooltipPlacement,
      children, // Just to omit them from buttonProps
      type = 'button',
      ...buttonProps
    },
    forwardedRef,
  ) => {
    return (
      <BaseAvatarComponent
        renderContainer={({ isFocusVisible, ...injectedProps }) => (
          <StyledButtonAvatar
            $activated={activated}
            $size={size}
            aria-label={label}
            boxShadow={boxShadow}
            isFocusVisible={isFocusVisible}
            ref={forwardedRef}
            type={type}
            {...mergeProps(buttonProps, injectedProps)}
          />
        )}
        {...{
          backgroundGradient,
          boxShadow,
          image,
          initials,
          label,
          size,
          tooltipPlacement,
        }}
        tooltipText={activated ? undefined : label}
      />
    );
  },
);

ButtonAvatar.displayName = 'ButtonAvatar';
