import { ComponentProps } from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { ListingFilterCategorySelector } from '../../../applications/contentInventory/content/features/ListingFilter/components/ListingFilterCategorySelector.tsx';
import { shouldUseAiSearch } from '../selectors/shouldUseAiSearch.ts';

export const useDisabledAiFilterProps = ():
  | Pick<
      ComponentProps<typeof ListingFilterCategorySelector<any>>,
      'caption' | 'disabled' | 'placeholder'
    >
  | undefined =>
  useSelector(shouldUseAiSearch)
    ? {
        caption: 'Not supported with semantic search yet.',
        disabled: true,
        placeholder: '',
      }
    : undefined;
