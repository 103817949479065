import { History } from 'history';
import { Action } from '../../../@types/Action.type.ts';
import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import {
  AccessDeniedType,
  getVariantIdToBeEnsuredOrAccessDeniedType,
} from '../../../applications/contentInventory/shared/utils/getVariantIdToBeEnsuredOrAccessDeniedType.ts';
import { getNormalizedRolesWithSettings } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { AppNames } from '../../constants/applicationNames.ts';
import {
  AccessDeniedRoute,
  AccessDeniedToLanguageRoute,
  AccessDeniedToLanguageRouteParams,
  AccessLostDueToDeactivatedLanguageRoute,
  EnvironmentAccessDeniedRouteParams,
  EnvironmentRouteParams,
} from '../../constants/routePaths.ts';
import { buildPath } from '../../utils/routing/routeTransitionUtils.ts';
import { isUuid } from '../../utils/validation/typeValidators.ts';

interface IDeps {
  readonly appName: AppNames;
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadLanguages: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly contentVariantIdStorage: {
    readonly load: () => Uuid | null;
    readonly save: (variantId: Uuid) => void;
  };
  readonly getPathWithReplacedVariantId: (location: string, variantId: Uuid) => string;
  readonly localizedRouteEntered: (variantId: Uuid) => Action;
}

export const createEnsureSelectedVariantInRouteAction =
  (deps: IDeps) =>
  (
    currentRouteVariantId: Uuid | undefined,
    history: History,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    await Promise.all([
      dispatch(deps.loadLanguages(abortSignal)),
      dispatch(deps.loadCollections(abortSignal)),
    ]);

    const {
      data: { collections, languages },
      sharedApp: { currentProjectId: projectId },
    } = getState();

    const ensuredVariantIdOrAccessDenied = getVariantIdToBeEnsuredOrAccessDeniedType(
      currentRouteVariantId,
      deps.contentVariantIdStorage.load,
      languages,
      getNormalizedRolesWithSettings(getState()),
      collections,
    );

    if (isUuid(ensuredVariantIdOrAccessDenied.id)) {
      deps.contentVariantIdStorage.save(ensuredVariantIdOrAccessDenied.id);

      const ensuredPath = deps.getPathWithReplacedVariantId(
        location.pathname,
        ensuredVariantIdOrAccessDenied.id,
      );
      if (ensuredPath !== location.pathname) {
        history.replace({
          pathname: ensuredPath,
          search: location.search,
        });
      }

      dispatch(deps.localizedRouteEntered(ensuredVariantIdOrAccessDenied.id));
    } else if (ensuredVariantIdOrAccessDenied.error) {
      switch (ensuredVariantIdOrAccessDenied.error) {
        case AccessDeniedType.NoContentProductionCapabilities: {
          history.push(
            buildPath<EnvironmentAccessDeniedRouteParams>(AccessDeniedRoute, {
              requestedAppName: deps.appName,
              projectId,
            }),
          );
          break;
        }

        case AccessDeniedType.AccessLostDueToDeactivatedLanguage: {
          history.push(
            buildPath<EnvironmentRouteParams>(AccessLostDueToDeactivatedLanguageRoute, {
              projectId,
            }),
          );
          break;
        }

        case AccessDeniedType.AccessToLanguageDenied: {
          history.push(
            buildPath<AccessDeniedToLanguageRouteParams>(AccessDeniedToLanguageRoute, {
              variantId: currentRouteVariantId,
              projectId,
            }),
          );
          break;
        }
      }
    }
  };
