import React from 'react';
import { BarItemBar } from './BarItemBar.tsx';

type Props = {
  readonly children: React.ReactNode;
  readonly dataUiObjectName?: string;
  readonly hasLoader?: boolean;
  readonly isClickable?: boolean;
  readonly isExpanded?: boolean;
};

export const BarItemWithLoader: React.FC<Props> = ({
  children,
  dataUiObjectName,
  hasLoader,
  isClickable,
  isExpanded,
}) => (
  <>
    {hasLoader ? (
      <div className="bar-item__bar-loader-pane">
        <div className="bar-item__bar-loader" />
      </div>
    ) : null}
    <BarItemBar
      isClickable={isClickable}
      isExpanded={isExpanded}
      dataUiObjectName={dataUiObjectName}
    >
      {children}
    </BarItemBar>
  </>
);

BarItemWithLoader.displayName = 'BarItemWithLoader';
