import React from 'react';
import { createDemoTasksDoneOnTimeCounts } from '../../../../missionControl/widgets/shared/utils/createDemoTasksDoneOnTimeCounts.ts';
import { TimeInterval } from '../../../constants/timeIntervals.ts';
import { MissionControlRepository } from '../../../repositories/MissionControlRepository.type.ts';
import { ITasksDoneOnTime } from '../../../repositories/serverModels/missionControlRepositoryServerModels.type.ts';
import { ItemsPublishedOnTimeWidget } from '../components/ItemsPublishedOnTimeWidget.tsx';

export const demoPublishedOnTimeData: Record<TimeInterval, ITasksDoneOnTime> = {
  [TimeInterval.CurrentMonth]: createDemoTasksDoneOnTimeCounts(2, 10),
  [TimeInterval.CurrentWeek]: createDemoTasksDoneOnTimeCounts(1, 2),
  [TimeInterval.PastThirtyDays]: createDemoTasksDoneOnTimeCounts(5, 12),
};

export const ItemsPublishedOnTimeWidgetDemoContainer: React.FC = () => {
  const itemsPublishedOnTimeFetcher: MissionControlRepository['getItemsPublishedOnTime'] = (
    selectedIntervalId,
  ) => Promise.resolve(demoPublishedOnTimeData[selectedIntervalId]);

  return <ItemsPublishedOnTimeWidget itemsPublishedOnTimeFetcher={itemsPublishedOnTimeFetcher} />;
};

ItemsPublishedOnTimeWidgetDemoContainer.displayName = 'ItemsPublishedOnTimeWidgetLiveContainer';
