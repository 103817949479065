import { areShallowEqual, noOperation } from '@kontent-ai/utils';
import { BlockRendererFn, DraftBlockRenderConfig } from 'draft-js';
import Immutable from 'immutable';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { CreateAutoScrollId } from '../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { DragSource } from '../../../../_shared/components/DragDrop/DragSource.tsx';
import { DndTypes } from '../../../../_shared/constants/dndTypes.ts';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlAltShortcutTemplate } from '../../../../_shared/constants/shortcutSymbols.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { TrackUserEventWithData } from '../../../../_shared/models/TrackUserEvent.type.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  DataUiElement,
  DataUiRteAction,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { canUserViewAnyActiveLanguage } from '../../../../_shared/utils/permissions/canUserViewAnyActiveLanguage.ts';
import { IContentItemWithVariantServerModel } from '../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { CannotViewItemsMessage } from '../../../contentInventory/content/constants/cannotViewMessages.ts';
import { PreselectedFilter } from '../../../contentInventory/content/features/ListingFilter/hooks/useSetUpContentItemFilter.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { useSpacesIds } from '../../../environmentSettings/spaces/hooks/useSpaceIds.ts';
import { ModalMultipleContentItemsSelector } from '../../../features/ModalContentItemSelector/containers/ModalMultipleContentItemsSelector.tsx';
import { ElementReference } from '../../../itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { ContentOverlayPlaceholder } from '../../../itemEditor/features/LinkedItems/components/ContentOverlay.tsx';
import { CommentThreadItemType } from '../../../itemEditor/models/comments/CommentThreadItem.ts';
import { EditorSizeHandler } from '../../components/utility/EditorSizeHandler.tsx';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { BaseEditorProps } from '../../editorCore/types/Editor.base.type.ts';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None, WithoutProps } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  PluginProps,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../editorCore/types/EditorChangeReason.ts';
import { DecorableFunction, Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import {
  getContentOverlayClass,
  getContentOverlayId,
} from '../../editorCore/utils/editorComponentUtils.ts';
import { BaseBlockType, BlockType } from '../../utils/blocks/blockType.ts';
import { getBaseBlockType } from '../../utils/blocks/editorBlockGetters.ts';
import { IEditorBlockProps } from '../../utils/blocks/editorBlockUtils.ts';
import { getContentModuleBlocks } from '../../utils/general/editorContentGetters.ts';
import { getNewBlockPlaceholderType } from '../../utils/general/editorContentUtils.ts';
import { ModalsPlugin } from '../ModalsPlugin.tsx';
import { LinkedItemsLimitations } from '../apiLimitations/api/EditorFeatureLimitations.ts';
import { CommentsPlugin } from '../comments/CommentsPlugin.tsx';
import { getCommentSegmentIdFromBlock } from '../comments/api/editorCommentUtils.ts';
import { CustomBlocksPlugin } from '../customBlocks/CustomBlocksPlugin.tsx';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { GetBaseBlockRenderMap } from '../draftJs/DraftJsPlugin.type.ts';
import { BaseBlockRenderMap, mergeBlockRenderMaps } from '../draftJs/utils/draftJsEditorUtils.ts';
import { DragDropPlugin } from '../dragDrop/DragDropPlugin.tsx';
import { DroppableCustomBlockWrapper } from '../dragDrop/components/DroppableCustomBlockWrapper.tsx';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import { BlockToolbarPlugin, GetInsertBlockMenuItems } from '../toolbars/BlockToolbarPlugin.tsx';
import { CommandToolbarMenuItem } from '../toolbars/components/menu/EditorCommandMenu.tsx';
import { OnHighlightedBlocksChanged } from '../visuals/StylesPlugin.tsx';
import { EditorLinkedItemApi } from './api/EditorLinkedItemApi.type.ts';
import { editorLinkedItemApi } from './api/editorLinkedItemApi.ts';
import { getModularContentItemId } from './api/editorModularUtils.ts';
import { ExpandedModularContents } from './components/expanded/ExpandedModularContents.tsx';
import { RichTextLinkedItem } from './containers/RichTextLinkedItem.tsx';

type LinkedItemsPluginState = {
  readonly onLinkedItemsInserted: DecorableFunction<() => void>;
};

type LinkedItemsPluginProps = {
  readonly contentItemCollectionId?: Uuid;
  readonly editedEntityName: string;
  readonly element: ElementReference;
  readonly limitations: LinkedItemsLimitations;
  readonly onDuplicateModularContentItem?: (
    contentItemId: Uuid,
    destinationCollectionId: Uuid,
  ) => Promise<IContentItemWithVariantServerModel | null>;
  readonly trackUserEventWithData?: TrackUserEventWithData;
};

export type LinkedItemsPlugin = DraftJsEditorPlugin<
  LinkedItemsPluginState,
  LinkedItemsPluginProps,
  EditorLinkedItemApi,
  None,
  [
    DragDropPlugin,
    CommentsPlugin,
    CustomBlocksPlugin,
    KeyboardShortcutsPlugin<RichTextInputCommand>,
    ModalsPlugin,
    BlockToolbarPlugin,
  ]
>;

type BlockAction = (blockKey: string) => void;
type DuplicateModularContentItem = (
  contentItemId: Uuid,
  destinationCollectionId: Uuid,
  blockKey: string,
) => void;

type LinkedItemBlockCustomProps = Pick<
  PluginState<LinkedItemsPlugin>,
  | 'createComment'
  | 'deleteCustomBlock'
  | 'hoveringCollisionStrategy'
  | 'getEditorId'
  | 'onDragEnd'
  | 'onDragStart'
  | 'onMoveBlocks'
> &
  Pick<
    PluginProps<LinkedItemsPlugin>,
    'disabled' | 'element' | 'limitations' | 'onDuplicateModularContentItem'
  > & {
    readonly blockCollapsed: BlockAction;
    readonly blockExpanded: BlockAction;
    readonly duplicateModularContentItem: DuplicateModularContentItem;
  };

const LinkedItemBlock: React.FC<IEditorBlockProps<LinkedItemBlockCustomProps>> = memo(
  (props) => {
    const {
      block,
      blockProps: {
        blockCollapsed,
        blockExpanded,
        createComment,
        deleteCustomBlock,
        disabled,
        duplicateModularContentItem,
        element: { contentComponentId, elementId, itemId },
        hoveringCollisionStrategy,
        getEditorId,
        limitations,
        onDragEnd,
        onDragStart,
        onDuplicateModularContentItem,
        onMoveBlocks,
      },
      ...otherProps
    } = props;

    const linkedItemId = getModularContentItemId(block);
    if (!linkedItemId || !itemId) {
      return null;
    }

    const editorId = getEditorId();
    const blockKey = block.getKey();
    const scrollId = CreateAutoScrollId.forRichTextBlock(blockKey);
    const commentSegmentId = getCommentSegmentIdFromBlock(block);

    const alternativeScrollIds = [
      CreateAutoScrollId.forContentItem(linkedItemId), // Ability to scroll to it by global item id (from usage or after creating new item in linked items)
    ];

    return (
      <DroppableCustomBlockWrapper
        block={block}
        canUpdate={!disabled}
        className="rte__linked-item"
        hoveringCollisionStrategy={hoveringCollisionStrategy}
        key={blockKey}
        onMove={onMoveBlocks}
        parentId={editorId}
        uiElement={DataUiElement.ContentModule}
        {...otherProps}
      >
        <DragSource
          onDragEnd={onDragEnd}
          onDragStart={() => onDragStart(blockKey)}
          parentId={editorId}
          renderDraggable={(connectDragSource, isDragging) => (
            <RichTextLinkedItem
              allowedTypeIds={limitations.allowedTypes}
              alternativeScrollIds={alternativeScrollIds}
              commentSegmentId={commentSegmentId}
              connectDragSource={connectDragSource}
              contentComponentId={contentComponentId}
              contentItemId={linkedItemId}
              displayDragButton
              elementId={elementId}
              isDisabled={!!disabled}
              isDragging={isDragging}
              onDelete={() => deleteCustomBlock(blockKey)}
              onDuplicate={
                onDuplicateModularContentItem
                  ? (contentItemId, destinationCollectionId) =>
                      duplicateModularContentItem(contentItemId, destinationCollectionId, blockKey)
                  : undefined
              }
              onExpandedChanged={(isExpanded) =>
                isExpanded ? blockExpanded(blockKey) : blockCollapsed(blockKey)
              }
              onNewComment={() => createComment(CommentThreadItemType.Comment, blockKey)}
              renderExpanded={() => (
                <ContentOverlayPlaceholder overlayId={getContentOverlayId(editorId, blockKey)} />
              )}
              scrollId={scrollId}
            />
          )}
          renderPreview={() => (
            <RichTextLinkedItem
              allowedTypeIds={limitations.allowedTypes}
              alternativeScrollIds={alternativeScrollIds}
              commentSegmentId={commentSegmentId}
              contentComponentId={contentComponentId}
              contentItemId={linkedItemId}
              displayDragButton
              elementId={elementId}
              isDisabled={!!disabled}
              isDragging={false}
              onDelete={noOperation}
              onDuplicate={noOperation}
              onNewComment={noOperation}
            />
          )}
          sourceId={blockKey}
          type={DndTypes.Rich_Text_ModularContent}
        />
      </DroppableCustomBlockWrapper>
    );
  },
  (prevProps, nextProps) =>
    areShallowEqual(prevProps, nextProps, [
      'contentState',
      'customStyleMap',
      'selection',
      'blockProps',
    ]) && areShallowEqual(prevProps.blockProps, nextProps.blockProps),
);

LinkedItemBlock.displayName = 'LinkedItemBlock';

type EditorWithLinkedItemsProps = Pick<
  LinkedItemsPluginProps,
  'limitations' | 'element' | 'onDuplicateModularContentItem'
> &
  Pick<BaseEditorProps, 'disabled'> & {
    readonly blockCollapsed: BlockAction;
    readonly blockExpanded: BlockAction;
  };

const EditorWithLinkedItems: DecoratedEditor<
  WithoutProps<LinkedItemsPlugin>,
  EditorWithLinkedItemsProps
> = ({
  baseRender,
  blockCollapsed,
  blockExpanded,
  disabled,
  element,
  limitations,
  onDuplicateModularContentItem,
  state,
}) => {
  const {
    canUpdateContent,
    createComment,
    deleteCustomBlock,
    draggedBlockKey,
    executeExternalAction,
    getEditorId,
    editorProps: { blockRendererFn: baseBlockRendererFn },
    getApi,
    hoveringCollisionStrategy,
    onDragEnd,
    onDragStart,
    onMoveBlocks,
  } = state;

  const duplicateModularContentItem: DuplicateModularContentItem = useCallback(
    (contentItemId, destinationCollectionId, blockKey) => {
      if (!canUpdateContent() || !onDuplicateModularContentItem) {
        return;
      }

      executeExternalAction(async (editorState) => {
        const contentItem = await onDuplicateModularContentItem(
          contentItemId,
          destinationCollectionId,
        );

        if (contentItem) {
          const { id } = contentItem.item;
          return getApi().insertModularContentItem(editorState, id, blockKey);
        }
        return editorState;
      });
    },
    [canUpdateContent, executeExternalAction, getApi, onDuplicateModularContentItem],
  );

  const blockProps: LinkedItemBlockCustomProps = useMemo(
    () => ({
      blockCollapsed,
      blockExpanded,
      createComment,
      deleteCustomBlock,
      disabled,
      draggedBlockKey,
      element,
      hoveringCollisionStrategy,
      getEditorId,
      limitations,
      onDragEnd,
      onDragStart,
      onMoveBlocks,
      duplicateModularContentItem,
      onDuplicateModularContentItem,
    }),
    [
      blockCollapsed,
      blockExpanded,
      createComment,
      deleteCustomBlock,
      disabled,
      draggedBlockKey,
      element,
      hoveringCollisionStrategy,
      getEditorId,
      limitations,
      onDragEnd,
      onDragStart,
      onMoveBlocks,
      duplicateModularContentItem,
      onDuplicateModularContentItem,
    ],
  );

  const blockRendererFn: BlockRendererFn<LinkedItemBlockCustomProps> = useCallback(
    (block) => {
      const baseBlockType = getBaseBlockType(block);
      if (baseBlockType === BlockType.ContentModule) {
        return {
          component: LinkedItemBlock,
          props: blockProps,
          editable: false,
        };
      }

      return baseBlockRendererFn?.(block) ?? null;
    },
    [baseBlockRendererFn, blockProps],
  );

  const stateWithLinkedItems: PluginState<LinkedItemsPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      blockRendererFn,
    },
  };

  return baseRender(stateWithLinkedItems);
};

EditorWithLinkedItems.displayName = 'EditorWithLinkedItems';

const linkedItemRenderMap: BaseBlockRenderMap = Immutable.Map<
  BaseBlockType,
  DraftBlockRenderConfig
>({
  [BaseBlockType.ContentModule]: {
    element: 'div',
  },
});

const getBaseBlockRenderMap: Decorator<GetBaseBlockRenderMap> = (baseGetBaseBlockRenderMap) => () =>
  mergeBlockRenderMaps(baseGetBaseBlockRenderMap(), linkedItemRenderMap);

const insertLinkedItemMenuItem: CommandToolbarMenuItem = {
  label: 'Insert existing item',
  command: RichTextInputCommand.InsertItem,
  shortcuts: ControlAltShortcutTemplate('I'),
  iconName: IconName.Puzzle,
  uiAction: DataUiRteAction.AddItem,
};

export const LinkedItemsPlugin: PluginComponent<LinkedItemsPlugin> = (props) => {
  const {
    contentItemCollectionId,
    onDuplicateModularContentItem,
    disabled,
    editedEntityName,
    element,
    limitations,
    trackUserEventWithData,
  } = props;

  const canViewContent = useSelector(canUserViewAnyActiveLanguage);
  const spaceIds = useSpacesIds(contentItemCollectionId);

  const [highlightedBlockKeys, setHighlightedBlockKeys] = useState<ReadonlySet<string>>(new Set());

  const onHighlightedBlocksChanged: Decorator<OnHighlightedBlocksChanged> = useCallback(
    (baseOnHighlightedBlocksChanged) => (newHighlightedBlockKeys) => {
      setHighlightedBlockKeys(newHighlightedBlockKeys);
      baseOnHighlightedBlocksChanged(newHighlightedBlockKeys);
    },
    [],
  );

  const [expandedBlockKeys, setExpandedBlockKeys] = useState<ReadonlySet<string>>(new Set());

  const blockExpanded = useCallback((blockKey: string): void => {
    setExpandedBlockKeys((prev) => new Set([...prev, blockKey]));
  }, []);

  const blockCollapsed = useCallback((blockKey: string): void => {
    setExpandedBlockKeys((prev) => new Set([...prev].filter((x) => x !== blockKey)));
  }, []);

  const renderOverlays: Decorator<Render<LinkedItemsPlugin>> = useCallback(
    (baseRenderOverlays) => (state) => {
      const { draggedBlockKey, editorState, getEditorId } = state;
      const content = editorState.getCurrentContent();
      const contentModuleBlocks = getContentModuleBlocks(content);
      const editorId = getEditorId();

      return (
        <>
          {baseRenderOverlays(state)}
          <ExpandedModularContents
            contentModuleBlocks={contentModuleBlocks}
            disabled={!!disabled}
            editorId={editorId}
            expandedBlockKeys={expandedBlockKeys}
            highlightedBlockKeys={highlightedBlockKeys}
            isDragging={!!draggedBlockKey}
          />
          <EditorSizeHandler
            contentOverlayClassName={
              contentModuleBlocks.length > 0 ? getContentOverlayClass(editorId) : undefined
            }
            editorRef={state.getWrapperRef()}
          />
        </>
      );
    },
    [disabled, expandedBlockKeys, highlightedBlockKeys],
  );

  const render: Decorator<Render<LinkedItemsPlugin>> = useCallback(
    (baseRender) => (state) => (
      <EditorWithLinkedItems
        baseRender={baseRender}
        blockCollapsed={blockCollapsed}
        blockExpanded={blockExpanded}
        disabled={disabled}
        element={element}
        limitations={limitations}
        onDuplicateModularContentItem={onDuplicateModularContentItem}
        state={state}
      />
    ),
    [blockCollapsed, blockExpanded, disabled, element, limitations, onDuplicateModularContentItem],
  );

  const getAddBlockMenuItems: Decorator<GetInsertBlockMenuItems> = useCallback(
    (baseGetAddBlockMenuItems) => () => [
      ...baseGetAddBlockMenuItems(),
      {
        ...insertLinkedItemMenuItem,
        disabled: !canViewContent,
        disabledTooltipText: canViewContent ? undefined : CannotViewItemsMessage,
      },
    ],
    [canViewContent],
  );

  const apply: Apply<LinkedItemsPlugin> = useCallback(
    (state) => {
      state.getBaseBlockRenderMap.decorate(getBaseBlockRenderMap);
      state.onHighlightedBlocksChanged.decorate(onHighlightedBlocksChanged);
      state.render.decorate(render);
      state.getInsertBlockMenuItems.decorate(getAddBlockMenuItems);
      state.renderOverlays.decorate(renderOverlays);

      const executeCommand: Decorator<ExecuteCommand<RichTextInputCommand>> =
        (baseExecuteCommand) => (command, isShiftPressed) => {
          switch (command) {
            case RichTextInputCommand.InsertItem: {
              state.createNewBlockPlaceholder(BlockType.ContentModule);
              return true;
            }

            default:
              return baseExecuteCommand(command, isShiftPressed);
          }
        };

      state.executeCommand.decorate(executeCommand);

      const onLinkedItemsInserted = decorable(noOperation);

      const insertLinkedItems = async (
        placeholderBlockKey: string,
        contentItemIds: ReadonlyArray<Uuid>,
      ) => {
        if (state.canUpdateContent(EditorChangeReason.Internal)) {
          await state.executeChange((editorState) => {
            const newEditorState = state
              .getApi()
              .insertModularContentItems(editorState, placeholderBlockKey, contentItemIds, false);
            if (editorState !== newEditorState) {
              trackUserEventWithData?.(TrackedEvent.ContentItemEditing, {
                action: ContentItemEditingEventTypes.LinkContentItem,
                allowedContentTypesCount: limitations.allowedTypes.length,
                origin: ContentItemEditingEventOrigins.Paper,
                contentElementType: ElementType.RichText,
                selectedItemsCount: contentItemIds.length,
              });
            }
            state.resetEditedBlockKey();
            return newEditorState;
          }, EditorChangeReason.Internal);

          onLinkedItemsInserted();
        }
      };

      const preselectedFilter: PreselectedFilter = {
        selectedCollectionsNodes: contentItemCollectionId
          ? new Set([contentItemCollectionId])
          : undefined,
        selectedContentTypesNodes: new Set(limitations.allowedTypes),
        selectedSpacesNodes: new Set(spaceIds),
      };

      const renderModalToViewer: Decorator<Render<LinkedItemsPlugin>> =
        (baseRenderModalToViewer) => (baseState) => {
          const { cancelNewBlock, editedBlockKey, focus } = baseState;
          if (editedBlockKey) {
            const content = baseState.editorState.getCurrentContent();
            const block = content.getBlockForKey(editedBlockKey);
            const newBlockType = getNewBlockPlaceholderType(block);
            if (newBlockType === BlockType.ContentModule) {
              return (
                <ModalMultipleContentItemsSelector
                  preselectedFilter={preselectedFilter}
                  onClose={() => {
                    cancelNewBlock(editedBlockKey);
                    focus();
                  }}
                  onSelect={(contentItemIds) => {
                    insertLinkedItems(editedBlockKey, contentItemIds);
                    focus();
                  }}
                  titleBarText={`Insert existing content items to ${editedEntityName}`}
                />
              );
            }
          }

          return baseRenderModalToViewer(baseState);
        };

      state.renderModalToViewer.decorate(renderModalToViewer);

      return {
        onLinkedItemsInserted,
      };
    },
    [
      contentItemCollectionId,
      editedEntityName,
      getAddBlockMenuItems,
      limitations,
      onHighlightedBlocksChanged,
      render,
      renderOverlays,
      spaceIds,
      trackUserEventWithData,
    ],
  );

  const { getApiMethods } = useEditorApi<LinkedItemsPlugin>(editorLinkedItemApi);

  return useEditorWithPlugin(props, { apply, getApiMethods });
};
