import { EditorState } from 'draft-js';
import { useCallback, useImperativeHandle, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply } from '../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../editorCore/types/EditorChangeReason.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import {
  RichTextContentChangeCallbackDebounce,
  RichTextSelectionChangeCallbackDebounce,
} from '../../editorCore/utils/editorComponentUtils.ts';
import { removeForcedSelection } from '../../utils/consistency/editorConsistencyUtils.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { OnUpdate } from '../draftJs/DraftJsPlugin.type.ts';

export type DebouncedChanges = {
  readonly propagatePendingContentChanges: () => Promise<void>;
};

const nonPropagatingChangeReasons: ReadonlyArray<EditorChangeReason> = [
  EditorChangeReason.ExternalUpdate,
  EditorChangeReason.Drag,
];

export type EditorChangeCallback = (
  editorState: EditorState,
  reason: EditorChangeReason,
) => Promise<void>;

type OnChangePluginProps = {
  readonly onContentChange: EditorChangeCallback;
  readonly onSelectionChange?: EditorChangeCallback;
  readonly debouncedChangesRef?: React.Ref<DebouncedChanges>;
};

type PropagateChanges = (editorState: EditorState, changeReason: EditorChangeReason) => void;

type OnChangePluginState = {
  readonly propagateChanges: PropagateChanges;
  readonly propagatePendingContentChanges: () => Promise<void>;
  readonly areContentChangesPending: () => boolean;
};

export type OnChangePlugin = DraftJsEditorPlugin<OnChangePluginState, OnChangePluginProps>;

export const OnChangePlugin: PluginComponent<OnChangePlugin> = (props) => {
  const { debouncedChangesRef, onContentChange, onSelectionChange } = props;

  const debouncedPropagateContentChange = useDebouncedCallback(
    onContentChange,
    RichTextContentChangeCallbackDebounce,
  );
  const debouncedPropagateSelectionChange = useDebouncedCallback(
    onSelectionChange ?? noSelectionChange,
    RichTextSelectionChangeCallbackDebounce,
  );

  const propagatePendingContentChanges = useCallback(async (): Promise<void> => {
    // Pending changes need to be propagated in case action based on selection is executed outside the editor
    // to ensure consistency of selection with the state available in the application state and prevent race conditions over state updates
    const promise = debouncedPropagateContentChange.flush();
    if (promise) {
      await promise;
    }
  }, [debouncedPropagateContentChange]);

  useImperativeHandle(debouncedChangesRef, () => ({ propagatePendingContentChanges }));

  const lastPropagatedState = useRef<EditorState | null>(null);

  const propagateChanges: PropagateChanges = useCallback(
    (editorState, changeReason) => {
      const contentChanged =
        editorState.getCurrentContent() !== lastPropagatedState.current?.getCurrentContent();
      const selectionChanged =
        editorState.getSelection() !== lastPropagatedState.current?.getSelection();

      lastPropagatedState.current = editorState;

      if (contentChanged) {
        // Editor never sends out forced selection within content change to be able to detect forced selection from outside
        const newStateWithoutForcedSelection = removeForcedSelection(editorState);
        debouncedPropagateContentChange(newStateWithoutForcedSelection, changeReason);
      }

      if (selectionChanged) {
        debouncedPropagateSelectionChange(editorState, changeReason);
      }
    },
    [debouncedPropagateSelectionChange, debouncedPropagateContentChange],
  );

  const onUpdate: Decorator<OnUpdate> = useCallback(
    (baseOnUpdate) => (params) => {
      const { editorState, changeReason } = params;

      if (nonPropagatingChangeReasons.includes(changeReason)) {
        return;
      }

      propagateChanges(editorState, changeReason);
      baseOnUpdate(params);
    },
    [propagateChanges],
  );

  const areContentChangesPending = useCallback(
    () => debouncedPropagateContentChange.isPending(),
    [debouncedPropagateContentChange],
  );

  const apply: Apply<OnChangePlugin> = useCallback(
    (state) => {
      state.onUpdate.decorate(onUpdate);

      return {
        propagateChanges,
        propagatePendingContentChanges,
        areContentChangesPending,
      };
    },
    [onUpdate, propagateChanges, propagatePendingContentChanges, areContentChangesPending],
  );

  return useEditorWithPlugin(props, { apply });
};

const noSelectionChange: EditorChangeCallback = () => Promise.resolve();
