import { ISelectItem, ISelectSection, Option } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { StyledStatusBarLanguageSelectorWrapper } from '@kontent-ai/component-library/StatusBar';
import { chain } from '@react-aria/utils';
import { PressEvent } from '@react-types/shared';
import Immutable from 'immutable';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import {
  DataUiAction,
  getDataUiActionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IListingLanguageSwitcherOption } from '../../../../../../../_shared/utils/languageSwitchers/getListingLanguageSwitcherOptions.ts';

export interface IContentListingLanguageSwitcherProps {
  readonly languages: Immutable.List<IListingLanguageSwitcherOption>;
  readonly selectedLanguageId: Uuid | null;
  readonly isSelectedLanguageEnsured: boolean;
  readonly onPress?: (event: PressEvent) => void;
}

interface ILanguageOption extends ISelectItem<ILanguageOption>, IListingLanguageSwitcherOption {}

export const ContentListingLanguageSwitcher: React.FC<IContentListingLanguageSwitcherProps> = ({
  languages,
  selectedLanguageId,
  onPress,
  isSelectedLanguageEnsured,
}) => {
  const languageOptions = useMemo((): ReadonlyArray<ISelectSection<ILanguageOption>> => {
    return [
      {
        id: 'languages',
        type: 'section',
        label: 'Language variants',
        items: languages.toArray().map((language) => {
          return {
            ...language,
            label: language.name,
          };
        }),
      },
    ];
  }, [languages]);

  const history = useHistory();
  const navigate = useCallback(
    (_id: Uuid, option: ILanguageOption | null): void => {
      if (option) {
        const { linkPath } = option;
        history.push(linkPath);
      }
    },
    [history],
  );

  const shouldShowSelector = languages.count() > 1;

  if (!shouldShowSelector || !isSelectedLanguageEnsured) {
    return null;
  }

  return (
    <StyledStatusBarLanguageSelectorWrapper>
      <SingleSelect
        css="width: 260px; min-width: 0"
        aria-label="Select a language variant"
        onSelectionChange={navigate}
        items={languageOptions}
        selectedItemId={selectedLanguageId}
        renderMenuOption={(optionProps) =>
          optionProps.item.value && (
            <Option
              {...optionProps}
              linkPath={optionProps.item.value.linkPath}
              onPress={chain(onPress, optionProps.onPress)}
              {...getDataUiObjectNameAttribute(optionProps.item.value.codename)}
            />
          )
        }
        {...getDataUiActionAttribute(DataUiAction.OpenLanguageSelector)}
      />
    </StyledStatusBarLanguageSelectorWrapper>
  );
};

ContentListingLanguageSwitcher.displayName = 'ContentListingLanguageSwitcher';
