import { useEnsuredContext } from '@kontent-ai/hooks';
import { useSubmenuTrigger } from '@react-aria/menu';
import { useSubmenuTriggerState } from '@react-stately/menu';
import { Key, Node } from '@react-types/shared';
import React, { PropsWithChildren, useContext, RefObject } from 'react';
import { VerticalMenuState } from '../../VerticalMenu/useNonAccessibleVerticalMenu.ts';
import { Descendant, useDescendantContext } from '../contexts/DescendantContext.tsx';
import { MenuContext } from '../contexts/MenuContext.tsx';
import {
  SubmenuContext,
  SubmenuContextProvider,
  useSubmenuContextInit,
} from '../contexts/SubmenuContext.tsx';

type InitializedSubmenuProps = Readonly<{
  node: Node<unknown>;
  submenuTriggerKey: Key;
  setSubmenuTriggerKey: React.Dispatch<React.SetStateAction<Key>>;
  submenuTriggerRef: RefObject<HTMLDivElement>;
  submenuListRef: RefObject<HTMLDivElement>;
}>;

const InitializedSubmenu = (props: PropsWithChildren<InitializedSubmenuProps>) => {
  const menuContext = useEnsuredContext(MenuContext);
  const submenuContext = useContext(SubmenuContext);

  const parentMenuRef = submenuContext?.submenuListRef ?? menuContext.menuListRef;

  const submenuTriggerState = useSubmenuTriggerState(
    {
      triggerKey: props.submenuTriggerKey,
    },
    menuContext.menuTriggerState,
  );

  const { submenuTriggerProps, submenuProps, popoverProps } = useSubmenuTrigger(
    {
      node: props.node,
      parentMenuRef,
      submenuRef: props.submenuListRef,
      type: 'menu',
    },
    submenuTriggerState,
    props.submenuTriggerRef,
  );

  return (
    <SubmenuContextProvider
      submenuTriggerProps={submenuTriggerProps}
      submenuTriggerState={submenuTriggerState}
      submenuListProps={submenuProps}
      popoverProps={popoverProps}
      {...props}
    />
  );
};

export const Submenu = (props: PropsWithChildren) => {
  const descendantContext = useDescendantContext<VerticalMenuState<Descendant>>();

  const { submenuTriggerKey, setSubmenuTriggerKey } = useSubmenuContextInit();

  const triggerRef = React.useRef(null);
  const submenuRef = React.useRef(null);

  const node = submenuTriggerKey
    ? descendantContext?.state.collection.getItem(submenuTriggerKey)
    : undefined;

  if (node && submenuTriggerKey) {
    return (
      <InitializedSubmenu
        node={node}
        submenuTriggerKey={submenuTriggerKey}
        setSubmenuTriggerKey={setSubmenuTriggerKey}
        submenuTriggerRef={triggerRef}
        submenuListRef={submenuRef}
      >
        {props.children}
      </InitializedSubmenu>
    );
  }

  return (
    <SubmenuContextProvider
      setSubmenuTriggerKey={setSubmenuTriggerKey}
      submenuTriggerRef={triggerRef}
      submenuListRef={submenuRef}
    >
      {props.children}
    </SubmenuContextProvider>
  );
};
