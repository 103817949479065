import { TabListState } from '@react-stately/tabs';
import { Key } from '@react-types/shared';
import { TabListProps } from '@react-types/tabs';
import React from 'react';

export type TabItem = {
  readonly id: Key;
  readonly label: string;
  readonly leadingElement?: React.ReactNode;
  readonly trailingElement?: React.ReactNode;
  readonly tooltipText?: string;
};

export type TabViewState = {
  readonly fullWidthTabs: boolean;
  readonly tabListState: TabListState<TabItem>;
  readonly tabListProps: TabListProps<TabItem>;
};

export const TabViewStateContext = React.createContext<TabViewState | undefined>(undefined);
TabViewStateContext.displayName = 'TabViewStateContext';

export const useTabViewState = (): TabViewState => {
  const state = React.useContext(TabViewStateContext);
  if (!state) {
    throw new Error(`${TabViewStateContext.displayName} context provider not found`);
  }
  return state;
};

/**
 * Hook for usage from outside the component when TabViewStateContext's presence may not be guaranteed or required.
 */
export const useOptionalTabViewState = (): TabViewState | undefined =>
  React.useContext(TabViewStateContext);
