import React, { useCallback } from 'react';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { canCreateItemVariant } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import {
  collapseContentComponent,
  expandContentComponent,
} from '../../../../../itemEditor/features/ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { useContentComponentType } from '../../../../hooks/useContentComponentType.ts';
import {
  ContentComponentItem as ContentComponentItemComponent,
  IContentComponentItemOwnProps,
} from '../../components/block/ContentComponentItem.tsx';

interface IContentComponentItemContainerProps extends IContentComponentItemOwnProps {
  readonly allowedTypeIds: ReadonlyArray<Uuid>;
  readonly disabled?: boolean;
}

export const ContentComponentItem: React.FC<IContentComponentItemContainerProps> = (props) => {
  const dispatch = useDispatch();

  const parentCollectionId = useSelector(
    (state) => state.contentApp.editedContentItem?.collectionId ?? null,
  );
  const selectedLanguageId = useSelector(getSelectedLanguageIdOrThrow);

  const contentType = useContentComponentType(props.contentComponentId);
  const containsNonLocalizableElements = !!contentType?.contentElements.some(
    (element) => element.isNonLocalizable,
  );
  const isEditingDefaultVariant = selectedLanguageId === DefaultVariantId;
  const isConversionPossible = !containsNonLocalizableElements || isEditingDefaultVariant;

  const canCreateContent = useSelector(
    (state) =>
      !!contentType &&
      !!parentCollectionId &&
      canCreateItemVariant(contentType.id, parentCollectionId, selectedLanguageId, state),
  );
  const isCollapsed = useSelector((state) =>
    state.contentApp.editorUi.collapsedContentComponents.contains(props.contentComponentId),
  );

  const onCollapse = useCallback((contentComponentId: Uuid) => {
    dispatch(collapseContentComponent(contentComponentId));
  }, []);

  const onExpand = useCallback((contentComponentId: Uuid) => {
    dispatch(expandContentComponent(contentComponentId));
  }, []);

  if (!contentType) {
    return null;
  }

  const isEntryTypeAllowed =
    props.allowedTypeIds.length === 0 || props.allowedTypeIds.includes(contentType.id);

  return (
    <ContentComponentItemComponent
      {...props}
      canCreateContent={canCreateContent && !props.disabled}
      canUpdate={!props.disabled}
      isCollapsed={isCollapsed}
      isConversionPossible={isConversionPossible}
      isEntryTypeAllowed={isEntryTypeAllowed}
      onCollapse={onCollapse}
      onExpand={onExpand}
    />
  );
};
