import { MenuItemWithMouseHover } from '@kontent-ai/component-library/MenuItem';
import { EditorState } from 'draft-js';
import { useCallback, useMemo, useState } from 'react';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { getStringActionResult } from '../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { useAiTask } from '../../../../../../_shared/features/AI/hooks/aiTasks/useAiTask.ts';
import { useOnFinishedAiActionTask } from '../../../../../../_shared/features/AI/hooks/aiTasks/useOnFinishedAiActionTask.ts';
import { useAiActionTrackingWithSession } from '../../../../../../_shared/features/AI/hooks/useAiActionTrackingWithSession.ts';
import { AiActionProps } from '../../../../../../_shared/features/AI/types/AiActionProps.type.ts';
import {
  AiActionSource,
  AiFollowingAction,
} from '../../../../../../_shared/models/events/AiActionEventData.type.ts';
import { Icon } from '../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AiActionName } from '../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createSummarizeRequest } from '../../../../../../repositories/serverModels/ai/actions/AiServerModels.summarize.ts';
import { useEditorStateCallbacks } from '../../../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../../editorCore/types/Editor.composition.type.ts';
import { None, Optional } from '../../../../editorCore/types/Editor.contract.type.ts';
import { Apply, Render } from '../../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../../editorCore/utils/decorable.ts';
import { createSimpleTextValueContent } from '../../../../utils/editorSimpleTextValueUtils.ts';
import { extractSelectedContent } from '../../../../utils/general/editorContentUtils.ts';
import { LockEditorPlugin } from '../../../behavior/LockEditorPlugin.tsx';
import { DraftJsEditorPlugin } from '../../../draftJs/DraftJsEditorPlugin.type.ts';
import { AiMenuItem, AiMenuPlugin, AiMenuSection, GetAiMenuItems } from '../../AiMenuPlugin.tsx';
import { getContentForActionInput } from '../../helpers/getContentForActionInput.ts';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard.ts';
import { ResultPositioner, useResultPositioner } from '../../hooks/useResultPositioner.tsx';
import { SummarizeAction } from './SummarizeAction.tsx';

export type SummarizePlugin = DraftJsEditorPlugin<
  None,
  AiActionProps,
  None,
  None,
  [LockEditorPlugin, Optional<AiMenuPlugin>]
>;

export const SummarizePlugin: PluginComponent<SummarizePlugin> = (props) => {
  const { element } = props;
  const [actionEditorState, setActionEditorState] = useState<EditorState | null>(null);

  const {
    aiSessionId,
    elementOperationTrackingData,
    resetAiSessionId,
    trackFinishedAction,
    trackFollowingAction,
    trackStartingAction,
  } = useAiActionTrackingWithSession(element);

  const { decorateWithEditorStateCallbacks, getEditorState, lockEditor, unlockEditor } =
    useEditorStateCallbacks<SummarizePlugin>();

  const { cancel, run, result } = useAiTask(AiActionName.Summarize, getStringActionResult);

  useOnFinishedAiActionTask(
    result.isFinished,
    () => result.trackingParams && trackFinishedAction(result.trackingParams),
  );

  const startSummarizeAction = useCallback(
    (editorState: EditorState, actionSource: AiActionSource) => {
      const contentState = extractSelectedContent(
        editorState.getCurrentContent(),
        editorState.getSelection(),
      );
      run(
        createSummarizeRequest(
          getContentForActionInput(element.elementType, contentState),
          elementOperationTrackingData,
        ),
      );

      trackStartingAction({ action: AiActionName.Summarize, source: actionSource });
    },
    [element, elementOperationTrackingData, run, trackStartingAction],
  );

  const start = useCallback(async () => {
    const editorState = getEditorState();
    await lockEditor(editorState);
    setActionEditorState(editorState);
    startSummarizeAction(editorState, AiActionSource.InlineToolbar);
  }, [lockEditor, getEditorState, startSummarizeAction]);

  const reset = useCallback(() => {
    if (!result.isFinished) {
      cancel();
    }
    resetAiSessionId();
    setActionEditorState(null);
    unlockEditor();
  }, [unlockEditor, cancel, result.isFinished, resetAiSessionId]);

  const tryAgain = useMemo(
    () =>
      result.isFinished
        ? () => {
            if (actionEditorState) {
              trackFollowingAction({ action: AiFollowingAction.TryAgain });
              startSummarizeAction(actionEditorState, AiActionSource.ActionMenu);
            }
          }
        : undefined,
    [startSummarizeAction, actionEditorState, result.isFinished, trackFollowingAction],
  );

  const { copyToClipboard } = useCopyToClipboard();

  const onCopyToClipboard = useMemo(() => {
    const content = result.content ? createSimpleTextValueContent(result.content) : null;

    return result.isFinished && content
      ? () => {
          trackFollowingAction({ action: AiFollowingAction.CopyToClipboard });
          reset();
          copyToClipboard(content, aiSessionId);
        }
      : undefined;
  }, [
    copyToClipboard,
    reset,
    result.content,
    result.isFinished,
    trackFollowingAction,
    aiSessionId,
  ]);

  const { decorateWithPositionerCallbacks, resultPositionerProps } = useResultPositioner(
    !!actionEditorState,
  );

  const renderOverlays: Decorator<Render<SummarizePlugin>> = useCallback(
    (baseRenderOverlays) => (state) => (
      <>
        {baseRenderOverlays(state)}
        {actionEditorState && (
          <ResultPositioner
            {...resultPositionerProps}
            renderResult={(isPositionedAboveContent, resultWidth, resultRef) => (
              <SummarizeAction
                onDiscard={() => {
                  trackFollowingAction({ action: AiFollowingAction.Discard });
                  reset();
                }}
                onCopyToClipboard={onCopyToClipboard}
                onTryAgain={tryAgain}
                preferMenuOnTop={isPositionedAboveContent}
                ref={resultRef}
                result={result}
                resultWidth={resultWidth}
              />
            )}
          />
        )}
      </>
    ),
    [
      actionEditorState,
      reset,
      onCopyToClipboard,
      result,
      resultPositionerProps,
      tryAgain,
      trackFollowingAction,
    ],
  );

  const getAiMenuItems: Decorator<GetAiMenuItems> = useCallback(
    (baseGetAiMenuItems) => (editorState) => {
      return [
        ...baseGetAiMenuItems(editorState),
        ...(isActionAvailable(editorState)
          ? [
              {
                id: 'summarize',
                label: 'Summarize',
                renderIntoMenu: (item, onActionStarted) => (
                  <MenuItemWithMouseHover
                    menuItemState="default"
                    text={item.label}
                    leadingElement={<Icon iconName={IconName.Bullseye} />}
                    onPress={() => {
                      onActionStarted();
                      start();
                    }}
                    {...getDataUiActionAttribute(DataUiRteAction.Summarize)}
                  />
                ),
                section: AiMenuSection.GenerateFromSelection,
                type: 'item',
              } satisfies AiMenuItem,
            ]
          : []),
      ];
    },
    [start],
  );

  const apply: Apply<SummarizePlugin> = useCallback(
    (state) => {
      decorateWithEditorStateCallbacks(state);
      decorateWithPositionerCallbacks(state);
      state.getInlineToolbarAiMenuItems?.decorate(getAiMenuItems);
      state.renderOverlays.decorate(renderOverlays);
      return {};
    },
    [
      decorateWithEditorStateCallbacks,
      decorateWithPositionerCallbacks,
      getAiMenuItems,
      renderOverlays,
    ],
  );

  return useEditorWithPlugin(props, { apply });
};

const isActionAvailable = (_: EditorState) => true;
