import { Collection } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../_shared/selectors/getSelectedLanguageId.ts';
import { ILoadListingContentItemsForInventory } from '../../../applications/contentInventory/content/features/ContentItemInventory/actions/thunks/loadListingItems/loadListingContentItems.ts';
import { IListingFilter } from '../../../applications/contentInventory/content/models/filter/IListingFilter.ts';
import { ILoadListingItemsAction } from '../../../applications/itemEditor/features/LoadedItems/actions/thunks/loadListingItems.ts';
import { AiSearchRepository } from '../repository/AiSearchRepository.type.ts';
import { AiSearchFilterServerModel } from '../repository/serverModels/AiSearchFilterServerModel.type.ts';
import { AiSearch_Finished, AiSearch_Started } from './aiSearchActionTypes.ts';

interface IDeps {
  readonly aiSearchRepository: AiSearchRepository;
  readonly loadListingItems: ILoadListingItemsAction;
}

export type AiSearchLoadListingContentItemsForInventoryActionsType = ReturnType<
  typeof finished | typeof started
>;

const started = () =>
  ({
    type: AiSearch_Started,
  }) as const;

const finished = (itemIds: ReadonlyArray<Uuid>) =>
  ({
    type: AiSearch_Finished,
    payload: { itemIds },
  }) as const;

export const createAiSearchLoadListingContentItemsForInventoryAction =
  (deps: IDeps): ILoadListingContentItemsForInventory =>
  (scrollPositionChanged, abortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    if (scrollPositionChanged) return;

    const state = getState();

    const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
    const filter = getAiSearchFilter(state.contentApp.listingUi.filter);

    dispatch(started());

    const result = await deps.aiSearchRepository.getListingItems(
      selectedLanguageId,
      filter,
      abortSignal,
    );
    const itemIds = result.items.map((item) => item.itemId);

    await dispatch(deps.loadListingItems(itemIds, abortSignal));

    dispatch(finished(itemIds));
  };

const getAiSearchFilter = (filter: IListingFilter): AiSearchFilterServerModel => ({
  assigneeUserIds: filter.selectedContributorsNodes.size
    ? Collection.getValues(filter.selectedContributorsNodes)
    : undefined,
  collectionIds: filter.selectedCollectionsNodes.size
    ? Collection.getValues(filter.selectedCollectionsNodes)
    : undefined,
  contentTypeIds: filter.selectedContentTypesNodes.size
    ? Collection.getValues(filter.selectedContentTypesNodes)
    : undefined,
  searchPhrase: filter.searchPhrase,
  spaceIds: filter.selectedSpacesNodes.size
    ? Collection.getValues(filter.selectedSpacesNodes)
    : undefined,
});
