import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';

type RefreshPreviewButtonProps = Readonly<{
  onClick: () => void;
}>;

export const RefreshPreviewButton: React.FC<RefreshPreviewButtonProps> = ({ onClick }) => (
  <Button buttonStyle="secondary" onClick={onClick}>
    <Button.Icon icon={Icons.RotateDoubleRight} />
    <Button.Label>Refresh</Button.Label>
  </Button>
);
