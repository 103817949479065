import Immutable from 'immutable';
import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { VariantCompletionStatus } from '../../../../../_shared/utils/contentItemVariantUtils.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { PublishingState } from '../../../../../data/constants/PublishingState.ts';
import { IAssignmentWorkflowStep } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { getCurrentProject } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { WidgetListingState } from '../../shared/types/WidgetListingState.type.ts';
import { decorateItemsRecentlyEditedByYou } from '../../tasksAssignedToYou/utils/yourContentItemDecorationUtils.ts';
import { RecentlyEditedByYouWidgetDetail } from '../components/RecentlyEditedByYouWidgetDetail.tsx';
import { RecentlyEditedByYouWidgetDetailRow } from '../components/RecentlyEditedByYouWidgetDetailRow.tsx';

type RecentlyEditedByYouWidgetDetailProps = React.PropsWithChildren<{
  readonly ariaLabelledBy: string;
  readonly onItemClick?: () => void;
  readonly dataState: WidgetListingState;
  readonly recentlyEditedItems: ReadonlyArray<IRecentlyEditedItemViewModel>;
}>;

export interface IRecentlyEditedItemViewModel {
  readonly id: ContentItemId;
  readonly contentType: string;
  readonly due: DateTimeStamp | null;
  readonly language: string;
  readonly lastUpdatedAt: DateTimeStamp | null;
  readonly name: string;
  readonly publishingState: PublishingState;
  readonly scheduledToPublishAt: DateTimeStamp | null;
  readonly scheduledToUnpublishAt: DateTimeStamp | null;
  readonly variantCompletionStatus: VariantCompletionStatus | null;
  readonly workflowStatus: IAssignmentWorkflowStep;
}

export const RecentlyEditedByYouWidgetDetailContainer: React.FC<
  RecentlyEditedByYouWidgetDetailProps
> = ({ ariaLabelledBy, onItemClick, dataState, recentlyEditedItems }) => {
  const loadingDependenciesFinished = useSelector(
    (s) =>
      s.data.contentTypes.loadingStatus === LoadingStatus.Loaded &&
      s.data.languages.byIdLoadingStatus === LoadingStatus.Loaded,
  );

  const contentTypes = useSelector((s) => s.data.contentTypes.byId);
  const languages = useSelector((s) => s.data.languages.byId);
  const viewableLanguages = useSelector((s) =>
    getAllActiveLanguagesForCurrentUserInAnyCollection(
      s.data.user,
      getCurrentProject(s).projectId,
      s.data.languages,
      Capability.ViewContent,
    ),
  );

  const decoratedRecentlyEditedItems: ReadonlyArray<IRecentlyEditedItemViewModel> =
    loadingDependenciesFinished
      ? decorateItemsRecentlyEditedByYou(
          Immutable.List(recentlyEditedItems),
          languages,
          viewableLanguages,
          contentTypes,
        ).toArray()
      : [];

  return (
    <RecentlyEditedByYouWidgetDetail
      state={loadingDependenciesFinished ? dataState : WidgetListingState.Loading}
      ariaLabelledBy={ariaLabelledBy}
    >
      {decoratedRecentlyEditedItems.map((recentlyEditedItem) => (
        <RecentlyEditedByYouWidgetDetailRow
          key={stringifyContentItemId(recentlyEditedItem.id)}
          onItemClick={onItemClick}
          recentlyEditedItem={recentlyEditedItem}
        />
      ))}
    </RecentlyEditedByYouWidgetDetail>
  );
};
