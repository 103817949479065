import React from 'react';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../../../_shared/constants/itemColumnCode.ts';
import { OrderBy } from '../../../../../../../_shared/models/OrderBy.ts';
import { ColumnSettingsSource } from '../../../../../../../_shared/models/events/ColumnSettingsEventData.type.ts';
import { ScrollTableCell } from '../../../../../../../_shared/uiComponents/ScrollTable/ScrollTableCell.tsx';
import { ScrollTableCheckboxCell } from '../../../../../../../_shared/uiComponents/ScrollTable/ScrollTableCheckboxCell.tsx';
import { ScrollTableHeadColumn } from '../../../../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadColumn.tsx';
import { ScrollTableHeadColumnClickable } from '../../../../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadColumnClickable.tsx';
import { ScrollTableHeadRow } from '../../../../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadRow.tsx';
import {
  DataUiCollection,
  DataUiElement,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ColumnSettings } from '../../containers/ItemInventoryScrollTable/ColumnSettings.tsx';

type ContentItemScrollTableHeadDataProps = {
  readonly contentListingOrderBy: OrderBy<ItemColumnCode> | null;
  readonly hiddenColumns: ReadonlySet<ItemColumnCode>;
  readonly hideColumnSettingsButton?: boolean;
  readonly isSelectAllOn?: boolean;
  readonly renderCheckboxPlaceholder?: boolean;
  readonly renderOpenItemInNewTab?: boolean;
  readonly selectAllDisabledMessage?: string;
  readonly source: ColumnSettingsSource;
};

type ContentItemScrollTableHeadCallbackProps = {
  readonly onCheckboxClick?: () => void;
  readonly onColumnHeadClicked: (columnCode: ItemColumnCode) => void;
};

export type ContentItemScrollTableHeadProps = ContentItemScrollTableHeadDataProps &
  ContentItemScrollTableHeadCallbackProps;

export const ContentItemScrollTableHead: React.FC<ContentItemScrollTableHeadProps> = ({
  contentListingOrderBy,
  hiddenColumns,
  hideColumnSettingsButton,
  isSelectAllOn,
  onCheckboxClick,
  onColumnHeadClicked,
  renderCheckboxPlaceholder,
  renderOpenItemInNewTab,
  selectAllDisabledMessage,
  source,
}) => {
  const SortableColumn = contentListingOrderBy
    ? ScrollTableHeadColumnClickable
    : ScrollTableHeadColumn;

  return (
    <ScrollTableHeadRow collectionName={DataUiCollection.SortOptions}>
      {renderCheckboxPlaceholder && <ScrollTableCell size={1} />}
      {!renderCheckboxPlaceholder && onCheckboxClick && (
        <ScrollTableCheckboxCell
          id="select-all"
          name="Select all"
          isItemChecked={!!isSelectAllOn}
          onCheckboxClick={onCheckboxClick}
          disabledMessage={selectAllDisabledMessage}
        />
      )}
      <SortableColumn
        dataUiElement={DataUiElement.ColumnHeaderName}
        name={translateColumnCodeToTitle(ItemColumnCode.Name)}
        code={ItemColumnCode.Name}
        currentOrdering={contentListingOrderBy}
        onColumnHeadClicked={onColumnHeadClicked}
        size={10}
        isGrowing
      />
      {renderOpenItemInNewTab && (
        <div className="scroll-table__column-head scroll-table__column--1" />
      )}
      {!hiddenColumns.has(ItemColumnCode.WorkflowStep) && (
        <ScrollTableHeadColumn
          dataUiElement={DataUiElement.ColumnHeaderWorkflowStep}
          name={translateColumnCodeToTitle(ItemColumnCode.WorkflowStep)}
          size={4}
          isGrowing
        />
      )}
      {!hiddenColumns.has(ItemColumnCode.ContentType) && (
        <ScrollTableHeadColumn
          dataUiElement={DataUiElement.ColumnHeaderContentType}
          name={translateColumnCodeToTitle(ItemColumnCode.ContentType)}
          size={7}
          isGrowing
        />
      )}
      {!hiddenColumns.has(ItemColumnCode.Collection) && (
        <ScrollTableHeadColumn
          dataUiElement={DataUiElement.ColumnHeaderCollection}
          name={translateColumnCodeToTitle(ItemColumnCode.Collection)}
          size={7}
          isGrowing
        />
      )}
      {!hiddenColumns.has(ItemColumnCode.Space) && (
        <ScrollTableHeadColumn
          dataUiElement={DataUiElement.ColumnHeaderSpace}
          name={translateColumnCodeToTitle(ItemColumnCode.Space)}
          size={7}
          isGrowing
        />
      )}
      {!hiddenColumns.has(ItemColumnCode.DueDate) && (
        <SortableColumn
          dataUiElement={DataUiElement.ColumnHeaderDueDate}
          name={translateColumnCodeToTitle(ItemColumnCode.DueDate)}
          code={ItemColumnCode.DueDate}
          currentOrdering={contentListingOrderBy}
          onColumnHeadClicked={onColumnHeadClicked}
          size={4}
          isGrowing
        />
      )}
      {!hiddenColumns.has(ItemColumnCode.LastPublishedAt) && (
        <SortableColumn
          dataUiElement={DataUiElement.ColumnHeaderPublished}
          name={translateColumnCodeToTitle(ItemColumnCode.LastPublishedAt)}
          code={ItemColumnCode.LastPublishedAt}
          currentOrdering={contentListingOrderBy}
          onColumnHeadClicked={onColumnHeadClicked}
          size={4}
        />
      )}
      {!hiddenColumns.has(ItemColumnCode.LastModifiedAt) && (
        <SortableColumn
          dataUiElement={DataUiElement.ColumnHeaderLastModified}
          name={translateColumnCodeToTitle(ItemColumnCode.LastModifiedAt)}
          code={ItemColumnCode.LastModifiedAt}
          currentOrdering={contentListingOrderBy}
          onColumnHeadClicked={onColumnHeadClicked}
          size={4}
        />
      )}

      {!hideColumnSettingsButton && <ColumnSettings source={source} />}
    </ScrollTableHeadRow>
  );
};

ContentItemScrollTableHead.displayName = 'ContentItemScrollTableHead';
