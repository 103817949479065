import { useEnsuredContext } from '@kontent-ai/hooks';
import { ICancellablePromise, delay, swallowCancelledPromiseError } from '@kontent-ai/utils';
import React, { PropsWithChildren, useEffect } from 'react';
import { ComponentLibraryGlobals } from '../../../globals/componentLibraryGlobals.ts';
import { Box } from '../../../layout/Box/Box.tsx';
import {
  colorAlertIcon,
  colorAlertText,
  colorSuccessIcon,
  colorSuccessText,
} from '../../../tokens/decision/colors.ts';
import { copyToClipboardDelayMs } from '../../../tokens/decision/transitions.ts';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { Icons } from '../../Icons/components/icons.ts';
import { MenuContext } from '../contexts/MenuContext.tsx';
import { MenuItem, MenuItemProps } from './MenuItem.tsx';

type CopyStatus = 'initial' | 'success' | 'error';

type Props = Omit<MenuItemProps, 'leadingElement' | 'onAction'> &
  Readonly<{
    /** Make sure the icon has size={IconSize.S} */
    initialLeadingElement?: MenuItemProps['leadingElement'];
    copySuccessLabel?: string;
    copyErrorLabel?: string;
    onCopySuccess?: () => void;
    onCopyError?: () => void;
    /** String value which is to be copied */
    value: string;
  }>;

export const ClipboardItem = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      copySuccessLabel = 'Copied',
      copyErrorLabel = 'Copying failed',
      label: labelFromProps,
      initialLeadingElement = <Icons.Chain size={IconSize.S} />,
      onCopySuccess,
      onCopyError,
      value,
      ...otherProps
    },
    forwardedRef,
  ) => {
    const menuContext = useEnsuredContext(MenuContext);
    const [copyStatus, setCopyStatus] = React.useState<CopyStatus>('initial');
    const delayedCloseRef = React.useRef<ICancellablePromise | null>(null);

    const getIcon = (status: CopyStatus) => {
      switch (status) {
        case 'initial':
          return initialLeadingElement;
        case 'success':
          return <Icons.CbCheck color={colorSuccessIcon} size={IconSize.S} />;
        case 'error':
          return <Icons.ExclamationTriangleInverted color={colorAlertIcon} size={IconSize.S} />;
      }
    };

    const getLabelText = (status: CopyStatus) => {
      switch (status) {
        case 'initial':
          return labelFromProps;
        case 'success':
          return copySuccessLabel;
        case 'error':
          return copyErrorLabel;
      }
    };

    /** We change the menu item label to force screen reader to properly announce the copy status. */
    const getRenderLabel = (status: CopyStatus) => {
      switch (status) {
        case 'initial':
          return labelFromProps;
        case 'success':
          return <Box color={colorSuccessText}>{copySuccessLabel}</Box>;
        case 'error':
          return <Box color={colorAlertText}>{copyErrorLabel}</Box>;
      }
    };

    const onAction = async () => {
      try {
        await navigator.clipboard.writeText(value);
        setCopyStatus('success');
        onCopySuccess?.();
      } catch (error) {
        if (!(error instanceof DOMException && error.name === 'NotAllowedError')) {
          ComponentLibraryGlobals.logError(error);
        }
        setCopyStatus('error');
        onCopyError?.();
      } finally {
        delayedCloseRef.current = delay(copyToClipboardDelayMs)
          .then(() => menuContext.menuTriggerState.close())
          .catch(swallowCancelledPromiseError);
      }
    };

    useEffect(() => {
      return () => {
        delayedCloseRef.current?.cancel();
      };
    }, []);

    return (
      <MenuItem
        ref={forwardedRef}
        onAction={onAction}
        closeOnSelect={false}
        /** Even though we're using renderLabel, we still need to provide text label */
        label={getLabelText(copyStatus)}
        renderLabel={() => getRenderLabel(copyStatus)}
        leadingElement={getIcon(copyStatus)}
        {...otherProps}
      />
    );
  },
);
