import { ISelectItem, ISelectSection, ItemId, Option } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { StyledStatusBarLanguageSelectorWrapper } from '@kontent-ai/component-library/StatusBar';
import { WorkflowStatus } from '@kontent-ai/component-library/WorkflowStatus';
import { chain } from '@react-aria/utils';
import Immutable from 'immutable';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { NotTranslatedTagForVariant } from '../../../../../../_shared/components/Workflow/NotTranslatedTagForVariant.tsx';
import { isNewVersion } from '../../../../../../_shared/utils/contentItemVariantUtils.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { renderWorkflowStepColor } from '../../../../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import { ILanguageSwitcherOption } from '../../../../../contentInventory/content/models/ILanguageSwitcherOption.type.ts';
import { publishedStepConstantValues } from '../../../../../environmentSettings/workflow/constants/publishedStepValues.ts';

export interface IContentEditorLanguageSwitcherProps {
  readonly onLanguageSwitch: () => void;
  readonly options: Immutable.List<ILanguageSwitcherOption>;
  readonly selectedLanguageId: Uuid;
}

interface IItemLanguageSwitcherOption
  extends ISelectItem<IItemLanguageSwitcherOption>,
    ILanguageSwitcherOption {}

export const ContentEditorLanguageSwitcher: React.FC<IContentEditorLanguageSwitcherProps> = ({
  onLanguageSwitch,
  options,
  selectedLanguageId,
}) => {
  const languageOptions = useMemo((): ReadonlyArray<
    ISelectSection<IItemLanguageSwitcherOption>
  > => {
    return [
      {
        id: 'language-options',
        type: 'section',
        label: 'Language variants',
        items: options.toArray().map((option) => ({
          ...option,
          id: option.languageId,
          label: option.languageName,
        })),
      },
    ];
  }, [options]);

  const history = useHistory();
  const navigate = useCallback(
    (_id: ItemId | null, node: IItemLanguageSwitcherOption | null): void => {
      if (node) {
        const { linkPath } = node;
        history.push(linkPath);
      }
    },
    [history],
  );

  return (
    <StyledStatusBarLanguageSelectorWrapper>
      <SingleSelect
        aria-label="Select a language variant"
        onSelectionChange={navigate}
        items={languageOptions}
        selectedItemId={selectedLanguageId}
        disabledItemIds={
          languageOptions[0]?.items
            .map((languageOption) => (languageOption.isDisabled ? languageOption.languageId : ''))
            .filter(Boolean) ?? []
        }
        renderMenuOption={(optionProps) => {
          const {
            item: { value: option },
          } = optionProps;
          if (!option) {
            return null;
          }

          const hasOlderPublishedVersion = isNewVersion({
            publishingState: option.publishingState,
            workflowStatus: option.workflowStatus,
          });
          return (
            <Option
              {...optionProps}
              linkPath={option.linkPath}
              onPress={chain(onLanguageSwitch, optionProps.onPress)}
              {...getDataUiObjectNameAttribute(option.codename)}
              trailingElements={
                option.doesVariantExist ? (
                  <WorkflowStatus
                    primary={{
                      name: option.workflowStatus.name,
                      background: renderWorkflowStepColor(option.workflowStatus.color),
                    }}
                    secondary={
                      hasOlderPublishedVersion
                        ? {
                            background: renderWorkflowStepColor(publishedStepConstantValues.color),
                            name: publishedStepConstantValues.name,
                          }
                        : undefined
                    }
                  />
                ) : (
                  <NotTranslatedTagForVariant />
                )
              }
            />
          );
        }}
        {...getDataUiActionAttribute(DataUiAction.OpenLanguageSelector)}
      />
    </StyledStatusBarLanguageSelectorWrapper>
  );
};

ContentEditorLanguageSwitcher.displayName = 'ContentEditorLanguageSwitcher';
