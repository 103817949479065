import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect, MultiSelectState } from '@kontent-ai/component-library/MultiSelect';
import {
  CollapsibleOption,
  ItemId,
  RenderSelectMenuOptionProps,
} from '@kontent-ai/component-library/Selects';
import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import { memoize } from '@kontent-ai/memoization';
import { Collection, createCompare, naturally } from '@kontent-ai/utils';
import Immutable from 'immutable';
import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithCount } from '../../../../../../../_shared/utils/stringUtils.ts';
import { isUuid } from '../../../../../../../_shared/utils/validation/typeValidators.ts';
import { calculateTagBackgroundColor } from '../../../../../../../_shared/utils/workflow/calculateTagBackgroundColor.ts';
import { TagColor } from '../../../../../../../data/constants/tagColor.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import {
  IRule,
  IRuleWithScope,
  ITypeRuleSetting,
} from '../../../../../../../data/models/roles/IRoleSettings.ts';
import { IContentGroup } from '../../../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';

type MultiSelectProps = ComponentProps<typeof MultiSelect<Option>>;

interface ICapabilityTypesAndGroupsSelectorDataProps
  extends Pick<MultiSelectProps, 'placeholder' | 'placeholderType'> {
  readonly isDisabled: boolean;
  readonly allTypes: Immutable.Map<Uuid, IContentType>;
  readonly capabilityRule: IRuleWithScope | IRule;
  readonly validationMessage?: string;
}

interface ICapabilityTypesAndGroupsSelectorCallbackProps {
  readonly onTypeAdded: (params: { typeId: Uuid }) => void;
  readonly onTypeRemoved: (params: { typeId: Uuid }) => void;
  readonly onGroupAdded: (params: { typeId: Uuid; groupId: Uuid }) => void;
  readonly onGroupRemoved: (params: { typeId: Uuid; groupId: Uuid }) => void;
}

type Props = ICapabilityTypesAndGroupsSelectorDataProps &
  ICapabilityTypesAndGroupsSelectorCallbackProps;

type TypeOption = {
  readonly groupId: Uuid | null;
  readonly hasGroups: boolean;
  readonly id: Uuid;
  readonly isDeleted: boolean;
  readonly label: string;
  readonly optionType: 'ContentType';
  readonly typeId: Uuid;
  readonly items: ReadonlyArray<GroupOption>;
};

type GroupOption = {
  readonly groupId: Uuid;
  readonly id: Uuid;
  readonly isDeleted: boolean;
  readonly label: string;
  readonly optionType: 'ContentGroup';
  readonly typeId: Uuid;
};

type Option = Readonly<TypeOption | GroupOption>;

const getGroupOptionId = (typeId: Uuid, groupId: Uuid) => `${typeId}#${groupId}`;

/**
 * Parses a composite option ID created by getGroupOptionId.
 * Or returns just typeId in case you pass a string with just one Uuid.
 * @param id Can be a simple Uuid or a composite id created by getGroupOptionId.
 * @returns An object with typeId and optionally groupId.
 */
const parseGroupOptionId = (id: string) => {
  const [typeId, groupId] = id.split('#');
  return {
    typeId: typeId as string,
    groupId,
  };
};

export const AllTypesPlaceholder: Option = {
  groupId: null,
  hasGroups: false,
  id: 'defaultEmptyType',
  isDeleted: false,
  label: 'Any content type and group',
  optionType: 'ContentType',
  typeId: 'defaultEmptyType',
  items: [],
};

const getDeletedTypeOption = (deletedTypeId: Uuid): TypeOption => ({
  groupId: null,
  hasGroups: false,
  id: deletedTypeId,
  isDeleted: true,
  label: 'Deleted type',
  optionType: 'ContentType',
  typeId: deletedTypeId,
  items: [],
});

const getDeletedGroupOption = (type: IContentType, groupId: Uuid): GroupOption => ({
  groupId,
  id: getGroupOptionId(type.id, groupId),
  isDeleted: true,
  label: 'Deleted group',
  optionType: 'ContentGroup',
  typeId: type.id,
});

const getExistingTypeOption = (
  type: IContentType,
  groups: ReadonlyArray<GroupOption>,
): TypeOption => ({
  groupId: null,
  hasGroups: type.contentGroups.size !== 0,
  id: type.id,
  isDeleted: false,
  label: type.name,
  optionType: 'ContentType',
  typeId: type.id,
  items: groups,
});

const getExistingGroupOption = (type: IContentType, group: IContentGroup): GroupOption => ({
  groupId: group.id,
  id: getGroupOptionId(type.id, group.id),
  isDeleted: false,
  label: group.name,
  optionType: 'ContentGroup',
  typeId: type.id,
});

const getTypeAndGroupOptions = (type: IContentType): TypeOption =>
  getExistingTypeOption(
    type,
    type.contentGroups.map((g: IContentGroup) => getExistingGroupOption(type, g)).toArray(),
  );

const getGroupOption = (
  type: IContentType,
  groupId: Uuid,
  group: IContentGroup | null,
): GroupOption =>
  group ? getExistingGroupOption(type, group) : getDeletedGroupOption(type, groupId);

const getSelectedTypeAndGroupOptions = (
  typeSetting: ITypeRuleSetting,
  allTypes: Immutable.Map<Uuid, IContentType>,
): ReadonlyArray<Option> => {
  const type = allTypes.get(typeSetting.typeId);
  if (!type) {
    return [getDeletedTypeOption(typeSetting.typeId)];
  }

  return [
    getExistingTypeOption(type, []),
    ...typeSetting.contentGroupIds
      .map((groupId: Uuid) =>
        getGroupOption(
          type,
          groupId,
          type.contentGroups.find((g: IContentGroup) => g.id === groupId) ?? null,
        ),
      )
      .toArray(),
  ];
};

const getAllExistingOptions = memoize.maxOne(
  (allTypes: Immutable.Map<Uuid, IContentType>): ReadonlyArray<TypeOption> =>
    allTypes
      .sort(
        createCompare({
          compare: naturally,
          select: (t) => t.name,
        }),
      )
      .map(getTypeAndGroupOptions)
      .toArray(),
);

const getSelectedOptionIds = memoize.weak(
  (typeSettings: Props['capabilityRule']['types'], allTypes: Props['allTypes']) => {
    return typeSettings
      .flatMap((typeSetting) => getSelectedTypeAndGroupOptions(typeSetting, allTypes))
      .map((typeGroup) => typeGroup.id);
  },
);

const getInputState = (
  isDisabled: boolean,
  validationMessage: string | null | undefined,
): InputState => {
  if (isDisabled) {
    return InputState.Disabled;
  }
  if (validationMessage) {
    return InputState.Alert;
  }

  return InputState.Default;
};

const getSelectedTypeLabelAndBackground = (
  typeItem: TypeOption,
  type: IContentType | null | undefined,
  selectedIds: ReadonlyArray<string>,
): {
  readonly label: ComponentProps<typeof Tag>['label'];
  readonly background?: ComponentProps<typeof Tag>['background'];
} => {
  const numberOfSelectedGroups = selectedIds.filter((key) =>
    key.startsWith(getGroupOptionId(typeItem.typeId, '')),
  ).length;
  const hasAlert = typeItem.isDeleted || (!typeItem.hasGroups && numberOfSelectedGroups !== 0);

  if (numberOfSelectedGroups === type?.contentGroups.size) {
    return {
      label: `${typeItem.label} - All groups`,
      background: hasAlert ? calculateTagBackgroundColor(TagColor.Red) : undefined,
    };
  }

  if (typeItem.isDeleted) {
    return {
      label: typeItem.label,
      background: calculateTagBackgroundColor(TagColor.Red),
    };
  }

  return {
    label: hasAlert
      ? `${typeItem.label} - ${pluralizeWithCount('deleted group', numberOfSelectedGroups)}`
      : `${typeItem.label} - ${pluralizeWithCount('group', numberOfSelectedGroups)}`,
    background: hasAlert ? calculateTagBackgroundColor(TagColor.Red) : undefined,
  };
};

const getRenderCollapsibleMenuTypeOption =
  (disableCollapse: boolean) =>
  (optionProps: RenderSelectMenuOptionProps<TypeOption | GroupOption>) => {
    const isUnselectedDeletedOption =
      !optionProps.state.getSelectionManager().isSelected(optionProps.item.key) &&
      optionProps.item.value?.isDeleted;

    return (
      <CollapsibleOption
        {...optionProps}
        disabledTooltipText={
          disableCollapse ? 'You can’t collapse the list when filtering.' : undefined
        }
        isDisabled={disableCollapse || isUnselectedDeletedOption}
      />
    );
  };

const getSelectedTypeOptionRenderer = memoize.maxOne(
  (allTypes: Immutable.Map<Uuid, IContentType>) =>
    (
      selectedItemId: ItemId,
      selectedItem: TypeOption | GroupOption,
      defaultTagProps: ComponentProps<typeof DefaultTag>,
      state: MultiSelectState<TypeOption | GroupOption>,
    ): React.ReactNode => {
      if (selectedItem.optionType === 'ContentGroup') {
        return null;
      }

      const { background, ...restProps } = getSelectedTypeLabelAndBackground(
        selectedItem,
        allTypes.get(selectedItemId),
        Array.from(state.getSelectionManager().selectedKeys).map((key) => key.toString()),
      );
      if (background) {
        return <Tag {...defaultTagProps} background={background} {...restProps} />;
      }

      return <DefaultTag {...defaultTagProps} {...restProps} />;
    },
);

const getComprehensiveOptions = memoize.weak(
  (
    selectedDeletedItems: ReadonlyArray<string>,
    allTypes: Immutable.Map<Uuid, IContentType>,
  ): ReadonlyArray<Option> => {
    const existingOptions = getAllExistingOptions(allTypes);
    return selectedDeletedItems.reduce((options, selectedDeletedId) => {
      const { typeId, groupId } = parseGroupOptionId(selectedDeletedId);
      if (typeId) {
        if (groupId) {
          const type = allTypes.get(typeId);
          if (type) {
            const newOption = getDeletedGroupOption(type, groupId);
            const existingTypeOption = existingOptions.find(
              (typeOption) => typeOption.id === typeId,
            );
            if (existingTypeOption && existingTypeOption.optionType === 'ContentType') {
              const typeOptionClone = {
                ...existingTypeOption,
                items: [newOption, ...existingTypeOption.items],
              };

              return options.map((option) => {
                if (option.id === typeOptionClone.id) {
                  return typeOptionClone;
                }
                return option;
              });
            }
          }
        }

        const deletedTypeOption = getDeletedTypeOption(typeId);
        return [deletedTypeOption, ...options];
      }
      return options;
    }, existingOptions);
  },
);

export const CapabilityTypesAndGroupsSelector: React.FC<Props> = (props) => {
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [expandedOptionIds, setExpandedOptionIds] = useState<ReadonlySet<Uuid>>(new Set());
  const [selectedDeletedItems, setSelectedDeletedItems] = useState<ReadonlyArray<string>>([]);

  const selectedItemIds = getSelectedOptionIds(props.capabilityRule.types, props.allTypes);
  const unselectedDeletedIds = Collection.removeMany(
    new Set(selectedDeletedItems),
    selectedItemIds,
  );

  useEffect(() => {
    const newSelectedIds = Collection.removeMany(new Set(selectedItemIds), selectedDeletedItems);
    const idsOfExistingOptions = getAllExistingOptions(props.allTypes).flatMap((option) => [
      option.id,
      ...option.items.map((groupOption) => groupOption.id),
    ]);
    const newIdsOfRemovedGroupsAndTypes = Collection.removeMany(
      newSelectedIds,
      idsOfExistingOptions,
    );

    if (newIdsOfRemovedGroupsAndTypes.size > 0) {
      setSelectedDeletedItems(
        Array.from(Collection.addMany(newIdsOfRemovedGroupsAndTypes, selectedDeletedItems)),
      );
    }
  }, [props.allTypes, selectedDeletedItems, selectedItemIds]);

  const comprehensiveOptions = getComprehensiveOptions(selectedDeletedItems, props.allTypes);
  const allTypeOptionIds = useMemo(
    () => new Set(comprehensiveOptions.map((option) => option.typeId)),
    [comprehensiveOptions],
  );

  const onSelectionChange = (newSelectedIds: ReadonlySet<string>) => {
    const addedOptionIds = Collection.removeMany(newSelectedIds, selectedItemIds);
    const removedOptionIds = Collection.removeMany(
      new Set(selectedItemIds),
      Array.from(newSelectedIds),
    );

    addedOptionIds.forEach((addedOptionId) => {
      const { typeId, groupId } = parseGroupOptionId(addedOptionId);
      if (isUuid(groupId)) {
        props.onGroupAdded({
          typeId,
          groupId,
        });
      } else {
        props.onTypeAdded({ typeId });
      }
    });

    setExpandedOptionIds(new Set([...expandedOptionIds, ...addedOptionIds]));

    removedOptionIds.forEach((removedOptionId) => {
      const { typeId, groupId } = parseGroupOptionId(removedOptionId);
      if (isUuid(groupId)) {
        props.onGroupRemoved({
          typeId,
          groupId,
        });
      } else {
        props.onTypeRemoved({ typeId });
      }
    });
  };

  const setFilteringIfNotEmpty = useCallback((inputText: string): void => {
    setIsFiltering(!!inputText);
  }, []);

  return (
    <MultiSelect
      aria-label="Select an option"
      caption={props.validationMessage}
      expandedKeys={isFiltering ? allTypeOptionIds : expandedOptionIds}
      inputState={getInputState(props.isDisabled, props.validationMessage)}
      items={comprehensiveOptions}
      onExpandedChange={setExpandedOptionIds}
      onInputChange={setFilteringIfNotEmpty}
      onSelectionChange={onSelectionChange}
      placeholder={props.placeholder}
      placeholderType={props.placeholderType}
      renderMenuOption={getRenderCollapsibleMenuTypeOption(isFiltering)}
      renderSelectedOption={getSelectedTypeOptionRenderer(props.allTypes)}
      selectedItemIds={selectedItemIds}
      verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.ContentTypes)}
      disabledItemIds={unselectedDeletedIds}
      {...getDataUiCollectionAttribute(DataUiCollection.ContentTypes)}
    />
  );
};

CapabilityTypesAndGroupsSelector.displayName = 'CapabilityTypesAndGroupsSelector';
