import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';

type Props = { readonly count?: number };

export const NonExistentBarItemActions = ({ count = 1 }: Props) => {
  const nonExistentActions = Array.from(Array(count).keys()).map((i) => (
    <Box key={i} width={Spacing.XL} />
  ));

  return <>{nonExistentActions}</>;
};
