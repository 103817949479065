import React from 'react';
import { BarItemBar } from './BarItemBar.tsx';

export const BarItemSkelet: React.FC = () => (
  <div className="bar-item__node">
    <div className="bar-item__wrapper">
      <div className="bar-item__pane">
        <BarItemBar className="bar-item__bar--is-skeleton">
          <div className="bar-item__skelet-pane" />
        </BarItemBar>
      </div>
    </div>
  </div>
);

BarItemSkelet.displayName = 'BarItemSkelet';
