import { EditorBlock } from 'draft-js';
import React from 'react';
import {
  DragMoveHandler,
  HoveringCollisionStrategy,
} from '../../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { IEditorBlockProps } from '../../../utils/blocks/editorBlockUtils.ts';

export type DroppableBlockProps = {
  readonly canUpdate: boolean;
  readonly hoveringCollisionStrategy: HoveringCollisionStrategy;
  readonly onMove: DragMoveHandler;
  readonly parentId: Uuid;
};

export class DroppableBlockWrapperBase<T extends DroppableBlockProps> extends React.Component<T> {
  shouldComponentUpdate(nextProps: Readonly<T>): boolean {
    return (
      nextProps.parentId !== this.props.parentId ||
      nextProps.canUpdate !== this.props.canUpdate ||
      nextProps.onMove !== this.props.onMove
    );
  }

  shouldBlockUpdate(
    editorBlockForUpdateCheck: EditorBlock,
    nextChildProps: Omit<IEditorBlockProps, 'blockProps'>,
  ): boolean {
    // We delegate the shouldComponentUpdate to DraftJS to avoid extra maintenance while updating DraftJS library
    return (
      !editorBlockForUpdateCheck.shouldComponentUpdate ||
      editorBlockForUpdateCheck.shouldComponentUpdate(
        nextChildProps,
        editorBlockForUpdateCheck.state,
        editorBlockForUpdateCheck.context,
      )
    );
  }
}
