import { Box } from '@kontent-ai/component-library/Box';
import { VerticalTabs } from '@kontent-ai/component-library/VerticalTabs';
import { createCustomAppDetailLink } from '../../../_shared/utils/routing/projectRoutingUtils.ts';
import { CustomApp } from '../../../data/models/customApps/CustomApp.ts';

export type CustomAppsMenuStateProps = {
  readonly activeCustomAppId?: Uuid;
  readonly customApps: ReadonlyArray<CustomApp>;
  readonly projectId: string;
};

export const CustomAppsMenu = ({
  activeCustomAppId,
  customApps,
  projectId,
}: CustomAppsMenuStateProps) => {
  return (
    <Box
      overflowX="hidden"
      overflowY="auto"
      flexGrow={0}
      flexShrink={0}
      flexBasis="auto"
      display="flex"
    >
      <VerticalTabs>
        {customApps.map((customApp) => (
          <VerticalTabs.Item
            key={customApp.id}
            label={customApp.name}
            isActive={activeCustomAppId === customApp.id}
            url={createCustomAppDetailLink({
              projectContainerId: projectId,
              customAppId: customApp.id,
            })}
          />
        ))}
      </VerticalTabs>
    </Box>
  );
};
