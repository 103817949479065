import { useAttachRef, useEnsuredContext } from '@kontent-ai/hooks';
import { mergeRefs } from '@react-aria/utils';
import { ListState } from '@react-stately/list';
import { Key } from '@react-types/shared';
import React, { useState, useMemo, useCallback, RefObject, RefCallback, Ref } from 'react';
import { VerticalMenuState } from '../../VerticalMenu/useNonAccessibleVerticalMenu.ts';
import { findDOMIndex } from '../utils.ts';

export type BaseDescendantProps = Readonly<{
  id: string;
  label: string;
}>;

export type Descendant = BaseDescendantProps &
  Readonly<{
    href?: string;
    elementRef: RefObject<HTMLElement>;
    onAction?: (key: Key) => void;
    target?: string;
  }>;

type DescendantContextState<
  TState extends VerticalMenuState<TDescendant> | ListState<TDescendant>,
  TDescendant extends Descendant = Descendant,
> = Readonly<{
  descendants: ReadonlyArray<Descendant>;
  registerDescendant: (menuItem: Descendant) => void;
  unregisterDescendant: (id: Descendant['id']) => void;
  state: TState;
}>;

const DescendantContext = React.createContext<DescendantContextState<
  VerticalMenuState<Descendant> | ListState<Descendant>
> | null>(null);

DescendantContext.displayName = 'DescendantContext';

type DescendantContextProviderProps<
  TState extends VerticalMenuState<TDescendant> | ListState<TDescendant>,
  TDescendant extends Descendant = Descendant,
> = Readonly<{
  children: React.ReactNode;
  descendants: ReadonlyArray<Descendant>;
  setDescendants: React.Dispatch<React.SetStateAction<ReadonlyArray<Descendant>>>;
  state: TState;
}>;

export const DescendantContextProvider = <
  TState extends VerticalMenuState<TDescendant> | ListState<TDescendant>,
  TDescendant extends Descendant = Descendant,
>({
  children,
  descendants,
  setDescendants,
  state,
}: DescendantContextProviderProps<TState>) => {
  const registerDescendant = useCallback(
    (newDescendant: Descendant) => {
      setDescendants((prevDescendants) => {
        const existingIndex = prevDescendants.findIndex(
          (descendant) => descendant.id === newDescendant.id,
        );

        if (existingIndex !== -1) {
          return prevDescendants.toSpliced(existingIndex, 1, newDescendant);
        }

        const index = findDOMIndex(prevDescendants, newDescendant.elementRef);

        return prevDescendants.toSpliced(index, 0, newDescendant);
      });
    },
    [setDescendants],
  );

  const unregisterDescendant = useCallback(
    (id: Descendant['id']) => {
      setDescendants((prevDescendants) =>
        prevDescendants.filter(({ id: itemId }) => itemId !== id),
      );
    },
    [setDescendants],
  );

  const contextState = useMemo<DescendantContextState<TState>>(
    () => ({
      descendants,
      registerDescendant,
      unregisterDescendant,
      state,
    }),
    [descendants, registerDescendant, unregisterDescendant, state],
  );

  return <DescendantContext.Provider value={contextState}>{children}</DescendantContext.Provider>;
};

export const useDescendantContextInit = <TDescendant extends Descendant = Descendant>() => {
  const [descendants, setDescendants] = useState<ReadonlyArray<TDescendant>>([]);

  return {
    descendants,
    setDescendants,
  };
};

type DescendantProps = Omit<Descendant, 'elementRef'>;
export const useDescendant = <TElement extends HTMLElement = HTMLElement>(
  descendantProps: DescendantProps,
  ref: Ref<TElement>,
) => {
  const { registerDescendant, unregisterDescendant } = useEnsuredContext(DescendantContext);

  const { href, id, label, onAction, target } = descendantProps;
  const { refObject, refToForward } = useAttachRef(ref);

  const initializedRef: RefCallback<TElement> = useCallback(
    (element) => {
      if (element) {
        registerDescendant({
          href,
          id,
          label,
          onAction,
          target,
          elementRef: refObject,
        });
      } else {
        unregisterDescendant(id);
      }
    },
    [href, id, label, onAction, refObject, registerDescendant, unregisterDescendant, target],
  );

  const uninitializedRef: RefCallback<TElement> = useCallback(
    (element) => {
      if (element) {
        registerDescendant({
          href,
          id,
          label,
          onAction,
          target,
          elementRef: refObject,
        });
      }
    },
    [href, id, label, onAction, refObject, registerDescendant, target],
  );

  const mergedInitializedRef = useMemo(
    () => mergeRefs<TElement>(refToForward, initializedRef),
    [refToForward, initializedRef],
  );
  const mergedUninitializedRef = useMemo(
    () => mergeRefs<TElement>(refToForward, uninitializedRef),
    [refToForward, uninitializedRef],
  );

  return {
    uninitializedRef: mergedUninitializedRef,
    initializedRef: mergedInitializedRef,
  };
};

export const useDescendantContext = <
  TState extends VerticalMenuState<TDescendant> | ListState<TDescendant>,
  TDescendant extends Descendant = Descendant,
>() => useEnsuredContext(DescendantContext) as DescendantContextState<TState>;
