import { Direction } from '@kontent-ai/types';
import { EditorProps, EditorState, SelectionState } from 'draft-js';
import { useCallback } from 'react';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None, WithoutProps } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import { Apply, PluginState, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { DecorableFunction, Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import { isAtEntityEdge } from '../../utils/blocks/editorBlockUtils.ts';
import { OnChangePlugin } from '../behavior/OnChangePlugin.tsx';
import { isAtCommentEdge } from '../comments/api/editorCommentUtils.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand, TextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import { EditorTextApi } from '../textApi/api/EditorTextApi.type.ts';
import { editorTextApi } from '../textApi/api/editorTextApi.ts';
import { ConversionResult, evaluateDashConversion } from './api/dashUtils.ts';

export type CanHandleNewCharsNatively = (params: {
  readonly chars: string;
  readonly editorState: EditorState;
}) => boolean;

export type PostProcessInsertedChars = (params: {
  readonly chars: string;
  readonly editorState: EditorState;
}) => EditorState;

export type PostProcessAfterReturn = (params: {
  readonly editorState: EditorState;
  readonly originalSelection: SelectionState;
}) => EditorState;

type CustomInputHandlingPluginState = {
  readonly canHandleNewCharsNatively: DecorableFunction<CanHandleNewCharsNatively>;
  readonly postProcessInsertedChars: DecorableFunction<PostProcessInsertedChars>;
  readonly postProcessAfterReturn: DecorableFunction<PostProcessAfterReturn>;
};

type CustomInputHandlingPluginInternalProps = { readonly enterKeyBehavior: EnterKeyBehavior };

export type CustomInputHandlingPlugin = DraftJsEditorPlugin<
  CustomInputHandlingPluginState,
  None,
  EditorTextApi,
  CustomInputHandlingPluginInternalProps,
  [OnChangePlugin, KeyboardShortcutsPlugin<TextInputCommand>]
>;

export enum EnterKeyBehavior {
  // Always soft new line, use in editors with single block
  AlwaysSoftNewLine = 'AlwaysSoftNewLine',
  // Only with shift, otherwise enter splits blocks / creates new block
  SoftNewLineWithShift = 'SoftNewLineWithShift',
}

type EditorWithCustomInputHandlingProps = {
  readonly enterKeyBehavior: EnterKeyBehavior;
};

const EditorWithCustomInputHandling: DecoratedEditor<
  WithoutProps<CustomInputHandlingPlugin>,
  EditorWithCustomInputHandlingProps
> = ({ baseRender, state, enterKeyBehavior }) => {
  const {
    areContentChangesPending,
    canHandleNewCharsNatively,
    canUpdateContent,
    editorProps: { handleBeforeInput: parentHandleBeforeInput, handleReturn: parentHandleReturn },
    executeChange,
    getApi,
    postProcessAfterReturn,
    postProcessInsertedChars,
  } = state;

  const handleBeforeInput = useCallback<Required<EditorProps>['handleBeforeInput']>(
    (chars, editorState, eventTimeStamp) => {
      if (
        !canHandleNewCharsNatively({
          chars,
          editorState,
        })
      ) {
        executeChange((currentEditorState) => {
          const selection = currentEditorState.getSelection();
          if (selection.getHasFocus() && canUpdateContent()) {
            const newEditorState = getApi().insertNewChars(
              currentEditorState,
              chars,
              !areContentChangesPending(),
            );
            if (newEditorState === currentEditorState) {
              return currentEditorState;
            }

            return postProcessInsertedChars({
              chars,
              editorState: newEditorState,
            });
          }
          return currentEditorState;
        });
        return 'handled';
      }

      return parentHandleBeforeInput?.(chars, editorState, eventTimeStamp) ?? 'not-handled';
    },
    [
      areContentChangesPending,
      canHandleNewCharsNatively,
      canUpdateContent,
      executeChange,
      getApi,
      parentHandleBeforeInput,
      postProcessInsertedChars,
    ],
  );

  const handleReturn: Required<EditorProps>['handleReturn'] = useCallback(
    (event, editorState) => {
      if (parentHandleReturn && parentHandleReturn(event, editorState) === 'handled') {
        return 'handled';
      }

      event.preventDefault();

      executeChange((currentEditorState) => {
        const selection = currentEditorState.getSelection();
        if (selection.getHasFocus() && canUpdateContent()) {
          const newEditorState =
            enterKeyBehavior === EnterKeyBehavior.AlwaysSoftNewLine || event.shiftKey
              ? getApi().insertSoftNewline(currentEditorState)
              : getApi().splitBlock(currentEditorState);
          if (newEditorState === currentEditorState) {
            return currentEditorState;
          }

          return postProcessAfterReturn({
            editorState: newEditorState,
            originalSelection: currentEditorState.getSelection(),
          });
        }
        return currentEditorState;
      });

      return 'handled';
    },
    [
      canUpdateContent,
      enterKeyBehavior,
      executeChange,
      getApi,
      parentHandleReturn,
      postProcessAfterReturn,
    ],
  );

  const stateWithCustomInputHandling: PluginState<CustomInputHandlingPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      handleBeforeInput,
      handleReturn,
    },
  };

  return baseRender(stateWithCustomInputHandling);
};

EditorWithCustomInputHandling.displayName = 'EditorWithCustomInputHandling';

const canHandleNewCharsNatively: CanHandleNewCharsNatively = ({ chars, editorState }) => {
  const selection = editorState.getSelection();

  // Non-collapsed selection and cursor at the block start may need special customization
  // Full set of cases is not mapped, but some known could be
  // 1) typing to the custom block sleeve
  // 2) rewriting some more complex selection (over more blocks or entities) with new chars
  if (!selection.isCollapsed() || selection.getStartOffset() === 0) {
    return false;
  }

  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(selection.getStartKey());
  if (block) {
    const offset = selection.getStartOffset();

    // Comment and entity edge behavior is customized, we need the customization
    if (isAtCommentEdge(block, offset) || isAtEntityEdge(block, offset)) {
      return false;
    }

    const text = block.getText();
    const existingText = text.substring(0, offset) + chars;

    // Dash conversion is handled in base API.insertNewChars
    if (evaluateDashConversion(existingText).result !== ConversionResult.NoConversion) {
      return false;
    }
  }

  return true;
};

const postProcessInsertedChars: PostProcessInsertedChars = ({ editorState }) => editorState;
const postProcessAfterReturn: PostProcessAfterReturn = ({ editorState }) => editorState;

export const CustomInputHandlingPlugin: PluginComponent<CustomInputHandlingPlugin> = (props) => {
  const { enterKeyBehavior } = props;
  const render: Decorator<Render<CustomInputHandlingPlugin>> = useCallback(
    (baseRender) => (state) => (
      <EditorWithCustomInputHandling
        baseRender={baseRender}
        enterKeyBehavior={enterKeyBehavior}
        state={state}
      />
    ),
    [enterKeyBehavior],
  );

  const apply: Apply<CustomInputHandlingPlugin> = useCallback(
    (state) => {
      state.render.decorate(render);

      const executeCommand: Decorator<ExecuteCommand<TextInputCommand>> =
        (baseExecuteCommand) => (command, isShiftPressed) => {
          switch (command) {
            case RichTextInputCommand.InsertNonBreakingSpace: {
              state.executeChange((editorState) =>
                state.getApi().insertNonBreakingSpace(editorState),
              );
              return true;
            }

            case RichTextInputCommand.Delete:
            case RichTextInputCommand.Backspace: {
              const direction =
                command === RichTextInputCommand.Backspace ? Direction.Backward : Direction.Forward;
              // We can't use executeChange the standard way here because it is asynchronous, and we need to yield synchronous result for
              // DraftJS Editor.handleKeyCommand
              const editorState = state.getEditorState();
              const selection = editorState.getSelection();
              const result = state
                .getApi()
                .handleDeleteAtSelection(editorState, selection, direction);
              state.executeChange(() => result.editorState);
              return !result.isUnhandled;
            }

            default:
              break;
          }
          return baseExecuteCommand(command, isShiftPressed);
        };

      state.executeCommand.decorate(executeCommand);

      return {
        canHandleNewCharsNatively: decorable(canHandleNewCharsNatively),
        postProcessInsertedChars: decorable(postProcessInsertedChars),
        postProcessAfterReturn: decorable(postProcessAfterReturn),
      };
    },
    [render],
  );

  const { getApiMethods } = useEditorApi<CustomInputHandlingPlugin>(editorTextApi);

  return useEditorWithPlugin(props, { apply, getApiMethods });
};
