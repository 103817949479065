import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import { colorAlertBackgroundInverse } from '@kontent-ai/component-library/tokens';
import React, { ComponentProps } from 'react';
import { Controller } from 'react-hook-form';
import { FormGroup } from '../../../../../_shared/components/input/FormGroup.tsx';
import { ValidatedInput } from '../../../../../_shared/components/input/ValidatedInput.tsx';
import { ValidatedMultipleOptionSelect } from '../../../../../_shared/components/input/ValidatedMultipleOptionSelect.tsx';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiCollection,
  DataUiInput,
  getDataUiCollectionAttribute,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { capitalizeFirstLetter } from '../../../../../_shared/utils/stringUtils.ts';
import { RoleOption } from '../../../../../data/models/roles/RoleOption.ts';
import { WorkflowStepOption } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { renderWorkflowStepColor } from '../../../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import { deletedRoleIdBase } from '../../../constants/getDeletedRole.ts';
import { getSortedRoleOptionsListWithAllRolesFirst } from '../../../selectors/getSortedRoleOptionsListWithAllRolesFirst.ts';
import { handleAllRolesRoleOnRolesChangedInFormInput } from '../../../utils/allRolesRoleBehaviorUtils.ts';
import { IWorkflowStepFormShape } from '../../model/IWorkflowStepFormShape.type.ts';
import { StepColorPickerList } from '../stepBarItems/StepColor/StepColorPickerList.tsx';

const thisStepName = 'this step';

type Props = {
  readonly formProps: HookFormProps<IWorkflowStepFormShape>;
  readonly transitionFromOptions: ReadonlyArray<WorkflowStepOption>;
  readonly transitionToOptions: ReadonlyArray<WorkflowStepOption>;
  readonly workflowStepName: string;
};

const renderWorkflowStepSelectedOption = (
  _id: string,
  workflowStep: WorkflowStepOption,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) => {
  return <Tag {...defaultTagProps} background={renderWorkflowStepColor(workflowStep.color)} />;
};

const renderRoleOption = (
  id: string,
  _role: RoleOption,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) => {
  if (id.startsWith(deletedRoleIdBase)) {
    return <Tag {...defaultTagProps} background={colorAlertBackgroundInverse} />;
  }

  return <DefaultTag {...defaultTagProps} />;
};

export const WorkflowStepFormFields: React.FC<Props> = (props) => {
  const roleOptions = useSelector((state) => {
    const { rolesById } = state.data.roles;
    return getSortedRoleOptionsListWithAllRolesFirst(rolesById);
  });

  return (
    <div className="bar-item__expansion">
      <ValidatedInput<IWorkflowStepFormShape>
        autoFocus
        dataUiInputName={DataUiInput.WorkflowStepName}
        label="Name"
        maxLength={ValidationConstants.WorkflowStatusNameMaxLength}
        name="name"
        formProps={props.formProps}
      />

      <div className="workflow-step__color-picker ">
        <div className="workflow-step__color-picker-label">
          <div className="form__label form__label--xs">Label color</div>
          <Controller
            control={props.formProps.control}
            name="color"
            render={({ field }) => (
              <StepColorPickerList
                ref={field.ref}
                onChange={field.onChange}
                selectedColor={field.value}
              />
            )}
          />
        </div>
      </div>
      <FormGroup>
        <ValidatedMultipleOptionSelect<IWorkflowStepFormShape, WorkflowStepOption>
          formProps={props.formProps}
          items={props.transitionFromOptions}
          label={`Steps that transition to ${props.workflowStepName || thisStepName}`}
          name="transitionFromStepIds"
          placeholder="No transitions"
          renderSelectedOption={renderWorkflowStepSelectedOption}
          verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.WorkflowSteps)}
          {...getDataUiInputAttribute(DataUiInput.WorkflowStepTransitionsFrom)}
        />
      </FormGroup>

      <FormGroup>
        <ValidatedMultipleOptionSelect<IWorkflowStepFormShape, WorkflowStepOption>
          formProps={props.formProps}
          items={props.transitionToOptions}
          label={`${props.workflowStepName || capitalizeFirstLetter(thisStepName)} transitions to`}
          name="transitionToStepIds"
          placeholder="No transitions"
          renderSelectedOption={renderWorkflowStepSelectedOption}
          verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.WorkflowSteps)}
          {...getDataUiInputAttribute(DataUiInput.WorkflowStepTransitionsTo)}
        />
      </FormGroup>

      <FormGroup>
        <ValidatedMultipleOptionSelect<IWorkflowStepFormShape, RoleOption>
          formProps={props.formProps}
          items={roleOptions}
          label="Roles that can work with this step"
          name="roleIds"
          normalize={handleAllRolesRoleOnRolesChangedInFormInput}
          placeholder="Select roles that can work with this workflow step"
          renderSelectedOption={renderRoleOption}
          verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.RoleList)}
          {...getDataUiInputAttribute(DataUiInput.WorkflowStepRoles)}
        />
      </FormGroup>
    </div>
  );
};

WorkflowStepFormFields.displayName = 'WorkflowStepFormFields';
