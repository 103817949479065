import { Direction } from '@kontent-ai/types';
import { createGuid } from '@kontent-ai/utils';
import { useCallback } from 'react';
import { useEventListener } from '../../../../_shared/hooks/useEventListener.ts';
import { AssetUploadFinishedEventForLocalState } from '../../../../_shared/utils/assets/AssetUploadFinishedEvent.ts';
import { triggerAssetUploadFinishedEvents } from '../../../../_shared/utils/assets/assetUtils.ts';
import { CustomEventName } from '../../../../_shared/utils/events/KontentEventMap.ts';
import { RequiredAssetCreationMetadata } from '../../../../repositories/serverModels/AssetServerModels.type.ts';
import {
  IAssetUploadResult,
  IOnAssetFinished,
} from '../../../contentInventory/assets/actions/thunks/createAssets.ts';
import { FileWithThumbnail } from '../../../contentInventory/assets/models/FileWithThumbnail.type.ts';
import {
  ElementReference,
  areReferencesPointingToSameElement,
} from '../../../itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import { Apply, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { getValidSelection } from '../../utils/consistency/editorConsistencyUtils.ts';
import { createSelection } from '../../utils/editorSelectionUtils.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { ImagesPlugin } from '../images/ImagesPlugin.tsx';
import { AssetLinksPlugin } from '../links/asset/AssetLinksPlugin.tsx';
import { StylesPlugin } from '../visuals/StylesPlugin.tsx';
import { EditorUploadFilesApi, UploadedAsset } from './api/EditorUploadFilesApi.type.ts';
import { editorUploadFilesApi } from './api/editorUploadFilesApi.ts';

type UploadFilesPluginProps = {
  readonly element: ElementReference;
  readonly onFilesUpload: (
    files: Map<Uuid, FileWithThumbnail>,
    metadata: RequiredAssetCreationMetadata,
    onAssetFinished: IOnAssetFinished,
  ) => Promise<ReadonlyArray<IAssetUploadResult>>;
  readonly onPostprocessingAssetsFinished: (oldAssetIds: UuidArray) => void;
};

export type UploadFiles = (
  files: ReadonlyArray<FileWithThumbnail>,
  metadata: RequiredAssetCreationMetadata,
  targetBlockId: string,
  direction: Direction,
) => Promise<void>;

type UploadFilesPluginState = {
  readonly uploadFiles: UploadFiles;
};

export type UploadFilesPlugin = DraftJsEditorPlugin<
  UploadFilesPluginState,
  UploadFilesPluginProps,
  EditorUploadFilesApi,
  None,
  [ImagesPlugin, AssetLinksPlugin, StylesPlugin]
>;

const EditorWithDropFiles: DecoratedEditor<UploadFilesPlugin> = ({
  baseRender,
  element,
  onPostprocessingAssetsFinished,
  state,
}) => {
  const { executeChange, getApi } = state;

  const handleAssetUploadFinishedEvent = useCallback(
    async (event: AssetUploadFinishedEventForLocalState): Promise<void> => {
      const { oldAssetId, newAssetId, element: eventElement } = event.detail;

      if (element && eventElement && areReferencesPointingToSameElement(element, eventElement)) {
        event.stopPropagation();

        if (newAssetId) {
          await executeChange((editorState) => {
            const editorStateWithUploadedFiles = getApi().replaceAsset(
              editorState,
              oldAssetId,
              newAssetId,
            );
            return editorStateWithUploadedFiles;
          });
        }

        onPostprocessingAssetsFinished?.([oldAssetId]);
      }
    },
    [onPostprocessingAssetsFinished, executeChange, getApi, element],
  );

  useEventListener(
    CustomEventName.assetUploadFinishedForLocalState,
    handleAssetUploadFinishedEvent,
    self,
  );

  return baseRender(state);
};

EditorWithDropFiles.displayName = 'EditorWithDropFiles';

export const UploadFilesPlugin: PluginComponent<UploadFilesPlugin> = (props) => {
  const { element, onFilesUpload, onPostprocessingAssetsFinished } = props;

  const render: Decorator<Render<UploadFilesPlugin>> = useCallback(
    (baseRender) => (state) => (
      <EditorWithDropFiles
        baseRender={baseRender}
        element={element}
        onFilesUpload={onFilesUpload}
        onPostprocessingAssetsFinished={onPostprocessingAssetsFinished}
        state={state}
      />
    ),
    [element, onFilesUpload, onPostprocessingAssetsFinished],
  );

  const onAssetUploadFinished: IOnAssetFinished = useCallback(
    (oldAssetId, newAsset) =>
      triggerAssetUploadFinishedEvents(oldAssetId, newAsset?.id ?? null, element),
    [element],
  );

  const apply: Apply<UploadFilesPlugin> = useCallback(
    (state) => {
      state.render.decorate(render);

      const uploadFiles: UploadFiles = async (files, collectionId, targetBlockId, direction) => {
        const { executeChange, getApi } = state;

        const filesArray: ReadonlyArray<UploadedAsset> = files.map((file) => ({
          assetId: createGuid(),
          file,
        }));

        await executeChange((editorState) => {
          const targetBlock = editorState.getCurrentContent().getBlockForKey(targetBlockId);
          if (targetBlock) {
            // Insert files to editor so the progress can be displayed
            const before = direction === Direction.Backward;
            const targetSelection = createSelection(
              targetBlockId,
              before ? 0 : targetBlock.getLength(),
            );

            // The selection may point to a target that can't have selection (e.g. custom block)
            // we need to make it valid so the insertion happens at the right place
            const validSelection = getValidSelection(
              editorState.getCurrentContent(),
              targetSelection,
              direction,
            );

            return getApi().insertFiles(editorState, validSelection, filesArray);
          }
          return editorState;
        });

        const fileMap = new Map(filesArray.map((item) => [item.assetId, item.file]));
        await onFilesUpload(fileMap, collectionId, onAssetUploadFinished);
      };

      return { uploadFiles };
    },
    [onAssetUploadFinished, onFilesUpload, render],
  );

  const { getApiMethods } = useEditorApi<UploadFilesPlugin>(editorUploadFilesApi);

  return useEditorWithPlugin(props, { apply, getApiMethods });
};
