import { useLayoutEffect } from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { isAiSearchEnabled } from '../../../_shared/selectors/aiSelectors.ts';
import {
  SearchScope,
  emptyListingFilter,
} from '../../../applications/contentInventory/content/models/filter/IListingFilter.ts';
import { getIsAiSearchScope } from './getIsAiSearchScope.ts';

export const useAiSearchScope = (
  scope: ReadonlySet<SearchScope>,
  changeScope: (newScope: ReadonlySet<SearchScope>) => void,
): {
  readonly allowAiSearch: boolean;
  readonly displayScope: ReadonlySet<SearchScope>;
} => {
  const allowAiSearch = useSelector(isAiSearchEnabled);
  const isAiSearch = useSelector(getIsAiSearchScope);

  // The selected scope can be AI search if it was used previously and the feature was disabled later
  // We ensure automatic switch to a standard one when that happens to keep the app in a consistent state
  useLayoutEffect(() => {
    if (!allowAiSearch && isAiSearch) {
      changeScope(emptyListingFilter.searchScope);
    }
  }, [allowAiSearch, isAiSearch, changeScope]);

  const displayScope = !allowAiSearch && isAiSearch ? emptyListingFilter.searchScope : scope;

  return {
    allowAiSearch,
    displayScope,
  };
};
