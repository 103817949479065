import React from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getIsAiSearchScope } from '../../../../paperModels/aiSearch/selectors/getIsAiSearchScope.ts';
import { FullTextSearchStatus } from '../../shared/reducers/IContentInventoryStoreState.type.ts';
import { ContentItemsTextFilter } from '../components/ContentItemsTextFilter.tsx';
import {
  resetFulltextSorting,
  updateFilterSearchPhrase,
} from '../features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';
import { notSpecifiedContentListingOrderBy } from '../reducers/listingUi/reducers/orderBy.ts';

interface IContainerProps {
  readonly allowAiSearch: boolean;
  readonly fullTextSearchStatus: FullTextSearchStatus;
  readonly isInDialog: boolean;
}

const ContentItemsTextFilterContainer: React.FC<IContainerProps> = ({
  allowAiSearch,
  fullTextSearchStatus,
  isInDialog,
}) => {
  const isAiSearchScope = useSelector(getIsAiSearchScope);
  const searchPhrase = useSelector((state) => state.contentApp.listingUi.filter.searchPhrase);
  const orderByColumnIsCustom = useSelector(
    (s) =>
      s.contentApp.listingUi.orderBy.columnCode !== notSpecifiedContentListingOrderBy.columnCode,
  );

  const dispatch = useDispatch();
  const onUpdateSearchPhrase = (newSearchPhrase: string) =>
    dispatch(updateFilterSearchPhrase(newSearchPhrase, isInDialog));

  return (
    <ContentItemsTextFilter
      autofocus
      fullTextSearchStatus={fullTextSearchStatus}
      isAiSearch={allowAiSearch && isAiSearchScope}
      onSortReset={() => dispatch(resetFulltextSorting(!isInDialog))}
      onUpdateSearchPhrase={onUpdateSearchPhrase}
      orderByColumnIsCustom={orderByColumnIsCustom}
      searchPhrase={searchPhrase}
    />
  );
};

ContentItemsTextFilterContainer.displayName = 'ContentItemsTextFilterContainer';

export { ContentItemsTextFilterContainer as ContentItemsTextFilter };
