import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import {
  ContentAppEntryRoute,
  EnvironmentRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';

interface ICopyingDeliverDataActionButtonProps {
  readonly projectId: string;
}

export const CopyingDeliverDataActionButton: FC<ICopyingDeliverDataActionButtonProps> = ({
  projectId,
}) => (
  <div className="my-project__button-wrapper">
    <div className="my-project__copying-status">
      <div className="my-project__loading-wrapper">
        <Icon iconName={IconName.Spinner} className="spin-500" />
      </div>
      <span
        className="my-project__loading-status"
        {...getDataUiElementAttribute(DataUiElement.ProjectCopyStatus)}
      >
        The Delivery API isn’t ready. Using the API now will get incomplete data.
      </span>
    </div>
    <RouterLinkButton
      to={buildPath<EnvironmentRouteParams>(ContentAppEntryRoute, { projectId })}
      buttonStyle="primary"
      size="medium"
      {...getDataUiActionAttribute(DataUiAction.OpenProject)}
    >
      Open project
    </RouterLinkButton>
  </div>
);

CopyingDeliverDataActionButton.displayName = 'CopyingDeliverDataActionButton';
