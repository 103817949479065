import { Column, Row } from '@kontent-ai/component-library/Row';
import { IWorkflowStepConfig, WorkflowStatus } from '@kontent-ai/component-library/WorkflowStatus';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { publishedStepConstantValues } from '../../../applications/environmentSettings/workflow/constants/publishedStepValues.ts';
import { PublishingState } from '../../../data/constants/PublishingState.ts';
import { IWorkflowStep } from '../../../data/models/workflow/WorkflowStep.ts';
import { renderWorkflowStepColor } from '../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import { isNewVersion } from '../../utils/contentItemVariantUtils.ts';
import { ScheduledUnpublishIcon } from './ScheduledUnpublishIcon.tsx';

interface IWorkflowStatusTagForVariantProps {
  readonly primaryTooltipText: string;
  readonly publishingState: PublishingState;
  readonly scheduledToUnpublishAt: DateTimeStamp | null;
  readonly scheduledToUnpublishAtTooltipText: string;
  readonly secondaryTooltipText: string;
  readonly workflowStatus: IWorkflowStep;
}

export const WorkflowStatusTagForVariant: React.FC<IWorkflowStatusTagForVariantProps> = ({
  primaryTooltipText,
  publishingState,
  scheduledToUnpublishAt,
  scheduledToUnpublishAtTooltipText,
  secondaryTooltipText,
  workflowStatus,
}) => {
  const hasOlderPublishedVersion = isNewVersion({
    publishingState,
    workflowStatus,
  });

  return (
    <div css="min-width: 0">
      <Row alignY="center" noWrap spacing={Spacing.XS}>
        <Column width="fit-content">
          <WorkflowStatus
            primary={{
              background: renderWorkflowStepColor(workflowStatus.color),
              name: workflowStatus.name,
              tooltipText: primaryTooltipText,
            }}
            secondary={
              hasOlderPublishedVersion
                ? ({
                    background: renderWorkflowStepColor(publishedStepConstantValues.color),
                    name: publishedStepConstantValues.name,
                    tooltipText: secondaryTooltipText,
                  } satisfies IWorkflowStepConfig)
                : undefined
            }
          />
        </Column>

        {scheduledToUnpublishAt && (
          <Column>
            <ScheduledUnpublishIcon tooltipText={scheduledToUnpublishAtTooltipText} />
          </Column>
        )}
      </Row>
    </div>
  );
};

WorkflowStatusTagForVariant.displayName = 'WorkflowStatusTagForVariant';
