import { InvariantException } from '@kontent-ai/errors';
import {
  ConditionAction,
  MultipleChoiceConditionOperator,
  TypeElementCondition,
} from '../../../_shared/models/utils/TypeElementCondition.ts';
import { TypeElement } from '../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { ICompiledContentItemElementData } from '../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { IMultipleChoiceItemElement } from '../models/contentItemElements/MultipleChoiceItemElement.ts';
import { isMultipleChoiceElement } from '../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../stores/utils/contentItemElementsUtils.ts';

export const isElementVisible = (
  typeElement: TypeElement,
  itemElements: ReadonlyArray<ICompiledContentItemElementData>,
): boolean => {
  const condition = typeElement.condition;

  if (!condition || !condition.isActive) {
    return true;
  }

  const triggerId = condition.trigger.elementId;
  const triggerElement = triggerId && getElementById(triggerId, itemElements);
  if (!triggerElement) {
    throw InvariantException(`Cannot find a trigger element with id '${triggerId}'.`);
  }

  if (!isMultipleChoiceElement(triggerElement)) {
    throw InvariantException(`Trigger element of type ${triggerElement.type} is not supported.`);
  }

  const isConditionSatisfied = isMultipleChoiceConditionSatisfied(condition, triggerElement);

  switch (condition.action) {
    case ConditionAction.Show:
      return isConditionSatisfied;
    case ConditionAction.Hide:
      return !isConditionSatisfied;
  }
};

const isMultipleChoiceConditionSatisfied = (
  condition: TypeElementCondition,
  triggerElement: IMultipleChoiceItemElement,
): boolean => {
  const { trigger } = condition;

  switch (trigger.operator) {
    case MultipleChoiceConditionOperator.All:
      return trigger.optionIds.every((optionId) => triggerElement.options.includes(optionId));
    case MultipleChoiceConditionOperator.Any:
      return trigger.optionIds.some((optionId) => triggerElement.options.includes(optionId));
  }
};

export const getTargetElementsAffectedByTriggerChange = (
  triggerElementPreviousValue: ICompiledContentItemElementData,
  triggerElementNewValue: ICompiledContentItemElementData,
  editedContentItemElements: ReadonlyArray<ICompiledContentItemElementData>,
  typeElements: ReadonlyArray<TypeElement>,
  conditionEvaluator: (
    targetTypeElement: TypeElement,
    triggerItemElement: ICompiledContentItemElementData,
  ) => boolean,
): ReadonlyArray<ICompiledContentItemElementData> => {
  const targetElements = typeElements.filter(
    (typeElement) =>
      typeElement.condition?.trigger?.elementId === triggerElementNewValue.elementId &&
      typeElement.condition?.isActive,
  );

  const previouslyAffectedTargetElements = targetElements.filter((targetElement) =>
    conditionEvaluator(targetElement, triggerElementPreviousValue),
  );
  const newlyAffectedTargetElements = targetElements.filter((targetElement) =>
    conditionEvaluator(targetElement, triggerElementNewValue),
  );

  const targetElementsThatChanged = newlyAffectedTargetElements.filter(
    (current) =>
      !previouslyAffectedTargetElements.some(
        (previous) => previous.elementId === current.elementId,
      ),
  );

  return editedContentItemElements.filter((itemElement) =>
    targetElementsThatChanged.find(
      (typeElement) => typeElement.elementId === itemElement.elementId,
    ),
  );
};

export const isElementShownByTrigger = (
  targetTypeElement: TypeElement,
  triggerItemElement: ICompiledContentItemElementData,
) => isElementVisible(targetTypeElement, [triggerItemElement]);

export const isElementHiddenByTrigger = (
  targetTypeElement: TypeElement,
  triggerItemElement: ICompiledContentItemElementData,
) => !isElementVisible(targetTypeElement, [triggerItemElement]);
