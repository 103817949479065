import { AnchorButton, Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';

type OpenInNewTabButtonProps = Readonly<{
  previewUrl: string;
  onClick: () => void;
}>;

export const OpenInNewTabButton: React.FC<OpenInNewTabButtonProps> = ({ previewUrl, onClick }) => (
  <AnchorButton href={previewUrl} onPress={onClick} target="_blank" buttonStyle="secondary">
    <Button.Icon icon={Icons.ArrowRightTopSquare} />
    <Button.Label>Open in new tab</Button.Label>
  </AnchorButton>
);
