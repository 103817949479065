import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import classNames from 'classnames';
import { ContentState, DraftStyleMap, EditorProps, EditorState } from 'draft-js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { logError } from '../../../../_shared/utils/logError.ts';
import { diffWordsWithSpaceParallel } from '../../../itemEditor/features/Revisions/utils/diffWordsWithSpaceParallel.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import { Apply, PluginState, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { ReadonlyPlugin } from '../behavior/ReadonlyPlugin.tsx';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { DiffStyles, createDiffFromContentState } from './api/editorDiffUtils.ts';
import { getDiffBlockClassNames } from './utils/diffRenderingUtils.ts';

type DiffPluginProps = {
  readonly originalContent?: ContentState;
};

export type DiffPlugin = DraftJsEditorPlugin<None, DiffPluginProps, None, None, [ReadonlyPlugin]>;

const EditorWithDiffStyles: DecoratedEditor<DiffPlugin> = ({ baseRender, state }) => {
  const {
    editorProps: { customStyleMap: parentCustomStyleMap, blockStyleFn: parentBlockStyleFn },
  } = state;

  const styleMapWithDiffStyles = useMemo(
    () =>
      ({
        ...parentCustomStyleMap,
        ...DiffStyles,
      }) as DraftStyleMap,
    [parentCustomStyleMap],
  );

  const blockStyleFn = useCallback<Required<EditorProps>['blockStyleFn']>(
    (block) => classNames(parentBlockStyleFn?.(block), getDiffBlockClassNames(block)),
    [parentBlockStyleFn],
  );

  const stateWithDiffStyles: PluginState<DiffPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      blockStyleFn,
      customStyleMap: styleMapWithDiffStyles,
    },
  };

  return baseRender(stateWithDiffStyles);
};

EditorWithDiffStyles.displayName = 'EditorWithDiffStyles';

const useDiffedEditorState = (
  oldContent: ContentState | undefined,
  newContent: ContentState,
): EditorState | null => {
  const [diffedEditorState, setDiffedEditorState] = useState<EditorState | null>(null);

  useEffect(() => {
    const { cancel } = makeCancellablePromise(async () => {
      if (!oldContent) {
        return null;
      }

      const diffContentState = await createDiffFromContentState(oldContent, newContent, {
        calculateDiff: diffWordsWithSpaceParallel,
      });
      return EditorState.createWithContent(diffContentState);
    })
      .then(setDiffedEditorState)
      .catch(swallowCancelledPromiseError)
      .catch((error) => logError('DiffPlugin: error while calculating diff.', error));

    return cancel;
  }, [oldContent, newContent]);

  return diffedEditorState;
};

export const DiffPlugin: PluginComponent<DiffPlugin> = (props) => {
  const { editorState, originalContent } = props;

  const render: Decorator<Render<DiffPlugin>> = useCallback(
    // eslint-disable-next-line react/no-unstable-nested-components
    (baseRender) => (state) => <EditorWithDiffStyles baseRender={baseRender} state={state} />,
    [],
  );

  const apply: Apply<DiffPlugin> = useCallback(
    (state) => {
      state.render.decorate(render);
      return {};
    },
    [render],
  );

  const diffedEditorState = useDiffedEditorState(originalContent, editorState.getCurrentContent());

  // The diff component can be used both as a viewer and as diff viewer, we display the diff only when original content is provided
  const showLoader = !!originalContent && !diffedEditorState;

  const editor = useEditorWithPlugin(
    { ...props, editorState: diffedEditorState ?? editorState },
    { apply },
  );
  return showLoader ? <Loader /> : editor;
};
