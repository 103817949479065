import { IconButton } from '@kontent-ai/component-library/Button';
import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { SrOnly } from '@kontent-ai/component-library/styles';
import { ICancellablePromise, delay, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useEffect, useRef } from 'react';
import { copyToClipboardDelay } from '../../constants/uiConstants.ts';
import {
  DataUiAction,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiInputAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly codename: string | null;
  readonly disabledEditingMessage?: string | null;
  readonly footnoteMessage?: string | null;
  readonly hideTitle?: boolean;
  readonly isEditable?: boolean;
  readonly onCopied?: () => void;
  readonly onCopyCompleted?: () => void;
  readonly onEdit: () => void;
};

export const ReadCodename = ({
  codename,
  disabledEditingMessage,
  footnoteMessage,
  hideTitle,
  isEditable,
  onCopied,
  onCopyCompleted,
  onEdit,
}: Props) => {
  const hiddenContainerRef = useRef<HTMLDivElement>(null);
  const delayedAction = useRef<ICancellablePromise | null>(null);

  const deferredOnCopyCompleted = (): void => {
    delayedAction.current = delay(copyToClipboardDelay)
      .then(() => {
        onCopyCompleted?.();
      })
      .catch(swallowCancelledPromiseError);
  };

  const onSuccess = (): void => {
    onCopied?.();
    deferredOnCopyCompleted();
  };

  useEffect(() => {
    return () => {
      delayedAction.current?.cancel();
    };
  }, []);

  return (
    <div>
      <SrOnly ref={hiddenContainerRef} />
      <Clipboard
        auxiliaryElement={
          isEditable && (
            <IconButton
              buttonState={disabledEditingMessage ? 'disabled' : 'default'}
              buttonStyle="secondary"
              iconName="Edit"
              onClick={onEdit}
              aria-label="Edit code name"
              size="medium"
              tooltipText={disabledEditingMessage ?? 'Edit'}
              tooltipPlacement="top-end"
              {...getDataUiActionAttribute(DataUiAction.Edit)}
            />
          )
        }
        caption={footnoteMessage ?? undefined}
        containerRef={hiddenContainerRef}
        label={hideTitle ? undefined : 'Codename'}
        onCopyCompleted={onCopyCompleted}
        onError={deferredOnCopyCompleted}
        onSuccess={onSuccess}
        value={codename || ''}
        {...getDataUiInputAttribute(DataUiInput.Codename)}
      />
    </div>
  );
};
