import { TabItem } from '@kontent-ai/component-library/TabView';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ApiKeyTab, getApiKeyTabLabel, getLastRouteSegmentForApiKeyTab } from './ApiKeyTab.ts';

export type TabItemWithLink = TabItem & {
  readonly to: string;
};

const getApiKeyTabWithLink = (apiKeyTab: ApiKeyTab): TabItemWithLink => ({
  id: apiKeyTab,
  to: getLastRouteSegmentForApiKeyTab(apiKeyTab),
  label: getApiKeyTabLabel(apiKeyTab),
  ...getDataUiElementAttribute(getApiKeyTabDataAttribute(apiKeyTab)),
});

export const getApiKeyTabsWithLink = (visibleTabs: ReadonlyArray<ApiKeyTab>) =>
  visibleTabs.map(getApiKeyTabWithLink);

export const getApiKeyTabDataAttribute = (apiKeyTab: ApiKeyTab): DataUiElement => {
  switch (apiKeyTab) {
    case ApiKeyTab.DapiKeys:
      return DataUiElement.DapiNavigationTab;
    case ApiKeyTab.MapiKeys:
      return DataUiElement.MapiNavigationTab;
  }
};
