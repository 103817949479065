import { Box } from '@kontent-ai/component-library/Box';
import { Input } from '@kontent-ai/component-library/Input';
import { useHotkeys } from '@kontent-ai/component-library/hooks';
import { gridUnit } from '@kontent-ai/component-library/tokens';
import React, { useState } from 'react';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { ShortcutSymbols } from '../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiAction,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import { editedAssetFolderCopy, newAssetFolderCopy } from '../../constants/assetFolderConstants.ts';

export enum AssetFolderState {
  New = 'New',
  Edited = 'Edited',
}

type Props = {
  readonly initialValue?: string;
  readonly onCancel: () => void;
  readonly onConfirm: (name: string) => void;
  readonly state: AssetFolderState;
};

const getUiConstants = (state: AssetFolderState) => {
  switch (state) {
    case AssetFolderState.New:
      return newAssetFolderCopy;

    case AssetFolderState.Edited:
      return editedAssetFolderCopy;
  }
};

export const AssetFolderNameDialog: React.FC<Props> = ({
  onCancel,
  onConfirm,
  state,
  initialValue,
}) => {
  const [newFolderName, setNewFolderName] = useState(initialValue || '');

  const confirmFolderName = () => {
    if (newFolderName && newFolderName !== initialValue) {
      onConfirm(newFolderName);
    }
  };

  const uiConstants = getUiConstants(state);
  const hasNameChanged = newFolderName !== initialValue;
  const isConfirmDisabled = isEmptyOrWhitespace(newFolderName) || !hasNameChanged;
  const disabledConfirmationTooltip =
    (!newFolderName && uiConstants.emptyNameConfirmTooltip) ||
    (!hasNameChanged && uiConstants.equalNamesConfirmTooltip) ||
    undefined;

  const hotkeyProps = useHotkeys({
    enter: (keyboardEvent) => {
      confirmFolderName();
      keyboardEvent.stopPropagation();
    },
  });

  return (
    <ModalDialog
      isOpen
      isDismissable
      onClose={onCancel}
      headline={uiConstants.header}
      primaryAction={{
        disabled: isConfirmDisabled,
        onClick: confirmFolderName,
        text: uiConstants.confirmText,
        tooltipText: isConfirmDisabled ? disabledConfirmationTooltip : uiConstants.confirmTooltip,
        tooltipShortcuts: isConfirmDisabled ? undefined : ShortcutSymbols.Enter,
        tooltipPlacement: 'bottom-start',
        ...getDataUiActionAttribute(DataUiAction.Confirm),
      }}
      cancelAction={{
        onClick: onCancel,
        tooltipText: uiConstants.cancelTooltip,
        tooltipShortcuts: ShortcutSymbols.Escape,
        tooltipPlacement: 'bottom-end',
      }}
      {...getDataUiElementAttribute(DataUiElement.Dialog)}
    >
      <Box maxWidth={38 * gridUnit} minWidth={38 * gridUnit} {...hotkeyProps}>
        <Input
          autoFocus
          placeholder={uiConstants.placeholder}
          value={newFolderName}
          maxLength={50}
          aria-label="folder name"
          onChange={(e) => setNewFolderName(e.currentTarget.value)}
          {...getDataUiInputAttribute(DataUiInput.Text)}
        />
      </Box>
    </ModalDialog>
  );
};

AssetFolderNameDialog.displayName = 'AssetFolderNameDialog';
