import React, { forwardRef } from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span``;

type Props = {
  readonly children?: React.ReactNode;
  readonly text?: string;
  readonly className?: string;
};

export const IgnoreByGrammarly = forwardRef<HTMLSpanElement, Props>(
  ({ children, className, text }, ref) =>
    children || text ? (
      <StyledSpan className={className} ref={ref} data-enable-grammarly="false">
        {children ?? text}
      </StyledSpan>
    ) : null,
);

IgnoreByGrammarly.displayName = 'IgnoreByGrammarly';
