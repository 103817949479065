import { Icons } from '@kontent-ai/component-library/Icons';
import { usePrevious } from '@kontent-ai/hooks';
import indefiniteArticles from 'articles';
import classNames from 'classnames';
import React, { forwardRef, memo, useContext, useEffect, useState, useId } from 'react';
import { CreateAutoScrollId } from '../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { AutoScrollLink } from '../../../../../_shared/components/AutoScroll/AutoScrollLink.tsx';
import { NonExistentBarItemActions } from '../../../../../_shared/components/BarItems/Actions/NonExistentBarItemActions.tsx';
import { BarItemActions } from '../../../../../_shared/components/BarItems/BarItemActions.tsx';
import { BarItemBar } from '../../../../../_shared/components/BarItems/BarItemBar.tsx';
import { LinkedItemOpenEditingAction } from '../../../../../_shared/components/linkedItems/LinkedItemOpenEditingAction.tsx';
import { EditorPaperContext } from '../../../../../_shared/contexts/EditorPaperContext.tsx';
import {
  ExpandedLinkedItemLayoutType,
  IContentEntryLinkedItemExpandedData,
} from '../../../../../_shared/models/TrackUserEventData.ts';
import {
  DataUiAction,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getReachedPaperThresholds } from '../../../../../_shared/utils/editorViewContext/editorPaperContext/utils/getReachedPaperThresholds.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { ContentNestingContext } from '../../ContentItemEditing/context/ContentNestingContext.tsx';
import { LinkedItemContent } from './LinkedItemContent.tsx';

type Props = {
  readonly canCreate: boolean;
  readonly cannotViewDisabledMessage: string | undefined;
  readonly className: string | undefined;
  readonly contentItem: IListingContentItem | undefined;
  readonly contentItemId: Uuid;
  readonly contentItemPath: string;
  readonly contentType: IContentType | undefined;
  readonly currentLanguageName: string;
  readonly disabled: boolean;
  readonly onExpandedChanged: (isExpanded: boolean) => void;
  readonly onTrackLinkedItemExpanded:
    | ((eventData: IContentEntryLinkedItemExpandedData) => void)
    | null;
  readonly renderExpanded: (contentItemId: string, expandedItemId: string) => React.ReactNode;
};

export const SimpleLinkedItem = memo(
  forwardRef<HTMLDivElement, Props>(
    (
      {
        canCreate,
        cannotViewDisabledMessage,
        className,
        contentItem,
        contentItemId,
        contentItemPath,
        contentType,
        currentLanguageName,
        disabled,
        onExpandedChanged,
        onTrackLinkedItemExpanded,
        renderExpanded,
      },
      ref,
    ) => {
      const isTranslated = !!contentItem?.variant && !contentItem.variant.isArchived;
      const isAvailable =
        !!contentItem?.item && !contentItem.item.archived && !cannotViewDisabledMessage;
      const editButtonTooltip = isTranslated
        ? 'Edit'
        : `Create ${indefiniteArticles.articlize(currentLanguageName)} variant`;

      const { isItemExpandable, isTopLevel, nestedLevel } = useContext(ContentNestingContext);

      const paperContext = useContext(EditorPaperContext);
      const reachedThresholds = getReachedPaperThresholds(paperContext.thresholds);

      const [isExpandedInternal, setIsExpandedInternal] = useState(false);
      const isExpanded = isExpandedInternal && !!renderExpanded;
      const previousIsExpanded = usePrevious(isExpanded);
      useEffect(() => {
        if (isExpanded !== previousIsExpanded) {
          onExpandedChanged?.(isExpanded);
        }
      }, [isExpanded, previousIsExpanded, onExpandedChanged]);
      const expandableItemId = useId();

      const toggleExpand = (): void => {
        if (!isExpanded && onTrackLinkedItemExpanded) {
          onTrackLinkedItemExpanded({
            linkedItemLayoutType: ExpandedLinkedItemLayoutType.Simple,
            nestedLevel,
          });
        }
        setIsExpandedInternal(!isExpanded);
      };

      const scrollId = CreateAutoScrollId.forContentItem(contentItemId);

      return (
        <div
          className="bar-item__node"
          ref={ref}
          {...getDataUiObjectNameAttribute(contentItem?.item?.name || contentItemId)}
        >
          <div className="bar-item__wrapper">
            <div
              className={classNames('bar-item__pane', 'linked-item', {
                'linked-item--is-disabled': disabled,
                'linked-item--is-top-level': isTopLevel,
                'linked-item--is-expanded': isExpanded,
              })}
            >
              <BarItemBar
                className={classNames(className, {
                  'bar-item__bar--is-disabled': isTranslated && !!cannotViewDisabledMessage,
                })}
                isCompact={reachedThresholds.sizeXS}
                isExpanded={isExpanded}
              >
                <LinkedItemContent
                  expandedItemId={expandableItemId}
                  cannotViewDisabledMessage={cannotViewDisabledMessage}
                  contentItem={contentItem}
                  contentType={contentType}
                  isExpanded={isExpanded}
                  reachedThresholds={reachedThresholds}
                  doNotRenderToggle={!isItemExpandable}
                  onToggleExpand={cannotViewDisabledMessage ? undefined : toggleExpand}
                />
                <BarItemActions isCompact={reachedThresholds.sizeXS}>
                  {!isAvailable && <NonExistentBarItemActions />}
                  <LinkedItemOpenEditingAction
                    canCreate={canCreate}
                    dataUiAction={DataUiAction.Edit}
                    isAvailable={isAvailable}
                    isTranslated={isTranslated}
                    icon={isTranslated ? Icons.Edit : Icons.DocPlus}
                    tooltipText={editButtonTooltip}
                    renderClickableWrapper={({ action }) => (
                      <AutoScrollLink to={contentItemPath} tabIndex={-1} scrollId={scrollId}>
                        {action}
                      </AutoScrollLink>
                    )}
                  />
                </BarItemActions>
              </BarItemBar>
              {isExpanded &&
                contentItem?.item &&
                renderExpanded?.(contentItem.item.id, expandableItemId)}
            </div>
          </div>
        </div>
      );
    },
  ),
);

SimpleLinkedItem.displayName = 'SimpleLinkedItem';
