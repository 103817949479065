import { Collection, notUndefined } from '@kontent-ai/utils';
import { OnSaveCodename } from '../../../../_shared/components/Codename/OnSaveCodename.type.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { areCollectionsVisibleForSpaces } from '../../../../_shared/selectors/contentCollections.ts';
import { ISpace } from '../../../../data/models/space/space.ts';
import { CollapsedSpaceBar as CollapsedSpaceBarComponent } from '../components/CollapsedSpaceBar.tsx';

type Props = {
  readonly isCodenameBeingSaved?: boolean;
  readonly isLinkedToActiveWebSpotlight: boolean;
  readonly onCodenameSave?: OnSaveCodename;
  readonly onEdit: (() => void) | undefined;
  readonly relatedCodeNames: ReadonlySet<string>;
  readonly searchPhrase: string;
  readonly space: ISpace;
};

export const CollapsedSpaceBar = ({
  isCodenameBeingSaved,
  isLinkedToActiveWebSpotlight,
  onCodenameSave,
  onEdit,
  relatedCodeNames,
  searchPhrase,
  space,
}: Props) => {
  const showCollections = useSelector((s) =>
    areCollectionsVisibleForSpaces(s, Collection.getValues(s.data.collections.byId)),
  );

  const allCollections = useSelector((s) => s.data.collections.byId);
  const selectedCollections = space.collectionIds
    .map((id) => allCollections.get(id))
    .filter(notUndefined);

  return (
    <CollapsedSpaceBarComponent
      areAllCollectionsSelected={allCollections.size === selectedCollections.length}
      collections={selectedCollections}
      isCodenameBeingSaved={isCodenameBeingSaved}
      isLinkedToActiveWebSpotlight={isLinkedToActiveWebSpotlight}
      onCodenameSave={onCodenameSave}
      onEdit={onEdit}
      relatedCodeNames={relatedCodeNames}
      searchPhrase={searchPhrase}
      showCollections={showCollections}
      space={space}
    />
  );
};
