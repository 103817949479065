import React, { InputHTMLAttributes } from 'react';
import { BaseInputComponent, BaseInputProps } from './components/BaseInputComponent.tsx';
import { InputControl } from './components/InputControl.tsx';
import { InputType } from './inputType.ts';

type InputElementAttributes = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  | 'autoComplete'
  | 'max'
  | 'maxLength'
  | 'min'
  | 'minLength'
  | 'name'
  | 'required'
  | 'step'
  | 'tabIndex'
>;

export interface IInputProps extends BaseInputProps, InputElementAttributes {
  readonly prefix?: React.ReactNode;
  readonly suffixes?: readonly React.ReactNode[];
  readonly type?: InputType;
}

export const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      prefix,
      suffixes,
      tooltipPlacement = 'top',
      tooltipText,
      type = InputType.Text,
      ...inputProps
    },
    forwardedRef,
  ) => {
    return (
      <BaseInputComponent
        renderControlComponent={(ref, { inputState, ...injectedProps }) => (
          <InputControl
            $inputState={inputState}
            ref={ref as React.Ref<HTMLInputElement>}
            type={type}
            {...injectedProps}
          />
        )}
        ref={forwardedRef}
        {...{
          prefix,
          suffixes,
          tooltipPlacement,
          tooltipText,
          ...inputProps,
        }}
      />
    );
  },
);

Input.displayName = 'Input';
