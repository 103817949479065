import { createGuid, notNull } from '@kontent-ai/utils';
import { ContentBlock, ContentState } from 'draft-js';
import Immutable from 'immutable';
import { Asset, IAsset } from '../../../../../data/models/assets/Asset.ts';
import {
  AssetReference,
  createAssetReferenceMemoized,
} from '../../../../itemEditor/models/contentItemElements/AssetItemElement.ts';
import { BlockType } from '../../../utils/blocks/blockType.ts';
import { isImage } from '../../../utils/blocks/blockTypeUtils.ts';
import {
  IRawBlock,
  RichTextCommentSegment,
  createRawBlock,
  getBlockDataValue,
  setBlockDataValue,
} from '../../../utils/blocks/editorBlockUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
  IGetReferencesFromContentState,
  replaceBlock,
} from '../../../utils/general/editorContentUtils.ts';

export const ImageAssetGuidKey = 'guid';
export const ImageUploadingKey = 'uploading';

export function hasExistingImage(
  content: ContentState,
  loadedAssets: Immutable.Map<Uuid, IAsset>,
): boolean {
  const blocks = getBlocks(content);
  return blocks.some((block: ContentBlock) => {
    if (isImage(block)) {
      const assetReference = getImageAssetReference(block);
      const asset = assetReference && loadedAssets.get(assetReference.id);
      return asset && Asset.exists(asset) && !asset.archived;
    }
    return false;
  });
}

export function getImageRawBlocks(
  assetIds: UuidArray,
  uploading?: boolean,
): ReadonlyArray<IRawBlock> {
  const blocks = assetIds.map((id: string) => {
    const block: IRawBlock = createRawBlock({
      type: BlockType.Image,
      data: {
        [ImageAssetGuidKey]: id,
        [RichTextCommentSegment]: createGuid(),
        ...(uploading ? { [ImageUploadingKey]: true } : {}),
      },
    });
    return block;
  });
  return blocks;
}

export function insertImagesToPlaceholder(
  input: IContentChangeInput,
  placeholderBlockKey: string,
  assetIds: UuidArray,
): IContentChangeResult {
  const rawBlocks = getImageRawBlocks(assetIds);

  return replaceBlock(input, placeholderBlockKey, rawBlocks);
}

export const getImageAssetReference = (block: ContentBlock): AssetReference | null => {
  const assetId: Uuid | null = getBlockDataValue(block, ImageAssetGuidKey) ?? null;
  return assetId ? createAssetReferenceMemoized(assetId) : null;
};

export function setImageAssetId(
  block: ContentBlock,
  assetId: Uuid,
  uploading?: boolean,
): ContentBlock {
  return setBlockDataValue(
    setBlockDataValue(block, ImageAssetGuidKey, assetId),
    ImageUploadingKey,
    uploading ? true : undefined,
  );
}

export const getImageAssetReferences: IGetReferencesFromContentState<AssetReference> = (content) =>
  getBlocks(content).filter(isImage).map(getImageAssetReference).filter(notNull);

export const isUploadingImage = (block: ContentBlock): boolean =>
  isImage(block) && !!getBlockDataValue(block, ImageUploadingKey);
