import {
  areArraysMembersShallowEqual,
  makeCancellablePromise,
  swallowCancelledPromiseError,
} from '@kontent-ai/utils';
import { useContext, useEffect, useState } from 'react';
import { loadProjectProperties } from '../../../../_shared/actions/thunkSharedActions.ts';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { getCurrentProjectId } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { getInnovationLabFeatures } from '../selectors/getInnovationLabFeatures.ts';
import { InnovationLabFeatureSet } from '../types/InnovationLabFeatureSet.type.ts';
import { createDomainModelFromServerModel } from '../utils/innovationLabModelConversion.ts';
import { InnovationLabAppFilterContext } from './InnovationLabAppFilterContext.tsx';
import { InnovationLabEmptyState } from './InnovationLabEmptyState.tsx';
import { InnovationLabFiltersEmptyState } from './InnovationLabFiltersEmptyState.tsx';

const { innovationLabRepository } = repositoryCollection;

const isFeatureSetEmpty = (featureSet: InnovationLabFeatureSet): boolean =>
  Object.keys(featureSet).length === 0;

const useInnovationLabFeaturesLoader = (): null | InnovationLabFeatureSet => {
  const [featureSet, setFeatureSet] = useState<null | InnovationLabFeatureSet>(null);
  useEffect(() => {
    const { cancel } = makeCancellablePromise(() => innovationLabRepository.getFeatures())
      .then(createDomainModelFromServerModel)
      .then(setFeatureSet)
      .catch(swallowCancelledPromiseError)
      .catch(() => setFeatureSet({}));

    return cancel;
  }, []);

  return featureSet;
};

export const InnovationLab = () => {
  const { sortBy, status } = useContext(InnovationLabAppFilterContext);
  const featureSet = useInnovationLabFeaturesLoader();
  const features = useSelector(
    getInnovationLabFeatures(featureSet, sortBy, status),
    areArraysMembersShallowEqual,
  );

  const currentProjectId = useSelector(getCurrentProjectId);
  const [projectPropertiesLoaded] = useThunkPromise(loadProjectProperties, currentProjectId);

  if (!featureSet || !projectPropertiesLoaded) {
    return <Loader />;
  }

  if (isFeatureSetEmpty(featureSet)) {
    return <InnovationLabEmptyState />;
  }

  if (features.length === 0) {
    return <InnovationLabFiltersEmptyState />;
  }

  return (
    <>
      {features.map((feature) => (
        <feature.Card key={feature.codename} featureInfo={feature.featureInfo} />
      ))}
    </>
  );
};
