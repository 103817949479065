import { Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';
import { DataUiAction } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { BarItemAction } from './BarItemAction.tsx';

type Props = {
  readonly disabled?: boolean;
  readonly disabledTooltipText?: string;
  readonly onClick?: () => void;
};

export const BarItemEditAction: React.FC<Props> = ({ disabled, disabledTooltipText, onClick }) => {
  const tooltipText = disabled && disabledTooltipText ? disabledTooltipText : 'Edit';

  return (
    <BarItemAction
      aria-label={tooltipText ?? 'Edit'}
      dataUiActionName={DataUiAction.Edit}
      disabled={disabled}
      icon={Icons.Edit}
      onClick={disabled ? undefined : onClick}
      tooltipText={tooltipText ?? ''}
    />
  );
};
