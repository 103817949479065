import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IUserCustomAppRepository } from '../../../../repositories/interfaces/IUserCustomAppRepository.type.ts';
import { ICustomAppResponseServerModel } from '../../../../repositories/serverModels/ICustomAppRepository.type.ts';
import {
  CustomApps_ListingInit_Finished,
  CustomApps_ListingInit_Started,
} from '../../constants/customAppsActionTypes.ts';

interface IInitCustomAppListingDependencies {
  readonly userCustomAppRepository: IUserCustomAppRepository;
}

const initCustomAppListingStarted = { type: CustomApps_ListingInit_Started } as const;

const initCustomAppListingFinished = (customApps: ReadonlyArray<ICustomAppResponseServerModel>) =>
  ({
    type: CustomApps_ListingInit_Finished,
    payload: {
      customApps,
    },
  }) as const;

export type InitCustomAppListingActionsType =
  | typeof initCustomAppListingStarted
  | ReturnType<typeof initCustomAppListingFinished>;

export const createInitCustomAppListingAction =
  (deps: IInitCustomAppListingDependencies) =>
  (projectId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(initCustomAppListingStarted);

    const customApps = await deps.userCustomAppRepository.getAll(projectId, abortSignal);

    dispatch(initCustomAppListingFinished(customApps));
  };
