import { IconButton } from '@kontent-ai/component-library/Button';
import { HotkeysCallback } from '@kontent-ai/component-library/hooks';
import { FocusScope } from '@react-aria/focus';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { HotkeysHandler, HotkeysMode } from '../Hotkeys/HotkeysHandler.tsx';
import { PopoverDialog, PopoverPlacement } from '../PopoverDialog/PopoverDialog.tsx';
import { ITriggerProps } from '../PopoverDialog/types/PopoverDialogContract.type.ts';
import { CodenamePopover } from './CodenamePopover.tsx';
import { OnSaveCodename } from './OnSaveCodename.type.ts';

type Props = {
  readonly codename?: string | null;
  readonly isCodenameEditable?: boolean;
  readonly onCodenameSave?: OnSaveCodename;
  readonly onCodenameCopy?: () => void;
  readonly onCodenameEdit?: () => void;
  readonly onCtrlSHandler?: HotkeysCallback;
  readonly relatedCodenames?: ReadonlySet<string>;
};

const CodenameButton = React.forwardRef<HTMLDivElement, ITriggerProps>(
  (triggerProps, forwardedRef) => (
    <div ref={forwardedRef} className="status-bar__button">
      <IconButton
        activated={triggerProps.isActive}
        aria-label="Open codename popover"
        buttonState="default"
        buttonStyle="tertiary"
        iconName="BracesOctothorpe"
        onClick={triggerProps.onToggle}
        size="medium"
        tooltipPlacement="bottom-end"
        tooltipText="Codename"
        {...getDataUiActionAttribute(DataUiAction.GetCodename)}
      />
      {triggerProps.popover}
    </div>
  ),
);

export const CodenameAppBarButton = ({
  codename,
  onCodenameSave,
  onCodenameCopy,
  onCodenameEdit,
  isCodenameEditable,
  relatedCodenames,
  onCtrlSHandler,
}: Props) => {
  if (!codename) {
    return null;
  }

  return (
    <PopoverDialog
      popoverClass="popover__dialog--is-over-app-bar"
      popoverPlacement={PopoverPlacement.Child}
      trigger={(triggerProps) => <CodenameButton {...triggerProps} />}
      popover={(togglePopover) => (
        <FocusScope contain>
          <HotkeysHandler
            mode={HotkeysMode.Dual}
            handlers={{
              onControlS: onCtrlSHandler ? onCtrlSHandler : undefined,
            }}
          >
            <CodenamePopover
              onAfterCopied={togglePopover}
              codename={codename}
              isEditable={isCodenameEditable}
              onSave={onCodenameSave}
              onEdited={onCodenameEdit}
              relatedCodenames={relatedCodenames}
              onCopied={onCodenameCopy}
            />
          </HotkeysHandler>
        </FocusScope>
      )}
    />
  );
};
