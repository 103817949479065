import { useCallback } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { ApplyEditorStateChanges } from '../draftJs/DraftJsPlugin.type.ts';

export type ForceSingleUnstyledLinePlugin = DraftJsEditorPlugin;

const applyEditorStateChanges: Decorator<ApplyEditorStateChanges> =
  (baseApplyEditorStateChanges) => (params) => {
    const allowedNewState = baseApplyEditorStateChanges(params);
    const content = allowedNewState.getCurrentContent();
    const hasSingleBlock = content.getBlockMap().size === 1;
    if (!hasSingleBlock) {
      return params.oldState;
    }
    const hasNewLines = content.getFirstBlock().getText().includes('\n');
    return hasNewLines ? params.oldState : allowedNewState;
  };

export const ForceSingleUnstyledLinePlugin: PluginComponent<ForceSingleUnstyledLinePlugin> = (
  props,
) => {
  const apply: Apply<ForceSingleUnstyledLinePlugin> = useCallback((state) => {
    state.applyEditorStateChanges.decorate(applyEditorStateChanges);
    return {};
  }, []);

  return useEditorWithPlugin(props, { apply });
};
