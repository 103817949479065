import React, { ComponentProps, useContext } from 'react';
import { CountStatus } from '../../CountStatus/CountStatus.tsx';
import { TagContext } from '../TagContext.tsx';

type Props = Omit<ComponentProps<typeof CountStatus>, 'color'>;

export const TagCountStatus = React.forwardRef<HTMLButtonElement, Props>((props, forwardedRef) => {
  const context = useContext(TagContext);

  if (!context) {
    throw new Error('Tag.CountStatus must be used within a Tag component');
  }

  return <CountStatus ref={forwardedRef} color={context.labelColor} {...props} />;
});

TagCountStatus.displayName = 'TagCountStatus';
