import React from 'react';
import { NonExistentBarItemActions } from './Actions/NonExistentBarItemActions.tsx';
import { BarItemActions } from './BarItemActions.tsx';
import { BarItemTitle } from './BarItemTitle.tsx';
import { BarItemWithLoader } from './BarItemWithLoader.tsx';

export interface IBarItemExpandedSimpleHeaderProps {
  readonly dataUiObjectName: string;
  readonly hasLoader?: boolean;
  readonly isDraggable?: boolean;
  readonly renderLeadingElement?: () => JSX.Element | React.ReactNode;
  readonly renderTitle: () => JSX.Element | React.ReactNode;
  readonly renderTitleTag?: () => JSX.Element | React.ReactNode;
}

export const BarItemExpandedSimpleHeader: React.FC<IBarItemExpandedSimpleHeaderProps> = ({
  dataUiObjectName,
  hasLoader,
  isDraggable,
  renderLeadingElement,
  renderTitle,
  renderTitleTag,
}) => (
  <BarItemWithLoader dataUiObjectName={dataUiObjectName} hasLoader={hasLoader} isExpanded>
    {isDraggable && (
      <BarItemActions>
        <NonExistentBarItemActions />
      </BarItemActions>
    )}
    {renderLeadingElement?.()}
    <BarItemTitle suffix={renderTitleTag?.()}>{renderTitle()}</BarItemTitle>
  </BarItemWithLoader>
);
