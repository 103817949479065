import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import { Collection, notNull } from '@kontent-ai/utils';
import { EditorState } from 'draft-js';
import { ClipboardEvent, useCallback } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { IUserInfo } from '../../../../../../../_shared/models/UserInfo.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { doesRoleAllowViewContentGroup } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getApplicableContributorRoleId } from '../../../../../../../_shared/utils/permissions/getContributorRole.ts';
import { getUsersInfo } from '../../../../../../../_shared/utils/usersUtils.ts';
import { IRoleWithSettings } from '../../../../../../../data/models/roles/IRoleWithSettings.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import { setRichTextClipboard } from '../../../../../../richText/actions/thunkRichTextActions.ts';
import {
  CommentInput as CommentInputComponent,
  CommentInputProps,
} from '../../../../../../richText/editors/comment/CommentInput.tsx';
import { EmptyMetadata } from '../../../../../../richText/plugins/clipboard/thunks/setRichTextClipboard.ts';
import { EmptyContentComponents } from '../../../../../models/contentItem/ContentComponent.ts';
import {
  getEditedItemViewableContentGroups,
  getSelectedContentGroupIdFromStateOrFirst,
} from '../../../../../stores/utils/contentItemElementsUtils.ts';
import { CreateContentGroupTabsId } from '../../../utils/contentGroupTabsId.ts';

type UserTuple = [Uuid, IRoleWithSettings | null | undefined, boolean];

const getUsersThatCannotViewEditedContentGroupMemoized = memoize.maxOne(
  (
    rolesById: ReadonlyMap<Uuid, IRoleWithSettings>,
    usersById: ReadonlyMap<Uuid, IProjectContributor>,
    assignees: ReadonlySet<IUserInfo>,
    editedItemTypeId: Uuid,
    selectedContentGroupId: Uuid | null,
    selectedLanguageId: Uuid,
    collectionId: Uuid | null,
  ): ReadonlyMap<Uuid, IProjectContributor> => {
    const users = Collection.getValues(usersById)
      .map(
        (u: IProjectContributor): UserTuple => [
          u.userId,
          rolesById.get(getApplicableContributorRoleId(u, selectedLanguageId, collectionId) ?? ''),
          Array.from(assignees).some((a: IUserInfo) => a.userId === u.userId),
        ],
      )
      .filter(
        ([, role, isUserAssigned]: UserTuple) =>
          !doesRoleAllowViewContentGroup(
            selectedContentGroupId,
            editedItemTypeId,
            role,
            isUserAssigned,
          ),
      )
      .map(([userId]: UserTuple) => usersById.get(userId) ?? null)
      .filter(notNull);

    return new Map(users.map((u) => [u.userId, u] as const));
  },
);

const getUsersThatCannotViewEditedContentGroup = (
  state: IStore,
): ReadonlyMap<Uuid, IProjectContributor> => {
  const {
    contentApp: { editedContentItemVariant, editedContentItem },
    data: {
      roles,
      users: { usersById },
    },
  } = state;

  const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
  if (!editedContentItem) {
    throw InvariantException('CommentInput.tsx container: editedContentItem is not set.');
  }

  const assigneeIdentifiers = editedContentItemVariant?.assignment?.assignees ?? new Set();
  const assignees = getUsersInfo(assigneeIdentifiers, usersById);
  const contentGroupTabsId = CreateContentGroupTabsId.forContentItem(editedContentItem.id);
  const selectedContentGroupId = getSelectedContentGroupIdFromStateOrFirst(
    contentGroupTabsId,
    getEditedItemViewableContentGroups(state),
    state,
  );

  const usersThatCannotViewEditedGroup = getUsersThatCannotViewEditedContentGroupMemoized(
    roles.rolesById,
    usersById,
    assignees,
    editedContentItem.editedContentItemTypeId,
    selectedContentGroupId,
    selectedLanguageId,
    editedContentItem?.collectionId ?? null,
  );

  return usersThatCannotViewEditedGroup;
};

type CommentInputOwnProps = Omit<
  CommentInputProps,
  'setRichTextClipboard' | 'usersThatCannotViewEditedGroup'
>;

export const CommentInput: React.FC<CommentInputOwnProps> = (props) => {
  const dispatch = useDispatch();
  const onSetRichTextClipboard = useCallback(
    (e: ClipboardEvent, editorState: EditorState) =>
      dispatch(setRichTextClipboard(e, editorState, EmptyContentComponents, EmptyMetadata)),
    [],
  );

  const usersThatCannotViewEditedGroup = useSelector(getUsersThatCannotViewEditedContentGroup);

  return (
    <CommentInputComponent
      {...props}
      setRichTextClipboard={onSetRichTextClipboard}
      usersThatCannotViewEditedGroup={usersThatCannotViewEditedGroup}
    />
  );
};
