import { useAttachRef, usePrevious } from '@kontent-ai/hooks';
import { IUpdateMessageElement } from '@kontent-ai/smart-link/types/lib/IFrameCommunicatorTypes';
import { Collection, noOperation } from '@kontent-ai/utils';
import {
  ReactNode,
  Ref,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useThrottledCallback } from 'use-debounce';
import useResizeObserver, { ObservedSize } from 'use-resize-observer';
import { trackUserEventWithData } from '../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { WebSpotlightPreviewResolutionChangeOrigin } from '../../../_shared/models/TrackUserEventData.ts';
import { getEditedContentItem } from '../../../_shared/selectors/getEditedContentItem.ts';
import { getEditedContentItemVariantId } from '../../../_shared/selectors/getEditedContentItemVariant.ts';
import { getSelectedLanguageCodename } from '../../../_shared/selectors/getSelectedLanguageCodename.ts';
import { clamp } from '../../../_shared/utils/numberUtils.ts';
import { getUrlOrigin } from '../../../_shared/utils/urlUtils.ts';
import { getCurrentProjectId } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { webSpotlightPreferencesStorage } from '../../../localStorages/webSpotlightPreferencesStorage.ts';
import {
  itemPreviewInfoReloadRequested,
  webSpotlightPreviewRefreshed,
} from '../actions/webSpotlightActions.ts';
import {
  WebSpotlightPreviewMaxResolutionPx,
  WebSpotlightPreviewMinResolutionPx,
} from '../constants/uiConstants.ts';
import { LivePreviewElementUpdater } from '../containers/LivePreviewElementUpdater.tsx';
import { useSharedContentItemPreviewRouteState } from '../hooks/useSharedContentItemPreviewRouteState.ts';
import {
  IWebSpotlightPreviewResolution,
  WebSpotlightPreviewResolutionType,
  fitToScreenResolution,
  isPredefinedResolutionType,
  webSpotlightPredefinedPreviewResolutions,
} from '../models/webSpotlightPreviewResolutionType.ts';
import { isPreviewAutoRefreshFeatureEnabledByUser } from '../selectors/webSpotlightSelectors.ts';
import {
  IReloadPreviewMessageData,
  ISmartLinkSDKSupportedFeatures,
  IUpdatePreviewSmartLinkSdkHostMessage,
  SmartLinkSdkHostMessage,
  SmartLinkSdkHostMessageType,
} from '../types/SmartLinkSdkApi.ts';
import { FloatingEditorPosition } from '../types/floatingEditorPosition.ts';
import {
  calculateContainerFitResolution,
  calculateContainerFitScale,
  doesPreviewResolutionFitInside,
} from '../utils/webSpotlightPreviewUtils.ts';

type RefreshPreviewParams = {
  readonly isManualRefresh: boolean;
  readonly data?: IReloadPreviewMessageData;
  readonly isPreviewUrlOutdated?: boolean;
};

type WebSpotlightContextState = {
  readonly currentPreviewUrl: string;
  readonly editableElementsVisible: boolean;
  readonly floatingEditorPosition: FloatingEditorPosition;
  readonly getPreviewIFrameWindow: () => Window | null;
  readonly isPreviewIFrameInitialized: boolean;
  readonly isSmartLinkSdkInitialized: boolean;
  readonly previewIFrameKey: number;
  readonly previewIFrameRef: RefObject<HTMLIFrameElement> | undefined;
  readonly previewIFrameResolution: IWebSpotlightPreviewResolution;
  readonly previewIFrameResolutionType: WebSpotlightPreviewResolutionType;
  readonly previewPaneRef: RefObject<HTMLDivElement> | undefined;
  readonly previewUrl: string;
  readonly refreshPreview: (params: RefreshPreviewParams) => void;
  readonly requestPreviewIFrameCurrentUrl: () => void;
  readonly rescalePreview: (scale: number) => void;
  readonly rescalePreviewToFit: () => void;
  readonly resetPreviewIFrameCurrentUrl: () => void;
  readonly resizePreview: (width: number, height: number) => void;
  readonly rotatePreview: () => void;
  readonly selectPreviewResolutionType: (type: WebSpotlightPreviewResolutionType) => void;
  readonly sendMessageToPreviewIFrame: (message: SmartLinkSdkHostMessage) => void;
  readonly setCurrentPreviewUrl: (url: string) => void;
  readonly setFloatingEditorPosition: (value: FloatingEditorPosition) => void;
  readonly setIsPreviewIFrameInitialized: (value: boolean) => void;
  readonly setIsSmartLinkSdkInitialized: (value: boolean) => void;
  readonly setPreviewIFrameRef: Ref<HTMLIFrameElement>;
  readonly setPreviewIFrameResolution: (resolution: IWebSpotlightPreviewResolution) => void;
  readonly setPreviewIFrameResolutionType: (type: WebSpotlightPreviewResolutionType) => void;
  readonly setSupportedSmartLinkFeatures: (value: ISmartLinkSDKSupportedFeatures | null) => void;
  readonly supportedSmartLinkFeatures: ISmartLinkSDKSupportedFeatures | null;
  readonly toggleEditableElements: () => void;
};

const defaultContext: WebSpotlightContextState = {
  currentPreviewUrl: '',
  editableElementsVisible: false,
  floatingEditorPosition: FloatingEditorPosition.Right,
  getPreviewIFrameWindow: () => null,
  isPreviewIFrameInitialized: false,
  isSmartLinkSdkInitialized: false,
  previewIFrameKey: 0,
  previewIFrameRef: undefined,
  previewIFrameResolution: fitToScreenResolution,
  previewIFrameResolutionType: WebSpotlightPreviewResolutionType.FitScreen,
  previewPaneRef: undefined,
  previewUrl: '',
  refreshPreview: noOperation,
  requestPreviewIFrameCurrentUrl: noOperation,
  rescalePreview: noOperation,
  rescalePreviewToFit: noOperation,
  resetPreviewIFrameCurrentUrl: noOperation,
  resizePreview: noOperation,
  rotatePreview: noOperation,
  selectPreviewResolutionType: noOperation,
  sendMessageToPreviewIFrame: noOperation,
  setCurrentPreviewUrl: noOperation,
  setFloatingEditorPosition: noOperation,
  setIsPreviewIFrameInitialized: noOperation,
  setIsSmartLinkSdkInitialized: noOperation,
  setPreviewIFrameRef: noOperation,
  setPreviewIFrameResolution: noOperation,
  setPreviewIFrameResolutionType: noOperation,
  setSupportedSmartLinkFeatures: noOperation,
  supportedSmartLinkFeatures: null,
  toggleEditableElements: noOperation,
};

const WebSpotlightContext = createContext<WebSpotlightContextState>(defaultContext);
WebSpotlightContext.displayName = 'WebSpotlight';

export const useWebSpotlight = (): WebSpotlightContextState => useContext(WebSpotlightContext);

type Props = Readonly<{
  children: ReactNode;
  previewUrl: string;
  spaceId: Uuid | null;
}>;

export const WebSpotlightContextProvider = ({ children, previewUrl, spaceId }: Props) => {
  const dispatch = useDispatch();
  const previewPaneRef = useRef<HTMLDivElement | null>(null);

  const [editableElementsVisible, setEditableElementsVisible] = useState<boolean>(true);
  const [floatingEditorPosition, setFloatingEditorPosition] = useState<FloatingEditorPosition>(
    FloatingEditorPosition.Right,
  );
  const [isPreviewIFrameInitialized, setIsPreviewIFrameInitialized] = useState<boolean>(false);
  const [isSmartLinkSdkInitialized, setIsSmartLinkSdkInitialized] = useState<boolean>(false);
  const [previewIFrameKey, setPreviewIFrameKey] = useState<number>(0);
  const [previewIFrameResolution, setPreviewIFrameResolution] =
    useState<IWebSpotlightPreviewResolution | null>(null);
  const [previewIFrameResolutionType, setPreviewIFrameResolutionType] =
    useState<WebSpotlightPreviewResolutionType | null>(null);
  const [supportedSmartLinkFeatures, setSupportedSmartLinkFeatures] =
    useState<ISmartLinkSDKSupportedFeatures | null>(null);

  const projectId = useSelector(getCurrentProjectId);
  const editedItemCodename = useSelector((s) => getEditedContentItem(s).codename);
  const editedContentItemVariantId = useSelector(getEditedContentItemVariantId);
  const languageCodename = useSelector(getSelectedLanguageCodename);

  const [sharedPreviewState, clearSharedPreviewState] = useSharedContentItemPreviewRouteState();

  const [currentPreviewUrl, setCurrentPreviewUrl] = useState<string>('');

  const adjustForPreviewIframeSize = useCallback(
    ({ width, height }: ObservedSize) => {
      if (!width || !height || !isPreviewIFrameInitialized) {
        return;
      }

      const fitsToScreen =
        previewIFrameResolutionType === WebSpotlightPreviewResolutionType.FitScreen;
      if (
        fitsToScreen &&
        (width !== previewIFrameResolution?.width || height !== previewIFrameResolution.height)
      ) {
        setPreviewIFrameResolution({
          scale: 1,
          width,
          height,
        });
      }
    },
    [
      previewIFrameResolution?.width,
      previewIFrameResolution?.height,
      previewIFrameResolutionType,
      isPreviewIFrameInitialized,
    ],
  );

  const onResize = useThrottledCallback(adjustForPreviewIframeSize, 200);
  const { ref: previewIFrameRefCallback } = useResizeObserver({
    onResize,
  });

  const { refToForward: setPreviewIFrameRef, refObject: previewIFrameRef } =
    useAttachRef<HTMLIFrameElement>(previewIFrameRefCallback);

  const getPreviewIFrameWindow = useCallback(
    (): Window | null => previewIFrameRef.current?.contentWindow ?? null,
    [previewIFrameRef],
  );

  const isAutoRefreshEnabledByUser = useSelector(isPreviewAutoRefreshFeatureEnabledByUser);

  const rescalePreview = useCallback((newScale: number) => {
    setPreviewIFrameResolution((prevState) =>
      prevState
        ? calculateContainerFitResolution(previewPaneRef.current, prevState, newScale)
        : null,
    );
  }, []);

  const resizePreview = useCallback((newWidth: number, newHeight: number, newScale?: number) => {
    const newResolution = calculateContainerFitScale(
      previewPaneRef.current,
      newWidth,
      newHeight,
      newScale,
    );

    setPreviewIFrameResolution(newResolution);
  }, []);

  const rotatePreview = useCallback(() => {
    if (!previewIFrameResolution) return;

    setPreviewIFrameResolutionType(WebSpotlightPreviewResolutionType.Responsive);
    resizePreview(previewIFrameResolution.height, previewIFrameResolution.width);

    dispatch(
      trackUserEventWithData(TrackedEvent.WebSpotlightPreviewResolutionChanged, {
        origin: WebSpotlightPreviewResolutionChangeOrigin.RotateButton,
      }),
    );
  }, [previewIFrameResolution, resizePreview]);

  const rescalePreviewToFit = useCallback(() => {
    const preview = previewIFrameRef.current;
    const container = previewPaneRef.current;
    if (!container || !preview || !isPreviewIFrameInitialized) {
      return;
    }

    const fitsInsideContainer =
      previewIFrameResolution && doesPreviewResolutionFitInside(previewIFrameResolution, container);
    if (
      !fitsInsideContainer &&
      previewIFrameResolution?.width.toString() === preview.width &&
      previewIFrameResolution?.height.toString() === preview.height
    ) {
      resizePreview(previewIFrameResolution.width, previewIFrameResolution.height);
    }
  }, [isPreviewIFrameInitialized, previewIFrameResolution, resizePreview, previewIFrameRef]);

  const selectPreviewResolutionType = useCallback(
    (type: WebSpotlightPreviewResolutionType) => {
      setPreviewIFrameResolutionType(type);

      if (isPredefinedResolutionType(type)) {
        const resolution = webSpotlightPredefinedPreviewResolutions[type];
        resizePreview(resolution.width, resolution.height);
      } else if (type === WebSpotlightPreviewResolutionType.Responsive) {
        const previewRect = previewIFrameRef.current?.getBoundingClientRect();
        const newWidth = previewRect?.width ?? 0;
        const newHeight = previewRect?.height ?? 0;
        resizePreview(newWidth, newHeight);
      }
    },
    [resizePreview, previewIFrameRef],
  );

  const sendMessageToPreviewIFrame = useCallback(
    (message: SmartLinkSdkHostMessage): void => {
      const targetWindow = getPreviewIFrameWindow();
      if (targetWindow) {
        // Send the message only to origin generated from previewUrl to prevent others from sniffing
        const targetOrigin = getUrlOrigin(previewUrl);
        targetWindow.postMessage(message, targetOrigin);
      }
    },
    [getPreviewIFrameWindow, previewUrl],
  );

  const updatePreview = useCallback(
    (elements: ReadonlyArray<IUpdateMessageElement>): void => {
      if (
        !editedContentItemVariantId ||
        !supportedSmartLinkFeatures?.updateHandler ||
        Collection.isEmpty(elements)
      ) {
        return;
      }

      const message: IUpdatePreviewSmartLinkSdkHostMessage = {
        type: SmartLinkSdkHostMessageType.UpdatePreview,
        data: {
          projectId,
          item: {
            id: editedContentItemVariantId?.itemId,
            codename: editedItemCodename,
          },
          variant: {
            id: editedContentItemVariantId?.variantId,
            codename: languageCodename,
          },
          elements,
        },
      };
      sendMessageToPreviewIFrame(message);
    },
    [
      sendMessageToPreviewIFrame,
      supportedSmartLinkFeatures?.updateHandler,
      editedContentItemVariantId,
      languageCodename,
      editedItemCodename,
      projectId,
    ],
  );

  const refreshPreview = useCallback(
    ({ isManualRefresh, data, isPreviewUrlOutdated }: RefreshPreviewParams) => {
      if (supportedSmartLinkFeatures?.refreshHandler && !isPreviewUrlOutdated) {
        sendMessageToPreviewIFrame({
          type: SmartLinkSdkHostMessageType.RefreshPreview,
          metadata: { manualRefresh: isManualRefresh },
          data,
        });
      } else {
        if (previewIFrameRef?.current) {
          setPreviewIFrameKey((previousState) => previousState + 1);
        }
        if (isManualRefresh || isPreviewUrlOutdated) {
          dispatch(itemPreviewInfoReloadRequested());
        }
      }

      dispatch(webSpotlightPreviewRefreshed(isManualRefresh));
    },
    [sendMessageToPreviewIFrame, supportedSmartLinkFeatures?.refreshHandler, previewIFrameRef],
  );

  const requestPreviewIFrameCurrentUrl = useCallback(() => {
    if (supportedSmartLinkFeatures?.previewIFrameCurrentUrlHandler) {
      sendMessageToPreviewIFrame({
        type: SmartLinkSdkHostMessageType.PreviewIFrameCurrentUrl,
      });
    }
  }, [sendMessageToPreviewIFrame, supportedSmartLinkFeatures?.previewIFrameCurrentUrlHandler]);

  const resetPreviewIFrameCurrentUrl = useCallback(() => setCurrentPreviewUrl(''), []);

  const previousPreviewUrl = usePrevious(previewUrl);
  useEffect(() => {
    if (previousPreviewUrl !== previewUrl) {
      setIsSmartLinkSdkInitialized(false);
    }
  }, [previousPreviewUrl, previewUrl]);

  useEffect(() => {
    if (!isPreviewIFrameInitialized) return;

    if (sharedPreviewState) {
      if (sharedPreviewState.width || sharedPreviewState.height) {
        const width = clamp(
          sharedPreviewState.width || 0,
          WebSpotlightPreviewMinResolutionPx,
          WebSpotlightPreviewMaxResolutionPx,
        );
        const height = clamp(
          sharedPreviewState.height || 0,
          WebSpotlightPreviewMinResolutionPx,
          WebSpotlightPreviewMaxResolutionPx,
        );
        resizePreview(width, height);
        setPreviewIFrameResolutionType(WebSpotlightPreviewResolutionType.Responsive);
      } else {
        setPreviewIFrameResolutionType(WebSpotlightPreviewResolutionType.FitScreen);
      }
    } else {
      const webSpotlightPreferences = webSpotlightPreferencesStorage.get(projectId);
      if (isPredefinedResolutionType(webSpotlightPreferences.previewResolutionType)) {
        const resolution =
          webSpotlightPredefinedPreviewResolutions[webSpotlightPreferences.previewResolutionType];
        resizePreview(resolution.width, resolution.height);
      } else if (
        webSpotlightPreferences.previewResolutionType ===
        WebSpotlightPreviewResolutionType.Responsive
      ) {
        const previewRect = previewIFrameRef.current?.getBoundingClientRect();
        const newWidth = webSpotlightPreferences.customPreviewWidth ?? previewRect?.width ?? 0;
        const newHeight = webSpotlightPreferences.customPreviewHeight ?? previewRect?.height ?? 0;
        const newScale = webSpotlightPreferences.customPreviewScale ?? undefined;
        resizePreview(newWidth, newHeight, newScale);
      } else if (
        webSpotlightPreferences.previewResolutionType ===
        WebSpotlightPreviewResolutionType.FitScreen
      ) {
        const newResolution = calculateContainerFitResolution(
          previewPaneRef.current,
          { width: 0, height: 0, scale: 0 },
          1,
        );
        setPreviewIFrameResolution(newResolution);
      }
      setPreviewIFrameResolutionType(webSpotlightPreferences.previewResolutionType);
    }
  }, [sharedPreviewState, projectId, resizePreview, isPreviewIFrameInitialized, previewIFrameRef]);

  useEffect(() => {
    const areSharedPreviewDataApplied =
      sharedPreviewState &&
      sharedPreviewState.height === previewIFrameResolution?.height &&
      sharedPreviewState.width === previewIFrameResolution?.width;

    if (
      areSharedPreviewDataApplied ||
      !isPreviewIFrameInitialized ||
      !previewIFrameResolution ||
      !previewIFrameResolutionType
    ) {
      return;
    }

    if (sharedPreviewState) {
      clearSharedPreviewState();
    }

    const typeRelatedValues =
      previewIFrameResolutionType === WebSpotlightPreviewResolutionType.Responsive
        ? {
            customPreviewHeight: previewIFrameResolution.height,
            customPreviewScale: previewIFrameResolution.scale,
            customPreviewWidth: previewIFrameResolution.width,
          }
        : {
            customPreviewHeight: null,
            customPreviewScale: null,
            customPreviewWidth: null,
          };

    webSpotlightPreferencesStorage.set(projectId, {
      ...typeRelatedValues,
      isPreviewAutoRefreshEnabled: isAutoRefreshEnabledByUser,
      previewResolutionType: previewIFrameResolutionType,
      spaceId,
    });
  }, [
    clearSharedPreviewState,
    isAutoRefreshEnabledByUser,
    isPreviewIFrameInitialized,
    previewIFrameResolution,
    previewIFrameResolutionType,
    projectId,
    sharedPreviewState,
    spaceId,
  ]);

  const contextValue: WebSpotlightContextState = useMemo(
    () => ({
      currentPreviewUrl,
      editableElementsVisible,
      floatingEditorPosition,
      getPreviewIFrameWindow,
      isPreviewIFrameInitialized,
      isSmartLinkSdkInitialized,
      previewIFrameKey,
      previewIFrameRef,
      previewIFrameResolution: previewIFrameResolution ?? fitToScreenResolution,
      previewIFrameResolutionType:
        previewIFrameResolutionType ?? WebSpotlightPreviewResolutionType.FitScreen,
      previewPaneRef,
      previewUrl,
      refreshPreview,
      requestPreviewIFrameCurrentUrl,
      rescalePreview,
      rescalePreviewToFit,
      resetPreviewIFrameCurrentUrl,
      resizePreview,
      rotatePreview,
      selectPreviewResolutionType,
      sendMessageToPreviewIFrame,
      setCurrentPreviewUrl,
      setFloatingEditorPosition,
      setIsPreviewIFrameInitialized,
      setIsSmartLinkSdkInitialized,
      setPreviewIFrameRef,
      setPreviewIFrameResolution,
      setPreviewIFrameResolutionType,
      setSupportedSmartLinkFeatures,
      supportedSmartLinkFeatures,
      toggleEditableElements: () => {
        setEditableElementsVisible(!editableElementsVisible);
      },
    }),
    [
      currentPreviewUrl,
      editableElementsVisible,
      floatingEditorPosition,
      getPreviewIFrameWindow,
      isPreviewIFrameInitialized,
      isSmartLinkSdkInitialized,
      previewIFrameKey,
      previewIFrameRef,
      previewIFrameResolution,
      previewIFrameResolutionType,
      previewUrl,
      refreshPreview,
      requestPreviewIFrameCurrentUrl,
      rescalePreview,
      rescalePreviewToFit,
      resetPreviewIFrameCurrentUrl,
      resizePreview,
      rotatePreview,
      selectPreviewResolutionType,
      sendMessageToPreviewIFrame,
      setPreviewIFrameRef,
      supportedSmartLinkFeatures,
    ],
  );

  return (
    <WebSpotlightContext.Provider value={contextValue}>
      <LivePreviewElementUpdater onUpdate={updatePreview} />
      {children}
    </WebSpotlightContext.Provider>
  );
};
