import React from 'react';
import styled, { css } from 'styled-components';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import {
  colorBackgroundHoverInverse,
  colorIconDefaultInverse,
  colorTextDefaultInverse,
} from '../../../tokens/decision/colors.ts';
import { transitionBgHover } from '../../../tokens/decision/transitions.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { Spacing, gridUnit } from '../../../tokens/quarks/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { getDataUiObjectNameAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { RouterLink } from '../../Anchor/Anchor.tsx';
import { Icons } from '../../Icons/components/icons.ts';
import { Label } from '../../Label/Label.tsx';
import { LabelSize } from '../../Label/labelSize.ts';
import { OptionalTooltip } from '../../Tooltip/OptionalTooltip.tsx';

export type BreadcrumbsItemProps = {
  readonly label: string;
  readonly to: string;
};

const breadcrumbsItemMinWidth = 5 * gridUnit;

type Props = BreadcrumbsItemProps & {
  readonly allowEllipsis?: boolean;
  readonly lastItem?: boolean;
};

export const StyledListItem = styled.li<Pick<Props, 'allowEllipsis'>>`
  display: flex;
  align-items: center;
  height: 100%;

  ${({ allowEllipsis }) =>
    allowEllipsis
      ? css`
    min-width: ${px(breadcrumbsItemMinWidth)};

    &:last-child {
      /* '8' was picked by trial-and-error as we want prioritize shrinking of the last breadcrumb item (as opposed to the first) */
      flex-shrink: 8;
    }
  `
      : css`
    flex-shrink: 0;
  `};
`;

const StyledLink = styled(RouterLink)<{
  readonly $isFocusVisible: boolean;
}>`
  position: relative;
  height: 100%;
  display: flex;
  min-width: ${px(breadcrumbsItemMinWidth)};
  padding: 0 ${px(Spacing.S)};
  align-items: center;
  transition: background-color ${transitionBgHover};
  white-space: nowrap;
  color: ${colorTextDefaultInverse};
  border-radius: ${px(BorderRadius.S)};
  
  &:focus,
  &:hover {
    background-color: ${colorBackgroundHoverInverse};
    color: ${colorTextDefaultInverse};
  }

  ${({ $isFocusVisible }) => $isFocusVisible && shadowFocusStyles};
`;

export const BreadcrumbsItem = React.forwardRef<HTMLLIElement, Props>(
  ({ label, to, lastItem, allowEllipsis = false }, forwardedRef) => {
    const { isFocusVisible, focusProps } = useOurFocusRing();

    return (
      <StyledListItem ref={forwardedRef} allowEllipsis={allowEllipsis}>
        <OptionalTooltip
          placement="bottom-start"
          text={label}
          customRenderText={(ref) => (
            <StyledLink
              to={to}
              aria-current={lastItem ? 'page' : undefined}
              $isFocusVisible={isFocusVisible}
              {...getDataUiObjectNameAttribute(label)}
              {...focusProps}
            >
              <Label ref={ref} size={LabelSize.L}>
                {label}
              </Label>
            </StyledLink>
          )}
        />
        {!lastItem && <Icons.ChevronRight size={IconSize.XS} color={colorIconDefaultInverse} />}
      </StyledListItem>
    );
  },
);

BreadcrumbsItem.displayName = 'BreadcrumbsItem';
