import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import { BaseColor } from '@kontent-ai/component-library/tokens';
import { ComponentProps } from 'react';

export const renderFilterOption =
  (anyId: string) =>
  (id: string, _object: object, defaultTagProps: ComponentProps<typeof DefaultTag>) =>
    id === anyId ? (
      <Tag {...defaultTagProps} background={BaseColor.Gray30} />
    ) : (
      <DefaultTag {...defaultTagProps} />
    );
