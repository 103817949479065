import React from 'react';
import { BarItemExpandedSimpleHeader } from '../../../../../_shared/components/BarItems/BarItemExpandedSimpleHeader.tsx';
import { WorkflowStepColor } from '../../../../../data/constants/WorkflowStepColor.ts';
import { StepColorPin } from '../stepBarItems/StepColor/StepColorPin.tsx';

type StepEditorHeader = {
  readonly stepName: string;
  readonly color: WorkflowStepColor;
};

export const StepEditorHeader: React.FC<StepEditorHeader> = (props) => {
  return (
    <BarItemExpandedSimpleHeader
      dataUiObjectName={props.stepName}
      isDraggable
      renderTitle={() => props.stepName}
      renderLeadingElement={() => <StepColorPin color={props.color} />}
    />
  );
};

StepEditorHeader.displayName = 'StepEditorHeader';
