import { AppMenuItem } from '@kontent-ai/component-library/NavigationBar';
import { useLocation } from 'react-router';
import { isSitemapEnabled } from '../../../applications/contentModels/sitemap/utils/sitemapUtils.ts';
import { canAccessAnyCustomApp as canAccessAnyCustomAppSelector } from '../../../applications/customApps/utils/customAppsUtils.ts';
import { canAccessEnvironmentSettings } from '../../../applications/environmentSettings/utils/environmentSettingsMenuStructureUtils.tsx';
import { isWebSpotlightEnabledAndInitializedForCurrentProject } from '../../../applications/webSpotlight/selectors/webSpotlightSelectors.ts';
import {
  IUserProjectInfo,
  emptyUserProjectInfo,
} from '../../../data/models/user/UserProjectInfo.ts';
import {
  getCurrentProject,
  getCurrentProjectContainer,
  getCurrentProjectId,
  getCurrentProjectSubscription,
  getProjectInfo,
} from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { projectIdStorage } from '../../../localStorages/projectIdStorage.ts';
import { MainMenu as MainMenuComponent } from '../../components/MainMenu/MainMenu.tsx';
import { useSelector } from '../../hooks/useSelector.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { getMainMenuStructure } from '../../utils/mainMenu/getMainMenuStructure.ts';
import { getUserCapability } from '../../utils/permissions/capability.ts';
import { isAtProjectContainerLevel } from '../../utils/routing/projectMenuUtils.ts';

export type MainMenuItemConfig = Omit<AppMenuItem, 'id' | 'isActive'>;

export const MainMenu = () => {
  const { pathname } = useLocation();
  const isProjectContainerLevel = isAtProjectContainerLevel(pathname);

  const currentProject = useSelector((s) =>
    isProjectContainerLevel ? getCurrentProjectContainer(s) : getCurrentUserProjectInfo(s),
  );

  const currentProjectSubscription = useSelector(getCurrentProjectSubscription);

  const showSitemap = isSitemapEnabled(currentProjectSubscription);
  const canAccessAnyCustomApp = useSelector(canAccessAnyCustomAppSelector);
  const showWebSpotlight = useSelector(isWebSpotlightEnabledAndInitializedForCurrentProject);
  const showEnvironmentSettings = useSelector((s) =>
    canAccessEnvironmentSettings(s, currentProject.projectId),
  );

  const capability = getUserCapability(currentProject);
  const menuStructure = getMainMenuStructure(
    capability,
    currentProject.projectId,
    currentProject.projectContainerId,
    canAccessAnyCustomApp,
    showSitemap,
    showWebSpotlight,
    showEnvironmentSettings,
    pathname,
  );

  return <MainMenuComponent menuStructure={menuStructure} />;
};

MainMenu.displayName = 'MainMenu';

const getCurrentUserProjectInfo = (state: IStore): IUserProjectInfo => {
  const currentEnvironmentId = getCurrentProjectId(state);

  return currentEnvironmentId ? getCurrentProject(state) : getLastOpenedUserProjectInfo(state);
};

const getLastOpenedUserProjectInfo = (state: IStore): IUserProjectInfo => {
  const lastOpenedEnvironmentId = projectIdStorage.load();

  return lastOpenedEnvironmentId
    ? getProjectInfo(state, lastOpenedEnvironmentId)
    : emptyUserProjectInfo;
};
