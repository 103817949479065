import { Box } from '@kontent-ai/component-library/Box';
import { IconButton, QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Stack } from '@kontent-ai/component-library/Stack';
import { SrOnly } from '@kontent-ai/component-library/styles';
import {
  Spacing,
  spacingElementEdgeHorizontal,
  spacingElementEdgeVertical,
  spacingMainLayoutTop,
} from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import Immutable from 'immutable';
import React, { useEffect } from 'react';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import { isUuid } from '../../../../_shared/utils/validation/typeValidators.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IEditedContentItem } from '../../../itemEditor/models/contentItem/edited/EditedContentItem.ts';
import { IExpandedNodesData } from '../../reducers/expandedNodesData.ts';
import { NodeId } from '../../types/nodeId.type.ts';
import { WebSpotlightTabName } from '../../types/webSpotlightTabName.ts';
import {
  checkIfItemHasSubpages,
  getNodeIdFromPath,
  getSelectedItemIdFromPath,
} from '../../utils/webSpotlightUtils.ts';
import { WebSpotlightTreeNode } from './WebSpotlightTreeNode.tsx';

const WebSpotlightRootItemDepth = 0;

export interface IWebSpotlightTreeStateProps {
  readonly contentItems: Immutable.Map<Uuid, IListingContentItem>;
  readonly currentPath: string;
  readonly editedContentItem: IEditedContentItem | null;
  readonly expandedNodesData: Immutable.Map<NodeId, IExpandedNodesData>;
  readonly focusedNodeId: NodeId;
  readonly isCollapsed: boolean;
  readonly nodesBeingLoaded: Immutable.Set<NodeId>;
  readonly rootItemId: Uuid;
  readonly rootPath: string;
  readonly selectedLanguageId: Uuid;
  readonly selectedTab: WebSpotlightTabName;
  readonly subpagesById: Immutable.Map<Uuid, UuidArray>;
}

export interface IWebSpotlightTreeDispatchProps {
  readonly onNodeCollapsed: (nodeId: NodeId) => void;
  readonly onNodeExpanded: (nodeId: NodeId, itemId: Uuid) => void;
  readonly onNodeSelected: (nodeId: NodeId, itemId: Uuid, nodePath: string) => void;
  readonly onToggleTree: (isCollapsed: boolean) => void;
}

export type WebSpotlightTreeProps = IWebSpotlightTreeStateProps & IWebSpotlightTreeDispatchProps;

export const WebSpotlightTree: React.FC<WebSpotlightTreeProps> = ({
  contentItems,
  currentPath,
  editedContentItem,
  expandedNodesData,
  focusedNodeId,
  isCollapsed,
  nodesBeingLoaded,
  onNodeCollapsed,
  onNodeExpanded,
  onNodeSelected,
  onToggleTree,
  rootItemId,
  rootPath,
  selectedLanguageId,
  selectedTab,
  subpagesById,
}) => {
  const selectedNodeId = getNodeIdFromPath(currentPath);
  const selectedItemId = getSelectedItemIdFromPath(currentPath);
  const canSelectedNodeBeExpanded =
    isUuid(selectedItemId) && checkIfItemHasSubpages(subpagesById, contentItems, selectedItemId);
  const expandTree = () => (isCollapsed ? onToggleTree(false) : undefined);
  const onTreeToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onToggleTree(!isCollapsed);
  };

  useEffect(() => {
    if (canSelectedNodeBeExpanded) {
      onNodeExpanded(selectedNodeId, selectedItemId);
    }
  }, [canSelectedNodeBeExpanded, onNodeExpanded, selectedItemId, selectedNodeId]);

  return (
    <div
      className={classNames('web-spotlight-tree', {
        'web-spotlight-tree--is-clickable': isCollapsed,
      })}
      onClick={expandTree}
    >
      <div className="web-spotlight-tree__nodes-wrapper">
        {isCollapsed ? (
          <>
            <SrOnly>
              <PageTitle>{AppNames.WebSpotlight}</PageTitle>
            </SrOnly>
            <div className="web-spotlight-tree__top-toggle-button">
              <QuinaryButton
                onClick={onTreeToggle}
                tooltipText="Open page tree"
                tooltipPlacement="top-start"
              >
                <QuinaryButton.Icon icon={Icons.TreeStructure} screenReaderText="Open page tree" />
              </QuinaryButton>
            </div>
          </>
        ) : (
          <Box
            overflowX="hidden"
            overflowY="auto"
            paddingTop={spacingMainLayoutTop}
            paddingX={spacingElementEdgeHorizontal}
            paddingBottom={spacingElementEdgeVertical}
          >
            <Stack spacing={Spacing.L}>
              <Box
                // Prevent text wrapping when tree is expanding from the collapsed state
                whiteSpace="nowrap"
              >
                <PageTitle>{AppNames.WebSpotlight}</PageTitle>
              </Box>
              <ul className="web-spotlight-tree__node web-spotlight-tree__node--is-root">
                <WebSpotlightTreeNode
                  contentItems={contentItems}
                  currentPath={currentPath}
                  depth={WebSpotlightRootItemDepth}
                  editedContentItem={editedContentItem}
                  expandedNodesData={expandedNodesData}
                  focusedNodeId={focusedNodeId}
                  itemId={rootItemId}
                  nodesBeingLoaded={nodesBeingLoaded}
                  onNodeCollapsed={onNodeCollapsed}
                  onNodeExpanded={onNodeExpanded}
                  onNodeSelected={onNodeSelected}
                  parentPath={rootPath}
                  selectedNodeId={selectedNodeId}
                  selectedLanguageId={selectedLanguageId}
                  selectedTab={selectedTab}
                  subpagesById={subpagesById}
                />
              </ul>
            </Stack>
          </Box>
        )}
      </div>

      <div className="web-spotlight-tree__toggle-button">
        <IconButton
          aria-label="Toggle page tree"
          buttonStyle="tertiary"
          iconName={isCollapsed ? 'ChevronDoubleRight' : 'ChevronDoubleLeft'}
          onClick={onTreeToggle}
          size="large"
          tooltipPlacement="right"
          tooltipText={isCollapsed ? 'Open page tree' : 'Close page tree'}
        />
      </div>
    </div>
  );
};

WebSpotlightTree.displayName = 'WebSpotlightTree';
