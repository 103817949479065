import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { memoize } from '@kontent-ai/memoization';
import { alphabetically, createCompare } from '@kontent-ai/utils';
import { AiGuidelines } from '../../../../data/models/aiGuidelines.ts';

export const getAiGuidelinesMenuOptions = memoize.maxOne(
  (
    aiGuidelines: ReadonlyArray<AiGuidelines>,
    defaultAiGuidelinesId: Uuid | null,
  ): ReadonlyArray<IBaseSelectItem> =>
    aiGuidelines
      .map(
        ({ id, name }): IBaseSelectItem => ({
          id,
          label: defaultAiGuidelinesId === id ? `${name} (Default)` : name,
        }),
      )
      .sort(createCompare({ compare: alphabetically, select: (item) => item.label })),
);
