import React, { ComponentProps } from 'react';
import { DragSource } from '../../../../../_shared/components/DragDrop/DragSource.tsx';
import { DndTypes } from '../../../../../_shared/constants/dndTypes.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { startLanguageDragging } from '../../actions/localizationActions.ts';
import { saveLanguagesToServer } from '../../actions/thunkLocalizationActions.ts';
import { LanguageBar } from './LanguageBar.tsx';

export const DraggableLanguageBar: React.FC<
  Omit<ComponentProps<typeof LanguageBar>, 'isDragging' | 'connectDragSource'>
> = (props) => {
  const dispatch = useDispatch();

  const onLanguagePickedUp = () => dispatch(startLanguageDragging(props.language.id));
  const onLanguageDropped = () => dispatch(saveLanguagesToServer());

  return (
    <DragSource
      parentId=""
      sourceId={props.language.id}
      onDragStart={onLanguagePickedUp}
      onDragEnd={onLanguageDropped}
      renderDraggable={(connectDragSource, isDragging) => (
        <LanguageBar {...props} connectDragSource={connectDragSource} isDragging={isDragging} />
      )}
      renderPreview={() => <LanguageBar {...props} isDragging={false} />}
      type={DndTypes.Language_Bar_Move}
    />
  );
};
