export enum AiActionName {
  CategorizeItem = 'CategorizeItem',
  ChangeTone = 'ChangeTone',
  CreateTitle = 'CreateTitle',
  DescribeImage = 'DescribeImage',
  GenerateContentByInstruction = 'GenerateContentByInstruction',
  ImproveContentByBrief = 'ImproveContentByBrief',
  MakeShorter = 'MakeShorter',
  MatchWritingStyleOfItem = 'MatchWritingStyleOfItem',
  ReviewByGuidelines = 'ReviewByGuidelines',
  Summarize = 'Summarize',
  TranslateContent = 'TranslateContent',
}
