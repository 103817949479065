import { useEventListener, usePrevious } from '@kontent-ai/hooks';
import { ListKeyboardDelegate, useSelectableCollection } from '@react-aria/selection';
import { mergeProps } from '@react-aria/utils';
import { Key } from '@react-types/shared';
import {
  ChangeEvent,
  ChangeEventHandler,
  ComponentPropsWithRef,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { VariableSizeList as VirtualizedList } from 'react-window';
import { useHotkeys } from '../../../hooks/useHotkeys.tsx';
import { InputState } from '../../Input/inputStateEnum.ts';
import {
  scrollItemIntoView,
  scrollVirtualizedItemIntoView,
} from '../../VerticalMenu/utils/scrollItemIntoView.ts';
import { isItemNode } from '../../VerticalMenu/utils/utils.tsx';
import { ListBox } from '../components/ListBox.tsx';
import { ISelectItem } from '../types.ts';
import { getOptionId } from '../utils/getOptionId.ts';
import { MultiSelectState, UseMultiSelectStateOptions } from './useMultiSelectState.ts';

type Refs = {
  readonly inputRef: RefObject<HTMLTextAreaElement>;
  readonly inputFieldRef: RefObject<HTMLDivElement>;
  readonly triggerRef: RefObject<HTMLButtonElement>;
  readonly menuRef: RefObject<HTMLDivElement>;
  readonly virtualizedListRef?: RefObject<VirtualizedList<HTMLDivElement>>;
};

export type InputProps = {
  readonly ref: Ref<HTMLInputElement>;
  readonly ariaControls?: string;
  readonly id?: string;
  readonly onBlur: FocusEventHandler<HTMLTextAreaElement>;
  readonly onChange: ChangeEventHandler<HTMLTextAreaElement>;
  readonly onClick: MouseEventHandler<HTMLElement>;
  readonly onFocus: FocusEventHandler<HTMLTextAreaElement>;
  readonly onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
  readonly value: string;
};

export type TriggerProps = {
  readonly isDisabled: boolean;
  readonly isOpen: boolean;
  readonly openMenu: () => void;
  readonly closeMenu: () => void;
};

export type ListBoxProps = ComponentPropsWithRef<typeof ListBox>;

export type UseMultiSelectOptions<TItem extends ISelectItem<TItem>> = Refs &
  Pick<UseMultiSelectStateOptions<TItem>, 'inputState'> & {
    readonly isVirtualized: boolean;
  };

export type UseMultiSelectResult = {
  readonly inputProps: InputProps;
  readonly listBoxProps: ListBoxProps;
  readonly triggerProps: TriggerProps;
};

export const useMultiSelect = <TItem extends ISelectItem<TItem>>(
  options: UseMultiSelectOptions<TItem>,
  state: MultiSelectState<TItem>,
): UseMultiSelectResult => {
  const {
    inputFieldRef,
    inputRef,
    inputState,
    isVirtualized,
    triggerRef,
    menuRef,
    virtualizedListRef,
  } = options;

  const {
    closeMenu,
    collection,
    filteredItems,
    isOpen,
    inputValue,
    openMenu,
    resetFilterValue,
    revertState,
    selectionManager,
  } = state;

  const activeDescendantId =
    selectionManager.focusedKey && state.isOpen
      ? getOptionId(state.componentId, selectionManager.focusedKey)
      : undefined;

  const openMenuWithFilterValue = () => openMenu(inputValue);

  const isDisabled = inputState === InputState.Disabled;
  const isReadOnly = inputState === InputState.ReadOnly;

  const delegate = useMemo(
    () => new ListKeyboardDelegate(collection, state.disabledKeys, menuRef),
    [collection, state.disabledKeys, menuRef],
  );

  useEffect(() => {
    virtualizedListRef?.current?.resetAfterIndex(0);
  }, [virtualizedListRef]);

  const previousFilteredItems = usePrevious(filteredItems);
  useEffect(() => {
    if (previousFilteredItems !== filteredItems) {
      virtualizedListRef?.current?.resetAfterIndex(0);
    }
  }, [previousFilteredItems, filteredItems, virtualizedListRef]);

  const focusFirstRelevantItem = useCallback(() => {
    const keys = [...collection.getKeys()];

    const firstRelevantKey = keys.find((key: Key) => {
      const item = collection.getItem(key);

      if (item === null || !isItemNode(item) || [...state.disabledKeys].includes(key)) {
        return false;
      }

      return item.value?.label.toLocaleLowerCase().includes(state.filterValue);
    });

    const focusedKey = firstRelevantKey ?? delegate.getFirstKey();

    selectionManager.setFocusedKey(focusedKey);
  }, [delegate, state.filterValue, collection, state.disabledKeys, selectionManager]);

  useEffect(() => {
    const focusedKey = selectionManager.focusedKey;

    if (state.isOpen && !focusedKey) {
      focusFirstRelevantItem();
    }
  }, [focusFirstRelevantItem, state.isOpen, selectionManager.focusedKey]);

  const scrollToFocusedItem = useCallback(() => {
    // scroll to focused item on focusedKey change caused by keyboard or "virtual" clicks
    if (isVirtualized && virtualizedListRef) {
      scrollVirtualizedItemIntoView(collection, selectionManager.focusedKey, virtualizedListRef);
    } else {
      scrollItemIntoView(selectionManager.focusedKey, menuRef);
    }
  }, [collection, isVirtualized, virtualizedListRef, menuRef, selectionManager.focusedKey]);

  useEffect(() => {
    scrollToFocusedItem();
  }, [scrollToFocusedItem]);

  const previousState = usePrevious(state);
  useEffect(() => {
    if (previousState !== state) {
      scrollToFocusedItem();
    }
  }, [previousState, state, scrollToFocusedItem]);

  const prevFilteredItems = usePrevious(filteredItems);
  useEffect(() => {
    if (state.isOpen && prevFilteredItems !== filteredItems) {
      focusFirstRelevantItem();
    }
  }, [filteredItems, focusFirstRelevantItem, state.isOpen, prevFilteredItems]);

  const handleInputTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    state.setInputValue(value);

    const filterValue = value.toLocaleLowerCase().trim();
    state.setFilterValue(filterValue);

    openMenu(filterValue);
  };

  // For textfield specific keydown operations
  const inputKeyHandlerProps = useHotkeys(
    {
      enter: (e) => {
        if (!state.isOpen || isReadOnly) {
          return;
        }

        // Prevent form submission and stop propagation if menu is open since we may be selecting a option
        e.preventDefault();
        e.stopPropagation();

        if (selectionManager.focusedKey) {
          selectionManager.select(selectionManager.focusedKey);
        }
      },
      backspace: () => {
        if (state.inputValue.length) {
          return;
        }

        // Input/Textarea is empty
        const selectedKeys = [...(selectionManager.selectedKeys ?? [])];
        const lastSelectedKey = selectedKeys[selectedKeys.length - 1];

        if (lastSelectedKey) {
          selectionManager.select(lastSelectedKey);
        }
      },
      escape: (e) => {
        if (state.isOpen) {
          e.stopPropagation();
        }
        state.revertState();
      },
      up: openMenuWithFilterValue,
      down: openMenuWithFilterValue,
      pageup: openMenuWithFilterValue,
      pagedown: openMenuWithFilterValue,
      home: openMenuWithFilterValue,
      end: openMenuWithFilterValue,
    },
    {
      isDisabled,
      ref: inputRef,
    },
  );

  const handleFocus: FocusEventHandler<HTMLTextAreaElement> = (e) => {
    if (!isReadOnly) {
      e.target.select();
    }
  };

  const handleBlur: FocusEventHandler<HTMLTextAreaElement> = (event) => {
    // Do not revert state, if the menu is interacted with.
    // Problems manifest only with reduced motion or clicking links with middle-button.
    if (!menuRef.current || !triggerRef.current || !menuRef.current.contains(event.relatedTarget)) {
      state.revertState();
    }
  };

  useEventListener(
    'mousedown',
    (e) => {
      // Prevent blur when clicking inside the input field, e.g. on selected option tags.
      e.preventDefault();
    },
    inputFieldRef.current,
  );

  const handleClick: MouseEventHandler<HTMLElement> = () => {
    state.openMenu(inputValue);
  };

  // Use useSelectableCollection to get the keyboard handlers to apply to the textfield
  const { collectionProps } = useSelectableCollection({
    autoFocus: false,
    disallowEmptySelection: true,
    disallowSelectAll: true,
    disallowTypeAhead: true,
    isVirtualized,
    keyboardDelegate: delegate,
    ref: inputRef,
    selectionManager,
    shouldUseVirtualFocus: true,
  });

  const isMenuInteractive = isOpen && !isDisabled && !isReadOnly;

  const inputProps: InputProps = mergeProps(
    {
      'aria-activedescendant': state.isOpen ? activeDescendantId : undefined,
      onBlur: handleBlur,
      onChange: handleInputTextChange,
      onClick: handleClick,
      onFocus: handleFocus,
      onKeyDown:
        isMenuInteractive && collectionProps.onKeyDown ? collectionProps.onKeyDown : undefined,
      value: inputValue,
    },
    inputKeyHandlerProps,
  );

  const listBoxRefCallback = useCallback(
    (element: HTMLDivElement) => {
      // Filter value needs to be reset after menu unmount to prevent if from updating during animation.
      if (element === null) {
        resetFilterValue();
      }
    },
    [resetFilterValue],
  );

  const listBoxProps: ListBoxProps = {
    onOverlayClose: revertState,
    ref: listBoxRefCallback,
    role: 'listbox',
    'aria-label': 'Options to select',
  };

  const triggerProps: TriggerProps = {
    isDisabled,
    isOpen,
    openMenu: () => {
      inputRef.current?.focus();
      openMenu(inputValue);
    },
    closeMenu,
  };

  return {
    inputProps,
    listBoxProps,
    triggerProps,
  };
};
