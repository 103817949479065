import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { FullTextSearchStatus } from '../../shared/reducers/IContentInventoryStoreState.type.ts';
import { ContentItemsTextFilter } from '../containers/ContentItemTextFilter.tsx';
import { ContentItemsSearchScope } from '../containers/ContentItemsSearchScope.tsx';

type Props = {
  readonly isInDialog: boolean;
  readonly isFullTextSearchForceDisabled?: boolean;
};

export const ContentItemsSearchArea: React.FC<Props> = ({
  isInDialog,
  isFullTextSearchForceDisabled,
}) => {
  const fullTextSearchStatusState = useSelector(
    (state) => state.contentInventory.fullTextSearchStatus,
  );
  const fullTextSearchStatus = isFullTextSearchForceDisabled
    ? FullTextSearchStatus.Disabled
    : fullTextSearchStatusState;

  return (
    <div className="content-items-search-area">
      <div className="content-items-search-area__search-phrase">
        <ContentItemsTextFilter
          isInDialog={isInDialog}
          fullTextSearchStatus={fullTextSearchStatus}
          allowAiSearch={!isFullTextSearchForceDisabled}
        />
      </div>
      {fullTextSearchStatus !== FullTextSearchStatus.Disabled && (
        <div className="content-items-search-area__search-scope">
          <ContentItemsSearchScope fullTextSearchStatus={fullTextSearchStatus} />
        </div>
      )}
    </div>
  );
};

ContentItemsSearchArea.displayName = 'ContentItemsSearchArea';
