import classNames from 'classnames';
import { useCallback } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply, PluginState, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { getDisallowedFeaturesClasses } from '../../editorCore/utils/editorComponentUtils.ts';
import { isContentEmpty } from '../../utils/general/editorContentUtils.ts';
import { ApiLimitationsPlugin } from '../apiLimitations/ApiLimitationsPlugin.tsx';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { StylesPlugin } from './StylesPlugin.tsx';

export type LimitationStylesPlugin = DraftJsEditorPlugin<
  None,
  None,
  None,
  None,
  [ApiLimitationsPlugin, StylesPlugin]
>;

export const LimitationStylesPlugin: PluginComponent<LimitationStylesPlugin> = (props) => {
  const render: Decorator<Render<LimitationStylesPlugin>> = useCallback(
    (baseRender) => (state) => {
      const content = state.editorState.getCurrentContent();
      const disallowedFeaturesClasses = isContentEmpty(content)
        ? []
        : getDisallowedFeaturesClasses(state.getApi().getLimitations());

      const stateWithLimitationStyles: PluginState<LimitationStylesPlugin> = {
        ...state,
        rteInputProps: {
          ...state.rteInputProps,
          className: classNames(
            state.rteInputProps.className,
            disallowedFeaturesClasses as Mutable<typeof disallowedFeaturesClasses>,
          ),
        },
      };

      return baseRender(stateWithLimitationStyles);
    },
    [],
  );

  const apply: Apply<LimitationStylesPlugin> = useCallback(
    (state) => {
      state.render.decorate(render);
      return {};
    },
    [render],
  );

  return useEditorWithPlugin(props, { apply });
};
