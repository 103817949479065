import { Box } from '@kontent-ai/component-library/Box';
import { IconButton } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { ItemId } from '@kontent-ai/component-library/Selects';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useCallback, useMemo, useState } from 'react';
import { trackUserEventWithData } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { WebSpotlightPreviewResolutionChangeOrigin } from '../../../../_shared/models/TrackUserEventData.ts';
import { useWebSpotlight } from '../../context/WebSpotlightContext.tsx';
import { useContainerBreakpoints } from '../../hooks/useContainerBreakpoints.ts';
import { useIsPreviewUrlOutdated } from '../../hooks/useIsPreviewUrlOutdated.ts';
import {
  WebSpotlightPreviewResolutionType,
  fitToScreenResolution,
  webSpotlightPreviewResolutionTypeOptions,
} from '../../models/webSpotlightPreviewResolutionType.ts';
import {
  getWebSpotlightPreviewScaleOptions,
  scaleIdToValue,
} from '../../models/webSpotlightPreviewScale.ts';
import { WebSpotlightPreviewControlsBreakpoint } from '../../types/WebSpotlightPreviewControlsBreakpoint.ts';
import { WebSpotlightActionMenu } from '../WebSpotlightActionMenu.tsx';
import { WebSpotlightPreviewResolutionInputs } from './Controls/WebSpotlightPreviewResolutionInputs.tsx';
import { WebSpotlightPreviewResolutionTypeSelect } from './Controls/WebSpotlightPreviewResolutionTypeSelect.tsx';
import { WebSpotlightPreviewScaleSelect } from './Controls/WebSpotlightPreviewScaleSelect.tsx';

const webSpotlightPreviewControlsBreakpoints = [
  WebSpotlightPreviewControlsBreakpoint.None,
  WebSpotlightPreviewControlsBreakpoint.MediumPreviewControls,
  WebSpotlightPreviewControlsBreakpoint.LargePreviewControls,
];

export const WebSpotlightPreviewControls = () => {
  const dispatch = useDispatch();
  const isPreviewUrlOutdated = useIsPreviewUrlOutdated();

  const { containerRef, reachedBreakpoints } = useContainerBreakpoints(
    webSpotlightPreviewControlsBreakpoints,
  );

  const {
    isPreviewIFrameInitialized,
    previewIFrameResolution,
    previewIFrameResolutionType,
    refreshPreview,
    rescalePreview,
    resizePreview,
    rotatePreview,
    selectPreviewResolutionType,
    setPreviewIFrameResolutionType,
  } = useWebSpotlight();

  const [formPreviewResolutionType, setFormPreviewResolutionType] =
    useState<WebSpotlightPreviewResolutionType | null>(null);

  const resolutionType = formPreviewResolutionType ?? previewIFrameResolutionType;

  const [selectedScaleId, scaleOptions] = useMemo(
    () => getWebSpotlightPreviewScaleOptions(previewIFrameResolution.scale),
    [previewIFrameResolution.scale],
  );

  const onManualRefreshPreview = useCallback(
    () =>
      refreshPreview({
        isManualRefresh: true,
        isPreviewUrlOutdated,
      }),
    [isPreviewUrlOutdated, refreshPreview],
  );

  const handleResolutionTypeChange = useCallback(
    (type: WebSpotlightPreviewResolutionType) => {
      setFormPreviewResolutionType(type);
      selectPreviewResolutionType(type);
      dispatch(
        trackUserEventWithData(TrackedEvent.WebSpotlightPreviewResolutionChanged, {
          origin: WebSpotlightPreviewResolutionChangeOrigin.ResolutionTypeSelector,
        }),
      );
    },
    [selectPreviewResolutionType],
  );

  const handleScaleChange = useCallback(
    (scaleId: ItemId) => {
      const newScale = scaleId ? scaleIdToValue(scaleId) : fitToScreenResolution.scale;
      rescalePreview(newScale);
    },
    [rescalePreview],
  );

  const handleResolutionSubmit = useCallback(
    (width: number, height: number) => {
      resizePreview(width, height);
      setPreviewIFrameResolutionType(WebSpotlightPreviewResolutionType.Responsive);
      setFormPreviewResolutionType(null);
      dispatch(
        trackUserEventWithData(TrackedEvent.WebSpotlightPreviewResolutionChanged, {
          origin: WebSpotlightPreviewResolutionChangeOrigin.Inputs,
        }),
      );
    },
    [resizePreview, setPreviewIFrameResolutionType],
  );

  const handleFitScreen = useCallback(() => {
    setFormPreviewResolutionType(WebSpotlightPreviewResolutionType.FitScreen);
    selectPreviewResolutionType(WebSpotlightPreviewResolutionType.FitScreen);
    dispatch(
      trackUserEventWithData(TrackedEvent.WebSpotlightPreviewResolutionChanged, {
        origin: WebSpotlightPreviewResolutionChangeOrigin.FitScreenButton,
      }),
    );
  }, [selectPreviewResolutionType]);

  const isResetButtonEnabled = resolutionType === WebSpotlightPreviewResolutionType.Responsive;
  const shouldResolutionSelectBeOnSecondRow = reachedBreakpoints.includes(
    WebSpotlightPreviewControlsBreakpoint.MediumPreviewControls,
  );
  const shouldEverythingBeOnOneRow = reachedBreakpoints.includes(
    WebSpotlightPreviewControlsBreakpoint.LargePreviewControls,
  );

  return (
    <Row ref={containerRef} spacingX={Spacing.XL} spacingY={Spacing.L}>
      {isPreviewIFrameInitialized && (
        <>
          <Column
            flexFactor={1}
            flexBasis={
              shouldResolutionSelectBeOnSecondRow && !shouldEverythingBeOnOneRow ? '100%' : 'auto'
            }
            css={`order: ${
              shouldEverythingBeOnOneRow ? 'initial' : shouldResolutionSelectBeOnSecondRow ? 3 : 2
            };`}
          >
            <Inline spacingX={Spacing.S}>
              <WebSpotlightPreviewResolutionTypeSelect
                onSelect={handleResolutionTypeChange}
                resolutionTypes={webSpotlightPreviewResolutionTypeOptions}
                selectedResolutionType={resolutionType}
              />
              <IconButton
                aria-label="Rotate preview"
                buttonStyle="tertiary"
                iconName="DeviceRotate"
                onClick={rotatePreview}
                size="medium"
                tooltipPlacement="top"
                tooltipText="Rotate preview"
              />
            </Inline>
          </Column>
          <Column
            flexFactor={1}
            flexBasis="auto"
            css={`order: ${shouldEverythingBeOnOneRow ? 'initial' : 1};`}
          >
            <Row
              spacingX={Spacing.S}
              spacingY={Spacing.L}
              css={`justify-content: ${shouldEverythingBeOnOneRow ? 'center' : 'start'};`}
            >
              <Column width="fit-content">
                <WebSpotlightPreviewResolutionInputs
                  onChange={() =>
                    setFormPreviewResolutionType(WebSpotlightPreviewResolutionType.Responsive)
                  }
                  onSubmit={handleResolutionSubmit}
                  previewHeight={previewIFrameResolution.height}
                  previewWidth={previewIFrameResolution.width}
                />
              </Column>
              <Column width="fit-content">
                <Inline spacingX={Spacing.S}>
                  <WebSpotlightPreviewScaleSelect
                    onSelect={handleScaleChange}
                    scales={scaleOptions}
                    selectedScale={selectedScaleId}
                  />
                  <IconButton
                    aria-label="Reset view"
                    buttonState={isResetButtonEnabled ? 'default' : 'disabled'}
                    buttonStyle="tertiary"
                    iconName="ArrowsCorners"
                    onClick={handleFitScreen}
                    size="medium"
                    tooltipPlacement="top"
                    tooltipText={
                      isResetButtonEnabled
                        ? 'Reset resolution and scale to fit screen'
                        : 'Reset view to fit screen. Nothing to reset now, adjust resolution or scale to use this.'
                    }
                  />
                </Inline>
              </Column>
            </Row>
          </Column>
        </>
      )}
      <Column
        flexFactor={1}
        flexBasis="auto"
        css={`order: ${
          shouldEverythingBeOnOneRow ? 'initial' : shouldResolutionSelectBeOnSecondRow ? 2 : 3
        };`}
      >
        <Box display="flex" justifyContent="end">
          <WebSpotlightActionMenu onRefreshPreview={onManualRefreshPreview} />
        </Box>
      </Column>
    </Row>
  );
};
