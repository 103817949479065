import { DraftBlockRenderMap } from 'draft-js';
import React, { ReactNode } from 'react';
import { splitToGroups } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { removeParentBlockType } from '../../../utils/blocks/blockType.ts';
import { getFullBlockType } from '../../../utils/blocks/editorBlockGetters.ts';
import { getBlockFromBlockElement } from '../../draftJs/utils/draftJsEditorUtils.ts';

interface IApplyNestedBlockWrappersProps {
  readonly children?: ReactNode;
  readonly blockRenderMap: DraftBlockRenderMap;
}

export class ApplyNestedBlockWrappers extends React.PureComponent<IApplyNestedBlockWrappersProps> {
  static displayName = 'ApplyNestedBlockWrappers';

  private readonly getWrapperFromElement = (
    element: React.ReactElement,
  ): React.ReactElement | null => {
    const block = getBlockFromBlockElement(element);
    const nestedBlockType = removeParentBlockType(getFullBlockType(block));

    const blockConfig = this.props.blockRenderMap.get(nestedBlockType) ?? null;

    return blockConfig && (blockConfig.wrapper as React.ReactElement | null);
  };

  private readonly isWrapperBoundary = (
    current: React.ReactElement,
    previous: React.ReactElement,
  ): boolean => {
    const currentWrapper = this.getWrapperFromElement(current);
    const previousWrapper = this.getWrapperFromElement(previous);

    return currentWrapper !== previousWrapper;
  };

  render() {
    const children = React.Children.toArray(
      this.props.children,
    ) as ReadonlyArray<React.ReactElement>;
    const groupedChildren = splitToGroups(children, this.isWrapperBoundary);

    return (
      <>
        {groupedChildren.map((group) => {
          const firstChildInGroup = group[0];
          if (!firstChildInGroup) {
            return null;
          }

          const block = getBlockFromBlockElement(firstChildInGroup);
          if (!block) {
            return null;
          }
          const blockKey = block.getKey();

          const wrapper = this.getWrapperFromElement(firstChildInGroup);
          if (!wrapper) {
            return <React.Fragment key={blockKey}>{group}</React.Fragment>;
          }

          const offsetKey = `${blockKey}-0-0`;

          const wrapperElement = React.cloneElement(
            wrapper,
            {
              key: `${blockKey}-wrap`,
              'data-offset-key': offsetKey,
            },
            group,
          );

          return wrapperElement;
        })}
      </>
    );
  }
}
