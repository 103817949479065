import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { shouldUseAiSearch } from '../selectors/shouldUseAiSearch.ts';

export const pickSearchAction =
  <T extends (...args: any[]) => ThunkPromise>(
    standardSearchScopeAction: T,
    aiSearchScopeAction: T,
  ): ((...args: Parameters<T>) => ThunkPromise) =>
  (...args): ThunkPromise =>
  async (dispatch, getState) =>
    await dispatch(
      (shouldUseAiSearch(getState()) ? aiSearchScopeAction : standardSearchScopeAction)(...args),
    );
