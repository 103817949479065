import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useMemo } from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import {
  NewMapiKeyDetailRoute,
  NewPersonalMapiKeyDetailRoute,
  ProjectRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ApiKeyType } from '../../../../_shared/models/ApiKeyType.ts';
import { getCurrentUser } from '../../../../_shared/selectors/getCurrentUser.ts';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { Tag } from '../../../../_shared/uiComponents/Tag/Tag.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { pluralizeWithCount } from '../../../../_shared/utils/stringUtils.ts';
import { TagColor } from '../../../../data/constants/tagColor.ts';
import { getCurrentProjectContainerId } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { isProjectManagerInAnyEnvironment } from '../../root/selectors/isProjectManagerInAnyEnvironment.ts';
import { ApiKeyDataTable } from '../components/ApiKeyDataTable.tsx';
import { ApiKeyEmptyTable } from '../components/ApiKeyEmptyTable.tsx';
import { ApiKeyExpirationStatus } from '../components/ApiKeyExpirationStatus.tsx';
import { ApiKeyList as ApiKeyListComponent } from '../components/ApiKeyList.tsx';
import { PersonalKeyTooltip, YourPersonalKeyTooltip } from '../constants/ApiKeyListingTooltips.ts';
import { getApiKeyTabsWithLink } from '../constants/ApiKeyTabWithLink.ts';
import { IApiKeyListingData } from '../models/ApiKeyListingData.ts';
import { getEnvironmentName } from '../selectors/getEnvironmentName.ts';
import { getEnvironmentsDetails } from '../selectors/getEnvironmentsDetails.ts';
import { getMapiKeyListingData } from '../selectors/getMapiKeyListingData.ts';
import { getVisibleApiKeyTabs } from '../selectors/getVisibleApiKeyTabs.ts';
import { hasCapabilityInAnyEnvironment } from '../selectors/hasCapabilityInAnyEnvironment.ts';

const CreateManagementApiKeyButtonLabel = 'Create Management API Key';
const CreatePersonalManagementApiKeyButtonLabel = 'Create your personal API key';

const getMapiKeyTag = (userId: string, currentUserId: string): JSX.Element => {
  const isYourPersonalKey = userId === currentUserId;

  return (
    <Tooltip
      tooltipText={isYourPersonalKey ? YourPersonalKeyTooltip : PersonalKeyTooltip}
      placement="top"
      maxGridUnitsWidth={50}
    >
      <Tag
        color={isYourPersonalKey ? TagColor.CoolGray : TagColor.Gray}
        customClass="data-table__tag"
      >
        {isYourPersonalKey ? 'Your personal key' : 'Personal key'}
      </Tag>
    </Tooltip>
  );
};

const getStatusMessage = (count: number): JSX.Element => (
  <ListingMessage
    statusInfoMessage={{
      text: pluralizeWithCount('Management API key', count),
    }}
  />
);

export const ManagementKeysListing: React.FC = () => {
  const apiKeyListingData = useSelector(getMapiKeyListingData);
  const currentUser = useSelector(getCurrentUser);
  const isProjectManager = useSelector(isProjectManagerInAnyEnvironment);
  const visibleApiKeyTabs = useSelector(getVisibleApiKeyTabs);
  const environmentDetails = useSelector(getEnvironmentsDetails);
  const projectContainerId = useSelector(getCurrentProjectContainerId);
  const hasManagePersonalApiKeyCapability = useSelector((state) =>
    hasCapabilityInAnyEnvironment(state, Capability.ManagePersonalApiKey),
  );

  const tabsWithLink = getApiKeyTabsWithLink(visibleApiKeyTabs);
  const hasOwnPersonalKey = useMemo(
    () =>
      apiKeyListingData
        .filter((apiKey: IApiKeyListingData) => apiKey.type === ApiKeyType.MAPIPat)
        .some((apiKey: IApiKeyListingData) => apiKey.userId === currentUser.userId),
    [apiKeyListingData, currentUser.userId],
  );
  const canCreateNewPersonalKey = hasManagePersonalApiKeyCapability && !hasOwnPersonalKey;

  const showSecondaryCreateButton = isProjectManager && canCreateNewPersonalKey;
  const newPersonalMapiKeyPath = canCreateNewPersonalKey
    ? buildPath<ProjectRouteParams>(NewPersonalMapiKeyDetailRoute, { projectContainerId })
    : '';
  const newMapiKeyPath = isProjectManager
    ? buildPath<ProjectRouteParams>(NewMapiKeyDetailRoute, { projectContainerId })
    : '';
  const primaryCreateButtonText = isProjectManager
    ? CreateManagementApiKeyButtonLabel
    : CreatePersonalManagementApiKeyButtonLabel;
  const primaryCreateButtonLink = isProjectManager ? newMapiKeyPath : newPersonalMapiKeyPath;
  const secondaryAction = showSecondaryCreateButton ? (
    <RouterLinkButton
      key="secondary"
      buttonStyle="secondary"
      to={newPersonalMapiKeyPath}
      {...getDataUiActionAttribute(DataUiAction.CreateNew)}
    >
      {CreatePersonalManagementApiKeyButtonLabel}
    </RouterLinkButton>
  ) : undefined;

  return (
    <ApiKeyListComponent
      renderTabsContent={() =>
        apiKeyListingData.length !== 0 ? (
          <ApiKeyDataTable
            apiKeyListingData={apiKeyListingData}
            createNewItemLabel={primaryCreateButtonText}
            createNewItemLinkPath={primaryCreateButtonLink}
            getEnvironmentName={(environmentId) =>
              getEnvironmentName(environmentId, environmentDetails)
            }
            renderExpiration={(expiresAt) => <ApiKeyExpirationStatus expiresAt={expiresAt} />}
            renderTag={(userId) => getMapiKeyTag(userId, currentUser.userId)}
            secondaryAction={secondaryAction}
            statusMessage={getStatusMessage(apiKeyListingData.length)}
          />
        ) : (
          <ApiKeyEmptyTable
            infoMessage={
              <EmptyState>
                <EmptyState.Title>There aren’t any Management API keys yet</EmptyState.Title>
                <EmptyState.Content>
                  Create a Management API key to manage your environments via API.
                </EmptyState.Content>
                <EmptyState.Footer>
                  <Stack spacing={Spacing.L}>
                    {showSecondaryCreateButton && (
                      <RouterLinkButton buttonStyle="secondary" to={newPersonalMapiKeyPath}>
                        {CreatePersonalManagementApiKeyButtonLabel}
                      </RouterLinkButton>
                    )}
                    <RouterLinkButton buttonStyle="primary" to={primaryCreateButtonLink}>
                      {primaryCreateButtonText}
                    </RouterLinkButton>
                  </Stack>
                </EmptyState.Footer>
              </EmptyState>
            }
          />
        )
      }
      tabs={tabsWithLink}
    />
  );
};

ManagementKeysListing.displayName = 'ManagementKeysListing';
