import { memoize } from '@kontent-ai/memoization';
import { assert, alphabetically } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import {
  ContentItemId,
  MemoizedContentItemId,
} from '../../../../../_shared/models/ContentItemId.ts';
import { getListingContentItem } from '../../../../../_shared/utils/getListingContentItem.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IListingVariantData } from '../../../../../data/models/listingContentItems/IListingVariantData.ts';
import { WorkflowStepAction } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { groupContentItemIdsByItemIdWithRelevantVariantFirst } from './groupContentItemIds.ts';

const isInPublishStep = (variant: IListingVariantData): boolean =>
  variant.assignment.workflowStatus.action === WorkflowStepAction.Publish;

const sortDefaultVariantForParentAsFirst = (
  a: ContentItemId,
  b: ContentItemId,
  parentItemId: Uuid,
): number | null => {
  // default variant of a parent item is always on top
  if (a.variantId === DefaultVariantId && a.itemId === parentItemId) {
    return -1;
  }

  // default variant of a parent item is always on top
  if (b.variantId === DefaultVariantId && b.itemId === parentItemId) {
    return 1;
  }

  return null;
};

const sortByTranslationStateAndWorkflowStatus = (
  childA: IListingContentItem,
  childB: IListingContentItem,
): number | null => {
  if (childA.variant === null && childB.variant === null) {
    return null;
  }

  if (childA.variant === null) {
    return 1;
  }

  if (childB.variant === null) {
    return -1;
  }

  if (isInPublishStep(childA.variant) && isInPublishStep(childB.variant)) {
    return null;
  }

  if (isInPublishStep(childA.variant)) {
    return 1;
  }

  if (isInPublishStep(childB.variant)) {
    return -1;
  }

  return null;
};

export const sortChildContentItemItemIds = memoize.weak(
  (
    childIds: ReadonlySet<MemoizedContentItemId>,
    byId: Immutable.Map<Uuid, IListingContentItem>,
    defaultById: Immutable.Map<Uuid, IListingContentItem>,
    selectedLanguageId: Uuid,
    parentItemId?: Uuid,
  ): ReadonlyArray<MemoizedContentItemId> => {
    const groupedContentItemIdsWithRelevantVariantFirst =
      groupContentItemIdsByItemIdWithRelevantVariantFirst(Array.from(childIds));

    return Array.from(groupedContentItemIdsWithRelevantVariantFirst.values())
      .map((ids) => {
        const firstId = ids[0];
        assert(firstId, () => `${__filename}: IDs are empty`);

        return {
          ids,
          firstId,
          firstItem: getListingContentItem(firstId, selectedLanguageId, byId, defaultById),
        };
      })
      .sort((a, b) => {
        const defaultVariantForParentAsFirstSortResult =
          parentItemId && selectedLanguageId !== DefaultVariantId
            ? sortDefaultVariantForParentAsFirst(a.firstId, b.firstId, parentItemId)
            : null;

        if (defaultVariantForParentAsFirstSortResult !== null) {
          return defaultVariantForParentAsFirstSortResult;
        }

        const publishingAndTranslationStateSortingResult =
          a.firstItem && b.firstItem
            ? sortByTranslationStateAndWorkflowStatus(a.firstItem, b.firstItem)
            : null;

        if (publishingAndTranslationStateSortingResult !== null) {
          return publishingAndTranslationStateSortingResult;
        }

        return alphabetically(a.firstItem?.item.name || '', b.firstItem?.item.name || '');
      })
      .flatMap((item) => item.ids);
  },
);
