import { memo } from 'react';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isGuidelinesTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { useIsElementEditableInEditedVariant } from '../hooks/useIsElementEditableInEditedVariant.ts';
import { EditableContentItemElement } from './EditableContentItemElement.tsx';
import { NonEditableContentItemElement } from './NonEditableContentItemElement.tsx';
import { Guidelines } from './elements/Guidelines.tsx';

type Props = {
  readonly canEdit: boolean;
  readonly typeElement: TypeElement;
};

export const ContentItemElement = memo(({ canEdit, typeElement }: Props) => {
  const isEditableInVariant = useIsElementEditableInEditedVariant(typeElement);

  if (isGuidelinesTypeElement(typeElement)) {
    return <Guidelines typeElement={typeElement} disabled={!canEdit} />;
  }

  if (!isEditableInVariant) {
    return <NonEditableContentItemElement typeElement={typeElement} />;
  }

  return <EditableContentItemElement typeElement={typeElement} canEdit={canEdit} />;
});
