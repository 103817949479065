import {
  CompiledContentTypeContentGroupServerModel,
  ContentTypeContentGroupServerModel,
} from '../../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';

export interface IContentGroup {
  readonly id: Uuid;
  readonly name: string;
  readonly codename: string | null;
}

export interface IContentGroupWithComments extends IContentGroup {
  readonly onCommentsClick: () => void;
  readonly commentCount: number;
}

export const getContentGroupFromServerModel = (
  serverModel: CompiledContentTypeContentGroupServerModel,
): IContentGroup => ({
  id: serverModel.id ?? '',
  name: serverModel.name ?? '',
  codename: serverModel.codeName,
});

export const getContentGroupServerModel = (
  contentGroup: IContentGroup,
): ContentTypeContentGroupServerModel => ({
  id: contentGroup.id,
  name: contentGroup.name,
  codeName: contentGroup.codename,
});
