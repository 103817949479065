import { InvariantException } from '@kontent-ai/errors';
import { History } from 'history';
import { forwardRef } from 'react';
import { useHistory } from 'react-router';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { modalDismissed } from '../../../../../_shared/actions/sharedActions.ts';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { createNewContentItemFromListing } from '../../../../contentInventory/content/features/ContentItemInventory/actions/thunks/createNewContentItemFromListing.ts';
import { createNewContentItemFromContentStatus } from '../../../../missionControl/contentStatus/actions/thunks/createNewContentItemFromContentStatus.ts';
import { createNewContentItemFromProjectOverview } from '../../../../missionControl/projectOverview/actions/thunkActions/createNewContentItemFromProjectOverview.ts';
import { createNewContentItemFromWebSpotlight } from '../../../../webSpotlight/actions/thunkWebSpotlightActions.ts';
import { prepareNewContentItemVariantInitFromNewContentItemForm } from '../actions/thunkNewContentItemActions.ts';
import { NewContentItemOrigin } from '../constants/newContentItemOrigin.ts';
import { getNewContentItemDialogProperties } from '../selectors/newContentItemDialog.ts';
import { NewContentItemDialog } from './NewContentItemDialog.tsx';

// Exists as a temporary wrapper because we are currently not able to fill onClose and onSubmit from outside to dialog any other way.
// This container will be removed when https://kontent-ai.atlassian.net/browse/KCL-8307 is done.
const NewContentItemDialogWrapperContainer = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  ({ isOpen }, ref) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const closeForm = () => dispatch(modalDismissed());

    const submitForm = () => {
      // The submit action is asynchronous but should not be awaited because the dialog must be closed as soon as possible but only after its data were read from the Redux store so they can be used in the callback. Should be taken into consideration and made more clear in https://kontent-ai.atlassian.net/browse/KCL-8307
      dispatch(createNewContentItemFromContentType(history));
      closeForm();
    };

    return (
      <NewContentItemDialog isOpen={isOpen} onClose={closeForm} onSubmit={submitForm} ref={ref} />
    );
  },
);

export { NewContentItemDialogWrapperContainer as NewContentItemDialog };

NewContentItemDialogWrapperContainer.displayName = 'NewContentItemDialogWrapperContainer';

// Should be removed in refactoring task https://kontent-ai.atlassian.net/browse/KCL-8307 with all origins.
const createNewContentItemFromContentType =
  (history: History): ThunkPromise =>
  async (dispatch, getState) => {
    const { origin } = getNewContentItemDialogProperties(getState());

    dispatch(prepareNewContentItemVariantInitFromNewContentItemForm());

    switch (origin) {
      case NewContentItemOrigin.Listing: {
        await dispatch(createNewContentItemFromListing(history));
        break;
      }

      case NewContentItemOrigin.ContentStatus: {
        await dispatch(createNewContentItemFromContentStatus(history));
        break;
      }

      case NewContentItemOrigin.ProjectOverview: {
        await dispatch(createNewContentItemFromProjectOverview(history));
        break;
      }

      case NewContentItemOrigin.WebSpotlight: {
        await dispatch(createNewContentItemFromWebSpotlight(history));
        break;
      }

      case NewContentItemOrigin.Other: // We do not care about other origins, see comment in enum itself.
        break;

      default:
        throw InvariantException(`Origin ${origin} of new content item dialog is not known.`);
    }
  };
