import {
  SmartLinkCommand,
  SmartLinkCommandType,
} from '../../../_shared/models/SmartLinkCommand.ts';
import {
  AddButtonAction,
  SmartLinkSdkClientMessage,
  SmartLinkSdkClientMessageType,
} from '../types/SmartLinkSdkApi.ts';

export function smartLinkSdkMessageToSmartLinkCommand(
  message: SmartLinkSdkClientMessage,
): SmartLinkCommand {
  switch (message.type) {
    case SmartLinkSdkClientMessageType.AddButtonAction:
      return {
        type: addButtonActionToSmartLinkCommandType(message.data.action),
        data: {
          projectId: message.data.projectId,
          languageCodename: message.data.languageCodename,
          itemId: message.data.itemId,
          contentComponentId: message.data.contentComponentId,
          elementCodename: message.data.elementCodename,
          insertPosition: message.data.insertPosition,
        },
      };

    default:
      throw new Error(`This Smart Link SDK message is not supported: ${JSON.stringify(message)}`);
  }
}

function addButtonActionToSmartLinkCommandType(action: AddButtonAction): SmartLinkCommandType {
  switch (action) {
    case AddButtonAction.CreateComponent:
      return SmartLinkCommandType.CreateComponent;

    case AddButtonAction.CreateLinkedItem:
      return SmartLinkCommandType.CreateLinkedItem;

    case AddButtonAction.InsertLinkedItem:
      return SmartLinkCommandType.InsertLinkedItem;
  }
}
