import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { RenderSelectMenuOptionProps } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { WebSpotlightResolutionInputMaxWidth } from '../../../constants/uiConstants.ts';
import { IWebSpotlightPreviewScaleOption } from '../../../models/webSpotlightPreviewScale.ts';

const renderMenuOption = (
  optionProps: RenderSelectMenuOptionProps<IWebSpotlightPreviewScaleOption>,
): React.ReactNode => (optionProps.item.value?.hidden ? '' : undefined);

interface IWebSpotlightPreviewScaleSelectProps {
  readonly onSelect: (value: string) => void;
  readonly scales: ReadonlyArray<IWebSpotlightPreviewScaleOption>;
  readonly selectedScale: string;
}

export const WebSpotlightPreviewScaleSelect: React.FC<IWebSpotlightPreviewScaleSelectProps> = ({
  onSelect,
  scales,
  selectedScale,
}) => {
  return (
    <Inline align="center" spacingX={Spacing.S}>
      <Box color={colorTextDefault} typography={Typography.LabelLarge}>
        Scale:
      </Box>
      <Box maxWidth={WebSpotlightResolutionInputMaxWidth}>
        <SingleSelect
          aria-label="Scale"
          items={scales}
          onSelectionChange={onSelect}
          renderMenuOption={renderMenuOption}
          selectedItemId={selectedScale}
        />
      </Box>
    </Inline>
  );
};

WebSpotlightPreviewScaleSelect.displayName = 'WebSpotlightPreviewScaleSelect';
