import { notNullNorUndefined } from '@kontent-ai/utils';
import { ContentBlock, DraftBlockRenderConfig, EditorState } from 'draft-js';
import Immutable from 'immutable';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlAltShortcutTemplate } from '../../../../_shared/constants/shortcutSymbols.ts';
import { useEventListener } from '../../../../_shared/hooks/useEventListener.ts';
import { DataUiRteAction } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import { Apply, PluginState, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import {
  BaseBlockType,
  BlockType,
  BlockTypesAllowedInTableCell,
  getNestedBlockType,
} from '../../utils/blocks/blockType.ts';
import { TopLevelBlockCategoryFeature } from '../apiLimitations/api/editorLimitationUtils.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { BaseBlockRenderMap, BlockRenderMap } from '../draftJs/utils/draftJsEditorUtils.ts';
import { DragDropPlugin } from '../dragDrop/DragDropPlugin.tsx';
import { DroppableTableWrapper } from '../dragDrop/components/DroppableTableWrapper.tsx';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import {
  BlockToolbarPlugin,
  CanDisplayBlockToolbar,
  GetInsertBlockMenuItems,
} from '../toolbars/BlockToolbarPlugin.tsx';
import { CanDisplayInlineToolbar, InlineToolbarPlugin } from '../toolbars/InlineToolbarPlugin.tsx';
import { CommandToolbarMenuItem } from '../toolbars/components/menu/EditorCommandMenu.tsx';
import { EditorTableApi, TableActionType } from './api/EditorTableApi.type.ts';
import { editorTableApi } from './api/editorTableApi.ts';
import { getClosestCollapsedSelectionInTableCell } from './api/editorTableUtils.ts';
import {
  TableContextMenu,
  deleteOnlyTableActions,
  tableActions,
} from './components/TableContextMenu.tsx';

export type TablesPlugin = DraftJsEditorPlugin<
  None,
  None,
  EditorTableApi,
  None,
  [
    KeyboardShortcutsPlugin<RichTextInputCommand>,
    BlockToolbarPlugin,
    InlineToolbarPlugin,
    DragDropPlugin,
  ]
>;

interface ITableContextMenuState {
  readonly cellRef: React.RefObject<HTMLDivElement> | null;
  readonly cellBlock: ContentBlock | null;
  readonly visible: boolean;
}

const InitialTableContextMenuState: ITableContextMenuState = {
  cellRef: null,
  cellBlock: null,
  visible: false,
};

type EditorWithTablesProps = {
  readonly disabled?: boolean;
  readonly editorWrapperRef: React.RefObject<HTMLDivElement>;
  readonly onContextMenuVisibilityChange: (visible: boolean) => void;
};

const EditorWithTables: DecoratedEditor<TablesPlugin, EditorWithTablesProps> = ({
  baseRender,
  disabled,
  editorWrapperRef,
  onContextMenuVisibilityChange,
  state,
}) => {
  const {
    canUpdateContent,
    editorProps: { blockRenderMap: baseBlockRenderMap },
    executeChange,
    getApi,
    hoveringCollisionStrategy,
    getEditorId,
    onMoveBlocks,
  } = state;

  const [tableContextMenuState, setTableContextMenuState] = useState(InitialTableContextMenuState);

  const hideTableContextMenu = useCallback(() => {
    setTableContextMenuState(InitialTableContextMenuState);
    onContextMenuVisibilityChange(false);
  }, [onContextMenuVisibilityChange]);

  useEventListener('contextmenu', hideTableContextMenu, document, true);

  const showTableContextMenu = useCallback(
    async (blockKey: string, cellRef: React.RefObject<HTMLDivElement>): Promise<void> => {
      if (!canUpdateContent()) {
        return;
      }

      let newTableContextMenuState = InitialTableContextMenuState;

      await executeChange((editorState) => {
        const content = editorState.getCurrentContent();
        const cellBlock = content.getBlockForKey(blockKey);
        if (cellBlock) {
          // Move selection inside the cell to make clear what the context menu relates to and prevent selection visuals interfering with the context menu
          const selection = editorState.getSelection();
          const newSelection = getClosestCollapsedSelectionInTableCell(
            content,
            selection,
            blockKey,
          );
          const newEditorState =
            newSelection !== selection
              ? EditorState.forceSelection(editorState, newSelection)
              : editorState;

          newTableContextMenuState = {
            cellRef,
            cellBlock,
            visible: true,
          };

          return newEditorState;
        }
        return editorState;
      });
      setTableContextMenuState(newTableContextMenuState);
      onContextMenuVisibilityChange(newTableContextMenuState.visible);
    },
    [canUpdateContent, executeChange, onContextMenuVisibilityChange],
  );

  const handleTableCommand = useCallback(
    (command: TableActionType, starterCell: ContentBlock): void => {
      executeChange((editorState) =>
        getApi().executeTableAction(editorState, command, starterCell),
      );
    },
    [executeChange, getApi],
  );

  const isTableAllowed = state
    .getApi()
    .getLimitations()
    .allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);

  const blockRenderMapRef = useRef<BlockRenderMap>(baseBlockRenderMap);

  const TableWrapper = useMemo(
    () => (
      <DroppableTableWrapper
        canUpdate={!disabled}
        hoveringCollisionStrategy={hoveringCollisionStrategy}
        getRenderMap={() => blockRenderMapRef.current}
        onContextMenu={disabled ? undefined : showTableContextMenu}
        onMove={onMoveBlocks}
        parentId={getEditorId()}
      />
    ),
    [getEditorId, disabled, hoveringCollisionStrategy, showTableContextMenu, onMoveBlocks],
  );

  blockRenderMapRef.current = useMemo(() => {
    const tableContentRenderMap = Immutable.Map<BlockType, DraftBlockRenderConfig>(
      BlockTypesAllowedInTableCell.map((baseBlockType) => {
        const blockType = getNestedBlockType([BlockType.TableCell], baseBlockType);
        const value = baseBlockRenderMap.get(baseBlockType);

        return (
          value && [
            blockType,
            {
              ...value,
              wrapper: TableWrapper,
            },
          ]
        );
      }).filter(notNullNorUndefined),
    );

    const tableCellRenderMap: BaseBlockRenderMap = Immutable.Map<
      BaseBlockType,
      DraftBlockRenderConfig
    >({
      [BaseBlockType.TableCell]: {
        element: 'div',
        wrapper: TableWrapper,
      },
    });

    return baseBlockRenderMap.merge(tableCellRenderMap).merge(tableContentRenderMap);
  }, [baseBlockRenderMap, TableWrapper]);

  const blockRenderMap = blockRenderMapRef.current;

  const stateWithTables: PluginState<TablesPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      blockRenderMap,
    },
  };

  return (
    <>
      {baseRender(stateWithTables)}
      <TableContextMenu
        actions={isTableAllowed ? tableActions : deleteOnlyTableActions}
        cellBlock={tableContextMenuState.cellBlock}
        cellRef={tableContextMenuState.cellRef}
        editorRef={editorWrapperRef}
        onAction={handleTableCommand}
        onHide={hideTableContextMenu}
        visible={tableContextMenuState.visible}
      />
    </>
  );
};

EditorWithTables.displayName = 'EditorWithTables';

const insertTableMenuItem: CommandToolbarMenuItem = {
  label: 'Insert table',
  command: RichTextInputCommand.InsertTable,
  shortcuts: ControlAltShortcutTemplate('T'),
  iconName: IconName.Table,
  uiAction: DataUiRteAction.AddTable,
};

const getAddBlockMenuItems: Decorator<GetInsertBlockMenuItems> =
  (baseGetAddBlockMenuItems) => () => [...baseGetAddBlockMenuItems(), insertTableMenuItem];

export const TablesPlugin: PluginComponent<TablesPlugin> = (props) => {
  const { disabled } = props;

  const render: Decorator<Render<TablesPlugin>> = useCallback(
    (baseRender) => (state) => (
      <EditorWithTables
        baseRender={baseRender}
        disabled={disabled}
        editorWrapperRef={state.getWrapperRef()}
        onContextMenuVisibilityChange={setIsTableContextMenuVisible}
        state={state}
      />
    ),
    [disabled],
  );

  const [isTableContextMenuVisible, setIsTableContextMenuVisible] = useState(false);

  const canDisplayBlockToolbar: Decorator<CanDisplayBlockToolbar> = useCallback(
    (baseCanDisplayBlockToolbar) => (editorState) =>
      !isTableContextMenuVisible && baseCanDisplayBlockToolbar(editorState),
    [isTableContextMenuVisible],
  );

  const canDisplayInlineToolbar: Decorator<CanDisplayInlineToolbar> = useCallback(
    (baseCanDisplayInlineToolbar) => (editorState) =>
      !isTableContextMenuVisible && baseCanDisplayInlineToolbar(editorState),
    [isTableContextMenuVisible],
  );

  const apply: Apply<TablesPlugin> = useCallback(
    (state) => {
      state.render.decorate(render);
      state.getInsertBlockMenuItems.decorate(getAddBlockMenuItems);
      state.canDisplayBlockToolbar.decorate(canDisplayBlockToolbar);
      state.canDisplayInlineToolbar.decorate(canDisplayInlineToolbar);

      const executeCommand: Decorator<ExecuteCommand<RichTextInputCommand>> =
        (baseExecuteCommand) => (command, isShiftPressed) => {
          switch (command) {
            case RichTextInputCommand.InsertTable: {
              state.executeChange((editorState) => {
                const selection = editorState.getSelection();
                return state.getApi().insertTable(editorState, selection, 3, 3);
              });
              return true;
            }

            default:
              return baseExecuteCommand(command, isShiftPressed);
          }
        };

      state.executeCommand.decorate(executeCommand);

      return {};
    },
    [canDisplayBlockToolbar, canDisplayInlineToolbar, render],
  );

  const { getApiMethods } = useEditorApi<TablesPlugin>(editorTableApi);

  return useEditorWithPlugin(props, { apply, getApiMethods });
};
