import { Tooltip, defaultTooltipTippyOptions } from '@kontent-ai/component-library/Tooltip';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { usePrevious } from '@kontent-ai/hooks';
import { DraftEditorLeafs } from 'draft-js';
import React, { forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  createAddFlipping,
  createAddOffset,
  createAddPreventOverflow,
} from '../../../../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import { usePreventOverflowFromScrollContainer } from '../../../../../../component-library/components/ScrollContainer/usePreventOverflowFromScrollContainer.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { updateLeafNodes } from '../../entityApi/components/AtomicEntity.tsx';
import { InlineAiInstructionStarted } from '../constants/uiConstants.ts';
import { ActiveInstructionContext } from './ActiveInstructionContext.tsx';

const instructionTextSpacing = Spacing.XS;

export const AiIcon = styled.span`
  position: relative;
  font-size: 17.5px;
  line-height: 1.37;
  font-weight: normal;
  margin-left: 2px;

  .rte__heading & {
    vertical-align: middle;
  }

  & > span {
    font-family: kentico-icons, monospace;
    color: transparent;
    background-image: url('/images/sparkles.svg');
    background-repeat: no-repeat;
    background-position: left center;
    vertical-align: middle;
  }
`;

// We need to use the square icon char in the presentation instead of the original char to ensure proper size of the Sparkles icon, see styles above
const squareIconChar = '\ue619';

type NewInstructionStartContentProps = {
  readonly children: DraftEditorLeafs;
  readonly className?: string;
};

type NewInstructionStartProps = NewInstructionStartContentProps & {
  readonly disabled: boolean;
  readonly entityKey: string;
};

export const NewInstructionStartContent = forwardRef<HTMLElement, NewInstructionStartContentProps>(
  ({ className, children }, ref) => {
    // We need to memoize the updated nodes, as the component re-renders when active instruction changes,
    // and it could cause DOM selection update via leaf node update
    const childrenWithIcon = useMemo(() => {
      return updateLeafNodes(children, () => ({ text: squareIconChar }));
    }, [children]);

    return (
      <AiIcon aria-label="Ai Icon" className={className} ref={ref}>
        {childrenWithIcon}
      </AiIcon>
    );
  },
);

export const NewInstructionStart: React.FC<NewInstructionStartProps> = ({
  className,
  disabled,
  entityKey,
  children,
}) => {
  const { activeInstructionEntityKey } = useContext(ActiveInstructionContext);

  // We need to delay displaying the tooltip after the component is mounted to make sure the correct scroll parent is available
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const showTooltip = mounted && !disabled && activeInstructionEntityKey === entityKey;

  const previousMounted = usePrevious(mounted);

  const { preventOverflowModifier, boundaryProps } = usePreventOverflowFromScrollContainer();

  const tooltipTippyOptions = compose(
    // Align tooltip arrow to the center of the Sparkles icon
    createAddOffset([
      defaultTooltipTippyOptions.offset[0] - instructionTextSpacing / 2,
      defaultTooltipTippyOptions.offset[1],
    ]),
    // We need to configure flipping for a whole viewport so that the tooltip can overflow from the editor horizontally
    // e.g. in case it is displayed in in-context editor
    createAddFlipping(boundaryProps),
    createAddPreventOverflow(preventOverflowModifier.options),
  )({
    ...defaultTooltipTippyOptions,
    // Make sure this tooltip doesn't overflow other absolutely positioned elements such as dropdowns / popovers
    // as it is not a hover tooltip but rather long-term tooltip displayed when selection is in a specific place
    zIndex: 1,
  });

  return (
    <Tooltip
      // Show the tooltip immediately without any delay when new instruction is mounted
      // otherwise use default delay so that transition from one tooltip to another doesn't show two tooltips at once
      delay={previousMounted ? undefined : [0, 0]}
      placement="top-start"
      tippyOptions={tooltipTippyOptions}
      tooltipText={InlineAiInstructionStarted}
      visible={showTooltip}
    >
      <NewInstructionStartContent className={className}>{children}</NewInstructionStartContent>
    </Tooltip>
  );
};

NewInstructionStart.displayName = 'NewInstructionStart';

const NewInstructionContentText = styled.span<{
  readonly isActive: boolean;
}>`
  font-size: 17.5px;
  line-height: 1.37;
  font-weight: normal;

  .rte__heading & {
    vertical-align: middle;
  }
`;

type NewInstructionContentProps = {
  readonly className?: string;
  readonly children: DraftEditorLeafs;
  readonly entityKey: string;
};

export const NewInstructionContent: React.FC<NewInstructionContentProps> = ({
  className,
  children,
  entityKey,
}) => {
  const { activeInstructionEntityKey } = useContext(ActiveInstructionContext);

  return (
    <NewInstructionContentText
      className={className}
      isActive={entityKey === activeInstructionEntityKey}
    >
      {children}
    </NewInstructionContentText>
  );
};

NewInstructionContent.displayName = 'NewInstructionContent';
