import { Action } from '../../../../../../../@types/Action.type.ts';
import {
  ModalContentItemSelector_Closed,
  ModalMultipleContentItemsSelector_Closed,
} from '../../../../../../features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import { Calendar_Page_Left } from '../../../../../../missionControl/calendar/constants/calendarActionTypes.ts';
import { ProjectOverview_Page_Left } from '../../../../../../missionControl/projectOverview/constants/projectOverviewActionTypes.ts';
import {
  Content_ContentItemListingFilter_Clear,
  Content_Filter_Clear,
} from '../../../../constants/contentActionTypes.ts';
import { ContentListing_Page_Left } from '../../../../features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import {
  ListingFilter_CollectionFilterChanged,
  ListingFilter_ContentItemStatusChanged,
  ListingFilter_ContentTypeFilterChanged,
  ListingFilter_ContributorsFilterChanged,
  ListingFilter_DeleteSavedFilter_Failed,
  ListingFilter_DeleteSavedFilter_Finished,
  ListingFilter_DeleteSavedFilter_Started,
  ListingFilter_HideRestoreButton,
  ListingFilter_PublishingStatusFilterChanged,
  ListingFilter_RestoreSavedFilter_Finished,
  ListingFilter_SaveFilter_Finished,
  ListingFilter_SitemapFilterChanged,
  ListingFilter_TaxonomyGroupsFilterChanged,
  ListingFilter_UpdateSavedFilter_Finished,
  ListingFilter_WorkflowStatusesFilterChanged,
} from '../../../../features/ListingFilter/constants/listingFilterActionTypes.ts';

const initialState: Uuid | null = null;

export function lastDeletedFilterId(state: Uuid | null = initialState, action: Action) {
  switch (action.type) {
    case ListingFilter_DeleteSavedFilter_Finished:
      return action.payload.filter.id;

    case Calendar_Page_Left:
    case Content_ContentItemListingFilter_Clear:
    case Content_Filter_Clear:
    case ContentListing_Page_Left:
    case ListingFilter_CollectionFilterChanged:
    case ListingFilter_ContentTypeFilterChanged:
    case ListingFilter_ContributorsFilterChanged:
    case ListingFilter_DeleteSavedFilter_Failed:
    case ListingFilter_DeleteSavedFilter_Started:
    case ListingFilter_HideRestoreButton:
    case ListingFilter_PublishingStatusFilterChanged:
    case ListingFilter_ContentItemStatusChanged:
    case ListingFilter_RestoreSavedFilter_Finished:
    case ListingFilter_SaveFilter_Finished:
    case ListingFilter_SitemapFilterChanged:
    case ListingFilter_TaxonomyGroupsFilterChanged:
    case ListingFilter_UpdateSavedFilter_Finished:
    case ListingFilter_WorkflowStatusesFilterChanged:
    case ModalContentItemSelector_Closed:
    case ModalMultipleContentItemsSelector_Closed:
    case ProjectOverview_Page_Left:
      return initialState;

    default:
      return state;
  }
}
