import { RouterLink } from '@kontent-ai/component-library/Anchor';
import { StatusBarIconButton } from '@kontent-ai/component-library/StatusBar';
import { BorderRadius, px } from '@kontent-ai/component-library/tokens';
import { mergeProps } from '@react-aria/utils';
import { LocationDescriptor } from 'history';
import React, { ComponentProps } from 'react';
import styled from 'styled-components';

export interface IBackButtonProps {
  readonly disabled?: boolean;
  readonly to: LocationDescriptor;
}

const goBackText = 'Go back';

const StyledRouterLink = styled(RouterLink)`
  &:focus-visible {
    border-radius: ${px(BorderRadius.Pill)};
  }
`;

export const BackButton: React.FC<React.PropsWithChildren<IBackButtonProps>> = ({
  to,
  disabled,
  ...otherProps
}) => {
  const linkProps: Omit<ComponentProps<typeof RouterLink>, 'ref'> = {
    to,
  };

  return (
    <StatusBarIconButton
      component={disabled ? 'button' : StyledRouterLink}
      disabled={disabled}
      iconName="ChevronLeft"
      label={goBackText}
      {...mergeProps(disabled ? {} : linkProps, otherProps)}
    />
  );
};

BackButton.displayName = 'BackButton';
