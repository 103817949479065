import { InvariantException } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { capitalizeFirstLetter } from '../../../../_shared/utils/stringUtils.ts';
import { CapabilityScope } from '../models/CapabilityScope.ts';

export const getCapabilityName = (capability: Capability): string => {
  switch (capability) {
    case Capability.ViewContent:
    case Capability.ViewAssets:
      return 'view';
    case Capability.UpdateContent:
    case Capability.UpdateAssets:
      return 'edit';
    case Capability.CreateContent:
    case Capability.CreateAssets:
      return 'create';
    case Capability.DeleteContent:
    case Capability.DeleteAssets:
      return 'delete';
    default:
      throw InvariantException(
        `Unknown option ${capability}. In CapabilityRulePicker getOpinionName.`,
      );
  }
};

export const getScopeName = (scope: CapabilityScope) => {
  switch (scope) {
    case CapabilityScope.AllContent:
      return 'All content items';
    case CapabilityScope.AssignedContent:
      return 'Assigned content items';
    case CapabilityScope.AllAssets:
      throw InvariantException(
        `RoleSettingsCapabilityScope ${CapabilityScope.AllAssets} should not be displayed in scopes dropdown.`,
      );
  }
};

export const contentScopeOptions: ReadonlyArray<CapabilityScope> = [
  CapabilityScope.AllContent,
  CapabilityScope.AssignedContent,
];

export const getOptionName = (option: ReadonlyArray<Capability>): string => {
  const prefix = option
    .slice(0, option.length - 1)
    .map(getCapabilityName)
    .join(', ');

  const lastCapability = Collection.getLast(option);
  const suffix = `${option.length > 2 ? ',' : ''}${option.length > 1 ? ' and ' : ''}${
    lastCapability ? getCapabilityName(lastCapability) : ''
  }`;

  return capitalizeFirstLetter(prefix + suffix);
};
