import { Collection } from '@kontent-ai/utils';
import { DefaultLanguageId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { AiOperationState } from '../../../../../../_shared/features/AI/types/AiOperationState.ts';
import { AiError } from '../../../../../../_shared/features/AI/types/aiErrors.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { ElementReference } from '../../../../../../applications/itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { getAllAiGuidelines } from '../../../../data/reducers/selectors/getAiGuidelines.ts';
import { AiReviewAction as AiReviewActionComponent } from '../components/AiReviewAction.tsx';
import { getAiGuidelinesMenuOptions } from '../utils/getAiGuidelinesMenuOptions.ts';

type AiReviewActionProps = {
  readonly aiGuidelinesIds: ReadonlyArray<Uuid>;
  readonly aiOperationState: AiOperationState;
  readonly anyAiReviewCommentsAdded: boolean;
  readonly element: ElementReference;
  readonly error: AiError | null;
  readonly isEmpty: boolean;
  readonly perform: (selectedAiGuidelinesId: Uuid) => void;
};

export const AiReviewAction = (props: AiReviewActionProps) => {
  const isDefaultLanguage = useSelector(
    (state) => getSelectedLanguageId(state) === DefaultLanguageId,
  );
  const defaultAiGuidelinesId = Collection.getFirst(props.aiGuidelinesIds);
  const aiGuidelinesOptions = getAiGuidelinesMenuOptions(
    useSelector(getAllAiGuidelines),
    defaultAiGuidelinesId,
  );

  return (
    <AiReviewActionComponent
      {...props}
      aiGuidelinesOptions={aiGuidelinesOptions}
      isDefaultLanguage={isDefaultLanguage}
    />
  );
};
