import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { OptionalTooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing, colorTextLowEmphasis } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { BarItemEditAction } from '../../../_shared/components/BarItems/Actions/BarItemEditAction.tsx';
import { BarItemActions } from '../../../_shared/components/BarItems/BarItemActions.tsx';
import { BarItemBar } from '../../../_shared/components/BarItems/BarItemBar.tsx';
import { BarItemContent } from '../../../_shared/components/BarItems/BarItemContent.tsx';
import { BarItemError } from '../../../_shared/components/BarItems/BarItemError.tsx';
import { SearchPhraseHighlighterElement } from '../../../_shared/components/Highlighting/SearchPhraseHighlighterElement.tsx';
import { pluralizeWithCount } from '../../../_shared/utils/stringUtils.ts';
import { SpacesMap } from '../../../data/models/space/space.ts';
import { DisabledEditingButtonTooltipText } from '../constants/previewConfigurationUiConstants.ts';
import { IPreviewUrlPattern } from '../models/IPreviewUrlPattern.type.ts';
import { anySpacesOptionId } from '../utils/getSpacesOptions.ts';

type Props = {
  readonly contentTypeName: string;
  readonly errorMessage: string | undefined;
  readonly isEditingEnabled: boolean;
  readonly onEdit: () => void;
  readonly previewUrlPatterns: ReadonlyArray<IPreviewUrlPattern>;
  readonly spaces: SpacesMap;
  readonly searchPhrase: string;
};

export const CollapsedPreviewUrlBar: React.FC<Props> = ({
  contentTypeName,
  errorMessage,
  isEditingEnabled,
  onEdit,
  previewUrlPatterns,
  spaces,
  searchPhrase,
}) => {
  const spacesTitleDescription = getSpacesTitleDescription(previewUrlPatterns, spaces);

  return (
    <div>
      <div className="bar-item__wrapper">
        <div className="bar-item__pane">
          <BarItemBar isClickable={isEditingEnabled} dataUiObjectName={contentTypeName}>
            <BarItemContent onClick={isEditingEnabled ? onEdit : undefined}>
              <Box width="100%">
                <Row alignX="start" spacingX={Spacing.L}>
                  <Column width="1/4">
                    <OptionalTooltip placement="bottom-start" text={contentTypeName}>
                      <SearchPhraseHighlighterElement
                        searchPhrase={searchPhrase}
                        text={contentTypeName}
                      />
                    </OptionalTooltip>
                  </Column>
                  <Column width="3/4">
                    <Box color={colorTextLowEmphasis}>
                      <OptionalTooltip placement="bottom-start" text={spacesTitleDescription}>
                        {spacesTitleDescription}
                      </OptionalTooltip>
                    </Box>
                  </Column>
                </Row>
              </Box>
            </BarItemContent>
            <BarItemActions>
              <BarItemEditAction
                disabled={!isEditingEnabled}
                disabledTooltipText={DisabledEditingButtonTooltipText}
                onClick={onEdit}
              />
            </BarItemActions>
          </BarItemBar>
          <BarItemError showError={!!errorMessage} error={errorMessage} />
        </div>
      </div>
    </div>
  );
};

CollapsedPreviewUrlBar.displayName = 'CollapsedPreviewUrlBar';

const getSpacesTitleDescription = (
  previewUrlPatterns: ReadonlyArray<IPreviewUrlPattern>,
  spaces: SpacesMap,
): string => {
  const allSelectedSpaceIds = previewUrlPatterns.flatMap((previewUrlPattern) =>
    Collection.getValues(previewUrlPattern.spaces),
  );
  if (!allSelectedSpaceIds.length) {
    return '';
  }

  const isDefinedForAllSpaces =
    allSelectedSpaceIds.includes(anySpacesOptionId) || allSelectedSpaceIds.length === spaces.size;
  if (isDefinedForAllSpaces) {
    return 'Defined for all spaces';
  }

  return `Defined for ${pluralizeWithCount('space', allSelectedSpaceIds.length)}`;
};
