import React, { ComponentProps } from 'react';
import { IconButton } from '../../Button/IconButton/IconButton.tsx';
import { IconName } from '../../Icons/types.ts';

type StatusBarIconButtonProps = {
  readonly iconName: IconName;
  readonly label: string;
  readonly isActive?: boolean;
  readonly disabled?: boolean;
  readonly component?: ComponentProps<typeof IconButton>['component'];
};

export const StatusBarIconButton = React.forwardRef<HTMLButtonElement, StatusBarIconButtonProps>(
  ({ iconName, label, isActive = false, ...restProps }: StatusBarIconButtonProps, ref) => {
    return (
      <IconButton
        activated={isActive}
        aria-label={label}
        buttonStyle="secondary-inverse"
        iconName={iconName}
        ref={ref}
        size="medium"
        tooltipPlacement="bottom"
        tooltipText={label}
        {...restProps}
      />
    );
  },
);

StatusBarIconButton.displayName = 'StatusBarIconButton';
