import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Spacing } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IModalDialogTitleBarCallbackProps {
  readonly onClose: () => void;
}

export interface IModalDialogTitleBarProps extends IModalDialogTitleBarCallbackProps {
  readonly noDivider?: boolean;
  readonly title: string;
}

export const ModalDialogTitleBar: React.FC<IModalDialogTitleBarProps> = ({
  noDivider,
  onClose,
  title,
}) => (
  <div
    className={classNames('modal-dialog__header', {
      'modal-dialog__header--with-divider': !noDivider,
    })}
  >
    <div className="modal-dialog__header-title" data-hj-suppress="">
      {title}
    </div>
    <Box marginTop={Spacing.XS} marginLeft={Spacing.M}>
      <QuinaryButton
        onClick={onClose}
        tooltipPlacement="left"
        tooltipShortcuts="Esc"
        tooltipText="Close"
        aria-label="Close"
        {...getDataUiActionAttribute(DataUiAction.Cancel)}
      >
        <QuinaryButton.Icon icon={Icons.ModalClose} />
      </QuinaryButton>
    </Box>
  </div>
);

ModalDialogTitleBar.displayName = 'ModalDialogTitleBar';
