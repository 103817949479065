import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Option, RenderSelectMenuOptionProps } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  IWorkflowStageSelectOption,
  WorkflowStage,
  workflowStages,
} from '../../constants/workflowStage.ts';

const renderMenuOption = (
  optionProps: RenderSelectMenuOptionProps<IWorkflowStageSelectOption>,
): React.ReactNode => {
  const {
    item: { value: option },
  } = optionProps;

  return option && <Option {...optionProps} complementaryText={option.complementaryText} />;
};

type ContentPaceWorkflowStageSelectorProps = Readonly<{
  selectedWorkflowStage: WorkflowStage;
  onSelectionChange: (selectedWorkflowStage: WorkflowStage) => void;
}>;

const labelId = 'workflow-stage-label';
export const ContentPaceWorkflowStageSelector: React.FC<ContentPaceWorkflowStageSelectorProps> = ({
  selectedWorkflowStage,
  onSelectionChange,
}) => {
  return (
    <Inline align="center" spacingX={Spacing.S}>
      <Box
        component="label"
        color={colorTextDefault}
        htmlFor={labelId}
        typography={Typography.BodyMedium}
      >
        Content lifecycle:
      </Box>
      <SingleSelect
        aria-labelledby={labelId}
        id={labelId}
        items={workflowStages}
        selectedItemId={selectedWorkflowStage}
        onSelectionChange={onSelectionChange}
        renderMenuOption={renderMenuOption}
      />
    </Inline>
  );
};

ContentPaceWorkflowStageSelector.displayName = 'ContentPaceWorkflowStageSelector';
