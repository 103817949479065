import React, { useCallback } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useAiSearchScope } from '../../../../paperModels/aiSearch/selectors/useAiSearchScope.ts';
import { FullTextSearchStatus } from '../../shared/reducers/IContentInventoryStoreState.type.ts';
import { ContentItemsSearchScope as ContentItemsSearchScopeComponent } from '../components/ContentItemsSearchScope.tsx';
import { onSearchScopeChanged } from '../features/ListingFilter/actions/listingFilterActions.ts';
import { SearchScope } from '../models/filter/IListingFilter.ts';

type Props = {
  readonly fullTextSearchStatus: FullTextSearchStatus;
};

export const ContentItemsSearchScope: React.FC<Props> = ({ fullTextSearchStatus }) => {
  const isFullTextSearchUnavailable = fullTextSearchStatus === FullTextSearchStatus.Unavailable;

  const scope = useSelector((state) =>
    isFullTextSearchUnavailable
      ? new Set([SearchScope.Name])
      : state.contentApp.listingUi.filter.searchScope,
  );

  const dispatch = useDispatch();
  const changeScope = useCallback(
    (newScope: ReadonlySet<SearchScope>) => dispatch(onSearchScopeChanged(newScope)),
    [],
  );

  const { allowAiSearch, displayScope } = useAiSearchScope(scope, changeScope);

  return (
    <ContentItemsSearchScopeComponent
      allowAiSearch={allowAiSearch}
      isFulltextDisabled={isFullTextSearchUnavailable}
      onChange={changeScope}
      scope={displayScope}
    />
  );
};
