import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { BarItemActions } from '../../../../../_shared/components/BarItems/BarItemActions.tsx';
import {
  DataUiAction,
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly isEditable?: boolean;
  readonly onUnlink?: () => void;
  readonly onEdit?: () => void;
};

export const LinkDetailActions = ({ isEditable, onUnlink, onEdit }: Props) => {
  if (!onUnlink && !onEdit) {
    return null;
  }

  return (
    <BarItemActions isCompact>
      {isEditable && onEdit && (
        <QuinaryButton
          onClick={onEdit}
          tooltipPlacement="bottom"
          tooltipText="Edit"
          {...getDataUiActionAttribute(DataUiAction.Edit)}
        >
          <QuinaryButton.Icon icon={Icons.Edit} screenReaderText="Edit" />
        </QuinaryButton>
      )}
      {onUnlink && (
        <QuinaryButton
          onClick={onUnlink}
          tooltipPlacement="bottom"
          tooltipText="Unlink"
          {...getDataUiActionAttribute(DataUiRteAction.Unlink)}
        >
          <QuinaryButton.Icon icon={Icons.ChainSlash} />
        </QuinaryButton>
      )}
    </BarItemActions>
  );
};
