import { Box } from '@kontent-ai/component-library/Box';
import { BaseColor, BorderRadius, Spacing } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { EditorState } from 'draft-js';
import React, { ClipboardEvent, useCallback, useId } from 'react';
import { ElementType } from '../../applications/contentInventory/content/models/ContentItemElementType.ts';
import { IGuidelinesTypeElement } from '../../applications/contentInventory/content/models/contentTypeElements/GuidelinesTypeElement.ts';
import { EmptyContentComponents } from '../../applications/itemEditor/models/contentItem/ContentComponent.ts';
import { setRichTextClipboard } from '../../applications/richText/actions/thunkRichTextActions.ts';
import { GuidelinesViewer } from '../../applications/richText/editors/guidelines/GuidelinesViewer.tsx';
import { EmptyMetadata } from '../../applications/richText/plugins/clipboard/thunks/setRichTextClipboard.ts';
import { isContentEmpty } from '../../applications/richText/utils/general/editorContentUtils.ts';
import { useDispatch } from '../hooks/useDispatch.ts';
import { getDataUiContentElementAttribute } from '../utils/dataAttributes/DataUiAttributes.ts';
import { BarItemToggleAction } from './BarItems/Actions/BarItemToggleAction.tsx';
import { BarItemActions } from './BarItems/BarItemActions.tsx';
import { BarItemExpandedNoActions } from './BarItems/BarItemExpandedNoActions.tsx';
import { BarItemTitle } from './BarItems/BarItemTitle.tsx';
import { BarItemWithLoader } from './BarItems/BarItemWithLoader.tsx';

interface IGuidelinesProps {
  readonly typeElement: IGuidelinesTypeElement;
  readonly isCollapsed: boolean;
  readonly onCollapsedStateChanged: (isCollapsed: boolean) => void;
}

const EmptyGuidelines: React.FC = () => (
  <Box
    backgroundColor={BaseColor.Yellow10}
    borderRadius={BorderRadius.M}
    className="content-item-guidelines"
    minHeight={Spacing.XXXXL}
    {...getDataUiContentElementAttribute(ElementType.Guidelines)}
  />
);

export const Guidelines: React.FC<IGuidelinesProps> = ({
  isCollapsed,
  onCollapsedStateChanged,
  typeElement,
}) => {
  const dispatch = useDispatch();
  const onSetRichTextClipboard = useCallback(
    (e: ClipboardEvent, editorState: EditorState) =>
      dispatch(setRichTextClipboard(e, editorState, EmptyContentComponents, EmptyMetadata)),
    [],
  );

  const guidelinesViewerId = useId();

  if (isContentEmpty(typeElement._editorState.getCurrentContent())) {
    return <EmptyGuidelines />;
  }

  return (
    <Box
      className="content-item-guidelines"
      {...getDataUiContentElementAttribute(ElementType.Guidelines)}
    >
      <Box className="bar-item__wrapper">
        <Box
          className={classNames('bar-item__pane', { 'bar-item__pane--is-expanded': !isCollapsed })}
        >
          <BarItemWithLoader isClickable isExpanded={!isCollapsed}>
            <BarItemActions>
              <BarItemToggleAction
                ariaLabel="Guidelines"
                ariaControls={guidelinesViewerId}
                isCollapsed={isCollapsed}
                onCollapse={() => onCollapsedStateChanged(true)}
                onExpand={() => onCollapsedStateChanged(false)}
              />
            </BarItemActions>

            <BarItemTitle onClick={() => onCollapsedStateChanged(!isCollapsed)}>
              Guidelines
            </BarItemTitle>
          </BarItemWithLoader>

          {!isCollapsed && (
            <BarItemExpandedNoActions>
              <GuidelinesViewer
                id={guidelinesViewerId}
                className="content-item-guidelines__content"
                editorState={typeElement._editorState}
                elementId={typeElement.elementId}
                isViewOnly
                setRichTextClipboard={onSetRichTextClipboard}
              />
            </BarItemExpandedNoActions>
          )}
        </Box>
      </Box>
    </Box>
  );
};

Guidelines.displayName = 'Guidelines';
