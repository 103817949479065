import { Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';
import { BarItemAction } from '../../../../../../../_shared/components/BarItems/Actions/BarItemAction.tsx';
import { DataUiAction } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly onClick: () => void;
};

export const LinkedItemDuplicateAction: React.FC<Props> = ({ onClick }) => (
  <BarItemAction
    dataUiActionName={DataUiAction.Duplicate}
    icon={Icons.DocCopy}
    onClick={onClick}
    tooltipText="Duplicate"
  />
);
