import { alphabetically, delay } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { ProjectOrderBy } from '../../../_shared/constants/projectOrderBy.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import { IProjectRepository } from '../../../repositories/interfaces/IProjectRepository.type.ts';
import { ProjectUiTransitionState } from '../types/projectUiTransitionState.ts';

export const createCompareProjectDetails =
  (orderBy: ProjectOrderBy) =>
  (proj1: IProjectDetails, proj2: IProjectDetails): number => {
    const proj1Inactive = proj1.inactive;
    const proj2Inactive = proj2.inactive;

    if (proj1Inactive !== proj2Inactive) {
      return proj1Inactive ? 1 : -1;
    }

    // we need to take secondary ordering into an account
    switch (orderBy) {
      case ProjectOrderBy.ProjectName:
        return (
          alphabetically(proj1.projectName, proj2.projectName) ||
          alphabetically(proj1.subscriptionName, proj2.subscriptionName)
        );
      case ProjectOrderBy.SubscriptionName:
        return (
          alphabetically(proj1.subscriptionName, proj2.subscriptionName) ||
          alphabetically(proj1.projectName, proj2.projectName)
        );
    }
  };

export async function waitUntilProjectIsActive(
  createdProjectId: Uuid,
  projectRepository: IProjectRepository,
  abortSignal?: AbortSignal,
): Promise<void> {
  const projectStatus = await projectRepository.getUserCacheStatus(createdProjectId, abortSignal);

  if (!projectStatus.isActive) {
    await delay(1000);
    await waitUntilProjectIsActive(createdProjectId, projectRepository);
  }
}

export function isProjectInSettingUpUiTransitionState(
  projectId: Uuid,
  projectsUiTransitionState: Immutable.Map<Uuid, ProjectUiTransitionState>,
): boolean {
  return projectsUiTransitionState.get(projectId) === ProjectUiTransitionState.SettingUp;
}
