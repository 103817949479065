import { isUserProjectManager } from '../../../../_shared/utils/permissions/isProjectManager.ts';
import { IUser } from '../../../../data/reducers/user/IUser.type.ts';
import {
  DashboardWidget,
  WidgetDescriptor,
  WidgetRequirement,
} from '../../types/WidgetDescriptor.type.ts';
import { widgets } from '../constants/widgets.ts';

type ResolverContext = Readonly<{
  projectId: Uuid;
  user: IUser;
}>;

type DashboardWidgetWithNullableComponent = Omit<DashboardWidget, 'component'> & {
  component: DashboardWidget['component'] | null;
};

const isWidgetRequirementFulfilled = (requirement: WidgetRequirement, context: ResolverContext) => {
  switch (requirement) {
    case WidgetRequirement.userIsProjectManager:
      return isUserProjectManager(context.user, context.projectId);
  }
};

const areWidgetRequirementsSatisfied = (
  widget: WidgetDescriptor,
  context: ResolverContext,
): boolean =>
  widget.requirements.every((requirement) => isWidgetRequirementFulfilled(requirement, context));

const widgetComponentIsDefined = (
  widget: DashboardWidgetWithNullableComponent,
): widget is DashboardWidget => !!widget.component;

const createDashboardWidgetMapper =
  (isDemoMode: boolean) =>
  (widget: WidgetDescriptor): DashboardWidgetWithNullableComponent => {
    const component = isDemoMode ? widget.demoComponent : widget.liveComponent;

    return {
      uniqueKey: widget.uniqueKey,
      component,
    };
  };

export const getAvailableWidgets: (
  isDemoMode: boolean,
  context: ResolverContext,
) => ReadonlyArray<DashboardWidget> = (isDemoMode, context) => {
  const toDashboardWidget = createDashboardWidgetMapper(isDemoMode);

  return widgets
    .filter((widget) => areWidgetRequirementsSatisfied(widget, context))
    .map(toDashboardWidget)
    .filter(widgetComponentIsDefined);
};
