import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { LinkInfo } from '../../components/LinkInfo.tsx';

type Props = {
  readonly disabled?: boolean;
  readonly phoneNumber: string;
};

export const PhoneLinkInfo = ({ disabled, phoneNumber }: Props) =>
  disabled ? (
    <Box padding={Spacing.S}>
      <LinkInfo>
        <LinkInfo.Label>Phone:</LinkInfo.Label>
        <LinkInfo.Value>
          <a href={`tel:${phoneNumber}`} rel="noopener noreferrer">
            {phoneNumber}
          </a>
        </LinkInfo.Value>
      </LinkInfo>
    </Box>
  ) : (
    <a
      className="link-dialog__detail-link-pane"
      href={`tel:${phoneNumber}`}
      rel="noopener noreferrer"
    >
      <div className="link-dialog__detail-link">{phoneNumber}</div>
    </a>
  );
