import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { ReactNode, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { initCustomAppListing } from '../../../applications/customApps/actions/thunkCustomAppActions.ts';
import { projectContextLeft } from '../../../applications/projects/actions/projectsActions.ts';
import { clearSelectedSubscription } from '../../../applications/subscriptionManagement/shared/actions/subscriptionManagementActions.ts';
import { loadAssetType, loadUserProjectsInfo } from '../../../data/actions/thunkDataActions.ts';
import { IUserProjectInfo } from '../../../data/models/user/UserProjectInfo.ts';
import {
  getCurrentProjectId,
  getCurrentSubscriptionId,
} from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { projectIdStorage } from '../../../localStorages/projectIdStorage.ts';
import { currentProjectUpdated } from '../../actions/sharedActions.ts';
import {
  loadFallbacksForLinkedContentStatus,
  loadProjectFeatureFlags,
  loadProjectProperties,
  loadSubscriptionProperties,
  loadWebSpotlightConfiguration,
} from '../../actions/thunkSharedActions.ts';
import { trackUserEvent } from '../../actions/thunks/trackUserEvent.ts';
import { Loader } from '../../components/Loader.tsx';
import {
  EnvironmentRoute,
  EnvironmentRouteParams,
  ProjectsRoute,
} from '../../constants/routePaths.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { useThunkPromise } from '../../hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../models/LoadingStatusEnum.ts';
import { repositoryCollection } from '../../repositories/repositories.ts';
import { buildPath } from '../../utils/routing/routeTransitionUtils.ts';
import { isUuid } from '../../utils/validation/typeValidators.ts';

const useProjectRouteEntered = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearSelectedSubscription());

    return () => {
      dispatch(projectContextLeft());
    };
  }, []);
};

const useReconcileCurrentProjectIdWithRouteParameter = (
  userProjects: Immutable.Map<string, IUserProjectInfo> | null,
): boolean => {
  const desiredProjectId = useParams<EnvironmentRouteParams>().projectId || projectIdStorage.load();
  const history = useHistory();
  const projectToBeSet =
    ((desiredProjectId && userProjects?.get(desiredProjectId)) || userProjects?.first()) ?? null;
  const projectIdToBeSet = projectToBeSet?.projectId;
  const masterEnvironmentIdToBeSet = projectToBeSet?.masterEnvironmentId;

  const dispatch = useDispatch();

  useEffect(() => {
    if (userProjects && (!projectToBeSet || !projectToBeSet.isSetUpCorrectly)) {
      history.push(ProjectsRoute);
    }
  }, [history, projectToBeSet, userProjects]);

  useEffect(() => {
    if (isUuid(projectIdToBeSet) && projectIdToBeSet !== desiredProjectId) {
      history.push(
        buildPath<EnvironmentRouteParams>(EnvironmentRoute, { projectId: projectIdToBeSet }),
      );
    }
  }, [desiredProjectId, history, projectIdToBeSet]);

  useEffect(() => {
    if (
      !isUuid(projectIdToBeSet) ||
      !isUuid(masterEnvironmentIdToBeSet) ||
      projectIdToBeSet !== desiredProjectId
    ) {
      return;
    }

    const { cancel } = makeCancellablePromise(() =>
      repositoryCollection.roleRepository.getNormalizedRoleWithSettings(projectIdToBeSet),
    )
      .then((roles) => {
        dispatch(currentProjectUpdated(projectIdToBeSet, masterEnvironmentIdToBeSet, roles));
      })
      .catch(swallowCancelledPromiseError);

    return cancel;
  }, [desiredProjectId, masterEnvironmentIdToBeSet, projectIdToBeSet]);

  const currentProjectId = useSelector(getCurrentProjectId);
  return isUuid(currentProjectId) && currentProjectId === desiredProjectId;
};

type Props = {
  readonly children: ReactNode;
};

export const EnsureCurrentProject = (props: Props) => {
  const currentProjectId = useSelector(getCurrentProjectId);
  const currentSubscriptionId = useSelector(getCurrentSubscriptionId);
  const webSpotlightConfiguration = useSelector((state) => state.webSpotlightApp.configuration);
  const customAppsLoadingStatus = useSelector((state) => state.data.userCustomApps.loadingStatus);
  const userProjects = useSelector((state) => state.data.user.projectsInfoById);

  useProjectRouteEntered();

  const areProjectsReconciled = useReconcileCurrentProjectIdWithRouteParameter(userProjects);

  const dispatch = useDispatch();
  useEffect(() => {
    if (areProjectsReconciled) {
      dispatch(trackUserEvent(TrackedEvent.CurrentProjectUpdated));
      projectIdStorage.save(currentProjectId);
    }
  }, [areProjectsReconciled, currentProjectId]);

  useEffect(() => {
    if (areProjectsReconciled) {
      repositoryCollection.projectRepository.setLastLoginForCurrentUser(currentProjectId);
    }
  }, [currentProjectId, areProjectsReconciled]);

  const [isLoadUserProjectsInfoThunkDone] = useThunkPromise(loadUserProjectsInfo);

  const [isFetchProjectDataThunkDone] = useThunkPromise(
    fetchProjectData,
    currentProjectId,
    currentSubscriptionId,
    {
      canRun: areProjectsReconciled,
    },
  );

  const isStateEnsured =
    isLoadUserProjectsInfoThunkDone &&
    isFetchProjectDataThunkDone &&
    areProjectsReconciled &&
    !!webSpotlightConfiguration &&
    customAppsLoadingStatus === LoadingStatus.Loaded;

  return isStateEnsured ? props.children : <Loader />;
};

export const fetchProjectData =
  (projectId: Uuid, subscriptionId: Uuid, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    await Promise.all([
      dispatch(loadAssetType(abortSignal)),
      dispatch(loadFallbacksForLinkedContentStatus(projectId, abortSignal)),
      dispatch(loadProjectFeatureFlags(projectId, abortSignal)),
      dispatch(loadProjectProperties(projectId, abortSignal)),
      dispatch(loadSubscriptionProperties(subscriptionId, abortSignal)),
      dispatch(loadWebSpotlightConfiguration(projectId, abortSignal)),
      dispatch(initCustomAppListing(projectId, abortSignal)),
    ]);
  };
