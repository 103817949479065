import React from 'react';
import { RefreshPreviewButton as RefreshPreviewButtonComponent } from '../../components/contentItemPreview/RefreshPreviewButton.tsx';
import { useWebSpotlightInItemEditing } from '../../context/WebSpotlightInItemEditingContext.tsx';

export const RefreshPreviewButton: React.FC = () => {
  const { refreshPreview } = useWebSpotlightInItemEditing();

  const onClick = () => {
    refreshPreview({ isManualRefresh: true, isPreviewUrlOutdated: true });
  };

  return <RefreshPreviewButtonComponent onClick={onClick} />;
};
