import { Collection } from '@kontent-ai/utils';
import { ItemEditorOperationId } from '../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import {
  EditedContentItemFailure,
  FailureStatus,
} from '../models/contentItem/edited/EditedContentItemStatus.ts';

const failureStatusPriority = {
  [FailureStatus.UnknownFailure]: 0,
  [FailureStatus.ModelValidationFailed]: 1,
  [FailureStatus.MissingCapability]: 2,
  [FailureStatus.ContentWasModified]: 3,
  [FailureStatus.ElementHasConcurrentConflict]: 4,
  [FailureStatus.NonLocalizableContentTooLarge]: 5,
  [FailureStatus.ContentTooLarge]: 6,
  [FailureStatus.ElementTooLarge]: 7,
  [FailureStatus.CodenameIsNotUnique]: 8,
} satisfies ReadonlyRecord<FailureStatus, number>;

const compareFilTypePriorityDesc = (typeA: FailureStatus, typeB: FailureStatus) => {
  return failureStatusPriority[typeA] - failureStatusPriority[typeB];
};

export const prioritizeFailures = (
  failures: ReadonlyMap<ItemEditorOperationId, EditedContentItemFailure>,
): readonly (readonly [ItemEditorOperationId, EditedContentItemFailure])[] => {
  return Collection.getEntries(failures).toSorted(([, failureA], [, failureB]) =>
    compareFilTypePriorityDesc(failureA.status, failureB.status),
  );
};

export const shouldDisableFormBasedOnFailureType = (type: FailureStatus): boolean => {
  switch (type) {
    case FailureStatus.NonLocalizableContentTooLarge:
    case FailureStatus.ContentTooLarge:
    case FailureStatus.CodenameIsNotUnique:
    case FailureStatus.ElementTooLarge:
      // We need to provide a way to shorten the content in these cases.
      return false;

    case FailureStatus.UnknownFailure /* Probably offline */:
    case FailureStatus.ContentWasModified:
    case FailureStatus.MissingCapability:
    case FailureStatus.ElementHasConcurrentConflict:
    case FailureStatus.ModelValidationFailed:
      // There is no point in further editing content in these cases.
      return true;
  }
};
