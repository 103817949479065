import { ReactNode } from 'react';
import { RedactedItemName } from '../../../applications/contentInventory/content/constants/uiConstants.ts';
import { BarItemActions } from '../BarItems/BarItemActions.tsx';
import { BarItemContent } from '../BarItems/BarItemContent.tsx';
import { BarItemTitle } from '../BarItems/BarItemTitle.tsx';

type Props = {
  readonly areCollectionsVisible: boolean;
  readonly cannotViewDisabledMessage: string | undefined;
  readonly collectionName: string | undefined;
  readonly contentItemName: string;
  readonly contentTypeName: string;
  readonly hideDescription?: boolean;
  readonly isCompact?: boolean;
  readonly isInvalid: boolean | undefined;
  readonly isUnfinished?: boolean;
  readonly onClick?: (() => void) | undefined;
  readonly renderExpandToggle?: () => ReactNode;
  readonly renderWorkflowStep?: () => ReactNode;
};

export const LoadedLinkedItemContent = ({
  areCollectionsVisible,
  cannotViewDisabledMessage,
  collectionName,
  contentItemName,
  contentTypeName,
  hideDescription,
  isCompact,
  isInvalid,
  isUnfinished,
  onClick,
  renderExpandToggle,
  renderWorkflowStep,
}: Props) => {
  const isRedacted = !!cannotViewDisabledMessage;

  return (
    <>
      {renderExpandToggle && (
        <BarItemActions isCompact={isCompact}>{renderExpandToggle()}</BarItemActions>
      )}
      <BarItemContent isCompact={isCompact} onClick={onClick}>
        <BarItemTitle
          isUnfinished={isUnfinished}
          tooltipText={isRedacted ? cannotViewDisabledMessage : contentItemName}
          titleStyle={isRedacted ? 'primary-redacted' : 'primary'}
        >
          {isRedacted ? RedactedItemName : contentItemName}
        </BarItemTitle>
        {!hideDescription && (
          <>
            <BarItemTitle
              titleStyle={isInvalid ? 'secondary-alert' : 'secondary'}
              tooltipText={`Content type: ${contentTypeName}`}
            >
              {contentTypeName}
            </BarItemTitle>
            {areCollectionsVisible && (
              <BarItemTitle titleStyle="secondary" tooltipText={`Collection: ${collectionName}`}>
                {collectionName}
              </BarItemTitle>
            )}
          </>
        )}
        {renderWorkflowStep?.()}
      </BarItemContent>
    </>
  );
};
