import { Box } from '@kontent-ai/component-library/Box';
import { IconButton } from '@kontent-ai/component-library/Button';
import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import { BaseColor, Spacing } from '@kontent-ai/component-library/tokens';
import React, { ComponentProps, useMemo, useRef } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  roleBuilderAnyCollectionOptionId,
  roleBuilderDeletedCollectionOptionId,
} from '../../constants/roleBuilder.ts';
import { getRoleBuilderCollectionOptions } from '../../utils/getRoleBuilderCollectionOptions.ts';

type Props = {
  readonly allSelectedCollectionIds: ReadonlySet<Uuid>;
  readonly anyCollectionOptionName: string;
  readonly autoFocus?: boolean;
  readonly canChangeCollectionsInCurrentPlan: boolean;
  readonly collectionIds: UuidArray;
  readonly disabledTooltip: string | undefined;
  readonly onCollectionIdsChange: (collectionIds: UuidArray) => void;
  readonly onRemove: (() => void) | null;
};

export type CollectionOption = ISelectItem<CollectionOption>;

const deletedCollectionOption: CollectionOption = {
  id: roleBuilderDeletedCollectionOptionId,
  label: 'Deleted collection',
};

const getCollectionOptionColor = (option: CollectionOption): BaseColor | undefined => {
  if (option.id === roleBuilderAnyCollectionOptionId) {
    return BaseColor.Gray30;
  }

  if (option.id === roleBuilderDeletedCollectionOptionId) {
    return BaseColor.Red60;
  }

  return undefined;
};

const renderSelectedOption = (
  _id: Uuid,
  selectedItem: CollectionOption,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) => {
  const background = getCollectionOptionColor(selectedItem);
  if (background) {
    return <Tag {...defaultTagProps} background={background} />;
  }

  return <DefaultTag {...defaultTagProps} />;
};

export const CollectionsGroupHeader: React.FC<Props> = ({
  allSelectedCollectionIds,
  anyCollectionOptionName,
  autoFocus,
  canChangeCollectionsInCurrentPlan,
  collectionIds,
  disabledTooltip,
  onCollectionIdsChange,
  onRemove,
}) => {
  const collectionOptions = useSelector((state) =>
    getRoleBuilderCollectionOptions(
      state.data.collections.byId,
      anyCollectionOptionName,
      allSelectedCollectionIds,
      collectionIds,
    ),
  );

  const existingSelectedCollectionOptions = useMemo(
    () => collectionOptions.filter((option) => collectionIds.includes(option.id)),
    [collectionOptions, collectionIds],
  );

  const hasOnlyDeletedCollections =
    collectionIds.length && !existingSelectedCollectionOptions.length;

  // We need to preserve the initial deleted option in the select options even when it is later removed from the selection
  const includeDeletedCollectionsOption = useRef(hasOnlyDeletedCollections).current;

  const allCollectionOptions = useMemo<ReadonlyArray<CollectionOption>>(
    () =>
      includeDeletedCollectionsOption
        ? [deletedCollectionOption, ...collectionOptions]
        : collectionOptions,
    [collectionOptions, includeDeletedCollectionsOption],
  );

  const selectedItemIds = useMemo(
    () =>
      hasOnlyDeletedCollections
        ? [roleBuilderDeletedCollectionOptionId]
        : existingSelectedCollectionOptions
            .filter((option) => option.id !== roleBuilderDeletedCollectionOptionId)
            .map((option) => option.id),
    [existingSelectedCollectionOptions, hasOnlyDeletedCollections],
  );

  const onChange = (newSelectedIds: ReadonlySet<Uuid>): void => {
    if (newSelectedIds.has(roleBuilderAnyCollectionOptionId)) {
      // If "any" option was previously selected and there are additionally selected standard options, dismiss the "any" option
      if (newSelectedIds.size > 1 && selectedItemIds.includes(roleBuilderAnyCollectionOptionId)) {
        onCollectionIdsChange(
          [...newSelectedIds].filter(
            (id) =>
              id !== roleBuilderAnyCollectionOptionId &&
              id !== roleBuilderDeletedCollectionOptionId,
          ),
        );
      } else {
        // If the "any" option is new, keep just the "any" option
        onCollectionIdsChange([roleBuilderAnyCollectionOptionId]);
      }
    } else {
      // Do not save the deleted collection option ID, it can only be deselected if something else is selected
      onCollectionIdsChange(
        [...newSelectedIds].filter((id) => id !== roleBuilderDeletedCollectionOptionId),
      );
    }
  };

  const disabledCollectionIds = allCollectionOptions
    .filter(
      (option) =>
        !!option.disabledTooltipText ||
        (option.id === deletedCollectionOption.id && !hasOnlyDeletedCollections),
    )
    .map((option) => option.id);

  const removeButtonTooltipText = onRemove
    ? 'Remove all role assignments for this collection.'
    : 'At least one collection must be specified.';

  const collectionDropdownDisabledTooltip = canChangeCollectionsInCurrentPlan
    ? undefined
    : 'Your subscription plan prevents using role-based permissions for collections.';

  return (
    <div className="collections-group__header">
      <div className="collections-group__title">
        Roles in collections
        <Box paddingLeft={Spacing.L}>
          <IconButton
            aria-label={disabledTooltip ?? removeButtonTooltipText}
            buttonState={!!disabledTooltip || !onRemove ? 'disabled' : 'default'}
            buttonStyle="tertiary"
            iconName="Bin"
            onClick={onRemove ?? undefined}
            size="medium"
            tooltipPlacement="bottom"
            tooltipText={disabledTooltip || removeButtonTooltipText}
            {...getDataUiActionAttribute(DataUiAction.Remove)}
          />
        </Box>
      </div>
      <MultiSelect<CollectionOption>
        autoFocus={autoFocus}
        disabledItemIds={disabledCollectionIds}
        inputState={
          !!disabledTooltip || !!collectionDropdownDisabledTooltip
            ? InputState.Disabled
            : InputState.Default
        }
        items={allCollectionOptions}
        label="Collection"
        onSelectionChange={onChange}
        placeholder="Select a collection"
        renderSelectedOption={renderSelectedOption}
        selectedItemIds={selectedItemIds}
        tooltipText={disabledTooltip || collectionDropdownDisabledTooltip}
        {...getDataUiCollectionAttribute(DataUiCollection.ContentCollections)}
      />
    </div>
  );
};

CollectionsGroupHeader.displayName = 'CollectionsGroupHeader';
