import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import { Task, TaskStatus } from '../../../../_shared/models/Task.ts';
import {
  ContentItemEditing_CreateTask_Finished,
  ContentItemEditing_LoadTasksForItemVariant_Finished,
  ContentItemEditing_LoadTasksForItemVariant_Started,
  ContentItemEditing_TaskArchive_Finished,
  ContentItemEditing_TaskComplete_Failed,
  ContentItemEditing_TaskComplete_Started,
  ContentItemEditing_TaskReopen_Failed,
  ContentItemEditing_TaskReopen_Started,
  ContentItemEditing_UpdateTask_Finished,
} from '../../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  YourTasks_Init_Finished,
  YourTasks_Init_Started,
} from '../../../../applications/missionControl/widgets/tasksAssignedToYou/constants/yourTasksActionTypes.ts';

const completeTask = (task: Task) => ({
  ...task,
  status: TaskStatus.Completed,
});

const reopenTask = (task: Task) => ({
  ...task,
  status: TaskStatus.Open,
});

const initialState: ReadonlyMap<Uuid, Task> = new Map();

export const byId = (state = initialState, action: Action): ReadonlyMap<Uuid, Task> => {
  switch (action.type) {
    case ContentItemEditing_LoadTasksForItemVariant_Started:
    case YourTasks_Init_Started:
      return initialState;

    case ContentItemEditing_LoadTasksForItemVariant_Finished: {
      const tasks = action.payload.orderedTasks.map((task: Task) => [task.id, task] as const);
      return new Map(tasks);
    }

    case YourTasks_Init_Finished: {
      const tasks = action.payload.yourTasks.map((task: Task) => [task.id, task] as const);
      return new Map(tasks);
    }

    case ContentItemEditing_TaskArchive_Finished:
      return Collection.remove(state, action.payload.taskId);

    case ContentItemEditing_CreateTask_Finished:
      return Collection.add(state, [action.payload.task.id, action.payload.task]);

    case ContentItemEditing_UpdateTask_Finished:
      return Collection.add(state, [action.payload.task.id, action.payload.task]);

    case ContentItemEditing_TaskComplete_Started:
    case ContentItemEditing_TaskReopen_Failed:
      return Collection.replaceWith(state, action.payload.taskId, completeTask);

    case ContentItemEditing_TaskReopen_Started:
    case ContentItemEditing_TaskComplete_Failed:
      return Collection.replaceWith(state, action.payload.taskId, reopenTask);

    default: {
      return state;
    }
  }
};
