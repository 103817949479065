import { InputState } from '@kontent-ai/component-library/Input';
import { IBaseSelectItem, ISelectSection, ItemId } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { RefObject } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SelectorType } from '../constants/orderedSelectors.ts';
import { SelectorHeader } from './SelectorHeader.tsx';

type Props = {
  readonly disabledTooltip: string | undefined;
  readonly index: number | undefined;
  readonly inputRef?: RefObject<HTMLInputElement>;
  //  The selected type id might contain the section to ensure uniqueness within the SingleSelect sections
  readonly onTypeSelection?: (itemId: ItemId | null, item: IBaseSelectItem | null) => void;
  readonly selectedTypeId: ItemId | null;
  readonly selectItems:
    | ReadonlyArray<ISelectSection<IBaseSelectItem>>
    | ReadonlyArray<IBaseSelectItem>;
};

export const ContentTypeSelector: React.FC<Props> = ({
  disabledTooltip,
  index,
  inputRef,
  onTypeSelection,
  selectedTypeId,
  selectItems,
}) => (
  <Stack spacing={Spacing.L}>
    <SelectorHeader index={index} type={SelectorType.ContentType} />
    <SingleSelect
      aria-label="Select a content type"
      inputState={disabledTooltip ? InputState.Disabled : undefined}
      items={selectItems}
      onSelectionChange={onTypeSelection}
      placeholder="Select a content type"
      selectedItemId={selectedTypeId}
      tooltipText={disabledTooltip}
      verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.ContentTypes)}
      inputRef={inputRef}
      {...getDataUiCollectionAttribute(DataUiCollection.ContentTypes)}
    />
  </Stack>
);

ContentTypeSelector.displayName = 'ContentTypeSelector';
