import { InputState } from '@kontent-ai/component-library/Input';
import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { Collection } from '@kontent-ai/utils';
import { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { ValidatedMultipleOptionSelect } from '../../../../../_shared/components/input/ValidatedMultipleOptionSelect.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiCollection,
  DataUiInput,
  getDataUiCollectionAttribute,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getAllWorkflowSteps } from '../../../../../_shared/utils/workflow/getAllWorkflowSteps.ts';
import { EntityWebhookAction } from '../../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { anyWorkflowStepWorkflowStep } from '../../../constants/anyWorkflowStepWorkflowStep.ts';
import { IEntityWebhookFormShape } from '../../../models/IEntityWebhookFormShape.type.ts';
import { handleAnyWorkflowStepWorkflowStepOnWorkflowStepsChangedInFormInput } from '../../../utils/anyWorkflowStepWorkflowStepUtils.ts';
import { renderFilterOption } from '../../../utils/renderFilterOption.tsx';
import { EntityWebhookValidationError } from '../EntityWebhookValidationError.tsx';

type Props = {
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
};

export interface IStepSelectItem extends ISelectItem<IStepSelectItem> {}

export const EntityWebhookWorkflowStepFilterSelector = (props: Props) => {
  const workflowsById = useSelector((state) => state.data.workflows.byId);
  const workflows = useMemo(() => Collection.getValues(workflowsById), [workflowsById]);

  const { control } = props.formProps;
  const { field } = useController({ control, name: 'triggers.contentItemTrigger' });

  const options = useMemo((): ReadonlyArray<IStepSelectItem> => {
    const firstWorkflow = workflows[0];
    if (!firstWorkflow) {
      return [];
    }

    return [anyWorkflowStepWorkflowStep].concat(
      getAllWorkflowSteps(firstWorkflow).map((step) => ({
        type: 'item',
        id: step.id,
        label: step.name,
        workflowId: firstWorkflow.id,
      })),
    );
  }, [workflows]);

  const inputState =
    field.value.checked && field.value.actions.includes(EntityWebhookAction.WorkflowStepChanged)
      ? InputState.Default
      : InputState.Disabled;

  const isEmptyWorkflowFilter = field.value.workflowSteps.length < 1;

  const normalize = (
    formValues: readonly IStepSelectItem[],
    previousValues: readonly IStepSelectItem[],
  ): readonly IStepSelectItem[] => {
    const newValues = handleAnyWorkflowStepWorkflowStepOnWorkflowStepsChangedInFormInput(
      formValues,
      previousValues,
    );
    field.onChange({
      ...field.value,
      workflowSteps: newValues.map((option) => option.id),
    });
    return newValues;
  };

  return (
    <>
      <ValidatedMultipleOptionSelect<IEntityWebhookFormShape, IStepSelectItem>
        aria-label="Select an option"
        formProps={props.formProps}
        inputState={inputState}
        items={options}
        name="triggers.contentItemTrigger.workflowSteps"
        normalize={normalize}
        placeholder="Select workflow steps"
        renderSelectedOption={renderFilterOption(anyWorkflowStepWorkflowStep.id)}
        verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.WorkflowSteps)}
        {...getDataUiInputAttribute(DataUiInput.EntityWebhookWorkflowStep)}
      />
      {isEmptyWorkflowFilter && inputState === InputState.Default && (
        <EntityWebhookValidationError message="Please select at least one language." />
      )}
    </>
  );
};

EntityWebhookWorkflowStepFilterSelector.displayName = 'EntityWebhookWorkflowStepFilterSelector';
