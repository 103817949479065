import React, { useCallback } from 'react';
import { ControlShortcutTemplate } from '../../../../../_shared/constants/shortcutSymbols.ts';
import { DataUiRteAction } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WebLinkProperties } from '../../../../itemEditor/models/contentItemElements/richText/WebLinkProperties.type.ts';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, PluginProps, PluginState } from '../../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../../editorCore/types/EditorChangeReason.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { getLinkStatus } from '../../apiLimitations/api/editorLimitationUtils.ts';
import { DraftJsEditorPlugin } from '../../draftJs/DraftJsEditorPlugin.type.ts';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand } from '../../keyboardShortcuts/api/EditorCommand.ts';
import { GetLinkEntityComponent, GetLinkOptions, LinksPlugin } from '../LinksPlugin.type.ts';
import { isNewLinkOfType, isWebLink } from '../api/LinkEntity.ts';
import { LinkType } from '../api/LinkType.ts';
import { NewLinkType } from '../api/NewLinkType.ts';
import {
  convertOpenInNewWindow,
  isLinkAtSelection,
  isNewLinkAllowedAtSelection,
} from '../api/editorLinkUtils.ts';
import { AutomaticWebLinkConversionPlugin } from './AutomaticWebLinkConversionPlugin.tsx';
import { NewWebLink } from './components/NewWebLink.tsx';
import { WebLink } from './components/WebLink.tsx';

export type WebLinksPlugin = DraftJsEditorPlugin<
  None,
  None,
  None,
  None,
  [LinksPlugin, KeyboardShortcutsPlugin<RichTextInputCommand>, AutomaticWebLinkConversionPlugin]
>;

type UpdateWebLink = (entityKey: string, values: WebLinkProperties, allowUndo?: boolean) => void;

type CustomLinkProps = Pick<
  PluginState<WebLinksPlugin>,
  'editLink' | 'cancelNewLink' | 'linkEditingCancelled' | 'unlink' | 'getApi'
> &
  Pick<PluginProps<WebLinksPlugin>, 'disabled'> & {
    readonly updateWebLink: UpdateWebLink;
  };

type NewWebLinkEntityProps = EntityDecoratorProps<CustomLinkProps> & {
  readonly children: ReadonlyArray<React.ReactNode>;
};

const NewWebLinkEntity: React.FC<NewWebLinkEntityProps> = ({
  cancelNewLink,
  children,
  contentState,
  decoratedText,
  entityKey,
  updateWebLink,
}) => {
  const entity = contentState.getEntity(entityKey);
  if (!isNewLinkOfType(entity, NewLinkType.WebLink)) {
    return children;
  }

  const isPlaceholder = !!entity.getData().isPlaceholder;

  return (
    <NewWebLink
      linkText={decoratedText}
      isPlaceholder={isPlaceholder}
      key={entityKey}
      onCancel={() => cancelNewLink(entityKey, isPlaceholder)}
      onInsert={(values) => updateWebLink(entityKey, values, false)}
    >
      {children}
    </NewWebLink>
  );
};

NewWebLinkEntity.displayName = 'NewWebLinkEntity';

type WebLinkEntityProps = EntityDecoratorProps<CustomLinkProps> & {
  readonly children: ReadonlyArray<React.ReactNode>;
};

const WebLinkEntity: React.FC<WebLinkEntityProps> = ({
  blockKey,
  children,
  contentState,
  decoratedText,
  disabled,
  editLink,
  entityKey,
  getApi,
  linkEditingCancelled,
  unlink,
  updateWebLink,
}) => {
  const entity = contentState.getEntity(entityKey);
  if (!isWebLink(entity)) {
    return children;
  }

  const { url, openInNewWindow, title } = entity.getData();

  const status = getLinkStatus(
    {
      blockKey,
      contentState,
    },
    getApi().getLimitations(),
  );

  return (
    <WebLink
      disabled={disabled}
      key={entityKey}
      onCancel={() => linkEditingCancelled(entityKey)}
      onEdit={() => editLink(entityKey)}
      onUnlink={() => unlink(entityKey)}
      onUpdate={(values) => updateWebLink(entityKey, values)}
      openInNewWindow={convertOpenInNewWindow(openInNewWindow)}
      status={status}
      text={decoratedText}
      title={title}
      url={url}
    >
      {children}
    </WebLink>
  );
};

WebLinkEntity.displayName = 'WebLinkEntity';

export const WebLinksPlugin: PluginComponent<WebLinksPlugin> = (props) => {
  const { disabled } = props;

  const apply: Apply<WebLinksPlugin> = useCallback(
    (state) => {
      const updateWebLink: UpdateWebLink = (entityKey, values, allowUndo = true): void => {
        if (!state.canUpdateContent(EditorChangeReason.Internal)) {
          return;
        }

        state.executeChange((editorState) => {
          const selection = state.getApi().getSelectionForEntity(editorState, entityKey);
          if (selection) {
            const newEditorState = state
              .getApi()
              .createWebLink(editorState, selection, values, allowUndo);
            state.linkEditingFinished();
            return newEditorState;
          }
          return editorState;
        }, EditorChangeReason.Internal);
      };

      const getLinkEntityComponent: Decorator<GetLinkEntityComponent<CustomLinkProps>> =
        (baseGetLinkEntityComponent) => (linkType) => {
          const customLinkProps: CustomLinkProps = {
            disabled,
            cancelNewLink: state.cancelNewLink,
            unlink: state.unlink,
            editLink: state.editLink,
            getApi: state.getApi,
            linkEditingCancelled: state.linkEditingCancelled,
            updateWebLink,
          };

          switch (linkType) {
            case LinkType.Web:
              return {
                component: WebLinkEntity,
                props: customLinkProps,
              };

            case NewLinkType.WebLink:
              return {
                component: NewWebLinkEntity,
                props: customLinkProps,
              };

            default:
              return baseGetLinkEntityComponent(linkType);
          }
        };

      state.getLinkEntityComponent.decorate(getLinkEntityComponent);

      const newWebLink = (): void => {
        if (!state.canUpdateContent()) {
          return;
        }

        state.executeChange((editorState) => {
          const selection = editorState.getSelection();
          const content = editorState.getCurrentContent();
          if (isNewLinkAllowedAtSelection(content, selection)) {
            const newEditorState = selection.isCollapsed()
              ? state.getApi().createLinkPlaceholder(editorState, selection, NewLinkType.WebLink)
              : state.getApi().createNewWebLink(editorState, selection);
            if (editorState !== newEditorState) {
              const entityKey = newEditorState.getCurrentContent().getLastCreatedEntityKey();
              state.setEditedLinkEntityKey(entityKey);
              return newEditorState;
            }
          }
          return editorState;
        }, EditorChangeReason.Internal);
      };

      const getLinkOptions: Decorator<GetLinkOptions> = (baseGetLinkOptions) => () => [
        ...baseGetLinkOptions(),
        {
          name: 'Web URL',
          onClick: newWebLink,
          shortcuts: ControlShortcutTemplate('K'),
          uiAction: DataUiRteAction.AddWebLink,
        },
      ];

      state.getLinkOptions.decorate(getLinkOptions);

      const executeCommand: Decorator<ExecuteCommand<RichTextInputCommand>> =
        (baseExecuteCommand) => (command, isShiftPressed) => {
          switch (command) {
            case RichTextInputCommand.InsertLink: {
              const editorState = state.getEditorState();
              const content = editorState.getCurrentContent();
              const selection = editorState.getSelection();
              if (isLinkAtSelection(content, selection)) {
                state.unlinkAtSelection();
              } else {
                newWebLink();
              }
              return true;
            }

            default:
              return baseExecuteCommand(command, isShiftPressed);
          }
        };

      state.executeCommand.decorate(executeCommand);

      return {};
    },
    [disabled],
  );

  return useEditorWithPlugin(props, { apply });
};
