import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { DefaultTag } from '@kontent-ai/component-library/Tag';
import { Collection } from '@kontent-ai/utils';
import { useMemo } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  formatCurrentUserName,
  formatUserName,
  makeCurrentUserFirst,
  userLastNameComparer,
} from '../../../../../../../_shared/utils/usersUtils.ts';
import { ListingFilterCategorySelector } from '../../components/ListingFilterCategorySelector.tsx';

type Props = {
  readonly selectedContributors: ReadonlySet<Uuid>;
  readonly currentUserId: Uuid;
  readonly onContributorsSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
};

interface IContributorSelectItem extends ISelectItem<IContributorSelectItem> {
  readonly selectedOptionLabel: string;
}

export const ContributorsFilterSelector = (props: Props) => {
  const usersById = useSelector((state) => state.data.users.usersById);
  const users = useMemo(() => Collection.getValues(usersById), [usersById]);
  const label = 'Select a contributor';

  const sortedActiveNonVirtualUsers = useMemo(
    () =>
      users
        .filter((user) => !user.inactive)
        .filter((user) => !user.isVirtual)
        .sort(userLastNameComparer),
    [users],
  );

  const selectedOptionIds = useMemo(
    () =>
      sortedActiveNonVirtualUsers
        .filter((user) => props.selectedContributors.has(user.userId))
        .map((user) => user.userId),
    [props.selectedContributors, sortedActiveNonVirtualUsers],
  );

  const options = useMemo(
    () =>
      makeCurrentUserFirst(sortedActiveNonVirtualUsers, props.currentUserId).map(
        (projectContributor) => ({
          id: projectContributor.userId,
          label: formatCurrentUserName(props.currentUserId)(projectContributor),
          selectedOptionLabel: formatUserName(projectContributor),
        }),
      ),
    [props.currentUserId, sortedActiveNonVirtualUsers],
  );

  return users.length ? (
    <ListingFilterCategorySelector<IContributorSelectItem>
      title="Contributor"
      collection={DataUiCollection.Contributors}
      options={options}
      selectedOptionIds={selectedOptionIds}
      placeholder={label}
      ariaLabel={label}
      onChange={props.onContributorsSelectionChanged}
      renderSelectedOption={(_id, selectedItem, defaultTagProps) => (
        <DefaultTag {...defaultTagProps} label={selectedItem.selectedOptionLabel} />
      )}
    />
  ) : null;
};
