import React, { useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { RelatedItemDoesNotExistReason } from '../../../applications/itemEditor/constants/errorMessages.ts';
import { DataUiElement } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { NonExistentBarItemActions } from '../BarItems/Actions/NonExistentBarItemActions.tsx';
import { BarItemActions } from '../BarItems/BarItemActions.tsx';
import { BarItemTitle } from '../BarItems/BarItemTitle.tsx';

type Props = {
  readonly message: string;
};

export const NonexistentLinkedItemContent: React.FC<Props> = ({ message }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [exhaustiveTooltipNeeded, setExhaustiveTooltipNeeded] = useState<boolean>(false);

  useResizeObserver({
    ref: containerRef,
    onResize: () => {
      const containerEl = containerRef.current;
      if (!containerEl) {
        return;
      }

      const isTooltipNeeded = containerEl.offsetWidth < containerEl.scrollWidth;
      if (isTooltipNeeded !== exhaustiveTooltipNeeded) {
        setExhaustiveTooltipNeeded(isTooltipNeeded);
      }
    },
  });

  return (
    <>
      <BarItemActions>
        <NonExistentBarItemActions />
      </BarItemActions>
      <BarItemTitle
        dataUiElement={DataUiElement.MissingLinkedItem}
        titleStyle="primary-disabled"
        tooltipText={
          exhaustiveTooltipNeeded
            ? [message, RelatedItemDoesNotExistReason].join('\n')
            : RelatedItemDoesNotExistReason
        }
        ref={containerRef}
      >
        {message}
      </BarItemTitle>
    </>
  );
};
