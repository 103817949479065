import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { OptionalTooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing, colorTextLowEmphasis } from '@kontent-ai/component-library/tokens';
import { BarItemEditAction } from '../../../../_shared/components/BarItems/Actions/BarItemEditAction.tsx';
import { BarItemActions } from '../../../../_shared/components/BarItems/BarItemActions.tsx';
import { BarItemBar } from '../../../../_shared/components/BarItems/BarItemBar.tsx';
import { BarItemContent } from '../../../../_shared/components/BarItems/BarItemContent.tsx';
import { CodenameBarItemButton } from '../../../../_shared/components/Codename/CodenameBarItemButton.tsx';
import { OnSaveCodename } from '../../../../_shared/components/Codename/OnSaveCodename.type.ts';
import { SearchPhraseHighlighterElement } from '../../../../_shared/components/Highlighting/SearchPhraseHighlighterElement.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { findMatchingIndexes } from '../../../../_shared/utils/filter/nameFilterUtils.ts';
import { ICollection } from '../../../../data/models/collections/Collection.ts';
import { ISpace } from '../../../../data/models/space/space.ts';
import {
  DisabledEditingButtonTooltipText,
  WebSpotlightActiveForSpaceText,
} from '../constants/spacesUiConstants.ts';
import { getCollectionsText } from '../utils/getCollectionsText.ts';

type Props = {
  readonly areAllCollectionsSelected: boolean;
  readonly collections: ReadonlyArray<ICollection>;
  readonly isCodenameBeingSaved?: boolean;
  readonly isLinkedToActiveWebSpotlight: boolean;
  readonly onCodenameSave?: OnSaveCodename;
  readonly onEdit: (() => void) | undefined;
  readonly relatedCodeNames: ReadonlySet<string>;
  readonly searchPhrase: string;
  readonly showCollections: boolean;
  readonly space: ISpace;
};

export const CollapsedSpaceBar = ({
  areAllCollectionsSelected,
  collections,
  isCodenameBeingSaved,
  isLinkedToActiveWebSpotlight,
  onCodenameSave,
  onEdit,
  relatedCodeNames,
  searchPhrase,
  showCollections,
  space,
}: Props) => {
  return (
    <div>
      <div className="bar-item__wrapper">
        <div className="bar-item__pane">
          <BarItemBar isClickable={!!onEdit} dataUiObjectName={space.name}>
            <BarItemContent onClick={onEdit}>
              <Box width="100%">
                <Row alignX="start" spacingX={Spacing.L}>
                  <Column flexFactor={4} {...getDataUiElementAttribute(DataUiElement.SpaceBarName)}>
                    <OptionalTooltip placement="bottom-start" text={space.name}>
                      {searchPhrase ? (
                        <SearchPhraseHighlighterElement
                          searchPhrase={searchPhrase}
                          text={space.name}
                          findMatchingIndexes={findMatchingIndexes}
                        />
                      ) : (
                        space.name
                      )}
                    </OptionalTooltip>
                  </Column>
                  <Column flexFactor={3}>
                    {isLinkedToActiveWebSpotlight && (
                      <Box color={colorTextLowEmphasis}>
                        <OptionalTooltip
                          placement="bottom-start"
                          text={WebSpotlightActiveForSpaceText}
                        >
                          {WebSpotlightActiveForSpaceText}
                        </OptionalTooltip>
                      </Box>
                    )}
                  </Column>
                  {showCollections && (
                    <Column
                      flexFactor={3}
                      {...getDataUiElementAttribute(DataUiElement.SpaceBarCollections)}
                    >
                      {space.collectionIds.length > 0 && (
                        <Box color={colorTextLowEmphasis}>
                          <OptionalTooltip
                            placement="bottom-start"
                            text={getCollectionsText(collections, areAllCollectionsSelected, true)}
                          >
                            {getCollectionsText(collections, areAllCollectionsSelected)}
                          </OptionalTooltip>
                        </Box>
                      )}
                    </Column>
                  )}
                </Row>
              </Box>
            </BarItemContent>
            <BarItemActions>
              <CodenameBarItemButton
                codename={space.codeName}
                isBeingSaved={isCodenameBeingSaved}
                maxLength={ValidationConstants.SpaceCodenameMaxLength}
                mode="save"
                onCodenameSave={onCodenameSave}
                relatedCodenames={relatedCodeNames}
              />
              <BarItemEditAction
                disabled={!onEdit}
                disabledTooltipText={DisabledEditingButtonTooltipText}
                onClick={onEdit}
              />
            </BarItemActions>
          </BarItemBar>
        </div>
      </div>
    </div>
  );
};
