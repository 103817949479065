import { isElementFullyVisible } from '@kontent-ai/DOM';
import { CancellableExecutor, createDeferredFunctionWithCheck } from '@kontent-ai/utils';
import { useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { DeferredAutoFocus } from '../../../itemEditor/features/ContentItemEditing/components/DeferredAutoFocus.tsx';
import { useEditorStateCallbacks } from '../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import { Apply, PluginState, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { moveCaretToEditorEnd, moveCaretToEditorStart } from '../../utils/editorStateUtils.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { WrapperPlugin } from '../visuals/WrapperPlugin.tsx';

export interface IFocusable {
  readonly blur: () => void;
  readonly focus: () => void;
  readonly focusAtTheStart: () => void;
  readonly focusAtTheEnd: () => void;
}

type FocusPluginProps = {
  readonly autoFocus?: boolean;
  readonly autoFocusBehavior?: AutoFocusBehavior;
  readonly focusableRef?: React.Ref<IFocusable>;
  readonly tabIndex?: number;
};

type FocusPluginState = {
  readonly focus: () => void;
  readonly blur: () => void;
};

export type FocusPlugin = DraftJsEditorPlugin<
  FocusPluginState,
  FocusPluginProps,
  None,
  None,
  [WrapperPlugin]
>;

type EditorWithFocusHandlingProps = {
  readonly disabled?: boolean;
};

const EditorWithFocusHandling: DecoratedEditor<FocusPlugin, EditorWithFocusHandlingProps> = ({
  autoFocus,
  baseRender,
  disabled,
  state,
  tabIndex,
}) => {
  const { focus } = state;

  const stateWithTabIndex: PluginState<FocusPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      tabIndex: tabIndex ?? state.editorProps.tabIndex,
    },
  };

  return (
    <>
      {baseRender(stateWithTabIndex)}
      <DeferredAutoFocus
        autoFocus={!disabled && autoFocus}
        onAutoFocus={focus}
        scrollToComponentAfterAutoFocus
      />
    </>
  );
};

export enum AutoFocusBehavior {
  Normal = 'Normal',
  WhenInView = 'WhenInView',
}

export const FocusPlugin: PluginComponent<FocusPlugin> = ({
  autoFocusBehavior = AutoFocusBehavior.Normal,
  ...props
}) => {
  const { autoFocus, disabled, focusableRef, tabIndex } = props;

  const { decorateWithEditorStateCallbacks, executeChange, getEditorRef, getWrapperRef } =
    useEditorStateCallbacks<FocusPlugin>();

  const blur = useCallback(() => {
    focusWhenInViewRef.current?.cancel();
    getEditorRef().current?.blur();
  }, [getEditorRef]);

  // We need to pre-declare reference to focusWhenInView, as otherwise there would be cyclic reference between it and focus method
  const focusWhenInViewRef = useRef<CancellableExecutor<[]> | null>(null);

  const focus = useCallback(() => {
    focusWhenInViewRef.current?.cancel();
    getEditorRef().current?.focus();
  }, [getEditorRef]);

  const focusAtTheStart = useCallback(() => {
    focus();
    executeChange(moveCaretToEditorStart);
  }, [focus, executeChange]);

  const focusAtTheEnd = useCallback(() => {
    focus();
    executeChange(moveCaretToEditorEnd);
  }, [focus, executeChange]);

  useImperativeHandle(focusableRef, () => ({
    focus,
    blur,
    focusAtTheStart,
    focusAtTheEnd,
  }));

  const focusWhenInView = useMemo(
    () =>
      createDeferredFunctionWithCheck(
        () => {
          const wrapper = getWrapperRef().current;
          return !!wrapper && !isElementFullyVisible(wrapper);
        },
        10,
        focus,
      ),
    [focus, getWrapperRef],
  );
  focusWhenInViewRef.current = focusWhenInView;

  const autoFocusWhenInView = useRef(
    autoFocus && autoFocusBehavior === AutoFocusBehavior.WhenInView,
  );
  useEffect(() => {
    if (autoFocusWhenInView.current) {
      focusWhenInView();
    }
    return focusWhenInView.cancel;
  }, [focusWhenInView]);

  const render: Decorator<Render<FocusPlugin>> = useCallback(
    (baseRender) => (state) => (
      <EditorWithFocusHandling
        autoFocus={!!autoFocus && autoFocusBehavior === AutoFocusBehavior.Normal}
        baseRender={baseRender}
        disabled={disabled}
        state={state}
        tabIndex={tabIndex}
      />
    ),
    [disabled, autoFocus, tabIndex, autoFocusBehavior],
  );

  const apply: Apply<FocusPlugin> = useCallback(
    (state) => {
      decorateWithEditorStateCallbacks(state);

      state.render.decorate(render);

      return {
        blur,
        focus,
      };
    },
    [decorateWithEditorStateCallbacks, render, blur, focus],
  );

  return useEditorWithPlugin(props, { apply });
};
