import { Feature } from '../../../../../../@types/FeatureEnum.ts';
import { useIsUnifiedWebSpotlightEnabled } from '../../../../../_shared/hooks/useIsUnifiedWebSpotlightEnabled.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { isFeatureEnabled } from '../../../../../_shared/utils/featureToggles.ts';

export const useIsWebSpotlightFixedQuickActionsEnabled = (): boolean => {
  const isUnifiedWebSpotlightEnabled = useIsUnifiedWebSpotlightEnabled();
  const isFixedQuickActionEnabledForProject = useSelector(
    (s) => s.sharedApp.projectFeatureFlags.WebSpotlightFixedQuickActions,
  );

  return (
    isUnifiedWebSpotlightEnabled ||
    isFixedQuickActionEnabledForProject ||
    isFeatureEnabled(Feature.WebSpotlightFixedQuickActions)
  );
};
