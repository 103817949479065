import { Box } from '@kontent-ai/component-library/Box';
import { colorAlertText } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { BarItemTitle } from '../../../../../../_shared/components/BarItems/BarItemTitle.tsx';
import { CannotViewAssetsMessage } from '../../../../../contentInventory/content/constants/cannotViewMessages.ts';
import { RelatedItemDoesNotExistReason } from '../../../../../itemEditor/constants/errorMessages.ts';

export const NoPermissionsAssetLink: React.FC = () => (
  <BarItemTitle>{CannotViewAssetsMessage}</BarItemTitle>
);

export const NonExistingAssetLink: React.FC = () => (
  <BarItemTitle tooltipText={RelatedItemDoesNotExistReason}>
    Linked asset doesn’t exist
  </BarItemTitle>
);

type ArchivedAssetLinkProps = {
  readonly assetName: string;
};

export const ArchivedAssetLink: React.FC<ArchivedAssetLinkProps> = ({ assetName }) => (
  <BarItemTitle suffix={<Box color={colorAlertText}>(Asset deleted)</Box>}>
    {assetName}
  </BarItemTitle>
);

type ValidAssetLinkProps = {
  readonly assetName: string;
  readonly assetPath?: string;
  readonly disabled?: boolean;
  readonly onAssetClick: () => void;
};

export const ValidAssetLink: React.FC<ValidAssetLinkProps> = ({
  assetName,
  assetPath,
  disabled,
  onAssetClick,
}) => {
  const onAssetTextClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onAssetClick();
  };

  return (
    <a
      className="link-dialog__detail-link-pane"
      href={assetPath}
      target="_blank"
      rel="noopener noreferrer"
      title={assetName}
      onClick={disabled ? undefined : onAssetTextClick}
    >
      <div className="link-dialog__detail-link">{assetName}</div>
    </a>
  );
};
