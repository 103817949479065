import { combineReducers } from '@reduxjs/toolkit';
import { assetLibraryReducer } from '../../applications/contentInventory/assets/reducers/assetLibraryReducer.ts';
import { relationsAppReducer } from '../../applications/contentInventory/relations/reducers/relationsAppReducer.ts';
import { contentInventoryReducer } from '../../applications/contentInventory/shared/reducers/contentInventoryReducer.ts';
import { contentModelsAppReducer } from '../../applications/contentModels/shared/reducers/contentModelsReducer.ts';
import { sitemapAppReducer } from '../../applications/contentModels/sitemap/reducers/sitemapAppReducer.ts';
import { taxonomyAppReducer } from '../../applications/contentModels/taxonomy/reducers/taxonomyAppReducer.ts';
import { auditLogAppReducer } from '../../applications/environmentSettings/auditLog/reducers/auditLogAppReducer.ts';
import { collectionsAppReducer } from '../../applications/environmentSettings/collections/reducers/collectionsAppReducer.ts';
import { localizationReducer } from '../../applications/environmentSettings/localization/reducers/localizationReducer.ts';
import { rolesAppReducer } from '../../applications/environmentSettings/roles/reducers/rolesAppReducer.ts';
import { environmentSettingsAppReducer } from '../../applications/environmentSettings/root/reducers/environmentSettingsAppReducer.ts';
import { usersReducer } from '../../applications/environmentSettings/users/reducers/usersStateReducer.ts';
import { workflowsAppReducer } from '../../applications/environmentSettings/workflow/reducers/workflowsAppReducer.ts';
import { contentAppReducer } from '../../applications/itemEditor/reducers/editorUi/contentAppReducer.ts';
import { calendarAppReducer } from '../../applications/missionControl/calendar/reducers/calendarReducer.ts';
import { projectOverviewAppReducer } from '../../applications/missionControl/projectOverview/reducers/projectOverview.ts';
import { yourTasksAppReducer } from '../../applications/missionControl/reducers/yourTasksAppReducer.ts';
import { previewConfigurationAppReducer } from '../../applications/previewConfiguration/reducers/previewConfigurationAppReducer.ts';
import { apiKeysAppReducer } from '../../applications/projectSettings/apiKeys/reducers/apiKeysAppReducer.ts';
import { environmentsAppReducer } from '../../applications/projectSettings/environments/reducers/environmentsAppReducer.ts';
import { projectSettingsAppReducer } from '../../applications/projectSettings/root/reducers/projectSettingsAppReducer.ts';
import { projectsAppReducer } from '../../applications/projects/reducers/projectsAppReducer.ts';
import { smartLinkAppReducer } from '../../applications/smartLink/reducers/smartLinkAppReducer.ts';
import { subscriptionAppReducer } from '../../applications/subscriptionManagement/shared/reducers/subscriptionAppReducer.ts';
import { webSpotlightReducer } from '../../applications/webSpotlight/reducers/webSpotlightReducer.ts';
import { webhooksAppReducer } from '../../applications/webhooks/reducers/webhooksAppReducer.ts';
import { data } from '../../data/reducers/dataReducer.ts';
import { sharedStateReducer } from './sharedStateReducer.ts';

export const rootReducer = combineReducers({
  apiKeysApp: apiKeysAppReducer,
  assetLibraryApp: assetLibraryReducer,
  auditLogApp: auditLogAppReducer,
  calendarApp: calendarAppReducer,
  collectionsApp: collectionsAppReducer,
  contentApp: contentAppReducer,
  contentInventory: contentInventoryReducer,
  contentModelsApp: contentModelsAppReducer,
  data,
  environmentsApp: environmentsAppReducer,
  environmentSettingsApp: environmentSettingsAppReducer,
  localizationApp: localizationReducer,
  previewConfigurationApp: previewConfigurationAppReducer,
  projectOverviewApp: projectOverviewAppReducer,
  projectsApp: projectsAppReducer,
  projectSettingsApp: projectSettingsAppReducer,
  relationsApp: relationsAppReducer,
  rolesApp: rolesAppReducer,
  sharedApp: sharedStateReducer,
  sitemapApp: sitemapAppReducer,
  smartLinkApp: smartLinkAppReducer,
  subscriptionApp: subscriptionAppReducer,
  taxonomyApp: taxonomyAppReducer,
  usersApp: usersReducer,
  webhooksApp: webhooksAppReducer,
  webSpotlightApp: webSpotlightReducer,
  workflowsApp: workflowsAppReducer,
  yourTasksApp: yourTasksAppReducer,
});
