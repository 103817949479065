import { Icons } from '@kontent-ai/component-library/Icons';
import { forwardRef } from 'react';
import { DataUiAction } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { ITriggerProps } from '../../PopoverDialog/types/PopoverDialogContract.type.ts';
import { BarItemAction } from './BarItemAction.tsx';

export const BarItemCodenameAction = forwardRef<HTMLButtonElement, ITriggerProps>(
  ({ onToggle, isActive, ...otherProps }, ref) => (
    <BarItemAction
      ref={ref}
      dataUiActionName={DataUiAction.GetCodename}
      icon={Icons.BracesOctothorpe}
      isActive={isActive}
      onClick={onToggle}
      tooltipText="Codename"
      {...otherProps}
    />
  ),
);

BarItemCodenameAction.displayName = 'BarItemCodenameAction';
