import { ContentItemIdString } from '../../../../_shared/models/ContentItemId.ts';
import {
  ContentStatusCommentCounts_Load_Started,
  ContentStatusCommentCounts_Load_Success,
  ContentStatusTaskCounts_Load_Started,
  ContentStatusTaskCounts_Load_Success,
} from '../constants/contentStatusActionTypes.ts';

export type ContentStatusCommentOrTaskCountsStartedActionPayload = Readonly<{
  loadedContentItemIds: ReadonlyArray<ContentItemIdString>;
}>;

export const contentStatusCommentCountsStarted = (
  payload: ContentStatusCommentOrTaskCountsStartedActionPayload,
) =>
  ({
    type: ContentStatusCommentCounts_Load_Started,
    payload,
  }) as const;

export type ContentStatusCommentOrTaskCountsLoadedActionPayload = Readonly<{
  counts: ReadonlyArray<readonly [ContentItemIdString, number]>;
}>;

export const contentStatusCommentCountsLoaded = (
  payload: ContentStatusCommentOrTaskCountsLoadedActionPayload,
) =>
  ({
    type: ContentStatusCommentCounts_Load_Success,
    payload,
  }) as const;

export const contentStatusTaskCountsStarted = (
  payload: ContentStatusCommentOrTaskCountsStartedActionPayload,
) =>
  ({
    type: ContentStatusTaskCounts_Load_Started,
    payload,
  }) as const;

export const contentStatusTaskCountsLoaded = (
  payload: ContentStatusCommentOrTaskCountsLoadedActionPayload,
) =>
  ({
    type: ContentStatusTaskCounts_Load_Success,
    payload,
  }) as const;

export type LoadContentStatusCommentOrTaskCountsActionsType = ReturnType<
  | typeof contentStatusCommentCountsStarted
  | typeof contentStatusCommentCountsLoaded
  | typeof contentStatusTaskCountsStarted
  | typeof contentStatusTaskCountsLoaded
>;
