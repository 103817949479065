import styled, { css } from 'styled-components';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { dividerBorderWidth } from '../decisionTokens.ts';

export enum DividerDirection {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

interface IStyledDividerProps {
  readonly $direction: DividerDirection;
  readonly $color: BaseColor;
  readonly offsetBefore: Spacing;
}

interface IStyledDividerWrapperProps extends Pick<IStyledDividerProps, '$direction'> {
  readonly offsetAfter: Spacing;
}

const getDividerWrapperStyles = ({ $direction, offsetAfter }: IStyledDividerWrapperProps) => {
  switch ($direction) {
    case DividerDirection.Vertical:
      return css`
        padding-right: ${px(offsetAfter)};
        flex-direction: initial;
        min-height: 100%;
      `;
    case DividerDirection.Horizontal:
      return css`
        padding-bottom: ${px(offsetAfter)};
        flex-direction: column;
      `;
  }
};

const getDividerStyles = ({ $direction, offsetBefore, $color }: IStyledDividerProps) => {
  const borderStyle = `${px(dividerBorderWidth)} solid ${$color}`;
  switch ($direction) {
    case DividerDirection.Vertical:
      return css`
        padding-left: ${px(offsetBefore)};
        border-right: ${borderStyle};
      `;
    case DividerDirection.Horizontal:
      return css`
        padding-top: ${px(offsetBefore)};
        border-bottom: ${borderStyle};
      `;
  }
};

export const StyledDividerWrapper = styled.div<IStyledDividerWrapperProps>`
  display: flex;
  ${getDividerWrapperStyles};
`;

export const StyledDivider = styled.div<IStyledDividerProps>`
  ${getDividerStyles};
`;
