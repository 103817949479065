import { Icons } from '@kontent-ai/component-library/Icons';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { memoize } from '@kontent-ai/memoization';
import { notNull, notUndefined } from '@kontent-ai/utils';
import classNames from 'classnames';
import React, { memo, useContext, useMemo, useRef, useState } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { CreateAutoScrollId } from '../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { BarItemAction } from '../../../../../../_shared/components/BarItems/Actions/BarItemAction.tsx';
import { BarItemDragAction } from '../../../../../../_shared/components/BarItems/Actions/BarItemDragAction.tsx';
import { BarItemToggleAction } from '../../../../../../_shared/components/BarItems/Actions/BarItemToggleAction.tsx';
import { BarItemActions } from '../../../../../../_shared/components/BarItems/BarItemActions.tsx';
import { BarItemBar } from '../../../../../../_shared/components/BarItems/BarItemBar.tsx';
import { BarItemTitle } from '../../../../../../_shared/components/BarItems/BarItemTitle.tsx';
import { EditorPaperContext } from '../../../../../../_shared/contexts/EditorPaperContext.tsx';
import { useAutoScroll } from '../../../../../../_shared/hooks/useAutoScroll.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiComponentIdAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getReachedPaperThresholds } from '../../../../../../_shared/utils/editorViewContext/editorPaperContext/utils/getReachedPaperThresholds.ts';
import { ILanguage } from '../../../../../../data/models/languages/Language.ts';
import { getDefaultLanguage } from '../../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { useItemVariantId } from '../../../../../itemEditor/features/ContentComponent/context/ContentItemContext.tsx';
import { useDeliveryContentComponentId } from '../../../../../itemEditor/features/ContentComponent/hooks/useDeliveryContentComponentId.tsx';
import { ContentNestingContext } from '../../../../../itemEditor/features/ContentItemEditing/context/ContentNestingContext.tsx';
import { IBaseTextItemElement } from '../../../../../itemEditor/models/contentItemElements/IBaseTextItemElement.type.ts';
import { ICompiledContentItemElementData } from '../../../../../itemEditor/models/contentItemElements/ICompiledContentItemElement.type.ts';
import {
  isRichTextElement,
  isTextElement,
} from '../../../../../itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { useContentComponent } from '../../../../hooks/useContentComponent.ts';
import { useContentComponentType } from '../../../../hooks/useContentComponentType.ts';
import { exportToServerPlainText } from '../../../../utils/export/plainText/exportToPlainText.ts';
import { ContentComponentConversionConfirmationDialog } from '../../containers/dialogs/ContentComponentConversionConfirmationDialog.tsx';
import {
  CollapsedContentComponentCssClass,
  ContentComponentCssClass,
  ContentComponentHeaderCssClass,
} from '../../utils/contentComponentRenderingUtils.ts';
import { ContentComponentDeleteConfirmationDialog } from '../dialogs/ContentComponentDeleteConfirmationDialog.tsx';

interface IContentComponentAutoScrollProps {
  readonly componentRef: React.RefObject<HTMLElement>;
}

const ContentComponentAutoScroll: React.FC<IContentComponentAutoScrollProps> = ({
  componentRef,
}) => {
  const deliveryContentComponentId = useDeliveryContentComponentId();
  const { itemId } = useItemVariantId();
  const scrollId = CreateAutoScrollId.forContentComponent(deliveryContentComponentId ?? '', itemId);
  useAutoScroll({
    scrollId,
    scrollTargetRef: componentRef,
  });

  return null;
};
ContentComponentAutoScroll.displayName = 'ContentComponentAutoScroll';

export interface IContentComponentItemStateProps {
  readonly canCreateContent: boolean;
  readonly canUpdate: boolean;
  readonly isCollapsed: boolean;
  readonly isConversionPossible?: boolean;
  readonly isEntryTypeAllowed: boolean;
}

export interface IContentComponentItemDispatchProps {
  readonly onCollapse: (contentComponentId: Uuid) => void;
  readonly onExpand: (contentComponentId: Uuid) => void;
}

export interface IContentComponentItemOwnProps {
  readonly connectDragSource?: ConnectDragSource;
  readonly contentComponentId: Uuid;
  readonly isDragging: boolean;
  readonly onConvertComponentToItemVariant?: (selectedWorkflowId?: Uuid) => void;
  readonly onDelete: () => void;
  readonly renderExpanded?: () => React.ReactNode;
}

interface IContentComponentItemProps
  extends IContentComponentItemStateProps,
    IContentComponentItemDispatchProps,
    IContentComponentItemOwnProps {}

export const ContentComponentItem: React.FC<IContentComponentItemProps> = memo(
  ({
    canCreateContent,
    canUpdate,
    connectDragSource,
    contentComponentId,
    isCollapsed,
    isConversionPossible,
    isDragging,
    isEntryTypeAllowed,
    onCollapse,
    onConvertComponentToItemVariant,
    onDelete,
    onExpand,
    renderExpanded,
  }) => {
    const defaultLanguage = useSelector(getDefaultLanguage);

    const [isConvertConfirmationPending, setIsConvertConfirmationPending] = useState(false);
    const [isDeleteConfirmationPending, setIsDeleteConfirmationPending] = useState(false);

    const { isTopLevel } = useContext(ContentNestingContext);
    const paperContext = useContext(EditorPaperContext);
    const reachedThresholds = getReachedPaperThresholds(paperContext.thresholds);
    const displayContentType = reachedThresholds.sizeL || reachedThresholds.sizeM;
    const contentComponent = useContentComponent(contentComponentId);
    const contentType = useContentComponentType(contentComponentId);
    const contentComponentTitle = useMemo(
      () =>
        contentComponent?.elements &&
        contentType?.contentElements
          .map((typeElement) => getElementById(typeElement.elementId, contentComponent.elements))
          .filter(notUndefined)
          .map(getTitle)
          .find(notNull),
      [contentComponent?.elements, contentType?.contentElements],
    );
    const hasElementsForTitle =
      !!contentComponentTitle || !!contentComponent?.elements.some(isElementForTitle);

    const ref = useRef<HTMLDivElement>(null);

    const isExpanded = !isCollapsed && !isDragging && !!renderExpanded;

    const hideConvertConfirmationDialog = () => {
      setIsConvertConfirmationPending(false);
    };

    const hideDeleteConfirmationDialog = () => {
      setIsDeleteConfirmationPending(false);
    };

    const handleConvertComponentToItemVariant = (selectedWorkflowId?: Uuid) => {
      hideConvertConfirmationDialog();
      onConvertComponentToItemVariant?.(selectedWorkflowId);
    };

    const handleConvertButtonClicked = () => {
      setIsConvertConfirmationPending(!isConvertConfirmationPending);
    };

    const handleDeleteComponentToItemVariant = () => {
      hideDeleteConfirmationDialog();
      onDelete();
    };

    const handleDeleteButtonClicked = () => {
      setIsDeleteConfirmationPending(!isDeleteConfirmationPending);
    };

    const toggleCollapse = () => {
      const action = isCollapsed ? onExpand : onCollapse;
      action(contentComponentId);
    };

    return (
      <div
        className={classNames(ContentComponentCssClass, 'bar-item__pane', {
          [CollapsedContentComponentCssClass]: !isExpanded,
          'content-component--is-disabled': !canUpdate,
          'content-component--is-dragging': isDragging,
          'content-component--is-top-level': isTopLevel,
          'content-component--is-not-allowed': !isEntryTypeAllowed,
          'content-component--has-content-groups': !contentType?.contentGroups.isEmpty(),
        })}
        ref={ref}
        {...getDataUiElementAttribute(DataUiElement.ContentComponentItem)}
        {...getDataUiComponentIdAttribute(contentComponentId)}
      >
        <ContentComponentAutoScroll componentRef={ref} />
        <BarItemBar
          className={ContentComponentHeaderCssClass}
          isCompact={reachedThresholds.sizeXS}
          isExpanded={isExpanded}
        >
          <BarItemActions isCompact={reachedThresholds.sizeXS}>
            {canUpdate && <BarItemDragAction connectDragSource={connectDragSource} />}
            <BarItemToggleAction
              ariaLabel="content component"
              isCollapsed={!isExpanded}
              onExpand={toggleCollapse}
              onCollapse={toggleCollapse}
            />
          </BarItemActions>
          <BarItemTitle
            dataUiElement={DataUiElement.ContentComponentTitle}
            onClick={toggleCollapse}
            suffix={
              <Tooltip
                tooltipText={createTitleTooltip(hasElementsForTitle, contentComponentTitle)}
                placement="top"
              >
                <Icons.ICircle />
              </Tooltip>
            }
            title={contentComponentTitle ?? createNoTitleFallback(hasElementsForTitle)}
            titleStyle={contentComponentTitle ? 'primary' : 'primary-disabled'}
          >
            {contentComponentTitle ?? createNoTitleFallback(hasElementsForTitle)}
          </BarItemTitle>
          {displayContentType && (
            <BarItemTitle
              dataUiElement={DataUiElement.ContentComponentType}
              onClick={toggleCollapse}
              title={contentType?.name}
              titleStyle={isEntryTypeAllowed ? 'secondary' : 'secondary-alert'}
            >
              {contentType?.name}
            </BarItemTitle>
          )}
          <BarItemActions isCompact={reachedThresholds.sizeXS}>
            {canUpdate && canCreateContent && handleConvertComponentToItemVariant && (
              <ContentComponentConversionConfirmationDialog
                contentTypeId={contentType?.id}
                isOpen={isConvertConfirmationPending}
                onCancel={hideConvertConfirmationDialog}
                onConvert={handleConvertComponentToItemVariant}
                renderTarget={(targetProps) => {
                  const tooltipText = getConversionActionTooltip(
                    isConvertConfirmationPending,
                    isConversionPossible,
                    defaultLanguage,
                  );

                  return (
                    <BarItemAction
                      disabled={!isConversionPossible}
                      dataUiActionName={
                        DataUiAction.ShowContentComponentConversionConfirmationDialog
                      }
                      tooltipText={tooltipText}
                      icon={Icons.Convert}
                      isActive={isConvertConfirmationPending}
                      onClick={handleConvertButtonClicked}
                      {...targetProps}
                    />
                  );
                }}
              />
            )}
            {canUpdate && (
              <ContentComponentDeleteConfirmationDialog
                onDelete={handleDeleteComponentToItemVariant}
                onCancel={hideDeleteConfirmationDialog}
                isOpen={isDeleteConfirmationPending}
                renderTarget={(targetProps) => (
                  <BarItemAction
                    dataUiActionName={DataUiAction.Delete}
                    icon={Icons.Bin}
                    isActive={isDeleteConfirmationPending}
                    isDestructive
                    onClick={handleDeleteButtonClicked}
                    tooltipText={isDeleteConfirmationPending ? '' : 'Delete'}
                    {...targetProps}
                  />
                )}
              />
            )}
          </BarItemActions>
        </BarItemBar>
        {isExpanded && renderExpanded?.()}
      </div>
    );
  },
);

ContentComponentItem.displayName = 'ContentComponentItem';

const createTitleTooltip = (
  hasElementsValidForTitle: boolean,
  title: string | undefined,
): string => {
  if (!hasElementsValidForTitle) {
    return 'Would you like to see a preview of what’s inside? Let us know via chat.';
  }
  if (!title) {
    return 'Add some content to a text element to see the text preview.';
  }
  return 'This is a sneak peek of what’s inside the component.';
};

const createNoTitleFallback = (hasElementsValidForTitle: boolean): string =>
  hasElementsValidForTitle
    ? 'No text preview available.'
    : 'Expand the component to see what’s inside.';

const isElementForTitle = (
  element: ICompiledContentItemElementData,
): element is IBaseTextItemElement => isTextElement(element) || isRichTextElement(element);

const getTitle = memoize.weak((element: ICompiledContentItemElementData): string | null =>
  isElementForTitle(element)
    ? (exportToServerPlainText(element._editorState.getCurrentContent())
        .split('\n')
        .map((l) => l.trim())
        .find((l) => l) ?? null)
    : null,
);

const getConversionActionTooltip = (
  isConvertConfirmationPending: boolean,
  isConversionPossible: boolean | undefined,
  defaultLanguage: ILanguage,
) => {
  if (isConvertConfirmationPending) return '';
  return isConversionPossible
    ? 'Reuse as content item'
    : `Components with non-localizable elements can be converted only in ${defaultLanguage.name}.`;
};
