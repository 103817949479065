import { noOperation } from '@kontent-ai/utils';
import React, { createContext, useState, useMemo, useContext, useEffect, useCallback } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { shallowEqual } from '../../../../../_shared/utils/shallowEqual.ts';
import { getCurrentProjectId } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ItemEditorLayoutStorage } from '../../../../../localStorages/itemEditorLayoutStorage.ts';
import { SpaceSelectorInWebSpotlightStorage } from '../../../../../localStorages/spaceSelectorInWebSpotlightStorage.ts';
import { getAvailableSpacesForUser } from '../selectors/getAvailableSpacesForUser.ts';

type EditorContextState = Readonly<{
  isPreviewOpened: boolean;
  setIsPreviewOpened: (newState: boolean) => void;
  selectedSpaceId: Uuid | null;
  setSelectedSpaceId: (newState: Uuid) => void;
}>;

const EditorContext = createContext<EditorContextState>({
  isPreviewOpened: false,
  setIsPreviewOpened: noOperation,
  selectedSpaceId: null,
  setSelectedSpaceId: noOperation,
});

export const EditorContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const currentEnvironmentId = useSelector(getCurrentProjectId);
  const availableSpaces = useSelector(getAvailableSpacesForUser, shallowEqual);

  const isPreviewOpenedInitialState =
    ItemEditorLayoutStorage.load(currentEnvironmentId).isPreviewOpened;

  const [isPreviewOpened, setIsPreviewOpened] = useState(isPreviewOpenedInitialState);
  const [selectedSpaceId, setSelectedSpaceId] = useState<Uuid | null>(null);

  const saveSpaceIdToLocalStorage = useCallback(
    (spaceId: Uuid): void => {
      SpaceSelectorInWebSpotlightStorage.save(spaceId, currentEnvironmentId);
    },
    [currentEnvironmentId],
  );

  const saveIsPreviewOpenedToLocalStorage = useCallback(
    (previewState: boolean): void => {
      ItemEditorLayoutStorage.save({ isPreviewOpened: previewState }, currentEnvironmentId);
    },
    [currentEnvironmentId],
  );

  useEffect(() => {
    const lastSelectedSpaceId = SpaceSelectorInWebSpotlightStorage.load(currentEnvironmentId);

    if (lastSelectedSpaceId && availableSpaces.find((s) => s.id === lastSelectedSpaceId)) {
      setSelectedSpaceId(lastSelectedSpaceId);
      return;
    }

    if (availableSpaces[0]) {
      setSelectedSpaceId(availableSpaces[0].id);
      saveSpaceIdToLocalStorage(availableSpaces[0].id);
    }
  }, [currentEnvironmentId, availableSpaces, saveSpaceIdToLocalStorage]);

  const state: EditorContextState = useMemo(
    () => ({
      isPreviewOpened,
      setIsPreviewOpened: (previewState: boolean) => {
        setIsPreviewOpened(previewState);
        saveIsPreviewOpenedToLocalStorage(previewState);
      },
      selectedSpaceId,
      setSelectedSpaceId: (spaceId: Uuid) => {
        setSelectedSpaceId(spaceId);
        saveSpaceIdToLocalStorage(spaceId);
      },
    }),
    [
      isPreviewOpened,
      saveIsPreviewOpenedToLocalStorage,
      saveSpaceIdToLocalStorage,
      selectedSpaceId,
    ],
  );

  return <EditorContext.Provider value={state}>{children}</EditorContext.Provider>;
};

export const useEditorContext = () => useContext(EditorContext);
