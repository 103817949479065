import { ILoaderProps, Loader } from './Loader.tsx';

export type AccesibleLoaderProps = ILoaderProps & {
  readonly screenReaderOnly?: boolean;
  readonly screenReaderText: string;
};

export const AccessibleLoader = (props: AccesibleLoaderProps) => {
  const { screenReaderText, screenReaderOnly, ...loaderProps } = props;
  return (
    <div role="alert" aria-label={screenReaderText}>
      {!screenReaderOnly && <Loader {...loaderProps} />}
    </div>
  );
};
