import { Identifier } from 'dnd-core';
import { ContentBlock } from 'draft-js';
import { AllRichTextObjectBlocks, DndTypes } from '../../../../../_shared/constants/dndTypes.ts';
import { isNestedBlockType } from '../../../utils/blocks/blockType.ts';
import { getFullBlockType } from '../../../utils/blocks/editorBlockGetters.ts';

export function getAcceptedDropTypes(block: ContentBlock): Identifier | ReadonlyArray<Identifier> {
  return isNestedBlockType(getFullBlockType(block))
    ? DndTypes.Rich_Text_Image
    : AllRichTextObjectBlocks;
}
