import React, { useContext } from 'react';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { Icon } from '../../Icons/types.ts';
import { TagContext } from '../TagContext.tsx';

type Props = {
  readonly icon: Icon;
};

export const TagIcon: React.FC<Props> = ({ icon, ...otherProps }) => {
  const context = useContext(TagContext);

  if (!context) {
    throw new Error('Tag.Icon must be used within a Tag component');
  }

  const IconComponent = icon;

  return (
    <IconComponent
      color={context.iconColor}
      size={IconSize.S}
      {...(otherProps as Omit<Props, 'color'>)}
    />
  );
};

TagIcon.displayName = 'TagIcon';
