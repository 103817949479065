import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Option, RenderSelectMenuOptionProps } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WebSpotlightResolutionSelectMaxWidth } from '../../../constants/uiConstants.ts';
import {
  IWebSpotlightPreviewResolutionTypeOption,
  WebSpotlightPreviewResolutionType,
  webSpotlightPreviewResolutionTypeIcons,
} from '../../../models/webSpotlightPreviewResolutionType.ts';

const renderPrefix = (resolutionType: WebSpotlightPreviewResolutionType): React.ReactNode => {
  const iconName = webSpotlightPreviewResolutionTypeIcons[resolutionType];

  if (!iconName) {
    return null;
  }

  const Icon = Icons[iconName];
  return <Icon />;
};

const renderMenuOption = (
  optionProps: RenderSelectMenuOptionProps<IWebSpotlightPreviewResolutionTypeOption>,
): React.ReactNode => {
  const {
    item: { value: option },
  } = optionProps;

  return (
    option && (
      <Option
        leadingElement={renderPrefix(option.id)}
        complementaryText={option.complementaryText}
        {...optionProps}
      />
    )
  );
};

interface IWebSpotlightPreviewResolutionTypeSelectProps {
  readonly onSelect: (type: WebSpotlightPreviewResolutionType) => void;
  readonly resolutionTypes: ReadonlyArray<IWebSpotlightPreviewResolutionTypeOption>;
  readonly selectedResolutionType: WebSpotlightPreviewResolutionType;
}

export const WebSpotlightPreviewResolutionTypeSelect: React.FC<
  IWebSpotlightPreviewResolutionTypeSelectProps
> = ({ onSelect, resolutionTypes, selectedResolutionType }) => (
  <Box maxWidth={WebSpotlightResolutionSelectMaxWidth}>
    <SingleSelect<IWebSpotlightPreviewResolutionTypeOption>
      aria-label="Resolution type"
      items={resolutionTypes}
      onSelectionChange={onSelect}
      renderMenuOption={renderMenuOption}
      renderPrefix={renderPrefix}
      selectedItemId={selectedResolutionType}
      {...getDataUiElementAttribute(DataUiElement.WebSpotlightResolutionTypeSelect)}
    />
  </Box>
);

WebSpotlightPreviewResolutionTypeSelect.displayName = 'WebSpotlightPreviewResolutionTypeSelect';
