import { useAttachRef } from '@kontent-ai/hooks';
import { useTabList } from '@react-aria/tabs';
import { AriaTabListProps } from '@react-types/tabs';
import React from 'react';
import { Box } from '../../../layout/Box/Box.tsx';
import { Inline } from '../../../layout/Inline/Inline.tsx';
import { Column } from '../../../layout/Row/Column.tsx';
import { Row } from '../../../layout/Row/Row.tsx';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { borderWidthDefault } from '../../../tokens/decision/border.ts';
import { colorBorderLowEmphasis } from '../../../tokens/decision/colors.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { TabItem, useTabViewState } from '../utils.ts';
import { tabHeight } from './Tab.tsx';

const FullWidthTabGroup = React.forwardRef<
  HTMLDivElement,
  {
    readonly children: React.ReactNode;
    readonly keys: readonly React.Key[];
  }
>(({ children, keys, ...otherProps }, ref) => (
  <Row ref={ref} spacing={Spacing.XXS} {...otherProps}>
    {React.Children.map(
      children,
      (child, index) =>
        child && (
          <Column flexFactor={1} key={keys[index]}>
            {child}
          </Column>
        ),
    )}
  </Row>
));

FullWidthTabGroup.displayName = 'FullWidthTabGroup';

type TabGroupProps = Omit<AriaTabListProps<TabItem>, 'children'> & {
  readonly fullWidthTabs?: boolean;
  readonly keys: readonly React.Key[];
};

export const TabGroup = React.forwardRef<HTMLDivElement, React.PropsWithChildren<TabGroupProps>>(
  ({ fullWidthTabs, children, keys, ...otherProps }, ref) => (
    <Box
      ref={ref}
      css={`border-bottom: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis}`}
      {...getDataUiComponentAttribute(TabGroup)}
      {...otherProps}
    >
      {fullWidthTabs ? (
        <FullWidthTabGroup keys={keys} {...getDataUiComponentAttribute(TabGroup)}>
          {children}
        </FullWidthTabGroup>
      ) : (
        <Inline spacing={Spacing.XXS} {...getDataUiComponentAttribute(TabGroup)}>
          {children}
        </Inline>
      )}
    </Box>
  ),
);

TabGroup.displayName = 'TabGroup';

type TabGroupWithStateProps = {
  readonly renderAuxElement?: () => React.ReactNode;
};

export const TabGroupWithState = React.forwardRef<HTMLElement, TabGroupWithStateProps>(
  ({ renderAuxElement, ...otherProps }, ref) => {
    const { refToForward, refObject } = useAttachRef(ref);
    const { tabListState, tabListProps: props, fullWidthTabs } = useTabViewState();
    const { tabListProps } = useTabList(props, tabListState, refObject);
    const tabs = Array.from(tabListState.collection);
    const keys = Array.from(tabListState.collection.getKeys());

    return (
      <TabGroup
        keys={keys}
        ref={refToForward}
        fullWidthTabs={fullWidthTabs}
        {...tabListProps}
        {...otherProps}
      >
        {tabs.map(
          (tab, index) =>
            tab.value && (
              <Tooltip tooltipText={tab.value.tooltipText} placement="top" key={tab.value.id}>
                <Box
                  minWidth={0}
                  paddingRight={
                    !!renderAuxElement && index === tabs.length - 1 ? Spacing.M : undefined
                  }
                  display="flex"
                  alignItems="center"
                  columnGap={Spacing.S}
                >
                  <SrOnly>{tab.value.leadingElement}</SrOnly>
                  {tab.rendered}
                  <SrOnly>{tab.value.trailingElement}</SrOnly>
                </Box>
              </Tooltip>
            ),
        )}
        {renderAuxElement && (
          <Box height={tabHeight} display="flex" alignItems="center">
            {renderAuxElement()}
          </Box>
        )}
      </TabGroup>
    );
  },
);

TabGroupWithState.displayName = 'TabGroupWithState';
