import { Action } from '../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import {
  YourTasks_Init_Started,
  YourTasks_LoadRelatedItems_Finished,
} from '../../../../applications/missionControl/widgets/tasksAssignedToYou/constants/yourTasksActionTypes.ts';

export const loadingStatus = (
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case YourTasks_Init_Started:
      return LoadingStatus.Loading;

    case YourTasks_LoadRelatedItems_Finished:
      return LoadingStatus.Loaded;

    default:
      return state;
  }
};
