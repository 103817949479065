import { useEnsuredContext } from '@kontent-ai/hooks';
import React from 'react';
import styled from 'styled-components';
import { Box } from '../../../layout/Box/Box.tsx';
import { Column } from '../../../layout/Row/Column.tsx';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { BreadcrumbsContext } from '../BreadcrumbsContext.tsx';

const StyledBox = styled(Box)`
  &:empty {
    /* so  that there is no empty space when child component renders null (e.g. saving status) */
    display: none; 
  }
`;

export const BreadcrumbsCell = React.forwardRef<HTMLDivElement, React.PropsWithChildren>(
  ({ children, ...restProps }, forwardedRef) => {
    const { allowEllipsis } = useEnsuredContext(BreadcrumbsContext);

    return (
      <Column width={allowEllipsis ? 'fit-content' : 'content'} ref={forwardedRef} {...restProps}>
        <StyledBox paddingX={Spacing.XS}>{children}</StyledBox>
      </Column>
    );
  },
);

BreadcrumbsCell.displayName = 'BreadcrumbsCell';
