import { Inline } from '@kontent-ai/component-library/Inline';
import { BorderRadius, Size, Spacing, px } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { getDataUiObjectNameAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';

const StyledBarItemBar = styled(Inline)<{
  readonly $isCompact: boolean;
  readonly $isExpanded: boolean;
}>`
  position: relative;
  width: 100%;
  height: ${({ $isExpanded }) => px($isExpanded ? Size.XL : Size.L)};
  padding: 0 ${({ $isCompact }) => px($isCompact ? Spacing.S : Spacing.L)};
  border-radius: ${px(BorderRadius.M)};
`;

type Props = {
  readonly children?: ReactNode;
  readonly className?: string;
  readonly dataUiObjectName?: string;
  readonly isDragging?: boolean;
  readonly isClickable?: boolean;
  readonly isCompact?: boolean;
  readonly isExpanded?: boolean;
};

export const BarItemBar = ({
  children,
  className,
  dataUiObjectName,
  isClickable,
  isCompact,
  isDragging,
  isExpanded,
}: Props) => (
  <StyledBarItemBar
    align="center"
    className={classNames('bar-item__bar', className, {
      'bar-item__bar--is-dragging': isDragging,
      'bar-item__bar--is-clickable': !isDragging && isClickable,
    })}
    noWrap
    spacing={isCompact ? Spacing.S : Spacing.L}
    $isCompact={isCompact}
    $isExpanded={isExpanded}
    {...(dataUiObjectName && getDataUiObjectNameAttribute(dataUiObjectName))}
  >
    {children}
  </StyledBarItemBar>
);
