import React, { ComponentProps, ReactElement } from 'react';
import styled from 'styled-components';
import { Box } from '../../layout/Box/Box.tsx';
import { Column } from '../../layout/Row/Column.tsx';
import { Row } from '../../layout/Row/Row.tsx';
import { BreadcrumbsContextProvider } from './BreadcrumbsContext.tsx';
import { BreadcrumbsCell } from './components/BreadcrumbsCell.tsx';
import { BreadcrumbsItems } from './components/BreadcrumbsItems.tsx';

type Props = {
  readonly children:
    | ReactElement<ComponentProps<typeof Column>>
    | ReadonlyArray<ReactElement<ComponentProps<typeof Column>>>;
};

const StyledRow = styled(Row)`
  flex: 1 1 auto;
  min-width: 0;
`;

const Component = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...otherProps }, forwardedRef) => (
    <BreadcrumbsContextProvider>
      <Box display="inline-flex" minWidth={0} ref={forwardedRef} {...otherProps}>
        <StyledRow alignY="center" noWrap>
          {children}
        </StyledRow>
      </Box>
    </BreadcrumbsContextProvider>
  ),
);

Component.displayName = 'Breadcrumbs';

const breadcrumbsComposition = {
  Items: BreadcrumbsItems,
  Cell: BreadcrumbsCell,
} as const;

export const Breadcrumbs = Object.assign(Component, breadcrumbsComposition);
