import { DraftDecorator } from 'draft-js';
import React, { useCallback } from 'react';
import { useEditorStateCallbacks } from '../../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { DraftJsEditorPlugin } from '../../draftJs/DraftJsEditorPlugin.type.ts';
import { GetInitialState } from '../../draftJs/DraftJsPlugin.type.ts';
import { EntityApiPlugin } from '../../entityApi/EntityApiPlugin.tsx';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { isWebLink } from '../api/LinkEntity.ts';
import { convertOpenInNewWindow, findLinks } from '../api/editorLinkUtils.ts';
import { AutomaticWebLinkConversionPlugin } from './AutomaticWebLinkConversionPlugin.tsx';
import { WebLink } from './components/WebLink.tsx';

export type SimpleWebLinksPlugin = DraftJsEditorPlugin<
  None,
  None,
  None,
  None,
  [EntityApiPlugin, AutomaticWebLinkConversionPlugin]
>;

type CustomWebLinkEntityProps = {
  readonly unlink: (entityKey: string) => void;
};

type WebLinkEntityProps = EntityDecoratorProps<CustomWebLinkEntityProps> & {
  readonly children: ReadonlyArray<React.ReactNode>;
};

const WebLinkEntity: React.FC<WebLinkEntityProps> = ({
  children,
  contentState,
  decoratedText,
  entityKey,
  unlink,
}) => {
  const entity = contentState.getEntity(entityKey);
  if (!isWebLink(entity)) {
    return children;
  }

  const { url, openInNewWindow, title } = entity.getData();

  return (
    <WebLink
      key={entityKey}
      onUnlink={() => unlink(entityKey)}
      openInNewWindow={convertOpenInNewWindow(openInNewWindow)}
      text={decoratedText}
      title={title}
      url={url}
    >
      {children}
    </WebLink>
  );
};

WebLinkEntity.displayName = 'WebLinkEntity';

export const SimpleWebLinksPlugin: PluginComponent<SimpleWebLinksPlugin> = (props) => {
  const { decorateWithEditorStateCallbacks, canUpdateContent, executeChange, getApi } =
    useEditorStateCallbacks<SimpleWebLinksPlugin>();

  const unlink = useCallback(
    (entityKey: string): void => {
      if (!canUpdateContent()) {
        return;
      }

      executeChange((editorState) => {
        const selection = getApi().getSelectionForEntity(editorState, entityKey);
        if (!selection) {
          return editorState;
        }

        return getApi().unlink(editorState, selection);
      });
    },
    [executeChange, canUpdateContent, getApi],
  );

  const getInitialState: Decorator<GetInitialState> = useCallback(
    (baseGetInitialState) => (initialEditorState) => {
      const state = baseGetInitialState(initialEditorState);

      const linkCustomProps: CustomWebLinkEntityProps = { unlink };
      const linkDecorator: DraftDecorator<CustomWebLinkEntityProps> = {
        strategy: findLinks,
        component: WebLinkEntity,
        props: linkCustomProps,
      };

      return {
        ...state,
        decorators: [...state.decorators, linkDecorator],
      };
    },
    [unlink],
  );

  const apply: Apply<SimpleWebLinksPlugin> = useCallback(
    (state) => {
      decorateWithEditorStateCallbacks(state);
      state.getInitialState.decorate(getInitialState);

      return {};
    },
    [getInitialState, decorateWithEditorStateCallbacks],
  );

  return useEditorWithPlugin(props, { apply });
};
