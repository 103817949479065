import classNames from 'classnames';
import React, { useContext } from 'react';
import { BarItemBar } from '../../../../../../_shared/components/BarItems/BarItemBar.tsx';
import { BarItemTitle } from '../../../../../../_shared/components/BarItems/BarItemTitle.tsx';
import { Loader } from '../../../../../../_shared/components/Loader.tsx';
import { EditorPaperContext } from '../../../../../../_shared/contexts/EditorPaperContext.tsx';
import { getReachedPaperThresholds } from '../../../../../../_shared/utils/editorViewContext/editorPaperContext/utils/getReachedPaperThresholds.ts';
import { ICompiledContentType } from '../../../../../contentInventory/content/models/CompiledContentType.ts';
import { ContentNestingContext } from '../../../../../itemEditor/features/ContentItemEditing/context/ContentNestingContext.tsx';
import {
  ContentComponentCssClass,
  ContentComponentHeaderCssClass,
} from '../../utils/contentComponentRenderingUtils.ts';

export interface IExpandedContentComponentOwnProps {
  readonly additionalClassName?: string;
  readonly renderElements: () => JSX.Element;
}

export interface IExpandedContentComponentStateProps {
  readonly contentType: ICompiledContentType;
}

interface IExpandedContentComponentProps
  extends IExpandedContentComponentOwnProps,
    IExpandedContentComponentStateProps {}

export const ExpandedContentComponent: React.FC<IExpandedContentComponentProps> = ({
  additionalClassName,
  contentType,
  renderElements,
}) => {
  const { isTopLevel } = useContext(ContentNestingContext);

  const paperContext = useContext(EditorPaperContext);
  const reachedThresholds = getReachedPaperThresholds(paperContext.thresholds);

  if (!contentType) {
    return <Loader />;
  }

  return (
    <div
      className={classNames(
        ContentComponentCssClass,
        additionalClassName,
        'bar-item__pane',
        'content-component--is-disabled',
        {
          'content-component--is-top-level': isTopLevel,
        },
      )}
    >
      <BarItemBar
        className={ContentComponentHeaderCssClass}
        isCompact={reachedThresholds.sizeXS}
        isExpanded
      >
        <BarItemTitle>{contentType?.name}</BarItemTitle>
      </BarItemBar>
      {renderElements()}
    </div>
  );
};
