import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Input, InputState, InputType } from '@kontent-ai/component-library/Input';
import { delay, noOperation } from '@kontent-ai/utils';
import React, { useEffect, useImperativeHandle, useRef, useCallback, forwardRef } from 'react';
import {
  DataUiAction,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';

const getInputState = (disabled?: boolean, errorMessage?: string): InputState => {
  if (disabled) {
    return InputState.Disabled;
  }

  if (errorMessage) {
    return InputState.Alert;
  }

  return InputState.Default;
};

const clearActionToolTip = 'Clear the search phrase.';
const disabledFilterTooltip =
  'Can’t change the filter value while creating or editing a collection.';

export interface ITextFilterHandle {
  readonly focus: () => void;
}

type Props = {
  readonly ariaLabel: string;
  readonly autofocus?: boolean;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly errorMessage?: string;
  readonly extraIconsAfterReset?: readonly React.ReactNode[];
  readonly extraIconsBeforeReset?: readonly React.ReactNode[];
  readonly hideClearButton?: boolean;
  readonly maxLength?: number;
  readonly onChange: (text: string) => void;
  readonly onClick?: () => void;
  readonly placeholder?: string;
  readonly text: string;
  readonly type?: InputType;
};

export const TextFilter = forwardRef<ITextFilterHandle, Props>(
  (
    {
      autofocus,
      className,
      disabled,
      errorMessage,
      extraIconsAfterReset = [],
      extraIconsBeforeReset = [],
      hideClearButton,
      maxLength,
      onChange,
      onClick = noOperation,
      placeholder,
      text,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const focus = useCallback(() => inputRef.current?.focus(), []);

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
      onChange(event.target.value);

    const handleClear = (): void => {
      onChange('');
      focus();
    };

    useEffect(() => {
      if (autofocus) {
        delay(0).then(() => {
          focus();
        });
      }
    }, [autofocus, focus]);

    useImperativeHandle(ref, () => ({
      focus,
    }));

    const icons = [
      ...extraIconsBeforeReset,
      text && !hideClearButton && (
        <QuinaryButton
          disabled={disabled}
          tooltipPlacement="bottom"
          tooltipText={disabled ? '' : clearActionToolTip}
          onClick={handleClear}
          {...getDataUiActionAttribute(DataUiAction.ClearInput)}
        >
          <QuinaryButton.Icon icon={Icons.TimesCircle} screenReaderText={clearActionToolTip} />
        </QuinaryButton>
      ),
      ...extraIconsAfterReset,
    ].filter(Boolean);

    return (
      <div className={className} {...getDataUiElementAttribute(DataUiElement.TextFilter)}>
        <Input
          autoComplete="off"
          autoFocus={autofocus}
          caption={errorMessage}
          inputState={getInputState(disabled, errorMessage)}
          maxLength={maxLength}
          onChange={handleTextChange}
          onClick={onClick}
          placeholder={placeholder || 'Filter'}
          ref={inputRef}
          suffixes={icons}
          tooltipText={disabled ? disabledFilterTooltip : undefined}
          value={text}
          {...props}
          {...getDataUiInputAttribute(DataUiInput.Search)}
        />
      </div>
    );
  },
);

TextFilter.displayName = 'TextFilter';
