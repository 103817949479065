import { TabView } from '@kontent-ai/component-library/TabView';
import { useAttachRef } from '@kontent-ai/hooks';
import classNames from 'classnames';
import { forwardRef, memo, useContext, useEffect, useRef } from 'react';
import { useThrottledCallback } from 'use-debounce';
import useResizeObserver from 'use-resize-observer';
import { ScrollContainerContextProvider } from '../../../../../../component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import { ScrollOptionsContextProvider } from '../../../../../_shared/components/AutoScroll/ScrollOptionsContext.tsx';
import {
  EditorPaperContext,
  useUpdatePaperContext,
} from '../../../../../_shared/contexts/EditorPaperContext.tsx';
import { EditorScrollContextDefaultGracePeriodMs } from '../../../../../_shared/contexts/EditorScrollContext.tsx';
import { IntersectionObserverRootContextProvider } from '../../../../../_shared/hooks/IntersectionObserverRootContext.tsx';
import { useFixAutoCompleteOnScroll } from '../../../../../_shared/hooks/useFixAutoCompleteOnScroll.ts';
import { getReachedPaperThresholds } from '../../../../../_shared/utils/editorViewContext/editorPaperContext/utils/getReachedPaperThresholds.ts';
import { ItemEditorScrollOptions } from '../constants/uiConstants.ts';
import { ContentItemElements } from './ContentItemElements.tsx';
import { ContentItemHeader } from './ContentItemHeader.tsx';
import { ContentItemTabView } from './ContentItemTabView.tsx';
import { ElementLocksObserver } from './ElementLocksObserver.tsx';
import { InlineCommentPane } from './comments/InlineCommentPane.tsx';
import { EditingActions } from './editingActions/EditingActions.tsx';

type ContentItemEditorPaneProps = Readonly<{
  disabled: boolean;
  hasReducedSpacing: boolean;
  hideQuickActions: boolean;
  onInit: () => void;
  onReposition: (pane: HTMLDivElement) => void;
  onScroll: (scrollTop: number) => void;
}>;

const ContentItemEditorPaneComponent = forwardRef<HTMLDivElement, ContentItemEditorPaneProps>(
  ({ disabled, hasReducedSpacing, hideQuickActions, onInit, onReposition, onScroll }, ref) => {
    const { refObject: contentItemCanvasRef, refToForward: contentItemCanvasRefToForward } =
      useAttachRef(ref);
    const contentItemPaneRef = useRef<HTMLDivElement>(null);
    const contentItemPaperRef = useRef<HTMLDivElement>(null);

    const isContentItemCanvasScrollable = contentItemCanvasRef.current
      ? contentItemCanvasRef.current.offsetWidth > contentItemCanvasRef.current.clientWidth // this works only if the canvas has no borders — KCL-8777
      : false;

    useFixAutoCompleteOnScroll(contentItemCanvasRef);

    // Do not render item content until paper context is updated because anything using it would rerender after it initializes
    // this mainly causes performance problems in case of many linked items
    const { isEditorPaperSet: canRenderPaperContent } = useUpdatePaperContext(contentItemPaperRef);

    const throttledOnScroll = useThrottledCallback(
      () => contentItemCanvasRef.current && onScroll(contentItemCanvasRef.current?.scrollTop),
      EditorScrollContextDefaultGracePeriodMs,
    );
    const memoizedRepositionHandler = useThrottledCallback(() => {
      if (onReposition && contentItemPaneRef.current) {
        onReposition(contentItemPaneRef.current);
      }
    }, EditorScrollContextDefaultGracePeriodMs);

    useResizeObserver({
      ref: contentItemCanvasRef,
      onResize: memoizedRepositionHandler,
    });

    useEffect(() => {
      onInit();
    }, [onInit]);

    const paperContext = useContext(EditorPaperContext);
    const reachedThresholds = getReachedPaperThresholds(paperContext.thresholds);

    return (
      <IntersectionObserverRootContextProvider rootRef={contentItemCanvasRef}>
        <ScrollContainerContextProvider
          scrollContainerRef={contentItemCanvasRef}
          tippyBoundaryRef={contentItemCanvasRef}
        >
          <div
            className="content-item-pane__canvas"
            onScroll={throttledOnScroll}
            ref={contentItemCanvasRefToForward}
          >
            <div
              className={classNames('content-item-pane', {
                'content-item-pane--is-disabled': disabled,
                'content-item-pane--is-small': reachedThresholds.sizeXS || reachedThresholds.sizeS,
                'content-item-pane--is-canvas-scrollable': isContentItemCanvasScrollable,
                'content-item-pane--top-spacing': hideQuickActions && !hasReducedSpacing,
                'content-item-pane--has-reduced-spacing': hasReducedSpacing,
              })}
              data-hj-suppress=""
              ref={contentItemPaneRef}
            >
              <ScrollOptionsContextProvider scrollOptions={ItemEditorScrollOptions}>
                {!hideQuickActions && <EditingActions />}
                <div className="content-item-pane__main-pane" ref={contentItemPaperRef}>
                  {canRenderPaperContent && (
                    <ContentItemTabView>
                      <ContentItemHeader />
                      <TabView.TabPanel
                        css={`
                        padding-top: 0;
                      `}
                      >
                        {() => <ContentItemElements />}
                      </TabView.TabPanel>

                      <ElementLocksObserver />
                    </ContentItemTabView>
                  )}
                </div>
                <InlineCommentPane />
              </ScrollOptionsContextProvider>
            </div>
          </div>
        </ScrollContainerContextProvider>
      </IntersectionObserverRootContextProvider>
    );
  },
);

export const ContentItemEditorPane = memo(
  ContentItemEditorPaneComponent,
  (prevProps, nextProps) =>
    prevProps.disabled === nextProps.disabled &&
    prevProps.hasReducedSpacing === nextProps.hasReducedSpacing,
);
