import {
  colorAlertActive,
  colorAlertBackgroundInverse,
  colorAlertHover,
  colorAlertText,
  colorBackgroundHover,
  colorBackgroundSelected,
  colorBackgroundSelectedHover,
  colorPrimarySelected,
  colorTextDefault,
  colorTextDefaultInverse,
  colorTextDisabled,
  colorTextHint,
} from '../../tokens/decision/colors.ts';
import { BaseColor, Color } from '../../tokens/quarks/colors.ts';
import { MenuItemState } from './menuItemState.ts';

export const getColor = (state: MenuItemState): Color => {
  switch (state) {
    case 'default':
    case 'destructive':
      return colorTextDefault;
    case 'disabled':
      return colorTextDisabled;
    case 'selected':
      return colorPrimarySelected;
    case 'readonly':
      return colorTextHint;
    case 'error':
      return colorAlertText;
    case 'error-selected':
      return colorTextDefaultInverse;
  }
};

export const getBackgroundColor = (state: MenuItemState): Color => {
  switch (state) {
    case 'default':
    case 'destructive':
    case 'disabled':
    case 'readonly':
      return BaseColor.Transparent;
    case 'selected':
      return colorBackgroundSelected;
    case 'error':
      return BaseColor.Transparent;
    case 'error-selected':
      return colorAlertBackgroundInverse;
  }
};

export const getCursor = (state: MenuItemState): string => {
  switch (state) {
    case 'default':
    case 'selected':
    case 'destructive':
    case 'error':
    case 'error-selected':
      return 'pointer';
    case 'disabled':
      return 'not-allowed';
    case 'readonly':
      return 'default';
  }
};

export const getHoverColor = (state: MenuItemState): Color => {
  switch (state) {
    case 'default':
      return colorTextDefault;
    case 'destructive':
      return colorTextDefaultInverse;
    case 'disabled':
      return colorTextDisabled;
    case 'selected':
      return colorPrimarySelected;
    case 'readonly':
      return colorTextHint;
    case 'error':
    case 'error-selected':
      return colorTextDefaultInverse;
  }
};

export const getBackgroundHoverColor = (state: MenuItemState): Color => {
  switch (state) {
    case 'default':
      return colorBackgroundHover;
    case 'destructive':
      return colorAlertBackgroundInverse;
    case 'disabled':
      return BaseColor.Transparent;
    case 'selected':
      return colorBackgroundSelectedHover;
    case 'readonly':
      return BaseColor.Transparent;
    case 'error':
    case 'error-selected':
      return colorAlertHover;
  }
};

export const getActiveColor = (state: MenuItemState): Color => {
  switch (state) {
    case 'selected':
    case 'default':
      return colorPrimarySelected;
    case 'destructive':
      return BaseColor.White;
    case 'disabled':
      return colorTextDisabled;
    case 'readonly':
      return colorTextHint;
    case 'error':
    case 'error-selected':
      return colorTextDefaultInverse;
  }
};

export const getActiveBackgroundColor = (state: MenuItemState): Color => {
  switch (state) {
    case 'selected':
    case 'default':
      return colorBackgroundSelected;
    case 'destructive':
      return colorAlertHover;
    case 'disabled':
      return BaseColor.Transparent;
    case 'readonly':
      return BaseColor.Transparent;
    case 'error':
    case 'error-selected':
      return colorAlertActive;
  }
};
