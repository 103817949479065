import {
  ConditionAction,
  MultipleChoiceConditionOperator,
  TypeElementCondition,
  emptyTypeElementCondition,
} from '../../../../../_shared/models/utils/TypeElementCondition.ts';
import {
  TypeElementConditionServerModel,
  TypeElementConditionTriggerServerModel,
} from '../../../../../repositories/serverModels/TypeElementConditionServerModel.type.ts';
import { ContentTypeElementBaseServerModel } from '../../../../../repositories/serverModels/contentModels/sharedContentTypeModels.type.ts';
import { IBaseTypeElementData } from '../../models/elements/types/TypeElementData.ts';

export function convertBaseTypeElementDataToServerModel(
  typeElement: IBaseTypeElementData,
): ContentTypeElementBaseServerModel {
  return {
    codeName: typeElement.codename || null,
    conditions: convertConditionToServerModel(typeElement.condition),
    contentGroupId: typeElement.contentGroupId,
    elementId: typeElement.elementId,
    guidelines: typeElement.guidelines || null,
    isNonLocalizable: typeElement.isNonLocalizable,
    isRequired: typeElement.isRequired,
    name: typeElement.name,
  };
}

export function convertConditionToServerModel(
  condition: TypeElementCondition | null,
): ReadonlyArray<TypeElementConditionServerModel> {
  if (!condition || isEmpty(condition)) {
    return [];
  }

  const conditionServerModel: TypeElementConditionServerModel = {
    id: condition.id,
    isActive: condition.isActive,
    action: convertConditionActionToServerModel(condition.action),
    triggers: isTriggerSelected(condition)
      ? [
          {
            elementId: condition.trigger.elementId,
            triggerType: 'multipleChoiceTypeElement',
            operator: convertMultipleChoiceOperatorToServerModel(condition.trigger.operator),
            values: condition.trigger.optionIds,
          },
        ]
      : [],
  };

  return [conditionServerModel];
}

const isEmpty = (condition: TypeElementCondition): boolean =>
  !condition.isActive &&
  condition.action === emptyTypeElementCondition.action &&
  condition.trigger.elementId === emptyTypeElementCondition.trigger.elementId;

const isTriggerSelected = (condition: TypeElementCondition): boolean =>
  !!condition.trigger.elementId;

function convertConditionActionToServerModel(
  action: ConditionAction,
): TypeElementConditionServerModel['action'] {
  switch (action) {
    case ConditionAction.Hide:
      return 'hide';
    case ConditionAction.Show:
      return 'show';
  }
}

function convertMultipleChoiceOperatorToServerModel(
  operator: MultipleChoiceConditionOperator,
): TypeElementConditionTriggerServerModel['operator'] {
  switch (operator) {
    case MultipleChoiceConditionOperator.All:
      return 'all';
    case MultipleChoiceConditionOperator.Any:
      return 'any';
  }
}
