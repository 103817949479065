import { ContentState, EditorState } from 'draft-js';
import React, { useCallback, useImperativeHandle } from 'react';
import { flushSync } from 'react-dom';
import { useEditorStateCallbacks } from '../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';

import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';

type ResetInput = (content: ContentState) => void;

export type Resettable = {
  readonly resetInput: ResetInput;
};

type ManualResetPluginProps = {
  readonly resettableRef?: React.Ref<Resettable>;
};

export type ManualResetPlugin = DraftJsEditorPlugin<None, ManualResetPluginProps>;

export const ManualResetPlugin: PluginComponent<ManualResetPlugin> = (props) => {
  const { resettableRef } = props;

  const { decorateWithEditorStateCallbacks, reinit } = useEditorStateCallbacks<ManualResetPlugin>();

  const resetInput: ResetInput = useCallback(
    (contentState) => {
      // We need to force immediate re-rendering as the reset editor may immediately receive focus,
      // and we need its internal state to be properly updated before than happens
      // This happens for example when clearing the input after submitting comment in Discussion sidebar
      flushSync(() => reinit(EditorState.createWithContent(contentState)));
    },
    [reinit],
  );

  useImperativeHandle(resettableRef, () => ({ resetInput }));

  return useEditorWithPlugin(props, {
    apply: decorateWithEditorStateCallbacks,
  });
};
