import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { DraftDecorator } from 'draft-js';
import React from 'react';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../editorCore/types/Editor.composition.type.ts';
import { Apply } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { DraftJsEditorPlugin } from '../../draftJs/DraftJsEditorPlugin.type.ts';
import { GetInitialState } from '../../draftJs/DraftJsPlugin.type.ts';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { isWebLink } from '../api/LinkEntity.ts';
import { findLinks } from '../api/editorLinkUtils.ts';

type DisplaySimpleWebLinksPlugin = DraftJsEditorPlugin;

export const SimpleWebLinkEntity: React.FC<EntityDecoratorProps> = ({
  contentState,
  decoratedText,
  entityKey,
}) => {
  const entity = contentState.getEntity(entityKey);
  if (!isWebLink(entity)) {
    return decoratedText;
  }

  const { url } = entity.getData();

  return (
    <OutwardLink href={url} contentEditable={false}>
      {decoratedText}
    </OutwardLink>
  );
};

const getInitialState: Decorator<GetInitialState> =
  (baseGetInitialState) => (initialEditorState) => {
    const state = baseGetInitialState(initialEditorState);

    const linkDecorator: DraftDecorator = {
      strategy: findLinks,
      component: SimpleWebLinkEntity,
    };

    return {
      ...state,
      decorators: [...state.decorators, linkDecorator],
    };
  };

const apply: Apply<DisplaySimpleWebLinksPlugin> = (state) => {
  state.getInitialState.decorate(getInitialState);
  return {};
};

export const DisplaySimpleWebLinksPlugin: PluginComponent<DisplaySimpleWebLinksPlugin> = (props) =>
  useEditorWithPlugin(props, { apply });
