import { Icons } from '@kontent-ai/component-library/Icons';
import {
  SimpleStatusDefault,
  SimpleStatusError,
  SimpleStatusSuccess,
  SimpleStatusWarning,
} from '@kontent-ai/component-library/SimpleStatus';
import {
  WebhookHealthStatus,
  WebhookHealthStatusDisplayNames,
} from '../../../webhooks/constants/webhookHealthStatuses.ts';

type EntityWebhookHealthStatusProps = {
  readonly enabled: boolean;
  readonly healthStatus: WebhookHealthStatus;
};

export const EntityWebhookHealthStatus = ({
  enabled,
  healthStatus,
}: EntityWebhookHealthStatusProps) => {
  if (!enabled) {
    return <SimpleStatusDefault icon={Icons.Drawers} label="Disabled" />;
  }

  switch (healthStatus) {
    case WebhookHealthStatus.Unknown:
      return (
        <SimpleStatusDefault
          icon={Icons.ICircle}
          label={WebhookHealthStatusDisplayNames[healthStatus]}
        />
      );
    case WebhookHealthStatus.Dead:
      return (
        <SimpleStatusWarning
          icon={Icons.ExclamationTriangle}
          label={WebhookHealthStatusDisplayNames[healthStatus]}
        />
      );
    case WebhookHealthStatus.Failing:
      return <SimpleStatusError label={WebhookHealthStatusDisplayNames[healthStatus]} />;
    case WebhookHealthStatus.Working:
      return (
        <SimpleStatusSuccess
          icon={Icons.CbCheck}
          label={WebhookHealthStatusDisplayNames[healthStatus]}
        />
      );
  }
};

EntityWebhookHealthStatus.displayName = 'EntityWebhookHealthStatus';
