import { AvatarSize } from '@kontent-ai/component-library/Avatar';
import { ColorCircle } from '@kontent-ai/component-library/ColorCircle';
import { Icons } from '@kontent-ai/component-library/Icons';
import {
  IconSize,
  colorIconDefaultInverse,
  colorPrimary,
} from '@kontent-ai/component-library/tokens';

export type IAiAvatarProps = {
  readonly size: AvatarSize;
};

const getIconSize = (size: AvatarSize): IconSize => {
  switch (size) {
    case AvatarSize.XS:
      return IconSize.XS;
    case AvatarSize.S:
      return IconSize.S;
    case AvatarSize.M:
      return IconSize.M;
    case AvatarSize.L:
      return IconSize.L;
  }
};

export const AiAvatar = ({ size }: IAiAvatarProps) => (
  <ColorCircle color={colorPrimary} size={size}>
    <Icons.Sparkles size={getIconSize(size)} color={colorIconDefaultInverse} />
  </ColorCircle>
);

AiAvatar.displayName = 'AiAvatar';
