import { useState } from 'react';
import { BarItemAnimation } from '../../../../_shared/components/BarItems/BarItemAnimation.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISpace } from '../../../../data/models/space/space.ts';
import { ExpandedSpaceBarItemHeight } from '../constants/spacesUiConstants.ts';
import { CollapsedSpaceBar } from '../containers/CollapsedSpaceBar.tsx';
import { ExpandedSpaceBar } from '../containers/ExpandedSpaceBar.tsx';
import { ActionInProgress } from '../types/acionInProgress.ts';

type Props = {
  readonly actionInProgress: ActionInProgress;
  readonly isEdited: boolean;
  readonly isEditingEnabled: boolean;
  readonly isWebSpotlightActive: boolean;
  readonly onCancel: () => void;
  readonly onDelete: () => void;
  readonly onEdit: () => void;
  readonly onSave: (
    space: ISpace,
    shouldCreateNewRootItem: boolean,
    onSuccess?: () => void,
    onFailure?: () => void,
  ) => Promise<void>;
  readonly relatedCodeNames: ReadonlySet<string>;
  readonly searchPhrase: string;
  readonly space: ISpace;
};

export const SpaceBar = ({
  actionInProgress,
  isEdited,
  isEditingEnabled,
  isWebSpotlightActive,
  onCancel,
  onDelete,
  onEdit,
  onSave,
  relatedCodeNames,
  searchPhrase,
  space,
}: Props) => {
  const editSpace = isEditingEnabled ? onEdit : undefined;

  const [isCodenameBeingSaved, setIsCodenameBeingSaved] = useState(false);

  const saveCodename = isEditingEnabled
    ? async (codeName: string, onSaved: () => void): Promise<void> => {
        setIsCodenameBeingSaved(true);
        await onSave(
          {
            ...space,
            codeName,
          },
          false,
        );
        onSaved();
        setIsCodenameBeingSaved(false);
      }
    : undefined;

  const saveSpace = async (
    name: string,
    collectionIds: ReadonlyArray<Uuid>,
    rootItemId: Uuid | null,
    shouldCreateNewRootItem: boolean,
  ): Promise<void> =>
    await onSave(
      {
        ...space,
        name,
        collectionIds,
        webSpotlightRootItemId: rootItemId,
      },
      shouldCreateNewRootItem,
    );

  return (
    <li {...getDataUiElementAttribute(DataUiElement.BarItemNode)}>
      <BarItemAnimation
        estimatedMaxHeightWhenExpanded={ExpandedSpaceBarItemHeight}
        renderCollapsed={() => (
          <CollapsedSpaceBar
            key={space.id}
            isCodenameBeingSaved={isCodenameBeingSaved}
            isLinkedToActiveWebSpotlight={isWebSpotlightActive && !!space.webSpotlightRootItemId}
            onCodenameSave={saveCodename}
            onEdit={editSpace}
            relatedCodeNames={relatedCodeNames}
            searchPhrase={searchPhrase}
            space={space}
          />
        )}
        renderExpanded={() => (
          <ExpandedSpaceBar
            key={space.id}
            actionInProgress={actionInProgress}
            space={space}
            onCancel={onCancel}
            onDelete={onDelete}
            onSave={saveSpace}
          />
        )}
        shouldBeExpanded={isEdited}
      />
    </li>
  );
};
