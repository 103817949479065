import { Toggle } from '@kontent-ai/component-library/Toggle';
import { useEditorContext } from '../../context/EditorContextProvider.tsx';

export const PreviewToggle = () => {
  const { isPreviewOpened, setIsPreviewOpened } = useEditorContext();
  const previewStatus = isPreviewOpened ? 'on' : 'off';

  return (
    <Toggle
      status={previewStatus}
      labelText="Preview"
      onToggleOn={() => setIsPreviewOpened(true)}
      onToggleOff={() => setIsPreviewOpened(false)}
    />
  );
};
