import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { isEmptyOrWhitespace } from '../../../../../../_shared/utils/stringUtils.ts';
import { isAbsoluteWebUrl } from '../../../../../../_shared/utils/urlUtils.ts';
import { LinkInfo } from '../../components/LinkInfo.tsx';

const createUrlLink = (url: string): JSX.Element => {
  if (isAbsoluteWebUrl(url)) {
    return (
      <a
        className="link-dialog__detail-link-pane"
        href={url}
        title={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="link-dialog__detail-link">{url}</div>
      </a>
    );
  }

  return <div className="link-dialog__detail-link">{url}</div>;
};

type Props = {
  readonly disabled?: boolean;
  readonly openInNewWindow?: boolean;
  readonly title?: string;
  readonly url: string;
};

export const WebLinkInfo: React.FC<Props> = ({ disabled, title, url, openInNewWindow }) =>
  disabled ? (
    <Box paddingY={Spacing.S}>
      <Stack spacing={Spacing.XS}>
        <LinkInfo>
          <LinkInfo.Label>URL:</LinkInfo.Label>
          <LinkInfo.Value>
            {isAbsoluteWebUrl(url) ? (
              <a href={url} title={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            ) : (
              url
            )}
          </LinkInfo.Value>
        </LinkInfo>
        {!isEmptyOrWhitespace(title) && (
          <LinkInfo>
            <LinkInfo.Label>Title:</LinkInfo.Label>
            <LinkInfo.Value>
              <span title={title}>{title}</span>
            </LinkInfo.Value>
          </LinkInfo>
        )}
        <LinkInfo>
          <LinkInfo.Label>Open link:</LinkInfo.Label>
          <LinkInfo.Value>
            {openInNewWindow ? 'In a new window' : 'In the same window'}
          </LinkInfo.Value>
        </LinkInfo>
      </Stack>
    </Box>
  ) : (
    createUrlLink(url)
  );
