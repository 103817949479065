import { ComponentProps, useCallback, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  ContentItemPreviewRoute,
  ContentItemRouteParams,
} from '../../../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { waitUntilFocusAndScrollAreNotDeferred } from '../../../../../../_shared/utils/autoScrollUtils.ts';
import {
  closeContentItemEditingSidebar,
  initializeContentItemEditingSidebar,
} from '../../actions/contentItemEditingActions.ts';
import { openContentItemEditingSidebar } from '../../actions/thunks/openContentItemEditingSidebar.ts';
import { ContentItemSidebar as ContentItemSidebarComponent } from '../../components/sidebar/ContentItemSidebar.tsx';
import { useIsWebSpotlightFixedQuickActionsEnabled } from '../../hooks/useIsWebSpotlightFixedQuickActionsEnabled.tsx';

const useWasEverTrue = (bool: boolean): boolean => {
  const wasEverTrue = useRef(false);
  wasEverTrue.current = wasEverTrue.current || bool;

  return wasEverTrue.current;
};

const useOpenByDefault = (): void => {
  const prefersClosedEditingSidebar = useSelector(
    (state) => state.sharedApp.userProperties.prefersClosedEditingSidebar,
  );
  const wasPrefersClosedEditingSidebarEverTrue = useWasEverTrue(prefersClosedEditingSidebar); // don't open sidebar when the preference changes
  const isInPreview = !!useRouteMatch<ContentItemRouteParams<Uuid>>(ContentItemPreviewRoute);
  const shouldOpen = useSelector(
    (state) =>
      !isInPreview &&
      !wasPrefersClosedEditingSidebarEverTrue &&
      state.contentApp.editorUi.contentItemSidebar.isInitialized,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (shouldOpen) {
      dispatch(openContentItemEditingSidebar());
    }
  }, [shouldOpen]);
};

type Props = Pick<
  ComponentProps<typeof ContentItemSidebarComponent>,
  'hasPlaceholder' | 'outsideClickElementRefs'
>;

export const ContentItemSidebar = (props: Props) => {
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(closeContentItemEditingSidebar());
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.ContentItemDetailsSidebarClosed,
        origin: ContentItemEditingEventOrigins.ItemDetails,
      }),
    );
  }, []);

  useEffect(() => {
    const onInit = () => dispatch(initializeContentItemEditingSidebar());
    // Do not initiate sidebar showing until all the content is rendered to give the sidebar maximum resources for its animation to be smooth
    const deferredOnInit = waitUntilFocusAndScrollAreNotDeferred(onInit);
    deferredOnInit();
  }, []);

  const isOpen = useSelector((state) => state.contentApp.editorUi.contentItemSidebar.isOpen);
  const isSidebarOpenedByUser = useSelector(
    (state) => state.contentApp.editorUi.contentItemSidebar.openedByUser,
  );
  const isInitialized = useSelector(
    (state) => state.contentApp.editorUi.contentItemSidebar.isInitialized,
  );

  useOpenByDefault();

  const isWebSpotlightFixedQuickActionsEnabled = useIsWebSpotlightFixedQuickActionsEnabled();

  return isInitialized ? (
    <ContentItemSidebarComponent
      autofocus={isSidebarOpenedByUser}
      hasPlaceholder={props.hasPlaceholder}
      isBelowQuickActions={isWebSpotlightFixedQuickActionsEnabled}
      isOpen={isOpen}
      onClose={onClose}
      outsideClickElementRefs={props.outsideClickElementRefs}
    />
  ) : null;
};
