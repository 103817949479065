import { Button } from '@kontent-ai/component-library/Button';
import { AnimatedProgressIcon, Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import {
  SimpleStatusDefault,
  SimpleStatusError,
  SimpleStatusSuccess,
} from '@kontent-ai/component-library/SimpleStatus';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { FC } from 'react';
import {
  ElementTranslationStatus,
  VariantTranslationStatus,
} from '../../../../../../_shared/utils/translations/variantTranslationStatus.ts';
import { TypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';

const getTranslationErrorMessage = (
  translationStatusPerElement: ReadonlyMap<Uuid, ElementTranslationStatus>,
  typeElements: ReadonlyArray<TypeElement>,
) => {
  const failedElementIds = [...translationStatusPerElement]
    .filter(([, status]) => status === ElementTranslationStatus.Failed)
    .map(([id]) => id);
  const failedElementNames = typeElements
    .filter((typeElement) => failedElementIds.includes(typeElement.elementId))
    .map((typeElement) => typeElement.name);

  const failedElementMessage =
    failedElementIds.length === 1
      ? ` for the following element: ${failedElementNames[0]}`
      : ` for the following elements: ${failedElementNames.join(', ')}`;

  return `The AI couldn’t finish the translation ${failedElementMessage}`;
};

type TranslationStatusProps = {
  readonly translationStatus: VariantTranslationStatus;
  readonly translationStatusPerElement: ReadonlyMap<Uuid, ElementTranslationStatus> | null;
  readonly onRetry: () => void;
  readonly typeElements: ReadonlyArray<TypeElement>;
};

export const TranslationStatus: FC<TranslationStatusProps> = ({
  translationStatus,
  translationStatusPerElement,
  onRetry,
  typeElements,
}) => {
  switch (translationStatus) {
    case VariantTranslationStatus.InProgress:
      return (
        <SimpleStatusDefault
          label="This content item is being translated by AI. You can continue using Kontent.ai"
          icon={AnimatedProgressIcon}
        />
      );

    case VariantTranslationStatus.Failed:
    case VariantTranslationStatus.PartiallyFailed:
      return (
        <Inline noWrap spacing={Spacing.M}>
          {translationStatusPerElement && (
            <SimpleStatusError
              label={getTranslationErrorMessage(translationStatusPerElement, typeElements)}
            />
          )}
          {translationStatus === VariantTranslationStatus.Failed && (
            <Button buttonStyle="secondary" destructive size="small" onClick={onRetry}>
              Retry
            </Button>
          )}
        </Inline>
      );

    case VariantTranslationStatus.Completed:
      return <SimpleStatusSuccess label="Translation finished" icon={Icons.CbCheckPreview} />;

    case VariantTranslationStatus.Cancelled:
      return null;
  }
};
