import { MultiSelect, MultiSelectFilterPredicate } from '@kontent-ai/component-library/MultiSelect';
import { Option, RenderSelectMenuOptionProps } from '@kontent-ai/component-library/Selects';
import React from 'react';
import { SearchPhraseHighlighterElement } from '../../../../../_shared/components/Highlighting/SearchPhraseHighlighterElement.tsx';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { UserOption } from '../../containers/promoteUsersModal/SubscriptionUsers.tsx';

interface ISubscriptionUsersProps {
  readonly userOptions: ReadonlyArray<UserOption>;
  readonly selectedUserIds: ReadonlySet<Uuid>;
  readonly changeSelectedUsers: (userIds: ReadonlySet<string>) => void;
}

const filterByLabelAndEmailPredicate: MultiSelectFilterPredicate<UserOption> = (
  item: UserOption,
  filterPhrase,
) => {
  return (
    !filterPhrase ||
    item.email.toLowerCase().includes(filterPhrase) ||
    item.label.toLowerCase().includes(filterPhrase)
  );
};

const renderMenuOption = (optionProps: RenderSelectMenuOptionProps<UserOption>) => (
  <Option
    {...optionProps}
    renderComplementaryText={() => (
      <SearchPhraseHighlighterElement
        searchPhrase={optionProps.highlightPattern}
        text={optionProps.item.value?.email ?? ''}
      />
    )}
  />
);

export const SubscriptionUsers: React.FC<ISubscriptionUsersProps> = ({
  userOptions,
  selectedUserIds,
  changeSelectedUsers,
}) => (
  <MultiSelect
    aria-label="Select a user"
    customFilterPredicate={filterByLabelAndEmailPredicate}
    items={userOptions}
    onSelectionChange={changeSelectedUsers}
    placeholder="Select a user to promote"
    renderMenuOption={renderMenuOption}
    selectedItemIds={selectedUserIds}
    verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.UsersList)}
    {...getDataUiCollectionAttribute(DataUiCollection.UsersList)}
  />
);

SubscriptionUsers.displayName = 'SubscriptionUsers';
