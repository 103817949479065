import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { getLanguageIdsWithAllowedCapabilityInAnyCollection } from '../../../../_shared/utils/permissions/getLanguageIdsWithAllowedCapability.ts';
import { isUuid } from '../../../../_shared/utils/validation/typeValidators.ts';
import { INormalizedRolesWithSettings } from '../../../../data/models/roles/INormalizedRolesWithSettings.ts';
import { ICollectionsData } from '../../../../data/reducers/collections/ICollections.type.ts';
import { ILanguagesData } from '../../../../data/reducers/languages/ILanguagesData.type.ts';
import {
  getActiveLanguageIds,
  getAllLanguageIds,
} from '../../../../data/reducers/languages/selectors/getLanguages.ts';

export enum AccessDeniedType {
  AccessLostDueToDeactivatedLanguage = 'AccessLostDueToDeactivatedLanguage',
  AccessToLanguageDenied = 'AccessToLanguageDenied',
  NoContentProductionCapabilities = 'NoContentProductionCapabilities',
}

export const getVariantIdToBeEnsuredOrAccessDeniedType = (
  currentRouteVariantId: Uuid | undefined | null,
  loadStorageValue: () => Uuid | undefined | null,
  languages: ILanguagesData,
  userRoles: INormalizedRolesWithSettings,
  collections: ICollectionsData,
): { readonly error?: AccessDeniedType; id?: Uuid } => {
  const allLanguageIds = getAllLanguageIds(languages);
  const languageIdsWithAllowedView = getLanguageIdsWithAllowedCapabilityInAnyCollection(
    userRoles,
    allLanguageIds,
    Capability.ViewContent,
    collections.byId,
  );

  if (languageIdsWithAllowedView.isEmpty()) {
    return { error: AccessDeniedType.NoContentProductionCapabilities };
  }

  const activeLanguageIds = getActiveLanguageIds(languages);
  const fallbackLanguageId = activeLanguageIds
    .filter((id: Uuid) => languageIdsWithAllowedView.has(id))
    .first();

  if (!fallbackLanguageId) {
    return { error: AccessDeniedType.AccessLostDueToDeactivatedLanguage };
  }

  const isExistingLanguageId = (variantId: Uuid | undefined | null): variantId is Uuid =>
    isUuid(variantId) && allLanguageIds.contains(variantId);

  const isAllowedActiveLanguage = (variantId: Uuid) =>
    languageIdsWithAllowedView.has(variantId) && activeLanguageIds.has(variantId);

  if (isExistingLanguageId(currentRouteVariantId)) {
    if (isAllowedActiveLanguage(currentRouteVariantId)) {
      return { id: currentRouteVariantId };
    }

    return { error: AccessDeniedType.AccessToLanguageDenied };
  }

  const storageValue = loadStorageValue();
  if (isExistingLanguageId(storageValue) && isAllowedActiveLanguage(storageValue)) {
    return { id: storageValue };
  }

  return { id: fallbackLanguageId };
};
