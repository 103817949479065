import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { shallowEqual } from '../../../../../_shared/utils/shallowEqual.ts';
import { areSpacesInCollectionsAvailable as areSpacesInCollectionsAvailableSelector } from '../../../../contentInventory/content/selectors/areSpacesInCollectionsAvailable.ts';
import { selectPreviewLinks } from '../containers/selectors/selectPreviewLinks.ts';
import { useEditorContext } from '../context/EditorContextProvider.tsx';
import { getAvailableSpacesForUser } from '../selectors/getAvailableSpacesForUser.ts';

export const useGetPreviewLink = () => {
  const { selectedSpaceId } = useEditorContext();
  const areSpacesInCollectionsAvailable = useSelector(areSpacesInCollectionsAvailableSelector);
  const availableSpaceIds = useSelector(
    (s) => getAvailableSpacesForUser(s).map((space) => space.id),
    shallowEqual,
  );

  const previewLinks = useSelector(selectPreviewLinks);
  const availablePreviewLinks = previewLinks.filter(
    (link) => !link.spaceId || availableSpaceIds.includes(link.spaceId),
  );

  if (!areSpacesInCollectionsAvailable || !selectedSpaceId) {
    return availablePreviewLinks[0];
  }

  const linkForSelectedSpace = availablePreviewLinks.find((l) => l.spaceId === selectedSpaceId);
  return linkForSelectedSpace ?? availablePreviewLinks[0];
};
