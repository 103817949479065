import { SpaceSelectorInWebSpotlightStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { StorageParams, getProjectSpecificStorage } from './projectSpecificStorage.ts';

const getSpaceIdFromJS = (rawSpaceId: Uuid): Uuid | null => {
  return rawSpaceId ?? null;
};

const spaceSelectorInWebSpotlightStorageParams: StorageParams<Uuid | null> = {
  key: SpaceSelectorInWebSpotlightStorageKey,
  initialState: null,
  fromJS: getSpaceIdFromJS,
};

export const SpaceSelectorInWebSpotlightStorage = getProjectSpecificStorage<Uuid | null>(
  spaceSelectorInWebSpotlightStorageParams,
);
