import styled, { css } from 'styled-components';
import { colorBackgroundHover } from '../../../tokens/decision/colors.ts';
import { offsetFocus } from '../../../tokens/decision/focus.ts';
import { Size } from '../../../tokens/decision/spacing.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { BoxShadow, shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { Anchor } from '../../Anchor/Anchor.tsx';
import { AvatarSize } from '../avatarSize.ts';

const getSizeStyles = (size: number) => css`
  width: ${px(size)};
  height: ${px(size)};
`;

const getAvatarSizeStyles = (size: AvatarSize) => {
  switch (size) {
    case AvatarSize.XS:
      return getSizeStyles(Spacing.XL);
    case AvatarSize.S:
      return getSizeStyles(Size.S);
    case AvatarSize.M:
      return getSizeStyles(Size.M);
    default:
      return getSizeStyles(Size.L);
  }
};

interface IBaseStyledAvatarProps {
  readonly boxShadow?: BoxShadow;
  readonly $activated?: boolean;
  readonly $size: AvatarSize;
}

const interactiveAvatarStyles = css`
  position: relative;
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: ${px(BorderRadius.Pill)};
    
    opacity: 0;
    transition: opacity ${transition250};
  }
  
  &::before {
    inset: 0;
    background: ${colorBackgroundHover};
  }
  
  &::after { 
    inset: ${px(-1 * offsetFocus)};
  }
  
  &:hover::before,
  &:focus::after {
    opacity: 1;
  }
  
  button&:disabled:hover::before {
    opacity: 0;
  }
`;

const baseAvatarStyles = css<IBaseStyledAvatarProps>`
  ${({ $size }) => getAvatarSizeStyles($size)};
  ${({ boxShadow }) =>
    boxShadow &&
    css`
      box-shadow: ${boxShadow};
    `};
  display: inline-block;
  border-radius: ${px(BorderRadius.Pill)};
  vertical-align: top;

  ${interactiveAvatarStyles};
  ${({ $activated }) =>
    $activated &&
    css`
      &::before {
        opacity: 1;
      }
    `};
`;

export const StyledAvatar = styled.div<IBaseStyledAvatarProps>`
  ${baseAvatarStyles};
`;

type FocusVisibleProps = {
  readonly isFocusVisible: boolean;
};

export const StyledButtonAvatar = styled.button<IBaseStyledAvatarProps & FocusVisibleProps>`
  ${baseAvatarStyles};
  padding: 0;
  border: none;
  background: none;

  &:disabled:hover {
    cursor: not-allowed;
  }

  ${({ isFocusVisible }) =>
    isFocusVisible &&
    css`
      &::after {
        ${shadowFocusStyles};
      }
    `};
`;

export const StyledLinkAvatar = styled(Anchor)<IBaseStyledAvatarProps & FocusVisibleProps>`
  ${baseAvatarStyles};
  ${({ isFocusVisible }) =>
    isFocusVisible &&
    css`
      &::after {
        ${shadowFocusStyles};
      }
    `};
`;
