import React, { RefObject } from 'react';
import { Box } from '../../../layout/Box/Box.tsx';
import { colorIconDefaultInverse } from '../../../tokens/decision/colors.ts';
import { spacingPopupDistance } from '../../../tokens/decision/spacing.ts';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { quinaryButtonHeight } from '../../Button/decisionTokens.ts';
import { Icons } from '../../Icons/components/icons.ts';
import { Menu } from '../../Menu/Menu.tsx';
import { StatusBarIconButton } from '../../StatusBar/components/StatusBarIconButton.tsx';
import { breadcrumbsHeight } from '../decisionTokens.ts';
import { BreadcrumbsItemProps, StyledListItem } from './BreadcrumbsItem.tsx';

const breadcrumbsMenuTriggerCopy = 'Show collapsed items';

export const BreadcrumbsVerticalMenu: React.FC<{
  readonly items: ReadonlyArray<BreadcrumbsItemProps>;
}> = ({ items }) => {
  const quinaryButtonOffset = (breadcrumbsHeight - quinaryButtonHeight) / 2;
  const offset = quinaryButtonOffset + spacingPopupDistance;

  return (
    <StyledListItem>
      <Box paddingX={Spacing.S}>
        <Menu>
          <Menu.Trigger>
            {(ref: RefObject<HTMLButtonElement>, triggerProps, isOpen) => (
              <StatusBarIconButton
                iconName="Ellipsis"
                isActive={isOpen}
                label={breadcrumbsMenuTriggerCopy}
                ref={ref}
                {...triggerProps}
              />
            )}
          </Menu.Trigger>
          <Menu.List offset={offset}>
            {items.map((item) => (
              <Menu.Item id={item.to} key={item.to} linkPath={item.to} label={item.label} />
            ))}
          </Menu.List>
        </Menu>
      </Box>
      <Icons.ChevronRight size={IconSize.XS} color={colorIconDefaultInverse} />
    </StyledListItem>
  );
};
