import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { NotificationBar } from '../../../../../_shared/containers/NotificationBar.tsx';
import { ContentGroupSelectionReason } from '../constants/ContentGroupSelectionReason.ts';
import { ContentComponentConvertedToItemNotificationBar } from '../containers/ContentComponentConvertedToItemNotificationBar.tsx';
import { ArchivedStepNotificationBar } from '../containers/contentItemEditorNotifications/ArchivedStepNotificationBar.tsx';
import { IncompleteDefaultVariantNotificationBar } from '../containers/contentItemEditorNotifications/IncompleteDefaultVariantNotificationBar.tsx';
import { IncompleteItemNotificationBar } from '../containers/contentItemEditorNotifications/IncompleteItemNotificationBar.tsx';
import { ItemLiveUsersNotificationBar } from '../containers/contentItemEditorNotifications/ItemLiveUsersNotificationBar.tsx';
import { OverwriteNotificationBar } from '../containers/contentItemEditorNotifications/OverwriteNotificationBar.tsx';
import { PublishingNotificationBar } from '../containers/contentItemEditorNotifications/PublishingNotificationBar.tsx';
import { RevisionRestoredNotificationBar } from '../containers/contentItemEditorNotifications/RevisionRestoredNotificationBar.tsx';
import { SavingFailedNotificationBar } from '../containers/contentItemEditorNotifications/SavingFailedNotificationBar.tsx';
import { TranslationProgressNotificationBar } from '../containers/contentItemEditorNotifications/TranslationProgressNotificationBar.tsx';
import { ValidationErrorNotificationBar } from '../containers/contentItemEditorNotifications/ValidationErrorNotificationBar.tsx';
import { VariantQuickActionsNotificationBar } from '../containers/contentItemEditorNotifications/variantQuickActionsNotification/VariantQuickActionsNotificationBar.tsx';
import { ElementsAreInaccessibleNotificationBar } from './contentItemEditorNotifications/ElementsAreInaccessibleNotificationBar.tsx';
import { MissingCapabilityNotificationBar } from './contentItemEditorNotifications/MissingCapabilityNotificationBar.tsx';
import { SignalRTooManyTimeoutsWarning } from './contentItemEditorNotifications/SignalRTooManyTimeoutsWarning.tsx';
import { DefaultVariantOverwriteNotificationBar } from './contentItemEditorNotifications/overwriteNotification/DefaultVariantOverwriteNotificationBar.tsx';

export interface IContentItemNotificationsOwnProps {
  readonly useCompactView?: boolean;
  readonly hasReducedSpacing?: boolean;
}

export interface IContentItemNotificationsStateProps {
  readonly elementsAreInaccessibleTrigger: ContentGroupSelectionReason | null;
  readonly showDefaultVariantOverwrittenNotification: boolean;
  readonly showIncompleteDefaultVariantNotificationBar: boolean;
  readonly showIncompleteItemNotificationBar: boolean;
  readonly showOverwriteNotification: boolean;
  readonly showRestoredNotification: boolean;
  readonly showSignalRNotConnectedWarning: boolean;
}

export type ContentItemNotificationsProps = IContentItemNotificationsOwnProps &
  IContentItemNotificationsStateProps;

export const ContentItemNotificationsWrapper = styled.div`
  grid-area: notifications;
`;

export const ContentItemNotifications: React.FC<ContentItemNotificationsProps> = (
  props: ContentItemNotificationsProps,
) => {
  return (
    <ContentItemNotificationsWrapper role="status">
      <Stack
        spacing={Spacing.S}
        className={classNames('content-item-editor__notifications', {
          'content-item-editor__notifications--has-reduced-spacing': props.hasReducedSpacing,
        })}
        role="region"
        aria-label="notifications"
      >
        <ItemLiveUsersNotificationBar showUserNamesInMessage={!props.useCompactView} />
        {props.showSignalRNotConnectedWarning && <SignalRTooManyTimeoutsWarning />}
        {!props.showOverwriteNotification && (
          <>
            <VariantQuickActionsNotificationBar />
            <ContentComponentConvertedToItemNotificationBar />
          </>
        )}
        <PublishingNotificationBar />
        <ArchivedStepNotificationBar />
        {props.showRestoredNotification && <RevisionRestoredNotificationBar />}
        {props.showIncompleteDefaultVariantNotificationBar && (
          <IncompleteDefaultVariantNotificationBar />
        )}
        {props.showIncompleteItemNotificationBar && <IncompleteItemNotificationBar />}
        {props.elementsAreInaccessibleTrigger && (
          <ElementsAreInaccessibleNotificationBar trigger={props.elementsAreInaccessibleTrigger} />
        )}
        {props.showDefaultVariantOverwrittenNotification && (
          <DefaultVariantOverwriteNotificationBar />
        )}
        {props.showOverwriteNotification && <OverwriteNotificationBar />}
        <NotificationBar />
        <MissingCapabilityNotificationBar />
        <SavingFailedNotificationBar />
        <ValidationErrorNotificationBar />
        <TranslationProgressNotificationBar />
      </Stack>
    </ContentItemNotificationsWrapper>
  );
};

ContentItemNotifications.displayName = 'ContentItemNotifications';
