import { Button } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { disabledInvitationManagementButtonBalloonText } from '../../applications/environmentSettings/users/constants/invitationManagementBalloonTexts.ts';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';

export enum ActionsPlacement {
  SubscriptionUserDetail = 'SubscriptionUserDetail',
  UserDetail = 'UserDetail',
  UserListing = 'UserListing',
}

interface IPendingInvitationActionsProps {
  readonly isAdminOnProject: boolean;
  readonly isInviteRevoking: boolean;
  readonly isInviteSending: boolean;
  readonly isInviteSent: boolean;
  readonly onResendInvite: () => void;
  readonly onRevokeInvite: () => void;
  readonly placement: ActionsPlacement;
}

const shouldUseLongerText = (placement: ActionsPlacement) =>
  placement === ActionsPlacement.UserDetail;

export const ResendButton = React.forwardRef<HTMLButtonElement, IPendingInvitationActionsProps>(
  (
    {
      isAdminOnProject,
      isInviteRevoking,
      isInviteSending,
      isInviteSent,
      onResendInvite,
      placement,
    },
    forwardedRef,
  ) => {
    const buttonSize =
      placement === ActionsPlacement.UserListing ||
      placement === ActionsPlacement.SubscriptionUserDetail
        ? 'small'
        : undefined;

    if (isInviteSending) {
      return (
        <Button ref={forwardedRef} buttonStyle="secondary" disabled size={buttonSize}>
          <Button.ProgressIcon />
          <Button.Label>
            {shouldUseLongerText(placement) ? 'Sending invitation' : 'Sending'}
          </Button.Label>
        </Button>
      );
    }

    if (isInviteSent) {
      return (
        <Button ref={forwardedRef} buttonStyle="secondary" disabled size={buttonSize}>
          <Button.Label>{shouldUseLongerText(placement) ? 'Invitation sent' : 'Sent'}</Button.Label>
        </Button>
      );
    }

    return (
      <Button
        ref={forwardedRef}
        buttonStyle="secondary"
        disabled={isInviteRevoking || isAdminOnProject}
        onClick={onResendInvite}
        size={buttonSize}
        {...getDataUiActionAttribute(DataUiAction.Resend)}
      >
        <Button.Label>
          {shouldUseLongerText(placement) ? 'Resend invitation' : 'Resend'}
        </Button.Label>
      </Button>
    );
  },
);
ResendButton.displayName = 'ResendButton';

export const RevokeButton = React.forwardRef<HTMLButtonElement, IPendingInvitationActionsProps>(
  (
    { isAdminOnProject, isInviteRevoking, isInviteSending, onRevokeInvite, placement },
    forwardedRef,
  ) => {
    const buttonSize =
      placement === ActionsPlacement.UserListing ||
      placement === ActionsPlacement.SubscriptionUserDetail
        ? 'small'
        : undefined;

    if (isInviteRevoking) {
      return (
        <Button ref={forwardedRef} buttonStyle="secondary" disabled size={buttonSize}>
          <Button.ProgressIcon />
          <Button.Label>
            {shouldUseLongerText(placement) ? 'Revoking invitation' : 'Revoking'}
          </Button.Label>
        </Button>
      );
    }

    return (
      <Button
        ref={forwardedRef}
        buttonStyle="secondary"
        disabled={isInviteSending || isAdminOnProject}
        onClick={onRevokeInvite}
        size={buttonSize}
        {...getDataUiActionAttribute(DataUiAction.Revoke)}
      >
        <Button.Label>
          {shouldUseLongerText(placement) ? 'Revoke invitation' : 'Revoke'}
        </Button.Label>
      </Button>
    );
  },
);
RevokeButton.displayName = 'RevokeButton';

export const PendingInvitationActions: React.FC<IPendingInvitationActionsProps> = (props) => {
  const { isAdminOnProject } = props;
  return (
    <div
      className="action-link__pane"
      {...getDataUiCollectionAttribute(DataUiCollection.InvitationOptions)}
    >
      <Inline spacing={Spacing.S} noWrap>
        <Tooltip
          tooltipText={isAdminOnProject ? disabledInvitationManagementButtonBalloonText : undefined}
          placement="top"
        >
          <ResendButton {...props} />
        </Tooltip>
        <Tooltip
          tooltipText={isAdminOnProject ? disabledInvitationManagementButtonBalloonText : undefined}
          placement="top"
        >
          <RevokeButton {...props} />
        </Tooltip>
      </Inline>
    </div>
  );
};
PendingInvitationActions.displayName = 'PendingInvitationActions';
