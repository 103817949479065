import { Divider, DividerDirection } from '@kontent-ai/component-library/Dividers';
import { Paper } from '@kontent-ai/component-library/Paper';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { BorderRadius, Spacing } from '@kontent-ai/component-library/tokens';
import { ICancellablePromise, delay, swallowCancelledPromiseError } from '@kontent-ai/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { QuickTip } from '../../../../../../_shared/components/infos/QuickTip.tsx';
import { Minute, SampleProjectPreparationTime } from '../../../constants/onboardingConstants.ts';
import { getAgeOfProject, getRoundedUpTimeInMinutes } from '../../../utils/timeUtils.ts';
import { MobileSampleAppSection } from './MobileSampleAppSection.tsx';
import { RunWebsiteLocallySection } from './RunWebsiteLocallySection.tsx';

export interface IDemoSampleAppSectionProps {
  readonly fallbackSampleMobileAppUrl: string;
  readonly isCurrentUserEmployee: boolean;
  readonly isSampleProject: boolean;
  readonly projectActivatedAt: DateTimeStamp;
  readonly tagMobileApp: () => void;
  readonly trackLinkClicked: (linkId: string, target: string) => void;
  readonly userSampleMobileAppUrl: string;
}

export const DemoSampleAppsSection: React.FC<IDemoSampleAppSectionProps> = (props) => {
  const [sampleProjectReadyIn, setSampleProjectReadyIn] = useState<number | null>(
    () =>
      Math.max(
        0,
        SampleProjectPreparationTime - getAgeOfProject(props.projectActivatedAt, new Date()),
      ) || null,
  );

  const isSampleProjectReady = sampleProjectReadyIn === null;
  const sampleMobileAppUrl = isSampleProjectReady
    ? props.userSampleMobileAppUrl
    : props.fallbackSampleMobileAppUrl;

  useEffect(() => {
    const createTimerPromise = (timeLeft: number): ICancellablePromise =>
      delay(Math.min(Minute, timeLeft))
        .then(() => {
          if (timeLeft <= Minute) {
            setSampleProjectReadyIn(null);
            return;
          }
          const newTimeLeft = timeLeft - Minute;
          setSampleProjectReadyIn(newTimeLeft);
          return createTimerPromise(newTimeLeft);
        })
        .catch(swallowCancelledPromiseError);

    const timerPromise = sampleProjectReadyIn ? createTimerPromise(sampleProjectReadyIn) : null;

    return () => timerPromise?.cancel();
  }, [sampleProjectReadyIn]);

  const { trackLinkClicked, tagMobileApp } = props;
  const openMobileApp = useCallback(
    (linkId: string, target: string): void => {
      trackLinkClicked(linkId, target);
      tagMobileApp();
    },
    [trackLinkClicked, tagMobileApp],
  );

  return (
    <div className="quickstart__demo-section">
      <Paper padding={Spacing.XL} borderRadius={BorderRadius.L}>
        <Stack spacing={Spacing.XL}>
          {props.isSampleProject && (
            <h3 className="quickstart__section-title">Test our sample applications</h3>
          )}
          {props.isSampleProject && !isSampleProjectReady && (
            <WaitForSampleProjectMessage waitTime={sampleProjectReadyIn} />
          )}
          <Row spacing={Spacing.None} alignY="normal">
            {sampleMobileAppUrl && (
              <>
                <Column flexFactor={1}>
                  <div className="sample-app-section">
                    <MobileSampleAppSection
                      sampleMobileAppUrl={sampleMobileAppUrl}
                      isCurrentUserEmployee={props.isCurrentUserEmployee}
                      onMobileAppOpened={openMobileApp}
                    />
                  </div>
                </Column>
                <Column width="content">
                  <Divider
                    direction={DividerDirection.Vertical}
                    offsetBefore={Spacing.XL}
                    offsetAfter={Spacing.XL}
                  />
                </Column>
              </>
            )}
            <Column flexFactor={1}>
              <div className="sample-app-section">
                <RunWebsiteLocallySection trackLinkClick={props.trackLinkClicked} />
              </div>
            </Column>
          </Row>
        </Stack>
      </Paper>
    </div>
  );
};

DemoSampleAppsSection.displayName = 'DemoSampleAppsSection';

const WaitForSampleProjectMessage: React.FC<{ readonly waitTime: number }> = (props) => {
  const timeToWait = getRoundedUpTimeInMinutes(props.waitTime);
  const minutesUnitLabel = timeToWait === '1' ? 'minute' : 'minutes';

  return (
    <QuickTip hideTitle>
      We’re preparing your Sample project. It’ll be ready within {timeToWait} {minutesUnitLabel}.
      While you wait, the mobile sample app will use content from our shared Sample project.
    </QuickTip>
  );
};

WaitForSampleProjectMessage.displayName = 'WaitForSampleProjectMessage';
