import { ReactNode } from 'react';
import { AccessibleLoader } from '../../../../../../_shared/components/AccessibleLoader.tsx';
import { useThunkPromise } from '../../../../../../_shared/hooks/useThunkPromise.ts';
import { ensureInventoryRelatedData } from '../../ContentItemInventory/actions/thunkContentItemInventoryActions.ts';

type Props = {
  readonly children: ReactNode;
};

export const EnsureInventoryRelatedData = (props: Props) => {
  const [isThunkDone] = useThunkPromise(ensureInventoryRelatedData);

  return isThunkDone ? (
    props.children
  ) : (
    <AccessibleLoader screenReaderOnly screenReaderText="Loading content inventory" />
  );
};
