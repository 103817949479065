import { Collection } from '@kontent-ai/utils';
import { useCallback, useReducer, useRef } from 'react';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import {
  getItemsBetweenInclusive,
  rangeSelectionShouldSelect,
} from '../../../../_shared/utils/itemSelectionUtils.ts';
import { assetSelectionToggled } from '../actions/thunks/toggleAssetSelection.ts';
import { AssetIdUpdate } from '../types/assetIdUpdate.type.ts';

type UseAssetSelectionArgs = {
  readonly listingAssetsIds: Immutable.OrderedSet<Uuid>;
  readonly reduxDispatch: Dispatch;
};

export type UseAssetSelectionReturnType = {
  readonly clearAssetSelection: () => void;
  readonly deselectAssets: (assetIds: ReadonlySet<Uuid>) => void;
  readonly selectAssets: (assetIds: ReadonlyArray<Uuid>) => void;
  readonly selectedAssets: ReadonlySet<Uuid>;
  readonly toggleAssetSelection: (assetId: Uuid, isShiftSelection: boolean) => void;
  readonly updateAssetId: (assetIdUpdate: AssetIdUpdate) => void;
};

export const useAssetSelection = ({
  listingAssetsIds,
  reduxDispatch,
}: UseAssetSelectionArgs): UseAssetSelectionReturnType => {
  const [selectedAssets, dispatch] = useReducer(selectedAssetsReducer, new Set<Uuid>());

  const lastSelectionTouchedAssetId = useRef<Uuid | null>(null);

  const toggleAssetSelection = (assetId: Uuid, isShiftSelection: boolean): void => {
    if (!isShiftSelection || !lastSelectionTouchedAssetId.current) {
      dispatch({ type: AssetSelectionActionType.ToggleSelection, assetId });
      reduxDispatch(assetSelectionToggled(assetId)); // Remove when not needed. We can get rid of this once we get rid of the lastAction reducer (this makes it work).
      lastSelectionTouchedAssetId.current = assetId;

      return;
    }

    const affectedIdsImmutable = getItemsBetweenInclusive(
      lastSelectionTouchedAssetId.current,
      assetId,
      listingAssetsIds.valueSeq(),
    );
    const affectedIds = new Set(affectedIdsImmutable.toArray());
    const shouldSelect = rangeSelectionShouldSelect(
      affectedIds,
      selectedAssets,
      lastSelectionTouchedAssetId.current,
    );

    if (!shouldSelect) {
      dispatch({ type: AssetSelectionActionType.DeselectAssets, assetIds: affectedIds });
      lastSelectionTouchedAssetId.current = assetId;

      return;
    }

    dispatch({ type: AssetSelectionActionType.SelectAssets, assetIds: affectedIds });
    lastSelectionTouchedAssetId.current = assetId;
  };

  const deselectAssets = useCallback((assetIds: ReadonlySet<Uuid>): void => {
    dispatch({ type: AssetSelectionActionType.DeselectAssets, assetIds });
  }, []);

  const selectAssets = useCallback((assetIds: ReadonlyArray<Uuid>): void => {
    dispatch({ type: AssetSelectionActionType.SelectAssets, assetIds: new Set(assetIds) });

    const lastAssetId = assetIds[assetIds.length - 1];
    if (lastAssetId) {
      lastSelectionTouchedAssetId.current = lastAssetId;
    }
  }, []);

  const clearAssetSelection = useCallback((): void => {
    dispatch({ type: AssetSelectionActionType.ClearSelection });
    lastSelectionTouchedAssetId.current = null;
  }, []);

  const updateAssetId = useCallback((assetIdUpdate: AssetIdUpdate): void => {
    dispatch({ type: AssetSelectionActionType.UpdateAssetId, ...assetIdUpdate });
    if (lastSelectionTouchedAssetId.current === assetIdUpdate.oldAssetId) {
      lastSelectionTouchedAssetId.current = assetIdUpdate.newAssetId;
    }
  }, []);

  return {
    clearAssetSelection,
    deselectAssets,
    selectAssets,
    selectedAssets,
    toggleAssetSelection,
    updateAssetId,
  };
};

enum AssetSelectionActionType {
  ClearSelection = 'ClearSelection',
  DeselectAssets = 'DeselectAssets',
  SelectAssets = 'SelectAssets',
  ToggleSelection = 'ToggleSelection',
  UpdateAssetId = 'UpdateAssetId',
}

type AssetSelectionAction =
  | {
      readonly type: AssetSelectionActionType.ClearSelection;
    }
  | {
      readonly type:
        | AssetSelectionActionType.SelectAssets
        | AssetSelectionActionType.DeselectAssets;
      readonly assetIds: ReadonlySet<Uuid>;
    }
  | {
      readonly type: AssetSelectionActionType.ToggleSelection;
      readonly assetId: Uuid;
    }
  | ({
      readonly type: AssetSelectionActionType.UpdateAssetId;
    } & AssetIdUpdate);

const selectedAssetsReducer = (
  state: ReadonlySet<Uuid>,
  action: AssetSelectionAction,
): ReadonlySet<Uuid> => {
  switch (action.type) {
    case AssetSelectionActionType.SelectAssets:
      return new Set([...state, ...action.assetIds]);

    case AssetSelectionActionType.DeselectAssets:
      return Collection.filter(state, (assetId) => !action.assetIds.has(assetId));

    case AssetSelectionActionType.ToggleSelection: {
      const { assetId } = action;

      return state.has(assetId)
        ? Collection.remove(state, assetId)
        : Collection.add(state, assetId);
    }

    case AssetSelectionActionType.UpdateAssetId: {
      const { newAssetId, oldAssetId } = action;

      if (state.has(oldAssetId)) {
        const newState = Collection.remove(state, oldAssetId);

        return Collection.add(newState, newAssetId);
      }

      return state;
    }

    case AssetSelectionActionType.ClearSelection:
      return new Set();
  }
};
