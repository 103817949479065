import { getParent, getSelfOrParent } from '@kontent-ai/DOM';
import { notNull } from '@kontent-ai/utils';
import { XYCoord } from 'dnd-core';
import { DropFilesTarget } from '../../../../../_shared/components/DragDrop/FileDropContainer.tsx';
import {
  DataUiAttributes,
  DataUiElement,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  RteClassName,
  isContentBlockElement,
  isRichText,
} from '../../../editorCore/utils/editorComponentUtils.ts';

export const RteAcceptsFilesClassName = `${RteClassName}--accepts-files`;

function isRichTextEditorAcceptingFiles(element: Element): boolean {
  return isRichText(element) && element.classList.contains(RteAcceptsFilesClassName);
}

function isUploadDropZone(element: Element): boolean {
  return element.getAttribute(DataUiAttributes.Element) === DataUiElement.UploadDropzone;
}

function isActiveFileDropContainer(element: Element): boolean {
  return isRichTextEditorAcceptingFiles(element) || isUploadDropZone(element);
}

export function findDropFilesTarget(
  editorElement: HTMLElement,
  clientOffset: XYCoord,
  lastTarget: DropFilesTarget | null,
): DropFilesTarget | null {
  const elementsFromPoint = document.elementsFromPoint(clientOffset.x, clientOffset.y);

  // Check whether the closest container receiving files is the current editor, if not, don't allow drop
  const topMostElementFromPoint = elementsFromPoint[0];
  if (
    !topMostElementFromPoint ||
    getSelfOrParent(topMostElementFromPoint, isActiveFileDropContainer) !== editorElement
  ) {
    return null;
  }

  // Collect possible targets, omit targets whose editors do not support file drop
  const targetCandidates = elementsFromPoint
    .filter((element, index) => index === 0 || isContentBlockElement(element))
    .map((element) => {
      const targetBlockElement = getSelfOrParent(element, isContentBlockElement);
      if (!targetBlockElement) {
        return null;
      }
      const editor = getParent(targetBlockElement, isRichText);
      if (!editor || !isRichTextEditorAcceptingFiles(editor)) {
        return null;
      }
      return {
        targetBlockElement,
        editor,
      };
    })
    .filter(notNull);

  const topMostTargetCandidate = targetCandidates[0];
  if (topMostTargetCandidate && topMostTargetCandidate.editor === editorElement) {
    const targetBlockElement = topMostTargetCandidate.targetBlockElement;
    const targetBlockKey = topMostTargetCandidate.targetBlockElement
      .getAttribute('data-offset-key')
      ?.split('-')[0];
    if (targetBlockKey) {
      return {
        element: targetBlockElement,
        id: targetBlockKey,
      };
    }
  }

  // Reaching here means we are in the right editor, but didn't find a proper target
  // keep the last target to prevent target flickering when moving over inactive places
  return lastTarget;
}
