import { Collection } from '@kontent-ai/utils';
import { DraftBlockRenderMap } from 'draft-js';
import React from 'react';
import { DropTarget } from '../../../../../_shared/components/DragDrop/DropTarget.tsx';
import { getBlockFromBlockElement } from '../../draftJs/utils/draftJsEditorUtils.ts';
import { TableCellWrapper } from '../../tables/components/TableCellWrapper.tsx';
import { getAcceptedDropTypes } from '../utils/getAcceptedDropTypes.ts';
import { DroppableBlockProps } from './DroppableBlockWrapperBase.tsx';

type Props = DroppableBlockProps & {
  readonly children?: Array<JSX.Element>;
  readonly getRenderMap: () => DraftBlockRenderMap;
  readonly onContextMenu?: (blockKey: string, cellRef: React.RefObject<HTMLElement>) => void;
};

export const DroppableTableWrapper = ({
  canUpdate,
  children,
  hoveringCollisionStrategy,
  getRenderMap,
  onContextMenu,
  onMove,
  parentId,
}: Props) => {
  if (!children) {
    return null;
  }

  const firstChild = Collection.getFirst(React.Children.toArray(children));
  const firstChildBlock = React.isValidElement(firstChild)
    ? getBlockFromBlockElement(firstChild)
    : null;

  const beforeTable = firstChildBlock && (
    // We need to place an extra drop target before the table to make sure two neighbouring tables have drop target between them
    // It cannot be placed around the whole table as it could intercept with the drop targets nested in table cell content
    // Drop target for position after the table which is placed at the end of the editor is provided by the editor itself
    <DropTarget<HTMLDivElement>
      accept={getAcceptedDropTypes(firstChildBlock)}
      canDrop={canUpdate}
      hoveringCollisionStrategy={hoveringCollisionStrategy}
      onMove={onMove}
      parentId={parentId}
      renderDroppable={(ref) => (
        <div className="rte__droptarget" contentEditable={false} ref={ref} />
      )}
      targetId={firstChildBlock.getKey()}
    />
  );

  return (
    <TableCellWrapper
      beforeTable={beforeTable}
      getRenderMap={getRenderMap}
      onContextMenu={onContextMenu}
    >
      {children}
    </TableCellWrapper>
  );
};
