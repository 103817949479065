import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Menu } from '@kontent-ai/component-library/Menu';
import React, { RefObject } from 'react';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly onDuplicateItem: () => void;
  readonly onRemoveItem: () => void;
};

export const LinkedItemMoreActionsMenu: React.FC<Props> = ({ onDuplicateItem, onRemoveItem }) => (
  <Menu>
    <Menu.Trigger>
      {(ref: RefObject<HTMLButtonElement>, triggerProps, isOpen) => (
        <QuinaryButton
          buttonStyle="default"
          aria-label="More actions"
          ref={ref}
          onClick={triggerProps.onClick}
          tooltipText={isOpen ? '' : 'More actions'}
          tooltipPlacement="bottom"
          {...triggerProps}
          {...getDataUiActionAttribute(DataUiAction.MoreActions)}
        >
          <QuinaryButton.Icon icon={Icons.Ellipsis} />
        </QuinaryButton>
      )}
    </Menu.Trigger>
    <Menu.List>
      <Menu.Item
        id="duplicate"
        label="Duplicate"
        onAction={onDuplicateItem}
        leadingElement={<Icon iconName={IconName.DocCopy} />}
        {...getDataUiActionAttribute(DataUiAction.Duplicate)}
      />
      <Menu.Item
        id="remove"
        label="Remove"
        onAction={onRemoveItem}
        leadingElement={<Icon iconName={IconName.Bin} />}
        {...getDataUiActionAttribute(DataUiAction.Delete)}
      />
    </Menu.List>
  </Menu>
);
