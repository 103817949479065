import { usePrevious } from '@kontent-ai/hooks';
import { ContentState, EditorState, SelectionState } from 'draft-js';
import { useCallback, useLayoutEffect, useRef } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { setContentSelection } from '../../utils/editorSelectionUtils.ts';
import { SelectionAfter, executeContentChange } from '../../utils/editorStateUtils.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
} from '../../utils/general/editorContentUtils.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { GetEditorState, GetInitialState, Reinit } from '../draftJs/DraftJsPlugin.type.ts';

type ExternalUpdatePlugin = DraftJsEditorPlugin;

type ExternalUpdateHandlerProps = {
  readonly getEditorState: GetEditorState;
  readonly lastExternalEditorStateCandidate: EditorState | null;
  readonly lastForcedSelectionRef: React.MutableRefObject<SelectionState | null>;
  readonly reinit: Reinit;
};

const ExternalUpdateHandler: React.FC<ExternalUpdateHandlerProps> = ({
  getEditorState,
  lastExternalEditorStateCandidate,
  lastForcedSelectionRef,
  reinit,
}) => {
  const previousExternalEditorStateCandidate = usePrevious(lastExternalEditorStateCandidate);

  useLayoutEffect(() => {
    if (
      lastExternalEditorStateCandidate &&
      lastExternalEditorStateCandidate !== previousExternalEditorStateCandidate
    ) {
      const newState = getNewEditorStateForComponent(
        getEditorState(),
        lastExternalEditorStateCandidate,
        lastForcedSelectionRef.current,
      );
      if (newState) {
        lastForcedSelectionRef.current = newState.lastForcedSelection;
        reinit(newState.editorState);
      }
    }
  }, [
    lastExternalEditorStateCandidate,
    previousExternalEditorStateCandidate,
    reinit,
    getEditorState,
    lastForcedSelectionRef,
  ]);

  return null;
};

ExternalUpdateHandler.displayName = 'ExternalUpdateHandler';

export const ExternalUpdatePlugin: PluginComponent<ExternalUpdatePlugin> = (props) => {
  const { editorState } = props;

  const lastForcedSelectionRef = useRef<SelectionState | null>(null);

  const lastExternalEditorStateCandidateRef = useRef<EditorState | null>(null);
  if (
    editorState.mustForceSelection() ||
    lastForcedSelectionRef.current !== editorState.getSelection()
  ) {
    lastExternalEditorStateCandidateRef.current = editorState;
  }
  const lastExternalEditorStateCandidate = lastExternalEditorStateCandidateRef.current;

  const render: Decorator<Render<ExternalUpdatePlugin>> = useCallback(
    (baseRender) => (state) => (
      <>
        {baseRender(state)}
        <ExternalUpdateHandler
          getEditorState={state.getEditorState}
          lastExternalEditorStateCandidate={lastExternalEditorStateCandidate}
          lastForcedSelectionRef={lastForcedSelectionRef}
          reinit={state.reinit}
        />
      </>
    ),
    [lastExternalEditorStateCandidate],
  );

  const getInitialState: Decorator<GetInitialState> = useCallback(
    (baseGetInitialState) => (initialEditorState) => {
      const state = baseGetInitialState(initialEditorState);

      if (state.initialEditorState.mustForceSelection()) {
        lastForcedSelectionRef.current = state.initialEditorState.getSelection();
      }

      return state;
    },
    [],
  );

  const apply: Apply<ExternalUpdatePlugin> = useCallback(
    (state) => {
      state.getInitialState.decorate(getInitialState);
      state.render.decorate(render);

      return {};
    },
    [getInitialState, render],
  );

  return useEditorWithPlugin(props, { apply });
};

interface IUpdatedInputComponentState {
  editorState: EditorState;
  lastForcedSelection: SelectionState | null;
}

function applyNewContentToContentState(
  input: IContentChangeInput,
  newContent: ContentState,
  originalSelection: SelectionState,
): IContentChangeResult {
  return {
    // We get the updated content, but with original selection kept
    content: setContentSelection(
      newContent,
      input.content.getSelectionBefore(),
      originalSelection,
      originalSelection.getHasFocus(),
    ),
    selection: originalSelection,
    wasModified: true,
  };
}

function applyNewContent(editorState: EditorState, newEditorState: EditorState): EditorState {
  const selection = editorState.getSelection();

  // Currently only changes left from Redux state are comment styles and regenerating URL slug
  // Having said that, we know for sure undo or selection change is never needed
  return executeContentChange(
    editorState,
    selection,
    (input) => applyNewContentToContentState(input, newEditorState.getCurrentContent(), selection),
    newEditorState.getLastChangeType(),
    false,
    SelectionAfter.NewWithOriginalFocus,
  );
}

function getNewEditorStateForComponent(
  editorState: EditorState,
  newEditorState: EditorState,
  lastForcedSelection: SelectionState | null,
): IUpdatedInputComponentState | null {
  if (editorState !== newEditorState) {
    const content = editorState.getCurrentContent();
    const newContent = newEditorState.getCurrentContent();

    // Get selection state from props only in case the external selection is forced, but only for the first time, remember it
    // Otherwise apply only content and keep current selection
    const newSelection = newEditorState.getSelection();
    const forcedSelection =
      newEditorState.mustForceSelection() && lastForcedSelection !== newSelection
        ? newSelection
        : null;

    if (content !== newContent || forcedSelection) {
      const updatedEditorState =
        content !== newContent ? applyNewContent(editorState, newEditorState) : editorState;
      const updatedEditorStateWithSelection = forcedSelection
        ? EditorState.forceSelection(updatedEditorState, newEditorState.getSelection())
        : updatedEditorState;

      return {
        editorState: updatedEditorStateWithSelection,
        lastForcedSelection: forcedSelection || lastForcedSelection,
      };
    }
  }
  return null;
}
