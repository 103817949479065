import { Box } from '@kontent-ai/component-library/Box';
import { colorBackground, px } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { WebSpotlightZIndex } from '../../../../../webSpotlight/constants/WebSpotlightZIndex.ts';
import { useSharedContentItemPreviewRouteState } from '../../../../../webSpotlight/hooks/useSharedContentItemPreviewRouteState.ts';
import { WebSpotlightPreviewResolutionType } from '../../../../../webSpotlight/models/webSpotlightPreviewResolutionType.ts';
import { useWebSpotlightInItemEditing } from '../../context/WebSpotlightInItemEditingContext.tsx';
import { useWebSpotlightInItemEditingMessaging } from '../../hooks/useWebSpotlightInItemEditingMessaging.ts';
import { useWebSpotlightPreviewInItemEditingInitialization } from '../../hooks/useWebSpotlightPreviewInItemEditingInitialization.ts';
import { WebSpotlightPreviewResizeHandlesInItemEditing } from './WebSpotlightPreviewResizeHandles.tsx';

type IStyledIFrameProps = Readonly<{
  fitScreen: boolean;
  height: number;
  scale: number;
  width: number;
}>;

const StyledIFrame = styled.iframe.attrs((props: IStyledIFrameProps) => ({
  style: {
    width: props.fitScreen ? '100%' : px(props.width),
    height: props.fitScreen ? '100%' : px(props.height),
    transform: `scale(${props.fitScreen ? 1 : props.scale})`,
  },
}))<IStyledIFrameProps>`
  background-color: ${colorBackground};
  transform-origin: ${(props) => (props.scale < 1 ? '0 0' : 'center top')};
  z-index: ${WebSpotlightZIndex.PreviewIFrame}
`;

type WebSpotlightPreviewSandboxProps = Readonly<{
  isResizable: boolean;
}>;

export const WebSpotlightPreviewSandboxInItemEditing: React.FC<WebSpotlightPreviewSandboxProps> = ({
  isResizable,
}) => {
  const {
    isSmartLinkSdkInitialized,
    previewIFrameKey,
    setPreviewIFrameRef,
    previewIFrameResolutionType,
    previewIFrameResolution,
    previewUrl,
  } = useWebSpotlightInItemEditing();

  const containerWidth = useMemo(
    () =>
      previewIFrameResolutionType !== WebSpotlightPreviewResolutionType.FitScreen &&
      previewIFrameResolution.scale < 1
        ? previewIFrameResolution.width * previewIFrameResolution.scale
        : 'auto',
    [previewIFrameResolutionType, previewIFrameResolution.scale, previewIFrameResolution.width],
  );

  useWebSpotlightInItemEditingMessaging();
  useWebSpotlightPreviewInItemEditingInitialization();

  const [sharedPreviewState] = useSharedContentItemPreviewRouteState();

  const previewIframe = (
    <StyledIFrame
      key={previewIFrameKey}
      title="Preview"
      ref={setPreviewIFrameRef}
      src={sharedPreviewState?.url ?? previewUrl}
      fitScreen={previewIFrameResolutionType === WebSpotlightPreviewResolutionType.FitScreen}
      width={previewIFrameResolution.width}
      height={previewIFrameResolution.height}
      scale={previewIFrameResolution.scale}
      sandbox="allow-forms allow-modals allow-popups allow-same-origin allow-scripts allow-downloads allow-storage-access-by-user-activation"
      className={classNames(
        'web-spotlight-preview__sandbox',
        'web-spotlight__layout-guard-content',
        {
          'web-spotlight-preview__sandbox--is-initialized': isSmartLinkSdkInitialized,
        },
      )}
    />
  );

  return (
    <Box
      width={containerWidth}
      height="100%"
      marginX="auto"
      marginY={0}
      paddingRight={0}
      paddingBottom={0}
    >
      {isResizable ? (
        <WebSpotlightPreviewResizeHandlesInItemEditing>
          {previewIframe}
        </WebSpotlightPreviewResizeHandlesInItemEditing>
      ) : (
        previewIframe
      )}
    </Box>
  );
};
