import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { NoItemsFoundEmptyState } from '../containers/NoItemsFoundEmptyState.tsx';
import { ProjectOverview as ProjectOverviewContainer } from '../containers/ProjectOverview.tsx';
import { EmptyProjectEmptyState } from './EmptyProjectEmptyState.tsx';

export enum ProjectOverviewPageType {
  LimitExceeded = 'limit-exceeded',
  NoItemsFound = 'no-items-found',
  ProjectOverview = 'project-overview',
  EmptyProject = 'empty-project',
}

type Props = {
  readonly onOpenCreateNewItemDialog: () => void;
  readonly projectOverviewPageContentType: ProjectOverviewPageType;
};

export const ProjectOverviewPageContent = ({
  onOpenCreateNewItemDialog,
  projectOverviewPageContentType,
}: Props) => {
  switch (projectOverviewPageContentType) {
    case ProjectOverviewPageType.LimitExceeded:
      return (
        <EmptyState size="fullOffset">
          <EmptyState.Title>We found too many items to display.</EmptyState.Title>
          <EmptyState.Content>
            Use the filters on the left to narrow the results.
          </EmptyState.Content>
        </EmptyState>
      );
    case ProjectOverviewPageType.NoItemsFound:
      return <NoItemsFoundEmptyState />;
    case ProjectOverviewPageType.ProjectOverview:
      return <ProjectOverviewContainer />;
    case ProjectOverviewPageType.EmptyProject:
      return <EmptyProjectEmptyState onCallToAction={onOpenCreateNewItemDialog} />;
  }
};
