import { Redirect, Route, Switch } from 'react-router';
import { AuthorizedSection as AuthorizedSectionComponent } from '../../../../../_shared/components/routing/AuthorizedSection.tsx';
import { useRedirectPropsWithSameSearch } from '../../../../../_shared/components/routing/useRedirectPropsWithSameSearch.tsx';
import {
  SubscriptionEnvironmentAuditLogRoute,
  SubscriptionEnvironmentCollectionsRoute,
  SubscriptionEnvironmentCustomAppConfigurationCreatingRoute,
  SubscriptionEnvironmentCustomAppConfigurationEditingRoute,
  SubscriptionEnvironmentCustomAppConfigurationEditingRouteParams,
  SubscriptionEnvironmentCustomAppsConfigurationRoute,
  SubscriptionEnvironmentEnvironmentsRoute,
  SubscriptionEnvironmentGeneralSettingsRoute,
  SubscriptionEnvironmentLegacyWebhookMessagesRoute,
  SubscriptionEnvironmentLegacyWebhookMessagesRouteParams,
  SubscriptionEnvironmentLegacyWebhooksRoute,
  SubscriptionEnvironmentLocalizationRoute,
  SubscriptionEnvironmentPreviewURLsRoute,
  SubscriptionEnvironmentRoleCreatorRoute,
  SubscriptionEnvironmentRoleEditorRoute,
  SubscriptionEnvironmentRoleEditorRouteParams,
  SubscriptionEnvironmentRolesRoute,
  SubscriptionEnvironmentSettingsRouteParams,
  SubscriptionEnvironmentSpacesRoute,
  SubscriptionEnvironmentUsersRoute,
  SubscriptionEnvironmentWebhookMessagesRoute,
  SubscriptionEnvironmentWebhookMessagesRouteParams,
  SubscriptionEnvironmentWebhooksCreatingRoute,
  SubscriptionEnvironmentWebhooksEditingRoute,
  SubscriptionEnvironmentWebhooksEditorRouteParams,
  SubscriptionEnvironmentWebhooksRoute,
  SubscriptionEnvironmentWorkflowsRoute,
} from '../../../../../_shared/constants/routePaths.ts';
import { AuthorizedSection } from '../../../../../_shared/containers/routing/AuthorizedSection.tsx';
import {
  IRouteContext,
  RouteContext,
} from '../../../../../_shared/containers/routing/RouteContext.tsx';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { EntityWebhookCreatorForm } from '../../../../entityWebhooks/containers/EntityWebhookCreatorForm.tsx';
import { EntityWebhookEditor } from '../../../../entityWebhooks/containers/EntityWebhookEditor.tsx';
import { EntityWebhookListing } from '../../../../entityWebhooks/containers/EntityWebhookListing.tsx';
import { AuditLog } from '../../../../environmentSettings/auditLog/containers/AuditLog.tsx';
import { CollectionsApp } from '../../../../environmentSettings/collections/containers/CollectionsApp.tsx';
import { CustomAppListing } from '../../../../environmentSettings/customApps/components/CustomAppListing.tsx';
import { CustomAppCreator } from '../../../../environmentSettings/customApps/containers/CustomAppCreator.tsx';
import { CustomAppEditor } from '../../../../environmentSettings/customApps/containers/CustomAppEditor.tsx';
import { GeneralSettings } from '../../../../environmentSettings/general/containers/GeneralSettings.tsx';
import { InnovationLabApp } from '../../../../environmentSettings/innovationLab/components/InnovationLabApp.tsx';
import { InnovationLabSettingsRoute } from '../../../../environmentSettings/innovationLab/constants/routePaths.ts';
import { LanguagesListing } from '../../../../environmentSettings/localization/containers/LanguagesListing.tsx';
import { RoleCreator } from '../../../../environmentSettings/roles/containers/RoleCreator.tsx';
import { RoleEditor } from '../../../../environmentSettings/roles/containers/RoleEditor.tsx';
import { RoleListing } from '../../../../environmentSettings/roles/containers/RoleListing.tsx';
import { EnvironmentSettingsApp } from '../../../../environmentSettings/root/components/EnvironmentSettingsApp.tsx';
import { EnvironmentSettingsAppNames } from '../../../../environmentSettings/root/constants/EnvironmentSettingsAppNames.ts';
import { SpacesApp } from '../../../../environmentSettings/spaces/containers/SpacesApp.tsx';
import { UserListing } from '../../../../environmentSettings/users/containers/userListing/UserListing.tsx';
import { WorkflowSettings } from '../../../../environmentSettings/workflow/components/WorkflowSettings.tsx';
import { PreviewConfigurationApp } from '../../../../previewConfiguration/containers/PreviewConfigurationApp.tsx';
import { EnvironmentListing } from '../../../../projectSettings/environments/containers/EnvironmentListing.tsx';
import { ProjectSettingsAppNames } from '../../../../projectSettings/root/constants/ProjectSettingsAppNames.ts';
import { WebhookListingPage } from '../../../../webhooks/containers/WebhookListing.tsx';
import { WebhookMessageListing } from '../../../../webhooks/containers/WebhookMessageListing.tsx';

type Props = {
  readonly areCollectionsEnabled: boolean;
  readonly areLegacyWebhooksEnabled: boolean;
  readonly areGeneralSettingsVisible: boolean;
  readonly areSpacesEnabled: boolean;
  readonly isAuditLogEnabled: boolean;
  readonly isInnovationLabAccessible: boolean;
  readonly projectId: Uuid;
  readonly subscriptionId: Uuid;
};

export const EnvironmentSettings = ({
  areCollectionsEnabled,
  areLegacyWebhooksEnabled,
  areGeneralSettingsVisible,
  areSpacesEnabled,
  isAuditLogEnabled,
  isInnovationLabAccessible,
  projectId,
  subscriptionId,
}: Props) => {
  const getRedirectPropsWithSameSearch = useRedirectPropsWithSameSearch();

  return (
    <Switch>
      <Route path={InnovationLabSettingsRoute}>
        <AuthorizedSectionComponent
          appName={EnvironmentSettingsAppNames.InnovationLab}
          isAuthorized={isInnovationLabAccessible}
          projectId={projectId}
        >
          <InnovationLabApp />
        </AuthorizedSectionComponent>
      </Route>
      <Route path={SubscriptionEnvironmentGeneralSettingsRoute}>
        <AuthorizedSectionComponent
          appName={EnvironmentSettingsAppNames.General}
          isAuthorized={areGeneralSettingsVisible}
          projectId={projectId}
        >
          <EnvironmentSettingsApp>
            <GeneralSettings />
          </EnvironmentSettingsApp>
        </AuthorizedSectionComponent>
      </Route>
      <Route path={SubscriptionEnvironmentUsersRoute}>
        <AuthorizedSection
          requiresOneOfCapabilities={[Capability.ManageUsers]}
          appName={EnvironmentSettingsAppNames.Users}
        >
          <EnvironmentSettingsApp>
            <UserListing projectId={projectId} subscriptionId={subscriptionId} />
          </EnvironmentSettingsApp>
        </AuthorizedSection>
      </Route>
      <Route path={SubscriptionEnvironmentWorkflowsRoute} render={() => <WorkflowSettings />} />
      {areSpacesEnabled && (
        <Route path={SubscriptionEnvironmentSpacesRoute}>
          <AuthorizedSection
            requiresOneOfCapabilities={[Capability.ManageSpaces]}
            appName={EnvironmentSettingsAppNames.Spaces}
          >
            <EnvironmentSettingsApp>
              <SpacesApp />
            </EnvironmentSettingsApp>
          </AuthorizedSection>
        </Route>
      )}
      {areCollectionsEnabled && (
        <Route path={SubscriptionEnvironmentCollectionsRoute}>
          <AuthorizedSection
            requiresOneOfCapabilities={[Capability.ManageCollections]}
            appName={EnvironmentSettingsAppNames.Collections}
          >
            <EnvironmentSettingsApp>
              <CollectionsApp />
            </EnvironmentSettingsApp>
          </AuthorizedSection>
        </Route>
      )}
      <Route path={SubscriptionEnvironmentLocalizationRoute}>
        <AuthorizedSection
          requiresOneOfCapabilities={[Capability.ConfigureLanguages]}
          appName={EnvironmentSettingsAppNames.Localization}
        >
          <EnvironmentSettingsApp>
            <LanguagesListing />
          </EnvironmentSettingsApp>
        </AuthorizedSection>
      </Route>
      <Route path={SubscriptionEnvironmentPreviewURLsRoute}>
        <AuthorizedSection
          requiresOneOfCapabilities={[Capability.ConfigurePreviewUrl]}
          appName={EnvironmentSettingsAppNames.PreviewConfiguration}
        >
          <EnvironmentSettingsApp>
            <PreviewConfigurationApp />
          </EnvironmentSettingsApp>
        </AuthorizedSection>
      </Route>
      <Route path={SubscriptionEnvironmentWebhookMessagesRoute}>
        <RouteContext>
          {({ match }: IRouteContext<SubscriptionEnvironmentWebhookMessagesRouteParams>) => (
            <AuthorizedSection
              requiresOneOfCapabilities={[Capability.ManageWebhooks]}
              appName={EnvironmentSettingsAppNames.Webhooks}
            >
              <WebhookMessageListing webhookId={match.params.webhookId} />
            </AuthorizedSection>
          )}
        </RouteContext>
      </Route>
      <Route path={SubscriptionEnvironmentWebhooksCreatingRoute}>
        <AuthorizedSection
          requiresOneOfCapabilities={[Capability.ManageWebhooks]}
          appName={EnvironmentSettingsAppNames.Webhooks}
        >
          <EntityWebhookCreatorForm />
        </AuthorizedSection>
      </Route>
      <Route path={SubscriptionEnvironmentWebhooksEditingRoute}>
        <RouteContext>
          {({ match }: IRouteContext<SubscriptionEnvironmentWebhooksEditorRouteParams>) => (
            <AuthorizedSection
              requiresOneOfCapabilities={[Capability.ManageWebhooks]}
              appName={EnvironmentSettingsAppNames.Webhooks}
            >
              <EntityWebhookEditor editedEntityWebhookId={match.params.webhookId} />
            </AuthorizedSection>
          )}
        </RouteContext>
      </Route>
      <Route path={SubscriptionEnvironmentWebhooksRoute}>
        <AuthorizedSection
          requiresOneOfCapabilities={[Capability.ManageWebhooks]}
          appName={EnvironmentSettingsAppNames.Webhooks}
        >
          <EnvironmentSettingsApp>
            <EntityWebhookListing projectId={projectId} subscriptionId={subscriptionId} />
          </EnvironmentSettingsApp>
        </AuthorizedSection>
      </Route>
      {areLegacyWebhooksEnabled && (
        <Route path={SubscriptionEnvironmentLegacyWebhookMessagesRoute}>
          <RouteContext>
            {({
              match,
            }: IRouteContext<SubscriptionEnvironmentLegacyWebhookMessagesRouteParams>) => (
              <AuthorizedSection
                requiresOneOfCapabilities={[Capability.ManageWebhooks]}
                appName={EnvironmentSettingsAppNames.LegacyWebhooks}
              >
                <WebhookMessageListing webhookId={match.params.webhookId} />
              </AuthorizedSection>
            )}
          </RouteContext>
        </Route>
      )}
      {areLegacyWebhooksEnabled && (
        <Route path={SubscriptionEnvironmentLegacyWebhooksRoute}>
          <AuthorizedSection
            requiresOneOfCapabilities={[Capability.ManageWebhooks]}
            appName={EnvironmentSettingsAppNames.LegacyWebhooks}
          >
            <EnvironmentSettingsApp>
              <WebhookListingPage />
            </EnvironmentSettingsApp>
          </AuthorizedSection>
        </Route>
      )}
      <Route path={SubscriptionEnvironmentRoleCreatorRoute}>
        <RouteContext>
          {({ history }: IRouteContext<SubscriptionEnvironmentRoleEditorRouteParams>) => (
            <AuthorizedSection
              requiresOneOfCapabilities={[Capability.ManageRoles]}
              appName={EnvironmentSettingsAppNames.Roles}
            >
              <RoleCreator
                projectId={projectId}
                withSubscriptionIdInRoute={subscriptionId}
                history={history}
              />
            </AuthorizedSection>
          )}
        </RouteContext>
      </Route>
      <Route path={SubscriptionEnvironmentRoleEditorRoute}>
        <RouteContext>
          {({ match }: IRouteContext<SubscriptionEnvironmentRoleEditorRouteParams>) => (
            <AuthorizedSection
              requiresOneOfCapabilities={[Capability.ManageRoles]}
              appName={EnvironmentSettingsAppNames.Roles}
            >
              <RoleEditor
                projectId={projectId}
                withSubscriptionIdInRoute={subscriptionId}
                roleId={match.params.roleId}
              />
            </AuthorizedSection>
          )}
        </RouteContext>
      </Route>
      <Route path={SubscriptionEnvironmentRolesRoute}>
        <AuthorizedSection
          requiresOneOfCapabilities={[Capability.ManageRoles]}
          appName={EnvironmentSettingsAppNames.Roles}
        >
          <EnvironmentSettingsApp>
            <RoleListing projectId={projectId} withSubscriptionIdInRoute={subscriptionId} />
          </EnvironmentSettingsApp>
        </AuthorizedSection>
      </Route>
      <Route path={SubscriptionEnvironmentEnvironmentsRoute}>
        <AuthorizedSectionComponent
          isAuthorized
          appName={ProjectSettingsAppNames.Environments}
          projectId={projectId}
        >
          <EnvironmentSettingsApp>
            <EnvironmentListing withSubscriptionIdInRoute={subscriptionId} />
          </EnvironmentSettingsApp>
        </AuthorizedSectionComponent>
      </Route>
      {isAuditLogEnabled && (
        <Route path={SubscriptionEnvironmentAuditLogRoute}>
          <AuthorizedSection
            requiresOneOfCapabilities={[Capability.AccessAuditLog]}
            appName={EnvironmentSettingsAppNames.AuditLog}
          >
            <EnvironmentSettingsApp>
              <AuditLog />
            </EnvironmentSettingsApp>
          </AuthorizedSection>
        </Route>
      )}
      <Route path={SubscriptionEnvironmentCustomAppConfigurationCreatingRoute}>
        <AuthorizedSection
          requiresOneOfCapabilities={[Capability.ManageCustomApps]}
          appName={EnvironmentSettingsAppNames.CustomApps}
        >
          <CustomAppCreator subscriptionId={subscriptionId} />
        </AuthorizedSection>
      </Route>
      <Route path={SubscriptionEnvironmentCustomAppConfigurationEditingRoute}>
        <RouteContext>
          {({
            match,
          }: IRouteContext<SubscriptionEnvironmentCustomAppConfigurationEditingRouteParams>) => (
            <AuthorizedSection
              requiresOneOfCapabilities={[Capability.ManageCustomApps]}
              appName={EnvironmentSettingsAppNames.CustomApps}
            >
              <CustomAppEditor
                customAppId={match.params.customAppId}
                subscriptionId={subscriptionId}
              />
            </AuthorizedSection>
          )}
        </RouteContext>
      </Route>
      <Route path={SubscriptionEnvironmentCustomAppsConfigurationRoute}>
        <AuthorizedSection
          requiresOneOfCapabilities={[Capability.ManageCustomApps]}
          appName={EnvironmentSettingsAppNames.CustomApps}
        >
          <EnvironmentSettingsApp>
            <CustomAppListing subscriptionId={subscriptionId} />
          </EnvironmentSettingsApp>
        </AuthorizedSection>
      </Route>
      <Route>
        <Redirect
          {...getRedirectPropsWithSameSearch({
            to: buildPath<SubscriptionEnvironmentSettingsRouteParams>(
              SubscriptionEnvironmentGeneralSettingsRoute,
              {
                projectId,
                subscriptionId,
              },
            ),
          })}
        />
      </Route>
    </Switch>
  );
};
