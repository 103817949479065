import { HTMLAttributes } from 'react';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { Divider } from '../../Dividers/Divider.tsx';
import { DividerDirection } from '../../Dividers/components/StyledDivider.tsx';

export const MenuDivider = (props: Omit<HTMLAttributes<HTMLElement>, 'color'>) => (
  <Divider
    color={BaseColor.Gray20}
    direction={DividerDirection.Horizontal}
    offsetBefore={Spacing.L}
    offsetAfter={Spacing.S}
    role="separator"
    {...props}
  />
);
