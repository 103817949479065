import { memoize } from '@kontent-ai/memoization';
import { ComponentProps, useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { loadListingItems } from '../../applications/itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import {
  getCurrentProjectContainerId,
  getCurrentProjectId,
} from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { loadBreadcrumbs } from '../actions/thunkSharedActions.ts';
import {
  Breadcrumbs as BreadcrumbsComponent,
  NavigationBreadcrumb,
} from '../components/Breadcrumbs.tsx';
import { ContentItemRouteParams } from '../constants/routePaths.ts';
import { useSelector } from '../hooks/useSelector.ts';
import { useThunkPromise } from '../hooks/useThunkPromise.ts';
import { Breadcrumb } from '../models/Breadcrumb.type.ts';
import { isMultipleWorkflowsConfigurationVisible } from '../selectors/Workflows/isMultipleWorkflowsConfigurationVisible.ts';
import { getBreadcrumbs } from '../selectors/breadcrumbs/getBreadcrumbs.ts';
import { getBreadcrumbsOrigin } from '../selectors/breadcrumbs/getBreadcrumbsOrigin.ts';
import { getSelectedLanguageIdOrRouteMacro } from '../selectors/getSelectedLanguageId.ts';
import { replaceLanguageMacroInBreadcrumbs } from '../utils/breadcrumbs/breadcrumbResolvers.ts';
import { parseContentItemIds } from '../utils/routing/routeTransitionUtils.ts';
import { isUuid } from '../utils/validation/typeValidators.ts';

const decorateBreadcrumbs = memoize.maxOne(
  (
    currentProjectId: Uuid,
    breadcrumbs: ReadonlyArray<Breadcrumb>,
    selectedLanguageId: Uuid,
  ): ReadonlyArray<NavigationBreadcrumb> => {
    return replaceLanguageMacroInBreadcrumbs(breadcrumbs, selectedLanguageId).map(
      (breadcrumb: Breadcrumb) => ({
        label: breadcrumb.title,
        to: `${
          breadcrumb.useProjectIdPrefix && isUuid(currentProjectId) ? `/${currentProjectId}` : ''
        }${breadcrumb.path}`,
      }),
    );
  },
);

type Props = Pick<ComponentProps<typeof BreadcrumbsComponent>, 'renderSavingStatusComponent'>;

export const Breadcrumbs = (props: Props) => {
  const location = useLocation();
  const match = useRouteMatch<Partial<ContentItemRouteParams<Uuid>>>();

  const breadcrumbs = useSelector(getBreadcrumbs);
  const breadcrumbsOrigin = useSelector(getBreadcrumbsOrigin);
  const isMultipleWorkflowsConfigurationAllowed = useSelector(
    isMultipleWorkflowsConfigurationVisible,
  );
  const listingContentItems = useSelector((state) => state.data.listingContentItems);
  const customApps = useSelector((state) => state.data.userCustomApps.customApps);
  const projectId = useSelector(getCurrentProjectId);
  const projectContainerId = useSelector(getCurrentProjectContainerId);
  const selectedLanguageId = useSelector(getSelectedLanguageIdOrRouteMacro);
  const canLoadProjectBreadcrumbs: boolean = !!projectId || !!projectContainerId;

  const params = useMemo(
    () => ({
      location,
      breadcrumbsOrigin,
      isMultipleWorkflowsConfigurationAllowed,
      listingContentItems,
      customApps,
    }),
    [
      location,
      breadcrumbsOrigin,
      isMultipleWorkflowsConfigurationAllowed,
      listingContentItems,
      customApps,
    ],
  );
  useThunkPromise(loadBreadcrumbs, params, { canRun: canLoadProjectBreadcrumbs });

  const contentItemIds = match.params.contentItemIds
    ? parseContentItemIds(match.params.contentItemIds)
    : null;

  useThunkPromise(loadListingItems, contentItemIds ?? [], {
    canRun: !!contentItemIds && !!projectId,
  });

  if (!breadcrumbs.length) {
    return null;
  }

  return (
    <BreadcrumbsComponent
      renderSavingStatusComponent={props.renderSavingStatusComponent}
      breadcrumbs={decorateBreadcrumbs(projectId, breadcrumbs, selectedLanguageId)}
    />
  );
};
