import { noOperation } from '@kontent-ai/utils';
import { useCallback, useMemo } from 'react';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  CodenameEventType,
  CodenameTargetType,
} from '../../../../../_shared/models/TrackUserEventData.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { ContentTypeOrSnippetEditorToolbarActions } from '../../../shared/components/statusBar/ContentTypeOrSnippetEditorToolbarActions.tsx';
import { getElementsWithAffectedLocalization } from '../../../shared/utils/getElementsWithAffectedLocalization.ts';
import { getTypeCodenamesWithoutEditedSnippetCodename } from '../../../shared/utils/typeCodenameUtils.ts';
import { changeContentTypeSnippetCodename } from '../../actions/snippetsActions.ts';
import { saveEditedContentTypeSnippet } from '../../actions/thunkSnippetsActions.ts';

const getRelatedCodenames = (s: IStore): ReadonlySet<string> => {
  const {
    contentTypes: { byId: contentTypesById },
    snippets: { byId: snippetsById },
  } = s.data;
  const editedSnippetId = s.contentModelsApp.snippets.editor.editedContentTypeSnippet.id;

  return getTypeCodenamesWithoutEditedSnippetCodename(
    contentTypesById,
    snippetsById,
    editedSnippetId,
  );
};

export const ContentTypeSnippetEditorToolbarActions = () => {
  const dispatch = useDispatch();
  const codename = useSelector(
    (s) => s.contentModelsApp.snippets.editor.editedContentTypeSnippet.codename,
  );
  const relatedCodenames = useSelector((s) => getRelatedCodenames(s));
  const isBeingSaved = useSelector(
    (s) => s.contentModelsApp.snippets.editor.contentTypeSnippetIsBeingSaved,
  );
  const isLoading = useSelector(
    (s) =>
      s.contentModelsApp.snippets.editor.contentTypeSnippetEditingLoadingStatus !==
      LoadingStatus.Loaded,
  );
  const editedTypeSnippet = useSelector(
    (s) => s.contentModelsApp.snippets.editor.editedContentTypeSnippet,
  );
  const originalTypeSnippetElements = useSelector(
    (s) => s.data.snippets.byId.get(editedTypeSnippet.id)?.typeElements,
  );

  const elementsWithAffectedLocalization = useMemo(
    () => getElementsWithAffectedLocalization(editedTypeSnippet, originalTypeSnippetElements),
    [editedTypeSnippet, originalTypeSnippetElements],
  );

  const onSave = useMemo(() => compose(dispatch, saveEditedContentTypeSnippet), []);
  const onCodenameSave = useCallback((newCodename: string, onSaved: () => void) => {
    dispatch(changeContentTypeSnippetCodename(newCodename));
    onSaved();
  }, []);
  const onCodenameCopy = useCallback(
    () =>
      dispatch(
        trackUserEventWithData(TrackedEvent.Codename, {
          type: CodenameEventType.Copy,
          target: CodenameTargetType.ContentTypeSnippet,
        }),
      ),
    [],
  );
  const onCodenameEdit = useCallback(
    () =>
      dispatch(
        trackUserEventWithData(TrackedEvent.Codename, {
          type: CodenameEventType.Edit,
          target: CodenameTargetType.ContentTypeSnippet,
        }),
      ),
    [],
  );

  return (
    <ContentTypeOrSnippetEditorToolbarActions
      codename={codename}
      elementsWithAffectedLocalization={elementsWithAffectedLocalization}
      hideLongProcessingChangesWarning
      isBeingSaved={isBeingSaved}
      isCodenameEditable
      relatedCodenames={relatedCodenames}
      isLoading={isLoading}
      onSave={onSave}
      onCodenameSave={onCodenameSave}
      onCodenameCopy={onCodenameCopy}
      onCodenameEdit={onCodenameEdit}
      onDismissWarning={noOperation}
    />
  );
};
