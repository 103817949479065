import React, { memo } from 'react';
import { DataUiElement } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  EditorProps,
  emptyPluginComposition,
  useEditor,
  usePluginComposition,
} from '../../editorCore/Editor.composition.tsx';
import { AiStylesPlugin } from '../../plugins/ai/AiStylesPlugin.tsx';
import { ApiLimitationsPlugin } from '../../plugins/apiLimitations/ApiLimitationsPlugin.tsx';
import { UnlimitedEditorLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { CompositionModeAdjustmentsPlugin } from '../../plugins/behavior/CompositionModeAdjustmentsPlugin.tsx';
import { ExternalUpdatePlugin } from '../../plugins/behavior/ExternalUpdatePlugin.tsx';
import { FocusPlugin } from '../../plugins/behavior/FocusPlugin.tsx';
import { ReadonlyPlugin } from '../../plugins/behavior/ReadonlyPlugin.tsx';
import { SpellCheckPlugin } from '../../plugins/behavior/SpellCheckPlugin.tsx';
import { ClipboardPlugin } from '../../plugins/clipboard/ClipboardPlugin.tsx';
import { DisplayContentComponentsPlugin } from '../../plugins/contentComponents/DisplayContentComponentsPlugin.tsx';
import { DraftJsPlugin } from '../../plugins/draftJs/DraftJsPlugin.tsx';
import { EntityApiPlugin } from '../../plugins/entityApi/EntityApiPlugin.tsx';
import { DisplayImagesPlugin } from '../../plugins/images/DisplayImagesPlugin.tsx';
import { DisplayInlineAiPlugin } from '../../plugins/inlineAi/DisplayInlineAiPlugin.tsx';
import { DisplayInlineStylesPlugin } from '../../plugins/inlineStyles/InlineStylesPlugin.tsx';
import { KeyboardShortcutsPlugin } from '../../plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { textKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { DisplayLinkedItemsPlugin } from '../../plugins/linkedItems/DisplayLinkedItemsPlugin.tsx';
import { DisplayLinksPlugin } from '../../plugins/links/DisplayLinksPlugin.tsx';
import { DisplayAssetLinksPlugin } from '../../plugins/links/asset/DisplayAssetLinksPlugin.tsx';
import { DisplayContentLinksPlugin } from '../../plugins/links/content/DisplayContentLinksPlugin.tsx';
import { DisplayEmailLinksPlugin } from '../../plugins/links/email/DisplayEmailLinksPlugin.tsx';
import { DisplayPhoneLinksPlugin } from '../../plugins/links/phone/DisplayPhoneLinksPlugin.tsx';
import { DisplayWebLinksPlugin } from '../../plugins/links/web/DisplayWebLinksPlugin.tsx';
import { DisplayTablesPlugin } from '../../plugins/tables/DisplayTablesPlugin.tsx';
import { TextApiPlugin } from '../../plugins/textApi/TextApiPlugin.tsx';
import { DisplayTextBlockTypesPlugin } from '../../plugins/textBlockTypes/TextBlockTypesPlugin.tsx';
import { PlaceholderPlugin } from '../../plugins/visuals/PlaceholderPlugin.tsx';
import { StylesPlugin } from '../../plugins/visuals/StylesPlugin.tsx';
import { WrapperPlugin } from '../../plugins/visuals/WrapperPlugin.tsx';

export const useComposition = () =>
  usePluginComposition(
    emptyPluginComposition,
    DraftJsPlugin,
    PlaceholderPlugin,
    WrapperPlugin,
    CompositionModeAdjustmentsPlugin,
    FocusPlugin,
    ApiLimitationsPlugin,
    KeyboardShortcutsPlugin,
    TextApiPlugin,
    ClipboardPlugin,
    StylesPlugin,
    DisplayTextBlockTypesPlugin,
    DisplayInlineStylesPlugin,
    EntityApiPlugin,
    DisplayLinksPlugin,
    DisplayWebLinksPlugin,
    DisplayContentLinksPlugin,
    DisplayAssetLinksPlugin,
    DisplayEmailLinksPlugin,
    DisplayPhoneLinksPlugin,
    DisplayImagesPlugin,
    DisplayTablesPlugin,
    DisplayLinkedItemsPlugin,
    DisplayContentComponentsPlugin,
    AiStylesPlugin,
    DisplayInlineAiPlugin,
    SpellCheckPlugin,
    ExternalUpdatePlugin,
    ReadonlyPlugin,
  );

export type RichTextViewerProps = EditorProps<typeof useComposition>;

export const RichTextViewer: React.FC<RichTextViewerProps> = memo((props) => {
  const composition = useComposition();

  return useEditor(composition, {
    ...props,
    dataUiElement: props.dataUiElement ?? DataUiElement.RichTextViewer,
    keyCommandMap: textKeyCommandMap,
    limitations: UnlimitedEditorLimitations,
    tabIndex: props.tabIndex ?? -1,
  });
});

RichTextViewer.displayName = 'RichTextViewer';
