import { PropsWithChildren, forwardRef } from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { Icon } from '../Icon/Icon.tsx';
import { ISquareButtonShellProps, SquareButtonShell } from './SquareButtonShell.tsx';

interface ISquareButtonIconStateProps {
  readonly iconName: IconName;
  readonly iconClass?: string;
  readonly isActive?: boolean;
}

export type SquareButtonProps = ISquareButtonIconStateProps & ISquareButtonShellProps;

export const SquareButton = forwardRef<HTMLDivElement, PropsWithChildren<SquareButtonProps>>(
  (props, forwardedRef) => {
    const { iconName, iconClass, children, ...rest } = props;
    return (
      <SquareButtonShell ref={forwardedRef} {...rest}>
        {children}
        <Icon iconName={iconName} className={iconClass} />
      </SquareButtonShell>
    );
  },
);

SquareButton.displayName = 'SquareButton';
