import styled, { css } from 'styled-components';
import { borderWidthDefault } from '../../../tokens/decision/border.ts';
import {
  colorAlertBackgroundInverse,
  colorBackground,
  colorBorderLowEmphasis,
  colorInfoBackground,
  colorInfoBackgroundInverse,
  colorSuccessBackgroundInverse,
  colorTextDefault,
  colorTextDefaultInverse,
  colorWarningBackground,
  colorWarningBackgroundInverse,
} from '../../../tokens/decision/colors.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { BoxShadow, shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { NotificationBarType } from '../notificationBarType.ts';

interface IStyledNotificationBarWrapperProps {
  readonly $type: NotificationBarType;
  readonly $isFocusVisible?: boolean;
  readonly floating?: boolean;
}

const getStylesByType = ({ $type }: IStyledNotificationBarWrapperProps) => {
  switch ($type) {
    case 'Alert':
      return css`
        background-color: ${colorAlertBackgroundInverse}; 
        color: ${colorTextDefaultInverse};
      `;
    case 'Info':
      return css`
        background-color: ${colorInfoBackground};
        color: ${colorTextDefault};
        border: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis};
      `;
    case 'InfoEmphasis':
      return css`
        background-color: ${colorInfoBackgroundInverse}; 
        color: ${colorTextDefaultInverse};
      `;
    case 'Success':
      return css`
        background-color: ${colorSuccessBackgroundInverse}; 
        color: ${colorTextDefaultInverse};
      `;
    case 'Warning':
      return css`
        background-color: ${colorWarningBackgroundInverse}; 
        color: ${colorTextDefault};
      `;
    case 'FriendlyWarning':
      return css`
        background-color: ${colorWarningBackground};
        color: ${colorTextDefault};
      `;
    case 'Blank':
      return css`
        background-color: ${colorBackground};
        color: ${colorTextDefault};
      `;
  }
};

export const StyledNotificationBarWrapper = styled.div<IStyledNotificationBarWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${px(Spacing.XXXXL)};
  min-width: min-content;
  border-radius: ${px(BorderRadius.M)};
  position: relative;

  box-shadow: ${({ floating }) => (floating ? BoxShadow.M : BoxShadow.S)};
  ${getStylesByType};

  &::after {
    border-radius: ${px(BorderRadius.M)};
    inset: -${px(borderWidthDefault)};
    position: absolute;
    ${({ $isFocusVisible }) =>
      $isFocusVisible &&
      css`
      content: '';
      ${shadowFocusStyles};
    `};
  }
`;
