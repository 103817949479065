import { assert } from '@kontent-ai/utils';
import { useCallback, useMemo, useRef } from 'react';
import { Contract } from '../types/Editor.contract.type.ts';
import {
  Apply,
  AttachCallbacks,
  Callbacks,
  EditorPlugin,
  PluginState,
} from '../types/Editor.plugins.type.ts';
import { Decorator } from '../utils/decorable.ts';

type DecorateWithEditorStateCallbacksProps<TPlugin extends Contract> = {
  readonly decorateWithEditorStateCallbacks: Apply<TPlugin>;
};

export const useEditorStateCallbacks = <
  TPlugin extends Contract,
  TKeys extends keyof PluginState<TPlugin> = keyof PluginState<TPlugin>,
  TResult extends Readonly<Callbacks<Pick<PluginState<TPlugin>, TKeys>>> = Readonly<
    Callbacks<Pick<PluginState<TPlugin>, TKeys>>
  >,
>(): TResult & DecorateWithEditorStateCallbacksProps<TPlugin> => {
  const latestCallbacksRef = useRef<TResult>({} as TResult);
  const attachCallbacks: Decorator<AttachCallbacks<EditorPlugin>> = useCallback(
    (baseAttachCallbacks) => (state) => {
      // Update the latest callbacks
      const result = state as any as TResult;

      for (const propName in result) {
        if (Object.hasOwn(result, propName)) {
          const resultValue = result[propName];
          if (typeof resultValue === 'function') {
            latestCallbacksRef.current[propName] = resultValue;
          }
        }
      }

      baseAttachCallbacks(state);
    },
    [],
  );

  const decorateWithEditorStateCallbacks = useCallback<Apply<EditorPlugin>>(
    (state) => {
      state.attachCallbacks.decorate(attachCallbacks);

      return {};
    },
    [attachCallbacks],
  );

  const stableCallbacksRef = useRef<TResult & DecorateWithEditorStateCallbacksProps<TPlugin>>({
    decorateWithEditorStateCallbacks,
  } as TResult & DecorateWithEditorStateCallbacksProps<TPlugin>);

  const stableProxy = useMemo(
    () =>
      new Proxy(stableCallbacksRef.current, {
        get(target, propName: string) {
          const typedPropName = propName as keyof TResult;
          const stableCallback = target[typedPropName];
          if (!stableCallback) {
            // Ensure the stable callbacks as they are first requested
            const newStableCallback = (...args: unknown[]) => {
              const targetFn = latestCallbacksRef.current?.[typedPropName];
              assert(
                typeof targetFn === 'function',
                () =>
                  `${typedPropName.toString()} callback used via useEditorStateCallbacks must be a function but ${targetFn} was found.`,
              );
              return targetFn(...args);
            };
            target[typedPropName] = newStableCallback as any;
            return newStableCallback;
          }
          return stableCallback;
        },
      }),
    [],
  );

  return stableProxy;
};
