import { useCallback } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { ApplyEditorStateChanges } from '../draftJs/DraftJsPlugin.type.ts';

export type ForceSingleUnstyledBlockPlugin = DraftJsEditorPlugin;

const applyEditorStateChanges: Decorator<ApplyEditorStateChanges> =
  (baseApplyEditorStateChanges) => (params) => {
    const allowedNewState = baseApplyEditorStateChanges(params);
    const hasSingleBlock = allowedNewState.getCurrentContent().getBlockMap().size === 1;
    return hasSingleBlock ? allowedNewState : params.oldState;
  };

export const ForceSingleUnstyledBlockPlugin: PluginComponent<ForceSingleUnstyledBlockPlugin> = (
  props,
) => {
  const apply: Apply<ForceSingleUnstyledBlockPlugin> = useCallback((state) => {
    state.applyEditorStateChanges.decorate(applyEditorStateChanges);
    return {};
  }, []);

  return useEditorWithPlugin(props, { apply });
};
