import { DefaultTag, RemovalState } from '@kontent-ai/component-library/Tag';
import React from 'react';
import {
  ByStatus,
  activeAndDeactivatedUsersOption,
  activeUsersOption,
  deactivatedUsersOption,
} from '../../../../../_shared/constants/userListingFilter.ts';

interface ISubscriptionUserListingStatusFilterTag {
  readonly status: ByStatus;
  readonly onLabelClick: () => void;
  readonly onRemoveClick: () => void;
}

const getStatusFilterTagText = (status: ByStatus) => {
  switch (status) {
    case ByStatus.Active:
      return activeUsersOption.label;
    case ByStatus.Deactivated:
      return deactivatedUsersOption.label;
    case ByStatus.ActiveAndDeactivated:
      return activeAndDeactivatedUsersOption.label;
  }
};

export const SubscriptionUserListingStatusFilterTag: React.FC<
  ISubscriptionUserListingStatusFilterTag
> = ({ status, onRemoveClick, onLabelClick }) => (
  <DefaultTag
    label={getStatusFilterTagText(status)}
    onLabelClick={onLabelClick}
    onRemoveClick={onRemoveClick}
    removalState={status !== ByStatus.Active ? RemovalState.Allowed : RemovalState.NoRemoval}
  />
);

SubscriptionUserListingStatusFilterTag.displayName = 'SubscriptionUserListingStatusFilterTag';
