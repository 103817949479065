import { Button } from '@kontent-ai/component-library/Button';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { ChangeEvent, ComponentProps, useRef } from 'react';
import { FileWithThumbnail } from '../../applications/contentInventory/assets/models/FileWithThumbnail.type.ts';
import {
  DataUiAction,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiInputAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';
import { getDroppedFiles } from '../utils/fileDragDropUtils.ts';

type ButtonProps = ComponentProps<typeof Button>;

interface IBrowseButtonProps {
  readonly accept?: string;
  readonly dataUiAction?: DataUiAction;
  readonly buttonStyle: ButtonProps['buttonStyle'];
  readonly buttonSize?: ButtonProps['size'];
  readonly destructive?: ButtonProps['destructive'];
  readonly buttonText: string;
  readonly multiple?: boolean;
  readonly onUpload: ((files: ReadonlyArray<FileWithThumbnail>) => void) | null;
  readonly supportClick?: boolean;
  readonly tooltipText?: string;
  readonly screenReaderText?: string;
}

export const BrowseButton = (props: IBrowseButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const upload = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e.target?.files ?? [];
    const filesWithThumbnail = getDroppedFiles(Array.prototype.slice.call(files, 0, files.length));

    props.onUpload?.(filesWithThumbnail);
  };

  const onClick = () => {
    if ((props.supportClick ?? true) && fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  return (
    <Tooltip placement="top" tooltipText={props.tooltipText}>
      <span className="notranslate">
        <input
          {...getDataUiInputAttribute(DataUiInput.UploadFiles)}
          style={{ display: 'none' }}
          type="file"
          multiple={props.multiple ?? true}
          ref={fileInputRef}
          onChange={upload}
          accept={props.accept}
        />
        <Button
          aria-label={props.screenReaderText}
          buttonStyle={props.buttonStyle}
          size={props.buttonSize}
          onClick={onClick}
          {...(props.dataUiAction && getDataUiActionAttribute(props.dataUiAction))}
          disabled={!props.supportClick}
        >
          {props.buttonText}
        </Button>
      </span>
    </Tooltip>
  );
};
