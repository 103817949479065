import { Collection } from '@kontent-ai/utils';
import { isCodenameUnique } from '../../../applications/contentModels/shared/utils/typeCodenameUtils.ts';
import {
  ArrowPosition,
  Popover,
  PopoverIsPointing,
  PopoverMessage,
} from '../../uiComponents/Popover/Popover.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Codename } from './Codename.tsx';
import { OnSaveCodename } from './OnSaveCodename.type.ts';

interface Props {
  readonly arrowPosition?: ArrowPosition;
  readonly codename: string;
  readonly isEditable?: boolean;
  readonly maxLength?: number;
  readonly onAfterCopied: () => void;
  readonly onCopied?: () => void;
  readonly onEdited?: () => void;
  readonly onSave?: OnSaveCodename;
  readonly relatedCodenames?: ReadonlySet<string>;
  readonly snippetCodename?: string | null;
}

export const CodenamePopover = (props: Props) => {
  const isCodenameUniqueCallback = (codename: string) =>
    isCodenameUnique(
      codename,
      props.relatedCodenames && Collection.remove(props.relatedCodenames, props.codename),
    );

  return (
    <Popover
      orientation={PopoverIsPointing.Up}
      arrowPosition={props.arrowPosition || ArrowPosition.End}
      hintDataAttribute={getDataUiElementAttribute(DataUiElement.CodenameForm)}
      hasFullWidth
    >
      <PopoverMessage>
        <Codename
          mode="confirm"
          codename={props.codename}
          isEditable={props.isEditable}
          isCodenameUnique={isCodenameUniqueCallback}
          maxLength={props.maxLength}
          onSave={props.onSave}
          onCopyCompleted={props.onAfterCopied}
          onEdited={props.onEdited}
          snippetCodename={props.snippetCodename}
          onCopied={props.onCopied}
        />
      </PopoverMessage>
    </Popover>
  );
};
