import React from 'react';
import { getDropDown } from '../../../../_shared/components/DropDown.tsx';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { CalendarArrangement, CalendarArrangementValues } from '../models/CalendarArrangement.ts';

export interface ICalendarArrangementSwitcherOption {
  readonly name: string;
  readonly arrangement: CalendarArrangement;
}

const Dropdown = getDropDown<ICalendarArrangementSwitcherOption>();

const getArrangementName = (arrangement: CalendarArrangement): string => {
  switch (arrangement) {
    case CalendarArrangement.Month:
      return 'Calendar view';
    case CalendarArrangement.Agenda:
      return 'Agenda view';
  }
};

const createSwitcherOption = (
  arrangement: CalendarArrangement,
): ICalendarArrangementSwitcherOption => ({
  name: getArrangementName(arrangement),
  arrangement,
});

const arrangements: ReadonlyArray<ICalendarArrangementSwitcherOption> =
  CalendarArrangementValues.map(createSwitcherOption);

export interface ICalendarArrangementSwitcherStateProps {
  readonly selectedArrangement: CalendarArrangement;
}

export interface ICalendarArrangementSwitcherDispatchProps {
  readonly onArrangementChange: (selectedArrangement: CalendarArrangement) => void;
}

type ICalendarArrangementSwitcherProps = ICalendarArrangementSwitcherStateProps &
  ICalendarArrangementSwitcherDispatchProps;

export const CalendarArrangementSwitcher: React.FC<ICalendarArrangementSwitcherProps> = ({
  onArrangementChange,
  selectedArrangement,
}) => {
  const selectedArrangementName = getArrangementName(selectedArrangement);
  const selectedOption = arrangements.find(
    (value: ICalendarArrangementSwitcherOption) => value.name === selectedArrangementName,
  );

  return (
    <Dropdown
      className="calendar__arrangement-switcher"
      selectedOption={selectedOption}
      onSelect={(option) => onArrangementChange(option.arrangement)}
      renderOptionName={(option) => option.name}
      getOptionId={(option) => option.arrangement}
      options={arrangements}
      dataUiCollectionName={DataUiCollection.CalendarArrangementSelector}
    />
  );
};

CalendarArrangementSwitcher.displayName = 'CalendarArrangementSwitcher';
