import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  Spacing,
  borderWidthDefault,
  colorBorderLowEmphasis,
  px,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import { OpenInNewTabButton } from '../../containers/contentItemPreview/OpenInNewTabButton.tsx';
import { RefreshPreviewButton } from '../../containers/contentItemPreview/RefreshPreviewButton.tsx';
import { SpaceSelector } from '../../containers/contentItemPreview/SpaceSelector.tsx';

export const ContentItemPreviewActionBar: React.FC = () => (
  <Box
    paddingX={Spacing.L}
    paddingY={Spacing.S}
    css={`border-bottom: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis}`}
  >
    <Row>
      <Column>
        <SpaceSelector />
      </Column>

      <Column width="fit-content">
        <Row spacingX={Spacing.M}>
          <Column>
            <OpenInNewTabButton />
          </Column>
          <Column>
            <RefreshPreviewButton />
          </Column>
        </Row>
      </Column>
    </Row>
  </Box>
);
