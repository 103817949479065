import { createEnsureSelectedVariantInRouteAction } from '../../../_shared/actions/thunks/ensureSelectedVariantInRoute.ts';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import { getPathWithReplacedVariantIdForMissionControlLanguageDependentRoutes } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  loadCollections,
  loadContentTypes,
  loadLanguages,
  loadUsers,
  loadWorkflows,
} from '../../../data/actions/thunkDataActions.ts';
import { contentVariantIdStorage } from '../../../localStorages/contentVariantStorage.ts';
import { localizedRouteEntered } from './missionControlActions.ts';
import { initMissionControlActionCreator } from './thunks/initMissionControl.ts';

export const initMissionControl = initMissionControlActionCreator({
  loadUsers,
  loadContentTypes,
  loadWorkflows,
});

export const ensureSelectedVariantForMissionControlLanguageDependentRoutes =
  createEnsureSelectedVariantInRouteAction({
    appName: AppNames.MissionControl,
    loadCollections,
    loadLanguages,
    contentVariantIdStorage,
    getPathWithReplacedVariantId:
      getPathWithReplacedVariantIdForMissionControlLanguageDependentRoutes,
    localizedRouteEntered,
  });
