import React from 'react';
import { BarItemEditAction } from '../../../../../_shared/components/BarItems/Actions/BarItemEditAction.tsx';
import { NonExistentBarItemActions } from '../../../../../_shared/components/BarItems/Actions/NonExistentBarItemActions.tsx';
import { BarItemActions } from '../../../../../_shared/components/BarItems/BarItemActions.tsx';
import { BarItemBar } from '../../../../../_shared/components/BarItems/BarItemBar.tsx';
import { BarItemTitle } from '../../../../../_shared/components/BarItems/BarItemTitle.tsx';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';

export interface IDefaultLanguageBarDataProps {
  readonly defaultLanguage: ILanguage;
  readonly canBeEdited: boolean;
}

export interface IDefaultLanguageBarCallbacksProps {
  readonly onEdit: () => void;
}

export const DefaultLanguageBar: React.FC<
  IDefaultLanguageBarDataProps & IDefaultLanguageBarCallbacksProps
> = ({ defaultLanguage, canBeEdited, onEdit }) => (
  <div className="bar-item__wrapper">
    <div className="bar-item__pane">
      <BarItemBar isClickable={canBeEdited}>
        <BarItemActions>
          <NonExistentBarItemActions />
        </BarItemActions>
        <BarItemTitle
          flexFactor={1}
          onClick={canBeEdited ? onEdit : undefined}
          title={defaultLanguage.name}
        >
          {defaultLanguage.name}
        </BarItemTitle>
        <BarItemTitle
          flexFactor={1}
          onClick={canBeEdited ? onEdit : undefined}
          title="Default language"
          titleStyle="secondary"
        >
          Default language
        </BarItemTitle>
        <BarItemActions>
          <BarItemEditAction onClick={onEdit} disabled={!canBeEdited} />
        </BarItemActions>
      </BarItemBar>
    </div>
  </div>
);

DefaultLanguageBar.displayName = 'DefaultLanguageBar';
