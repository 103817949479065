import { AvatarSize, ButtonAvatar } from '@kontent-ai/component-library/Avatar';
import { Divider, DividerDirection } from '@kontent-ai/component-library/Dividers';
import { Menu, TabbableMenuItem } from '@kontent-ai/component-library/Menu';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { RefObject } from 'react';
import { AppNames } from '../../constants/applicationNames.ts';
import { ProjectsRoute, SubscriptionsRoute, UserProfileRoute } from '../../constants/routePaths.ts';
import { IUserInfo } from '../../models/UserInfo.ts';
import {
  DataUiAction,
  DataUiAppName,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiNavAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { getExistingUserNewColorGradient, getUserInitials } from '../../utils/usersUtils.ts';

export interface IUserMenuDataProps {
  readonly currentUser: IUserInfo;
  readonly showSubscriptionLink: boolean;
}

export interface IUserMenuDispatchProps {
  readonly trackMenuOpened: () => void;
  readonly onSignOutClick: () => void;
}

interface IUserMenuProps extends IUserMenuDataProps, IUserMenuDispatchProps {}

export const UserMenu: React.FC<IUserMenuProps> = ({
  currentUser,
  onSignOutClick,
  showSubscriptionLink,
  trackMenuOpened,
}) => (
  <Menu
    onOpenChange={(isOpen) => {
      if (isOpen) {
        trackMenuOpened();
      }
    }}
  >
    <Menu.Trigger>
      {(ref, triggerProps, isOpen) => (
        <ButtonAvatar
          activated={isOpen}
          backgroundGradient={getExistingUserNewColorGradient(currentUser)}
          label="Your profile"
          initials={getUserInitials(currentUser)}
          ref={ref as RefObject<HTMLButtonElement>}
          size={AvatarSize.M}
          tooltipPlacement="right"
          {...getDataUiActionAttribute(DataUiAction.OpenUserMenu)}
          {...triggerProps}
        />
      )}
    </Menu.Trigger>
    <Menu.List
      placement="right bottom"
      role="list"
      {...getDataUiCollectionAttribute(DataUiCollection.UserMenu)}
    >
      <TabbableMenuItem
        id="user-profile"
        label={AppNames.UserProfile}
        linkPath={UserProfileRoute}
        {...getDataUiNavAttribute(DataUiAppName.UserProfile)}
      />
      <TabbableMenuItem
        id="projects"
        label={AppNames.Projects}
        linkPath={ProjectsRoute}
        {...getDataUiNavAttribute(DataUiAppName.Projects)}
      />
      {showSubscriptionLink && (
        <TabbableMenuItem
          id="subscriptions"
          label={AppNames.Subscriptions}
          linkPath={SubscriptionsRoute}
          {...getDataUiNavAttribute(DataUiAppName.SubscriptionListing)}
        />
      )}
      <Divider
        direction={DividerDirection.Horizontal}
        offsetAfter={Spacing.M}
        offsetBefore={Spacing.M}
      />
      <TabbableMenuItem
        id="sign-out"
        label="Sign out"
        onAction={onSignOutClick}
        {...getDataUiActionAttribute(DataUiAction.Logout)}
      />
    </Menu.List>
  </Menu>
);

UserMenu.displayName = 'UserMenu';
