import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';

export const ContentGroupTabCommentsCssClass = 'content-group-tab';
export const SelectedContentGroupTabCommentsCssClass = 'content-group-tab--is-selected';

interface IContentGroupTabCommentsProps {
  readonly commentCount?: number;
  readonly onCommentsClick: () => void;
}

export const ContentGroupTabComments: React.FC<IContentGroupTabCommentsProps> = ({
  commentCount,
  onCommentsClick,
}) => {
  if (commentCount === 0) {
    return null;
  }

  return (
    <div className="tabbed-navigation__tab-info">
      <QuinaryButton
        onClick={onCommentsClick}
        tooltipPlacement="top"
        tooltipText=""
        aria-label={pluralizeWithCount('comment', commentCount)}
        aria-description="focus on the first comment"
      >
        <QuinaryButton.Icon icon={Icons.Bubble} />
        <QuinaryButton.Badge value={commentCount} aria-hidden />
      </QuinaryButton>
    </div>
  );
};

ContentGroupTabComments.displayName = 'ContentGroupTabComments';
