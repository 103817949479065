import { Inline } from '@kontent-ai/component-library/Inline';
import { RemovalState, Tag } from '@kontent-ai/component-library/Tag';
import {
  Spacing,
  colorBackgroundDisabledComplementary,
} from '@kontent-ai/component-library/tokens';
import React from 'react';

interface IImageTags {
  readonly tags: ReadonlyArray<string>;
}

export const ImageTags: React.FC<IImageTags> = ({ tags }: IImageTags): JSX.Element => {
  return (
    <Inline spacing={Spacing.XS}>
      {tags.map((tag) => (
        <Tag
          background={colorBackgroundDisabledComplementary}
          key={tag}
          label={tag}
          removalState={RemovalState.NoRemoval}
        />
      ))}
    </Inline>
  );
};
