import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import { ProjectType } from '../../../../_shared/models/ProjectType.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { GettingStartedQuickstart } from '../containers/GettingStartedQuickstart/GettingStartedQuickstart.tsx';
import { SampleProjectQuickstart } from './SampleProjectQuickstart/SampleProjectQuickstart.tsx';

interface IQuickstartProps {
  readonly projectType: ProjectType;
}

export const Quickstart = (props: IQuickstartProps): JSX.Element => (
  <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.Quickstart)}>
    <HtmlPageTitle appName={AppNames.Quickstart} />
    {props.projectType === ProjectType.GettingStarted && <GettingStartedQuickstart />}
    {props.projectType === ProjectType.DancingGoat && <SampleProjectQuickstart />}
  </div>
);

Quickstart.displayName = 'Quickstart';
