import { AppMenuItem, NavigationBar } from '@kontent-ai/component-library/NavigationBar';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { DocsMenu } from '../../containers/MainMenu/DocsMenu.tsx';
import { FeedbackMenu } from '../../containers/MainMenu/FeedbackMenu.tsx';
import { IntercomLauncherButton } from '../../containers/MainMenu/IntercomLauncherButton.tsx';
import { ReportBugButton } from '../../containers/MainMenu/ReportBugButton.tsx';
import { UserMenu } from '../../containers/MainMenu/UserMenu.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

type NavigationBarProps = {
  readonly appMenuItems: ReadonlyArray<AppMenuItem>;
};

const StandaloneMenuItems = () => (
  <Stack spacing={Spacing.XL} align="center">
    <IntercomLauncherButton />
    <ReportBugButton />
    <FeedbackMenu />
    <DocsMenu />
    <UserMenu />
  </Stack>
);

export const MainMenuNavigationBar = ({ appMenuItems }: NavigationBarProps) => (
  <NavigationBar
    appMenuItems={appMenuItems}
    {...getDataUiElementAttribute(DataUiElement.NavigationBar)}
  >
    <StandaloneMenuItems />
  </NavigationBar>
);
