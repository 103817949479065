import React from 'react';
import { colorDividerDefault } from '../../tokens/decision/colors.ts';
import { BaseColor } from '../../tokens/quarks/colors.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import {
  DividerDirection,
  StyledDivider,
  StyledDividerWrapper,
} from './components/StyledDivider.tsx';

export interface IDividerProps {
  readonly className?: string;
  readonly direction: DividerDirection;
  readonly color?: BaseColor;
  readonly offsetBefore: Spacing;
  readonly offsetAfter: Spacing;
}

export const Divider = React.forwardRef<HTMLDivElement, React.PropsWithChildren<IDividerProps>>(
  (
    {
      className,
      direction,
      color = colorDividerDefault,
      offsetBefore,
      offsetAfter,
      children,
      ...otherProps
    },
    forwardedRef,
  ) => (
    <StyledDividerWrapper
      className={className}
      ref={forwardedRef}
      $direction={direction}
      offsetAfter={offsetAfter}
    >
      {children}
      <StyledDivider
        $color={color}
        $direction={direction}
        offsetBefore={offsetBefore}
        {...otherProps}
      />
    </StyledDividerWrapper>
  ),
);

Divider.displayName = 'Divider';
