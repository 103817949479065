import { useEnsuredContext } from '@kontent-ai/hooks';
import { useButton } from '@react-aria/button';
import { HTMLAttributes, ReactNode, RefObject } from 'react';
import { MenuContext } from '../contexts/MenuContext.tsx';

// these are defined on the return type of useButton, but are not injected
// it may conflict with the types at the consumer level
type OmittedProps = 'aria-label' | 'aria-labelledby' | 'children';
type TriggerProps<TElement extends HTMLElement = HTMLElement> = Omit<
  HTMLAttributes<TElement>,
  OmittedProps
>;

type Props = Readonly<{
  children: (ref: RefObject<Element>, triggerProps: TriggerProps, isOpen: boolean) => ReactNode;
}>;

export const MenuTrigger = (props: Props) => {
  const {
    menuTriggerProps,
    menuTriggerRef,
    menuTriggerState: { isOpen },
  } = useEnsuredContext(MenuContext);

  const { buttonProps } = useButton(menuTriggerProps, menuTriggerRef);

  return props.children(menuTriggerRef, buttonProps, isOpen);
};
