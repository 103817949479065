import { ISelectItem, filterItems } from '@kontent-ai/component-library/Selects';
import { DefaultTag } from '@kontent-ai/component-library/Tag';
import { InvariantException } from '@kontent-ai/errors';
import { useMemo, useState } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { doesEntitySatisfyFilterPhrase } from '../../../../../../../_shared/utils/filter/nameFilterUtils.ts';
import { getDuplicateNames } from '../../../../../../../_shared/utils/hierarchicalOptionsUtils.ts';
import { logError } from '../../../../../../../_shared/utils/logError.ts';
import {
  ISitemapNode,
  emptySitemapNode,
} from '../../../../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import { useDisabledAiFilterProps } from '../../../../../../../paperModels/aiSearch/hooks/useDisabledAiFilterProps.ts';
import { ListingFilterCategorySelector } from '../../components/ListingFilterCategorySelector.tsx';

type Props = {
  readonly onSitemapSelectionChanged: (selectedSitemapNodes: ReadonlySet<Uuid>) => void;
  readonly selectedSitemapNodes: ReadonlySet<Uuid>;
};

interface ISitemapSelectItem extends ISelectItem<ISitemapSelectItem> {
  readonly selectedOptionLabel: string;
  readonly selectedOptionTooltipText: string;
}

const toSitemapSelectItem = (
  childId: Uuid,
  nodes: ReadonlyMap<Uuid, ISitemapNode>,
  duplicitousNames: ReadonlySet<string>,
  paths: ReadonlyArray<string> = [],
): ISitemapSelectItem => {
  const foundNode = nodes.get(childId);
  if (!foundNode) {
    logError(InvariantException(`${__filename}: Encountered node with unknown id ${childId}`));
  }
  const node = foundNode ?? emptySitemapNode;

  return {
    id: node.id,
    label: node.name,
    type: 'item',
    items: node.childIds.map((id) =>
      toSitemapSelectItem(id, nodes, duplicitousNames, [...paths, node.name]),
    ),
    selectedOptionTooltipText: [...paths, node.name].join('  >  '),
    selectedOptionLabel:
      !duplicitousNames.has(node.name) || !paths.length
        ? node.name
        : `${node.name} (${paths.at(-1)})`,
  };
};

export const SitemapFilterSelector = (props: Props) => {
  const disabledAiFilterProps = useDisabledAiFilterProps();
  const sitemap = useSelector((state) => state.data.sitemap.data);

  const [searchPhrase, setSearchPhrase] = useState('');

  const filteredItems = useMemo(() => {
    const duplicateNames = getDuplicateNames(Array.from(sitemap.nodes), ([, node]) => node.name);
    const sitemapItems = sitemap.childIds.map((id) =>
      toSitemapSelectItem(id, sitemap.nodes, duplicateNames),
    );

    return searchPhrase
      ? filterItems(
          sitemapItems,
          (item) =>
            item.type !== 'section' &&
            doesEntitySatisfyFilterPhrase(searchPhrase, item, [(o) => o.label]),
          true,
        )
      : sitemapItems;
  }, [sitemap.nodes, sitemap.childIds, searchPhrase]);

  return (
    <ListingFilterCategorySelector
      onChange={props.onSitemapSelectionChanged}
      options={filteredItems}
      onSearchTextChange={(text) => setSearchPhrase(text.trim())}
      collection={DataUiCollection.Sitemap}
      placeholder="Select a sitemap location"
      title="Location in sitemap"
      selectedOptionIds={props.selectedSitemapNodes}
      renderSelectedOption={(_id, selectedItem, { label, ...restTagProps }) => (
        <DefaultTag
          customTooltipText={selectedItem.selectedOptionTooltipText}
          label={selectedItem.selectedOptionLabel}
          {...restTagProps}
        />
      )}
      {...disabledAiFilterProps}
    />
  );
};
