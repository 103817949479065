import { OptionalTooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import React from 'react';
import { ConnectDragSource } from 'react-dnd';
import { BarItemDragAction } from '../../../../../_shared/components/BarItems/Actions/BarItemDragAction.tsx';
import { BarItemEditAction } from '../../../../../_shared/components/BarItems/Actions/BarItemEditAction.tsx';
import { BarItemActions } from '../../../../../_shared/components/BarItems/BarItemActions.tsx';
import { BarItemBar } from '../../../../../_shared/components/BarItems/BarItemBar.tsx';
import { BarItemContent } from '../../../../../_shared/components/BarItems/BarItemContent.tsx';
import { BarItemTitle } from '../../../../../_shared/components/BarItems/BarItemTitle.tsx';
import { BarItemTitleTag } from '../../../../../_shared/components/BarItems/BarItemTitleTag.tsx';
import { CodenameBarItemButton } from '../../../../../_shared/components/Codename/CodenameBarItemButton.tsx';
import { SearchPhraseHighlighterElement } from '../../../../../_shared/components/Highlighting/SearchPhraseHighlighterElement.tsx';
import { DefaultCollectionId } from '../../../../../_shared/constants/variantIdValues.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { findMatchingIndexes } from '../../../../../_shared/utils/filter/nameFilterUtils.ts';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import { TagColor } from '../../../../../data/constants/tagColor.ts';
import { ICollection } from '../../../../../data/models/collections/Collection.ts';
import { CollectionBarSpaces } from '../../containers/CollectionBarSpaces.tsx';

const getDragActionTooltipText = (
  isDraggingDisabled: boolean,
  isEditable: boolean,
): string | undefined => {
  if (!isEditable) {
    return 'Finish editing your collection to reorder.';
  }

  if (isDraggingDisabled) {
    return 'Clear your search phrase to reorder collections.';
  }

  return undefined;
};

export interface ICollectionBarBaseProps {
  readonly collection: ICollection;
  readonly isEditable: boolean;
  readonly onEdit?: () => void;
  readonly showSpaces: boolean;
}

interface ICollectionBarProps extends ICollectionBarBaseProps {
  readonly connectDragSource?: ConnectDragSource;
  readonly isDragging: boolean;
}

export const CollectionBar: React.FC<ICollectionBarProps> = ({
  collection,
  connectDragSource,
  isDragging,
  isEditable,
  onEdit,
  showSpaces,
}) => {
  const filterSearchPhrase = useSelector((s) => s.collectionsApp.filterSearchPhrase);
  const isDraggingDisabled = !isEmptyOrWhitespace(filterSearchPhrase);

  return (
    <div>
      <div className="bar-item__wrapper">
        <div
          className={classNames('bar-item__pane', {
            'bar-item__pane--is-dragging': isDragging,
          })}
        >
          <BarItemBar
            dataUiObjectName={collection.name}
            isDragging={isDragging}
            isClickable={isEditable}
          >
            <BarItemActions>
              <BarItemDragAction
                connectDragSource={connectDragSource}
                disabled={isDraggingDisabled || !isEditable}
                tooltipText={getDragActionTooltipText(isDraggingDisabled, isEditable)}
              />
            </BarItemActions>
            <BarItemTitle
              flexFactor={2}
              onClick={onEdit}
              suffix={
                collection.id === DefaultCollectionId ? (
                  <BarItemTitleTag color={TagColor.Gray}>Default collection</BarItemTitleTag>
                ) : undefined
              }
            >
              <OptionalTooltip placement="bottom-start" text={collection.name}>
                {filterSearchPhrase ? (
                  <SearchPhraseHighlighterElement
                    searchPhrase={filterSearchPhrase}
                    text={collection.name}
                    findMatchingIndexes={findMatchingIndexes}
                  />
                ) : (
                  collection.name
                )}
              </OptionalTooltip>
            </BarItemTitle>
            {showSpaces && (
              <BarItemContent flexFactor={1}>
                <CollectionBarSpaces collectionId={collection.id} />
              </BarItemContent>
            )}
            <BarItemActions>
              <CodenameBarItemButton codename={collection.codeName} />
              <BarItemEditAction
                disabled={!isEditable}
                disabledTooltipText="Finish editing your collection to edit an another one."
                onClick={onEdit}
              />
            </BarItemActions>
          </BarItemBar>
        </div>
      </div>
    </div>
  );
};

CollectionBar.displayName = 'CollectionBar';
