import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { MenuItemWithMouseHover } from '@kontent-ai/component-library/MenuItem';
import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { useVerticalMenu } from '@kontent-ai/component-library/VerticalMenu';
import { spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import React, { useState } from 'react';
import { DropDownMenuControlled } from '../../../../../../../component-library/components/DropDownMenu/DropDownMenuControlled.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AiReviewDropdownMenuMaxWidth } from '../constants/uiConstants.ts';

type Props = {
  readonly aiGuidelinesOptions: ReadonlyArray<IBaseSelectItem>;
  readonly disabledTooltipText: string | null;
  readonly perform: (guidelinesId: string) => void;
};

export const AiReviewDropdownMenu: React.FC<Props> = (props) => {
  const { aiGuidelinesOptions, disabledTooltipText, perform } = props;
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const { verticalMenuState, VerticalMenu, verticalMenuProps } = useVerticalMenu([
    {
      id: 'aiGuidelinesOptions',
      items: aiGuidelinesOptions,
      label: 'Review content with AI',
      type: 'section',
    },
  ]);

  return (
    <DropDownMenuControlled
      onDropDownVisibilityChange={setIsMenuVisible}
      isDropDownVisible={isMenuVisible}
      tippyOptions={{
        placement: 'bottom-end',
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top-end'],
              },
            },
          ],
        },
        offset: [0, spacingPopupDistance],
      }}
      renderDropDown={() => (
        <VerticalMenu
          {...verticalMenuProps}
          maxWidth={AiReviewDropdownMenuMaxWidth}
          aria-label="Review content with AI"
          renderItem={({ item }) => (
            <MenuItemWithMouseHover
              menuItemState="default"
              text={item.textValue}
              onPress={() => {
                if (item.value) {
                  perform(item.value.id);
                }
                setIsMenuVisible(false);
              }}
            />
          )}
          state={verticalMenuState}
          contain={false}
        />
      )}
      renderTrigger={({ ref }) => (
        <QuinaryButton
          tooltipText={disabledTooltipText ?? 'Review content with AI'}
          tooltipPlacement="bottom"
          activated={isMenuVisible}
          disabled={!!disabledTooltipText}
          onClick={() => setIsMenuVisible(true)}
          ref={ref}
          {...getDataUiElementAttribute(DataUiElement.AiReviewButton)}
        >
          <QuinaryButton.Icon icon={Icons.UserCheckbox} />
        </QuinaryButton>
      )}
    />
  );
};
