import { WorkflowStatus } from '@kontent-ai/component-library/WorkflowStatus';
import { BaseColor } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../../../_shared/components/NotificationBar.tsx';
import {
  ScheduledActionInProgress,
  ScheduledActionType,
} from '../../../../../../../_shared/components/Workflow/ScheduledActionInProgress.tsx';
import { DataUiElement } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { renderDatetimeString } from '../../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { isVariantScheduledActionInProgress } from '../../../../../../../_shared/utils/workflow/isVariantScheduledActionInProgress.ts';
import { WorkflowStepColor } from '../../../../../../../data/constants/WorkflowStepColor.ts';
import {
  IWorkflowStep,
  WorkflowStepAction,
} from '../../../../../../../data/models/workflow/WorkflowStep.ts';
import { PublishingPrivileges } from '../../../models/PublishingPrivileges.ts';
import { IToolButtonsProps, getTool } from './ToolButtons.tsx';
import { getMessage } from './ToolMessage.ts';

export type INotUndoneProps = IToolButtonsProps &
  ITimestampProps & {
    readonly publishingPrivilege: PublishingPrivileges;
  };

type ITimestampProps = {
  readonly publishedAt: DateTimeStamp | null;
  readonly scheduledToPublishAt: DateTimeStamp | null;
  readonly scheduledToUnpublishAt: DateTimeStamp | null;
  readonly workflowStatus: IWorkflowStep;
};

const renderDatetimeOrUnknown = (date: DateTimeStamp | null): string =>
  renderDatetimeString(date) || 'unknown';

const Timestamp: React.FC<ITimestampProps> = (props) => {
  if (props.workflowStatus.action === WorkflowStepAction.Publish && props.publishedAt) {
    return (
      <>
        {' '}
        on&nbsp;<strong>{renderDatetimeString(props.publishedAt)}</strong>
      </>
    );
  }
  if (props.workflowStatus.action === WorkflowStepAction.ScheduleToPublish) {
    return (
      <>
        {' '}
        to&nbsp;<strong>{renderDatetimeOrUnknown(props.scheduledToPublishAt)}</strong>
      </>
    );
  }

  return null;
};

Timestamp.displayName = 'Timestamp';

const getWorkflowStatusInvertedColor = (workflowStatus: IWorkflowStep): BaseColor => {
  if (workflowStatus.color === WorkflowStepColor.PersianGreen) {
    return BaseColor.PersianGreen30;
  }

  if (workflowStatus.color === WorkflowStepColor.DarkBlue) {
    return BaseColor.OceanBlue30;
  }

  return BaseColor.White;
};

const DefaultMessage: React.FC<INotUndoneProps> = (props) => {
  const message = getMessage(props.publishingPrivilege, props.numberOfProcessedItems);

  return (
    <>
      {props.numberOfProcessedItems > 1
        ? `There are ${props.numberOfProcessedItems} content items`
        : 'This content was'}
      &nbsp;
      <div css="display: inline-block">
        <WorkflowStatus
          primary={{
            name: props.workflowStatus.name,
            background: getWorkflowStatusInvertedColor(props.workflowStatus),
          }}
        />
      </div>
      <Timestamp {...props} />. {message}
    </>
  );
};

const ActionInProgressMessage: React.FC<INotUndoneProps> = (props) => {
  if (props.scheduledToPublishAt) {
    return (
      <ScheduledActionInProgress actionType={ScheduledActionType.Publish}>
        This content item’s being published now. It’ll be done in a few minutes.
      </ScheduledActionInProgress>
    );
  }
  if (props.scheduledToUnpublishAt) {
    return (
      <ScheduledActionInProgress actionType={ScheduledActionType.Unpublish}>
        This content item’s being unpublished and archived now. It’ll be done in a few minutes.
      </ScheduledActionInProgress>
    );
  }
  return null;
};

export const NotUndoneMessage: React.FC<INotUndoneProps> = (props) => {
  const tool = getTool(props.publishingPrivilege, props);
  const isActionInProgress =
    isVariantScheduledActionInProgress(props.scheduledToPublishAt) ||
    isVariantScheduledActionInProgress(props.scheduledToUnpublishAt);

  return (
    <NotificationBar
      type={NotificationBarType.InfoDark}
      message={
        isActionInProgress ? <ActionInProgressMessage {...props} /> : <DefaultMessage {...props} />
      }
      tools={!isActionInProgress && tool}
      uiElement={DataUiElement.NotificationBarInfo}
    />
  );
};

NotUndoneMessage.displayName = 'NotUndoneMessage';
