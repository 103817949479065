import { useRadioGroup } from '@react-aria/radio';
import { mergeProps } from '@react-aria/utils';
import { RadioGroupProps, useRadioGroupState } from '@react-stately/radio';
import React, { ReactNode } from 'react';
import { Box, BoxBorder } from '../../layout/Box/Box.tsx';
import { Inline } from '../../layout/Inline/Inline.tsx';
import { Column } from '../../layout/Row/Column.tsx';
import { Row } from '../../layout/Row/Row.tsx';
import { Stack } from '../../layout/Stack/Stack.tsx';
import {
  colorAlertIcon,
  colorAlertText,
  colorTextDefault,
  colorTextHint,
} from '../../tokens/decision/colors.ts';
import { IconSize } from '../../tokens/quarks/iconSize.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { Typography } from '../../tokens/quarks/typography.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Icons } from '../Icons/components/icons.ts';
import { RequiredA11yLabellingPropsExtension } from '../_utils/ariaLabelingProps.ts';
import { RadioGroupContextProvider } from './radioGroupContext.tsx';
import { RadioGroupState } from './types.ts';

type GroupLabelOrAriaProps = RequiredA11yLabellingPropsExtension<{
  readonly groupLabel: string;
}>;

type Props = GroupLabelOrAriaProps & {
  /** Message shown when RadioGroup is in 'alert' state */
  readonly alertMessage?: ReactNode;
  readonly defaultValue?: string;
  readonly groupSubLabel?: ReactNode;
  readonly inline?: boolean;
  readonly name: string;
  readonly onChange?: (value: string) => void;
  readonly radioGroupState?: RadioGroupState;
  readonly value?: string;
};

export const RadioGroup = React.forwardRef<HTMLFieldSetElement, React.PropsWithChildren<Props>>(
  (props, forwardedRef) => {
    const {
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledby,
      alertMessage,
      children,
      defaultValue,
      groupLabel,
      groupSubLabel,
      inline = false,
      name,
      onChange,
      radioGroupState = 'default',
      value,
      ...otherProps
    } = props;

    const commonProps: RadioGroupProps = {
      defaultValue,
      description: groupSubLabel,
      errorMessage: alertMessage,
      isDisabled: radioGroupState === 'disabled',
      isInvalid: radioGroupState === 'alert',
      label: ariaLabel ?? groupLabel,
      name,
      onChange,
      value,
    };

    const state = useRadioGroupState(commonProps);

    const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } = useRadioGroup(
      { ...commonProps, 'aria-labelledby': ariaLabelledby },
      state,
    );

    return (
      <Box
        component="fieldset"
        ref={forwardedRef}
        {...getDataUiComponentAttribute(RadioGroup)}
        {...mergeProps(radioGroupProps, otherProps)}
        // fieldset’s default styles reset
        padding={0}
        border={BoxBorder.None}
        margin={0}
      >
        <RadioGroupContextProvider state={state}>
          <Stack spacing={Spacing.L}>
            {(groupLabel || groupSubLabel) && (
              <Stack spacing={Spacing.L}>
                {groupLabel && (
                  <Box
                    component="legend"
                    color={colorTextDefault}
                    overflowWrap="anywhere"
                    typography={Typography.LabelLarge}
                    {...labelProps}
                  >
                    {groupLabel}
                  </Box>
                )}
                {groupSubLabel && (
                  <Box
                    color={colorTextHint}
                    overflowWrap="anywhere"
                    typography={Typography.LabelLarge}
                    {...descriptionProps}
                  >
                    {groupSubLabel}
                  </Box>
                )}
              </Stack>
            )}

            {inline ? (
              <Inline spacingX={Spacing.S}>{children}</Inline>
            ) : (
              <Stack spacing={Spacing.S}>{children}</Stack>
            )}

            {!!alertMessage && radioGroupState === 'alert' && (
              <Row spacing={Spacing.XS} noWrap>
                <Column width="content">
                  <Icons.ExclamationTriangleInverted color={colorAlertIcon} size={IconSize.S} />
                </Column>
                <Column>
                  <Box
                    color={radioGroupState === 'alert' ? colorAlertText : colorTextDefault}
                    overflowWrap="anywhere"
                    typography={Typography.TitleMedium}
                    {...errorMessageProps}
                  >
                    {alertMessage}
                  </Box>
                </Column>
              </Row>
            )}
          </Stack>
        </RadioGroupContextProvider>
      </Box>
    );
  },
);

RadioGroup.displayName = 'RadioGroup';
