import { ComponentProps } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { showLanguageEditor } from '../../actions/localizationActions.ts';
import { DraggableLanguageBar as DraggableLanguageBarComponent } from '../../components/languageBarItems/DraggableLanguageBar.tsx';

export const DraggableLanguageBar = (
  ownProps: Pick<ComponentProps<typeof DraggableLanguageBarComponent>, 'language'>,
) => {
  const dispatch = useDispatch();
  const onShowEditor = () => dispatch(showLanguageEditor(ownProps.language.id));

  const fallbackLanguageName = useSelector((state) => {
    const possibleFallback = state.localizationApp.languages.get(
      ownProps.language.fallbackLanguageId || '',
    );
    return possibleFallback ? possibleFallback.name : 'Default language';
  });

  const isEditable = useSelector(
    (state) =>
      !state.localizationApp.editedLanguageId && !state.localizationApp.isDefaultLanguageEdited,
  );

  return (
    <DraggableLanguageBarComponent
      onShowEditor={onShowEditor}
      fallbackLanguageName={fallbackLanguageName}
      isEditable={isEditable}
      {...ownProps}
    />
  );
};
