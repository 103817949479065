import { HTMLProps, useCallback } from 'react';
import { stopPropagation } from '../../../../_shared/utils/func/functionalTools.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { GetEditorRef, useGetEditorRef } from '../../editorCore/hooks/useGetEditorRef.ts';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';

export type WrapperProps = Readonly<Omit<HTMLProps<HTMLDivElement>, 'ref' | 'onMouseDown'>>;

type WrapperPluginState = {
  readonly wrapperProps: WrapperProps;
  readonly getWrapperRef: GetEditorRef<HTMLDivElement>;
};

export type WrapperPlugin = DraftJsEditorPlugin<WrapperPluginState>;

const initialWrapperProps: WrapperProps = {};

const render: Decorator<Render<WrapperPlugin>> = (baseRender) => (state) => (
  <div
    ref={state.getWrapperRef()}
    // Propagation of mouse down/up event is disabled
    // to allow proper timely reaction to selection change
    // to avoid blur event for the already focused comment that is raised on click outside the comment
    onMouseDown={stopPropagation}
    {...state.wrapperProps}
  >
    {baseRender(state)}
  </div>
);

export const WrapperPlugin: PluginComponent<WrapperPlugin> = (props) => {
  const getWrapperRef = useGetEditorRef<HTMLDivElement>();

  const apply: Apply<WrapperPlugin> = useCallback(
    (state) => {
      state.render.decorate(render);
      return {
        wrapperProps: initialWrapperProps,
        getWrapperRef,
      };
    },
    [getWrapperRef],
  );

  return useEditorWithPlugin(props, { apply });
};
