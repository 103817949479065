import React, { ComponentProps } from 'react';
import { useLocation } from 'react-router';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { LastUpdatedAt } from '../../../../../_shared/components/LastUpdatedAt.tsx';
import { WorkflowStatusTagForVariant } from '../../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IRecentlyEditedListingContentItem } from '../../../../../data/models/listingContentItems/IRecentlyEditedListingContentItem.ts';
import { TableCellDueDate } from '../../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import {
  StyledDataTableCell,
  StyledDataTableRow,
} from '../../../../missionControl/widgets/shared/components/StyledDataTable.tsx';

type RecentlyEditedByYouWidgetRowProps = {
  readonly onItemClick?: () => void;
  readonly recentlyEditedItem: IRecentlyEditedListingContentItem;
  readonly rowFocusLinkAriaLabel: string;
};

export const RecentlyEditedByYouWidgetRow: React.FC<RecentlyEditedByYouWidgetRowProps> = ({
  onItemClick,
  recentlyEditedItem,
  rowFocusLinkAriaLabel,
}) => {
  const { pathname } = useLocation();

  const linkPath = getContentItemPath(
    pathname,
    recentlyEditedItem.id.itemId,
    recentlyEditedItem.id.variantId,
  );

  const linkProps = {
    linkPath,
    onClick: onItemClick,
  } satisfies ComponentProps<typeof LinkDataTableCell>;

  return (
    <StyledDataTableRow
      dataUiObjectName={recentlyEditedItem.name}
      id={stringifyContentItemId(recentlyEditedItem.id)}
    >
      <StyledDataTableCell {...linkProps} focusableRowLinkAriaLabel={rowFocusLinkAriaLabel}>
        {recentlyEditedItem.name}
      </StyledDataTableCell>
      <StyledDataTableCell {...linkProps}>
        <WorkflowStatusTagForVariant
          publishingState={recentlyEditedItem.publishingState}
          workflowStatus={recentlyEditedItem.workflowStatus}
          scheduledToPublishAt={recentlyEditedItem.scheduledToPublishAt}
          scheduledToUnpublishAt={recentlyEditedItem.scheduledToUnpublishAt}
        />
      </StyledDataTableCell>
      <StyledDataTableCell {...linkProps}>
        <TableCellDueDate dueDate={recentlyEditedItem.due} />
      </StyledDataTableCell>
      <StyledDataTableCell {...linkProps}>
        <LastUpdatedAt time={recentlyEditedItem.lastUpdatedAt} />
      </StyledDataTableCell>
    </StyledDataTableRow>
  );
};
