import { Collection } from '@kontent-ai/utils';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../_shared/hooks/useThunkPromise.ts';
import { isCollectionsConfigurationVisible } from '../../../../../_shared/selectors/contentCollections.ts';
import { isLegacyWebhookConfigurationEnabled } from '../../../../../_shared/selectors/isLegacyWebhookConfigurationEnabled.ts';
import { getSelectedSubscription } from '../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { getCurrentProject } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { canAccessInnovationLab } from '../../../../environmentSettings/innovationLab/selectors/canAccessInnovationLab.ts';
import { initEnvironmentSettings } from '../../../../environmentSettings/root/actions/thunkEnvironmentSettingsActions.ts';
import {
  areSpacesEnabledForCurrentProject,
  isAuditLogEnabledForCurrentProjectPlan,
} from '../../../../environmentSettings/selectors/allowedFeaturesUtils.ts';
import { isEnvironmentGeneralSettingsTabVisible } from '../../../../environmentSettings/selectors/environmentSettingsMenuCustomVisibilities.ts';
import { EnvironmentSettings as EnvironmentSettingsComponent } from '../../components/EnvironmentSettings/EnvironmentSettings.tsx';

export const EnvironmentSettings = () => {
  useThunkPromise(initEnvironmentSettings);

  const areCollectionsEnabled = useSelector((state) =>
    isCollectionsConfigurationVisible(state, Collection.getValues(state.data.collections.byId)),
  );
  const subscriptionId = useSelector(
    (state) => getSelectedSubscription(state)?.subscriptionId ?? '',
  );
  const projectId = useSelector((state) => getCurrentProject(state).projectId);
  const areGeneralSettingsVisible = useSelector(isEnvironmentGeneralSettingsTabVisible);
  const areSpacesEnabled = useSelector(areSpacesEnabledForCurrentProject);
  const isAuditLogEnabled = useSelector(isAuditLogEnabledForCurrentProjectPlan);
  const isInnovationLabAccessible = useSelector(canAccessInnovationLab);
  const areLegacyWebhooksEnabled = useSelector(isLegacyWebhookConfigurationEnabled);

  return (
    <EnvironmentSettingsComponent
      areCollectionsEnabled={areCollectionsEnabled}
      areLegacyWebhooksEnabled={areLegacyWebhooksEnabled}
      areGeneralSettingsVisible={areGeneralSettingsVisible}
      areSpacesEnabled={areSpacesEnabled}
      isAuditLogEnabled={isAuditLogEnabled}
      isInnovationLabAccessible={isInnovationLabAccessible}
      projectId={projectId}
      subscriptionId={subscriptionId}
    />
  );
};
