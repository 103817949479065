import { InvariantException } from '@kontent-ai/errors';
import React, { useContext } from 'react';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { NoItemsFoundEmptyState } from '../../../../missionControl/projectOverview/components/NoItemsFoundEmptyState.tsx';
import { getAllProjectsMemoized } from '../../../../projects/selectors/projectSelectors.ts';
import { projectFiltersCleared } from '../../actions/subscriptionUserDetailActions.ts';
import { UserSubscriptionSettings as Component } from '../../components/UserDetail/UserSubscriptionSettings.tsx';
import { SubscriptionCapabilitiesContext } from '../../context/SubscriptionCapabilitiesContext.tsx';
import {
  getSubscriptionUserUsage,
  getUserEnvironments,
} from '../../selectors/subscriptionUsersUsageSelectors.ts';
import { getFilteredProjectSettings } from '../../utils/userProjectSettingsFilterUtils.ts';

type UserSubscriptionSettingsContainerProps = {
  readonly userId: Uuid;

  readonly onNavigateBack: () => void;
};

export const UserSubscriptionSettings: React.FC<UserSubscriptionSettingsContainerProps> = ({
  userId,
  onNavigateBack,
}) => {
  const allProjects = useSelector((s) => getAllProjectsMemoized(s.data.projects.byId));
  const userEnvironments = useSelector((s) => getUserEnvironments(s, userId));
  const subscriptionUsers = useSelector(
    (s) => s.data.subscriptions.subscriptionUsersUsage?.subscriptionUsers,
  );
  const filter = useSelector((s) => s.subscriptionApp.users.userDetailUi.filter);

  const capabilities = useContext(SubscriptionCapabilitiesContext);

  const dispatch = useDispatch();
  const onClearFilter = () => dispatch(projectFiltersCleared());

  if (!userEnvironments || !userEnvironments.length || !subscriptionUsers) {
    return <Loader />;
  }

  const userProjectsSettings = getSubscriptionUserUsage(
    subscriptionUsers,
    userId,
  )?.userProjectsSettings;
  if (!userProjectsSettings) {
    throw InvariantException(
      `UserSubscriptionSettingsContainer.tsx: Cannot find project settings for user id ${userId}.`,
    );
  }

  const filteredProjectSettings = getFilteredProjectSettings(
    allProjects,
    userEnvironments,
    userProjectsSettings,
    filter,
    capabilities.areLanguageRolesEnabled,
  );

  if (!filteredProjectSettings.length) {
    return (
      <div>
        <NoItemsFoundEmptyState onClearFilter={onClearFilter} />
      </div>
    );
  }

  return (
    <Component
      userId={userId}
      searchResults={filteredProjectSettings}
      onNavigateBack={onNavigateBack}
    />
  );
};

UserSubscriptionSettings.displayName = 'UserSubscriptionSettings';
