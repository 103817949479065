import { forwardRef } from 'react';
import styled from 'styled-components';

type CustomAppSandboxIframeProps = {
  readonly src: string;
  readonly name: string;
};

const StyledIframe = styled.iframe`
  border: none;
`;

export const CustomAppSandboxIframe = forwardRef<HTMLIFrameElement, CustomAppSandboxIframeProps>(
  ({ src, name }, ref) => (
    <StyledIframe
      height="100%"
      sandbox="allow-forms allow-modals allow-popups allow-same-origin allow-scripts allow-downloads allow-storage-access-by-user-activation"
      ref={ref}
      src={src}
      title={name}
      width="100%"
    />
  ),
);
