import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import React, { ComponentProps } from 'react';
import { FormGroup } from '../../../../../_shared/components/input/FormGroup.tsx';
import { ValidatedMultipleOptionSelect } from '../../../../../_shared/components/input/ValidatedMultipleOptionSelect.tsx';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiCollection,
  DataUiInput,
  getDataUiCollectionAttribute,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { calculateTagBackgroundColor } from '../../../../../_shared/utils/workflow/calculateTagBackgroundColor.ts';
import { TagColor } from '../../../../../data/constants/tagColor.ts';
import { RoleOption } from '../../../../../data/models/roles/RoleOption.ts';
import { WorkflowStepOption } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { renderWorkflowStepColor } from '../../../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import { deletedRoleIdBase } from '../../../constants/getDeletedRole.ts';
import { handleAllRolesRoleOnRolesChangedInFormInput } from '../../../utils/allRolesRoleBehaviorUtils.ts';
import { IPublishedWorkflowStepFormShape } from '../../model/IPublishedWorkflowStepFormShape.type.ts';
import { systemWorkflowStepAutofocusDelay } from './contants.ts';

interface IWorkflowStepFormFieldsProps {
  readonly formProps: HookFormProps<IPublishedWorkflowStepFormShape>;
  readonly transitionFromOptions: ReadonlyArray<WorkflowStepOption>;
  readonly permanentTransitionToOptions: ReadonlyArray<WorkflowStepOption>;
  readonly roleOptions: ReadonlyArray<RoleOption>;
  readonly stepName: string;
}

const renderWorkflowStepSelectedOption = (
  _id: string,
  workflowStep: WorkflowStepOption,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) => {
  return <Tag {...defaultTagProps} background={renderWorkflowStepColor(workflowStep.color)} />;
};

const renderDisabledWorkflowStepSelectedOption = (
  _id: string,
  workflowStep: WorkflowStepOption,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) => {
  return (
    <Tag {...defaultTagProps} disabled background={renderWorkflowStepColor(workflowStep.color)} />
  );
};

const renderRoleOption = (
  id: string,
  _role: RoleOption,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) => {
  return (
    <Tag
      {...defaultTagProps}
      background={calculateTagBackgroundColor(
        id.startsWith(deletedRoleIdBase) ? TagColor.Red : TagColor.Gray,
      )}
    />
  );
};

const emptyOptions: ReadonlyArray<WorkflowStepOption> = [];
const emptyOptionIds: ReadonlyArray<string> = [];

export const PublishedWorkflowStepFormFields: React.FC<IWorkflowStepFormFieldsProps> = (props) => (
  <div className="bar-item__expansion">
    <ValidatedMultipleOptionSelect<IPublishedWorkflowStepFormShape, WorkflowStepOption>
      autoFocus
      delayAutoFocus={systemWorkflowStepAutofocusDelay}
      formProps={props.formProps}
      items={props.transitionFromOptions}
      label={`Steps that transition to ${props.stepName}`}
      name="transitionFrom"
      placeholder="No transitions"
      renderSelectedOption={renderWorkflowStepSelectedOption}
      verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.WorkflowSteps)}
      {...getDataUiInputAttribute(DataUiInput.WorkflowStepTransitionsFrom)}
    />

    <FormGroup>
      <MultiSelect
        inputState={InputState.Disabled}
        items={emptyOptions}
        label={`${props.stepName} transitions to`}
        permanentOptions={props.permanentTransitionToOptions}
        renderPermanentOption={renderDisabledWorkflowStepSelectedOption}
        selectedItemIds={emptyOptionIds}
        verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.WorkflowSteps)}
        {...getDataUiInputAttribute(DataUiInput.WorkflowStepTransitionsTo)}
      />
    </FormGroup>
    <FormGroup>
      <ValidatedMultipleOptionSelect<IPublishedWorkflowStepFormShape, RoleOption>
        formProps={props.formProps}
        items={props.roleOptions}
        label="Roles that can create new versions"
        name="rolesForNewVersion"
        normalize={(formValues, previousValues) =>
          handleAllRolesRoleOnRolesChangedInFormInput(formValues, previousValues)
        }
        placeholder="Select roles that can work with this workflow step"
        renderSelectedOption={renderRoleOption}
        verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.RoleList)}
        {...getDataUiInputAttribute(DataUiInput.WorkflowPublishStepRolesForCreateNew)}
      />
    </FormGroup>
    <FormGroup>
      <ValidatedMultipleOptionSelect<IPublishedWorkflowStepFormShape, RoleOption>
        formProps={props.formProps}
        items={props.roleOptions}
        label="Roles that can unpublish and archive, and cancel scheduling"
        name="rolesForUnpublish"
        normalize={(formValues, previousValues) =>
          handleAllRolesRoleOnRolesChangedInFormInput(formValues, previousValues)
        }
        placeholder="Select roles that can work with this workflow step"
        renderSelectedOption={renderRoleOption}
        verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.RoleList)}
        {...getDataUiInputAttribute(DataUiInput.WorkflowPublishStepRolesForUnpublish)}
      />
    </FormGroup>
  </div>
);

PublishedWorkflowStepFormFields.displayName = 'PublishedWorkflowStepFormFields';
