import { AiActionName } from '../../../repositories/serverModels/ai/AiActionName.type.ts';
import { TrackingAiActionName } from '../../models/events/AiActionEventData.type.ts';

type AnyAiActionName = AiActionName | TrackingAiActionName;

export enum FeedbackType {
  Positive = 'positive',
  Negative = 'negative',
}

export enum SpecificFeedbackReason {
  // General
  ResultTooShort = 'result-too-short',
  ResultTooLong = 'result-too-long',
  ResultSlow = 'result-slow',
  // Content transformation
  ContentChangedMeaning = 'content-changed-meaning',
  ContentChangedTooMuch = 'content-changed-too-much',
  ContentTooSimilar = 'content-too-similar',
  // Instructed by custom prompt
  ResultNotByInstructions = 'result-not-by-instructions',
  // Review by guidelines
  ReviewByGuidelinesResultMismatch = 'review-by-guidelines-result-mismatch',
  ReviewByGuidelinesSlow = 'review-by-guidelines-slow',
  // Categorization
  TaxonomyTermsIrrelevant = 'taxonomy-terms-irrelevant',
  TaxonomyTermsTooMany = 'taxonomy-terms-too-many',
  TaxonomyTermsTooFew = 'taxonomy-terms-too-few',
  TaxonomyTermsNone = 'taxonomy-terms-none',
  // Linked items
  SuggestedLinkedItemsIrrelevant = 'suggested-linked-items-irrelevant',
  SuggestedLinkedItemsNotEnough = 'suggested-linked-items-not-enough',
  // Action specific
  ToneWrong = 'tone-wrong',
  VoiceAndToneWrong = 'voice-and-tone-wrong',
}

export const reasonLabels: ReadonlyRecord<SpecificFeedbackReason, string> = {
  [SpecificFeedbackReason.ResultTooShort]: 'The result was too short',
  [SpecificFeedbackReason.ResultTooLong]: 'The result was too long',
  [SpecificFeedbackReason.ResultSlow]: 'It took too long to create the result',
  [SpecificFeedbackReason.ContentChangedMeaning]:
    'The result had a different meaning than the original',
  [SpecificFeedbackReason.ContentChangedTooMuch]: 'The text changed too much',
  [SpecificFeedbackReason.ContentTooSimilar]: 'The result was too similar to the original',
  [SpecificFeedbackReason.ResultNotByInstructions]: 'The result didn’t match my instructions',
  [SpecificFeedbackReason.ReviewByGuidelinesResultMismatch]:
    'The suggestion doesn’t match the review guidelines',
  [SpecificFeedbackReason.ReviewByGuidelinesSlow]: 'The content review takes too long',
  [SpecificFeedbackReason.TaxonomyTermsIrrelevant]: 'The suggested terms were irrelevant',
  [SpecificFeedbackReason.TaxonomyTermsTooMany]: 'Too many terms were suggested',
  [SpecificFeedbackReason.TaxonomyTermsTooFew]: 'Too few terms were suggested',
  [SpecificFeedbackReason.TaxonomyTermsNone]: 'No terms were suggested',
  [SpecificFeedbackReason.SuggestedLinkedItemsIrrelevant]: 'Suggested items are irrelevant',
  [SpecificFeedbackReason.SuggestedLinkedItemsNotEnough]: 'Not enough suggested items',
  [SpecificFeedbackReason.ToneWrong]: 'The tone of the result was wrong',
  [SpecificFeedbackReason.VoiceAndToneWrong]: 'The voice and tone of the result were wrong',
} as const;

type OtherFeedbackReason = 'other';

export type AnyReason = SpecificFeedbackReason | OtherFeedbackReason;

export const getAiFeedbackFeature = (actionName: AnyAiActionName): `ai-${AnyAiActionName}` =>
  `ai-${actionName}`;

type AiFeedbackFeature = ReturnType<typeof getAiFeedbackFeature>;

export type FeedbackFeature = AiFeedbackFeature;

type ReasonWithLabel = {
  reason: SpecificFeedbackReason;
  label: string;
};

export type FeedbackReasons = ReadonlyArray<ReasonWithLabel>;

export const createFeedbackReasons = (
  reasons: ReadonlyArray<SpecificFeedbackReason>,
): FeedbackReasons =>
  reasons.map((reason) => ({
    reason,
    label: reasonLabels[reason],
  }));

const aiFeatureNameByActionName: ReadonlyRecord<AnyAiActionName, string> = {
  [AiActionName.CategorizeItem]: 'Suggest taxonomy terms',
  [AiActionName.ChangeTone]: 'Change tone',
  [AiActionName.CreateTitle]: 'Create title',
  [AiActionName.DescribeImage]: 'Describe image',
  [AiActionName.GenerateContentByInstruction]: 'Generate content',
  [AiActionName.ImproveContentByBrief]: 'Improve content',
  [AiActionName.MakeShorter]: 'Make shorter',
  [AiActionName.MatchWritingStyleOfItem]: 'Match writing style',
  [AiActionName.ReviewByGuidelines]: 'Review content',
  [AiActionName.Summarize]: 'Summarize',
  [AiActionName.TranslateContent]: 'Translate content',
  [TrackingAiActionName.ImproveContent]: 'Improve content',
  [TrackingAiActionName.MatchWritingStyle]: 'Match writing style',
  [TrackingAiActionName.NewInlineInstruction]: 'New inline instruction',
  [TrackingAiActionName.SuggestLinkedItems]: 'Suggest linked items',
};

export const feedbackFeatureNameByAiFeedbackFeature: ReadonlyRecord<FeedbackFeature, string> =
  Object.fromEntries(
    Object.entries(aiFeatureNameByActionName).map(([key, value]) => [
      getAiFeedbackFeature(key),
      `AI - ${value}`,
    ]),
  );

export type NegativeFeedbackPayload = {
  readonly canBeContacted: boolean;
  readonly explanation: string;
  readonly feature: FeedbackFeature;
  readonly feedbackType: typeof FeedbackType.Negative;
  readonly reasons: ReadonlyArray<AnyReason>;
};

type PositiveFeedbackPayload = {
  readonly feature: FeedbackFeature;
  readonly feedbackType: typeof FeedbackType.Positive;
};

export type StructuredFeedbackPayload = NegativeFeedbackPayload | PositiveFeedbackPayload;
