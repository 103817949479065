import { Action } from '../../../../../../@types/Action.type.ts';
import { ModalDialogType } from '../../../../../../_shared/constants/modalDialogType.ts';
import {
  Shared_CurrentProjectId_Updated,
  Shared_ModalOpenedWithProperties,
} from '../../../../../../_shared/constants/sharedActionTypes.ts';
import { shouldUseLastContinuationToken } from '../../../../../../_shared/utils/scrollTableUtils.ts';
import {
  Data_ContentTypeComponentUsage_Started,
  Data_ListingContentItems_FullTextSearchBecameUnavailable,
  Data_ListingContentItems_Started,
} from '../../../../../../data/constants/dataActionTypes.ts';
import { AiSearch_Started } from '../../../../../../paperModels/aiSearch/actions/aiSearchActionTypes.ts';
import { ContentType_LoadComponentUsage_Dismissed } from '../../../../../contentModels/contentTypes/constants/contentTypesActionTypes.ts';
import {
  ModalContentItemSelector_Closed,
  ModalContentItemSelector_Opened,
  ModalMultipleContentItemsSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../../../../../features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFailed,
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeModal_PublishingFailed,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_SchedulingFailed,
  ContentEditing_CascadeModal_SchedulingFinished,
} from '../../../../../itemEditor/features/CascadePublish/constants/cascadeModalActionTypes.ts';
import {
  ContentListing_Filter_Changed,
  ContentListing_ScrollTableState_Changed,
} from '../../../features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { ScrollTableState } from '../../../models/ScrollTableState.type.ts';

const initialState: ScrollTableState = {
  availableHeightPx: 0,
  scrollPositionPx: 0,
};

export const contentItemListingScrollTableState = (
  state = initialState,
  action: Action,
): ScrollTableState => {
  switch (action.type) {
    case ContentListing_ScrollTableState_Changed: {
      return {
        availableHeightPx: action.payload.availableHeightPx,
        scrollPositionPx: action.payload.scrollPositionPx,
      };
    }

    case ContentType_LoadComponentUsage_Dismissed:
    case ModalMultipleContentItemsSelector_Closed:
    case ModalMultipleContentItemsSelector_Opened:
    case ModalContentItemSelector_Closed:
    case ModalContentItemSelector_Opened:
    case AiSearch_Started:
      return initialState;

    case Shared_ModalOpenedWithProperties: {
      if (
        action.payload.properties.dialogType === ModalDialogType.ContentTypeAsComponentUsageDialog
      ) {
        return initialState;
      }

      return state;
    }

    case Data_ContentTypeComponentUsage_Started:
    case Data_ListingContentItems_Started: {
      if (shouldUseLastContinuationToken(action.payload.requestTrigger)) {
        return state;
      }

      return {
        scrollPositionPx: 0,
        availableHeightPx: state.availableHeightPx,
      };
    }

    case ContentListing_Filter_Changed:
    case ContentEditing_CascadeModal_PublishingFinished:
    case ContentEditing_CascadeModal_SchedulingFinished:
    case ContentEditing_CascadeAction_UndoFinished:
    case ContentEditing_CascadeModal_PublishingFailed:
    case ContentEditing_CascadeModal_SchedulingFailed:
    case ContentEditing_CascadeAction_UndoFailed:
    case Shared_CurrentProjectId_Updated: {
      return {
        scrollPositionPx: 0,
        availableHeightPx: state.availableHeightPx,
      };
    }

    case Data_ListingContentItems_FullTextSearchBecameUnavailable: {
      return action.payload.shouldResetLoadedItems
        ? {
            scrollPositionPx: 0,
            availableHeightPx: state.availableHeightPx,
          }
        : state;
    }

    default:
      return state;
  }
};
