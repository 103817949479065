import { Icons } from '@kontent-ai/component-library/Icons';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { RTEToolbarIconButton } from '../../toolbars/components/buttons/RTEToolbarButton.tsx';

type Props = {
  readonly isToolbarVertical: boolean;
  readonly onClick: () => void;
};

export const AiIconButton = ({ isToolbarVertical, onClick }: Props) => (
  <RTEToolbarIconButton
    onClick={onClick}
    icon={Icons.Sparkles}
    tooltipPlacement={isToolbarVertical ? 'right' : 'top'}
    tooltipText="AI request"
    shortcut="//"
    {...getDataUiActionAttribute(DataUiRteAction.AiAction)}
  />
);
