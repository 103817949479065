import { Action } from '../../../../@types/Action.type.ts';
import { CustomApps_ListingInit_Finished } from '../../../../applications/customApps/constants/customAppsActionTypes.ts';
import { CustomApp, createCustomAppDomainModel } from '../../../models/customApps/CustomApp.ts';

export const customApps = (
  state: ReadonlyArray<CustomApp> = [],
  action: Action,
): ReadonlyArray<CustomApp> => {
  switch (action.type) {
    case CustomApps_ListingInit_Finished:
      return action.payload.customApps.map((customApp) => createCustomAppDomainModel(customApp));

    default:
      return state;
  }
};
