export enum UnitOption {
  Words = 'words',
  Characters = 'characters',
}

export const UnitOptions: ReadonlyArray<UnitOption> = [UnitOption.Words, UnitOption.Characters];

export function getUnitOptionName(value: UnitOption): string {
  switch (value) {
    case UnitOption.Words:
      return 'Words';
    case UnitOption.Characters:
      return 'Characters';
  }
}
