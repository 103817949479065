import { EditorState } from 'draft-js';
import { useCallback, useMemo, useState } from 'react';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { getContentStateActionResult } from '../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { useAiTask } from '../../../../../../_shared/features/AI/hooks/aiTasks/useAiTask.ts';
import { useOnFinishedAiActionTask } from '../../../../../../_shared/features/AI/hooks/aiTasks/useOnFinishedAiActionTask.ts';
import { useAiActionTrackingWithSession } from '../../../../../../_shared/features/AI/hooks/useAiActionTrackingWithSession.ts';
import { AiActionProps } from '../../../../../../_shared/features/AI/types/AiActionProps.type.ts';
import {
  AiActionSource,
  AiFollowingAction,
} from '../../../../../../_shared/models/events/AiActionEventData.type.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AiActionName } from '../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import {
  Tone,
  createChangeToneParams,
} from '../../../../../../repositories/serverModels/ai/actions/AiServerModels.changeTone.ts';
import { useEditorStateCallbacks } from '../../../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../../editorCore/types/Editor.composition.type.ts';
import { None, Optional } from '../../../../editorCore/types/Editor.contract.type.ts';
import { Apply, Render } from '../../../../editorCore/types/Editor.plugins.type.ts';
import { DecorableFunction, Decorator, decorable } from '../../../../editorCore/utils/decorable.ts';
import { extractSelectedContent } from '../../../../utils/general/editorContentUtils.ts';
import { LockEditorPlugin } from '../../../behavior/LockEditorPlugin.tsx';
import { ClipboardPlugin } from '../../../clipboard/ClipboardPlugin.tsx';
import { DraftJsEditorPlugin } from '../../../draftJs/DraftJsEditorPlugin.type.ts';
import { AiMenuItem, AiMenuPlugin, AiMenuSection, GetAiMenuItems } from '../../AiMenuPlugin.tsx';
import { AiMenuActionItem } from '../../components/menu/AiMenuActionItem.tsx';
import {
  AiMenuItemWithSubMenu,
  AiSubMenuItem,
  AiSubMenuSection,
} from '../../components/menu/AiMenuItemWithSubMenu.tsx';
import { createAiActionResultSelector } from '../../helpers/createAiActionResultSelector.ts';
import { getContentForActionInput } from '../../helpers/getContentForActionInput.ts';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard.ts';
import { useReplaceSelection } from '../../hooks/useReplaceSelection.ts';
import { ResultPositioner, useResultPositioner } from '../../hooks/useResultPositioner.tsx';
import { useResultWithPreservedBlockKeys } from '../../hooks/useResultWithPreservedBlockKeys.ts';
import { ChangeToneAction } from './ChangeToneAction.tsx';

export type ChangeTonePlugin = DraftJsEditorPlugin<
  ChangeTonePluginState,
  AiActionProps,
  None,
  None,
  [ClipboardPlugin, LockEditorPlugin, Optional<AiMenuPlugin>]
>;

export type GetExtraChangeToneMenuItems = (
  editorState: EditorState,
  onActionStarted: () => void,
) => ReadonlyArray<AiSubMenuItem>;

type ChangeTonePluginState = {
  readonly getExtraChangeToneMenuItems: DecorableFunction<GetExtraChangeToneMenuItems>;
};

const getEmptyExtraChangeToneMenuItems: GetExtraChangeToneMenuItems = () => [];

type ChangeToneInputs = {
  readonly editorState: EditorState;
  readonly tone: Tone;
};

export const ChangeTonePlugin: PluginComponent<ChangeTonePlugin> = (props) => {
  const { element } = props;
  const [activeInputs, setActiveInputs] = useState<ChangeToneInputs | null>(null);

  const {
    aiSessionId,
    elementOperationTrackingData,
    resetAiSessionId,
    trackFinishedAction,
    trackFollowingAction,
    trackStartingAction,
  } = useAiActionTrackingWithSession(element);

  const { decorateWithEditorStateCallbacks, getEditorState, lockEditor, unlockEditor } =
    useEditorStateCallbacks<ChangeTonePlugin>();

  const { cancel, run, result } = useAiTask(
    AiActionName.ChangeTone,
    createAiActionResultSelector(element.elementType, getContentStateActionResult),
  );

  useOnFinishedAiActionTask(
    result.isFinished,
    () => result.trackingParams && trackFinishedAction(result.trackingParams),
  );

  const startChangeToneAction = useCallback(
    (inputs: ChangeToneInputs, actionSource: AiActionSource) => {
      const { editorState, tone } = inputs;

      const contentState = extractSelectedContent(
        editorState.getCurrentContent(),
        editorState.getSelection(),
      );
      run(
        createChangeToneParams(
          getContentForActionInput(element.elementType, contentState),
          tone,
          elementOperationTrackingData,
        ),
      );
      trackStartingAction({
        action: AiActionName.ChangeTone,
        source: actionSource,
        tone: inputs.tone,
      });
    },
    [element, elementOperationTrackingData, run, trackStartingAction],
  );

  const start = useCallback(
    async (tone: Tone) => {
      const editorState = getEditorState();
      await lockEditor(editorState);

      const inputs: ChangeToneInputs = {
        editorState,
        tone,
      };
      setActiveInputs(inputs);
      startChangeToneAction(inputs, AiActionSource.InlineToolbar);
    },
    [lockEditor, getEditorState, startChangeToneAction],
  );

  const reset = useCallback(() => {
    if (!result.isFinished) {
      cancel();
    }
    resetAiSessionId();
    setActiveInputs(null);
    unlockEditor();
  }, [unlockEditor, cancel, result.isFinished, resetAiSessionId]);

  const tryAgain = useMemo(
    () =>
      result.isFinished
        ? () => {
            if (activeInputs) {
              trackFollowingAction({ action: AiFollowingAction.TryAgain });
              startChangeToneAction(activeInputs, AiActionSource.ActionMenu);
            }
          }
        : undefined,
    [startChangeToneAction, activeInputs, result.isFinished, trackFollowingAction],
  );

  const { decorateWithReplaceCallbacks, replaceSelection } = useReplaceSelection(
    activeInputs?.editorState ?? null,
  );

  const onReplaceSelection = useMemo(() => {
    const content = result.content;

    return result.isFinished && content
      ? () => {
          trackFollowingAction({ action: AiFollowingAction.ReplaceSelection });
          reset();
          replaceSelection(content);
        }
      : undefined;
  }, [replaceSelection, reset, result.content, result.isFinished, trackFollowingAction]);

  const { copyToClipboard } = useCopyToClipboard();

  const onCopyToClipboard = useMemo(() => {
    const content = result.content;

    return result.isFinished && content
      ? () => {
          trackFollowingAction({ action: AiFollowingAction.CopyToClipboard });
          reset();
          copyToClipboard(content, aiSessionId);
        }
      : undefined;
  }, [
    copyToClipboard,
    reset,
    result.content,
    result.isFinished,
    trackFollowingAction,
    aiSessionId,
  ]);

  const { decorateWithPositionerCallbacks, resultPositionerProps } = useResultPositioner(
    !!activeInputs,
  );

  const resultWithPreservedBlockKeys = useResultWithPreservedBlockKeys(result);
  const renderOverlays: Decorator<Render<ChangeTonePlugin>> = useCallback(
    (baseRenderOverlays) => (state) => (
      <>
        {baseRenderOverlays(state)}
        {activeInputs && (
          <ResultPositioner
            {...resultPositionerProps}
            renderResult={(isPositionedAboveContent, resultWidth, resultRef) => (
              <ChangeToneAction
                onDiscard={() => {
                  trackFollowingAction({ action: AiFollowingAction.Discard });
                  reset();
                }}
                onCopyToClipboard={onCopyToClipboard}
                onReplaceSelection={onReplaceSelection}
                onTryAgain={tryAgain}
                preferMenuOnTop={isPositionedAboveContent}
                ref={resultRef}
                result={resultWithPreservedBlockKeys}
                resultWidth={resultWidth}
                tone={activeInputs.tone}
              />
            )}
          />
        )}
      </>
    ),
    [
      activeInputs,
      reset,
      onReplaceSelection,
      resultWithPreservedBlockKeys,
      resultPositionerProps,
      tryAgain,
      onCopyToClipboard,
      trackFollowingAction,
    ],
  );

  const apply: Apply<ChangeTonePlugin> = useCallback(
    (state) => {
      const getExtraChangeToneMenuItems = decorable(getEmptyExtraChangeToneMenuItems);
      const getAiMenuItems: Decorator<GetAiMenuItems> = (baseGetAiMenuItems) => (editorState) => {
        return [
          ...baseGetAiMenuItems(editorState),
          ...(isActionAvailable(editorState)
            ? [
                {
                  id: 'change-tone',
                  label: 'Change tone',
                  renderIntoMenu: (item, onActionStarted) => (
                    <AiMenuItemWithSubMenu
                      label={item.label}
                      iconName={IconName.Mask}
                      items={[
                        {
                          id: 'base-tones',
                          type: 'section',
                          items: Object.values(Tone).map(
                            (tone): AiSubMenuItem => ({
                              id: tone,
                              label: tone,
                              renderIntoMenu: (aiMenuItem) => (
                                <AiMenuActionItem
                                  label={aiMenuItem.label}
                                  onPress={() => {
                                    onActionStarted();
                                    start(tone);
                                  }}
                                  {...getDataUiObjectNameAttribute(aiMenuItem.label)}
                                />
                              ),
                            }),
                          ),
                        } satisfies AiSubMenuSection,
                        {
                          id: 'complex-tones',
                          type: 'section',
                          items: getExtraChangeToneMenuItems(editorState, onActionStarted),
                        } satisfies AiSubMenuSection,
                      ].filter((section) => section.items.length > 0)}
                      {...getDataUiActionAttribute(DataUiRteAction.ChangeTone)}
                    />
                  ),
                  section: AiMenuSection.EditOrReviewSelection,
                  type: 'item',
                } satisfies AiMenuItem,
              ]
            : []),
        ];
      };

      decorateWithEditorStateCallbacks(state);
      decorateWithReplaceCallbacks(state);
      decorateWithPositionerCallbacks(state);
      state.getInlineToolbarAiMenuItems?.decorate(getAiMenuItems);
      state.renderOverlays.decorate(renderOverlays);
      return {
        getExtraChangeToneMenuItems,
      };
    },
    [
      decorateWithEditorStateCallbacks,
      decorateWithReplaceCallbacks,
      decorateWithPositionerCallbacks,
      renderOverlays,
      start,
    ],
  );

  return useEditorWithPlugin(props, { apply });
};

const isActionAvailable = (_: EditorState) => true;
