import { memoize } from '@kontent-ai/memoization';
import classNames from 'classnames';
import { ContentBlock, DraftBlockRenderConfig, DraftCSSProperties } from 'draft-js';
import React from 'react';
import {
  BlockClassName,
  BlockClassNames,
  CustomBlockSleeveAfterClassname,
  CustomBlockSleeveBeforeClassname,
  CustomBlockSleeveClassname,
  EmptyTextBlockClassName,
  FirstBlockClassName,
  LastBlockClassName,
  ListItemClassName,
  TextBlockClassName,
  TopLevelBlockClassName,
  UnstyledClassName,
  getBlockIdClassName,
} from '../../../editorCore/utils/editorComponentUtils.ts';
import {
  AdjacentBlockType,
  BaseBlockType,
  BlockType,
  isBlockTypeWithSleeves,
  isHeadingBlockType,
  isTextBlockType,
} from '../../../utils/blocks/blockType.ts';
import {
  CustomBlockSleevePosition,
  isEmptyParagraph,
  isEmptyTextBlock,
  isTableCell,
  isTopLevelBlock,
} from '../../../utils/blocks/blockTypeUtils.ts';
import {
  getBaseBlockType,
  getNextBlockType,
  getPreviousBlockType,
} from '../../../utils/blocks/editorBlockGetters.ts';

export const mergeInlineStyles = memoize.weak(
  (
    newStyles: DraftCSSProperties,
    originalStyles: DraftCSSProperties | undefined,
  ): DraftCSSProperties => ({
    ...originalStyles,
    ...newStyles,
    ...(newStyles.className && {
      className: classNames(originalStyles?.className, newStyles.className),
    }),
  }),
);

export type BaseBlockRenderMap = Immutable.Map<BaseBlockType, DraftBlockRenderConfig>;

export type BlockRenderMap = Immutable.Map<BlockType, DraftBlockRenderConfig>;

export const mergeBlockRenderMaps = memoize.weak(
  (
    first: BaseBlockRenderMap,
    ...renderMaps: ReadonlyArray<BaseBlockRenderMap>
  ): BaseBlockRenderMap => renderMaps.reduce((merged, item) => merged.merge(item), first),
);

export const getBlockFromBlockElement = (element: React.ReactElement): ContentBlock =>
  element.props.children.props.block;

export function isCellBoundary(current: React.ReactElement): boolean {
  const currentBlock = getBlockFromBlockElement(current);

  return isTableCell(currentBlock);
}

// Only use this method for rendering as it relies on specific block data.
// Use getCustomBlockSleevePosition for editor API methods.
export function getCustomBlockSleevePositionForRendering(
  block: ContentBlock,
): CustomBlockSleevePosition {
  if (!isEmptyParagraph(block)) {
    return CustomBlockSleevePosition.None;
  }

  const nextBlockType = getNextBlockType(block);
  const previousBlockType = getPreviousBlockType(block);

  if (nextBlockType !== AdjacentBlockType.None && isBlockTypeWithSleeves(nextBlockType)) {
    return CustomBlockSleevePosition.BeforeOwner;
  }
  if (previousBlockType !== AdjacentBlockType.None && isBlockTypeWithSleeves(previousBlockType)) {
    return CustomBlockSleevePosition.AfterOwner;
  }
  return CustomBlockSleevePosition.None;
}

export function getBlockClassNames(block: ContentBlock): BlockClassNames {
  const type = getBaseBlockType(block);
  const depth = block.getDepth();
  const nextBlockType = getNextBlockType(block);
  const previousBlockType = getPreviousBlockType(block);

  const customBlockSleevePosition = getCustomBlockSleevePositionForRendering(block);

  const baseClassNames: BlockClassNames = {
    [TopLevelBlockClassName]: isTopLevelBlock(block),
    [FirstBlockClassName]: previousBlockType === AdjacentBlockType.None,
    [LastBlockClassName]: nextBlockType === AdjacentBlockType.None,
    [getBlockIdClassName(block.getKey())]: true,
  };

  const specificClassNames: BlockClassNames =
    customBlockSleevePosition === CustomBlockSleevePosition.None
      ? // Regular block
        {
          [TextBlockClassName]: isTextBlockType(type),
          [EmptyTextBlockClassName]: isEmptyTextBlock(block),
          [UnstyledClassName]: type === BlockType.Unstyled,
          rte__heading: isHeadingBlockType(type),
          'rte__heading-one': type === BlockType.HeadingOne,
          'rte__heading-two': type === BlockType.HeadingTwo,
          'rte__heading-three': type === BlockType.HeadingThree,
          'rte__heading-four': type === BlockType.HeadingFour,
          'rte__heading-five': type === BlockType.HeadingFive,
          'rte__heading-six': type === BlockType.HeadingSix,
          'rte__table-cell-content': type === BlockType.TableCell,
          [`${ListItemClassName} rte__list-item--depth${depth} rte__olist-item`]:
            type === BlockType.OrderedListItem,
          [`${ListItemClassName} rte__list-item--depth${depth} rte__ulist-item`]:
            type === BlockType.UnorderedListItem,
          'rte__content-module': type === BlockType.ContentModule,
          'rte__content-image': type === BlockType.Image,
          'rte__content-component': type === BlockType.ContentComponent,
          'rte__new-block-placeholder': type === BlockType.NewBlockPlaceholder,
        }
      : // Custom block sleeve
        {
          [CustomBlockSleeveClassname]: true,
          [CustomBlockSleeveBeforeClassname]:
            customBlockSleevePosition === CustomBlockSleevePosition.BeforeOwner,
          [CustomBlockSleeveAfterClassname]:
            customBlockSleevePosition === CustomBlockSleevePosition.AfterOwner,
          [`rte__next--is-${nextBlockType}`]: !!nextBlockType,
          [`rte__previous--is-${previousBlockType}`]: !!previousBlockType,
        };

  return {
    [BlockClassName]: true,
    ...baseClassNames,
    ...specificClassNames,
  };
}

export const getBlockClass = memoize.weak((block: ContentBlock): string => {
  return classNames(getBlockClassNames(block));
});
