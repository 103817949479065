import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React, { useCallback } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export type CommentButtonDispatchProps = {
  readonly onClick: () => void;
};

export type CommentButtonDataProps = {
  readonly hasComment: boolean;
  readonly isFocused: boolean;
};

export type CommentButtonProps = CommentButtonDispatchProps & CommentButtonDataProps;

export const CommentButton: React.FC<CommentButtonProps> = ({ hasComment, isFocused, onClick }) => {
  const onButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onClick();
    },
    [onClick],
  );

  return (
    <QuinaryButton
      activated={isFocused}
      aria-label="Comment"
      onClick={onButtonClick}
      tooltipText={hasComment ? 'Show comment' : 'Add comment'}
      {...getDataUiElementAttribute(DataUiElement.CommentButton)}
    >
      <QuinaryButton.Icon icon={hasComment ? Icons.BubbleWithLines : Icons.Bubble} />
    </QuinaryButton>
  );
};
