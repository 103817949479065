import { mergeProps } from '@react-aria/utils';
import React, { ReactNode, RefObject, forwardRef, useRef } from 'react';
import { VariableSizeList as VirtualizedList } from 'react-window';
import { DropDownMenuControlled } from '../../../../../client/component-library/components/DropDownMenu/DropDownMenuControlled.tsx';
import { RefForwardingComponent } from '../../../@types/RefForwardingComponent.type.ts';
import {
  DataUiCLElement,
  ObjectWithDataAttribute,
  getDataUiCLElementAttribute,
  getDataUiComponentAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { IInputProps, Input } from '../../Input/Input.tsx';
import { InputState } from '../../Input/inputStateEnum.ts';
import { simpleMenuItemHeight } from '../../MenuItem/decisionTokens.ts';
import { isItem } from '../../VerticalMenu/utils/utils.tsx';
import { RequiredA11yLabellingPropsExtension } from '../../_utils/ariaLabelingProps.ts';
import { ChevronTrigger } from '../components/ChevronTrigger.tsx';
import { ListBox } from '../components/ListBox.tsx';
import { SelectMenu } from '../components/SelectMenu.tsx';
import { emptySelectionItem } from '../emptySelectionItem.ts';
import { useSelectMenu } from '../hooks/useSelectMenu.tsx';
import { IBaseSelectItem, ISelectItem, ItemId, VirtualizedOrNot } from '../types.ts';
import { findItem } from '../utils/findItem.ts';
import { UseSingleSelectOptions, useSingleSelect } from './useSingleSelect.ts';
import { UseSingleSelectStateOptions, useSingleSelectState } from './useSingleSelectState.ts';

type PickedInputProps = Partial<
  Pick<
    IInputProps,
    | 'autoFocus'
    | 'caption'
    | 'delayAutoFocus'
    | 'id'
    | 'label'
    | 'placeholder'
    | 'tooltipPlacement'
    | 'tooltipText'
  >
>;

type CaptionOrAriaProps = RequiredA11yLabellingPropsExtension<{
  readonly label: string;
}>;

type Props<TItem extends ISelectItem<TItem>> = PickedInputProps &
  Omit<UseSingleSelectStateOptions<TItem>, 'inputRef'> &
  Pick<UseSingleSelectOptions<TItem>, 'onInputChange'> &
  VirtualizedOrNot<TItem> &
  CaptionOrAriaProps & {
    readonly children?: never;
    readonly delayAutoFocus?: number;
    readonly inputDataAttributes?: ObjectWithDataAttribute;
    readonly inputRef?: RefObject<HTMLInputElement>;
    readonly renderPrefix?: (itemId: ItemId, item: TItem | null) => React.ReactNode;
    readonly verticalMenuDataAttributes?: ObjectWithDataAttribute;
  };

interface SingleSelectForwardingRef
  extends RefForwardingComponent<Props<IBaseSelectItem>, HTMLDivElement> {
  <TSelect extends ISelectItem<TSelect>>(props: Props<TSelect>): ReactNode;
}

export const SingleSelect: SingleSelectForwardingRef = forwardRef((props, forwardedRef) => {
  const {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    'aria-describedby': ariaDescribedBy,
    autoFocus,
    caption,
    children,
    defaultSelectedItemId,
    delayAutoFocus,
    disabledItemIds,
    id,
    inputDataAttributes,
    inputState,
    inputRef: forwardedInputRef,
    isVirtualized = false,
    items,
    label,
    optionHeight = simpleMenuItemHeight,
    onInputChange,
    onSelectionChange,
    placeholder,
    renderPrefix,
    renderMenuOption,
    selectedItemId,
    tooltipPlacement,
    tooltipText,
    verticalMenuDataAttributes,
    ...otherProps
  } = props;
  const isReadOnly = inputState === InputState.ReadOnly;
  const isDisabled = inputState === InputState.Disabled;

  const inputRef = useRef<HTMLInputElement>(null);
  const triggerRef = useRef<HTMLElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const virtualizedListRef = React.useRef<VirtualizedList<HTMLDivElement>>(null);

  const ensuredInputRef = forwardedInputRef ?? inputRef;

  const state = useSingleSelectState({
    ...props,
    inputRef: ensuredInputRef,
  });

  const { isOpen, selectionManager } = state;

  const { inputProps, listBoxProps, triggerProps } = useSingleSelect(
    {
      ...props,
      isVirtualized,
      inputRef: ensuredInputRef,
      inputState,
      triggerRef,
      menuRef,
      virtualizedListRef,
    },
    state,
  );

  const chevronTrigger = <ChevronTrigger {...triggerProps} />;

  const selectedKey = [...selectionManager.selectedKeys][0]?.toString() ?? emptySelectionItem.id;
  const foundSelectedItem = findItem(items, (item) => item.id === selectedKey);
  const selectedItem = foundSelectedItem && isItem(foundSelectedItem) ? foundSelectedItem : null;

  const selectMenuProps = useSelectMenu(
    {
      isReadOnly,
      isVirtualized,
      menuRef,
      optionHeight,
      renderMenuOption,
      selectionMode: 'single',
      verticalMenuDataAttributes,
      virtualizedListRef,
    },
    state,
  );

  return (
    <div ref={forwardedRef} {...getDataUiComponentAttribute(SingleSelect)} {...otherProps}>
      <DropDownMenuControlled
        triggerId={id}
        triggerRef={triggerRef}
        renderTrigger={({ ref, type, onKeyDown, ...restDropDownProps }) => (
          <Input
            inputFieldRef={ref}
            prefix={renderPrefix?.(selectedKey, selectedItem)}
            suffixes={[chevronTrigger]}
            {...getDataUiCLElementAttribute(DataUiCLElement.SingleSelectDropdownInput)}
            {...mergeProps(inputProps, restDropDownProps, {
              'aria-describedby': ariaDescribedBy,
              'aria-label': ariaLabel,
              'aria-labelledby': ariaLabelledBy,
              autoFocus,
              caption,
              delayAutoFocus,
              inputState,
              label,
              placeholder,
              tooltipPlacement,
              tooltipText,
            })}
            {...inputDataAttributes}
          />
        )}
        renderDropDown={(triggerWidth, _, menuProps) => (
          <ListBox {...mergeProps(listBoxProps, menuProps)}>
            <SelectMenu triggerWidth={triggerWidth} {...selectMenuProps} />
          </ListBox>
        )}
        isDropDownVisible={isOpen && !isDisabled}
        onDropDownVisibilityChange={(newIsOpen) => {
          if (!newIsOpen) {
            state.revertState();
          }
        }}
      />
    </div>
  );
});

SingleSelect.displayName = 'SingleSelect';
