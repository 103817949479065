import styled from 'styled-components';
import {
  colorAlertText,
  colorInfoText,
  colorWarningText,
} from '../../../tokens/decision/colors.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { CalloutType } from '../types.ts';

type StyledSubheadlineProps = Readonly<{
  calloutType: CalloutType;
}>;

const getSubheadlineColor = ({ calloutType }: StyledSubheadlineProps) => {
  switch (calloutType) {
    case 'friendlyWarning':
      return colorWarningText;
    case 'quickTip':
      return colorInfoText;
    case 'warning':
      return colorAlertText;
  }
};

export const StyledSubheadline = styled.div<StyledSubheadlineProps>`
  ${Typography.Subheadline};
  color: ${getSubheadlineColor};
`;
