import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { useAttachRef } from '@kontent-ai/hooks';
import { useButton } from '@react-aria/button';
import React, { useRef } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IUrlSlugAutogenerateButtonProps {
  readonly onClick: () => void;
}

export const UrlSlugAutogenerateButton: React.FC<IUrlSlugAutogenerateButtonProps> = ({
  onClick,
}) => {
  const ref = useRef();
  const { refObject } = useAttachRef(ref.current);

  const { buttonProps } = useButton(
    {
      onPress: () => {
        onClick();
      },
    },
    refObject,
  );

  return (
    <QuinaryButton
      tooltipText="Autogenerate"
      aria-label="Autogenerate"
      className="text-field__button"
      {...buttonProps}
      {...getDataUiActionAttribute(DataUiAction.Regenerate)}
    >
      <QuinaryButton.Icon icon={Icons.RotateDoubleRight} />
    </QuinaryButton>
  );
};
