import { useHistory } from 'react-router';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemsAppRouteSegment,
  ContentModelsRoute,
  EnvironmentRouteParams,
  MissionControlYourWorkRoute,
} from '../../../../../_shared/constants/routePaths.ts';
import { WelcomeContentItemId } from '../../../../../_shared/constants/sampleContentItems.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { isTechnicalRole } from '../../../../../_shared/models/utils/quickstartUtils.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getUrlFactory } from '../../../../../_shared/utils/urlFactory.ts';
import { getCurrentProjectId } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  OnboardingCardsSection as OnboardingCardsSectionComponent,
  OnboardingCardsSectionType,
} from '../../components/GettingStartedQuickstart/OnboardingCardsSection.tsx';

export const OnboardingCardsSection = () => {
  const projectId = useSelector(getCurrentProjectId);
  const onboardingType = useSelector((s) =>
    isTechnicalRole(s.sharedApp.userProperties.businessRole)
      ? OnboardingCardsSectionType.Technical
      : OnboardingCardsSectionType.Business,
  );
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <OnboardingCardsSectionComponent
      type={onboardingType}
      onClickEditContent={() => {
        dispatch(
          trackUserEventWithData(TrackedEvent.QuickstartLinkOpened, {
            'link-id': 'edit-content-card-cta',
          }),
        );
        history.push(
          buildPath<ContentItemRouteParams<UuidArray>>(ContentItemRoute, {
            app: ContentItemsAppRouteSegment.Content,
            projectId,
            variantId: DefaultVariantId,
            spaceId: undefined,
            contentItemIds: [WelcomeContentItemId],
          }),
        );
      }}
      onClickFindContent={() => {
        dispatch(
          trackUserEventWithData(TrackedEvent.QuickstartLinkOpened, {
            'link-id': 'find-content-card-cta',
          }),
        );
        history.push(
          buildPath<EnvironmentRouteParams>(MissionControlYourWorkRoute, {
            projectId,
          }),
        );
      }}
      onClickPreviewContent={() => {
        dispatch(
          trackUserEventWithData(TrackedEvent.QuickstartLinkOpened, {
            'link-id': 'preview-content-card-cta',
          }),
        );
        window.open(getUrlFactory().getDeployedPreviewAppUrl(projectId), '_blank');
      }}
      onClickSeeJSON={() => {
        dispatch(
          trackUserEventWithData(TrackedEvent.QuickstartLinkOpened, {
            'link-id': 'see-json-card-cta',
          }),
        );
        const jsonSourceUrl = `${getUrlFactory().getDeliverLiveUrl()}/${projectId}/items/`;
        const jsonPrettifierUrl = getUrlFactory().getJsonPrettifierUrl(jsonSourceUrl);
        window.open(jsonPrettifierUrl, '_blank');
      }}
      onClickStartModeling={() => {
        dispatch(
          trackUserEventWithData(TrackedEvent.QuickstartLinkOpened, {
            'link-id': 'start-modeling-card-cta',
          }),
        );
        history.push(buildPath<EnvironmentRouteParams>(ContentModelsRoute, { projectId }));
      }}
    />
  );
};

OnboardingCardsSection.displayName = 'OnboardingCardsSection';
