import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';

export function getIncompleteElementsTooltip(incompleteElements: number): string | null {
  if (!incompleteElements) {
    return null;
  }
  return `${pluralizeWithCount('element', incompleteElements)} left to complete`;
}

export function getTooltipText(incompleteElements: number, comments: number): string | undefined {
  return (
    [
      getIncompleteElementsTooltip(incompleteElements),
      comments > 0 ? pluralizeWithCount('comment', comments) : undefined,
    ]
      .filter((x) => !!x)
      .join('\n') || undefined
  );
}
