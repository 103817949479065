import { Action } from '../../../../../../@types/Action.type.ts';
import { HighlightedTask_Ensured } from '../../../../../contentInventory/shared/constants/contentInventoryActionTypes.ts';
import { YourTasks_GoToTask_Selected } from '../../../../../missionControl/widgets/tasksAssignedToYou/constants/yourTasksActionTypes.ts';
import {
  ContentItemEditing_HighlightOpenTasks_Finished,
  ContentItemEditing_HighlightedTask_Highlighted,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: UuidArray = [];

const removeTask = (all: UuidArray, removed: Uuid): UuidArray => all.filter((id) => id !== removed);

export const highlightedTaskIds = (state = initialState, action: Action): UuidArray => {
  switch (action.type) {
    case HighlightedTask_Ensured:
    case YourTasks_GoToTask_Selected:
      return [action.payload.taskId];

    case ContentItemEditing_HighlightedTask_Highlighted:
      return removeTask(state, action.payload.taskId);

    case ContentItemEditing_HighlightOpenTasks_Finished:
      return action.payload.taskIds;

    default:
      return state;
  }
};
