import { Box } from '@kontent-ai/component-library/Box';
import { forwardRef } from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getEditedContentItem } from '../../../../../../_shared/selectors/getEditedContentItem.ts';
import { WebSpotlightInItemEditingContextProvider } from '../../context/WebSpotlightInItemEditingContext.tsx';
import { useGetPreviewLink } from '../../hooks/useGetPreviewLink.ts';
import { ContentItemPreviewActionBar } from './ContentItemPreviewActionBar.tsx';
import { ContentItemPreviewCanvas } from './ContentItemPreviewCanvas.tsx';

export const ContentItemPreview = forwardRef<HTMLDivElement>((_, ref) => {
  const { id: contentItemId } = useSelector(getEditedContentItem);
  const previewLink = useGetPreviewLink();

  if (!previewLink?.url) {
    return null;
  }

  return (
    <WebSpotlightInItemEditingContextProvider
      isAutoRefreshEnabled={false}
      previewUrl={previewLink.url}
      spaceId={previewLink.spaceId}
    >
      <Box css="grid-area: preview" display="flex" flexDirection="column" ref={ref}>
        <ContentItemPreviewActionBar />
        <Box width="100%" height="100%" flexGrow={1}>
          <ContentItemPreviewCanvas contentItemId={contentItemId} previewLink={previewLink} />
        </Box>
      </Box>
    </WebSpotlightInItemEditingContextProvider>
  );
});
