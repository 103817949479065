import {
  ContentState,
  DraftBlockRenderConfig,
  DraftDecorator,
  Editor as DraftJSEditor,
  EditorProps as DraftJSEditorProps,
  EditorState,
} from 'draft-js';
import { GetEditorRef } from '../../editorCore/hooks/useGetEditorRef.ts';
import { EditorPlugin } from '../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../editorCore/types/EditorChangeReason.ts';
import { DecorableFunction } from '../../editorCore/utils/decorable.ts';
import { BlockType } from '../../utils/blocks/blockType.ts';
import { DraftJsEditorApi } from './api/draftJsEditorApi.type.ts';
import { BaseBlockRenderMap } from './utils/draftJsEditorUtils.ts';

export enum UndoState {
  // We keep the enum number-based be able to compare and take the best/worst
  DisabledDropHistory = 0,
  DisabledKeepHistory = 1,
  EnabledKeepHistory = 2,
}

export type InitialState = {
  readonly initialEditorState: EditorState;
  readonly content: ContentState;
  readonly decorators: ReadonlyArray<DraftDecorator>;
  readonly undo: UndoState;
};

export type GetInitialState = (initialEditorState: EditorState) => InitialState;

export type Change = (prevState: EditorState) => EditorState;

export type ExecuteChange = (
  change: Change,
  changeReason?: EditorChangeReason,
) => Promise<EditorState>;

export type GetEditorState = () => EditorState;

export type GetBaseBlockRenderMap = () => BaseBlockRenderMap;

export type ApplyEditorStateChanges = (params: {
  readonly newState: EditorState;
  readonly oldState: EditorState;
  readonly allowEditContent: boolean;
  readonly changeReason: EditorChangeReason;
}) => EditorState;

export type OnUpdate = (params: {
  readonly editorState: EditorState;
  readonly changeReason: EditorChangeReason;
}) => void;

type AsyncChange = (prevState: EditorState) => Promise<EditorState>;

export type ExecuteExternalAction = (
  change: AsyncChange,
  changeReason?: EditorChangeReason,
) => Promise<EditorState>;

export type Reinit = (editorState: EditorState) => void;

export type RemoveInvalidState = (editorState: EditorState) => void;

// Even when disabled the editor itself is still active editable area to be able to properly handle selection
// Content changes are prevented programmatically
export type InternalEditorProps = Readonly<
  Omit<DraftJSEditorProps, 'readOnly' | 'blockRenderMap'> & {
    blockRenderMap: Immutable.Map<BlockType, DraftBlockRenderConfig>;
  }
>;

type DraftJsPluginState = {
  readonly applyEditorStateChanges: DecorableFunction<ApplyEditorStateChanges>;
  readonly editorProps: InternalEditorProps;
  readonly editorState: EditorState;
  readonly executeChange: ExecuteChange;
  readonly executeExternalAction: ExecuteExternalAction;
  readonly getEditorRef: GetEditorRef<DraftJSEditor>;
  readonly getEditorState: GetEditorState;
  readonly getBaseBlockRenderMap: DecorableFunction<GetBaseBlockRenderMap>;
  readonly getInitialState: DecorableFunction<GetInitialState>;
  readonly onUpdate: DecorableFunction<OnUpdate>;
  readonly reinit: Reinit;
  readonly removeInvalidState: DecorableFunction<RemoveInvalidState>;
};

type DraftJsPluginProps = {
  readonly editorState: EditorState;
};

export type DraftJsPlugin = EditorPlugin<DraftJsPluginState, DraftJsPluginProps, DraftJsEditorApi>;
