import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';

export enum ActionType {
  Create = 'Create',
  Delete = 'Delete',
  Restore = 'Restore',
  Update = 'Update',
}

export const ActionTypeList = [
  { id: ActionType.Create, label: ActionType.Create },
  { id: ActionType.Delete, label: ActionType.Delete },
  { id: ActionType.Restore, label: ActionType.Restore },
  { id: ActionType.Update, label: ActionType.Update },
] satisfies ReadonlyArray<IBaseSelectItem>;

export const getActionTypePastTenseString = (actionType: ActionType): string => {
  switch (actionType) {
    case ActionType.Create:
      return 'created';
    case ActionType.Update:
      return 'updated';
    case ActionType.Delete:
      return 'deleted';
    case ActionType.Restore:
      return 'restored';
  }
};
