import { useEnsuredContext } from '@kontent-ai/hooks';
import { Collection } from '@kontent-ai/utils';
import React, { useLayoutEffect, forwardRef, useMemo } from 'react';
import styled from 'styled-components';
import {
  AttachVisibleItemRefCallback,
  SliceDirection,
  SliceFrom,
  useSliceOverflowingItems,
} from '../../../hooks/useSliceOverflowingItems.ts';
import { Column } from '../../../layout/Row/Column.tsx';
import { px } from '../../../tokens/utils/utils.ts';
import { BreadcrumbsContext } from '../BreadcrumbsContext.tsx';
import { breadcrumbsHeight } from '../decisionTokens.ts';
import { BreadcrumbsItem, BreadcrumbsItemProps } from './BreadcrumbsItem.tsx';
import { BreadcrumbsVerticalMenu } from './BreadcrumbsVerticalMenu.tsx';

const StyledNavigation = styled(Column)`
  height: ${px(breadcrumbsHeight)};
  min-width: 0;
  display: flex;
  align-items: center;
`;

const StyledList = styled.ol`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
`;

type ShortenedBreadcrumbsItemsProps = {
  readonly attachVisibleItemRef: AttachVisibleItemRefCallback;
  readonly visibleItems: ReadonlyArray<BreadcrumbsItemProps>;
  readonly hiddenItems: ReadonlyArray<BreadcrumbsItemProps>;
};

const ShortenedBreadcrumbsItems: React.FC<ShortenedBreadcrumbsItemsProps> = ({
  attachVisibleItemRef,
  visibleItems,
  hiddenItems,
}) => (
  <>
    {!!hiddenItems.length && <BreadcrumbsVerticalMenu items={hiddenItems} />}
    {visibleItems.map((item: BreadcrumbsItemProps, index) => (
      <BreadcrumbsItem
        key={item.to}
        label={item.label}
        to={item.to}
        ref={attachVisibleItemRef(index)}
      />
    ))}
  </>
);

const getHideableItems = (
  items: ReadonlyArray<BreadcrumbsItemProps>,
): ReadonlyArray<BreadcrumbsItemProps> => items.slice(1, -1);

type Props = {
  readonly containerRef: React.RefObject<HTMLElement>;
  readonly items: ReadonlyArray<BreadcrumbsItemProps>;
};

export const BreadcrumbsItems = forwardRef<HTMLDivElement, Props>(
  ({ containerRef, items, ...restProps }, forwardedRef) => {
    const hideableItems = useMemo(() => getHideableItems(items), [items]);
    const firstItem = Collection.getFirst(items);
    const lastItem = items.length > 1 ? items.at(-1) : null;

    const { attachVisibleItemRef, visibleItems, hiddenItems } = useSliceOverflowingItems(
      hideableItems,
      containerRef,
      SliceFrom.Beginning,
      SliceDirection.Horizontal,
    );

    const { allowEllipsis, setAllowEllipsis } = useEnsuredContext(BreadcrumbsContext);

    useLayoutEffect(() => {
      setAllowEllipsis(visibleItems.length === 0);
    }, [setAllowEllipsis, visibleItems.length]);

    return (
      <StyledNavigation
        ref={forwardedRef}
        width={allowEllipsis ? 'fit-content' : 'content'}
        {...restProps}
      >
        <StyledList>
          {firstItem && (
            <BreadcrumbsItem
              label={firstItem.label}
              to={firstItem.to}
              lastItem={!lastItem}
              allowEllipsis={allowEllipsis}
            />
          )}
          <ShortenedBreadcrumbsItems
            {...{
              attachVisibleItemRef,
              visibleItems,
              hiddenItems,
            }}
          />
          {lastItem && (
            <BreadcrumbsItem
              label={lastItem.label}
              to={lastItem.to}
              lastItem
              allowEllipsis={allowEllipsis}
            />
          )}
        </StyledList>
      </StyledNavigation>
    );
  },
);

BreadcrumbsItems.displayName = 'BreadcrumbsItems';
