import { assert } from '@kontent-ai/utils';
import { ContentBlock } from 'draft-js';
import { isEmptyOrWhitespace, isString } from '../../../../../../_shared/utils/stringUtils.ts';
import { isUuid } from '../../../../../../_shared/utils/validation/typeValidators.ts';
import { IContentComponentServerModel } from '../../../../../itemEditor/models/contentItem/ContentComponentServerModel.type.ts';
import { getContentComponentId } from '../../../../plugins/contentComponents/api/editorContentComponentUtils.ts';
import { getImageAssetReference } from '../../../../plugins/images/api/editorImageUtils.ts';
import { getModularContentItemId } from '../../../../plugins/linkedItems/api/editorModularUtils.ts';
import { BlockType, isObjectBlockType } from '../../../blocks/blockType.ts';
import { getBaseBlockType } from '../../../blocks/editorBlockGetters.ts';
import { HtmlData, TagData } from './tags.ts';

export const KontentObjectType = 'application/kenticocloud';

export enum ObjectDataType {
  AiSession = 'ai-session',
  ContentComponent = 'component',
  ContentItem = 'entry',
  Project = 'project-id',
}

export const getObjectTagData = (
  dataObjectType: ObjectDataType,
  objectId: Uuid,
  dataValue?: string,
): TagData => ({
  tagName: 'object',
  attributes: {
    type: KontentObjectType,
    'data-type': dataObjectType,
    'data-id': objectId,
    ...(isString(dataValue) && !isEmptyOrWhitespace(dataValue)
      ? { 'data-value': dataValue }
      : undefined),
  },
});

const getContentComponentTagData = (block: ContentBlock): TagData | null => {
  const contentComponentId = getContentComponentId(block);
  if (!isUuid(contentComponentId)) {
    return null;
  }

  return getObjectTagData(ObjectDataType.ContentComponent, contentComponentId);
};

export const getContentComponentWithValueTagData = (
  block: ContentBlock,
  contentComponents: ReadonlyMap<Uuid, IContentComponentServerModel>,
): TagData | null => {
  const contentComponentId = getContentComponentId(block);
  if (!isUuid(contentComponentId)) {
    return null;
  }

  const contentComponent: IContentComponentServerModel | undefined =
    contentComponents?.get(contentComponentId);
  const dataValue = contentComponent ? JSON.stringify(contentComponent) : '';

  return getObjectTagData(ObjectDataType.ContentComponent, contentComponentId, dataValue);
};

const getLinkedItemTagData = (block: ContentBlock): TagData | null => {
  const contentItemId = getModularContentItemId(block);
  if (!isUuid(contentItemId)) {
    return null;
  }

  return getObjectTagData(ObjectDataType.ContentItem, contentItemId);
};

const getImageData = (block: ContentBlock): HtmlData | null => {
  const assetId = getImageAssetReference(block)?.id;
  if (!isUuid(assetId)) {
    return null;
  }

  return [
    {
      tagName: 'figure',
      attributes: { 'data-asset-id': assetId },
      children: [
        {
          tagName: 'img',
          attributes: {
            src: '#',
            'data-asset-id': assetId,
          },
          selfClosing: true,
        },
      ],
    },
  ];
};

export const getObjectBlockData = (block: ContentBlock): HtmlData | null => {
  const blockType = getBaseBlockType(block);
  assert(isObjectBlockType(blockType), () => 'Block must be object block');

  switch (blockType) {
    case BlockType.Image:
      return getImageData(block);

    case BlockType.ContentModule: {
      const tagData = getLinkedItemTagData(block);
      return tagData && [tagData];
    }

    case BlockType.ContentComponent: {
      const tagData = getContentComponentTagData(block);
      return tagData && [tagData];
    }
  }
};
