import { IconButton } from '@kontent-ai/component-library/Button';
import { RefObject, useCallback, useState } from 'react';
import ReactFocusLock from 'react-focus-lock';
import {
  HotkeysHandler,
  HotkeysMode,
} from '../../../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { DropDownFrame } from '../../../../../../../_shared/uiComponents/DropDown/DropDownFrame.tsx';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISavedFiltersUi } from '../../../../reducers/listingUi/reducers/savedFiltersUi/ISavedFiltersUi.type.ts';
import { FilterAction } from '../../constants/listingFilterConstants.ts';
import { FilterActionsLeafMenu } from './FilterActionsLeafMenu.tsx';
import { FilterActionsMainMenu } from './FilterActionsMainMenu.tsx';
import { FilterNameForm } from './FilterNameForm.tsx';
import { ShareFilterLinkForm } from './ShareFilterLinkForm.tsx';

type Props = {
  readonly canSaveCurrentFilter: boolean;
  readonly savedFiltersUi: ISavedFiltersUi;
  readonly onSaveFilter: (name: string) => void;
  readonly onCreateLinkedFilter: () => void;
};

export const FilterActionsMenu = (props: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeAction, setActiveAction] = useState<FilterAction>(FilterAction.MainMenu);

  const hideMenu = useCallback(() => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [isMenuOpen]);

  const showMainMenu = () => {
    setActiveAction(FilterAction.MainMenu);
    setIsMenuOpen(true);
  };

  const toggleMenu = isMenuOpen ? hideMenu : showMainMenu;

  return (
    <DropDownFrame
      longer
      optionListClassName="filter-actions-menu"
      optionListDataUiAttributes={getDataUiCollectionAttribute(
        DataUiCollection.MoreActionsDropdown,
      )}
      isOptionListVisible={isMenuOpen}
      onClickOutside={hideMenu}
      renderContent={() => (
        <ReactFocusLock>
          <HotkeysHandler mode={HotkeysMode.Dual} handlers={{ onEscape: hideMenu }}>
            {activeAction === FilterAction.MainMenu ? (
              <FilterActionsMainMenu
                canSaveCurrentFilter={props.canSaveCurrentFilter}
                onActionClick={setActiveAction}
              />
            ) : (
              <FilterActionsLeafMenu
                activeAction={activeAction}
                onBackClick={showMainMenu}
                renderLeafForm={() => (
                  <div className="form__group filter-actions-menu__leaf-form">
                    {activeAction === FilterAction.SaveFilter && (
                      <FilterNameForm
                        onClose={hideMenu}
                        disabled={props.savedFiltersUi.isFilterBeingCreated}
                        onSave={props.onSaveFilter}
                      />
                    )}
                    {activeAction === FilterAction.ShareFilter && (
                      <ShareFilterLinkForm
                        link={props.savedFiltersUi.currentFilterLink}
                        loading={props.savedFiltersUi.currentFilterLinkLoading}
                        onCreateLinkedFilter={props.onCreateLinkedFilter}
                      />
                    )}
                  </div>
                )}
              />
            )}
          </HotkeysHandler>
        </ReactFocusLock>
      )}
      renderSelector={(ref: RefObject<HTMLButtonElement>) => (
        <IconButton
          ref={ref}
          iconName="ThreeDotsVertical"
          activated={isMenuOpen}
          onClick={toggleMenu}
          size="small"
          buttonStyle="tertiary"
          tooltipText="More actions"
          tooltipPlacement="right"
          aria-label="More actions"
          {...getDataUiActionAttribute(DataUiAction.MoreActions)}
        />
      )}
    />
  );
};
