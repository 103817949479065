import { isAbortedXMLHttpRequest } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { browserIsOffline, onAccessUnauthorized } from '../../../_shared/actions/sharedActions.ts';
import { ICurrentUserInfo } from '../../../data/models/user/CurrentUserInfo.ts';

interface IDeps {
  readonly loadUserInfo: (abortSignal: AbortSignal) => ThunkPromise<ICurrentUserInfo>;
}

export const createHandleServerErrorResponseAction =
  (deps: IDeps) =>
  (httpRequest: XMLHttpRequest, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    switch (httpRequest.status) {
      case 401: {
        // Unauthorized access
        dispatch(onAccessUnauthorized());
        break;
      }
      case 403: {
        // Forbidden access (logged in but does not have the necessary permission)
        await dispatch(deps.loadUserInfo(abortSignal));
        break;
      }
      case 0: {
        // The browser is offline
        if (!isAbortedXMLHttpRequest(httpRequest)) {
          const state = getState();
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
          const isNotOffline = !state || !state.sharedApp || state.sharedApp.isOnline !== false;

          if (isNotOffline) {
            dispatch(browserIsOffline());
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  };
