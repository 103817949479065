import { Collection, createCompare, numerically } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { CommonPlanName } from '../../../constants/CommonPlanName.ts';
import { PlanType, orderedPlans } from '../../../constants/PlanType.ts';
import { IPlan } from '../../../models/plans/Plan.ts';
import { ISubscription } from '../../../models/subscriptions/Subscription.ts';
import { getActiveSubscriptions } from '../../subscriptions/selectors/subscriptionSelectors.ts';

export const getPlanNameSearchKey = (planType: Exclude<PlanType, 'Unknown'>): string => {
  switch (planType) {
    case 'Employee':
      return CommonPlanName.Employee;
    case 'Starter':
      return CommonPlanName.Starter;
    case 'Developer':
      return CommonPlanName.Developer;
    case 'Trial':
      return CommonPlanName.Trial;
    case 'Delivery':
      return 'delivery';
    case 'Professional':
      return CommonPlanName.Professional;
    case 'Business':
      return 'business';
    case 'Premium':
      return CommonPlanName.Premium;
    case 'Enterprise':
      return 'enterprise';
    case 'Partner':
      return 'partner';
  }
};

export const getHighestPlan = (
  plans: Immutable.Map<Uuid, IPlan>,
  subscriptions: Immutable.Map<Uuid, ISubscription>,
): PlanType | null => {
  const subscriptionValues = subscriptions.valueSeq().toArray();
  const activePlanIds = new Set(
    getActiveSubscriptions(subscriptionValues).map((sub) => sub.currentPlan.planId),
  );

  const isActivePlan = (plan: IPlan): boolean => activePlanIds.has(plan.planId);

  const sortedPlans = plans
    .toArray()
    .filter(isActivePlan)
    .map(getOrdinalPlanType)
    .sort(planOrderComparer);

  return Collection.getLast(sortedPlans);
};

const getOrdinalPlanType = (plan: IPlan): PlanType =>
  orderedPlans.find(
    (ordinalPlanType) =>
      ordinalPlanType !== 'Unknown' && plan.name.startsWith(getPlanNameSearchKey(ordinalPlanType)),
  ) ?? 'Unknown';

const planOrderComparer = createCompare<PlanType, number>({
  compare: numerically,
  select: (type) => orderedPlans.indexOf(type),
});
