import { createGuid } from '@kontent-ai/utils';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { initializeRequestTokenFactory } from '../../../../../_shared/utils/requestTokenUtils.ts';
import { loadListingContentItemsByIds } from '../../../../../data/actions/thunkDataActions.ts';
import {
  createInitYourTasks,
  createTokenizedInitYourTasksStarted,
} from './thunks/initYourTasks.ts';
import { createLoadRelatedItems } from './thunks/loadRelatedItems.ts';

const { taskRepository } = repositoryCollection;

const yourTasksRequestTokenFactory = initializeRequestTokenFactory(
  (state) => state.yourTasksApp.cancellationToken,
  createTokenizedInitYourTasksStarted,
  createGuid,
);

export const loadRelatedItems = createLoadRelatedItems({
  loadListingContentItemsByIds,
});

export const initYourTasks = createInitYourTasks({
  loadRelatedItems,
  taskRepository,
  requestTokenFactory: yourTasksRequestTokenFactory,
});
