import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { App } from './_shared/containers/App.tsx';
import { RedirectIfUserIsIncomplete } from './_shared/containers/routing/RedirectIfUserIsIncomplete.tsx';
import { AuthTokenProvider } from './_shared/contexts/AuthTokenProvider.tsx';
import { useAnimationsAccessibility } from './_shared/hooks/useAnimationsAccessibility.ts';
import { useBrowserActivationClasses } from './_shared/hooks/useBrowserActivationClasses.ts';
import { initializeSentry } from './_shared/sentry.ts';
import { createClientReduxStore } from './_shared/stores/clientReduxStore.ts';
import { initializeApplicationInsights } from './_shared/utils/applicationInsights.ts';
import {
  disableBrowserSpecificFeatures,
  ignoreInsertKey,
  logUnhandledPromiseRejection,
  registerUrlFactory,
  setupComponentLibrary,
} from './_shared/utils/bootstrap.ts';
import { bootstrapContentComponentElementComponents } from './applications/itemEditor/features/ContentComponent/utils/contentComponentElementComponentBootstrapper.ts';
import { bootstrapExpandedElementComponents } from './applications/itemEditor/features/LinkedItems/utils/expandedElementComponentBootstrapper.ts';
import { Initializer } from './initialization/containers/Initializer.tsx';

initializeApplicationInsights();
initializeSentry();
setupComponentLibrary();
registerUrlFactory();
logUnhandledPromiseRejection();
ignoreInsertKey();
disableBrowserSpecificFeatures();
// CMP-473 this is here because of cyclic dependencies within expandable linked items
bootstrapExpandedElementComponents();
// CMP-704 this is here because of cyclic dependencies within nested content components in rich text element
bootstrapContentComponentElementComponents();

const store = createClientReduxStore();

export const Root = () => {
  useAnimationsAccessibility();
  useBrowserActivationClasses();

  return (
    <StrictMode>
      <Provider store={store}>
        <HelmetProvider>
          <BrowserRouter>
            <AuthTokenProvider>
              <Initializer
                renderWhenInitialized={() => (
                  <RedirectIfUserIsIncomplete>
                    <App />
                  </RedirectIfUserIsIncomplete>
                )}
              />
            </AuthTokenProvider>
          </BrowserRouter>
        </HelmetProvider>
      </Provider>
    </StrictMode>
  );
};
