import { ReactNode } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { ContentItemOpenCommentRouteParams } from '../../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../_shared/hooks/useThunkPromise.ts';
import { initContentItemEditing } from '../actions/thunkContentItemEditingActions.ts';
import { getIsEditedItemInitialized } from '../selectors/isEditedItemInitialized.ts';

type Props = {
  readonly children: ReactNode;
};

export const EnsureEditedItemInitialized = ({ children }: Props) => {
  const { isInitialized } = useInitContentItemEditing();

  return isInitialized ? children : null;
};

const useInitContentItemEditing = (): {
  readonly isInitialized: boolean;
} => {
  const history = useHistory();
  const { commentThreadId } = useParams<ContentItemOpenCommentRouteParams<string>>();

  const [isInitItemEditingThunkDone] = useThunkPromise(
    initContentItemEditing,
    history,
    commentThreadId,
  );

  const location = useLocation();
  const isInitialized = useSelector(
    (s) => isInitItemEditingThunkDone && getIsEditedItemInitialized(s, location.pathname),
  );

  return {
    isInitialized,
  };
};
