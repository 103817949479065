import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import {
  DataUiStatusBarActions,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly to: string;
};

export const ContentItemRevisionToolbarActions = ({ to }: Props) => (
  <RouterLinkButton
    to={to}
    buttonStyle="tertiary"
    size="medium"
    {...getDataUiActionAttribute(DataUiStatusBarActions.CloseRevisions)}
  >
    <RouterLinkButton.Icon icon={Icons.Times} />
    Close
  </RouterLinkButton>
);
