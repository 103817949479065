import { Action } from '../../../../../../@types/Action.type.ts';
import { ItemsRequestTrigger } from '../../../../../../_shared/utils/scrollTableUtils.ts';
import { Data_ListingContentItems_Success } from '../../../../../../data/constants/dataActionTypes.ts';
import { AiSearch_Started } from '../../../../../../paperModels/aiSearch/actions/aiSearchActionTypes.ts';
import {
  ModalContentItemSelector_Closed,
  ModalContentItemSelector_Opened,
  ModalMultipleContentItemsSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../../../../../features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import {
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_CancelScheduledPublishingFinished,
  Content_Editing_CancelScheduledUnpublishingFinished,
  Content_Editing_PublishContentItemVariantFinished,
  Content_Editing_ScheduledPublishingFinished,
  Content_Editing_UnpublishContentItemVariantFinished,
} from '../../../../../itemEditor/constants/editorActionTypes.ts';
import {
  ContentItemEditing_Archiving_Finished,
  ContentItemEditing_CopyContentFromVariant_Finished,
  ContentItemEditing_CreateNewVersion_Finished,
  ContentItemEditing_Duplicating_Finished,
  ContentItemEditing_Init_Ready,
  ContentItemEditing_ItemElementsSaving_Finished,
  ContentItemEditing_ItemSaving_Finished,
  ContentItemEditing_LinkedEntityChanged_ItemRefreshed,
} from '../../../../../itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ContentListing_OrderBy_Changed } from '../../../features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { itemEditorOperationIds } from '../../../utils/itemEditorOperationIdUtils.ts';

export const isOrderByDisplayed = (state: boolean = true, action: Action): boolean => {
  switch (action.type) {
    case Data_ListingContentItems_Success:
      return state || action.payload.requestTrigger === ItemsRequestTrigger.Other;

    case ContentListing_OrderBy_Changed:
    case ModalMultipleContentItemsSelector_Closed:
    case ModalMultipleContentItemsSelector_Opened:
    case ModalContentItemSelector_Closed:
    case ModalContentItemSelector_Opened:
      return true;

    case ContentItemEditing_ItemSaving_Finished:
      return (
        state &&
        (action.payload.operationId !== itemEditorOperationIds.name ||
          action.payload.orderBy.columnCode !== 'name')
      );

    // new item will be added on the top of the list
    case ContentItemEditing_Duplicating_Finished:
    case AiSearch_Started:
      return false;

    case ContentItemEditing_Init_Ready:
      return state && !action.payload.isContentItemVariantJustCreated;

    // due date
    case Content_Editing_AssignmentSubmittingFinished:
      return state && action.payload.orderBy.columnCode !== 'due';

    // publish date
    case Content_Editing_PublishContentItemVariantFinished:
    case Content_Editing_UnpublishContentItemVariantFinished:
      return state && action.payload.orderBy.columnCode !== 'lastPublishedAt';

    // leave the cases here to make it explicit
    case Content_Editing_CancelScheduledPublishingFinished:
    case Content_Editing_CancelScheduledUnpublishingFinished:
    case Content_Editing_ScheduledPublishingFinished:
    case ContentItemEditing_Archiving_Finished:
    case ContentItemEditing_CopyContentFromVariant_Finished:
    case ContentItemEditing_CreateNewVersion_Finished:
    case ContentItemEditing_ItemElementsSaving_Finished:
    case ContentItemEditing_LinkedEntityChanged_ItemRefreshed:
      return state;

    default:
      return state;
  }
};
