export enum AssetFileTypeOption {
  Any = 'any',
  Images = 'image',
  ResponsiveImages = 'responsive-image',
}

export const ResponsiveImagesExplanation: string =
  'Adjustable images are limited to jpeg, png, gif, and webp and\ncan be transformed via the Delivery API.';

export const defaultAssetFileTypeOption = AssetFileTypeOption.Any;
export const defaultRichTextFileTypeOption = AssetFileTypeOption.Images;

export const AssetFileTypes: ReadonlyArray<AssetFileTypeOption> = [
  AssetFileTypeOption.Any,
  AssetFileTypeOption.ResponsiveImages,
];
export const RichTextImageTypes: ReadonlyArray<AssetFileTypeOption> = [
  AssetFileTypeOption.Images,
  AssetFileTypeOption.ResponsiveImages,
];

export function getAssetFileTypeOptionName(value: AssetFileTypeOption): string {
  switch (value) {
    case AssetFileTypeOption.Any:
      return 'Any file';
    case AssetFileTypeOption.Images:
      return 'Any image';
    case AssetFileTypeOption.ResponsiveImages:
      return 'Adjustable images';
  }
}
