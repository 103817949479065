import { useCallback } from 'react';
import { createGlobalStyle } from 'styled-components';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { getEditorIdClassName } from '../../editorCore/utils/editorComponentUtils.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { StylesPlugin } from './StylesPlugin.tsx';

export type BorderlessPlugin = DraftJsEditorPlugin<None, None, None, None, [StylesPlugin]>;

const BorderlessEditorStyle = createGlobalStyle<{ readonly editorId: string }>`
  .${(props) => getEditorIdClassName(props.editorId)} {
    background: none;
    border: none;
  }

  .${(props) => getEditorIdClassName(props.editorId)} .public-DraftEditor-content {
    padding: 0;
  }
`;

const render: Decorator<Render<BorderlessPlugin>> = (baseRender) => (state) => (
  <>
    {baseRender(state)}
    <BorderlessEditorStyle editorId={state.getEditorId()} />
  </>
);

export const BorderlessPlugin: PluginComponent<BorderlessPlugin> = (props) => {
  const apply: Apply<BorderlessPlugin> = useCallback((state) => {
    state.render.decorate(render);
    return {};
  }, []);

  return useEditorWithPlugin(props, { apply });
};
