import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../@types/Action.type.ts';
import { ContentItemIdString } from '../../../../../_shared/models/ContentItemId.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  ContentStatusCommentOrTaskCountsLoadedActionPayload,
  ContentStatusCommentOrTaskCountsStartedActionPayload,
} from '../../actions/commentOrTasks.ts';
import {
  ContentStatusCommentCounts_Load_Started,
  ContentStatusCommentCounts_Load_Success,
} from '../../constants/contentStatusActionTypes.ts';

export type ValueWithLoadingStatus<T> = Readonly<{
  value: T;
  loadingStatus: LoadingStatus;
}>;

export type ContentStatusCommentCount = ValueWithLoadingStatus<number>;

export const commentsOrTasksLoadedReducer = <T extends ValueWithLoadingStatus<number>>(
  state: ReadonlyMap<ContentItemIdString, T>,
  payload: ContentStatusCommentOrTaskCountsLoadedActionPayload,
): ReadonlyMap<ContentItemIdString, T> => {
  const { counts } = payload;
  if (counts === null) {
    return state;
  }

  const countsWithLoadingStatus: ReadonlyArray<[ContentItemIdString, T]> = counts.map(
    ([id, value]) => [
      id,
      {
        value,
        loadingStatus: LoadingStatus.Loaded,
      } as T,
    ],
  );

  return Collection.addMany(state, countsWithLoadingStatus);
};

export const commentsOrTasksStartedReducer = <T extends ValueWithLoadingStatus<number>>(
  state: ReadonlyMap<ContentItemIdString, T>,
  payload: ContentStatusCommentOrTaskCountsStartedActionPayload,
): ReadonlyMap<ContentItemIdString, T> => {
  const { loadedContentItemIds } = payload;
  if (!loadedContentItemIds.length || !state.size) {
    return state;
  }

  const newCounts = new Map(state);
  loadedContentItemIds.forEach((contentItemId) => {
    const value = state.get(contentItemId);
    if (value) {
      newCounts.set(contentItemId, {
        ...value,
        loadingStatus: LoadingStatus.Loading,
      });
    }
  });

  return newCounts;
};

const initialState: ReadonlyMap<ContentItemIdString, ContentStatusCommentCount> = new Map();

export function byId(
  state: ReadonlyMap<ContentItemIdString, ContentStatusCommentCount> = initialState,
  action: Action,
): ReadonlyMap<ContentItemIdString, ContentStatusCommentCount> {
  switch (action.type) {
    case ContentStatusCommentCounts_Load_Success: {
      return commentsOrTasksLoadedReducer(state, action.payload);
    }

    case ContentStatusCommentCounts_Load_Started: {
      return commentsOrTasksStartedReducer(state, action.payload);
    }

    default:
      return state;
  }
}
