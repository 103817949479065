import React, { useCallback } from 'react';
import { RTECommandSource } from '../../../../_shared/models/events/RTECommandEventData.type.ts';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply, PluginState, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import {
  doesSelectionContainText,
  getFullBlockTypesAtSelection,
  getMetadataAtSelection,
} from '../../utils/editorSelectionUtils.ts';
import { areAllTextBlocksAllowed } from '../apiLimitations/api/editorLimitationUtils.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import { InlineToolbarPlugin } from '../toolbars/InlineToolbarPlugin.tsx';
import { EditorToolbarDivider } from '../toolbars/components/buttons/EditorToolbarDivider.tsx';
import { EditorClearFormattingApi } from './api/EditorClearFormattingApi.type.ts';
import { editorClearFormattingApi } from './api/editorClearFormattingApi.ts';
import { ClearFormattingButton } from './components/ClearFormattingButton.tsx';

export type ClearFormattingPlugin = DraftJsEditorPlugin<
  None,
  None,
  EditorClearFormattingApi,
  None,
  [InlineToolbarPlugin, KeyboardShortcutsPlugin<RichTextInputCommand>]
>;

type ClearFormattingToolbarButtonProps = Pick<
  PluginState<ClearFormattingPlugin>,
  'editorState' | 'handleCommand' | 'getApi'
>;

const ClearFormattingToolbarButton: React.FC<ClearFormattingToolbarButtonProps> = ({
  editorState,
  getApi,
  handleCommand,
}) => {
  const clearFormatting = useCallback((): void => {
    handleCommand(RichTextInputCommand.ClearFormatting, RTECommandSource.InlineToolbar);
  }, [handleCommand]);

  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(content, selection);
  const limitations = getApi().getLimitations();
  const textChangeDisabled = !areAllTextBlocksAllowed(fullBlockTypesAtSelection, limitations);

  return (
    <ClearFormattingButton
      disabled={textChangeDisabled}
      onClick={clearFormatting}
      tooltipText={
        textChangeDisabled ? 'Text and empty lines are not allowed here' : 'Clear formatting'
      }
    />
  );
};

ClearFormattingToolbarButton.displayName = 'ClearFormattingToolbarButton';

export const ClearFormattingPlugin: PluginComponent<ClearFormattingPlugin> = (props) => {
  const { disabled } = props;

  const renderInlineToolbarButtons: Decorator<Render<ClearFormattingPlugin>> = useCallback(
    (baseRender) => (state) => {
      const { editorState } = state;
      const content = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const metadataAtSelection = getMetadataAtSelection(content, selection);
      const selectionContainsText = doesSelectionContainText(selection, metadataAtSelection);

      const allowClearFormatting = state.canUpdateContent() && selectionContainsText && !disabled;
      const buttonsBefore = baseRender(state);

      if (!allowClearFormatting) {
        return buttonsBefore;
      }

      return (
        <>
          {buttonsBefore}
          {buttonsBefore && <EditorToolbarDivider />}
          <ClearFormattingToolbarButton
            editorState={state.editorState}
            getApi={state.getApi}
            handleCommand={state.handleCommand}
          />
        </>
      );
    },
    [disabled],
  );

  const apply: Apply<ClearFormattingPlugin> = useCallback(
    (state) => {
      state.renderInlineToolbarButtons.decorate(renderInlineToolbarButtons);

      const executeCommand: Decorator<ExecuteCommand<RichTextInputCommand>> =
        (baseExecuteCommand) => (command, isShiftPressed) => {
          switch (command) {
            case RichTextInputCommand.ClearFormatting: {
              state.executeChange((editorState) =>
                state.getApi().clearSelectionFormatting(editorState),
              );
              return true;
            }

            default:
              return baseExecuteCommand(command, isShiftPressed);
          }
        };

      state.executeCommand.decorate(executeCommand);

      return {};
    },
    [renderInlineToolbarButtons],
  );

  const { getApiMethods } = useEditorApi<ClearFormattingPlugin>(editorClearFormattingApi);

  return useEditorWithPlugin(props, { apply, getApiMethods });
};
