import React, { ComponentProps } from 'react';
import { DataTableRow } from '../../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { WorkflowStatusTagForVariant } from '../../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { VariantCompletionStatus } from '../../../../../_shared/utils/contentItemVariantUtils.ts';
import { formatUserName } from '../../../../../_shared/utils/usersUtils.ts';
import { TableCellDueDate } from '../../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import { NameWithLanguage } from '../../shared/components/NameWithLanguage.tsx';
import { YourTaskWithPathToItem } from '../types/YourTaskWithPathToItem.type.ts';

type TasksAssignedToYouWidgetDetailRowProps = {
  readonly task: YourTaskWithPathToItem;
  readonly variantCompletionStatus: VariantCompletionStatus;
  readonly onTaskClick: () => void;
  readonly rowFocusLinkAriaLabel: string;
};

export const TasksAssignedToYouWidgetDetailRow: React.FC<
  TasksAssignedToYouWidgetDetailRowProps
> = ({ task, variantCompletionStatus, onTaskClick, rowFocusLinkAriaLabel }) => {
  const linkProps = {
    linkPath: task.contentItemPath,
    onClick: onTaskClick,
  } satisfies ComponentProps<typeof LinkDataTableCell>;

  const taskAuthorName = formatUserName(task.taskAuthor);

  return (
    <DataTableRow id={task.id} key={task.id} dataUiObjectName={task.description}>
      <LinkDataTableCell focusableRowLinkAriaLabel={rowFocusLinkAriaLabel} {...linkProps}>
        {task.description}
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>
        <TableCellDueDate dueDate={task.taskDueDate} />
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps} title={taskAuthorName}>
        {taskAuthorName}
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>
        <NameWithLanguage
          name={task.contentItemName}
          language={task.contentItemVariantName}
          variantCompletionStatus={variantCompletionStatus}
        />
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>{task.contentType}</LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>
        <WorkflowStatusTagForVariant
          publishingState={task.publishingState}
          workflowStatus={task.workflowStatus}
          scheduledToPublishAt={task.contentItemScheduledToPublishAt}
          scheduledToUnpublishAt={task.contentItemScheduledToUnpublishAt}
        />
      </LinkDataTableCell>
    </DataTableRow>
  );
};
