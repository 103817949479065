import { ElementType } from '../../contentInventory/content/models/ContentItemElementType.ts';
import {
  DateTimeInvalidValue,
  LinkedItemsInvalidValue,
  NumberIsNotValid,
  NumberIsTooLargeError,
  TaxonomyIsNotValid,
  TextTooLongToSaveError,
} from '../constants/errorMessages.ts';
import { ElementError } from './elementErrorCheckers/types/Errors.ts';

export const mapElementErrorToMessage = (
  elementError: ElementError,
  elementType: ElementType,
): string => {
  switch (elementError) {
    case ElementError.InvalidDateTime:
      return DateTimeInvalidValue;
    case ElementError.InvalidLinkedItems:
      return LinkedItemsInvalidValue(elementType);
    case ElementError.InvalidNumber:
      return NumberIsNotValid;
    case ElementError.InvalidTaxonomy:
      return TaxonomyIsNotValid;
    case ElementError.NumberTooLarge:
      return NumberIsTooLargeError;
    case ElementError.TextTooLong:
      return TextTooLongToSaveError;
  }
};
