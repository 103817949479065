import { Box } from '@kontent-ai/component-library/Box';
import { useEventListener } from '@kontent-ai/hooks';
import { useHover } from '@react-aria/interactions';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { WebSpotlightPreviewResolutionChangeOrigin } from '../../../../../../_shared/models/TrackUserEventData.ts';
import { clamp } from '../../../../../../_shared/utils/numberUtils.ts';
import { WebSpotlightPreviewDiagonalResizeButton } from '../../../../../webSpotlight/components/preview/ResizeElements/WebSpotlightPreviewDiagonalResizeButton.tsx';
import { WebSpotlightPreviewHorizontalResizeBar } from '../../../../../webSpotlight/components/preview/ResizeElements/WebSpotlightPreviewHorizontalResizeBar.tsx';
import { WebSpotlightPreviewVerticalResizeBar } from '../../../../../webSpotlight/components/preview/ResizeElements/WebSpotlightPreviewVerticalResizeBar.tsx';
import {
  WebSpotlightPreviewContainerOffset,
  WebSpotlightPreviewMaxResolutionPx,
  WebSpotlightPreviewMinResolutionPx,
} from '../../../../../webSpotlight/constants/uiConstants.ts';
import { WebSpotlightPreviewResizingDirection } from '../../../../../webSpotlight/models/WebSpotlightPreviewResizingDirection.tsx';
import { WebSpotlightPreviewResolutionType } from '../../../../../webSpotlight/models/webSpotlightPreviewResolutionType.ts';
import { useWebSpotlightInItemEditing } from '../../context/WebSpotlightInItemEditingContext.tsx';

const resizeThrottleIntervalMs = 100;

type Props = {
  readonly children: ReactNode;
};

export const WebSpotlightPreviewResizeHandlesInItemEditing = ({ children }: Props) => {
  const dispatch = useDispatch();

  const {
    previewIFrameResolution: { width, height, scale },
    previewIFrameResolutionType,
    previewPaneRef,
    setPreviewIFrameResolution,
    setPreviewIFrameResolutionType,
  } = useWebSpotlightInItemEditing();

  const { isHovered: isDiagonalResizeButtonHovered, hoverProps: diagonalResizeButtonHoverProps } =
    useHover({});

  const containerWidth = useMemo(
    () =>
      previewIFrameResolutionType !== WebSpotlightPreviewResolutionType.FitScreen
        ? width * scale
        : '100%',
    [previewIFrameResolutionType, scale, width],
  );

  const containerHeight = useMemo(
    () =>
      previewIFrameResolutionType !== WebSpotlightPreviewResolutionType.FitScreen
        ? height * scale
        : '100%',
    [previewIFrameResolutionType, scale, height],
  );

  const [resizingDirection, setResizingDirection] = useState<WebSpotlightPreviewResizingDirection>(
    WebSpotlightPreviewResizingDirection.None,
  );
  const [startX, setStartX] = useState<number>(0);
  const [startY, setStartY] = useState<number>(0);

  const startResizing = useCallback(
    (event: React.MouseEvent<HTMLElement>, direction: WebSpotlightPreviewResizingDirection) => {
      setPreviewIFrameResolutionType(WebSpotlightPreviewResolutionType.Responsive);
      setResizingDirection(direction);
      setStartX(event.clientX);
      setStartY(event.clientY);
    },
    [setPreviewIFrameResolutionType],
  );

  const stopResizing = useCallback(() => {
    if (resizingDirection === WebSpotlightPreviewResizingDirection.None) {
      return;
    }

    setResizingDirection(WebSpotlightPreviewResizingDirection.None);
    setStartX(0);
    setStartY(0);
    dispatch(
      trackUserEventWithData(TrackedEvent.WebSpotlightPreviewResolutionChanged, {
        origin: WebSpotlightPreviewResolutionChangeOrigin.ResizeHandle,
      }),
    );
  }, [resizingDirection]);

  const moveMouse = useCallback(
    (event: MouseEvent) => {
      if (resizingDirection === WebSpotlightPreviewResizingDirection.None) {
        return;
      }

      let newWidth = width;
      let newHeight = height;
      if (
        [
          WebSpotlightPreviewResizingDirection.Horizontal,
          WebSpotlightPreviewResizingDirection.Diagonal,
        ].includes(resizingDirection)
      ) {
        newWidth = width + (event.clientX - startX) / scale;
        setStartX(event.clientX);
      }

      if (
        [
          WebSpotlightPreviewResizingDirection.Vertical,
          WebSpotlightPreviewResizingDirection.Diagonal,
        ].includes(resizingDirection)
      ) {
        newHeight = height + (event.clientY - startY) / scale;
        setStartY(event.clientY);
      }

      if ((newWidth !== width || newHeight !== height) && previewPaneRef?.current) {
        const { width: maxWidth, height: maxHeight } =
          previewPaneRef.current.getBoundingClientRect();
        const offset = WebSpotlightPreviewContainerOffset / scale;

        newWidth = clamp(
          newWidth,
          WebSpotlightPreviewMinResolutionPx,
          Math.min(WebSpotlightPreviewMaxResolutionPx, maxWidth / scale - offset),
        );
        newHeight = clamp(
          newHeight,
          WebSpotlightPreviewMinResolutionPx,
          Math.min(WebSpotlightPreviewMaxResolutionPx, maxHeight / scale - offset),
        );
        setPreviewIFrameResolution({
          width: Math.floor(newWidth),
          height: Math.floor(newHeight),
          scale,
        });
      }
    },
    [
      resizingDirection,
      height,
      width,
      scale,
      setPreviewIFrameResolution,
      previewPaneRef,
      startX,
      startY,
    ],
  );

  const throttledMouseMove = useThrottledCallback(moveMouse, resizeThrottleIntervalMs);

  useEventListener('mouseup', stopResizing, window);
  useEventListener('mousemove', throttledMouseMove, window);

  const isDiagonalButtonHovered =
    resizingDirection !== WebSpotlightPreviewResizingDirection.None
      ? resizingDirection === WebSpotlightPreviewResizingDirection.Diagonal
      : isDiagonalResizeButtonHovered;

  return (
    <Box
      position="relative"
      width={containerWidth}
      height={containerHeight}
      marginX="auto"
      marginY={0}
    >
      {children}
      <WebSpotlightPreviewHorizontalResizeBar
        isBorderHovered={isDiagonalResizeButtonHovered}
        resizingDirection={resizingDirection}
        onResizeStart={(event) =>
          startResizing(event, WebSpotlightPreviewResizingDirection.Horizontal)
        }
      />
      <WebSpotlightPreviewVerticalResizeBar
        isBorderHovered={isDiagonalResizeButtonHovered}
        resizingDirection={resizingDirection}
        onResizeStart={(event) =>
          startResizing(event, WebSpotlightPreviewResizingDirection.Vertical)
        }
      />
      <WebSpotlightPreviewDiagonalResizeButton
        isHovered={isDiagonalButtonHovered}
        onResizeStart={(event) =>
          startResizing(event, WebSpotlightPreviewResizingDirection.Diagonal)
        }
        {...diagonalResizeButtonHoverProps}
      />
    </Box>
  );
};
