import { forwardRef, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { ModalDialog } from '../../../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { modalDismissed } from '../../../../../../../_shared/actions/sharedActions.ts';
import { IAnimatedModalDialogProps } from '../../../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { ShortcutSymbols } from '../../../../../../../_shared/constants/shortcutSymbols.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { createFormValidationResolver } from '../../../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { getModalDialogActionOrigin } from '../../../../../selectors/getModalDialogActionOrigin.ts';
import { abandonAssignmentSectionChange } from '../../../actions/contentItemEditingActions.ts';
import { updateDueDate } from '../../../actions/thunkContentItemEditingActions.ts';
import { DueDateFormBase } from '../../../components/details/DueDate/DueDateFormBase.tsx';
import { AssignmentSections } from '../../../constants/AssignmentSections.ts';
import { IDueDateFormShape } from '../../../models/IDueDateFormShape.type.ts';
import {
  isOtherAssignmentSectionSubmitting as isOtherAssignmentSectionSubmittingSelector,
  isSectionSubmitting as isSectionSubmittingSelector,
} from '../../../selectors/isSectionSubmitting.ts';
import { dueDateFormValidationConfig } from '../../../validation/dueDateFormValidation.ts';

const DueDateFormDatePickerId = 'due-date-form-datepicker';

const getSubmitButtonText = (
  initialNote: string | null | undefined,
  withIngForm: boolean,
): string => {
  if (!initialNote) {
    return withIngForm ? 'Adding...' : 'Add';
  }

  return withIngForm ? 'Changing...' : 'Change';
};

export const DueDateFormDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  ({ isOpen }, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const actionOrigin = useSelector(getModalDialogActionOrigin);

    const initialDueDate = useSelector(
      (s) => s.contentApp.editedContentItemVariant?.assignment.due ?? '',
    );

    const defaultValues: IDueDateFormShape = {
      date: {
        value: initialDueDate,
        isValid: true,
      },
    };

    const isOtherAssignmentSectionSubmitting = useSelector((s) =>
      isOtherAssignmentSectionSubmittingSelector(s, AssignmentSections.DueDate),
    );
    const isSectionSubmitting = useSelector((s) =>
      isSectionSubmittingSelector(s, AssignmentSections.DueDate),
    );

    const formProps = useForm<IDueDateFormShape>({
      defaultValues,
      resolver: createFormValidationResolver(dueDateFormValidationConfig, {}),
    });

    const formRef = useRef<HTMLFormElement>(null);
    const { handleSubmit } = formProps;

    const submitForm = handleSubmit((values) =>
      dispatch(updateDueDate(history, values.date.value, actionOrigin)),
    );

    const resetForm = (): void => {
      abandonAssignmentSectionChange(AssignmentSections.DueDate);
      dispatch(modalDismissed());
    };

    return (
      <ModalDialog
        autoFocusRef={formRef}
        headline={initialDueDate ? 'Change due date' : 'Set due date'}
        isDismissable
        isOpen={isOpen}
        onClose={resetForm}
        ref={ref}
        shouldCloseOnInteractOutside={() => false}
        primaryAction={{
          disabled: isSectionSubmitting || isOtherAssignmentSectionSubmitting,
          onClick: submitForm,
          text: getSubmitButtonText(initialDueDate, isSectionSubmitting),
          tooltipText: getSubmitButtonText(initialDueDate, false),
          type: 'button',
          tooltipShortcuts: ShortcutSymbols.Enter,
        }}
        cancelAction={{
          disabled: isSectionSubmitting,
          tooltipShortcuts: ShortcutSymbols.Escape,
        }}
        {...getDataUiElementAttribute(DataUiElement.ContentItemDueDateDialog)}
      >
        <DueDateFormBase
          ref={formRef}
          datePickerId={DueDateFormDatePickerId}
          formProps={formProps}
          isSectionSubmitting={isSectionSubmitting}
          onReset={resetForm}
          onSubmit={submitForm}
        />
      </ModalDialog>
    );
  },
);

DueDateFormDialog.displayName = 'DueDateFormDialog';
