import { Action } from '../../../@types/Action.type.ts';
import { YourTasks_Init_Finished } from '../widgets/tasksAssignedToYou/constants/yourTasksActionTypes.ts';

export const hasMore = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case YourTasks_Init_Finished:
      return action.payload.hasMore;

    default:
      return state;
  }
};
