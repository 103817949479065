import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { ISelectSection } from '@kontent-ai/component-library/Selects';
import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import React, { ComponentProps } from 'react';
import { FormGroup } from '../../../../_shared/components/input/FormGroup.tsx';
import { FormSection } from '../../../../_shared/components/input/FormSection.tsx';
import {
  DataUiCollection,
  DataUiInput,
  getDataUiCollectionAttribute,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { renderWorkflowStepColor } from '../../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import { ITriggers } from '../../models/IWebhookFormShape.type.ts';
import { IWorkflowStepTrigger } from '../../models/IWorkflowStepTrigger.type.ts';
import {
  ItemContentTriggers,
  ManagementApiContentTriggers,
  PreviewItemContentTriggers,
  PreviewTaxonomyContentTriggers,
  TaxonomyContentTriggers,
} from '../../utils/webhookSettingsTriggers.ts';

type Props = {
  readonly workflowStepsInWorkflows: ReadonlyArray<ISelectSection<IWorkflowStepTrigger>>;
  readonly onChange: (newTriggers: ITriggers) => void;
  readonly value: ITriggers;
  readonly disabled?: boolean;
};

const renderWorkflowStepSelectedOption = (
  _id: string,
  workflowStep: IWorkflowStepTrigger,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) => {
  return (
    <Tag
      {...defaultTagProps}
      background={renderWorkflowStepColor(workflowStep.color)}
      customTooltipText={workflowStep.tooltip}
    />
  );
};

export const Triggers: React.FC<Props> = ({
  workflowStepsInWorkflows,
  onChange,
  value,
  disabled,
}) => {
  const changeValue =
    <Trigger extends keyof ITriggers>(triggerName: Trigger) =>
    (triggerValue: ITriggers[Trigger]) => {
      onChange({
        ...value,
        [triggerName]: triggerValue,
      });
    };

  return (
    <>
      <FormSection title="Management API triggers">
        <FormGroup>
          <MultiSelect
            inputDataAttributes={getDataUiInputAttribute(DataUiInput.WebhookWorkflowStepTriggers)}
            inputState={disabled ? InputState.Disabled : InputState.Default}
            items={workflowStepsInWorkflows}
            label="Transition to specific workflow step to watch"
            onSelectionChange={changeValue('itemWorkflowStepIds')}
            placeholder="Select workflow steps"
            renderSelectedOption={renderWorkflowStepSelectedOption}
            selectedItemIds={value.itemWorkflowStepIds}
            verticalMenuDataAttributes={getDataUiCollectionAttribute(
              DataUiCollection.WorkflowSteps,
            )}
          />
        </FormGroup>
        <FormGroup>
          <MultiSelect
            inputDataAttributes={getDataUiInputAttribute(DataUiInput.WebhookManagementItemTriggers)}
            inputState={disabled ? InputState.Disabled : InputState.Default}
            items={ManagementApiContentTriggers}
            label="Content item events to watch"
            onSelectionChange={changeValue('managementApiItemContentTriggerIds')}
            placeholder="Select events"
            selectedItemIds={value.managementApiItemContentTriggerIds}
            verticalMenuDataAttributes={getDataUiCollectionAttribute(
              DataUiCollection.WebhookTriggers,
            )}
          />
        </FormGroup>
      </FormSection>
      <FormSection title="Delivery API triggers">
        <FormGroup>
          <MultiSelect
            inputDataAttributes={getDataUiInputAttribute(DataUiInput.WebhookDeliveryItemTriggers)}
            inputState={disabled ? InputState.Disabled : InputState.Default}
            items={ItemContentTriggers}
            label="Content item events to watch"
            onSelectionChange={changeValue('deliveryApiItemContentTriggerIds')}
            placeholder="Select events"
            selectedItemIds={value.deliveryApiItemContentTriggerIds}
            verticalMenuDataAttributes={getDataUiCollectionAttribute(
              DataUiCollection.WebhookTriggers,
            )}
          />
        </FormGroup>
        <FormGroup>
          <MultiSelect
            inputDataAttributes={getDataUiInputAttribute(DataUiInput.WebhookTaxonomyTriggers)}
            inputState={disabled ? InputState.Disabled : InputState.Default}
            items={TaxonomyContentTriggers}
            label="Taxonomy events to watch"
            onSelectionChange={changeValue('deliveryApiTaxonomyContentTriggerIds')}
            placeholder="Select events"
            selectedItemIds={value.deliveryApiTaxonomyContentTriggerIds}
            verticalMenuDataAttributes={getDataUiCollectionAttribute(
              DataUiCollection.WebhookTriggers,
            )}
          />
        </FormGroup>
      </FormSection>
      <FormSection title="Delivery Preview API triggers">
        <FormGroup>
          <MultiSelect
            inputDataAttributes={getDataUiInputAttribute(
              DataUiInput.WebhookPreviewDeliveryItemTriggers,
            )}
            inputState={disabled ? InputState.Disabled : InputState.Default}
            items={PreviewItemContentTriggers}
            label="Preview content item events to watch"
            onSelectionChange={changeValue('previewDeliveryApiItemContentTriggerIds')}
            placeholder="Select events"
            selectedItemIds={value.previewDeliveryApiItemContentTriggerIds}
            verticalMenuDataAttributes={getDataUiCollectionAttribute(
              DataUiCollection.WebhookTriggers,
            )}
          />
        </FormGroup>
        <FormGroup>
          <MultiSelect
            inputDataAttributes={getDataUiInputAttribute(
              DataUiInput.WebhookPreviewTaxonomyTriggers,
            )}
            inputState={disabled ? InputState.Disabled : InputState.Default}
            items={PreviewTaxonomyContentTriggers}
            label="Preview taxonomy events to watch"
            onSelectionChange={changeValue('previewDeliveryApiTaxonomyContentTriggerIds')}
            placeholder="Select events"
            selectedItemIds={value.previewDeliveryApiTaxonomyContentTriggerIds}
            verticalMenuDataAttributes={getDataUiCollectionAttribute(
              DataUiCollection.WebhookTriggers,
            )}
          />
        </FormGroup>
      </FormSection>
    </>
  );
};

Triggers.displayName = 'Triggers';
