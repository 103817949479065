import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { Capability, getUserCapability } from '../../../../_shared/utils/permissions/capability.ts';
import { createMissionControlRootLink } from '../../../../_shared/utils/routing/environmentRoutingUtils.ts';
import { createEnvironmentSettingsLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import {
  getCurrentProjectContainer,
  getProjectInfo,
} from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  EnvironmentTile as EnvironmentTileComponent,
  EnvironmentTileOwnProps,
} from '../components/EnvironmentTile.tsx';
import { ProductionEnvironmentDefaultName } from '../constants/uiConstants.ts';
import { IEnvironmentViewModel } from '../selectors/getEnvironmentsForListing.ts';

type EnvironmentTileProps = EnvironmentTileOwnProps;

const isAnyDialogShownSelector = (state: IStore) => {
  const { createDialog, deleteDialog, swapDialog } = state.environmentsApp.showDialogs;
  return createDialog || deleteDialog || swapDialog;
};

const nonProductionEnvironmentHasDefaultName = (environment: IEnvironmentViewModel) => {
  return (
    environment.environmentName === ProductionEnvironmentDefaultName &&
    !environment.isMasterEnvironment
  );
};

export const EnvironmentTile: React.FC<EnvironmentTileProps> = ({
  environment,
  onCreateEnvironment,
  onDeleteEnvironment,
  onSwapEnvironment,
  planLimitReached,
  subscriptionId,
}) => {
  const history = useHistory();

  const hasManageEnvironmentCapability = useSelector((s) => {
    const projectInfo = getProjectInfo(s, environment.environmentId);
    return getUserCapability(projectInfo).can(Capability.ManageEnvironments);
  });
  const hasManageProductionEnvironmentCapability = useSelector((s) => {
    const currentProject = getCurrentProjectContainer(s);
    const masterProjectInfo = getProjectInfo(s, currentProject.masterEnvironmentId);
    return getUserCapability(masterProjectInfo).can(Capability.ManageEnvironments);
  });
  const isAnyDialogShown = useSelector((s) => isAnyDialogShownSelector(s));

  const [showRenameAdvice, setShowRenameAdvice] = useState(
    nonProductionEnvironmentHasDefaultName(environment),
  );

  useEffect(() => {
    setShowRenameAdvice(nonProductionEnvironmentHasDefaultName(environment));
  }, [environment]);

  const setCurrentEnvironment = () => {
    const link = createMissionControlRootLink({ projectId: environment.environmentId });
    history.push(link);
  };

  const goToEnvironmentSettings = () => {
    const link = createEnvironmentSettingsLink({
      projectId: environment.environmentId,
      subscriptionId,
    });
    history.push(link);
  };

  return (
    <EnvironmentTileComponent
      environment={environment}
      hasManageEnvironmentCapability={hasManageEnvironmentCapability}
      hasManageProductionEnvironmentCapability={hasManageProductionEnvironmentCapability}
      onCreateEnvironment={onCreateEnvironment}
      onDeleteEnvironment={onDeleteEnvironment}
      onDismissRenameAdvice={() => setShowRenameAdvice(false)}
      onGoToEnvironment={setCurrentEnvironment}
      onGoToEnvironmentSettings={goToEnvironmentSettings}
      onSwapEnvironment={onSwapEnvironment}
      planLimitReached={planLimitReached}
      showRenameAdvice={showRenameAdvice && !isAnyDialogShown}
    />
  );
};

EnvironmentTile.displayName = 'EnvironmentTile';
