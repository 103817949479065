import { EditorProps } from 'draft-js';
import { useCallback } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import { Apply, PluginState, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { ForceSingleUnstyledBlockPlugin } from '../behavior/ForceSingleUnstyledBlockPlugin.tsx';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';

export type IgnoreCtrlEnterPlugin = DraftJsEditorPlugin<
  None,
  None,
  None,
  None,
  [ForceSingleUnstyledBlockPlugin]
>;

const EditorWithReturnHandling: DecoratedEditor<IgnoreCtrlEnterPlugin> = ({
  baseRender,
  state,
}) => {
  const {
    editorProps: { handleReturn: parentHandleReturn },
  } = state;

  const handleReturn: Required<EditorProps>['handleReturn'] = useCallback(
    (event, editorState) => {
      if (event.ctrlKey || event.metaKey) {
        //  Handled by parent within Ctrl/Command + Enter
        event.preventDefault();
        return 'handled';
      }

      return parentHandleReturn?.(event, editorState) ?? 'not-handled';
    },
    [parentHandleReturn],
  );

  const stateWithReturnHandling: PluginState<IgnoreCtrlEnterPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      handleReturn,
    },
  };

  return baseRender(stateWithReturnHandling);
};

EditorWithReturnHandling.displayName = 'EditorWithReturnHandling';

export const IgnoreCtrlEnterPlugin: PluginComponent<IgnoreCtrlEnterPlugin> = (props) => {
  const render: Decorator<Render<IgnoreCtrlEnterPlugin>> = useCallback(
    (baseRender) => (state) => <EditorWithReturnHandling baseRender={baseRender} state={state} />,
    [],
  );

  const apply: Apply<IgnoreCtrlEnterPlugin> = useCallback(
    (state) => {
      state.render.decorate(render);
      return {};
    },
    [render],
  );

  return useEditorWithPlugin(props, { apply });
};
