import { notUndefined } from '@kontent-ai/utils';
import { DraftBlockRenderConfig } from 'draft-js';
import Immutable from 'immutable';
import { useCallback, useMemo, useRef } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import { Apply, PluginState, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import {
  BaseBlockType,
  BlockType,
  BlockTypesAllowedInTableCell,
  getNestedBlockType,
} from '../../utils/blocks/blockType.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { BaseBlockRenderMap, BlockRenderMap } from '../draftJs/utils/draftJsEditorUtils.ts';
import { TableCellWrapper } from './components/TableCellWrapper.tsx';

export type DisplayTablesPlugin = DraftJsEditorPlugin;

const EditorWithTables: DecoratedEditor<DisplayTablesPlugin> = ({ baseRender, state }) => {
  const {
    editorProps: { blockRenderMap: baseBlockRenderMap },
  } = state;

  const blockRenderMapRef = useRef<BlockRenderMap>(baseBlockRenderMap);

  const TableWrapper = useMemo(
    () => <TableCellWrapper getRenderMap={() => blockRenderMapRef.current} />,
    [],
  );

  blockRenderMapRef.current = useMemo(() => {
    const tableContentRenderMap = Immutable.Map<BlockType, DraftBlockRenderConfig>(
      BlockTypesAllowedInTableCell.map((baseBlockType) => {
        const blockType = getNestedBlockType([BlockType.TableCell], baseBlockType);
        const value = baseBlockRenderMap.get(baseBlockType);

        return (
          value && [
            blockType,
            {
              ...value,
              wrapper: TableWrapper,
            },
          ]
        );
      }).filter(notUndefined),
    );

    const tableCellRenderMap: BaseBlockRenderMap = Immutable.Map<
      BaseBlockType,
      DraftBlockRenderConfig
    >({
      [BaseBlockType.TableCell]: {
        element: 'div',
        wrapper: TableWrapper,
      },
    });

    return baseBlockRenderMap.merge(tableCellRenderMap).merge(tableContentRenderMap);
  }, [baseBlockRenderMap, TableWrapper]);

  const blockRenderMap = blockRenderMapRef.current;

  const stateWithTables: PluginState<DisplayTablesPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      blockRenderMap,
    },
  };

  return baseRender(stateWithTables);
};

EditorWithTables.displayName = 'EditorWithTables';

const render: Decorator<Render<DisplayTablesPlugin>> = (baseRender) => (state) => (
  <EditorWithTables baseRender={baseRender} state={state} />
);

export const DisplayTablesPlugin: PluginComponent<DisplayTablesPlugin> = (props) => {
  const apply: Apply<DisplayTablesPlugin> = useCallback((state) => {
    state.render.decorate(render);

    return {};
  }, []);

  return useEditorWithPlugin(props, { apply });
};
