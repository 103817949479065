import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { EditorEntityApi } from './api/EditorEntityApi.type.ts';
import { editorEntityApi } from './api/editorEntityApi.ts';

export type EntityApiPlugin = DraftJsEditorPlugin<None, None, EditorEntityApi>;

export const EntityApiPlugin: PluginComponent<EntityApiPlugin> = (props) => {
  const { getApiMethods } = useEditorApi<EntityApiPlugin>(editorEntityApi);

  return useEditorWithPlugin<EntityApiPlugin>(props, { getApiMethods });
};
