import { Action } from '../../../@types/Action.type.ts';
import {
  LocalizedRouteEntered,
  LocalizedRouteLeft,
} from '../../../applications/contentInventory/shared/constants/contentInventoryActionTypes.ts';
import {
  MissionControl_LocalizedRoute_Entered,
  MissionControl_LocalizedRoute_Left,
} from '../../../applications/missionControl/constants/missionControlActionTypes.ts';
import {
  WebSpotlight_LocalizedRoute_Entered,
  WebSpotlight_LocalizedRoute_Left,
} from '../../../applications/webSpotlight/constants/webSpotlightActionTypes.ts';
import {
  Shared_CurrentProjectId_Updated,
  Shared_TryEnsuringSelectedLanguageId_Ensured,
  Shared_TryEnsuringSelectedLanguageId_NoLanguageAvailable,
  Shared_TryEnsuringSelectedLanguageId_Started,
} from '../../constants/sharedActionTypes.ts';

export enum EnsuringStatus {
  NotEnsured = 'NotEnsured',
  EnsuringInProgress = 'EnsuringInProgress',
  Ensured = 'Ensured',
  // user either has no view-content permission or only has it in deactivated language
  EnsuredButEmpty = 'EnsuredButEmpty',
}

export interface ISelectedLanguageState {
  readonly id: Uuid | null;
  readonly ensuringStatus: EnsuringStatus;
}

const initialState: ISelectedLanguageState = {
  ensuringStatus: EnsuringStatus.NotEnsured,
  id: null,
};

const ensuringInProgressState: ISelectedLanguageState = {
  ensuringStatus: EnsuringStatus.EnsuringInProgress,
  id: null,
};

const ensuredButEmptyState: ISelectedLanguageState = {
  ensuringStatus: EnsuringStatus.EnsuredButEmpty,
  id: null,
};

// We need to differentiate between not initialized and empty states. Undefined vs null seemed too "hidden" and error prone.
// Another options of a union type with the selectedLanguageId is not viable because string enum
// is technically a string and Uuid is a string as well, so it would cause bugs...
export function selectedLanguage(state = initialState, action: Action): ISelectedLanguageState {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Shared_TryEnsuringSelectedLanguageId_Started:
      return ensuringInProgressState;

    case Shared_TryEnsuringSelectedLanguageId_NoLanguageAvailable:
      return ensuredButEmptyState;

    case Shared_TryEnsuringSelectedLanguageId_Ensured:
    case MissionControl_LocalizedRoute_Entered:
    case LocalizedRouteEntered:
    case WebSpotlight_LocalizedRoute_Entered:
      return {
        ensuringStatus: EnsuringStatus.Ensured,
        id: action.payload.selectedLanguageId,
      };

    case MissionControl_LocalizedRoute_Left:
    case LocalizedRouteLeft:
    case WebSpotlight_LocalizedRoute_Left:
      // do not reset language, so that the preference is preserved for links generation
      // while user switches from localized to non-localizable routes and links
      return state;

    default:
      return state;
  }
}
