import { assert } from '@kontent-ai/utils';
import { buildAssetsRoutePath } from '../../../applications/contentInventory/assets/utils/assetsPathUtils.ts';
import {
  AssetRoute,
  AssetRouteParams,
  ContentItemEditorRouteParams,
  ContentItemEditorRoutes,
  ContentItemPreviewRoute,
  ContentItemRevisionViewerRoute,
  ContentItemRevisionViewerRouteParams,
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemsRoute,
  ContentItemsRouteParams,
  ContentTypeSnippetsRoute,
  ContentTypesRoute,
  CreateNewContentTypeRoute,
  CreateNewContentTypeSnippetsRoute,
  CreateNewTaxonomyGroupRoute,
  CustomAppConfigurationCreatingRoute,
  CustomAppConfigurationEditingRoute,
  CustomAppConfigurationEditingRouteParams,
  CustomAppConfigurationRouteParams,
  CustomAppDetailRoute,
  CustomAppDetailRouteParams,
  CustomAppRouteParams,
  CustomAppsConfigurationRoute,
  CustomAppsRoute,
  DapiKeyDetailRoute,
  DapiKeyListingRoute,
  EditContentTypeRoute,
  EditContentTypeRouteParams,
  EditContentTypeSnippetRoute,
  EditContentTypeSnippetRouteParams,
  EditTaxonomyGroupRoute,
  EditTaxonomyGroupRouteParams,
  EnvironmentRouteParams,
  EnvironmentSettingsRoute,
  LegacyWebhookMessagesRoute,
  LegacyWebhookMessagesRouteParams,
  LegacyWebhooksRoute,
  MapiKeyDetailRoute,
  MapiKeyListingRoute,
  NewDapiKeyDetailRoute,
  NewMapiKeyDetailRoute,
  NewPersonalMapiKeyDetailRoute,
  PersonalMapiKeyDetailRoute,
  ProjectRouteParams,
  ProjectsInSubscriptionRoute,
  ProjectsRoute,
  RoleCreatorRoute,
  RoleEditorRoute,
  RoleEditorRouteParams,
  RolesRoute,
  SubscriptionEnvironmentCustomAppConfigurationCreatingRoute,
  SubscriptionEnvironmentCustomAppConfigurationEditingRoute,
  SubscriptionEnvironmentCustomAppConfigurationEditingRouteParams,
  SubscriptionEnvironmentCustomAppsConfigurationRoute,
  SubscriptionEnvironmentLegacyWebhookMessagesRoute,
  SubscriptionEnvironmentLegacyWebhookMessagesRouteParams,
  SubscriptionEnvironmentLegacyWebhooksRoute,
  SubscriptionEnvironmentRoleCreatorRoute,
  SubscriptionEnvironmentRoleEditorRoute,
  SubscriptionEnvironmentRoleEditorRouteParams,
  SubscriptionEnvironmentRolesRoute,
  SubscriptionEnvironmentSettingsRoute,
  SubscriptionEnvironmentSettingsRouteParams,
  SubscriptionEnvironmentWebhookMessagesRoute,
  SubscriptionEnvironmentWebhookMessagesRouteParams,
  SubscriptionEnvironmentWebhooksCreatingRoute,
  SubscriptionEnvironmentWebhooksEditingRoute,
  SubscriptionEnvironmentWebhooksEditorRouteParams,
  SubscriptionEnvironmentWebhooksRoute,
  SubscriptionEnvironmentWorkflowCreatingRoute,
  SubscriptionEnvironmentWorkflowEditingRoute,
  SubscriptionEnvironmentWorkflowEditingRouteParams,
  SubscriptionEnvironmentWorkflowsRoute,
  TaxonomyGroupsRoute,
  WebhookEditorRouteParams,
  WebhookMessagesRoute,
  WebhookMessagesRouteParams,
  WebhooksCreatingRoute,
  WebhooksEditingRoute,
  WebhooksRoute,
  WebhooksRouteParams,
  WorkflowCreatingRoute,
  WorkflowEditingRoute,
  WorkflowEditingRouteParams,
  WorkflowsRoute,
  WorkflowsRouteParams,
} from '../../constants/routePaths.ts';
import { VariantIdMacro } from '../../constants/variantIdValues.ts';
import { buildPath, matchPath, parseContentItemIds } from '../routing/routeTransitionUtils.ts';

function getContentItemRevisionViewerGoBackLink(
  routeParams: ContentItemRevisionViewerRouteParams<string>,
): string {
  const contentItemIds = parseContentItemIds(routeParams.contentItemIds);
  return buildPath<ContentItemRouteParams<UuidArray>>(ContentItemRoute, {
    app: routeParams.app,
    projectId: routeParams.projectId,
    variantId: routeParams.variantId,
    spaceId: routeParams.spaceId,
    contentItemIds,
  });
}

function getContentItemEditorGoBackLink(
  routeParams: ContentItemEditorRouteParams<string>,
  originPath: string | null = null,
): string {
  const contentItemIds = parseContentItemIds(routeParams.contentItemIds);
  const previousContentItemIds = routeParams.editedItemId
    ? contentItemIds
    : contentItemIds.slice(0, -1);

  if (previousContentItemIds.length > 0) {
    const previewMatch = matchPath<ContentItemRouteParams<string>>(
      location.pathname,
      ContentItemPreviewRoute,
    );
    const route = previewMatch ? ContentItemPreviewRoute : ContentItemRoute;

    return buildPath<ContentItemRouteParams<UuidArray>>(route, {
      app: routeParams.app,
      projectId: routeParams.projectId,
      variantId: routeParams.variantId,
      spaceId: routeParams.spaceId,
      contentItemIds: previousContentItemIds,
    });
  }

  if (originPath) {
    return originPath.replace(VariantIdMacro, routeParams.variantId);
  }

  return buildPath<ContentItemsRouteParams>(ContentItemsRoute, {
    app: routeParams.app,
    projectId: routeParams.projectId,
    variantId: routeParams.variantId,
    spaceId: routeParams.spaceId,
  });
}

function getCustomAppGoBackLink(routeParams: CustomAppRouteParams): string {
  return buildPath<EnvironmentRouteParams>(CustomAppsRoute, { projectId: routeParams.projectId });
}

function getCustomAppConfigurationGoBackLink(
  routeParams: CustomAppConfigurationRouteParams,
): string {
  return buildPath<EnvironmentRouteParams>(CustomAppsConfigurationRoute, {
    projectId: routeParams.projectId,
  });
}

function getSubscriptionCustomAppGoBackLink(
  routeParams: SubscriptionEnvironmentSettingsRouteParams,
): string {
  return buildPath<SubscriptionEnvironmentSettingsRouteParams>(
    SubscriptionEnvironmentCustomAppsConfigurationRoute,
    { projectId: routeParams.projectId, subscriptionId: routeParams.subscriptionId },
  );
}

function getWebhookGoBackLink(routeParams: WebhooksRouteParams): string {
  return buildPath<EnvironmentRouteParams>(WebhooksRoute, { projectId: routeParams.projectId });
}

function getSubscriptionWebhookGoBackLink(
  routeParams: SubscriptionEnvironmentSettingsRouteParams,
): string {
  return buildPath<SubscriptionEnvironmentSettingsRouteParams>(
    SubscriptionEnvironmentWebhooksRoute,
    { projectId: routeParams.projectId, subscriptionId: routeParams.subscriptionId },
  );
}

function getSubscriptionLegacyWebhookGoBackLink(
  routeParams: SubscriptionEnvironmentSettingsRouteParams,
): string {
  return buildPath<SubscriptionEnvironmentSettingsRouteParams>(
    SubscriptionEnvironmentLegacyWebhooksRoute,
    { projectId: routeParams.projectId, subscriptionId: routeParams.subscriptionId },
  );
}

function getContentTypeEditorGoBackLink(routeParams: EditContentTypeRouteParams): string {
  return buildPath<EnvironmentRouteParams>(ContentTypesRoute, { projectId: routeParams.projectId });
}

function getContentTypeSnippetEditorGoBackLink(
  routeParams: EditContentTypeSnippetRouteParams,
): string {
  return buildPath<EnvironmentRouteParams>(ContentTypeSnippetsRoute, {
    projectId: routeParams.projectId,
  });
}

function getEnvironmentSettingsGoBackLink(routeParams: EnvironmentRouteParams): string {
  return buildPath<EnvironmentRouteParams>(ProjectsRoute, { projectId: routeParams.projectId });
}

function getSubscriptionEnvironmentSettingsGoBackLink(
  routeParams: SubscriptionEnvironmentSettingsRouteParams,
): string {
  const { subscriptionId, projectId } = routeParams;
  return buildPath<SubscriptionEnvironmentSettingsRouteParams>(ProjectsInSubscriptionRoute, {
    projectId,
    subscriptionId,
  });
}

function getWebhookMessageListingBackLink(routeParams: WebhookMessagesRouteParams): string {
  return buildPath<EnvironmentRouteParams>(WebhooksRoute, { projectId: routeParams.projectId });
}

function getLegacyWebhookMessageListingBackLink(
  routeParams: LegacyWebhookMessagesRouteParams,
): string {
  return buildPath<EnvironmentRouteParams>(LegacyWebhooksRoute, {
    projectId: routeParams.projectId,
  });
}

function getSubscriptionWebhookMessageListingBackLink(
  routeParams: SubscriptionEnvironmentSettingsRouteParams,
): string {
  return buildPath<SubscriptionEnvironmentSettingsRouteParams>(
    SubscriptionEnvironmentWebhooksRoute,
    {
      projectId: routeParams.projectId,
      subscriptionId: routeParams.subscriptionId,
    },
  );
}

function getTaxonomyGroupEditorGoBackLink(routeParams: EditTaxonomyGroupRouteParams): string {
  return buildPath<EnvironmentRouteParams>(TaxonomyGroupsRoute, {
    projectId: routeParams.projectId,
  });
}

function getRoleEditorOpenedFromSubscriptionsManagerGoBackLink(
  routeParams: SubscriptionEnvironmentSettingsRouteParams,
): string {
  return buildPath<SubscriptionEnvironmentSettingsRouteParams>(SubscriptionEnvironmentRolesRoute, {
    projectId: routeParams.projectId,
    subscriptionId: routeParams.subscriptionId,
  });
}

const getWorkflowOpenedFromSubscriptionsManagerGoBackLink = (
  routeParams: SubscriptionEnvironmentSettingsRouteParams,
): string =>
  buildPath<SubscriptionEnvironmentSettingsRouteParams>(SubscriptionEnvironmentWorkflowsRoute, {
    projectId: routeParams.projectId,
    subscriptionId: routeParams.subscriptionId,
  });

function getRoleEditorOpenedFromEnvironmentSettingsGoBackLink(
  routeParams: EnvironmentRouteParams,
): string {
  return buildPath<EnvironmentRouteParams>(RolesRoute, { projectId: routeParams.projectId });
}

const getWorkflowOpenedFromEnvironmentSettingsGoBackLink = (
  routeParams: WorkflowsRouteParams,
): string => buildPath<WorkflowsRouteParams>(WorkflowsRoute, { projectId: routeParams.projectId });

const getDapiKeyDetailGoBackLink = (routeParams: ProjectRouteParams): string =>
  buildPath<ProjectRouteParams>(DapiKeyListingRoute, {
    projectContainerId: routeParams.projectContainerId,
  });

const getMapiKeyDetailGoBackLink = (routeParams: ProjectRouteParams): string =>
  buildPath<ProjectRouteParams>(MapiKeyListingRoute, {
    projectContainerId: routeParams.projectContainerId,
  });

export function getGoBackLinkByPath(path: string, originPath: string | null = null): string {
  const matchSubscriptionCustomAppCreator = matchPath<SubscriptionEnvironmentSettingsRouteParams>(
    path,
    SubscriptionEnvironmentCustomAppConfigurationCreatingRoute,
  );
  if (matchSubscriptionCustomAppCreator) {
    return getSubscriptionCustomAppGoBackLink(matchSubscriptionCustomAppCreator);
  }

  const matchCustomAppConfigurationCreator = matchPath<CustomAppConfigurationRouteParams>(
    path,
    CustomAppConfigurationCreatingRoute,
  );
  if (matchCustomAppConfigurationCreator) {
    return getCustomAppConfigurationGoBackLink(matchCustomAppConfigurationCreator);
  }

  const matchSubscriptionCustomAppEditor =
    matchPath<SubscriptionEnvironmentCustomAppConfigurationEditingRouteParams>(
      path,
      SubscriptionEnvironmentCustomAppConfigurationEditingRoute,
    );
  if (matchSubscriptionCustomAppEditor) {
    return getSubscriptionCustomAppGoBackLink(matchSubscriptionCustomAppEditor);
  }

  const matchCustomAppConfigurationEditor = matchPath<CustomAppConfigurationEditingRouteParams>(
    path,
    CustomAppConfigurationEditingRoute,
  );
  if (matchCustomAppConfigurationEditor) {
    return getCustomAppConfigurationGoBackLink(matchCustomAppConfigurationEditor);
  }

  const matchCustomAppDetail = matchPath<CustomAppDetailRouteParams>(path, CustomAppDetailRoute);
  if (matchCustomAppDetail) {
    return getCustomAppGoBackLink(matchCustomAppDetail);
  }

  const matchSubscriptionWebhookMessageListing =
    matchPath<SubscriptionEnvironmentWebhookMessagesRouteParams>(
      path,
      SubscriptionEnvironmentWebhookMessagesRoute,
    );
  if (matchSubscriptionWebhookMessageListing) {
    return getSubscriptionWebhookMessageListingBackLink(matchSubscriptionWebhookMessageListing);
  }

  const matchWebhookMessageListing = matchPath<WebhookMessagesRouteParams>(
    path,
    WebhookMessagesRoute,
  );
  if (matchWebhookMessageListing) {
    return getWebhookMessageListingBackLink(matchWebhookMessageListing);
  }

  const matchSubscriptionLegacyWebhookMessageListing =
    matchPath<SubscriptionEnvironmentLegacyWebhookMessagesRouteParams>(
      path,
      SubscriptionEnvironmentLegacyWebhookMessagesRoute,
    );
  if (matchSubscriptionLegacyWebhookMessageListing) {
    return getSubscriptionLegacyWebhookGoBackLink(matchSubscriptionLegacyWebhookMessageListing);
  }

  const matchLegacyWebhookMessageListing = matchPath<LegacyWebhookMessagesRouteParams>(
    path,
    LegacyWebhookMessagesRoute,
  );
  if (matchLegacyWebhookMessageListing) {
    return getLegacyWebhookMessageListingBackLink(matchLegacyWebhookMessageListing);
  }

  const matchSubscriptionWebhookCreator = matchPath<SubscriptionEnvironmentSettingsRouteParams>(
    path,
    SubscriptionEnvironmentWebhooksCreatingRoute,
  );
  if (matchSubscriptionWebhookCreator) {
    return getSubscriptionWebhookGoBackLink(matchSubscriptionWebhookCreator);
  }

  const matchWebhookCreator = matchPath<WebhooksRouteParams>(path, WebhooksCreatingRoute);
  if (matchWebhookCreator) {
    return getWebhookGoBackLink(matchWebhookCreator);
  }

  const matchSubscriptionWebhookEditor =
    matchPath<SubscriptionEnvironmentWebhooksEditorRouteParams>(
      path,
      SubscriptionEnvironmentWebhooksEditingRoute,
    );
  if (matchSubscriptionWebhookEditor) {
    return getSubscriptionWebhookGoBackLink(matchSubscriptionWebhookEditor);
  }

  const matchWebhookEditor = matchPath<WebhookEditorRouteParams>(path, WebhooksEditingRoute);
  if (matchWebhookEditor) {
    return getWebhookGoBackLink(matchWebhookEditor);
  }

  const matchContentItemRevisionViewer = matchPath<ContentItemRevisionViewerRouteParams<string>>(
    path,
    ContentItemRevisionViewerRoute,
  );
  if (matchContentItemRevisionViewer) {
    return originPath || getContentItemRevisionViewerGoBackLink(matchContentItemRevisionViewer);
  }

  const matchContentItemEditor = matchPath<ContentItemEditorRouteParams<string>>(path, {
    path: ContentItemEditorRoutes,
  });
  if (matchContentItemEditor) {
    return getContentItemEditorGoBackLink(matchContentItemEditor, originPath);
  }

  const matchAssetEditor = matchPath<AssetRouteParams>(path, AssetRoute);
  if (matchAssetEditor) {
    return originPath || buildAssetsRoutePath({ projectId: matchAssetEditor.projectId });
  }

  const matchContentTypeCreator = matchPath<EditContentTypeRouteParams>(
    path,
    CreateNewContentTypeRoute,
  );
  if (matchContentTypeCreator) {
    return getContentTypeEditorGoBackLink(matchContentTypeCreator);
  }

  const matchContentTypeEditor = matchPath<EditContentTypeRouteParams>(path, EditContentTypeRoute);
  if (matchContentTypeEditor) {
    return getContentTypeEditorGoBackLink(matchContentTypeEditor);
  }

  const matchContentTypeSnippetCreator = matchPath<EditContentTypeSnippetRouteParams>(
    path,
    CreateNewContentTypeSnippetsRoute,
  );
  if (matchContentTypeSnippetCreator) {
    return getContentTypeSnippetEditorGoBackLink(matchContentTypeSnippetCreator);
  }

  const matchContentTypeSnippetEditor = matchPath<EditContentTypeSnippetRouteParams>(
    path,
    EditContentTypeSnippetRoute,
  );
  if (matchContentTypeSnippetEditor) {
    return getContentTypeSnippetEditorGoBackLink(matchContentTypeSnippetEditor);
  }

  const matchRoleCreatorOpenedFromSubscriptionsManager =
    matchPath<SubscriptionEnvironmentRoleEditorRouteParams>(
      path,
      SubscriptionEnvironmentRoleCreatorRoute,
    );
  if (matchRoleCreatorOpenedFromSubscriptionsManager) {
    return getRoleEditorOpenedFromSubscriptionsManagerGoBackLink(
      matchRoleCreatorOpenedFromSubscriptionsManager,
    );
  }

  const matchRoleEditorOpenedFromSubscriptionsManager =
    matchPath<SubscriptionEnvironmentRoleEditorRouteParams>(
      path,
      SubscriptionEnvironmentRoleEditorRoute,
    );
  if (matchRoleEditorOpenedFromSubscriptionsManager) {
    return getRoleEditorOpenedFromSubscriptionsManagerGoBackLink(
      matchRoleEditorOpenedFromSubscriptionsManager,
    );
  }

  const matchWorkflowCreatorOpenedFromSubscriptionsManager =
    matchPath<SubscriptionEnvironmentWorkflowEditingRouteParams>(
      path,
      SubscriptionEnvironmentWorkflowCreatingRoute,
    );
  if (matchWorkflowCreatorOpenedFromSubscriptionsManager) {
    return (
      originPath ||
      getWorkflowOpenedFromSubscriptionsManagerGoBackLink(
        matchWorkflowCreatorOpenedFromSubscriptionsManager,
      )
    );
  }

  const matchWorkflowOpenedFromSubscriptionsManager =
    matchPath<SubscriptionEnvironmentWorkflowEditingRouteParams>(
      path,
      SubscriptionEnvironmentWorkflowEditingRoute,
    );
  if (matchWorkflowOpenedFromSubscriptionsManager) {
    return (
      originPath ||
      getWorkflowOpenedFromSubscriptionsManagerGoBackLink(
        matchWorkflowOpenedFromSubscriptionsManager,
      )
    );
  }

  const matchRoleCreatorOpenedFromEnvironmentSettings = matchPath<RoleEditorRouteParams>(
    path,
    RoleCreatorRoute,
  );
  if (matchRoleCreatorOpenedFromEnvironmentSettings) {
    return getRoleEditorOpenedFromEnvironmentSettingsGoBackLink(
      matchRoleCreatorOpenedFromEnvironmentSettings,
    );
  }

  const matchRoleEditorOpenedFromEnvironmentSettings = matchPath<RoleEditorRouteParams>(
    path,
    RoleEditorRoute,
  );
  if (matchRoleEditorOpenedFromEnvironmentSettings) {
    return getRoleEditorOpenedFromEnvironmentSettingsGoBackLink(
      matchRoleEditorOpenedFromEnvironmentSettings,
    );
  }

  const matchWorkflowCreatorOpenedFromEnvironmentSettings = matchPath<WorkflowsRouteParams>(
    path,
    WorkflowCreatingRoute,
  );
  if (matchWorkflowCreatorOpenedFromEnvironmentSettings) {
    return (
      originPath ||
      getWorkflowOpenedFromEnvironmentSettingsGoBackLink(
        matchWorkflowCreatorOpenedFromEnvironmentSettings,
      )
    );
  }

  const matchWorkflowEditorOpenedFromEnvironmentSettings = matchPath<WorkflowEditingRouteParams>(
    path,
    WorkflowEditingRoute,
  );
  if (matchWorkflowEditorOpenedFromEnvironmentSettings) {
    return (
      originPath ||
      getWorkflowOpenedFromEnvironmentSettingsGoBackLink(
        matchWorkflowEditorOpenedFromEnvironmentSettings,
      )
    );
  }

  const matchEnvironmentSettings = matchPath<EnvironmentRouteParams>(
    path,
    EnvironmentSettingsRoute,
  );
  if (matchEnvironmentSettings) {
    return getEnvironmentSettingsGoBackLink(matchEnvironmentSettings);
  }

  const matchSubscriptionEnvironmentSettings =
    matchPath<SubscriptionEnvironmentSettingsRouteParams>(
      path,
      SubscriptionEnvironmentSettingsRoute,
    );
  if (matchSubscriptionEnvironmentSettings) {
    return getSubscriptionEnvironmentSettingsGoBackLink(matchSubscriptionEnvironmentSettings);
  }

  const matchTaxonomyGroupCreator = matchPath<EditTaxonomyGroupRouteParams>(
    path,
    CreateNewTaxonomyGroupRoute,
  );
  if (matchTaxonomyGroupCreator) {
    return getTaxonomyGroupEditorGoBackLink(matchTaxonomyGroupCreator);
  }

  const matchDapiKeyDetail = matchPath<ProjectRouteParams>(path, DapiKeyDetailRoute);
  if (matchDapiKeyDetail) {
    return getDapiKeyDetailGoBackLink(matchDapiKeyDetail);
  }

  const matchNewDapiKeyDetail = matchPath<ProjectRouteParams>(path, NewDapiKeyDetailRoute);
  if (matchNewDapiKeyDetail) {
    return getDapiKeyDetailGoBackLink(matchNewDapiKeyDetail);
  }

  const matchPersonalMapiKeyDetail = matchPath<ProjectRouteParams>(
    path,
    PersonalMapiKeyDetailRoute,
  );
  if (matchPersonalMapiKeyDetail) {
    return getMapiKeyDetailGoBackLink(matchPersonalMapiKeyDetail);
  }

  const matchNewPersonalMapiKeyDetail = matchPath<ProjectRouteParams>(
    path,
    NewPersonalMapiKeyDetailRoute,
  );
  if (matchNewPersonalMapiKeyDetail) {
    return getMapiKeyDetailGoBackLink(matchNewPersonalMapiKeyDetail);
  }

  const matchMapiKeyDetail = matchPath<ProjectRouteParams>(path, MapiKeyDetailRoute);
  if (matchMapiKeyDetail) {
    return getMapiKeyDetailGoBackLink(matchMapiKeyDetail);
  }

  const matchNewMapiKeyDetail = matchPath<ProjectRouteParams>(path, NewMapiKeyDetailRoute);
  if (matchNewMapiKeyDetail) {
    return getMapiKeyDetailGoBackLink(matchNewMapiKeyDetail);
  }

  const matchTaxonomyGroupEditor = matchPath<EditTaxonomyGroupRouteParams>(
    path,
    EditTaxonomyGroupRoute,
  );
  assert(
    matchTaxonomyGroupEditor,
    () => `appToolbarRouteUtils.ts: Current route ${path} does not have goBackLink.`,
  );
  return getTaxonomyGroupEditorGoBackLink(matchTaxonomyGroupEditor);
}
