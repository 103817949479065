import { InvariantException } from '@kontent-ai/errors';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { rememberSmartLinkCommand } from '../../../../../_shared/actions/sharedActions.ts';
import {
  trackUserEvent,
  trackUserEventWithData,
} from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useEventListener } from '../../../../../_shared/hooks/useEventListener.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getUrlOrigin } from '../../../../../_shared/utils/urlUtils.ts';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';
import { loadElementActionPermissionsForVariant } from '../../../../webSpotlight/actions/thunkWebSpotlightActions.ts';
import { navigateToAddButtonTarget } from '../../../../webSpotlight/actions/thunks/navigateToAddButtonTarget.ts';
import { navigateToClickedContentComponent } from '../../../../webSpotlight/actions/thunks/navigateToClickedContentComponent.ts';
import { navigateToClickedContentItem } from '../../../../webSpotlight/actions/thunks/navigateToClickedContentItem.ts';
import { navigateToEditElementButtonTarget } from '../../../../webSpotlight/actions/thunks/navigateToEditElementButtonTarget.ts';
import {
  IAddButtonActionSmartLinkSdkClientMessage,
  IAddButtonInitialSmartLinkSdkClientMessage,
  IAddButtonInitialSmartLinkSdkHostMessage,
  IEditButtonClickedContentComponentSdkClientMessage,
  IEditButtonClickedContentItemSmartLinkSdkClientMessage,
  IEditButtonClickedElementSmartLinkSdkClientMessage,
  IInitializedResponseSmartLinkSdkHostMessage,
  IInitializedSmartLinkSdkClientMessage,
  IPreviewIFrameCurrentUrlResponseSmartLinkSdkClientMessage,
  ISmartLinkSdkMessageMetadata,
  SmartLinkSdkClientMessage,
  SmartLinkSdkClientMessageType,
  SmartLinkSdkHostMessageType,
} from '../../../../webSpotlight/types/SmartLinkSdkApi.ts';
import { getStartingPositionForFloatingEditor } from '../../../../webSpotlight/utils/floatingEditorUtils.ts';
import { smartLinkSdkMessageToSmartLinkCommand } from '../../../../webSpotlight/utils/smartLinkSdkUtils.ts';
import { useWebSpotlightInItemEditing } from '../context/WebSpotlightInItemEditingContext.tsx';

export const useWebSpotlightInItemEditingMessaging = () => {
  const {
    editableElementsVisible,
    getPreviewIFrameWindow,
    isSmartLinkSdkInitialized,
    previewIFrameRef,
    previewUrl,
    sendMessageToPreviewIFrame,
    setCurrentPreviewUrl,
    setFloatingEditorPosition,
    setIsSmartLinkSdkInitialized,
    setSupportedSmartLinkFeatures,
  } = useWebSpotlightInItemEditing();

  const dispatch = useDispatch();
  const languages = useSelector((s) => s.data.languages);

  useEffect(() => {
    if (isSmartLinkSdkInitialized) {
      sendMessageToPreviewIFrame({
        type: SmartLinkSdkHostMessageType.Status,
        data: {
          enabled: editableElementsVisible,
        },
      });
    }
  }, [editableElementsVisible, isSmartLinkSdkInitialized, sendMessageToPreviewIFrame]);

  const history = useHistory();

  const adjustFloatingEditorPosition = (metadata: ISmartLinkSdkMessageMetadata): void => {
    if (previewIFrameRef?.current && metadata) {
      const position = getStartingPositionForFloatingEditor(previewIFrameRef.current, metadata);
      setFloatingEditorPosition(position);
    }
  };

  const handleEditButtonContentComponentClicked = ({
    data,
    metadata,
  }: IEditButtonClickedContentComponentSdkClientMessage): void => {
    adjustFloatingEditorPosition(metadata);
    dispatch(trackUserEvent(TrackedEvent.WebSpotlightPreviewEditContentComponentClicked));
    dispatch(navigateToClickedContentComponent(data, history));
  };

  const handleEditButtonContentItemClicked = ({
    data,
    metadata,
  }: IEditButtonClickedContentItemSmartLinkSdkClientMessage): void => {
    adjustFloatingEditorPosition(metadata);
    dispatch(trackUserEvent(TrackedEvent.WebSpotlightPreviewEditContentItemClicked));
    dispatch(navigateToClickedContentItem(data, history));
  };

  const handleEditButtonClicked = ({
    data,
    metadata,
  }: IEditButtonClickedElementSmartLinkSdkClientMessage): void => {
    adjustFloatingEditorPosition(metadata);
    dispatch(trackUserEvent(TrackedEvent.WebSpotlightPreviewEditElementClicked));
    dispatch(navigateToEditElementButtonTarget(data, history));
  };

  const handleAddButtonClicked = async ({
    data,
    requestId,
    metadata,
  }: IAddButtonInitialSmartLinkSdkClientMessage): Promise<void> => {
    const variantId =
      languages.byId.find((language: ILanguage) => language.codename === data.languageCodename)
        ?.id ?? DefaultVariantId;

    adjustFloatingEditorPosition(metadata);
    dispatch(trackUserEvent(TrackedEvent.WebSpotlightPreviewAddButtonClicked));
    dispatch(navigateToAddButtonTarget(data, history));

    const result = await dispatch(
      loadElementActionPermissionsForVariant(
        variantId,
        data.itemId,
        data.elementCodename,
        data.contentComponentId,
      ),
    );

    const response: IAddButtonInitialSmartLinkSdkHostMessage = {
      data: {
        ...result,
        permissions: new Map(Object.entries(result.permissions)),
      },
      requestId,
      type: SmartLinkSdkHostMessageType.AddButtonInitialResponse,
    };

    sendMessageToPreviewIFrame(response);
  };

  const handleSdkInitialized = ({
    requestId,
    data,
  }: IInitializedSmartLinkSdkClientMessage): void => {
    const response: IInitializedResponseSmartLinkSdkHostMessage = {
      type: SmartLinkSdkHostMessageType.InitializedResponse,
      requestId,
    };

    sendMessageToPreviewIFrame(response);
    setIsSmartLinkSdkInitialized(true);
    setSupportedSmartLinkFeatures(data?.supportedFeatures ?? null);
  };

  const handleAddButtonAction = (message: IAddButtonActionSmartLinkSdkClientMessage): void => {
    adjustFloatingEditorPosition(message.metadata);

    dispatch(
      trackUserEventWithData(TrackedEvent.WebSpotlightPreviewAddButtonActionClicked, {
        action: message.data.action,
      }),
    );
    dispatch(navigateToAddButtonTarget(message.data, history));

    const command = smartLinkSdkMessageToSmartLinkCommand(message);
    dispatch(rememberSmartLinkCommand(command));
  };

  const handlePreviewIFrameCurrentUrlResponse = ({
    data,
  }: IPreviewIFrameCurrentUrlResponseSmartLinkSdkClientMessage): void => {
    dispatch(trackUserEvent(TrackedEvent.WebSpotlightPreviewShareLinkCreated));
    setCurrentPreviewUrl(data.previewUrl);
  };

  const originatesFromPreviewIFrame = (eventSource: MessageEventSource | null): boolean => {
    return !!eventSource && eventSource === getPreviewIFrameWindow();
  };

  const validateOrigin = (eventOrigin: string): void => {
    const sourceUrlOrigin = getUrlOrigin(previewUrl);

    if (sourceUrlOrigin !== eventOrigin) {
      throw InvariantException(
        `Incoming message origin ${eventOrigin} does not correspond with provided preview URL origin ${sourceUrlOrigin}.`,
      );
    }
  };

  const processMessage = (event: MessageEvent): void => {
    const { source, origin, data } = event;

    if (!originatesFromPreviewIFrame(source)) {
      return;
    }

    validateOrigin(origin);

    const message = data as SmartLinkSdkClientMessage;
    switch (message.type) {
      case SmartLinkSdkClientMessageType.Initialized: {
        handleSdkInitialized(message);
        break;
      }

      case SmartLinkSdkClientMessageType.EditContentComponentClicked: {
        handleEditButtonContentComponentClicked(message);
        break;
      }

      case SmartLinkSdkClientMessageType.EditContentItemClicked: {
        handleEditButtonContentItemClicked(message);
        break;
      }

      case SmartLinkSdkClientMessageType.EditElementClicked: {
        handleEditButtonClicked(message);
        break;
      }

      case SmartLinkSdkClientMessageType.AddButtonInitial: {
        handleAddButtonClicked(message);
        break;
      }

      case SmartLinkSdkClientMessageType.AddButtonAction: {
        handleAddButtonAction(message);
        break;
      }

      case SmartLinkSdkClientMessageType.PreviewIFrameCurrentUrlResponse: {
        handlePreviewIFrameCurrentUrlResponse(message);
        break;
      }
    }
  };

  useEventListener('message', (event: MessageEvent) => processMessage(event), self, true);
};
