import Immutable from 'immutable';
import { useState } from 'react';
import { getComposedElementCodename } from '../../../applications/contentModels/shared/utils/typeElementCodenameComposer.ts';
import { EditCodename } from './EditCodename.tsx';
import { OnSaveCodename } from './OnSaveCodename.type.ts';
import { ReadCodename } from './ReadCodename.tsx';

type Props = {
  readonly codename: string;
  readonly disabledEditingMessage?: string | null;
  readonly footnoteMessage?: string | null;
  readonly friendlyWarningMessage?: string;
  readonly hideTitle?: boolean;
  readonly isBeingSaved?: boolean;
  readonly isCodenameUnique: (codename: string) => boolean;
  readonly isEditable?: boolean;
  readonly maxLength?: number;
  readonly mode: 'save' | 'confirm';
  readonly onChange?: () => void;
  readonly onCopied?: () => void;
  readonly onCopyCompleted?: () => void;
  readonly onDiscardChanges?: () => void;
  readonly onEdited?: () => void;
  readonly onSave?: OnSaveCodename;
  readonly relatedCodenames?: Immutable.Set<string>;
  readonly snippetCodename?: string | null;
};

export const Codename = (props: Props) => {
  const [isEdited, setIsEdited] = useState(false);

  const edit = (): void => {
    props.onEdited?.();
    setIsEdited(true);
  };

  const discard = (): void => {
    props.onDiscardChanges?.();
    setIsEdited(false);
  };

  const save = async (codename: string): Promise<void> => {
    await props.onSave?.(codename, () => setIsEdited(false));
  };

  return isEdited ? (
    <EditCodename
      codename={props.codename}
      friendlyWarningMessage={props.friendlyWarningMessage}
      hideTitle={props.hideTitle}
      isBeingSaved={props.isBeingSaved}
      isCodenameUnique={props.isCodenameUnique}
      maxLength={props.maxLength}
      onChange={props.onChange}
      onSave={save}
      onDiscard={discard}
      mode={props.mode}
      snippetCodename={props.snippetCodename}
    />
  ) : (
    <ReadCodename
      codename={getComposedElementCodename(props.codename, props.snippetCodename)}
      disabledEditingMessage={props.disabledEditingMessage}
      footnoteMessage={props.footnoteMessage}
      hideTitle={props.hideTitle}
      isEditable={props.isEditable}
      onCopied={props.onCopied}
      onCopyCompleted={props.onCopyCompleted}
      onEdit={edit}
    />
  );
};
