import React from 'react';
import { StatusMessageTypes } from '../../../../../_shared/constants/statusMessageType.ts';
import { getConnectedStatusMessage } from '../../../../../_shared/containers/StatusBar/StatusMessage.tsx';
import { OperationStatus } from '../../../../../_shared/models/OperationStatus.ts';
import {
  DefaultStatusMessage,
  StatusMessage,
  UnsavedStatusMessage,
} from '../../../../../_shared/models/StatusMessage.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { IWebhooksAppStoreState } from '../../../../webhooks/stores/IWebhooksAppStoreState.type.ts';

const getWebhookStatusMessage = (webhookAppState: IWebhooksAppStoreState): StatusMessage => {
  const { lastOperation, editedWebhookId } = webhookAppState;
  if (editedWebhookId) {
    return UnsavedStatusMessage;
  }
  if (lastOperation) {
    switch (lastOperation.status) {
      case OperationStatus.Pending:
        return { messageType: StatusMessageTypes.Saving };
      case OperationStatus.Success:
        return { messageType: StatusMessageTypes.Saved };
      case OperationStatus.Failed:
        return { messageType: StatusMessageTypes.ServerError };
      case OperationStatus.Initialized:
        return { messageType: StatusMessageTypes.Unsaved };
    }
  }

  return DefaultStatusMessage;
};

const getSavingStatusMessage = ({ webhooksApp }: IStore): StatusMessage =>
  getWebhookStatusMessage(webhooksApp);

export const WebhookSavingStatus: React.ComponentType =
  getConnectedStatusMessage(getSavingStatusMessage);
