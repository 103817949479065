import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import React from 'react';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  DataUiWorkflowAction,
  ItemEditingForms,
  getDataUiActionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../../../../../../_shared/utils/func/compose.ts';
import { preventDefault } from '../../../../../../../_shared/utils/func/functionalTools.ts';

interface ISectionProps {
  readonly dataUiActionAttribute: DataUiWorkflowAction | DataUiAction;
  readonly dataUiNameAttribute: ItemEditingForms;
  readonly onEditClick: () => void;
}

export const ClickableSection: React.FC<React.PropsWithChildren<ISectionProps>> = ({
  children,
  dataUiActionAttribute,
  dataUiNameAttribute,
  onEditClick,
}) => {
  const hotkeysProps = useHotkeys({
    [ShortcutsConfig.Enter]: compose(onEditClick, preventDefault),
    [ShortcutsConfig.Space]: compose(onEditClick, preventDefault),
  });

  return (
    <div
      className="sidebar__section-item sidebar__section-item--is-clickable"
      onClick={onEditClick}
      tabIndex={0}
      role="button"
      {...hotkeysProps}
      {...getDataUiActionAttribute(dataUiActionAttribute)}
    >
      <div {...getDataUiObjectNameAttribute(dataUiNameAttribute || '')}>{children}</div>
      <Icon iconName={IconName.Edit} className="content-item-sidebar__icon-edit" />
    </div>
  );
};

ClickableSection.displayName = 'ClickableSection';
