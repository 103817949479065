import { SelectorType } from './orderedSelectors.ts';

export const requiredNewItemSelectorsForContentStatus: ReadonlySet<SelectorType> = new Set([
  SelectorType.ContentType,
  SelectorType.Collection,
  SelectorType.Workflow,
  SelectorType.Language,
]);

export const requiredNewItemSelectorsForListing: ReadonlySet<SelectorType> = new Set([
  SelectorType.ContentType,
  SelectorType.Collection,
  SelectorType.Workflow,
]);

export const requiredNewItemSelectorsForProjectOverview: ReadonlySet<SelectorType> = new Set([
  SelectorType.ContentType,
  SelectorType.Collection,
  SelectorType.Workflow,
]);

export const requiredNewItemSelectorsForLinkedItems: ReadonlySet<SelectorType> = new Set([
  SelectorType.ContentType,
  SelectorType.Collection,
  SelectorType.Workflow,
]);

export const requiredNewItemSelectorsForContentComponent: ReadonlySet<SelectorType> = new Set([
  SelectorType.ContentType,
]);

export const requiredNewItemSelectorsForWebSpotlight: ReadonlySet<SelectorType> = new Set([
  SelectorType.ContentType,
  SelectorType.Collection,
  SelectorType.Workflow,
]);

export const requiredNewItemSelectorsForSpaces: ReadonlySet<SelectorType> = new Set([
  SelectorType.Name,
  SelectorType.ContentType,
  SelectorType.Collection,
  SelectorType.Workflow,
]);
