import { Box } from '@kontent-ai/component-library/Box';
import { InputState } from '@kontent-ai/component-library/Input';
import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { gridUnit } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { CollectionSelectOption } from '../../../../_shared/containers/CollectionSelectOption.tsx';
import { IDropdownTippyOptions } from '../../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICollection } from '../../../../data/models/collections/Collection.ts';

export type CollectionSingleSelectProps = {
  readonly collectionOptions: ReadonlyArray<ICollection>;
  readonly currentCollectionId: Uuid | null;
  readonly customClassName?: string;
  readonly customErrorClassName?: string;
  readonly customIconClassName?: string;
  readonly disabledTooltipMessage?: string;
  readonly getCollectionDisabledMessage?: (collection: ICollection) => string | undefined;
  readonly id?: Uuid;
  readonly onChange: (collectionId: Uuid) => void;
  readonly shouldHighlightAsError?: boolean;
  readonly tippyOptions?: IDropdownTippyOptions;
};

interface ICollectionOption extends ISelectItem<ICollectionOption> {
  readonly collection: ICollection;
}

const collectionDropdownMaxWidth = 90 * gridUnit;

export const CollectionSingleSelect = React.forwardRef<HTMLDivElement, CollectionSingleSelectProps>(
  (props, forwardedRef) => {
    const {
      currentCollectionId,
      collectionOptions,
      disabledTooltipMessage,
      onChange,
      getCollectionDisabledMessage,
    } = props;

    const items = collectionOptions.map(
      (collection): ICollectionOption => ({
        id: collection.id,
        label: collection.name,
        disabledTooltipText: getCollectionDisabledMessage?.(collection),
        collection,
      }),
    );

    const disabledIds = items.filter((item) => !!item.disabledTooltipText).map((item) => item.id);

    return (
      <SingleSelect
        aria-label="Select a collection"
        inputState={disabledTooltipMessage ? InputState.Disabled : undefined}
        onSelectionChange={onChange}
        items={items}
        disabledItemIds={disabledIds}
        placeholder="Select a collection"
        renderMenuOption={(optionProps) =>
          optionProps.item.value && (
            <Box maxWidth={collectionDropdownMaxWidth}>
              <CollectionSelectOption collectionId={optionProps.item.value.id} {...optionProps} />
            </Box>
          )
        }
        selectedItemId={currentCollectionId}
        ref={forwardedRef}
        {...getDataUiCollectionAttribute(DataUiCollection.ContentCollections)}
      />
    );
  },
);

CollectionSingleSelect.displayName = 'CollectionSingleSelect';
