import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { shallowEqual } from '../../../../../../_shared/utils/shallowEqual.ts';
import { SpaceSelector as SpaceSelectorComponent } from '../../components/contentItemPreview/SpaceSelector.tsx';
import { useEditorContext } from '../../context/EditorContextProvider.tsx';
import { getAvailableSpacesForUser } from '../../selectors/getAvailableSpacesForUser.ts';

export const SpaceSelector = () => {
  const { selectedSpaceId, setSelectedSpaceId } = useEditorContext();
  const availableSpaces = useSelector(getAvailableSpacesForUser, shallowEqual);

  if (!availableSpaces.length) return null;

  const spaceMenuItems = availableSpaces.map((space) => ({
    id: space.id,
    label: space.name,
  }));

  return (
    <SpaceSelectorComponent
      onSelectionChange={setSelectedSpaceId}
      selectedSpaceId={selectedSpaceId}
      spaceMenuItems={spaceMenuItems}
    />
  );
};
