import React, { ComponentProps } from 'react';
import { useLocation } from 'react-router';
import { DataTableRow } from '../../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { LastUpdatedAt } from '../../../../../_shared/components/LastUpdatedAt.tsx';
import { WorkflowStatusTagForVariant } from '../../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { TableCellDueDate } from '../../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import { NameWithLanguage } from '../../shared/components/NameWithLanguage.tsx';
import { IRecentlyEditedItemViewModel } from '../containers/RecentlyEditedByYouWidgetDetailContainer.tsx';

type RecentlyEditedByYouWidgetDetailRowProps = {
  readonly onItemClick?: () => void;
  readonly recentlyEditedItem: IRecentlyEditedItemViewModel;
};

export const RecentlyEditedByYouWidgetDetailRow: React.FC<
  RecentlyEditedByYouWidgetDetailRowProps
> = ({ onItemClick, recentlyEditedItem }) => {
  const { pathname } = useLocation();

  const linkProps = {
    linkPath: getContentItemPath(
      pathname,
      recentlyEditedItem.id.itemId,
      recentlyEditedItem.id.variantId,
    ),
    onClick: onItemClick,
  } satisfies ComponentProps<typeof LinkDataTableCell>;

  return (
    <DataTableRow
      id={stringifyContentItemId(recentlyEditedItem.id)}
      key={stringifyContentItemId(recentlyEditedItem.id)}
      dataUiObjectName={recentlyEditedItem.name}
    >
      <LinkDataTableCell
        focusableRowLinkAriaLabel={`visit item — ${recentlyEditedItem.name}`}
        {...linkProps}
      >
        <NameWithLanguage
          name={recentlyEditedItem.name}
          language={recentlyEditedItem.language}
          variantCompletionStatus={recentlyEditedItem.variantCompletionStatus}
        />
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps} title={recentlyEditedItem.contentType}>
        {recentlyEditedItem.contentType}
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>
        <WorkflowStatusTagForVariant
          publishingState={recentlyEditedItem.publishingState}
          workflowStatus={recentlyEditedItem.workflowStatus}
          scheduledToPublishAt={recentlyEditedItem.scheduledToPublishAt}
          scheduledToUnpublishAt={recentlyEditedItem.scheduledToUnpublishAt}
        />
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>
        <TableCellDueDate dueDate={recentlyEditedItem.due} />
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>
        <LastUpdatedAt time={recentlyEditedItem.lastUpdatedAt} />
      </LinkDataTableCell>
    </DataTableRow>
  );
};
