import {
  CustomAppDetailRoute,
  CustomAppDetailRouteParams,
} from '../../../_shared/constants/routePaths.ts';
import { LoadingStatus } from '../../../_shared/models/LoadingStatusEnum.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { matchPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';

export const canAccessAnyCustomApp = (state: IStore): boolean => {
  return (
    (state.data.userCustomApps.loadingStatus === LoadingStatus.Loaded ||
      state.data.userCustomApps.loadingStatus === LoadingStatus.Loading) &&
    state.data.userCustomApps.customApps.length > 0
  );
};

export const getCurrentUserWithRoles = (state: IStore, projectId: string) => {
  const userRoles =
    state.data.user.normalizedRolesWithSettingsPerProject
      .get(projectId)
      ?.collectionGroups.flatMap((collectionGroupRole) =>
        collectionGroupRole.roles.map((r) => ({ id: r.role.id, codename: r.role.codename })),
      ) ?? [];

  return {
    roles: userRoles,
    id: state.data.user.info.userId,
    email: state.data.user.info.email,
  };
};

export const getActiveCustomAppId = (state: IStore, pathname: string) => {
  const customApps = state.data.userCustomApps.customApps;
  const match = matchPath<CustomAppDetailRouteParams>(pathname, CustomAppDetailRoute);

  return match ? match.customAppId : customApps[0]?.id;
};
