import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React, { useState } from 'react';
import { FullScreenModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import { useAiActionTrackingWithSession } from '../../../../../_shared/features/AI/hooks/useAiActionTrackingWithSession.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  AiActionSource,
  AiFollowingAction,
  TrackingAiActionName,
} from '../../../../../_shared/models/events/AiActionEventData.type.ts';
import { isAiLinkedItemsEnabled } from '../../../../../_shared/selectors/aiSelectors.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ILinkedItemsTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/LinkedItemsTypeElement.ts';
import { isLinkedItemsTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import {
  EmptyElementReference,
  useItemElementReference,
} from '../../ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { RecommendedLinkedItemsModalBody } from '../containers/RecommendedLinkedItemsModalBody.tsx';

type Props = {
  readonly onAddExistingItems: (itemsIds: ReadonlyArray<Uuid>) => void;
  readonly typeElement: ILinkedItemsTypeElement;
};

export const LinkedItemsAiAssistant: React.FC<Props> = ({ onAddExistingItems, typeElement }) => {
  const elementReference = useItemElementReference(typeElement);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { resetAiSessionId, trackStartingAction, trackFollowingAction } =
    useAiActionTrackingWithSession(elementReference ?? EmptyElementReference);

  const isAssistantEnabled = useSelector(isAiLinkedItemsEnabled);
  if (
    !isAssistantEnabled ||
    !elementReference ||
    elementReference.contentComponentId ||
    !isLinkedItemsTypeElement(typeElement)
  ) {
    return null;
  }

  const onOpenDialog = () => {
    setIsDialogOpen(true);
    trackStartingAction({
      action: TrackingAiActionName.SuggestLinkedItems,
      source: AiActionSource.Element,
    });
  };

  const onAddItem = (itemId: Uuid, score: number) => {
    onAddExistingItems([itemId]);
    closeDialogAndResetSession();
    trackFollowingAction({
      action: AiFollowingAction.SuggestedLinkedItemsAdded,
      items: [{ itemId, score }],
    });
  };

  const closeDialogAndResetSession = () => {
    setIsDialogOpen(false);
    resetAiSessionId();
  };

  return (
    <>
      <Button
        tooltipPlacement="right"
        buttonStyle="tertiary"
        onClick={onOpenDialog}
        size="small"
        {...getDataUiActionAttribute(DataUiAction.AiAction)}
      >
        <Button.Icon icon={Icons.Sparkles} />
        <Button.Label>Suggest with AI</Button.Label>
      </Button>
      <FullScreenModalDialog
        headline={`Insert an existing content item to ${typeElement.name}`}
        isOpen={isDialogOpen}
        isDismissable
        onClose={closeDialogAndResetSession}
      >
        <RecommendedLinkedItemsModalBody
          allowedContentTypeIds={typeElement.allowedTypes}
          onAddItem={onAddItem}
        />
      </FullScreenModalDialog>
    </>
  );
};
