import { GeneralPropertiesStateKeysByServerKeys } from '../models/GeneralPropertiesModel.ts';
import {
  EnableSpacesInCollectionsServerKey,
  EnableUmuxSurveyServerKey,
} from '../models/GeneralPropertiesServerKeys.ts';
import { parseBooleanFromValue } from './parseUtils.ts';

export const resolvePropertyValue = (
  propName: string,
  propertyValue: string | null,
): string | boolean | null => {
  switch (propName) {
    case GeneralPropertiesStateKeysByServerKeys[EnableUmuxSurveyServerKey]: {
      if (typeof propertyValue === 'string' && !Number.isNaN(new Date(propertyValue).getTime())) {
        return propertyValue;
      }

      return null;
    }

    case GeneralPropertiesStateKeysByServerKeys[EnableSpacesInCollectionsServerKey]: {
      return parseBooleanFromValue(propertyValue);
    }

    default:
      return propertyValue;
  }
};
