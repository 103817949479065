import { EditingAction } from '../../models/EditingAction.ts';

export const MainMenuStructureQuickActionsKey = 'Quick actions';

export const mainMenuStructure = [
  {
    actions: [
      EditingAction.previewItem,
      EditingAction.previewItemInMultipleSpaces,
      EditingAction.previewSampleAppItem,
      EditingAction.setupItemPreview,
      EditingAction.demonstrateDisabledPreviewItem,
      EditingAction.addTask,
      EditingAction.changeStep,
      EditingAction.publishItem,
      EditingAction.setDueDate,
      EditingAction.changeDueDate,
      EditingAction.assignPeople,
      EditingAction.setNote,
      EditingAction.changeNote,
    ],
    key: MainMenuStructureQuickActionsKey,
    title: undefined,
  },
  {
    actions: [
      EditingAction.openInlineComments,
      EditingAction.closeInlineComments,
      EditingAction.openResolvedComments,
      EditingAction.openDiscussions,
    ],
    key: 'Comments and Suggestions',
    title: 'Comments and Suggestions',
  },
  {
    actions: [
      EditingAction.disabledTranslateVariant,
      EditingAction.translateVariant,
      EditingAction.copyFromLanguage,
      EditingAction.openComponents,
      EditingAction.closeComponents,
      EditingAction.compareVersions,
      EditingAction.shareLink,
      EditingAction.showDuplicationOptions,
      EditingAction.changeCollection,
      EditingAction.changeWorkflow,
      EditingAction.discardNewVersion,
      EditingAction.deleteItem,
      EditingAction.demonstratePublishPreventsDeletingItem,
      EditingAction.demonstrateWebSpotlightPreventsDeletingItem,
    ],
    key: 'Content item',
    title: 'Content item',
  },
  {
    actions: [EditingAction.viewCodename],
    key: 'For developers',
    title: 'For developers',
  },
];
