import { InvariantException } from '@kontent-ai/errors';
import {
  colorBackgroundDisabledComplementary,
  colorIconDefault,
  colorIconDefaultInverse,
  colorIconDisabled,
  colorIconDisabledInverse,
  colorTextDefault,
  colorTextDefaultInverse,
  colorTextDisabled,
} from '../../tokens/decision/colors.ts';
import { Color } from '../../tokens/quarks/colors.ts';
import { GradientType } from '../../tokens/quarks/gradients.ts';

export const getTagBackground = (background: Color | GradientType, disabled?: boolean) =>
  disabled ? colorBackgroundDisabledComplementary : background;

const tagRemoveButtonColorTokens = {
  [colorTextDefaultInverse]: colorIconDefaultInverse,
  [colorTextDefault]: colorIconDefault,
} as const;

const isTagRemoveButtonColor = (color: Color): color is keyof typeof tagRemoveButtonColorTokens =>
  (Object.keys(tagRemoveButtonColorTokens) as ReadonlyArray<Color>).includes(color);

const tagRemoveButtonDisabledColorTokens = {
  [colorTextDefaultInverse]: colorIconDisabledInverse,
  [colorTextDefault]: colorIconDisabled,
} as const;

const isTagRemoveDisabledButtonColor = (
  color: Color,
): color is keyof typeof tagRemoveButtonDisabledColorTokens =>
  (Object.keys(tagRemoveButtonDisabledColorTokens) as ReadonlyArray<Color>).includes(color);

export const getRemoveButtonColor = (
  disabled: boolean | undefined,
  parentDisabled: boolean | undefined,
  labelColor: Color,
): Color => {
  if (parentDisabled) {
    return disabled ? colorTextDisabled : colorIconDefault;
  }

  if (disabled) {
    if (isTagRemoveDisabledButtonColor(labelColor)) {
      return tagRemoveButtonDisabledColorTokens[labelColor];
    }
  } else if (isTagRemoveButtonColor(labelColor)) {
    return tagRemoveButtonColorTokens[labelColor];
  }

  throw InvariantException(`LabelColor "${labelColor}" does not match with any expected colors.`);
};
