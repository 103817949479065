import {
  HotkeysHandler,
  HotkeysMode,
} from '../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { NotificationBar } from '../../../../_shared/containers/NotificationBar.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import { RoleConfiguratorMessage } from '../containers/RoleConfiguratorMessage.tsx';
import { RoleNameEditorElement } from '../containers/RoleNameEditorElement.tsx';
import { RoleCapabilitiesConfigurator } from './configurator/RoleCapabilitiesConfigurator.tsx';

type Props = {
  readonly areCollectionsEnabled: boolean;
  readonly areSpacesEnabled: boolean;
  readonly editedRoleName: string;
  readonly isAssetTypeEnabled: boolean;
  readonly isAuditLogEnabled: boolean;
  readonly isRoleBeingCreated: boolean;
  readonly isSitemapEnabled: boolean;
  readonly newRoleLink: string;
  readonly onControlSHandler: (e: KeyboardEvent) => void;
  readonly projectId: Uuid;
  readonly readonly: boolean;
};

export const RoleEditor = ({
  areCollectionsEnabled,
  areSpacesEnabled,
  editedRoleName,
  isAssetTypeEnabled,
  isAuditLogEnabled,
  isRoleBeingCreated,
  isSitemapEnabled,
  newRoleLink,
  onControlSHandler,
  projectId,
  readonly,
}: Props) => {
  return (
    <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.RoleEditing)}>
      <HtmlSettingsPageTitle
        settingsAppName={EnvironmentSettingsAppNames.Roles}
        customName={editedRoleName}
      />
      <div className="canvas__workspace">
        <section className="canvas__content">
          <div className="canvas__notifications">
            <NotificationBar />
          </div>
          <div className="canvas__content-pane">
            <HotkeysHandler
              mode={HotkeysMode.Dual}
              handlers={{
                onControlS: onControlSHandler,
              }}
            >
              <div className="canvas__inner-section canvas__inner-section--restricted-width canvas__inner-section--centered">
                <RoleConfiguratorMessage
                  newRoleLink={newRoleLink}
                  isRoleBeingCreated={isRoleBeingCreated}
                />
                <RoleNameEditorElement readOnly={readonly} />
                <RoleCapabilitiesConfigurator
                  areCollectionsEnabled={areCollectionsEnabled}
                  areSpacesEnabled={areSpacesEnabled}
                  projectId={projectId}
                  isAssetTypeEnabled={isAssetTypeEnabled}
                  isAuditLogEnabled={isAuditLogEnabled}
                  isSitemapEnabled={isSitemapEnabled}
                />
              </div>
            </HotkeysHandler>
          </div>
        </section>
      </div>
    </div>
  );
};
