import { connect } from 'react-redux';
import { Dispatch } from '../../@types/Dispatcher.type.ts';
import { upsertUserProperty } from '../actions/thunkSharedActions.ts';
import {
  IDropDownDispatchProps,
  IDropDownStateProps,
  getDropDown,
} from '../components/DropDown.tsx';
import { ProjectOrderBy } from '../constants/projectOrderBy.ts';
import { ProjectOrderBy as ProjectOrderByUserProperty } from '../models/UserPropertiesServerKeys.ts';
import { IStore } from '../stores/IStore.type.ts';
import { DataUiCollection } from '../utils/dataAttributes/DataUiAttributes.ts';

const options: ReadonlyArray<ProjectOrderBy> = Object.values(ProjectOrderBy);

const renderOptionName = (option: ProjectOrderBy): string => {
  switch (option) {
    case ProjectOrderBy.ProjectName:
      return 'Sort by name';
    case ProjectOrderBy.SubscriptionName:
      return 'Sort by subscription name';
  }
};

const Dropdown = getDropDown<ProjectOrderBy>();

const mapStateToProps = (state: IStore): IDropDownStateProps<ProjectOrderBy> => {
  const {
    sharedApp: { userProperties },
  } = state;

  return {
    className: 'combo-box',
    dataUiCollectionName: DataUiCollection.ProjectOrderBy,
    getOptionId: (o) => o,
    options,
    renderOptionName,
    selectedOption: userProperties.projectOrderBy,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDropDownDispatchProps<ProjectOrderBy> => {
  return {
    onSelect: (selectedOption: ProjectOrderBy) =>
      dispatch(upsertUserProperty(ProjectOrderByUserProperty, selectedOption)),
  };
};

export const ProjectSortingDropdown = connect(mapStateToProps, mapDispatchToProps)(Dropdown);
