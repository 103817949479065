import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { LocalizedRouteLeft } from '../../../../contentInventory/shared/constants/contentInventoryActionTypes.ts';
import {
  ModalContentItemSelector_Closed,
  ModalContentItemSelector_Opened,
  ModalMultipleContentItemsSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../../../../features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFailed,
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeModal_PublishingFailed,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_SchedulingFailed,
  ContentEditing_CascadeModal_SchedulingFinished,
} from '../../../../itemEditor/features/CascadePublish/constants/cascadeModalActionTypes.ts';
import { Calendar_Page_Left } from '../../../calendar/constants/calendarActionTypes.ts';
import {
  ContentStatusContentItems_Init,
  ContentStatusContentItems_Load_Started,
  ContentStatusContentItems_Load_Success,
} from '../../constants/contentStatusActionTypes.ts';

export const loadingStatus = (
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case LocalizedRouteLeft:
    case Shared_CurrentProjectId_Updated:
    case ModalMultipleContentItemsSelector_Closed:
    case ModalMultipleContentItemsSelector_Opened:
    case ModalContentItemSelector_Closed:
    case ModalContentItemSelector_Opened:
    case Calendar_Page_Left:
    case ContentStatusContentItems_Init:
    case ContentEditing_CascadeModal_PublishingFinished:
    case ContentEditing_CascadeModal_SchedulingFinished:
    case ContentEditing_CascadeAction_UndoFinished:
    case ContentEditing_CascadeModal_PublishingFailed:
    case ContentEditing_CascadeModal_SchedulingFailed:
    case ContentEditing_CascadeAction_UndoFailed:
      return LoadingStatus.InitialEmpty;

    case ContentStatusContentItems_Load_Success:
      return LoadingStatus.Loaded;

    case ContentStatusContentItems_Load_Started:
      return LoadingStatus.Loading;

    default:
      return state;
  }
};
