import { Inline } from '@kontent-ai/component-library/Inline';
import {
  BorderRadius,
  Spacing,
  Typography,
  colorPrimary,
  colorTextDefault,
  px,
  sizeBarItemBar,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EditorLinkStatus } from '../../../../richText/plugins/apiLimitations/api/EditorLinkStatus.ts';
import { LinkDetailActions } from './LinkDetailActions.tsx';

const LinkDialogDetail = styled.div`
  ${Typography.BodyLarge}
  color: ${colorTextDefault};
  cursor: default;
  position: relative;
  padding: 0 ${px(Spacing.S)};
`;

const UploadProgressWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: ${px(BorderRadius.M)};
  border-radius: ${px(BorderRadius.M)} ${px(BorderRadius.M)} 0 0;
  overflow: hidden;
  background: transparent;
`;

UploadProgressWrapper.displayName = 'UploadProgress';

interface IUploadProgressLineProps {
  readonly $width?: number;
}

const UploadProgressLine = styled.div.attrs<IUploadProgressLineProps>(({ $width }) => ({
  style: {
    width: `${$width ?? 100}%`,
  },
}))<IUploadProgressLineProps>`
  height: 2px;
  background: ${colorPrimary};
`;

interface ILinkDetailDataProps {
  readonly disabled?: boolean;
  readonly forwardedRef?: React.Ref<HTMLDivElement>;
  readonly isUploading?: boolean;
  readonly status?: EditorLinkStatus;
  readonly uploadedSoFar?: number;
}

interface ILinkDetailCallbackProps {
  readonly onEdit?: () => void;
  readonly onUnlink?: () => void;
}

type LinkDetailProps = ILinkDetailCallbackProps & ILinkDetailDataProps;

export const LinkDetail: React.FC<React.PropsWithChildren<LinkDetailProps>> = ({
  children,
  disabled,
  forwardedRef,
  isUploading,
  uploadedSoFar,
  status,
  onEdit,
  onUnlink,
}) => {
  const isDisabled = disabled || status === EditorLinkStatus.InNotAllowedText;
  return (
    <div
      ref={forwardedRef}
      className="rte__link-dialog link-dialog"
      contentEditable={false}
      {...getDataUiElementAttribute(DataUiElement.RteLinkDetail)}
    >
      <LinkDialogDetail>
        <Inline align="center" spacing={Spacing.S} noWrap css={`height: ${px(sizeBarItemBar)}`}>
          {isUploading && (
            <UploadProgressWrapper>
              <UploadProgressLine $width={uploadedSoFar} />
            </UploadProgressWrapper>
          )}
          {children}
          {!isDisabled && (
            <LinkDetailActions
              isEditable={status === EditorLinkStatus.Allowed}
              onEdit={onEdit}
              onUnlink={onUnlink}
            />
          )}
        </Inline>
      </LinkDialogDetail>
    </div>
  );
};
