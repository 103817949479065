import { EnvironmentsRouteSegment } from '../../../applications/projectSettings/root/constants/routeSegments.ts';
import {
  CustomAppDetailRoute,
  CustomAppDetailRouteParams,
  ProjectRouteParams,
  ProjectSettingsRoute,
} from '../../constants/routePaths.ts';
import { buildPath } from './routeTransitionUtils.ts';

export const createEnvironmentsLink = ({ projectContainerId }: ProjectRouteParams): string =>
  buildPath<ProjectRouteParams>(`${ProjectSettingsRoute}/${EnvironmentsRouteSegment}`, {
    projectContainerId,
  });

export const createCustomAppDetailLink = ({
  projectContainerId,
  customAppId,
}: ProjectRouteParams & { customAppId: Uuid }): string =>
  buildPath<CustomAppDetailRouteParams>(CustomAppDetailRoute, {
    projectId: projectContainerId,
    customAppId,
  });
