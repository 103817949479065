import { Tooltip, disabledAriaTippyOptions } from '@kontent-ai/component-library/Tooltip';
import { Placement } from '@kontent-ai/component-library/types';
import { useIdWithPrefix } from '@kontent-ai/hooks';
import classNames from 'classnames';
import { FocusEvent, MouseEvent, ReactNode, forwardRef } from 'react';
import {
  DataUiAction,
  getDataAttributeProps,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { SquareButtonSize } from './squareButtonSize.ts';
import { SquareButtonStyle } from './squareButtonStyle.ts';

type RequiredAriaLabelProps =
  | {
      readonly ariaLabel: string;
      readonly tooltipText?: string;
    }
  | {
      readonly ariaLabel?: string;
      /** Used as aria-label when ariaLabel not provided */
      readonly tooltipText: string;
    };

export type ISquareButtonShellProps = RequiredAriaLabelProps & {
  readonly ariaControls?: string;
  readonly ariaExpanded?: boolean;
  readonly appendTooltipToParent?: boolean;
  readonly className?: string;
  readonly children?: ReactNode;
  readonly customButtonClassName?: string;
  readonly dataUiAction?: DataUiAction;
  readonly disabled?: boolean;
  readonly isActive?: boolean;
  readonly size?: SquareButtonSize;
  readonly style: SquareButtonStyle;
  readonly tooltipPlacement?: Placement;
  readonly tooltipShortcuts?: string;
  readonly onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  readonly onFocus?: (e: FocusEvent) => void;
};

export const SquareButtonShell = forwardRef<HTMLDivElement, ISquareButtonShellProps>(
  ({ appendTooltipToParent, size = SquareButtonSize.M, ...props }, forwardedRef) => {
    const tooltipId = useIdWithPrefix('square-button');

    return (
      <Tooltip
        placement={props.tooltipPlacement ?? 'bottom'}
        shortcuts={props.tooltipShortcuts}
        tippyOptions={
          appendTooltipToParent
            ? { appendTo: 'parent', ...disabledAriaTippyOptions }
            : disabledAriaTippyOptions
        }
        tooltipText={props.isActive ? '' : props.tooltipText}
        tooltipTextId={props.ariaLabel && tooltipId}
      >
        <div ref={forwardedRef} className={classNames('btn-wrapper', props.className)}>
          <button
            className={classNames(
              'btn btn--square',
              props.style,
              size,
              props.customButtonClassName,
              { active: props.isActive },
            )}
            onClick={props.onClick}
            onFocus={props.onFocus}
            disabled={props.disabled}
            aria-describedby={tooltipId}
            aria-expanded={props.ariaExpanded}
            aria-controls={props.ariaControls}
            {...getDataAttributeProps(props)}
          >
            {props.children}
            <span className="sr-only">{props.ariaLabel ?? props.tooltipText}</span>
          </button>
        </div>
      </Tooltip>
    );
  },
);

SquareButtonShell.displayName = 'SquareButtonShell';
