import { Box } from '@kontent-ai/component-library/Box';
import { colorAlertText } from '@kontent-ai/component-library/tokens';
import { RedactedItemName } from '../../../applications/contentInventory/content/constants/uiConstants.ts';
import { NonExistentBarItemActions } from '../BarItems/Actions/NonExistentBarItemActions.tsx';
import { BarItemActions } from '../BarItems/BarItemActions.tsx';
import { BarItemTitle } from '../BarItems/BarItemTitle.tsx';

type Props = {
  readonly cannotViewDisabledMessage: string | undefined;
  readonly isCompact?: boolean;
  readonly name: string;
  readonly showExpandActionPlaceholder?: boolean;
};

export const DeletedLinkedItemContent = ({
  cannotViewDisabledMessage,
  isCompact,
  name,
  showExpandActionPlaceholder,
}: Props) => {
  const isRedacted = !!cannotViewDisabledMessage;

  return (
    <>
      {showExpandActionPlaceholder && (
        <BarItemActions isCompact={isCompact}>
          <NonExistentBarItemActions />
        </BarItemActions>
      )}
      <BarItemTitle
        suffix={<Box color={colorAlertText}>(Deleted)</Box>}
        title={isRedacted ? undefined : name}
        titleStyle={isRedacted ? 'primary-redacted' : 'primary'}
        tooltipText={cannotViewDisabledMessage}
      >
        {isRedacted ? RedactedItemName : name}
      </BarItemTitle>
    </>
  );
};
