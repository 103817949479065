import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { RefObject } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SelectorType } from '../constants/orderedSelectors.ts';
import { SelectorHeader } from './SelectorHeader.tsx';

interface ILanguageDropdownComponentProps {
  readonly currentLanguageId: Uuid;
  readonly index: number;
  readonly inputRef?: RefObject<HTMLInputElement>;
  readonly languageOptions: ReadonlyArray<IBaseSelectItem>;
  readonly onSelect: (languageId: Uuid) => void;
}

export const LanguageSelector: React.FC<ILanguageDropdownComponentProps> = ({
  currentLanguageId,
  index,
  inputRef,
  languageOptions,
  onSelect,
}) => (
  <Stack spacing={Spacing.L}>
    <SelectorHeader index={index} type={SelectorType.Language} />
    <SingleSelect
      aria-label="Select a language"
      items={languageOptions}
      inputRef={inputRef}
      onSelectionChange={onSelect}
      placeholder="Select a language"
      selectedItemId={currentLanguageId}
      verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.LanguageList)}
      {...getDataUiCollectionAttribute(DataUiCollection.LanguageList)}
    />
  </Stack>
);

LanguageSelector.displayName = 'LanguageSelector';
