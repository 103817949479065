import React from 'react';
import { AccessDenied } from '../components/AccessDenied.tsx';
import { AccessDeniedToContentItemAction } from '../constants/AccessDeniedToContentItemAction.ts';

export const AccessDeniedToContentItem: React.FC<{
  readonly requestedAction: AccessDeniedToContentItemAction;
}> = ({ requestedAction }) => {
  switch (requestedAction) {
    case AccessDeniedToContentItemAction.Translate:
      return (
        <AccessDenied
          title="Do you need to translate content items?"
          descriptionParagraphs={[
            'You can’t translate content items.',
            'Contact your project manager for more information.',
          ]}
        />
      );
    case AccessDeniedToContentItemAction.View:
      return (
        <AccessDenied
          title="Do you need to see this item?"
          descriptionParagraphs={[
            'You can’t view this item.',
            'Contact your project manager for more information.',
          ]}
        />
      );
  }
};
