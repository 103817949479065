import { Inline } from '@kontent-ai/component-library/Inline';
import {
  GridListMenu,
  VerticalMenuItem,
  useGridListMenuState,
} from '@kontent-ai/component-library/VerticalMenu';
import { WorkflowStatus } from '@kontent-ai/component-library/WorkflowStatus';
import { BaseColor } from '@kontent-ai/component-library/tokens';
import { ReactNode, useState } from 'react';
import { useHistory } from 'react-router';
import { SelectGroupRenderMode } from '../../../../../component-library/components/StatusBar/Selects/types/SelectGroupRenderMode.ts';
import { AppNames } from '../../../constants/applicationNames.ts';
import { StatefulDropDown } from '../../../uiComponents/DropDown/StatefulDropDown.tsx';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { StatusBarDropDownSelected } from '../../StatusBar/StatusBarDropDownSelected.tsx';
import { ItemSettings } from '../ItemSettings.tsx';
import { optionMaxWidth, projectMenuTippyOptions } from '../constants.ts';
import { MenuItem } from '../types.type.ts';

const renderStatusTag = (project: ProjectMenuItem): ReactNode => {
  if (project?.isPlanExpired) {
    return (
      <WorkflowStatus
        primary={{
          name: 'Expired',
          background: BaseColor.Red60,
        }}
      />
    );
  }

  if (project?.isPlanSuspended) {
    return (
      <WorkflowStatus
        primary={{
          name: 'Suspended',
          background: BaseColor.Red30,
        }}
      />
    );
  }

  return null;
};

export type ProjectMenuItem = MenuItem &
  VerticalMenuItem<ProjectMenuItem> & {
    readonly isPlanExpired: boolean;
    readonly isPlanSuspended: boolean;
    readonly settingsEnabled: boolean;
    readonly settingsLink: string;
  };

type ProjectSelectProps = {
  readonly projectItems: ReadonlyArray<ProjectMenuItem>;
  readonly renderMode: SelectGroupRenderMode;
  readonly selectedProjectName: string;
};

export const ProjectSelect: React.FC<ProjectSelectProps> = ({
  projectItems,
  selectedProjectName,
  renderMode,
}) => {
  const history = useHistory();
  const { state, items } = useGridListMenuState([
    {
      id: 'projects',
      items: projectItems,
      label: 'Projects',
      type: 'section',
    },
  ]);

  const [transitionFinished, setTransitionFinished] = useState(true);
  const handleTransitionEnd = () => {
    setTransitionFinished(true);
  };

  return (
    <StatefulDropDown
      optionListDataUiAttributes={getDataUiCollectionAttribute(DataUiCollection.Projects)}
      onClose={() => setTransitionFinished(false)}
      renderContent={(closeMenu) =>
        transitionFinished && (
          <GridListMenu
            pinnedItemId="projects-pinned"
            aria-label="Project select"
            state={state}
            maxWidth={optionMaxWidth}
            items={items}
            renderItem={({ item }) =>
              item.value && (
                <GridListMenu.Item
                  item={item}
                  state={state}
                  onPress={() => {
                    closeMenu();
                    if (item.value) {
                      history.push(item.value.link);
                    }
                  }}
                  trailingElements={
                    <Inline>
                      {renderStatusTag(item.value)}
                      {item.value?.settingsEnabled && (
                        <ItemSettings
                          onClick={() => {
                            closeMenu();
                            if (item.value) {
                              history.push(item.value.settingsLink);
                            }
                          }}
                          tooltipText={AppNames.ProjectSettings}
                        />
                      )}
                    </Inline>
                  }
                />
              )
            }
          />
        )
      }
      renderSelectedOption={(ref, toggleProjectList, isOpen) => (
        <StatusBarDropDownSelected
          dataUiAttributes={getDataUiActionAttribute(DataUiAction.OpenProjectMenu)}
          isExpanded={isOpen}
          onClick={toggleProjectList}
          renderMode={renderMode}
          onChevronTransitionEnd={handleTransitionEnd}
          ref={ref}
        >
          {selectedProjectName}
        </StatusBarDropDownSelected>
      )}
      tippyOptions={projectMenuTippyOptions}
    />
  );
};

ProjectSelect.displayName = 'ProjectSelect';
