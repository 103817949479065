import classNames from 'classnames';
import { useRef } from 'react';
import { HtmlPageTitle } from '../../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../../_shared/constants/applicationNames.ts';
import { EditorPaperContextProvider } from '../../../../../_shared/contexts/EditorPaperContext.tsx';
import { EditorScrollContextProvider } from '../../../../../_shared/contexts/EditorScrollContext.tsx';
import {
  DataUiAppName,
  DataUiElement,
  getDataUiAppNameAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { untitledContentItem } from '../../../../contentInventory/content/constants/uiConstants.ts';
import { EditedContentItemContextProvider } from '../../ContentComponent/context/ContentItemContext.tsx';
import { ContentItemEditorPane } from '../containers/ContentItemEditorPane.tsx';
import { ContentItemNotifications } from '../containers/ContentItemNotifications.tsx';
import { DiscussionsSidebar } from '../containers/comments/DiscussionsSidebar.tsx';
import { ResolvedCommentsSidebar } from '../containers/comments/ResolvedCommentsSidebar.tsx';
import { EditingActionsBar } from '../containers/editingActions/EditingActionsBar.tsx';
import { ContentItemSidebar } from '../containers/sidebar/ContentItemSidebar.tsx';
import { ContentItemChangeIntercomTracker } from './ContentItemChangeIntercomTracker.tsx';
import { ContentItemPreview } from './contentItemPreview/ContentItemPreview.tsx';
import { ContentItemSidebarPlaceholder } from './sidebar/ContentItemSidebarPlaceholder.tsx';

type Props = Readonly<{
  areQuickActionsAlwaysVisible: boolean;
  contentItemName: string | null;
  disabled: boolean;
  isPreviewOpened: boolean;
  shouldRenderSidebar: boolean;
}>;

export const ContentItemEditor = ({
  areQuickActionsAlwaysVisible,
  contentItemName,
  disabled,
  isPreviewOpened,
  shouldRenderSidebar,
}: Props) => {
  const contentItemCanvasRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className="canvas"
      {...getDataUiAppNameAttribute(DataUiAppName.ContentItemEditing)}
      data-hj-suppress=""
    >
      <HtmlPageTitle
        appName={AppNames.ContentItemEditor}
        customName={contentItemName || untitledContentItem}
      />
      <EditedContentItemContextProvider>
        <div className="canvas__workspace">
          <section
            className={classNames('canvas__content', 'content-item-editor', {
              'content-item-editor--quick-actions-always-visible': areQuickActionsAlwaysVisible,
              'content-item-editor--with-preview-opened': isPreviewOpened,
            })}
            {...getDataUiElementAttribute(DataUiElement.ContentItemEditor)}
          >
            <EditorPaperContextProvider>
              <EditorScrollContextProvider>
                {(updateScrollContext) => (
                  <>
                    <ContentItemNotifications />
                    {areQuickActionsAlwaysVisible && <EditingActionsBar />}
                    <ContentItemEditorPane
                      disabled={disabled}
                      hideQuickActions={areQuickActionsAlwaysVisible}
                      hasReducedSpacing={isPreviewOpened}
                      onInit={() =>
                        updateScrollContext(() => ({
                          isCandid: true,
                        }))
                      }
                      onScroll={(scrollTopPx) => {
                        updateScrollContext(() => ({ scrollTopPx }));
                      }}
                      onReposition={(pane) =>
                        updateScrollContext(() => ({
                          offsetTopPx: pane.offsetTop,
                        }))
                      }
                      ref={contentItemCanvasRef}
                    />
                    {isPreviewOpened && <ContentItemPreview ref={previewRef} />}
                    {shouldRenderSidebar && (
                      <>
                        <ContentItemSidebar
                          hasPlaceholder
                          outsideClickElementRefs={[contentItemCanvasRef, previewRef]}
                        />
                        <ContentItemSidebarPlaceholder />
                      </>
                    )}
                  </>
                )}
              </EditorScrollContextProvider>
            </EditorPaperContextProvider>
          </section>
          <ResolvedCommentsSidebar />
          <DiscussionsSidebar />
        </div>
      </EditedContentItemContextProvider>
      <ContentItemChangeIntercomTracker />
    </div>
  );
};
