import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { RefObject } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SelectorType, orderedSelectors } from '../constants/orderedSelectors.ts';
import { CollectionSelector } from '../containers/CollectionSelector.tsx';
import { ContentTypeSelector } from '../containers/ContentTypeSelector.tsx';
import { LanguageSelector } from '../containers/LanguageSelector.tsx';
import { NameInput } from '../containers/NameInput.tsx';
import { WorkflowSelector } from '../containers/WorkflowSelector.tsx';

interface INewContentItemFormProps {
  readonly firstInputRef: RefObject<HTMLInputElement>;
  readonly selectedValues: Record<SelectorType, Uuid | null>;
  readonly visibleSelectors: ReadonlySet<SelectorType>;
}

export const NewContentItemForm: React.FC<INewContentItemFormProps> = ({
  firstInputRef,
  selectedValues,
  visibleSelectors,
}) => (
  <Stack spacing={Spacing.XL} {...getDataUiElementAttribute(DataUiElement.NewContentItemForm)}>
    {orderedSelectors
      .filter((selectorType) => visibleSelectors.has(selectorType))
      .map((selectorType, index, allVisibleSelectors) => {
        const disabledTooltip = getDisabledSelectorTooltipText(selectorType, selectedValues);

        const headerIndex = index + 1;
        const isTheOnlySelector = allVisibleSelectors.length === 1;

        const autoFocusProps = index === 0 ? { inputRef: firstInputRef } : {};

        switch (selectorType) {
          case SelectorType.Name:
            return (
              <NameInput key={selectorType} autoFocus index={headerIndex} {...autoFocusProps} />
            );

          case SelectorType.Collection:
            return (
              <CollectionSelector
                key={selectorType}
                disabledTooltip={disabledTooltip}
                index={headerIndex}
                {...autoFocusProps}
              />
            );

          case SelectorType.Language:
            return <LanguageSelector key={selectorType} index={headerIndex} {...autoFocusProps} />;

          case SelectorType.Workflow:
            return (
              <WorkflowSelector
                key={selectorType}
                disabledTooltip={disabledTooltip}
                index={headerIndex}
                {...autoFocusProps}
              />
            );

          case SelectorType.ContentType:
            return (
              <ContentTypeSelector
                key={selectorType}
                disabledTooltip={disabledTooltip}
                index={isTheOnlySelector ? undefined : headerIndex}
                {...autoFocusProps}
              />
            );
        }
      })}
  </Stack>
);

NewContentItemForm.displayName = 'NewContentItemForm';

const getDisabledSelectorTooltipText = (
  selectorType: SelectorType,
  selectedValues: Record<SelectorType, Uuid | null>,
): string | undefined => {
  switch (selectorType) {
    case SelectorType.Name:
    case SelectorType.Language:
    case SelectorType.Collection:
      return undefined;

    case SelectorType.ContentType:
      return selectedValues.collection ? undefined : 'Select a collection first.';

    case SelectorType.Workflow: {
      if (!selectedValues.collection && selectedValues.contentType) {
        return 'Select a collection first.';
      }
      if (selectedValues.collection && !selectedValues.contentType) {
        return 'Select a content type first.';
      }
      if (!selectedValues.collection && !selectedValues.contentType) {
        return 'Select a collection and content type first.';
      }

      return undefined;
    }
  }
};
