import React, { memo } from 'react';
import {
  EditorProps,
  emptyPluginComposition,
  useEditor,
  usePluginComposition,
} from '../../editorCore/Editor.composition.tsx';
import { ApiLimitationsPlugin } from '../../plugins/apiLimitations/ApiLimitationsPlugin.tsx';
import { SimpleTextEditorLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { CompositionModeAdjustmentsPlugin } from '../../plugins/behavior/CompositionModeAdjustmentsPlugin.tsx';
import { ExternalUpdatePlugin } from '../../plugins/behavior/ExternalUpdatePlugin.tsx';
import { FocusPlugin } from '../../plugins/behavior/FocusPlugin.tsx';
import { ForceSelectionOnBlurPlugin } from '../../plugins/behavior/ForceSelectionOnBlurPlugin.tsx';
import { ForceSingleUnstyledLinePlugin } from '../../plugins/behavior/ForceSingleUnstyledLinePlugin.tsx';
import { OnChangePlugin } from '../../plugins/behavior/OnChangePlugin.tsx';
import { ClipboardPlugin } from '../../plugins/clipboard/ClipboardPlugin.tsx';
import { CommentsPlugin } from '../../plugins/comments/CommentsPlugin.tsx';
import {
  CustomInputHandlingPlugin,
  EnterKeyBehavior,
} from '../../plugins/customInputHandling/CustomInputHandlingPlugin.tsx';
import { DraftJsPlugin } from '../../plugins/draftJs/DraftJsPlugin.tsx';
import { KeyboardShortcutsPlugin } from '../../plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { textKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { InlineToolbarPlugin } from '../../plugins/toolbars/InlineToolbarPlugin.tsx';
import { UndoRedoPlugin } from '../../plugins/undoRedo/UndoRedoPlugin.tsx';
import { PlaceholderPlugin } from '../../plugins/visuals/PlaceholderPlugin.tsx';
import { StylesPlugin } from '../../plugins/visuals/StylesPlugin.tsx';
import { WrapperPlugin } from '../../plugins/visuals/WrapperPlugin.tsx';
import { AutoGeneratedUrlSlugPlugin } from './plugins/AutoGeneratedUrlSlugPlugin.tsx';
import { DisableCommentsForAutoGeneratedUrlSlugPlugin } from './plugins/DisableCommentsForAutoGeneratedUrlSlugPlugin.tsx';
import { UrlSlugInputStatusPlugin } from './plugins/UrlSlugInputStatusPlugin.tsx';

const useComposition = () =>
  usePluginComposition(
    emptyPluginComposition,
    DraftJsPlugin,
    PlaceholderPlugin,
    WrapperPlugin,
    CompositionModeAdjustmentsPlugin,
    AutoGeneratedUrlSlugPlugin,
    FocusPlugin,
    ApiLimitationsPlugin,
    KeyboardShortcutsPlugin,
    UndoRedoPlugin,
    StylesPlugin,
    InlineToolbarPlugin,
    ForceSelectionOnBlurPlugin,
    ForceSingleUnstyledLinePlugin,
    OnChangePlugin,
    CustomInputHandlingPlugin,
    CommentsPlugin,
    ClipboardPlugin,
    DisableCommentsForAutoGeneratedUrlSlugPlugin,
    UrlSlugInputStatusPlugin,
    ExternalUpdatePlugin,
  );

export type UrlSlugInputProps = EditorProps<typeof useComposition>;

export const UrlSlugInput: React.FC<UrlSlugInputProps> = memo((props) => {
  const composition = useComposition();

  return useEditor(composition, {
    ...props,
    enterKeyBehavior: EnterKeyBehavior.AlwaysSoftNewLine,
    keyCommandMap: textKeyCommandMap,
    limitations: SimpleTextEditorLimitations,
  });
});

UrlSlugInput.displayName = 'UrlSlugInput';
