import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { getCurrentProjectPlan } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';

export const areLanguageRolesEnabledForCurrentProject = (state: IStore): boolean =>
  getCurrentProjectPlan(state).features.areLanguageRolesEnabled;

export const areContentCollectionsEnabledForCurrentProjectPlan = (state: IStore): boolean =>
  getCurrentProjectPlan(state).features.areContentCollectionsEnabled;

export const areContentCollectionsPermissionsEnabledForCurrentProjectPlan = (
  state: IStore,
): boolean => getCurrentProjectPlan(state).features.areContentCollectionsPermissionsEnabled;

export const isAuditLogEnabledForCurrentProjectPlan = (state: IStore): boolean =>
  getCurrentProjectPlan(state).features.auditLogEnabled;

export const areMultipleWorkflowsEnabledForCurrentProject = (state: IStore): boolean =>
  getCurrentProjectPlan(state).features.areMultipleWorkflowsEnabled;

export const areSpacesEnabledForCurrentProject = (state: IStore): boolean => {
  const { maxProjectSpaces } = getCurrentProjectPlan(state).features;
  return maxProjectSpaces === null || maxProjectSpaces > 0;
};

export const isAssetCollectionMandatoryForCurrentSubscription = (state: IStore): boolean =>
  state.sharedApp.subscriptionProperties.AssetCollectionMandatory === 'Enabled';

export const isAssetCollectionMandatoryForCurrentSubscriptionOrProject = (state: IStore): boolean =>
  state.sharedApp.projectProperties.AssetCollectionMandatory === 'Enabled' ||
  isAssetCollectionMandatoryForCurrentSubscription(state);

export const isSignalRAlternativeTransportEnabledForCurrentProject = (state: IStore): boolean =>
  state.sharedApp.projectProperties.SignalRAlternativeTransport === 'Enabled';

export const areSpacesInCollectionsEnabled = (state: IStore): boolean =>
  state.sharedApp.generalProperties.enableSpacesInCollections ||
  state.sharedApp.projectProperties.SpacesInCollections === 'Enabled';

export const areSpacesInDeliverEnabled = (state: IStore): boolean =>
  areSpacesInCollectionsEnabled(state) && state.sharedApp.projectFeatureFlags.SpacesInDeliver;
