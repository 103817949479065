import { Button, ButtonStyle } from '@kontent-ai/component-library/Button';
import { IconName, Icons } from '@kontent-ai/component-library/Icons';
import { BaseColor, BorderRadius, px } from '@kontent-ai/component-library/tokens';
import { MouseEventHandler, forwardRef, useContext } from 'react';
import styled from 'styled-components';
import { EditorScrollContext } from '../../../../../../../_shared/contexts/EditorScrollContext.tsx';
import {
  DataUiAction,
  getDataAttributeProps,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { useIsWebSpotlightFixedQuickActionsEnabled } from '../../../hooks/useIsWebSpotlightFixedQuickActionsEnabled.tsx';

type EditingActionsButtonProps = {
  readonly buttonStyle: ButtonStyle;
  readonly dataUiAction?: DataUiAction;
  readonly disabled?: boolean;
  readonly iconName: IconName;
  readonly isActive?: boolean;
  readonly onClick?: MouseEventHandler<HTMLElement>;
  readonly text: string;
};

// Created using styled-components so that `as` prop can be utilized and the underlying DOM element is not changed
// Might break tippy for example
const Wrapper = styled.div``;

const ScrolledButtonGlow = styled.div`
  position: relative;
  border-radius: ${px(BorderRadius.Pill)};
  background-color: ${BaseColor.White};

  &:after {
    content: "";
    position: absolute;
    left: 0;
    z-index: -1;
    box-shadow: 0 0 40px 10px ${BaseColor.White};
    width: 100%;
    height: 100%;
    border-radius: ${px(BorderRadius.Pill)};
  }
`;

export const EditingActionsButton = forwardRef<HTMLButtonElement, EditingActionsButtonProps>(
  (props, forwardedRef) => {
    const viewContext = useContext(EditorScrollContext);
    const isWebSpotlightFixedQuickActionsEnabled = useIsWebSpotlightFixedQuickActionsEnabled();

    return (
      <Wrapper
        as={
          viewContext.isPaperScrolledOut && !isWebSpotlightFixedQuickActionsEnabled
            ? ScrolledButtonGlow
            : undefined
        }
      >
        <Button
          activated={props.isActive}
          disabled={props.disabled}
          onClick={props.onClick}
          ref={forwardedRef}
          buttonStyle={props.buttonStyle ?? 'tertiary'}
          {...getDataAttributeProps(props)}
        >
          <Button.Icon icon={Icons[props.iconName]} />
          {props.text}
        </Button>
      </Wrapper>
    );
  },
);

EditingActionsButton.displayName = 'EditingActionsButton';
