import { ContentState } from 'draft-js';
import { ContentItemId } from '../../../../_shared/models/ContentItemId.ts';
import { convertContentToServerModel } from '../../../../applications/richText/utils/serverModel/editorServerModelUtils.ts';
import { AiActionName } from '../AiActionName.type.ts';
import {
  DraftJsOperationInput,
  ItemVariantIdOperationInput,
  StringOperationInput,
} from '../AiServerModels.input.type.ts';
import {
  ElementOperationTrackingData,
  MultipleInputsOperationParamsModelBase,
} from '../AiServerModels.params.ts';
import {
  CumulatedOperationResponseMessageTemplate,
  ErrorOperationResponseMessage,
  OperationResponseMessage,
  isErrorOperationMessage,
} from '../AiServerModels.response.ts';
import {
  CumulatedDraftJsResult,
  isCumulatedDraftJsOperationResponseMessage,
} from '../AiServerModels.result.ts';

export type GenerateContentByInstructionParams =
  MultipleInputsOperationParamsModelBase<ElementOperationTrackingData> & {
    readonly actionName: AiActionName.GenerateContentByInstruction;
    readonly inputs: {
      readonly content: DraftJsOperationInput;
      readonly elementId: StringOperationInput;
      readonly elementName: StringOperationInput | null;
      readonly instruction: StringOperationInput;
      readonly itemName: StringOperationInput | null;
      readonly itemVariantId: ItemVariantIdOperationInput;
      readonly languageCodename: StringOperationInput | null;
      readonly languageName: StringOperationInput | null;
    };
  };

type GenerateContentByInstructionInputParams = {
  readonly contentState: ContentState;
  readonly elementId: Uuid;
  readonly elementName: string | null;
  readonly instruction: string;
  readonly itemName: string | null;
  readonly itemVariantId: ContentItemId;
  readonly languageCodename: string | null;
  readonly languageName: string | null;
};

export const createGenerateContentByInstructionParams = (
  {
    contentState,
    elementId,
    elementName,
    instruction,
    itemName,
    itemVariantId,
    languageCodename,
    languageName,
  }: GenerateContentByInstructionInputParams,
  trackingData: ElementOperationTrackingData,
): GenerateContentByInstructionParams => ({
  actionName: AiActionName.GenerateContentByInstruction,
  type: 'multiple-inputs-request-v1',
  inputs: {
    content: {
      type: 'draft-js',
      value: convertContentToServerModel(contentState),
    },
    elementId: {
      type: 'string',
      value: elementId,
    },
    elementName: {
      type: 'string',
      value: elementName ?? '',
    },
    instruction: {
      type: 'string',
      value: instruction,
    },
    itemName: {
      type: 'string',
      value: itemName ?? '',
    },
    itemVariantId: {
      type: 'item-variant-id',
      value: {
        itemId: itemVariantId.itemId,
        variantId: itemVariantId.variantId,
      },
    },
    languageCodename: {
      type: 'string',
      value: languageCodename ?? '',
    },
    languageName: {
      type: 'string',
      value: languageName ?? '',
    },
  },
  trackingData,
});

export type GenerateContentByInstructionResponseMessage =
  CumulatedOperationResponseMessageTemplate<CumulatedDraftJsResult>;

export const isGenerateContentByInstructionMessage = (
  message: OperationResponseMessage,
): message is GenerateContentByInstructionResponseMessage | ErrorOperationResponseMessage =>
  isCumulatedDraftJsOperationResponseMessage(message) || isErrorOperationMessage(message);
