import { ReactNode, useEffect } from 'react';
import { AccessibleLoader } from '../../../../_shared/components/AccessibleLoader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { calendarArrangementStorage } from '../../../../localStorages/calendarArrangementStorage.ts';
import { calendarArrangementSelected } from '../actions/calendarActions.ts';

type Props = {
  readonly children: ReactNode;
};

export const EnsureSelectedArrangementForCalendar = (props: Props) => {
  const selectedArrangement = useSelector((s) => s.calendarApp.selectedArrangement);
  const arrangementInLocalStorage = calendarArrangementStorage.load();
  const isStateEnsured = selectedArrangement === arrangementInLocalStorage;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(calendarArrangementSelected(arrangementInLocalStorage));
  }, [arrangementInLocalStorage]);

  return isStateEnsured ? (
    props.children
  ) : (
    <AccessibleLoader screenReaderText="Loading editorial calendar." />
  );
};
