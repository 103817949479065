import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { MouseEventHandler } from 'react';

type Props = {
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly isCompact?: boolean;
  readonly onClick?: MouseEventHandler<HTMLDivElement>;
};

export const BarItemActions = ({ children, className, isCompact, onClick }: Props) => (
  <Inline
    align="center"
    className={className}
    onClick={onClick}
    spacing={isCompact ? Spacing.S : Spacing.L}
    noWrap
  >
    {children}
  </Inline>
);
