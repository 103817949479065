import { subDays } from 'date-fns';
import React, { useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { DateTime } from '../../../../_shared/models/DateTime.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { getCurrentDate } from '../../../missionControl/calendar/utils/calendarUtils.ts';
import {
  clearAuditLogFilter,
  fromDateChanged,
  toDateChanged,
} from '../actions/auditLogFilterActions.ts';
import { searchAuditEvents } from '../actions/thunkAuditLogActions.ts';
import { AuditLogFilter as AuditLogFilterComponent } from '../components/AuditLogFilter.tsx';
import {
  AuditLogFilterDebounceTime,
  SupportedAuditLogHistoryInDays,
} from '../constants/auditLogProperties.ts';
import {
  IAuditLogFilter,
  areFiltersEqual,
  emptyAuditLogFilter,
} from '../models/AuditEventFilter.ts';

export const AuditLogFilter: React.FC = () => {
  const auditLogFilter = useSelector((state: IStore) => state.auditLogApp.auditLogFilter);
  const dispatch = useDispatch();
  const lastUsedFilterRef = useRef<IAuditLogFilter>(emptyAuditLogFilter);

  const searchEventsDebounced = useDebouncedCallback((filter: IAuditLogFilter) => {
    lastUsedFilterRef.current = filter;
    dispatch(searchAuditEvents());
  }, AuditLogFilterDebounceTime);

  useEffect(() => {
    const hasFilterChanged = !areFiltersEqual(auditLogFilter, lastUsedFilterRef.current);

    if (hasFilterChanged && auditLogFilter.isFilterValid) {
      searchEventsDebounced(auditLogFilter);
    }
    return searchEventsDebounced.cancel;
  }, [searchEventsDebounced, auditLogFilter]);

  const currentDate = getCurrentDate();

  return (
    <AuditLogFilterComponent
      currentFilterState={auditLogFilter}
      maxCalendarDate={currentDate}
      minCalendarDate={subDays(currentDate, SupportedAuditLogHistoryInDays)}
      onClearFilter={() => dispatch(clearAuditLogFilter())}
      onFromDateChange={(datetime: DateTime, isFromDateBeforeToDate: boolean) =>
        dispatch(fromDateChanged(datetime, isFromDateBeforeToDate))
      }
      onToDateChange={(datetime: DateTime, isFromDateBeforeToDate: boolean) =>
        dispatch(toDateChanged(datetime, isFromDateBeforeToDate))
      }
    />
  );
};

AuditLogFilter.displayName = 'AuditLogFilter';
