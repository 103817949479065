import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import {
  BorderRadius,
  Color,
  Spacing,
  gridUnit,
  px,
  shadowFocusStyles,
  transition150,
} from '@kontent-ai/component-library/tokens';
import { useHover, usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import React from 'react';
import styled, { css } from 'styled-components';
import { IHorizontalStackedBarData } from '../../../../missionControl/types/IHorizontalStackedBarData.type.ts';

type StyledChartSectionProps = Readonly<{
  $color: Color;
  flexGrow: number;
  isClickable: boolean;
  isFocusVisible: boolean;
  isHighlighted: boolean;
}>;

const StyledChartSection = styled.div.attrs<StyledChartSectionProps>(({ flexGrow }) => ({
  style: {
    flexGrow,
  },
}))<StyledChartSectionProps>`
  background-color: ${(props) => props.$color};
  flex-shrink: 1;
  flex-basis: ${px(Spacing.L)};
  transition: margin ${transition150};
  transition-property: border-radius, margin;
  
  ${({ isClickable }) =>
    isClickable &&
    css`
    cursor: pointer;
  `};
  ${({ isFocusVisible }) => isFocusVisible && shadowFocusStyles};
  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
    margin-bottom: ${px(Spacing.L * -0.5)};
    margin-top: ${px(Spacing.L * -0.5)};
    border-radius: ${px(gridUnit * 0.75)};
    z-index: 1; // To make the segment pop over the neighbor
  `};

  &:first-of-type {
    border-top-left-radius: ${px(BorderRadius.M)};
    border-bottom-left-radius: ${px(BorderRadius.M)};
  }

  &:last-of-type {
    border-top-right-radius: ${px(BorderRadius.M)};
    border-bottom-right-radius: ${px(BorderRadius.M)};
  }
`;

type ChartSectionProps = Readonly<{
  item: IHorizontalStackedBarData;
  onItemClick?: (itemId: string, item: IHorizontalStackedBarData) => void;
  tooltipText: string;
  total: number;
}>;

export const ChartSection: React.FC<ChartSectionProps> = ({
  item,
  onItemClick,
  tooltipText,
  total,
  ...props
}) => {
  const { hoverProps, isHovered } = useHover({});

  const { isFocusVisible, focusProps } = useOurFocusRing();

  const { pressProps } = usePress({
    onPress: () => onItemClick?.(item.id, item),
  });

  const isClickable = !!onItemClick;

  if (item.value <= 0) {
    return null;
  }

  return (
    <Tooltip placement="top" tooltipText={tooltipText}>
      <StyledChartSection
        aria-label={item.label}
        aria-valuemax={total}
        aria-valuenow={item.value}
        $color={item.color}
        flexGrow={item.value}
        isClickable={isClickable}
        isFocusVisible={isFocusVisible}
        isHighlighted={isHovered || isFocusVisible}
        role="meter"
        tabIndex={0}
        {...mergeProps(hoverProps, focusProps, pressProps, props)}
      />
    </Tooltip>
  );
};
