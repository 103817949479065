import { ItemsRequestTrigger } from '../../../../_shared/utils/scrollTableUtils.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { SearchMethod } from '../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import {
  ContentStatusContentItems_Init,
  ContentStatusContentItems_Load_Started,
  ContentStatusContentItems_Load_Success,
} from '../constants/contentStatusActionTypes.ts';

export const contentStatusContentItemsInit = () =>
  ({
    type: ContentStatusContentItems_Init,
  }) as const;

export const createTokenizedContentStatusItemsLoadingStarted =
  (raceConditionToken: Uuid) => (requestTrigger: ItemsRequestTrigger) =>
    ({
      type: ContentStatusContentItems_Load_Started,
      payload: {
        raceConditionToken,
        requestTrigger,
      },
    }) as const;

type ListingItemsLoadedActionPayload = {
  readonly contentItems: ReadonlyArray<IListingContentItem>;
  readonly continuationToken: string | null;
  readonly requestTrigger: ItemsRequestTrigger;
  readonly searchMethod: SearchMethod;
};

export const contentStatusItemsLoaded = (payload: ListingItemsLoadedActionPayload) =>
  ({
    type: ContentStatusContentItems_Load_Success,
    payload,
  }) as const;

export type TokenizedContentStatusItemsLoadingStartedActionType = ReturnType<
  ReturnType<typeof createTokenizedContentStatusItemsLoadingStarted>
>;
