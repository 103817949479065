import { useAttachRef } from '@kontent-ai/hooks';
import { useLink } from '@react-aria/link';
import { mergeProps } from '@react-aria/utils';
import { LinkProps } from '@react-types/link';
import React from 'react';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { RouterLinkProps } from '../../Anchor/Anchor.tsx';
import { A11yLabelingPropsExtension } from '../../_utils/ariaLabelingProps.ts';
import { mergeAriaDescribedBy } from '../../_utils/ariaUtils.ts';
import { TooltipPropsExtension } from '../../_utils/propPrefabs.ts';
import { ButtonBadge } from '../ButtonBadge.tsx';
import { ButtonIcon } from '../ButtonIcon.tsx';
import { IBaseButtonProps } from '../components/BaseButton.ts';
import { BaseButtonComponent } from '../components/BaseButtonComponent.tsx';
import { StyledRouterLinkButton } from '../components/StyledButton.tsx';

type RouterLinkButtonProps = TooltipPropsExtension &
  Pick<IBaseButtonProps, 'buttonStyle' | 'size' | 'buttonDisplay' | 'disableTabulator'> &
  Pick<RouterLinkProps, 'to'> &
  A11yLabelingPropsExtension &
  LinkProps & {
    readonly className?: string;
  };

const RouterLinkButtonWithRef = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<RouterLinkButtonProps>
>(
  (
    {
      buttonDisplay = 'inline',
      buttonStyle,
      children,
      className,
      size = 'medium',
      to,
      tooltipMaxGridUnitsWidth,
      tooltipPlacement = 'top',
      tooltipShortcuts,
      tooltipText,
      ...otherProps
    },
    forwardedRef,
  ) => {
    const { refObject, refToForward } = useAttachRef<HTMLAnchorElement>(forwardedRef);
    const {
      linkProps: { 'aria-describedby': ariaDescribedBy = '', ...linkProps },
    } = useLink(otherProps, refObject);

    return (
      <BaseButtonComponent
        tooltipText={tooltipText}
        tooltipPlacement={tooltipPlacement}
        tooltipMaxGridUnitsWidth={tooltipMaxGridUnitsWidth}
        tooltipShortcuts={tooltipShortcuts}
        buttonStyle={buttonStyle}
        buttonDisplay={buttonDisplay}
        size={size}
        renderButtonComponent={({
          'aria-describedby': injectedAriaDescribedBy,
          ...injectedProps
        }) => (
          <StyledRouterLinkButton
            aria-describedby={mergeAriaDescribedBy(injectedAriaDescribedBy, ariaDescribedBy)}
            className={className}
            innerRef={refToForward}
            to={to}
            {...mergeProps(linkProps, injectedProps)}
            {...getDataUiComponentAttribute(RouterLinkButtonWithRef)}
          />
        )}
      >
        {children}
      </BaseButtonComponent>
    );
  },
);

RouterLinkButtonWithRef.displayName = 'RouterLinkButton';

const routerLinkButtonComposition = {
  Badge: ButtonBadge,
  Icon: ButtonIcon,
} as const;

export const RouterLinkButton = Object.assign(RouterLinkButtonWithRef, routerLinkButtonComposition);
