import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React, { MouseEventHandler, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

const StyledBarItemContent = styled(Inline)<{ readonly $flexFactor?: number }>`
  ${Typography.LabelLarge};
  color: ${colorTextDefault};
  height: 100%;
  min-width: 0;
  width: 100%;
  ${({ $flexFactor }) => ($flexFactor ? css`flex: ${$flexFactor}` : '')}
`;

type Props = {
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly dataUiElement?: DataUiElement;
  readonly flexFactor?: number;
  readonly isCompact?: boolean;
  readonly onClick?: MouseEventHandler<HTMLDivElement>;
  readonly title?: string;
};

export const BarItemContent = forwardRef<HTMLDivElement, Props>(
  ({ children, className, dataUiElement, flexFactor, isCompact, onClick, title }: Props, ref) => (
    <StyledBarItemContent
      align="center"
      className={classNames('bar-item__title-pane', className)}
      spacing={isCompact ? Spacing.S : Spacing.L}
      noWrap
      onClick={onClick}
      ref={ref}
      title={title}
      $flexFactor={flexFactor}
      {...(dataUiElement && getDataUiElementAttribute(dataUiElement))}
    >
      {children}
    </StyledBarItemContent>
  ),
);

BarItemContent.displayName = 'BarItemContent';
