import { assert, Collection } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { hasActiveVariantCapability } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { hasCapabilityInLanguage } from '../../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { LoadListingContentItemsByIdsAction } from '../../../../../../data/actions/thunks/listingContentItems/loadListingContentItemsByIds.ts';
import { createActiveCapabilitiesForVariantFromServerModel } from '../../../../../../data/models/activeCapabilities.ts';
import { getItemDataFromJS } from '../../../../../../data/models/listingContentItems/IListingItemData.ts';
import { getListingVariantDataFromJS } from '../../../../../../data/models/listingContentItems/IListingVariantData.ts';
import { IListingContentItemServerModel } from '../../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { YourTasks_LoadRelatedItems_BatchReceived } from '../../constants/yourTasksActionTypes.ts';
import { IYourTasksListingContentItem } from '../../models/IYourTasksListingContentItem.type.ts';
import { ItemIdsByVariantIds } from '../../types/ItemIdsByVariantIds.type.ts';

const batchReceived = (items: ReadonlyArray<IYourTasksListingContentItem>) =>
  ({
    type: YourTasks_LoadRelatedItems_BatchReceived,
    payload: {
      items,
    },
  }) as const;

export type YourTasksRelatedItemsActionsType = ReturnType<typeof batchReceived>;

interface Deps {
  readonly loadListingContentItemsByIds: LoadListingContentItemsByIdsAction;
}

export const createLoadRelatedItems =
  ({ loadListingContentItemsByIds }: Deps) =>
  (itemIdsByVariantIds: ItemIdsByVariantIds, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const hasAccessToVariant = createHasAccessToVariant(getState());

    const itemsForVariantsRequests = Collection.getEntries(itemIdsByVariantIds)
      .filter(([variantId]) => hasAccessToVariant(variantId))
      .map(([variantId, itemIds]) =>
        dispatch(loadListingContentItemsByIds(variantId, [...itemIds], abortSignal)),
      );

    const itemsForVariants = await Promise.all(itemsForVariantsRequests);

    const viewableItems = itemsForVariants
      .flat()
      .map(createYourTasksListingContentItemFromServerModel)
      .filter(canViewItem);

    dispatch(batchReceived(viewableItems));
  };

const createHasAccessToVariant =
  (state: IStore) =>
  (variantId: Uuid): boolean =>
    hasCapabilityInLanguage(state, Capability.ViewContent, variantId);

const canViewItem = (item: IYourTasksListingContentItem): boolean =>
  hasActiveVariantCapability(ActiveCapabilityType.ViewContent, item);

const createYourTasksListingContentItemFromServerModel = (
  serverModel: IListingContentItemServerModel,
): IYourTasksListingContentItem => {
  assert(serverModel.variant, () => 'Item must have a variant!');
  return {
    activeCapabilities: createActiveCapabilitiesForVariantFromServerModel(
      serverModel.activeCapabilities,
    ),
    item: getItemDataFromJS(serverModel.item),
    variant: getListingVariantDataFromJS(serverModel.variant),
  };
};
