import { mergeProps } from '@react-aria/utils';
import React from 'react';
import { BaseAvatarComponent, IBaseAvatarProps } from '../components/BaseAvatarComponent.tsx';
import { StyledLinkAvatar } from '../components/StyledAvatar.tsx';

export interface ILinkAvatarProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    IBaseAvatarProps {
  readonly activated?: boolean;
}

export const LinkAvatar = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<ILinkAvatarProps>
>(
  (
    {
      activated,
      backgroundGradient,
      boxShadow,
      image,
      initials,
      label,
      size,
      tooltipPlacement,
      children, // Just to omit them from linkProps
      ...linkProps
    },
    forwardedRef,
  ) => {
    return (
      <BaseAvatarComponent
        renderContainer={({ isFocusVisible, ...injectedProps }) => (
          <StyledLinkAvatar
            $activated={activated}
            $size={size}
            aria-label={label}
            boxShadow={boxShadow}
            isFocusVisible={isFocusVisible}
            ref={forwardedRef}
            {...mergeProps(linkProps, injectedProps)}
          />
        )}
        {...{
          backgroundGradient,
          boxShadow,
          image,
          initials,
          label,
          size,
          tooltipPlacement,
          tooltipText: label,
        }}
      />
    );
  },
);

LinkAvatar.displayName = 'LinkAvatar';
