import { BlockRendererFn, DraftBlockRenderConfig } from 'draft-js';
import Immutable from 'immutable';
import React, { useCallback, useMemo, useState } from 'react';
import { ContentOverlayPlaceholder } from '../../../itemEditor/features/LinkedItems/components/ContentOverlay.tsx';
import { SimpleLinkedItem } from '../../../itemEditor/features/LinkedItems/containers/SimpleLinkedItem.tsx';
import { EditorSizeHandler } from '../../components/utility/EditorSizeHandler.tsx';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None, WithoutProps } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import { Apply, PluginState, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import {
  getContentOverlayClass,
  getContentOverlayId,
} from '../../editorCore/utils/editorComponentUtils.ts';
import { BaseBlockType, BlockType } from '../../utils/blocks/blockType.ts';
import { getBaseBlockType } from '../../utils/blocks/editorBlockGetters.ts';
import { IEditorBlockProps } from '../../utils/blocks/editorBlockUtils.ts';
import { getContentModuleBlocks } from '../../utils/general/editorContentGetters.ts';
import { CustomBlockWrapper } from '../customBlocks/components/CustomBlockWrapper.tsx';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { GetBaseBlockRenderMap } from '../draftJs/DraftJsPlugin.type.ts';
import { BaseBlockRenderMap, mergeBlockRenderMaps } from '../draftJs/utils/draftJsEditorUtils.ts';
import { OnHighlightedBlocksChanged, StylesPlugin } from '../visuals/StylesPlugin.tsx';
import { LinkedItemsPlugin } from './LinkedItemsPlugin.tsx';
import { getModularContentItemId } from './api/editorModularUtils.ts';
import { ExpandedModularContents } from './components/expanded/ExpandedModularContents.tsx';

export type DisplayLinkedItemsPlugin = DraftJsEditorPlugin<None, None, None, None, [StylesPlugin]>;

type BlockAction = (blockKey: string) => void;

type LinkedItemBlockCustomProps = Pick<PluginState<LinkedItemsPlugin>, 'getEditorId'> & {
  readonly blockCollapsed: BlockAction;
  readonly blockExpanded: BlockAction;
};

const LinkedItemBlock: React.FC<IEditorBlockProps<LinkedItemBlockCustomProps>> = (props) => {
  const {
    block,
    blockProps: { blockCollapsed, blockExpanded, getEditorId },
  } = props;

  const contentItemId = getModularContentItemId(block);
  if (!contentItemId) {
    return null;
  }

  const blockKey = block.getKey();

  return (
    <CustomBlockWrapper className="rte__linked-item" key={blockKey}>
      <SimpleLinkedItem
        contentItemId={contentItemId}
        onExpandedChanged={(isExpanded) =>
          isExpanded ? blockExpanded(blockKey) : blockCollapsed(blockKey)
        }
        renderExpanded={() => (
          <ContentOverlayPlaceholder overlayId={getContentOverlayId(getEditorId(), blockKey)} />
        )}
      />
    </CustomBlockWrapper>
  );
};

type EditorWithLinkedItemsProps = {
  readonly blockCollapsed: BlockAction;
  readonly blockExpanded: BlockAction;
};

const EditorWithLinkedItems: DecoratedEditor<
  WithoutProps<DisplayLinkedItemsPlugin>,
  EditorWithLinkedItemsProps
> = ({ baseRender, blockCollapsed, blockExpanded, state }) => {
  const {
    getEditorId,
    editorProps: { blockRendererFn: baseBlockRendererFn },
  } = state;

  const blockProps: LinkedItemBlockCustomProps = useMemo(
    () => ({
      blockCollapsed,
      blockExpanded,
      getEditorId,
    }),
    [blockCollapsed, blockExpanded, getEditorId],
  );

  const blockRendererFn: BlockRendererFn<LinkedItemBlockCustomProps> = useCallback(
    (block) => {
      const baseBlockType = getBaseBlockType(block);
      if (baseBlockType === BlockType.ContentModule) {
        return {
          component: LinkedItemBlock,
          props: blockProps,
          editable: false,
        };
      }

      return baseBlockRendererFn?.(block) ?? null;
    },
    [baseBlockRendererFn, blockProps],
  );

  const stateWithLinkedItems: PluginState<DisplayLinkedItemsPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      blockRendererFn,
    },
  };

  return baseRender(stateWithLinkedItems);
};

EditorWithLinkedItems.displayName = 'EditorWithLinkedItems';

const linkedItemRenderMap: BaseBlockRenderMap = Immutable.Map<
  BaseBlockType,
  DraftBlockRenderConfig
>({
  [BaseBlockType.ContentModule]: {
    element: 'div',
  },
});

const getBaseBlockRenderMap: Decorator<GetBaseBlockRenderMap> = (baseGetBaseBlockRenderMap) => () =>
  mergeBlockRenderMaps(baseGetBaseBlockRenderMap(), linkedItemRenderMap);

export const DisplayLinkedItemsPlugin: PluginComponent<DisplayLinkedItemsPlugin> = (props) => {
  const { disabled } = props;

  const [highlightedBlockKeys, setHighlightedBlockKeys] = useState<ReadonlySet<string>>(new Set());

  const onHighlightedBlocksChanged: Decorator<OnHighlightedBlocksChanged> = useCallback(
    (baseOnHighlightedBlocksChanged) => (newHighlightedBlockKeys) => {
      setHighlightedBlockKeys(newHighlightedBlockKeys);
      baseOnHighlightedBlocksChanged(newHighlightedBlockKeys);
    },
    [],
  );

  const [expandedBlockKeys, setExpandedBlockKeys] = useState<ReadonlySet<string>>(new Set());

  const blockExpanded = useCallback((blockKey: string): void => {
    setExpandedBlockKeys((prev) => new Set([...prev, blockKey]));
  }, []);

  const blockCollapsed = useCallback((blockKey: string): void => {
    setExpandedBlockKeys((prev) => new Set([...prev].filter((x) => x !== blockKey)));
  }, []);

  const renderOverlays: Decorator<Render<DisplayLinkedItemsPlugin>> = useCallback(
    (baseRenderOverlays) => (state) => {
      const { editorState, getEditorId } = state;
      const content = editorState.getCurrentContent();
      const contentModuleBlocks = getContentModuleBlocks(content);
      const editorId = getEditorId();

      return (
        <>
          {baseRenderOverlays(state)}
          <ExpandedModularContents
            contentModuleBlocks={contentModuleBlocks}
            disabled={!!disabled}
            editorId={editorId}
            expandedBlockKeys={expandedBlockKeys}
            highlightedBlockKeys={highlightedBlockKeys}
            isDragging={false}
          />
          <EditorSizeHandler
            contentOverlayClassName={
              contentModuleBlocks.length > 0 ? getContentOverlayClass(editorId) : undefined
            }
            editorRef={state.getWrapperRef()}
          />
        </>
      );
    },
    [disabled, expandedBlockKeys, highlightedBlockKeys],
  );

  const render: Decorator<Render<DisplayLinkedItemsPlugin>> = useCallback(
    (baseRender) => (state) => (
      <EditorWithLinkedItems
        baseRender={baseRender}
        blockCollapsed={blockCollapsed}
        blockExpanded={blockExpanded}
        state={state}
      />
    ),
    [blockCollapsed, blockExpanded],
  );

  const apply: Apply<DisplayLinkedItemsPlugin> = useCallback(
    (state) => {
      state.getBaseBlockRenderMap.decorate(getBaseBlockRenderMap);
      state.onHighlightedBlocksChanged.decorate(onHighlightedBlocksChanged);
      state.render.decorate(render);
      state.renderOverlays.decorate(renderOverlays);

      return {};
    },
    [onHighlightedBlocksChanged, render, renderOverlays],
  );

  return useEditorWithPlugin(props, { apply });
};
