import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { showErrorNotification } from '../../../../../../../_shared/actions/sharedActions.ts';
import { trackUserEventWithData } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventTypes } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import {
  ServerApiErrorCode,
  tryParseApiError,
} from '../../../../../../../repositories/serverModels/ServerApiError.ts';
import { ContentItemCodenameStatus } from '../../../../../models/contentItem/ContentItemCodenameStatus.ts';
import {
  contentItemCodenameChanged,
  contentItemCodenameEditorClosed,
  contentItemCodenameEditorErrorStatusCleared,
  contentItemCodenameEditorOpened,
  contentItemCodenameNotUniqueError,
  deactivateContentItemEditingAction,
} from '../../../actions/contentItemEditingActions.ts';
import { saveContentItemCodenameToServer } from '../../../actions/thunkContentItemEditingActions.ts';
import { LeafActionFormProps } from '../../../components/editingActions/EditingLeafActionForm.tsx';
import { CodenameForm as CodenameFormComponent } from '../../../components/editingActions/leafActionForms/CodenameForm.tsx';
import {
  CodenameChangeMayBreakYourAppMessage,
  CodenameEditingPossibleOnlyInDefaultLanguage,
  CodenameIsAutoGeneratedMessage,
  CodenameIsNotUniqueErrorMessage,
  CodenameSavingFailedErrorMessage,
} from '../../../constants/uiConstants.ts';
import {
  getContentItemSavingOriginFromRoute,
  isContentItemNameAndCodenameEditingAllowed,
} from '../../../utils/itemEditingUtils.ts';

export const CodenameForm = (props: LeafActionFormProps) => {
  const dispatch = useDispatch();

  const codename = useSelector((s) => s.contentApp.editedContentItem?.codename ?? '');

  const footnoteMessage = useSelector((s) =>
    s.contentApp.editorUi.codenameEditor.isCodenameAutogenerated
      ? CodenameIsAutoGeneratedMessage
      : null,
  );

  const friendlyWarningMessage = useSelector((s) =>
    s.contentApp.editorUi.codenameEditor.isCodenameAutogenerated
      ? undefined
      : CodenameChangeMayBreakYourAppMessage,
  );

  const isBeingSaved = useSelector(
    (s) => s.contentApp.editorUi.codenameEditor.status === ContentItemCodenameStatus.IsBeingSaved,
  );

  const isCodenameUnique = useSelector(
    (s) =>
      s.contentApp.editorUi.codenameEditor.status !==
      ContentItemCodenameStatus.CodenameNotUniqueError,
  );

  const disabledEditingMessage = useSelector((s) =>
    isContentItemNameAndCodenameEditingAllowed(
      s.contentApp.contentItemVariants,
      s.data.languages.defaultLanguage.id,
      getSelectedLanguageIdOrThrow(s),
    )
      ? null
      : CodenameEditingPossibleOnlyInDefaultLanguage,
  );

  useEffect(() => {
    dispatch(contentItemCodenameEditorOpened());

    return () => {
      dispatch(contentItemCodenameEditorClosed());
    };
  }, []);

  const editedContentItem = useSelector((s) => s.contentApp.editedContentItem);
  const usedSearchMethod = useSelector((s) => s.data.listingContentItems.usedSearchMethod);
  const filter = useSelector((s) => s.contentApp.listingUi.filter);
  const orderBy = useSelector((s) => s.contentApp.listingUi.orderBy);
  const isCodenameItemEditorVisible = useSelector(
    (s) => s.contentApp.editorUi.codenameEditor.isVisible,
  );
  const location = useLocation();
  const contentItemSavingOrigin = getContentItemSavingOriginFromRoute(location.pathname);

  return (
    editedContentItem && (
      <CodenameFormComponent
        codename={codename}
        isCodenameUnique={isCodenameUnique}
        disabledEditingMessage={disabledEditingMessage}
        footnoteMessage={footnoteMessage}
        friendlyWarningMessage={friendlyWarningMessage}
        isBeingSaved={isBeingSaved}
        onSave={async (newCodename, onSaved): Promise<void> => {
          try {
            await dispatch(
              saveContentItemCodenameToServer(
                editedContentItem.id,
                editedContentItem.lastModifiedAt,
                newCodename,
                usedSearchMethod,
                filter,
                orderBy,
                contentItemSavingOrigin,
              ),
            );
            onSaved();
          } catch (error) {
            const apiError = tryParseApiError(error);
            if (apiError?.code === ServerApiErrorCode.CodenameIsNotUnique) {
              dispatch(contentItemCodenameNotUniqueError());

              if (!isCodenameItemEditorVisible) {
                dispatch(showErrorNotification(CodenameIsNotUniqueErrorMessage));
              }
            } else {
              dispatch(showErrorNotification(CodenameSavingFailedErrorMessage));
              throw error;
            }
          }
        }}
        onChange={() => dispatch(contentItemCodenameChanged())}
        onDiscardChanges={() => dispatch(contentItemCodenameEditorErrorStatusCleared())}
        onCopyCompleted={() => dispatch(deactivateContentItemEditingAction())}
        onCopied={() =>
          dispatch(
            trackUserEventWithData(TrackedEvent.ContentItemEditing, {
              action: ContentItemEditingEventTypes.Codename,
              origin: props.actionOrigin,
            }),
          )
        }
      />
    )
  );
};
