import { useAttachRef, useEnsuredContext } from '@kontent-ai/hooks';
import { useHover } from '@react-aria/interactions';
import { useRadio } from '@react-aria/radio';
import { mergeProps } from '@react-aria/utils';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import React, { FocusEventHandler, ReactNode, useId } from 'react';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { Box } from '../../../layout/Box/Box.tsx';
import { Inline } from '../../../layout/Inline/Inline.tsx';
import { Column } from '../../../layout/Row/Column.tsx';
import { Row } from '../../../layout/Row/Row.tsx';
import { Stack } from '../../../layout/Stack/Stack.tsx';
import {
  colorPrimaryHoverInverse,
  colorTextDisabled,
  colorTextHint,
} from '../../../tokens/decision/colors.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { disabledAriaTippyOptions } from '../../Tooltip/constants.ts';
import { mergeAriaDescribedBy } from '../../_utils/ariaUtils.ts';
import { TooltipPropsExtension } from '../../_utils/propPrefabs.ts';
import { RadioGroupContext } from '../radioGroupContext.tsx';
import { RadioButtonState } from '../types.ts';
import { RadioButtonIcon } from './components/RadioButtonIcon.tsx';
import { mainCircleSize, protectiveZone, radioButtonLabelTypography } from './tokens.ts';
import { getRadioButtonLabelColor } from './utils/getRadioButtonLabelColor.ts';

// Offset label so that its first line is vertically centered with the checkbox symbol
const radioButtonIconMinHeight = 2 * protectiveZone + mainCircleSize;
const radioButtonLabelOffset = `calc((${px(radioButtonIconMinHeight)} - ${
  radioButtonLabelTypography.lineHeight
}) / 2)`;

export type RadioButtonProps = Pick<TooltipPropsExtension, 'tooltipText' | 'tooltipPlacement'> & {
  readonly autoFocus?: boolean;
  readonly caption?: ReactNode | null;
  readonly onBlur?: FocusEventHandler<HTMLInputElement>;
  readonly onFocus?: FocusEventHandler<HTMLInputElement>;
  readonly radioButtonState?: RadioButtonState;
  readonly value: string;
};

export const RadioButton = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<RadioButtonProps> // optional children
>(
  (
    {
      autoFocus,
      caption,
      children,
      onBlur,
      onFocus,
      radioButtonState: radioButtonStateFromProps = 'default',
      tooltipText,
      tooltipPlacement = 'top',
      value,
      ...otherProps
    },
    forwardedRef,
  ) => {
    const { state } = useEnsuredContext(RadioGroupContext);

    const radioButtonState = state.isDisabled ? 'disabled' : radioButtonStateFromProps;
    const isDisabled = radioButtonState === 'disabled';
    const isSelected = state.selectedValue === value;

    const { isFocusVisible, focusProps } = useOurFocusRing();

    const { isHovered: isRadioButtonHovered, hoverProps: radioButtonHoverProps } = useHover({});
    const { isHovered: isIconHovered, hoverProps: iconHoverProps } = useHover({
      isDisabled,
    });

    const { refToForward, refObject } = useAttachRef(forwardedRef);

    // useSlotId fires layoutEffect
    const captionId = useId();
    const tooltipId = useId();

    const isTooltipVisible = isFocusVisible || isRadioButtonHovered;
    const { inputProps } = useRadio(
      {
        autoFocus,
        children,
        isDisabled,
        onBlur,
        onFocus,
        value,
        'aria-describedby': mergeAriaDescribedBy(
          caption ? captionId : null,
          isTooltipVisible ? tooltipId : null,
        ),
      },
      state,
      refObject,
    );

    return (
      <Stack align="start" {...getDataUiComponentAttribute(RadioButton)} {...otherProps}>
        <Inline>
          <Tooltip
            placement={tooltipPlacement}
            tooltipText={tooltipText}
            visible={isTooltipVisible}
            tooltipTextId={tooltipId}
            tippyOptions={disabledAriaTippyOptions}
          >
            <Box
              backgroundColor={
                isRadioButtonHovered && !isDisabled
                  ? colorPrimaryHoverInverse
                  : BaseColor.Transparent
              }
              borderRadius={BorderRadius.S}
              component="label"
              color={getRadioButtonLabelColor(radioButtonState)}
              cursor={isDisabled ? 'not-allowed' : 'pointer'}
              display="block"
              overflowWrap="anywhere"
              typography={radioButtonLabelTypography}
              css={`transition: background-color ${transition250};`}
              {...radioButtonHoverProps}
            >
              <Box
                component="span"
                borderRadius="inherit"
                display="block"
                css={`
                  ${isFocusVisible && shadowFocusStyles};
                  transition: box-shadow ${transition250};
                `}
              >
                <VisuallyHidden elementType="span">
                  <input ref={refToForward} {...mergeProps(inputProps, focusProps)} />
                </VisuallyHidden>
                <Row component="span" alignY="start" noWrap spacing={Spacing.None}>
                  <Column component="span" width="content">
                    <RadioButtonIcon
                      isDisabled={isDisabled}
                      isSelected={isSelected}
                      isIconHovered={isIconHovered}
                      isRadioButtonHovered={isRadioButtonHovered}
                      isFocusVisible={isFocusVisible}
                      radioButtonState={radioButtonState}
                      {...iconHoverProps}
                    />
                  </Column>
                  <Column component="span">
                    <Box
                      component="span"
                      display="block"
                      paddingRight={protectiveZone}
                      css={`
                        padding-top: ${radioButtonLabelOffset};
                        padding-bottom: ${radioButtonLabelOffset};
                      `}
                    >
                      {children}
                    </Box>
                  </Column>
                </Row>
              </Box>
            </Box>
          </Tooltip>
        </Inline>
        {!!caption && (
          <Box
            id={captionId}
            color={isDisabled ? colorTextDisabled : colorTextHint}
            typography={Typography.Caption}
            overflowWrap="anywhere"
            css={`padding-left: ${px(2 * protectiveZone + mainCircleSize)}`}
          >
            {caption}
          </Box>
        )}
      </Stack>
    );
  },
);

RadioButton.displayName = 'RadioButton';
