import { Button, RouterLinkButton } from '@kontent-ai/component-library/Button';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IDataTableProps } from './DataTable.tsx';

interface ICreateNewButtonProps extends IDataTableProps {
  readonly showOtherActions: boolean;
}

export const DataTableCreateNewButton: React.FC<ICreateNewButtonProps> = ({
  createNewItemLabel = 'Create new',
  onCreateNewItem,
  createNewItemTooltip,
  isCreateNewDisabled,
  createNewItemLinkPath,
  showOtherActions,
}) => {
  const keyToPreventAnimation = showOtherActions ? 'secondary' : 'primary';

  if (onCreateNewItem || isCreateNewDisabled) {
    return (
      <Button
        tooltipText={createNewItemTooltip}
        tooltipPlacement="left"
        buttonStyle={showOtherActions ? 'secondary' : 'primary'}
        disabled={isCreateNewDisabled}
        key={keyToPreventAnimation}
        onClick={onCreateNewItem}
        {...getDataUiActionAttribute(DataUiAction.CreateNew)}
      >
        {createNewItemLabel}
      </Button>
    );
  }

  if (createNewItemLinkPath) {
    return (
      <RouterLinkButton
        buttonStyle={showOtherActions ? 'tertiary' : 'primary'}
        tooltipText={createNewItemTooltip}
        tooltipPlacement="left"
        key={keyToPreventAnimation}
        to={createNewItemLinkPath}
        {...getDataUiActionAttribute(DataUiAction.CreateNew)}
      >
        {createNewItemLabel}
      </RouterLinkButton>
    );
  }

  return null;
};

DataTableCreateNewButton.displayName = 'DataTableCreateNewButton';
