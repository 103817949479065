import React from 'react';

export enum DataUiAttributes {
  Component = 'data-ui-component',
  Element = 'data-ui-element',
  Input = 'data-ui-input',
  IsFocused = 'data-ui-is-focused',
  ObjectName = 'data-ui-object-name',
}

export enum DataUiCLElement {
  Alert = 'alert',
  DialogPrimaryAction = 'dialog-primary-action',
  MultiSelectDropdown = 'multi-select-dropdown',
  MultiSelectDropdownOption = 'multi-select-dropdown-option',
  MultiSelectOption = 'multi-select-option',
  MultiSelectOptionArea = 'multi-select-option-area',
  MultiSelectPermanentOption = 'multi-select-permanent-option',
  MultiSelectPlaceholder = 'multi-select-placeholder',
  MultiSelectSearchText = 'multi-select-search-text',
  NotificationBarContent = 'notification-bar-content',
  SectionHeadline = 'section-headline',
  SingleSelectDropdownInput = 'single-select-dropdown-input',
  SingleSelectDropdownOption = 'single-select-dropdown-option',
  SingleSelectDropdown = 'single-select-dropdown',
  SingleSelectDropdownSelectedOptionPane = 'single-select-dropdown-selected-option-pane',
  SingleSelectDropdownSelectedOptionText = 'single-select-dropdown-selected-option-text',
  WorkflowStatus = 'workflow-status',
  WorkflowStepContainer = 'workflow-step-container',
}

export type ObjectWithDataAttribute = Partial<
  ReadonlyRecord<DataUiAttributes, string | number | undefined>
>;

export const getDataAttribute = (
  name: DataUiAttributes,
  value: string | number | undefined,
): ObjectWithDataAttribute => (value ? { [name]: value } : {});

export const getDataUiComponentAttribute = (component: React.ComponentType<any>) =>
  getDataAttribute(DataUiAttributes.Component, component.displayName);

export const getDataUiCLElementAttribute = (value: DataUiCLElement) =>
  getDataAttribute(DataUiAttributes.Element, value);

export const getDataUiFocusedAttribute = (value: boolean) =>
  getDataAttribute(DataUiAttributes.IsFocused, value ? 'true' : 'false');

export const getDataUiObjectNameAttribute = (objectName: string) =>
  getDataAttribute(DataUiAttributes.ObjectName, objectName);
