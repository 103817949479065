import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import React, { ComponentProps } from 'react';
import { FormGroup } from '../../../../../_shared/components/input/FormGroup.tsx';
import { ValidatedMultipleOptionSelect } from '../../../../../_shared/components/input/ValidatedMultipleOptionSelect.tsx';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiCollection,
  DataUiInput,
  getDataUiCollectionAttribute,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { calculateTagBackgroundColor } from '../../../../../_shared/utils/workflow/calculateTagBackgroundColor.ts';
import { TagColor } from '../../../../../data/constants/tagColor.ts';
import { RoleOption } from '../../../../../data/models/roles/RoleOption.ts';
import { WorkflowStepOption } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { renderWorkflowStepColor } from '../../../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import { deletedRoleIdBase } from '../../../constants/getDeletedRole.ts';
import { handleAllRolesRoleOnRolesChangedInFormInput } from '../../../utils/allRolesRoleBehaviorUtils.ts';
import { IArchivedWorkflowStepFormShape } from '../../model/IArchivedWorkflowStepFormShape.type.ts';
import { systemWorkflowStepAutofocusDelay } from './contants.ts';

interface IArchivedWorkflowStepFormFieldsProps {
  readonly formProps: HookFormProps<IArchivedWorkflowStepFormShape>;
  readonly permanentTransitionFromOptions: ReadonlyArray<WorkflowStepOption>;
  readonly restoreFromArchivedStepMessage: string;
  readonly roleOptions: ReadonlyArray<RoleOption>;
  readonly stepName: string;
  readonly transitionFromOptions: ReadonlyArray<WorkflowStepOption>;
}

const renderWorkflowStepSelectedOption = (
  _id: string,
  workflowStep: WorkflowStepOption,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) => {
  return <Tag {...defaultTagProps} background={renderWorkflowStepColor(workflowStep.color)} />;
};

const renderRoleOption = (
  id: string,
  _role: RoleOption,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) => {
  return (
    <Tag
      {...defaultTagProps}
      background={calculateTagBackgroundColor(
        id.startsWith(deletedRoleIdBase) ? TagColor.Red : TagColor.Gray,
      )}
    />
  );
};

export const ArchivedWorkflowStepFormFields: React.FC<IArchivedWorkflowStepFormFieldsProps> = (
  props,
) => (
  <div className="bar-item__expansion">
    <ValidatedMultipleOptionSelect<IArchivedWorkflowStepFormShape, WorkflowStepOption>
      autoFocus
      delayAutoFocus={systemWorkflowStepAutofocusDelay}
      formProps={props.formProps}
      items={props.transitionFromOptions}
      label={`Steps that transition to ${props.stepName}`}
      name="transitionFromStepIds"
      permanentOptions={props.permanentTransitionFromOptions}
      placeholder="No transitions"
      renderPermanentOption={renderWorkflowStepSelectedOption}
      renderSelectedOption={renderWorkflowStepSelectedOption}
      verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.WorkflowSteps)}
      {...getDataUiInputAttribute(DataUiInput.WorkflowStepTransitionsTo)}
    />
    <FormGroup>
      <p className="form__label form__label--xs">{props.restoreFromArchivedStepMessage}</p>
    </FormGroup>
    <FormGroup>
      <ValidatedMultipleOptionSelect<IArchivedWorkflowStepFormShape, RoleOption>
        formProps={props.formProps}
        items={props.roleOptions}
        label="Roles that can work with this step"
        name="roleIds"
        normalize={(formValues, previousValues) =>
          handleAllRolesRoleOnRolesChangedInFormInput(formValues, previousValues)
        }
        placeholder="Select roles that can work with this workflow step"
        renderSelectedOption={renderRoleOption}
        verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.RoleList)}
        {...getDataUiInputAttribute(DataUiInput.WorkflowStepRoles)}
      />
    </FormGroup>
  </div>
);

ArchivedWorkflowStepFormFields.displayName = 'ArchivedWorkflowStepFormFields';
