import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { FC } from 'react';
import { BrowseButton } from '../../../../../_shared/components/BrowseButton.tsx';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { FileWithThumbnail } from '../../models/FileWithThumbnail.type.ts';
import { FlexingTile } from './FlexingTile.tsx';

type UploadTileProps = {
  readonly accept?: string;
  readonly multiple?: boolean;
  readonly onUpload: (files: ReadonlyArray<FileWithThumbnail>) => void;
  readonly supportClick?: boolean;
  readonly tileStyle?: string;
};

export const UploadTile: FC<UploadTileProps> = ({
  accept,
  multiple = true,
  onUpload,
  supportClick = true,
  tileStyle,
}) => (
  <FlexingTile className={tileStyle}>
    <div
      className="asset-library__uploader-tile"
      {...getDataUiElementAttribute(DataUiElement.AssetUploader)}
    >
      <Icon iconName={IconName.ArrowUpLine} />
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Box marginBottom={Spacing.M}>
          <BrowseButton
            accept={accept}
            buttonStyle="secondary"
            dataUiAction={DataUiAction.PickAsset}
            buttonText="Browse files"
            multiple={multiple}
            onUpload={onUpload}
            screenReaderText="Select an asset from your computer."
            supportClick={supportClick}
          />
        </Box>
        or drop here to upload
      </Box>
    </div>
  </FlexingTile>
);

UploadTile.displayName = 'UploadTile';
