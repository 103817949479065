import styled from 'styled-components';
import { borderWidthDefault } from '../../../tokens/decision/border.ts';
import {
  colorAlertActive,
  colorAlertHover,
  colorBorderActive,
  colorBorderAlert,
  colorBorderDisabled,
  colorBorderHover,
  colorInputBorder,
} from '../../../tokens/decision/colors.ts';
import { shadowAlertFocusStyles, shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { InputState } from '../inputStateEnum.ts';
import { InputField } from './InputField.tsx';
import { StyledInputWrapper } from './StyledInputWrapper.tsx';
import { InputStyleProps } from './inputStyleProps.type.ts';
import { inputBorderRadius } from './tokens.ts';

export const getFakeInputBorderColor = ({ $inputState }: InputStyleProps) => {
  switch ($inputState) {
    case InputState.Disabled:
    case InputState.ReadOnly:
      return colorBorderDisabled;
    case InputState.Alert:
      return colorBorderAlert;
    case InputState.Default:
      return colorInputBorder;
  }
};

export const getFakeInputFocusBorderColor = ({ $inputState }: InputStyleProps) => {
  switch ($inputState) {
    case InputState.Alert:
      return colorAlertActive;
    case InputState.Default:
    case InputState.Disabled:
    case InputState.ReadOnly:
      return colorBorderActive;
  }
};

export const getFakeInputHoverBorderColor = ({ $inputState }: InputStyleProps) => {
  switch ($inputState) {
    case InputState.Alert:
      return colorAlertHover;
    case InputState.Default:
      return colorBorderHover;
    case InputState.Disabled:
    case InputState.ReadOnly:
      return undefined;
  }
};

export const FakeInput = styled.span<
  InputStyleProps & {
    readonly isFocusVisible: boolean;
  }
>`
  position: absolute;
  top: 0;
  left: 0;
  
  display: block;
  width: 100%;
  height: 100%;
  border: ${px(borderWidthDefault)} solid ${getFakeInputBorderColor};
  border-radius: ${inputBorderRadius};
  
  pointer-events: none;
  transition: border-color ${transition250};
  ${StyledInputWrapper}:focus-within && {
    border-color: ${getFakeInputFocusBorderColor};
  }
  
  ${InputField}:hover && {
    border-color: ${getFakeInputHoverBorderColor}; 
  };
  
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    
    ${({ $inputState }) =>
      $inputState === InputState.Alert ? shadowAlertFocusStyles : shadowFocusStyles};
    opacity: ${({ isFocusVisible }) => (isFocusVisible ? 1 : 0)};
    transition: opacity ${transition250};
  }
`;
FakeInput.displayName = 'FakeInput';
