import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None, Optional } from '../../editorCore/types/Editor.contract.type.ts';
import { ApiLimitationsPlugin } from '../apiLimitations/ApiLimitationsPlugin.tsx';
import { ApiStatisticsPlugin } from '../apiStatistics/ApiStatisticsPlugin.tsx';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { TextBlockTypesPlugin } from '../textBlockTypes/TextBlockTypesPlugin.tsx';
import { EditorTextApi } from './api/EditorTextApi.type.ts';
import { editorTextApi } from './api/editorTextApi.ts';

export type TextApiPlugin = DraftJsEditorPlugin<
  None,
  None,
  EditorTextApi,
  None,
  [Optional<TextBlockTypesPlugin>, ApiLimitationsPlugin, Optional<ApiStatisticsPlugin>]
>;

export const TextApiPlugin: PluginComponent<TextApiPlugin> = (props) => {
  const { getApiMethods } = useEditorApi<TextApiPlugin>(editorTextApi);

  return useEditorWithPlugin<TextApiPlugin>(props, { getApiMethods });
};
