import { Icon } from '@kontent-ai/component-library/Icons';
import React, { ReactNode } from 'react';
import { CannotCreateNewVariantForItemError } from '../../../applications/itemEditor/constants/errorMessages.ts';
import { DataUiAction } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { BarItemAction } from '../BarItems/Actions/BarItemAction.tsx';

type Props = {
  readonly canCreate: boolean;
  readonly dataUiAction: DataUiAction;
  readonly icon: Icon;
  readonly isAvailable: boolean;
  readonly isCompact?: boolean;
  readonly isTranslated: boolean;
  readonly renderClickableWrapper: (props: {
    readonly action: ReactNode;
  }) => JSX.Element;
  readonly tooltipText: string;
};

export const LinkedItemOpenEditingAction: React.FC<Props> = ({
  canCreate,
  dataUiAction,
  icon,
  isAvailable,
  isTranslated,
  renderClickableWrapper,
  tooltipText,
}) => {
  if (!isAvailable) {
    return null;
  }

  const isDisabled = !isTranslated && !canCreate;

  const action = (
    <BarItemAction
      dataUiActionName={dataUiAction}
      disabled={isDisabled}
      icon={icon}
      tooltipText={isDisabled ? CannotCreateNewVariantForItemError : tooltipText}
    />
  );

  if (isDisabled) {
    return action;
  }

  return renderClickableWrapper({
    action,
  });
};

LinkedItemOpenEditingAction.displayName = 'LinkedItemOpenEditingAction';
