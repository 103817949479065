import { useAttachRef } from '@kontent-ai/hooks';
import { useLink } from '@react-aria/link';
import { mergeProps } from '@react-aria/utils';
import { AriaLinkProps } from '@react-types/link';
import React from 'react';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { mergeAriaDescribedBy } from '../../_utils/ariaUtils.ts';
import { TooltipPropsExtension } from '../../_utils/propPrefabs.ts';
import { ButtonBadge } from '../ButtonBadge.tsx';
import { ButtonIcon } from '../ButtonIcon.tsx';
import { IBaseButtonProps } from '../components/BaseButton.ts';
import { BaseButtonComponent } from '../components/BaseButtonComponent.tsx';
import { StyledAnchorButton } from '../components/StyledButton.tsx';

type AnchorButtonProps = TooltipPropsExtension &
  Pick<IBaseButtonProps, 'buttonStyle' | 'size' | 'buttonDisplay' | 'disableTabulator'> &
  AriaLinkProps & {
    readonly className?: string;
    readonly href: string;
  };

const AnchorButtonWithRef = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<AnchorButtonProps>
>(
  (
    {
      buttonDisplay = 'inline',
      buttonStyle,
      children,
      className,
      disableTabulator,
      size = 'medium',
      tooltipMaxGridUnitsWidth,
      tooltipPlacement = 'top',
      tooltipShortcuts,
      tooltipText,
      ...otherProps
    },
    forwardedRef,
  ) => {
    const { refObject, refToForward } = useAttachRef(forwardedRef);
    const {
      linkProps: { 'aria-describedby': ariaDescribedBy = '', ...linkProps },
    } = useLink(otherProps, refObject);

    return (
      <BaseButtonComponent
        tooltipText={tooltipText}
        tooltipPlacement={tooltipPlacement}
        tooltipMaxGridUnitsWidth={tooltipMaxGridUnitsWidth}
        tooltipShortcuts={tooltipShortcuts}
        buttonStyle={buttonStyle}
        buttonDisplay={buttonDisplay}
        disableTabulator={disableTabulator}
        size={size}
        renderButtonComponent={({
          'aria-describedby': injectedAriaDescribedBy,
          ...injectedProps
        }) => (
          <StyledAnchorButton
            aria-describedby={mergeAriaDescribedBy(injectedAriaDescribedBy, ariaDescribedBy)}
            className={className}
            ref={refToForward}
            {...mergeProps(linkProps, injectedProps)}
            {...getDataUiComponentAttribute(AnchorButton)}
          />
        )}
      >
        {children}
      </BaseButtonComponent>
    );
  },
);

AnchorButtonWithRef.displayName = 'AnchorButton';

const anchorLinkButtonComposition = {
  Badge: ButtonBadge,
  Icon: ButtonIcon,
} as const;

export const AnchorButton = Object.assign(AnchorButtonWithRef, anchorLinkButtonComposition);
