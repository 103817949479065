import { Box } from '@kontent-ai/component-library/Box';
import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { FC } from 'react';
import {
  EnvironmentRoute,
  EnvironmentRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';

interface IOpenProjectActionButton {
  readonly projectId: string;
}

export const OpenProjectActionButton: FC<IOpenProjectActionButton> = ({ projectId }) => (
  <Box marginRight={Spacing.S}>
    <RouterLinkButton
      to={buildPath<EnvironmentRouteParams>(EnvironmentRoute, { projectId })}
      buttonStyle="primary"
      buttonDisplay="block"
      {...getDataUiActionAttribute(DataUiAction.OpenProject)}
    >
      Open project
    </RouterLinkButton>
  </Box>
);

OpenProjectActionButton.displayName = 'OpenProjectActionButton';
