import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IContentItemStatus } from '../../../../contentInventory/content/models/filter/IContentItemStatus.ts';
import { IPublishingState } from '../../../../contentInventory/content/models/filter/IPublishingState.ts';
import { contentStatusContentItemsInit } from '../contentStatusActions.ts';

interface IDeps {
  readonly loadLanguages: (abortSignal: AbortSignal) => ThunkPromise;
  readonly initAction: typeof contentStatusContentItemsInit;
}

export const getArrayOfStringFlags = (
  flagObject: IPublishingState | IContentItemStatus,
): readonly string[] =>
  Object.entries(flagObject)
    .filter(([, value]) => value)
    .map(([flag]) => flag);

export const createInitializeContentStatusAction =
  (deps: IDeps) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(deps.initAction());
    await dispatch(deps.loadLanguages(abortSignal));
  };
