import { Action } from '../../../../@types/Action.type.ts';
import {
  YourTasks_LoadRelatedItems_BatchReceived,
  YourTasks_LoadRelatedItems_Started,
} from '../../../../applications/missionControl/widgets/tasksAssignedToYou/constants/yourTasksActionTypes.ts';
import { IYourTasksListingContentItem } from '../../../../applications/missionControl/widgets/tasksAssignedToYou/models/IYourTasksListingContentItem.type.ts';
import { getYourTaskVariantIdentifier } from '../../../../applications/missionControl/widgets/tasksAssignedToYou/selectors/getYourTasks.ts';

const initialState: ReadonlyMap<string, IYourTasksListingContentItem> = new Map<
  string,
  IYourTasksListingContentItem
>();

export function byId(
  state: ReadonlyMap<string, IYourTasksListingContentItem> = initialState,
  action: Action,
): ReadonlyMap<string, IYourTasksListingContentItem> {
  switch (action.type) {
    case YourTasks_LoadRelatedItems_Started:
      return initialState;

    case YourTasks_LoadRelatedItems_BatchReceived: {
      const newState = new Map(state.entries());
      action.payload.items.forEach((itemVariant) =>
        newState.set(
          getYourTaskVariantIdentifier(
            itemVariant.variant.id.itemId,
            itemVariant.variant.id.variantId,
          ),
          itemVariant,
        ),
      );
      return newState;
    }

    default:
      return state;
  }
}
