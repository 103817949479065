import {
  MissionControl_LocalizedRoute_Entered,
  MissionControl_LocalizedRoute_Left,
} from '../constants/missionControlActionTypes.ts';

export const localizedRouteLeft = () => ({ type: MissionControl_LocalizedRoute_Left }) as const;

export const localizedRouteEntered = (selectedLanguageId: Uuid) =>
  ({
    type: MissionControl_LocalizedRoute_Entered,
    payload: {
      selectedLanguageId,
    },
  }) as const;
