import { Button } from '@kontent-ai/component-library/Button';
import {
  ControlShortcutTemplate,
  ShortcutSymbols,
} from '../../../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiCommentsAction,
  getDataUiCommentActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly isSubmitting: boolean;
  readonly isSuggestion: boolean;
  readonly isSubmitButtonDisabled: boolean;
  readonly onCancelEditing: () => void;
};

export const NewCommentThreadItemActionsBar = ({
  isSuggestion,
  onCancelEditing,
  isSubmitting,
  isSubmitButtonDisabled,
}: Props) => {
  const cancelButtonTooltip = isSuggestion ? 'Discard your suggestion' : 'Discard your comment';
  const submitButtonTooltip = isSuggestion
    ? 'Suggest a replacement for the highlighted text'
    : 'Save your comment';

  return (
    <div className="comment-editor__actions">
      <Button
        tooltipText={isSubmitting ? undefined : cancelButtonTooltip}
        tooltipPlacement="bottom-end"
        tooltipShortcuts={ShortcutSymbols.Escape}
        buttonStyle="secondary"
        size="small"
        onClick={isSubmitting ? undefined : onCancelEditing}
        disabled={isSubmitting}
        {...getDataUiCommentActionAttribute(DataUiCommentsAction.Cancel)}
      >
        Cancel
      </Button>
      <Button
        tooltipText={!isSubmitting && !isSubmitButtonDisabled ? submitButtonTooltip : undefined}
        tooltipPlacement="bottom-end"
        tooltipShortcuts={ControlShortcutTemplate(ShortcutSymbols.Enter)}
        buttonStyle="primary"
        size="small"
        type="submit"
        disabled={isSubmitButtonDisabled}
        {...getDataUiCommentActionAttribute(DataUiCommentsAction.SaveComment)}
      >
        {getButtonText(isSuggestion, isSubmitting)}
      </Button>
    </div>
  );
};

const getButtonText = (isSuggestion: boolean, isSubmitting: boolean): string => {
  if (isSuggestion) {
    return isSubmitting ? 'Suggesting…' : 'Suggest changes';
  }

  return isSubmitting ? 'Sending…' : 'Send';
};
