import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { IconSize, Spacing, colorTextLowEmphasis } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { ConnectDragSource } from 'react-dnd';
import styled from 'styled-components';
import { BarItemDragAction } from '../../../../../_shared/components/BarItems/Actions/BarItemDragAction.tsx';
import { BarItemEditAction } from '../../../../../_shared/components/BarItems/Actions/BarItemEditAction.tsx';
import { BarItemActions } from '../../../../../_shared/components/BarItems/BarItemActions.tsx';
import { BarItemBar } from '../../../../../_shared/components/BarItems/BarItemBar.tsx';
import { BarItemTitle } from '../../../../../_shared/components/BarItems/BarItemTitle.tsx';
import { isLanguageActive } from '../../../../../_shared/models/utils/isLanguageActive.ts';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';

type Props = {
  readonly connectDragSource?: ConnectDragSource;
  readonly isDragging: boolean;
  readonly fallbackLanguageName: string;
  readonly isEditable: boolean;
  readonly language: ILanguage;
  readonly onShowEditor: () => void;
};

const InactiveWrapper = styled(Inline)`
  color: ${colorTextLowEmphasis};
  white-space: nowrap;
`;

const FallbackWrapper = styled.span`
  color: ${colorTextLowEmphasis};
  white-space: nowrap;
`;

export const LanguageBar = ({
  connectDragSource,
  fallbackLanguageName,
  isDragging,
  isEditable,
  language,
  onShowEditor,
}: Props) => (
  <div className="bar-item__wrapper">
    <div
      className={classNames('bar-item__pane', {
        'bar-item__pane--is-dragging': isDragging,
      })}
    >
      <BarItemBar isClickable={isEditable && !isDragging}>
        <BarItemActions>
          <BarItemDragAction
            connectDragSource={connectDragSource}
            disabled={!isEditable || !isLanguageActive(language)}
          />
        </BarItemActions>
        <BarItemTitle
          flexFactor={1}
          onClick={isEditable ? onShowEditor : undefined}
          suffix={
            !isLanguageActive(language) && (
              <InactiveWrapper spacing={Spacing.XS} align="center" noWrap>
                <Icons.Drawers size={IconSize.S} />
                {' Inactive'}
              </InactiveWrapper>
            )
          }
          title={language.name}
        >
          {language.name}
        </BarItemTitle>
        {isLanguageActive(language) && (
          <BarItemTitle
            flexFactor={1}
            onClick={isEditable ? onShowEditor : undefined}
            title={`Fallback: ${fallbackLanguageName}`}
          >
            <FallbackWrapper>{'Fallback: '}</FallbackWrapper>
            {fallbackLanguageName}
          </BarItemTitle>
        )}
        <BarItemActions>
          <BarItemEditAction onClick={onShowEditor} disabled={!isEditable} />
        </BarItemActions>
      </BarItemBar>
    </div>
  </div>
);
