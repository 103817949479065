import React from 'react';
import styled, { css } from 'styled-components';
import { BaseColor } from '../../tokens/quarks/colors.ts';
import { Typography } from '../../tokens/quarks/typography.ts';
import { LabelSize } from './labelSize.ts';

const getLabelStyles = ({ $size, hasEmphasis }: IStyledLabelProps) => {
  switch ($size) {
    case LabelSize.S:
      return hasEmphasis ? Typography.LabelSmallEmphasis : Typography.LabelSmall;
    case LabelSize.M:
      return hasEmphasis ? Typography.LabelMediumEmphasis : Typography.LabelMedium;
    case LabelSize.L:
      return hasEmphasis ? Typography.LabelLargeEmphasis : Typography.LabelLarge;
  }
};

const StyledLabel = styled.span<IStyledLabelProps>`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ $color }) =>
    $color &&
    css`
    color: ${$color};
  `};
  ${getLabelStyles};
`;

export interface ILabelProps {
  readonly className?: string;
  readonly color?: BaseColor;
  readonly component?: React.ElementType;
  readonly hasEmphasis?: boolean;
  readonly size: LabelSize;
}

interface IStyledLabelProps extends Pick<ILabelProps, 'className' | 'hasEmphasis'> {
  readonly $color?: BaseColor;
  readonly $size: LabelSize;
}

export const Label = React.forwardRef<HTMLElement, React.PropsWithChildren<ILabelProps>>(
  (
    { color, className, children, component = 'span', hasEmphasis, size, ...otherProps },
    forwardedRef,
  ) => (
    <StyledLabel
      as={component}
      className={className}
      $color={color}
      hasEmphasis={hasEmphasis}
      ref={forwardedRef}
      $size={size}
      {...otherProps}
    >
      {children}
    </StyledLabel>
  ),
);

Label.displayName = 'Label';
