import { Collection } from '@kontent-ai/utils';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { filterCollectionsByCapabilityInLanguage } from '../../../../../_shared/utils/permissions/filterCollectionsByCapability.ts';
import { ISpace } from '../../../../../data/models/space/space.ts';
import { spacesContainCollection } from '../../../../environmentSettings/spaces/utils/spacesUtils.ts';

export const getAvailableSpacesForUser = (state: IStore): ReadonlyArray<ISpace> => {
  const variantId = state.contentApp.editedContentItemVariant?.id.variantId;
  const spacesById = state.data.spaces.byId;
  const collectionsById = state.data.collections.byId;
  const projectId = state.sharedApp.currentProjectId;
  const user = state.data.user;

  const spaces = Collection.getValues(spacesById);
  const collections = Collection.getValues(collectionsById);

  const availableCollections = filterCollectionsByCapabilityInLanguage(
    collections,
    variantId ?? '',
    Capability.ViewContent,
    projectId,
    user,
  );

  const areAllCollectionsAvailable = availableCollections.length === collections.length;

  return areAllCollectionsAvailable
    ? spaces
    : spacesContainCollection(
        spaces,
        availableCollections.map((collection) => collection.id),
      );
};
