import { Divider, DividerDirection } from '@kontent-ai/component-library/Dividers';
import { Spacing } from '@kontent-ai/component-library/tokens';

export const EditorToolbarDivider = () => (
  <Divider
    direction={DividerDirection.Vertical}
    offsetBefore={Spacing.None}
    offsetAfter={Spacing.None}
  />
);
