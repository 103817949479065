import { Redirect, Route, Switch, useLocation } from 'react-router';
import {
  CustomAppDetailRoute,
  CustomAppDetailRouteParams,
} from '../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { jsonTryParse } from '../../../_shared/utils/jsonUtils.ts';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { CustomAppSandbox } from '../components/CustomAppSandbox.tsx';
import { CustomApps as CustomAppsComponent } from '../components/CustomApps.tsx';
import { CustomAppsMenu } from '../components/CustomAppsMenu.tsx';
import { getActiveCustomAppId, getCurrentUserWithRoles } from '../utils/customAppsUtils.ts';

export const CustomApps = () => {
  const pathname = useLocation().pathname;
  const projectId = useSelector((state) => state.sharedApp.currentProjectId);
  const customApps = useSelector((state) => state.data.userCustomApps.customApps);
  const currentUser = useSelector((state) => getCurrentUserWithRoles(state, projectId));
  const activeCustomAppId = useSelector((state) => getActiveCustomAppId(state, pathname));
  const activeCustomApp = useSelector((state) =>
    state.data.userCustomApps.customApps.find((customApp) => customApp.id === activeCustomAppId),
  );

  return (
    <CustomAppsComponent
      renderMenu={() => (
        <CustomAppsMenu
          activeCustomAppId={activeCustomAppId}
          customApps={customApps}
          projectId={projectId}
        />
      )}
      renderCustomApp={() =>
        activeCustomAppId &&
        activeCustomApp && (
          <Switch>
            <Route path={CustomAppDetailRoute}>
              <CustomAppSandbox
                customApp={activeCustomApp}
                createGetContextV1Response={() => ({
                  payload: {
                    config: jsonTryParse(activeCustomApp?.config),
                    context: {
                      environmentId: projectId,
                      userEmail: currentUser.email,
                      userRoles: currentUser.roles,
                      userId: currentUser.id,
                    },
                  },
                })}
              />
            </Route>
            <Route>
              <Redirect
                to={buildPath<CustomAppDetailRouteParams>(CustomAppDetailRoute, {
                  projectId,
                  customAppId: activeCustomAppId,
                })}
              />
            </Route>
          </Switch>
        )
      }
    />
  );
};
