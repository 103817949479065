import { Action } from '../../../../../../../@types/Action.type.ts';
import { Data_ListingContentItems_Success } from '../../../../../../../data/constants/dataActionTypes.ts';
import { AiSearch_Finished } from '../../../../../../../paperModels/aiSearch/actions/aiSearchActionTypes.ts';
import {
  ModalMultipleContentItemsSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../../../../../../features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import {
  ContentListing_CancelScheduledPublish_Finished,
  ContentListing_DeleteItems_Finished,
  ContentListing_Init_Started,
  ContentListing_MoveToCollection_Finished,
  ContentListing_Page_Left,
  ContentListing_PublishItems_Finished,
  ContentListing_ScheduledPublishItems_Finished,
  ContentListing_ScheduledUnpublishItems_Finished,
  ContentListing_SelectAll_Off,
  ContentListing_SelectAll_On,
  ContentListing_UndoArchiveItems_Finished,
  ContentListing_UnpublishItems_Finished,
  ContentListing_UpdateWorkflowStep_Finished,
} from '../../../../features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';

export function selectAll(state = false, action: Action): boolean {
  switch (action.type) {
    case ContentListing_SelectAll_On:
      return true;

    case ContentListing_Page_Left:
    case ContentListing_SelectAll_Off:
    case ContentListing_MoveToCollection_Finished:
    case ContentListing_PublishItems_Finished:
    case ContentListing_ScheduledPublishItems_Finished:
    case ContentListing_UnpublishItems_Finished:
    case ContentListing_ScheduledUnpublishItems_Finished:
    case ContentListing_CancelScheduledPublish_Finished:
    case ContentListing_UpdateWorkflowStep_Finished:
    case ContentListing_DeleteItems_Finished:
    case ContentListing_UndoArchiveItems_Finished:
      return false;

    case ContentListing_Init_Started:
    case ModalMultipleContentItemsSelector_Closed:
    case ModalMultipleContentItemsSelector_Opened:
    case AiSearch_Finished:
      return false;

    case Data_ListingContentItems_Success:
      return state && !action.payload.continuationToken;

    default:
      return state;
  }
}
