import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ILanguage, Languages } from '../../../../../data/models/languages/Language.ts';
import {
  IAssignedListingContentItem,
  YourContentItem,
} from '../../../../../data/models/listingContentItems/IAssignedListingContentItem.ts';
import { IRecentlyEditedListingContentItem } from '../../../../../data/models/listingContentItems/IRecentlyEditedListingContentItem.ts';
import { IAssignedItemViewModel } from '../../itemsAssignedToYou/containers/ItemsAssignedToYouWidgetDetailContainer.tsx';
import { IRecentlyEditedItemViewModel } from '../../recentlyEditedByYou/containers/RecentlyEditedByYouWidgetDetailContainer.tsx';

const _decorateItems = <T extends YourContentItem>(
  items: Immutable.List<T>,
  languages: Immutable.OrderedMap<Uuid, ILanguage>,
  viewableLanguages: Languages,
  contentTypes: Immutable.Map<Uuid, IContentType>,
) => {
  const decorateWithLanguageAndContentType = (item: T) => {
    const language = viewableLanguages.size === 1 ? null : languages.get(item.id.variantId);
    const contentType = contentTypes.get(item.contentTypeId);

    return {
      id: item.id,
      contentType: contentType?.name || '',
      due: item.due,
      language: language?.name || '',
      lastUpdatedAt: item.lastUpdatedAt,
      workflowStatus: item.workflowStatus,
      publishingState: item.publishingState,
      scheduledToPublishAt: item.scheduledToPublishAt,
      scheduledToUnpublishAt: item.scheduledToUnpublishAt,
      variantCompletionStatus: item.variantCompletionStatus,
      name: item.name,
    };
  };

  return items.map(decorateWithLanguageAndContentType).toList();
};

export const decorateItemsAssignedToYou = memoize.weak(
  (
    items: Immutable.List<IAssignedListingContentItem>,
    languages: Immutable.OrderedMap<Uuid, ILanguage>,
    viewableLanguages: Languages,
    contentTypes: Immutable.Map<Uuid, IContentType>,
  ): Immutable.List<IAssignedItemViewModel> =>
    _decorateItems<IAssignedListingContentItem>(items, languages, viewableLanguages, contentTypes),
);

export const decorateItemsRecentlyEditedByYou = memoize.weak(
  (
    items: Immutable.List<IRecentlyEditedListingContentItem>,
    languages: Immutable.OrderedMap<Uuid, ILanguage>,
    viewableLanguages: Languages,
    contentTypes: Immutable.Map<Uuid, IContentType>,
  ): Immutable.List<IRecentlyEditedItemViewModel> =>
    _decorateItems<IRecentlyEditedListingContentItem>(
      items,
      languages,
      viewableLanguages,
      contentTypes,
    ),
);
