import { notNullNorUndefined } from '@kontent-ai/utils';

export const mergeAriaDescribedBy = (...strings: (string | null | undefined)[]) =>
  strings
    .filter((str) => notNullNorUndefined(str) && str !== '')
    .join(' ')
    .trim();

export const getA11yLabelProps = (label: string | null | undefined, ariaLabel: string) => {
  if (label) {
    return { label } as const;
  }
  return { 'aria-label': ariaLabel } as const;
};

export const getA11yLabelledByProps = (
  label: string | null | undefined,
  ariaLabelledBy: string,
) => {
  if (label) {
    return { label };
  }
  return { 'aria-labelledby': ariaLabelledBy };
};
